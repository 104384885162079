import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Swal from "sweetalert2";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      show: false,

      data_search: "",

      data_menu_work: [],
      data_business_type: [],
      country_qty: "",
      type_search: "ในระบบ",

      receive_code: "",
      receive_date: new Date(),
      branch: { value: "1", label: "สำนักงานใหญ่" },
      immigration_office: "",
      broker: "",
      emp_full_name: "",
      full_name_en: "",
      emp_type: "",
      register_date: "",
      register_capital: "",
      id_card: "",
      place_issue: "",
      issue_date: "",
      expiry_date: "",
      business_type: "",
      contact_name: "",
      phone: "",
      fax: "",
      email: "",
      home_number: "",
      address_number: "",
      phone_2: "",
      fax_2: "",
      binding_authority_th: "",
      position_th: "",
      binding_authority_en: "",
      position_en: "",
      process_matter: "",
      cambodia_qty: "",
      laos_qty: "",
      myanmar_qty: "",
      vietnam_qty: "",
      total_qty: "",
      remark: "",

      data_deputize: [],

      readOnly: false,

      check_list: [],
    };
  }
  async componentDidMount() {
    await this.GetBusinessType();
    await this.GetMenuWork();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
      this.setState({ readOnly: true });
    }
  }
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      req_price_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/request-price/list/page/0", body);
    if (result && result.status) { 
      if (result.data.emp_data) {
        this.setState({
          data_search: result.data.emp_data.emp_number,
          emp_id: result.data.emp_data.emp_id,
          emp_code: result.data.emp_data.emp_code,
          emp_user_type: result.data.emp_data.emp_user_type,
          emp_type: result.data.emp_data.emp_type,
          emp_number: result.data.emp_data.emp_number,
          register_capital: result.data.emp_data.register_capital,
          register_date: result.data.emp_data.register_date,
          id_card: result.data.emp_data.id_card,
          place_issue: result.data.emp_data.place_issue,
          issue_date: result.data.emp_data.issue_date,
          expiry_date: result.data.emp_data.expiry_date,
          contact_name: result.data.emp_data.contact_name,
          emp_full_name: result.data.emp_data.full_name_th,
          full_name_en: result.data.emp_data.full_name_en,
          business_type: result.data.emp_data.business_type,
          address_number: result.data.emp_data.address_number,
          village_name: result.data.emp_data.village_name,
          floor: result.data.emp_data.floor,
          room_no: result.data.emp_data.room_no,
          building_name: result.data.emp_data.building_name,
          moo: result.data.emp_data.moo,
          soi: result.data.emp_data.soi,
          street: result.data.emp_data.street,
          province: result.data.emp_data.province,
          district: result.data.emp_data.district,
          sub_district: result.data.emp_data.sub_district,
          zipcode: result.data.emp_data.zipcode,
          email: result.data.emp_data.email,
          phone: result.data.emp_data.phone,
          fax: result.data.emp_data.fax,
          mobile_phone: result.data.emp_data.mobile_phone,
          home_number: result.data.emp_data.home_number,
          phone_2: result.data.emp_data.phone_2,
          fax_2: result.data.emp_data.fax_2,
          binding_authority_th: result.data.emp_data.binding_authority_th,
          binding_authority_en: result.data.emp_data.binding_authority_en,
          position_th: result.data.emp_data.position_th,
          position_en: result.data.emp_data.position_en,
          operator_type: result.data.emp_data.operator_type,
          deputize_number: result.data.emp_data.deputize_number,
          deputize_full_name: result.data.emp_data.deputize_full_name,
          username: result.data.emp_data.username,
          copy_idcard_image: result.data.emp_data.copy_idcard_image,
          copy_idcard_image_date: result.data.emp_data.copy_idcard_image_date,
          coordinator_data: result.data.emp_data.coordinator_data,
        });
      }
      this.setState({  
        req_price_id: id,

        process_matter: this.state.data_menu_work.filter((e) => e.value === result.data.menu_id)[0],
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.cambodia_qty + result.data.laos_qty + result.data.myanmar_qty + result.data.vietnam_qty,
        country_qty:result.data.cambodia_qty?"กัมพูชา":result.data.laos_qty?"ลาว":result.data.myanmar_qty?"เมียนมา":result.data.vietnam_qty?"เวียดนาม":"",
        check_list:result.data.checklist_data, 
      });
    }
    this.setState({ loading: false });
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetMenuWork = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: [],
      menu_id: "",
    };
    let result = await GET(tokens, "api/receive-matter-menu/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data.map((e) => {
          return {
            value: e.menu_id,
            label: e.menu_name,
          };
        }),
        process_matter: result.data
          .filter((e) => e.menu_id === "69656dc0-3392-4d15-9816-33a4dbad264b")
          .map((e) => {
            return {
              value: e.menu_id,
              label: e.menu_name,
            };
          })[0],
      });
    }
    this.setState({ loading: false });
  };
  GetUser = async () => {
    if (this.state.data_search === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขประจำตัวนายจ้าง");
      return;
    }
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      emp_id: "",
    };
    let result = await GET(tokens, this.state.type_search === "กรมพัฒนาธุรกิจการค้า" ? "api/employer/department-trade" : "api/employer/list/page/0", body);
    if (result && result.status) {
      this.setState({
        emp_id: result.data[0].emp_id,
        emp_code: result.data[0].emp_code,
        emp_user_type: result.data[0].emp_user_type,
        emp_type: result.data[0].emp_type,
        emp_number: result.data[0].emp_number,
        register_capital: result.data[0].register_capital,
        register_date: result.data[0].register_date,
        id_card: result.data[0].id_card,
        place_issue: result.data[0].place_issue,
        issue_date: result.data[0].issue_date,
        expiry_date: result.data[0].expiry_date,
        contact_name: result.data[0].contact_name,
        emp_full_name: result.data[0].full_name_th,
        full_name_en: result.data[0].full_name_en,
        business_type: result.data[0].business_type,
        address_number: result.data[0].address_number,
        village_name: result.data[0].village_name,
        floor: result.data[0].floor,
        room_no: result.data[0].room_no,
        building_name: result.data[0].building_name,
        moo: result.data[0].moo,
        soi: result.data[0].soi,
        street: result.data[0].street,
        province: result.data[0].province,
        district: result.data[0].district,
        sub_district: result.data[0].sub_district,
        zipcode: result.data[0].zipcode,
        email: result.data[0].email,
        phone: result.data[0].phone,
        fax: result.data[0].fax,
        mobile_phone: result.data[0].mobile_phone,
        home_number: result.data[0].home_number,
        phone_2: result.data[0].phone_2,
        fax_2: result.data[0].fax_2,
        binding_authority_th: result.data[0].binding_authority_th,
        binding_authority_en: result.data[0].binding_authority_en,
        position_th: result.data[0].position_th,
        position_en: result.data[0].position_en,
        operator_type: result.data[0].operator_type,
        deputize_number: result.data[0].deputize_number,
        deputize_full_name: result.data[0].deputize_full_name,
        username: result.data[0].username,
        copy_idcard_image: result.data[0].copy_idcard_image,
        copy_idcard_image_date: result.data[0].copy_idcard_image_date,
        dpz_data: result.data[0].dpz_data,
        pw_data: result.data[0].pw_data,
        data_workplace: result.data[0].pw_data,
        coordinator_data: result.data[0].coordinator_data, 
      });
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_number: this.state.emp_number,
      menu_id: this.state.process_matter ? this.state.process_matter.value : "",
      menu_name: this.state.process_matter ? this.state.process_matter.label : "",
      cambodia_qty: Number(this.state.cambodia_qty),
      laos_qty: Number(this.state.laos_qty),
      myanmar_qty: Number(this.state.myanmar_qty),
      vietnam_qty: Number(this.state.vietnam_qty),
      total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
      check_list: this.state.check_list
    };
    let result = await POST(tokens, "api/request-price/create", body);
    if (result && result.status) {
      success_url("/RequestForPrice/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      emp_number: this.state.emp_number,
      menu_id: this.state.process_matter ? this.state.process_matter.value : "",
      menu_name: this.state.process_matter ? this.state.process_matter.label : "",
      cambodia_qty: Number(this.state.cambodia_qty),
      laos_qty: Number(this.state.laos_qty),
      myanmar_qty: Number(this.state.myanmar_qty),
      vietnam_qty: Number(this.state.vietnam_qty),
      total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
      check_list: this.state.check_list
    };
    let result = await PUT(tokens, "api/request-price/update/" + this.state.req_price_id, body);
    if (result && result.status) {
      success_url("/RequestForPrice/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ขอใบเสนอราคา" page3="เพิ่ม" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>ขอใบเสนอราคา</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                {/*<div className="w-100">
                  <h5 className="mb-3">
                    <b>ข้อมูลนายจ้าง</b>
                  </h5>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขนิติบุคคล/เลขประจำตัวผู้เสียภาษี(13หลัก){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ data_search: e.target.value });
                        }}
                        value={this.state.data_search}
                        disabled={this.state.readOnly}
                      />
                      {!this.state.readOnly && (
                        <button
                          className="btn btn-primary mx-1 px-4"
                          onClick={() => {
                            this.GetUser();
                          }}
                        >
                          ค้นหา
                        </button>
                      )}
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทนายจ้าง</span>
                      <input className="form-control mt-1" type="text" readOnly value={this.state.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_type === 2 ? "นิติบุคคล" : this.state.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""} />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อนายจ้าง</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ emp_full_name: e.target.value });
                        }}
                        value={this.state.emp_full_name}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">English</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ full_name_en: e.target.value });
                        }}
                        value={this.state.full_name_en}
                      />
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary px-4 mb-3"
                      onClick={() => {
                        this.setState({ show: !this.state.show });
                      }}
                    >
                      {this.state.show ? "ซ่อนรายละเอียด" : "แสดงรายละเอียด"}
                    </button>
                  </div>
                  {this.state.show ? (
                    <div>
                      <div className="row">
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">จดทะเบียนเมื่อ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.register_date ? format_date(this.state.register_date) : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ทุนจดทะเบียน(บาท)</span>
                          <input className="form-control mt-1" type="text" readOnly value={toFixed(this.state.register_capital)} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">บัตรประชาชนเลขที่</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.id_card} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">สถานที่ออกบัตร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.place_issue} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออกบัตร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.issue_date ? format_date(this.state.issue_date) : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมดอายุ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.expiry_date ? format_date(this.state.expiry_date) : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ประเภทกิจการ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.business_type.toString())[0].label : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ประเภทกิจการ(English)</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.business_type.toString())[0].label : ""} />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อผู้ติดต่อ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.contact_name} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรศัพท์</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.phone} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรสาร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.fax} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรศัพท์มือถือ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.phone} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">E-mail Address</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.email} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขรหัสประจำบ้าน ๑๑ หลัก</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.home_number} />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ที่ตั้ง/ที่อยู่</span>
                          <input className="form-control mt-1" type="text" readOnly value={(this.state.address_number ? this.state.address_number : "") + (this.state.moo ? " หมู่ " + this.state.moo : "") + (this.state.soi ? " ซอย " + this.state.soi : "") + (this.state.street ? " ถนน " + this.state.street : "") + (this.state.sub_district ? " ตำบล " + this.state.sub_district : "") + (this.state.district ? " อำเภอ " + this.state.district : "") + (this.state.province ? " จังหวัด " + this.state.province : "") + (this.state.zipcode ? " รหัสไปรษณีย์ " + this.state.zipcode : "")} />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ที่ตั้ง/ที่อยู่(English)</span>
                          <input className="form-control mt-1" type="text" readOnly value={(this.state.address_number ? this.state.address_number : "") + (this.state.moo ? " Moo " + this.state.moo : "") + (this.state.soi ? " Soi " + this.state.soi : "") + (this.state.street ? " Street " + this.state.street : "") + (this.state.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.sub_district)[0]?.sub_district_name_en : "") + (this.state.district ? " , " + district.filter((e) => e.district_name === this.state.district)[0]?.district_name_en : "") + (this.state.province ? " , " + province.filter((e) => e.province_name === this.state.province)[0]?.province_name_en : "") + (this.state.zipcode ? " " + this.state.zipcode : "")} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรศัพท์</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.phone_2} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรสาร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.fax_2} />
                        </div>
                      </div>
                      {this.state.emp_id && (
                        <div>
                          <div className="d-flex justify-content-between mb-3">
                            <h5 className="mb-3">
                              <b>กรรมการผู้มีอำนาจ</b>
                            </h5>
                            {!this.state.readOnly && this.state.data_deputize.length < 3 && (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  let data_deputize = this.state.data_deputize;
                                  data_deputize.push({
                                    operator_type: 2,
                                    deputize_id: "",
                                    deputize_number: "",
                                    deputize_full_name: "",
                                    deputize_phone: "",
                                    deputize_position: "",
                                    copy_company_certificate: "",
                                    copy_company_certificate_date: "",
                                    copy_power_attorney: "",
                                    copy_power_attorney_date: "",
                                    copy_tax_certificate: "",
                                    copy_tax_certificate_date: "",
                                  });
                                  this.setState({ data_deputize: data_deputize });
                                }}
                              >
                                + เพิ่มกรรมการผู้มีอำนาจ
                              </button>
                            )}
                          </div>
                          <div className="card bg-contain p-3 pb-0">
                            {this.state.data_deputize.map((item, index) => (
                              <div className="card p-3 mb-3 position-relative">
                                <div
                                  className="wpx-30 hpx-30 rounded-circle d-flex align-items-center justify-content-center icon text-white pointer bg-danger position-absolute"
                                  style={{ top: -8, right: -8 }}
                                  onClick={() => {
                                    let data_deputize = this.state.data_deputize;
                                    data_deputize.splice(index, 1);
                                    this.setState({ data_deputize: data_deputize });
                                  }}
                                >
                                  {"\uf00d"}
                                </div>
                                <div className="row">
                                  <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <span className="wpx-220">ชื่อ - นามสกุล กรรมการผู้มีอำนาจ{item.operator_type === 2 ? required : null}</span>
                                    <input
                                      className="form-control mt-1"
                                      type="text"
                                      onChange={(e) => {
                                        let data_deputize = this.state.data_deputize;
                                        data_deputize[index].deputize_full_name = e.target.value;
                                        this.setState({ data_deputize: data_deputize });
                                      }}
                                      value={item.deputize_full_name}
                                    />
                                  </div>
                                  <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <span className="wpx-80">ตำแหน่ง</span>
                                    <input
                                      className="form-control mt-1"
                                      type="text"
                                      onChange={(e) => {
                                        let data_deputize = this.state.data_deputize;
                                        data_deputize[index].deputize_position = e.target.value;
                                        this.setState({ data_deputize: data_deputize });
                                      }}
                                      value={item.deputize_position}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
                <hr /> */}
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>บริการที่เสนอราคา</b>
                  </h5>
                  <div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                    <span className="wpx-150">เลข 13 หลัก นายจ้าง{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ emp_number: e.target.value });
                        }}
                        value={this.state.emp_number}
                        disabled={this.state.readOnly}
                        maxLength={13}
                      />  
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                      
                      <span className="wpx-150">บริการที่เสนอราคา{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_menu_work}
                        onChange={(e) => {
                          this.setState({
                            process_matter: e,
                            country_qty: "",
                            cambodia_qty: "",
                            laos_qty: "",
                            myanmar_qty: "",
                            vietnam_qty: "",
                            total_qty: "",
                          });
                        }}
                        value={this.state.process_matter}
                        isDisabled={this.state.readOnly}
                      ></Select>
                    </div>
                  </div>
                  {this.state.process_matter && (
                    <div className="row">
                      {this.state.process_matter.label === "ขอนำเข้าแรงงานต่างด้าว (MOU)" ? (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white wpx-140">สัญชาติ</th>
                                <th className="text-center text-white">จำนวนคน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        country_qty: e.target.value,
                                        cambodia_qty: "",
                                        laos_qty: "",
                                        myanmar_qty: "",
                                        vietnam_qty: "",
                                        total_qty: "",
                                      });
                                    }}
                                    value={this.state.country_qty}
                                    disabled={this.state.readOnly}
                                  >
                                    <option value=""></option>
                                    <option value="กัมพูชา">กัมพูชา</option>
                                    <option value="ลาว">ลาว</option>
                                    <option value="เมียนมา">เมียนมา</option>
                                    <option value="เวียดนาม">เวียดนาม</option>
                                  </select>
                                </td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      switch (this.state.country_qty) {
                                        case "กัมพูชา":
                                          this.setState({
                                            cambodia_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            laos_qty: "",
                                            myanmar_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "ลาว":
                                          this.setState({
                                            laos_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            myanmar_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "เมียนมา":
                                          this.setState({
                                            myanmar_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            laos_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "เวียดนาม":
                                          this.setState({
                                            vietnam_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            laos_qty: "",
                                            myanmar_qty: "",
                                          });
                                          break;

                                        default:
                                          break;
                                      }
                                    }}
                                    value={this.state.country_qty === "กัมพูชา" ? this.state.cambodia_qty : this.state.country_qty === "ลาว" ? this.state.laos_qty : this.state.country_qty === "เมียนมา" ? this.state.myanmar_qty : this.state.country_qty === "เวียดนาม" ? this.state.vietnam_qty : ""}
                                    disabled={this.state.readOnly || !this.state.country_qty}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white wpx-140">สัญชาติ</th>
                                <th className="text-center text-white">จำนวนคน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">กัมพูชา</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ cambodia_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.cambodia_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">ลาว</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ laos_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.laos_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">เมียนมา</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ myanmar_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.myanmar_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">เวียดนาม</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ vietnam_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.vietnam_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                      <span className="wpx-150">รวมทั้งสิ้น</span>
                      <input
                        className="form-control mt-1 text-end"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ total_qty: e.target.value });
                        }}
                        value={this.state.total_qty}
                      />
                    </div>
                  </div>
                </div>
                <hr />

                <div className="w-100">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-3">
                      <b>รายชื่อ</b>
                    </h5>
                    {!this.state.readOnly && (
                    <button
                      className="btn btn-primary px-4"
                      onClick={() => {
                        Swal.fire({
                          icon: "info",
                          title: "ยืนยัน",
                          text: "ยืนยันรายการ",
                          confirmButtonText: "ตกลง",
                          showCancelButton: true,
                          cancelButtonText: "ยกเลิก",
                        }).then((re) => {
                          if (re.isConfirmed) {
                           
                        let check_list = [];
                        for (let i = 0; i < Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty); i++) {
                          check_list.push({
                            checklist_id: "",
                            id_card: "",
                            name_list: "",
                            c_passport: false,
                            c_visa: false,
                            c_work_permit: false,
                            c_disease: false,
                            c_health_insurance: false,
                            c_consent_address: false,
                            c_profile: false,
                          });
                        }
                        this.setState({
                          check_list:check_list,
                          // readOnly: true
                        })

                        }
                      });
                      }}
                    >
                      ยืนยันรายการ
                    </button>
                    )}
                  </div>
                  <div className="table-responsive cs-table-responsive">
                    <table className="table table-bordered table-striped table-fixed3">
                      <thead className="bg-primary">
                        <tr>
                          <th className="text-center text-white wpx-60">ลำดับ</th>
                          <th className="text-center text-white wpx-200">เลขประจำตัว 13 หลัก</th>
                          <th className="text-center text-white wpx-200">Name List</th>
                          <th className="text-center text-white" style={{ minWidth: 830 }} colSpan={8}>
                            เอกสารลูกจ้าง
                            <input
                              className="mx-2"
                              type="checkbox"
                              onChange={(e) => {
                                let check_list = this.state.check_list;
                                for (let i = 0; i < check_list.length; i++) {
                                  check_list[i].c_passport = e.target.checked;
                                  check_list[i].c_visa = e.target.checked;
                                  check_list[i].c_work_permit = e.target.checked;
                                  check_list[i].c_disease = e.target.checked;
                                  check_list[i].c_health_insurance = e.target.checked;
                                  check_list[i].c_consent_address = e.target.checked;
                                  check_list[i].c_profile = e.target.checked;
                                }
                                this.setState({ check_list: check_list });
                              }}
                              checked={this.state.check_list.filter((item) => !item.c_passport).length === 0 && this.state.check_list.filter((item) => !item.c_visa).length === 0 && this.state.check_list.filter((item) => !item.c_work_permit).length === 0 && this.state.check_list.filter((item) => !item.c_disease).length === 0 && this.state.check_list.filter((item) => !item.c_health_insurance).length === 0 && this.state.check_list.filter((item) => !item.c_consent_address).length === 0 && this.state.check_list.filter((item) => !item.c_profile).length === 0}
                            />{" "}
                            ทั้งหมด
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.check_list &&
                          this.state.check_list.map((item, index) => (
                            <tr key={index}>
                              <td className="text-center" style={{ verticalAlign: "middle" }}>
                                {index + 1}
                              </td>
                              <td className="text-left">
                                <input
                                  className="form-control"
                                  type="text"
                                  maxLength={13}
                                  onChange={(e) => {
                                    let check_list = this.state.check_list;
                                    check_list[index].id_card = e.target.value;
                                    this.setState({ check_list: check_list });
                                  }}
                                  value={item.id_card}
                                />
                              </td>
                              <td className="text-left">
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={(e) => {
                                    let check_list = this.state.check_list;
                                    check_list[index].name_list = e.target.value;
                                    this.setState({ check_list: check_list });
                                  }}
                                  value={item.name_list}
                                />
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_passport = e.target.checked;
                                      check_list[index].c_visa = e.target.checked;
                                      check_list[index].c_work_permit = e.target.checked;
                                      check_list[index].c_disease = e.target.checked;
                                      check_list[index].c_health_insurance = e.target.checked;
                                      check_list[index].c_consent_address = e.target.checked;
                                      check_list[index].c_profile = e.target.checked;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_passport && item.c_visa && item.c_work_permit && item.c_disease && item.c_health_insurance && item.c_consent_address && item.c_profile}
                                  />{" "}
                                  ทั้งหมด
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_passport = !check_list[index].c_passport;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_passport}
                                  />{" "}
                                  Passport
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_visa = !check_list[index].c_visa;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_visa}
                                  />{" "}
                                  Visa
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_work_permit = !check_list[index].c_work_permit;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_work_permit}
                                  />{" "}
                                  Work permit
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_disease = !check_list[index].c_disease;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_disease}
                                  />{" "}
                                  ใบตรวจโรค
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_health_insurance = !check_list[index].c_health_insurance;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_health_insurance}
                                  />{" "}
                                  ประกันสุขภาพ
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_consent_address = !check_list[index].c_consent_address;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_consent_address}
                                  />{" "}
                                  ยินยอมให้ใช้ที่อยู่
                                </div>
                              </td>
                              <td className="text-left">
                                <div className="w-100  d-flex align-items-center">
                                  <input
                                    className="mt-1 me-2"
                                    type="checkbox"
                                    onChange={(e) => {
                                      let check_list = this.state.check_list;
                                      check_list[index].c_profile = !check_list[index].c_profile;
                                      this.setState({ check_list: check_list });
                                    }}
                                    checked={item.c_profile}
                                  />{" "}
                                  รูปถ่าย
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/RequestForPrice/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (this.state.check_list.length === 0) {
                              alert("warning", "แจ้งเตือน", "กรุณายืนยันรายการ");
                              return;
                            }
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

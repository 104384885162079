import React from "react";
import { Router, Route } from "react-router";

import "./components/global.js";

// PUBLIC
import Console from "./page/Console";
import Loading from "./page/Loading";
import Login from "./page/Login";
import Register from "./page/Register";
import ForgotPassword from "./page/ForgotPassword";
import ChangePassword from "./page/ChangePassword.js";

import ListWaiting from "./page/admin/ListWaiting.js";
import ListRecieveAll from "./page/admin/ListRecieveAll.js";
import CreateUpdateRecieveAll from "./page/admin/CreateUpdateRecieveAll.js";

// ขอใบอนุญาตทำงาน
import ListRecieveMOU from "./page/admin/ListRecieveMOU.js";
import CreateUpdateRecieveMOU from "./page/admin/CreateUpdateRecieveMOU.js";

// ต่ออใบอนุญาตทำงาน
import ListRenewLicense from "./page/admin/ListRenewLicense.js";
import CreateUpdateRenewLicense from "./page/admin/CreateUpdateRenewLicense.js";

// รายการรอตรวจสอบ ใบรับเรื่อง
import ListApproveRecieveAll from "./page/admin/ListApproveRecieveAll.js";
import ListApproveQC from "./page/admin/ListApproveQC.js";
import ListApproveRenewLicense from "./page/admin/ListApproveRenewLicense.js";

// ใบเสนอราคา
import ListQuotation from "./page/admin/ListQuotation.js";
import CreateUpdateQuotation from "./page/admin/CreateUpdateQuotation.js";

// ใบขอราคา
import ListRequestForPrice from "./page/admin/ListRequestForPrice.js";
import CreateUpdateRequestForPrice from "./page/admin/CreateUpdateRequestForPrice.js";

// ฝ่ายขาย
import ListSaleDepartment from "./page/admin/ListSaleDepartment.js";
import CreateUpdateSaleDepartment from "./page/admin/CreateUpdateSaleDepartment.js";

// รายการใบแจ้งหนี้
import ListInvoice from "./page/admin/ListInvoice.js";
import CreateUpdateInvoice from "./page/admin/CreateUpdateInvoice.js";

// รายการใบเสร็จ
import ListReceipt from "./page/admin/ListReceipt.js";

// Billing Note
import ListBillingNote from "./page/admin/ListBillingNote.js";
import CreateUpdateBillingNote from "./page/admin/CreateUpdateBillingNote.js";

// ระบบ LMS
import ListLMS from "./page/admin/ListLMS.js";
import CreateUpdateLMS from "./page/admin/CreateUpdateLMS.js";

// ทะเบียนนายจ้าง
import ListEmployer from "./page/admin/ListEmployer.js";
import CreateUpdateEmployer from "./page/admin/CreateUpdateEmployer.js";
import CreateUpdateEmployerPublic from "./page/admin/CreateUpdateEmployerPublic.js";

// ทะเบียนลูกจ้าง
import ListEmployee from "./page/admin/ListEmployee.js";
import CreateUpdateEmployee from "./page/admin/CreateUpdateEmployee.js";

// ทะเบียนตัวแทน
import ListAgent from "./page/admin/ListAgent.js";
import ListInsurance from "./page/admin/ListInsurance.js";

// จัดการบัญชีระบบ
import ListCustomer from "./page/admin/ListCustomer.js";
import CreateUpdateCustomer from "./page/admin/CreateUpdateCustomer.js";

// ตั้งค่าการออกเลขเรื่อง
import ListNumber from "./page/admin/ListNumber.js";

// ผู้ใช้งาน
import ListUser from "./page/admin/ListUser.js";

// สิทธิ์ผู้ใช้งาน
import ListRole from "./page/admin/ListRole.js";

// ดาวน์โหลดไฟล์
import ListDownload from "./page/admin/ListDownload.js";

// อัพโหลดไฟล์
import UploadFile from "./page/admin/UploadFile.js";

// การตั้งค่า
import ListPostBoxNo from "./page/admin/setting/ListPostBoxNo.js";
import ListImmigrationOffice from "./page/admin/setting/ListImmigrationOffice.js";
import ListPrefix from "./page/admin/setting/ListPrefix.js";
import ListSalary from "./page/admin/setting/ListSalary.js";
import ListDocumentName from "./page/admin/setting/ListDocumentName.js";
import ListLMS2 from "./page/admin/setting/ListLMS.js";
import ListLMSCategory from "./page/admin/setting/ListLMSCategory.js"; 
import ListQuotation2 from "./page/admin/setting/ListQuotation.js";
import ListSendDocument from "./page/admin/setting/ListSendDocument.js";
import ListServiceRate from "./page/admin/setting/ListServiceRate.js";
import ListServiceType from "./page/admin/setting/ListServiceType.js";
import ListMenuWork from "./page/admin/setting/ListMenuWork.js";
import ListWorkType from "./page/admin/setting/ListWorkType.js";
import ListBusinessType from "./page/admin/setting/ListBusinessType.js";
import ListBranch from "./page/admin/setting/ListBranch.js";
import ListBank from "./page/admin/setting/ListBank.js";
import ListEmpOffice from "./page/admin/setting/ListEmpOffice.js";

import CreateUpdateDocumentName from "./page/admin/setting/CreateUpdateDocumentName.js";
import CreateUpdateLMS2 from "./page/admin/setting/CreateUpdateLMS.js";
import CreateUpdatePaperHead from "./page/admin/setting/CreateUpdatePaperHead.js";
import CreateUpdateQuotation2 from "./page/admin/setting/CreateUpdateQuotation.js";
import CreateUpdateSendDocument from "./page/admin/setting/CreateUpdateSendDocument.js";
import CreateUpdateAlert from "./page/admin/setting/CreateUpdateAlert.js";

import CreateJob from "./page/urgent/CreateJob.js";
import UploadJob from "./page/urgent/UploadJob.js";

import ReportInvoice from "./components/report/ReportInvoice.js";
import ReportReceipt from "./components/report/ReportReceipt.js";

const Routes = (props) => (
  <Router {...props}>
    {/* PUBLIC */}
    <Route path="/" component={Loading} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/change-password" component={ChangePassword} />
    <Route path="/console" component={Console} />

    <Route path="/Waiting/list" component={ListWaiting} />

    <Route path="/RecieveAll/list" component={ListRecieveAll} />
    <Route path="/RecieveAll/create" component={CreateUpdateRecieveAll} />
    <Route path="/RecieveAll/update" component={CreateUpdateRecieveAll} />
    
    <Route path="/RecieveMOU/list" component={ListRecieveMOU} />
    <Route path="/RecieveMOU/create" component={CreateUpdateRecieveMOU} />
    <Route path="/RecieveMOU/update" component={CreateUpdateRecieveMOU} />

    <Route path="/RenewLicense/list" component={ListRenewLicense} />
    <Route path="/RenewLicense/create" component={CreateUpdateRenewLicense} />
    <Route path="/RenewLicense/update" component={CreateUpdateRenewLicense} />

    <Route path="/ApproveRecieveAll/list" component={ListApproveRecieveAll} />
    <Route path="/ApproveQC/list" component={ListApproveQC} />
    <Route path="/ApproveRenewLicense/list" component={ListApproveRenewLicense} />

    <Route path="/Quotation/list" component={ListQuotation} />
    <Route path="/Quotation/create" component={CreateUpdateQuotation} />
    <Route path="/Quotation/update" component={CreateUpdateQuotation} />

    <Route path="/RequestForPrice/list" component={ListRequestForPrice} />
    <Route path="/RequestForPrice/create" component={CreateUpdateRequestForPrice} />
    <Route path="/RequestForPrice/update" component={CreateUpdateRequestForPrice} />

    <Route path="/SaleDepartment/list" component={ListSaleDepartment} />
    <Route path="/SaleDepartment/create" component={CreateUpdateSaleDepartment} />
    <Route path="/SaleDepartment/update" component={CreateUpdateSaleDepartment} />

    <Route path="/Invoice/list" component={ListInvoice} />
    <Route path="/Invoice/create" component={CreateUpdateInvoice} />
    <Route path="/Invoice/update" component={CreateUpdateInvoice} />

    <Route path="/Receipt/list" component={ListReceipt} />

    <Route path="/BillingNote/list" component={ListBillingNote} />
    <Route path="/BillingNote/create" component={CreateUpdateBillingNote} />
    <Route path="/BillingNote/update" component={CreateUpdateBillingNote} />

    <Route path="/LMSmain/list" component={ListLMS} />
    <Route path="/LMSmain/create" component={CreateUpdateLMS} />
    <Route path="/LMSmain/update" component={CreateUpdateLMS} />

    <Route path="/Employer/list" component={ListEmployer} />
    <Route path="/Employer/create" component={CreateUpdateEmployer} />
    <Route path="/Employer/update" component={CreateUpdateEmployer} />
    <Route path="/Employer/create-public" component={CreateUpdateEmployerPublic} />

    <Route path="/Employee/list" component={ListEmployee} />
    <Route path="/Employee/create" component={CreateUpdateEmployee} />
    <Route path="/Employee/update" component={CreateUpdateEmployee} />

    <Route path="/Customer/list" component={ListCustomer} />
    <Route path="/Customer/create" component={CreateUpdateCustomer} />
    <Route path="/Customer/update" component={CreateUpdateCustomer} />

    <Route path="/Number/list" component={ListNumber} />
    <Route path="/User/list" component={ListUser} />
    <Route path="/Role/list" component={ListRole} />

    <Route path="/Agent/list" component={ListAgent} />
    <Route path="/Insurance/list" component={ListInsurance} />

    <Route path="/Download/list" component={ListDownload} />

    <Route path="/upload" component={UploadFile} />

    <Route path="/setting/DocumentName/list" component={ListDocumentName} />
    <Route path="/setting/DocumentName/create" component={CreateUpdateDocumentName} />
    <Route path="/setting/DocumentName/update" component={CreateUpdateDocumentName} />
    <Route path="/setting/LMS/list" component={ListLMS2} />
    <Route path="/setting/LMS/create" component={CreateUpdateLMS2} />
    <Route path="/setting/LMS/update" component={CreateUpdateLMS2} />
    <Route path="/setting/LMSCategory/list" component={ListLMSCategory} /> 
    <Route path="/setting/PaperHead/create" component={CreateUpdatePaperHead} /> 
    <Route path="/setting/Alert/create" component={CreateUpdateAlert} /> 
    <Route path="/setting/Quotation/list" component={ListQuotation2} />
    <Route path="/setting/Quotation/create" component={CreateUpdateQuotation2} />
    <Route path="/setting/Quotation/update" component={CreateUpdateQuotation2} />
    <Route path="/setting/SendDocument/list" component={ListSendDocument} />
    <Route path="/setting/SendDocument/create" component={CreateUpdateSendDocument} />
    <Route path="/setting/SendDocument/update" component={CreateUpdateSendDocument} />
    <Route path="/setting/ServiceRate/list" component={ListServiceRate} />
    <Route path="/setting/Salary/list" component={ListSalary} />
    <Route path="/setting/ServiceType/list" component={ListServiceType} />
    <Route path="/setting/ListMenuWork/list" component={ListMenuWork} />
    <Route path="/setting/Prefix/list" component={ListPrefix} />
    <Route path="/setting/ImmigrationOffice/list" component={ListImmigrationOffice} />
    <Route path="/setting/WorkType/list" component={ListWorkType} />
    <Route path="/setting/BusinessType/list" component={ListBusinessType} />
    <Route path="/setting/Branch/list" component={ListBranch} />
    <Route path="/setting/Bank/list" component={ListBank} />
    <Route path="/setting/EmpOffice/list" component={ListEmpOffice} />
    <Route path="/setting/PostBoxNo/list" component={ListPostBoxNo} />

    <Route path="/urgent/create" component={CreateJob} />
    <Route path="/urgent/upload" component={UploadJob} />

    <Route path="/report/invoice" component={ReportInvoice} />
    <Route path="/report/receipt" component={ReportReceipt} />
    <Route path="/*" component={Loading} />
  </Router>
);

export default Routes;

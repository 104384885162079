import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import province from "../../../assets/files/province.json";
import district from "../../../assets/files/district.json";
import subdistrict from "../../../assets/files/all_province.json";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_delete: false,
      tab: 1,
      loading: false,

      data_search: "",

      data_user: [],
      data_insurance: [],
      data_business_type: [],
      data_workplace: [],
      data_agent: [],
      data_nationality: global.data_nationality,
      data_prefix_th: [],
      data_prefix_en: [],
      data_gender: global.data_gender,
      data_evidence_type: global.data_evidence_type,
      data_country: global.data_country,

      // นายจ้าง
      emp_number: "",
      emp_full_name: "",
      emp_address_number: "",
      emp_moo: "",
      emp_soi: "",
      emp_street: "",
      emp_province: "",
      emp_district: "",
      emp_sub_district: "",
      emp_zipcode: "",

      // ข้อมูลทั้งหมด
      fw_id: "",
      emp_id: "",
      insurance_type: 0,
      business_type: "",
      province: "",
      place_work: "",
      agent: "",
      phone: "",
      email: "",
      fw_number: "",
      nationality: "",
      prefix_th: "",
      first_name_th: "",
      middle_name_th: "",
      last_name_th: "",
      prefix_en: "",
      first_name_en: "",
      middle_name_en: "",
      last_name_en: "",
      birth_date: "",
      age: "",
      gender: "",
      evidence_type: "",
      document_type_number: "",

      job_position: "",

      profile_image: "",
      identity_document: "",
      evidence_allowed_document: "",

      profile_image_date: "",
      identity_document_date: "",
      evidence_allowed_document_date: "",

      profile_image_type: "",
      identity_document_type: "",
      evidence_allowed_document_type: "",

      // เพิ่มสถานที่ทำงานใหม่
      address_number: "",
      moo: "",
      soi: "",
      street: "",
      province: "",
      district: "",
      sub_district: "",
      zipcode: "",
      phone_create: "",
      email_create: "",

      // ตัวแทน
      agent_name: "",
      agent_phone: "",
      agent_email: "",
      modal_agent_create: false,

      // ไฟล์แนบ
      attachment_name: "",

      // Passport
      passport_number: "",
      passport_place: "",
      passport_born_place: "",
      passport_country: "",
      passport_allowed_date: "",
      passport_expiry_date: "",
      passport_image: "",

      // Visa
      visa_number: "",
      visa_allowed_location: "",
      visa_allowed_date: "",
      visa_expiry_date: "",
      visa_image: "",

      // Work permit
      work_pm_number: "",
      work_pm_allowed_location: "",
      work_pm_allowed_date: "",
      work_pm_expiry_date: "",
      work_pm_image: "",

      // ประกันสุขภาพรัฐบาล
      health_number: "",
      health_hospital_name: "",
      health_allowed_date: "",
      health_expiry_date: "",
      health_image: "",

      // ประกันภัยเอกชน
      insurance_name: "",
      insurance_number: "",
      insurance_allowed_date: "",
      insurance_expiry_date: "",
      insurance_image: "",

      // ประกันสังคม
      sso_number: "",
      sso_image: "",

      // ตรวจโรค
      hospital_name: "",
      inspec_date: "",
      diagnose_image: "",

      // ประกันภัย
      company_name: "",
      policy_number: "",
      allowed_stay_date: "",
      expiry_date: "",
      policy_image: "",
    };
  }
  async componentDidMount() {
    await this.GetAgent();
    await this.GetInsurance();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
    }
    if (this.props.location.query.emp_number) {
      this.setState({ data_search: this.props.location.query.emp_number });
      setTimeout(() => {
        this.GetUser();
      }, 10);
    }
  }
  GetInsurance = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      insurance_id: "",
    };
    let result = await GET(tokens, "api/insurance/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_insurance: result.data.map((e) => {
          return { value: e.insurance_id, label: e.company_name };
        }),
      });
    }
    this.setState({ loading: false });
  };
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      fw_id: id,
    };
    let result = await GET(tokens, "api/foreign-worker/list/page/0", body);
    if (result && result.status) {
      let item = result.data;
      await this.GetWorkplace(item.emp_data.emp_id);
      this.setState({
        fw_id: item.fw_id,
        emp_id: item.emp_data.emp_id,

        emp_number: item.emp_data.emp_number,
        emp_full_name: item.emp_data.full_name_th,
        emp_address_number: item.emp_data.address_number,
        emp_moo: item.emp_data.moo,
        emp_soi: item.emp_data.soi,
        emp_street: item.emp_data.street,
        emp_province: item.emp_data.province,
        emp_district: item.emp_data.district,
        emp_sub_district: item.emp_data.sub_district,
        emp_zipcode: item.emp_data.zipcode,

        insurance_type: item.insurance_type,
        business_type: item.emp_data ? this.state.data_business_type.filter((e) => e.value === item.emp_data.business_type.toString())[0] : "",
        province: { value: item.province, label: item.province },
        place_work: item.pw_data ? this.state.data_workplace.filter((e) => e.value === item.pw_data.pw_id.toString())[0] : "",
        agent: item.agent ? this.state.data_agent.filter((e) => e.value === item.agent)[0] : "",

        phone: item.phone,
        email: item.email,

        fw_number: item.fw_number,
        nationality: item.nationality ? this.state.data_nationality.filter((e) => e.value === item.nationality.toString())[0] : "",
        prefix_th: item.prefix_th ? this.state.data_prefix_th.filter((e) => e.value === item.prefix_th.toString())[0] : "",
        first_name_th: item.first_name_th,
        middle_name_th: item.middle_name_th,
        last_name_th: item.last_name_th,
        prefix_en: item.prefix_en ? this.state.data_prefix_en.filter((e) => e.value === item.prefix_en.toString())[0] : "",
        first_name_en: item.first_name_en,
        middle_name_en: item.middle_name_en,
        last_name_en: item.last_name_en,
        birth_date: item.birth_date,
        age: item.age,
        gender: this.state.data_gender.filter((e) => e.value === item.gender)[0],
        evidence_type: item.evidence_type ? this.state.data_evidence_type.filter((e) => e.value === item.evidence_type.toString())[0] : "",
        document_type_number: item.document_type_number,
        country: item.country ? this.state.data_country.filter((e) => e.value === item.country)[0] : "",
        allowed_work_date: item.allowed_work_date,

        profile_image: item.profile_image,
        identity_document: item.identity_document,
        evidence_allowed_document: item.evidence_allowed_document,

        profile_image_type: item.profile_image ? item.profile_image.split(".").pop() : "",
        identity_document_type: item.identity_document ? item.identity_document.split(".").pop() : "",
        evidence_allowed_document_type: item.evidence_allowed_document ? item.evidence_allowed_document.split(".").pop() : "",

        // Passport
        passport_number: item.passport_number,
        passport_place: item.passport_place,
        passport_born_place: item.passport_born_place,
        passport_country: item.passport_country ? this.state.data_country.filter((e) => e.value === item.passport_country)[0] : "",
        passport_allowed_date: item.passport_allowed_date,
        passport_expiry_date: item.passport_expiry_date,
        passport_image: item.passport_image,

        // Visa
        visa_number: item.visa_number,
        visa_allowed_location: item.visa_allowed_location ? { value: item.visa_allowed_location, label: item.visa_allowed_location } : "",
        visa_allowed_date: item.visa_allowed_date,
        visa_expiry_date: item.visa_expiry_date,
        visa_image: item.visa_image,

        // Work permit
        work_pm_number: item.work_pm_number,
        work_pm_allowed_location: item.work_pm_allowed_location ? { value: item.work_pm_allowed_location, label: item.work_pm_allowed_location } : "",
        work_pm_allowed_date: item.work_pm_allowed_date,
        work_pm_expiry_date: item.work_pm_expiry_date,
        work_pm_image: item.work_pm_image,

        // ประกันสุขภาพรัฐบาล
        health_number: item.health_number,
        health_hospital_name: item.health_hospital_name,
        health_allowed_date: item.health_allowed_date,
        health_expiry_date: item.health_expiry_date,
        health_image: item.health_image,

        // ประกันภัยเอกชน
        insurance_name: item.insurance_name ? this.state.data_insurance.filter((e) => e.value === item.insurance_name) : "",
        insurance_number: item.insurance_number,
        insurance_allowed_date: item.insurance_allowed_date,
        insurance_expiry_date: item.insurance_expiry_date,
        insurance_image: item.insurance_image,

        // ประกันสังคม
        sso_number: item.sso_number,
        sso_image: item.sso_image,

        // ตรวจโรค
        hospital_name: item.hospital_name,
        inspec_date: item.inspec_date,
        diagnose_image: item.diagnose_image,

        // ประกันภัย
        company_name: item.company_name,
        policy_number: item.policy_number,
        allowed_stay_date: item.allowed_stay_date,
        expiry_date: item.expiry_date,
        policy_image: item.policy_image,
      });
    }
    this.setState({ loading: false });
  };
  GetUser = async () => {
    if (this.state.data_search === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขประจำตัวนายจ้าง");
      return;
    }
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      emp_id: "",
    };
    let result = await GET(tokens, "api/employer/list/page/0", body);
    if (result && result.status) {
      await this.GetWorkplace(result.data[0].emp_id);
      this.setState({
        emp_id: result.data[0].emp_id,
        emp_number: result.data[0].emp_number,
        emp_full_name: result.data[0].full_name_th,
        emp_address_number: result.data[0].address_number,
        emp_moo: result.data[0].moo,
        emp_soi: result.data[0].soi,
        emp_street: result.data[0].street,
        emp_province: result.data[0].province,
        emp_district: result.data[0].district,
        emp_sub_district: result.data[0].sub_district,
        emp_zipcode: result.data[0].zipcode,
        business_type: this.state.data_business_type.filter((e) => e.value === result.data[0].business_type.toString())[0],
        place_work: this.state.data_workplace[0],
      });

      if (this.state.data_workplace.length === 0) {
        this.setState({
          emp_id: result.data[0].emp_id,
          address_number: result.data[0].address_number,
          moo: result.data[0].moo,
          soi: result.data[0].soi,
          street: result.data[0].street,
          province: province
            .map((item) => {
              return { value: item.province_name, label: item.province_name };
            })
            .filter((item) => item.value === result.data[0].province)[0],
          district: district
            .map((item) => {
              return { value: item.district_name, label: item.district_name };
            })
            .filter((item) => item.value === result.data[0].district)[0],
          sub_district: subdistrict
            .map((item) => {
              return { value: item.sub_district_name, label: item.sub_district_name, zip_code: item.zip_code };
            })
            .filter((item) => item.value === result.data[0].sub_district)[0],
          zipcode: result.data[0].zipcode,
          phone_create: result.data[0].phone,
          email_create: result.data[0].email,
        });
        await this.CreateWorkplace();
      }
    }
    this.setState({ loading: false });
  };
  GetWorkplace = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.pw_id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
            ...e,
          };
        }),
      });
      if (result.data.length === 1) {
        setTimeout(() => {
          this.setState({
            place_work: this.state.data_workplace[0],
          });
        }, 10);
      }
    }
    this.setState({ loading: false });
  };
  GetAgent = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      agent_id: "",
    };
    let result = await GET(tokens, "api/agent/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_agent: result.data.map((e) => {
          return {
            value: e.agent_id,
            label: e.agent_name + " " + e.agent_phone + " " + e.agent_email,
            ...e,
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      insurance_type: this.state.insurance_type,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      province: this.state.province ? this.state.province.value : "",
      place_work: this.state.place_work ? this.state.place_work.value : "",
      agent: this.state.agent ? this.state.agent.value : "",
      phone: this.state.phone,
      email: this.state.email,

      fw_number: this.state.fw_number,
      nationality: this.state.nationality ? Number(this.state.nationality.value) : "",
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      first_name_th: this.state.first_name_th,
      middle_name_th: this.state.middle_name_th,
      last_name_th: this.state.last_name_th,
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      first_name_en: this.state.first_name_en,
      middle_name_en: this.state.middle_name_en,
      last_name_en: this.state.last_name_en,
      birth_date: this.state.birth_date,
      age: this.state.age,
      gender: this.state.gender ? this.state.gender.value : "",
      evidence_type: this.state.evidence_type ? Number(this.state.evidence_type.value) : "",
      document_type_number: this.state.document_type_number,
      job_position: this.state.job_position ? this.state.job_position.value : "",

      profile_image: this.state.profile_image,
      identity_document: this.state.identity_document,
      evidence_allowed_document: this.state.evidence_allowed_document,

      profile_image_type: this.state.profile_image_type ? this.state.profile_image_type : "",
      identity_document_type: this.state.identity_document_type ? this.state.identity_document_type : "",
      evidence_allowed_document_type: this.state.evidence_allowed_document_type ? this.state.evidence_allowed_document_type : "",

      // Passport
      passport_number: this.state.passport_number,
      passport_place: this.state.passport_place,
      passport_born_place: this.state.passport_born_place,
      passport_country: this.state.passport_country ? this.state.passport_country.value : "",
      passport_allowed_date: this.state.passport_allowed_date,
      passport_expiry_date: this.state.passport_expiry_date,
      passport_image: this.state.passport_image,

      // Visa
      visa_number: this.state.visa_number,
      visa_allowed_location: this.state.visa_allowed_location ? this.state.visa_allowed_location.value : "",
      visa_allowed_date: this.state.visa_allowed_date,
      visa_expiry_date: this.state.visa_expiry_date,
      visa_image: this.state.visa_image,

      // Work permit
      work_pm_number: this.state.work_pm_number,
      work_pm_allowed_location: this.state.work_pm_allowed_location ? this.state.work_pm_allowed_location.value : "",
      work_pm_allowed_date: this.state.work_pm_allowed_date,
      work_pm_expiry_date: this.state.work_pm_expiry_date,
      work_pm_image: this.state.work_pm_image,

      // ประกันสุขภาพรัฐบาล
      health_number: this.state.health_number,
      health_hospital_name: this.state.health_hospital_name,
      health_allowed_date: this.state.health_allowed_date,
      health_expiry_date: this.state.health_expiry_date,
      health_image: this.state.health_image,

      // ประกันภัยเอกชน
      insurance_name: this.state.insurance_name ? this.state.insurance_name.value : "",
      insurance_number: this.state.insurance_number,
      insurance_allowed_date: this.state.insurance_allowed_date,
      insurance_expiry_date: this.state.insurance_expiry_date,
      insurance_image: this.state.insurance_image,

      // ประกันสังคม
      sso_number: this.state.sso_number,
      sso_image: this.state.sso_image,

      // ตรวจโรค
      hospital_name: this.state.hospital_name,
      inspec_date: this.state.inspec_date,
      diagnose_image: this.state.diagnose_image,

      // ประกันภัย
      company_name: this.state.company_name,
      policy_number: this.state.policy_number,
      allowed_stay_date: this.state.allowed_stay_date,
      expiry_date: this.state.expiry_date,
      policy_image: this.state.policy_image,
    };
    let result = await POST(tokens, "api/foreign-worker/create", body);
    if (result && result.status) {
      success_url("/setting/SendDocument/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      insurance_type: this.state.insurance_type,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      province: this.state.province ? this.state.province.value : "",
      place_work: this.state.place_work ? this.state.place_work.value : "",
      agent: this.state.agent ? this.state.agent.value : "",
      phone: this.state.phone,
      email: this.state.email,

      fw_number: this.state.fw_number,
      nationality: this.state.nationality ? Number(this.state.nationality.value) : "",
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      first_name_th: this.state.first_name_th,
      middle_name_th: this.state.middle_name_th,
      last_name_th: this.state.last_name_th,
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      first_name_en: this.state.first_name_en,
      middle_name_en: this.state.middle_name_en,
      last_name_en: this.state.last_name_en,
      birth_date: this.state.birth_date,
      age: this.state.age,
      gender: this.state.gender ? this.state.gender.value : "",
      evidence_type: this.state.evidence_type ? Number(this.state.evidence_type.value) : "",
      document_type_number: this.state.document_type_number,
      job_position: this.state.job_position ? this.state.job_position.value : "",

      profile_image: this.state.profile_image,
      identity_document: this.state.identity_document,
      evidence_allowed_document: this.state.evidence_allowed_document,

      profile_image_type: this.state.profile_image_type ? this.state.profile_image_type : "",
      identity_document_type: this.state.identity_document_type ? this.state.identity_document_type : "",
      evidence_allowed_document_type: this.state.evidence_allowed_document_type ? this.state.evidence_allowed_document_type : "",

      // Passport
      passport_number: this.state.passport_number,
      passport_place: this.state.passport_place,
      passport_born_place: this.state.passport_born_place,
      passport_country: this.state.passport_country ? this.state.passport_country.value : "",
      passport_allowed_date: this.state.passport_allowed_date,
      passport_expiry_date: this.state.passport_expiry_date,
      passport_image: this.state.passport_image,

      // Visa
      visa_number: this.state.visa_number,
      visa_allowed_location: this.state.visa_allowed_location ? this.state.visa_allowed_location.value : "",
      visa_allowed_date: this.state.visa_allowed_date,
      visa_expiry_date: this.state.visa_expiry_date,
      visa_image: this.state.visa_image,

      // Work permit
      work_pm_number: this.state.work_pm_number,
      work_pm_allowed_location: this.state.work_pm_allowed_location ? this.state.work_pm_allowed_location.value : "",
      work_pm_allowed_date: this.state.work_pm_allowed_date,
      work_pm_expiry_date: this.state.work_pm_expiry_date,
      work_pm_image: this.state.work_pm_image,

      // ประกันสุขภาพรัฐบาล
      health_number: this.state.health_number,
      health_hospital_name: this.state.health_hospital_name,
      health_allowed_date: this.state.health_allowed_date,
      health_expiry_date: this.state.health_expiry_date,
      health_image: this.state.health_image,

      // ประกันภัยเอกชน
      insurance_name: this.state.insurance_name ? this.state.insurance_name.value : "",
      insurance_number: this.state.insurance_number,
      insurance_allowed_date: this.state.insurance_allowed_date,
      insurance_expiry_date: this.state.insurance_expiry_date,
      insurance_image: this.state.insurance_image,

      // ประกันสังคม
      sso_number: this.state.sso_number,
      sso_image: this.state.sso_image,

      // ตรวจโรค
      hospital_name: this.state.hospital_name,
      inspec_date: this.state.inspec_date,
      diagnose_image: this.state.diagnose_image,

      // ประกันภัย
      company_name: this.state.company_name,
      policy_number: this.state.policy_number,
      allowed_stay_date: this.state.allowed_stay_date,
      expiry_date: this.state.expiry_date,
      policy_image: this.state.policy_image,
    };
    let result = await PUT(tokens, "api/foreign-worker/update/" + this.state.fw_id, body);
    if (result && result.status) {
      success_url("/setting/SendDocument/list");
    }
    this.setState({ loading: false });
  };
  CreateWorkplace = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone_create,
      email: this.state.email_create,
    };
    let result = await POST(tokens, "api/place-work/create", body);
    if (result && result.status) {
      success();
      this.setState({
        address_number: "",
        moo: "",
        soi: "",
        street: "",
        province: "",
        district: "",
        sub_district: "",
        zipcode: "",
        phone_create: "",
        email_create: "",
        modal_create: false,
      });
      setTimeout(() => {
        this.GetWorkplace(this.state.emp_id);
      }, 1);
    }
    this.setState({ loading: false });
  };
  CreateAgent = async () => {
    this.setState({ loading: true });
    let body = {
      agent_name: this.state.agent_name,
      agent_phone: this.state.agent_phone,
      agent_email: this.state.agent_email,
    };
    let result = await POST(tokens, "api/agent/create", body);
    if (result && result.status) {
      success();
      this.setState({
        agent_name: "",
        agent_phone: "",
        agent_email: "",
        modal_agent_create: false,
      });
      setTimeout(() => {
        this.GetAgent();
      }, 1);
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    await this.changePhoto(e.target.files[0], type);
    if (type === "profile_image") {
      document.getElementById("file_profile_image").value = "";
      document.getElementById("file_profile_image").file = null;
    } else if (type === "identity_document") {
      document.getElementById("file_identity_document").value = "";
      document.getElementById("file_identity_document").file = null;
    } else if (type === "evidence_allowed_document") {
      document.getElementById("file_evidence_allowed_document").value = "";
      document.getElementById("file_evidence_allowed_document").file = null;
    } else if (type === "passport_image") {
      document.getElementById("file_passport_image").value = "";
      document.getElementById("file_passport_image").file = null;
    } else if (type === "visa_image") {
      document.getElementById("file_visa_image").value = "";
      document.getElementById("file_visa_image").file = null;
    } else if (type === "work_pm_image") {
      document.getElementById("file_work_pm_image").value = "";
      document.getElementById("file_work_pm_image").file = null;
    } else if (type === "health_image") {
      document.getElementById("file_health_image").value = "";
      document.getElementById("file_health_image").file = null;
    } else if (type === "insurance_image") {
      document.getElementById("file_insurance_image").value = "";
      document.getElementById("file_insurance_image").file = null;
    } else if (type === "sso_image") {
      document.getElementById("file_sso_image").value = "";
      document.getElementById("file_sso_image").file = null;
    } else if (type === "diagnose_image") {
      document.getElementById("file_diagnose_image").value = "";
      document.getElementById("file_diagnose_image").file = null;
    } else if (type === "policy_image") {
      document.getElementById("file_policy_image").value = "";
      document.getElementById("file_policy_image").file = null;
    }
  };
  changePhoto = async (file, type) => {
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("pdf") && !file.type.includes("image")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (type === "profile_image") {
        this.setState({
          profile_image: e.target.result.split(",")[1],
          profile_image_type: file.type.split("/")[1],
          profile_image_date: new Date(),
        });
      } else if (type === "identity_document") {
        this.setState({
          identity_document: e.target.result.split(",")[1],
          identity_document_type: file.type.split("/")[1],
          identity_document_date: new Date(),
        });
      } else if (type === "evidence_allowed_document") {
        this.setState({
          evidence_allowed_document: e.target.result.split(",")[1],
          evidence_allowed_document_type: file.type.split("/")[1],
          evidence_allowed_document_date: new Date(),
        });
      } else if (type === "passport_image") {
        this.setState({
          passport_image: e.target.result.split(",")[1],
        });
      } else if (type === "visa_image") {
        this.setState({
          visa_image: e.target.result.split(",")[1],
        });
      } else if (type === "work_pm_image") {
        this.setState({
          work_pm_image: e.target.result.split(",")[1],
        });
      } else if (type === "health_image") {
        this.setState({
          health_image: e.target.result.split(",")[1],
        });
      } else if (type === "insurance_image") {
        this.setState({
          insurance_image: e.target.result.split(",")[1],
        });
      } else if (type === "sso_image") {
        this.setState({
          sso_image: e.target.result.split(",")[1],
        });
      } else if (type === "diagnose_image") {
        this.setState({
          diagnose_image: e.target.result.split(",")[1],
        });
      } else if (type === "policy_image") {
        this.setState({
          policy_image: e.target.result.split(",")[1],
        });
      }
    };
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ทะเบียนต่างด้าว" page3="เพิ่มลูกจ้าง" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>เพิ่มลูกจ้าง</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>ค้นหานายจ้าง</b>
                  </h5>
                  <hr />
                  <div className="w-100 card border-0 p-0">
                    <div className="d-flex align-items-center mx-0">
                      <span className="me-2">เลขประจำตัวนายจ้าง</span>
                      <input
                        className="form-control wpx-250 bg-contain border-0 mx-1"
                        type="text"
                        placeholder="กรอกข้อมูล"
                        onChange={(e) => {
                          this.setState({ data_search: e.target.value });
                        }}
                        value={this.state.data_search}
                      />
                      <button
                        className="btn btn-primary mx-1 px-4"
                        onClick={() => {
                          this.GetUser();
                        }}
                      >
                        ค้นหา
                      </button>
                    </div>
                  </div>
                  <hr />
                  <h5 className="mb-3">
                    <b>ข้อมูลนายจ้าง</b>
                  </h5>
                  <div className="row">
                    <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่ประจำตัวนายจ้าง</span>
                      <span>: {this.state.emp_number ? this.state.emp_number : ""}</span>
                    </div>
                    <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อนายจ้าง</span>
                      <span>: {this.state.emp_full_name ? this.state.emp_full_name : ""}</span>
                    </div>
                    <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทกิจการ</span>
                      <span>: {this.state.business_type ? this.state.business_type.label : ""}</span>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่ตั้ง</span>
                      <span>
                        : {this.state.emp_address_number ? this.state.emp_address_number : ""}
                        {this.state.emp_moo ? " หมู่ " + this.state.emp_moo : ""}
                        {this.state.emp_soi ? " ซอย " + this.state.emp_soi : ""}
                        {this.state.emp_street ? " ถนน " + this.state.emp_street : ""}
                        {this.state.emp_sub_district ? " ตำบล " + this.state.emp_sub_district : ""}
                        {this.state.emp_district ? " อำเภอ " + this.state.emp_district : ""}
                        {this.state.emp_province ? " จังหวัด" + this.state.emp_province : ""}
                        {this.state.emp_zipcode ? " รหัสไปรษณีย์ " + this.state.emp_zipcode : ""}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <h5 className="mb-3">
                    <b>เลือกสถานที่ทำงานของลูกจ้าง</b>
                  </h5>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่ทำงาน{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_workplace}
                        onChange={(e) => {
                          this.setState({ place_work: e });
                        }}
                        value={this.state.place_work}
                      ></Select>
                    </div>
                    {this.state.emp_number && (
                      <div className="col-12 col-md-6">
                        <button
                          className="btn btn-primary px-4"
                          onClick={() => {
                            this.setState({
                              emp_id: this.state.emp_id,
                              address_number: "",
                              moo: "",
                              soi: "",
                              street: "",
                              province: "",
                              district: "",
                              sub_district: "",
                              zipcode: "",
                              phone_create: "",
                              email_create: "",
                              modal_create: true,
                            });
                          }}
                        >
                          + เพิ่มสถานที่ทำงานใหม่
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ตัวแทน{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_agent}
                        onChange={(e) => {
                          this.setState({ agent: e });
                        }}
                        value={this.state.agent}
                      ></Select>
                    </div>
                    {this.state.emp_number && (
                      <div className="col-12 col-md-6">
                        <button
                          className="btn btn-primary px-4"
                          onClick={() => {
                            this.setState({
                              agent_name: "",
                              agent_phone: "",
                              agent_email: "",
                              modal_agent_create: true,
                            });
                          }}
                        >
                          + เพิ่มตัวแทน
                        </button>
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-3 mx-auto">
                      {this.state.profile_image && this.state.profile_image.includes("://") && <img src={this.state.profile_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                      {this.state.profile_image && !this.state.profile_image.includes("://") && <img src={"data:application/png;base64," + this.state.profile_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                    </div>
                  </div>
                  <h5 className="mb-3">
                    <b>กรอกข้อมูลลูกจ้าง</b>
                  </h5>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขบัตรประจำตัว{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        maxLength={13}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ fw_number: e.target.value });
                        }}
                        value={this.state.fw_number}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สัญชาติ{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_nationality}
                        onChange={(e) => {
                          this.setState({ nationality: e });
                        }}
                        value={this.state.nationality}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">คำนำหน้า(ไทย){required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_prefix_th}
                        onChange={(e) => {
                          this.setState({ prefix_th: e });
                        }}
                        value={this.state.prefix_th}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อต้น(ไทย){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ first_name_th: e.target.value });
                        }}
                        value={this.state.first_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อกลาง(ไทย)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ middle_name_th: e.target.value });
                        }}
                        value={this.state.middle_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">นามสกุล(ไทย)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ last_name_th: e.target.value });
                        }}
                        value={this.state.last_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">คำนำหน้า(Eng){required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_prefix_en}
                        onChange={(e) => {
                          this.setState({ prefix_en: e });
                        }}
                        value={this.state.prefix_en}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อต้น(Eng){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ first_name_en: e.target.value });
                        }}
                        value={this.state.first_name_en}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อกลาง(Eng)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ middle_name_en: e.target.value });
                        }}
                        value={this.state.middle_name_en}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">นามสกุล(Eng)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ last_name_en: e.target.value });
                        }}
                        value={this.state.last_name_en}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันเกิด{required}</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ birth_date: e });
                        }}
                        value={this.state.birth_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">อายุ</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ age: e.target.value });
                        }}
                        value={this.state.age}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เพศ</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_gender}
                        onChange={(e) => {
                          this.setState({ gender: e });
                        }}
                        value={this.state.gender}
                      ></Select>
                    </div>
                    {/* <div className="col-12 col-md-6"></div>
                                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">ประเภทหลักฐาน</span>
                                            <input
                                                className="form-control mt-1"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({ evidence_type: e.target.value })
                                                }}
                                                value={this.state.evidence_type}
                                            />
                                        </div> */}
                    {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">หมายเลขหลักฐาน</span>
                                            <input
                                                className="form-control mt-1"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({ document_type_number: e.target.value })
                                                }}
                                                value={this.state.document_type_number}
                                            />
                                        </div> */}
                    <div className="d-flex align-items-center justify-content-between my-3">
                      <span>
                        <span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF,JPG,PNG ขนาดไม่เกิน 3 MB เท่านั้น)
                      </span>
                    </div>
                    <div className="table-responsive mb-2">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-center text-white">รายการ</th>
                            <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                            <th className="text-center text-white">ดำเนินการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">1</td>
                            <td className="text-start">รูปถ่ายของลูกจ้าง (รูปถ่ายหน้าตรง ไม่สวมหมวก ไม่ถือสิ่งของ เห็นหน้าชัดเจนและพื้นหลังต้องเป็นสีขาวเท่านั้น) {required}</td>
                            <td className="text-center">{this.state.profile_image_date ? format_date(this.state.profile_image_date, "dd mmm yyyy") + " เวลา " + format_date(this.state.profile_image_date, "hh:mm") + " น." : ""}</td>
                            <td className="text-center">
                              <input
                                className="d-none"
                                type="file"
                                id="file_profile_image"
                                accept="image/*"
                                onChange={(e) => {
                                  this.import_file(e, "profile_image");
                                }}
                              />
                              {!this.state.profile_image ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  onClick={() => {
                                    document.getElementById("file_profile_image").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-center">
                                  {this.state.profile_image && this.state.profile_image.includes("://") && <img src={this.state.profile_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                                  {this.state.profile_image && !this.state.profile_image.includes("://") && <img src={"data:application/png;base64," + this.state.profile_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                                  {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                                        onClick={() => { this.setState({ modal_detail: true, attachment_name: "profile_image" }) }}>
                                                                        <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                                    </div> */}
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      this.setState({ modal_delete: true, attachment_name: "profile_image" });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                          {/* <tr>
                                                        <td className="text-center">2</td>
                                                        <td className="text-start">หลักฐานประจำตัวลูกจ้าง {required}</td>
                                                        <td className="text-center">{this.state.identity_document_date ? format_date(this.state.identity_document_date, "dd mmm yyyy") + " เวลา " + format_date(this.state.identity_document_date, "hh:mm") + " น." : ""}</td>
                                                        <td className="text-center">
                                                            <input className="d-none" type="file" id="file_identity_document" accept="application/pdf,image/*" onChange={(e) => { this.import_file(e, "identity_document") }} />
                                                            {this.state.identity_document === "" ? (
                                                                <button className="btn btn-primary mx-1 px-4" onClick={() => { document.getElementById("file_identity_document").click() }}>
                                                                    แนบไฟล์
                                                                </button>
                                                            ) : (
                                                                <div className="w-100 d-flex justify-content-center">
                                                                    <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                                        onClick={() => { this.setState({ modal_detail: true, attachment_name: "identity_document" }) }}>
                                                                        <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                                    </div>
                                                                    <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                                        onClick={() => { this.setState({ modal_delete: true, attachment_name: "identity_document" }) }}>
                                                                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">3</td>
                                                        <td className="text-start">สำเนาหลักฐานการอนุญาตให้เข้ามาในราชอาณาจักร ทุกหน้าที่มีตราประทับ</td>
                                                        <td className="text-center">{this.state.evidence_allowed_document_date ? format_date(this.state.evidence_allowed_document_date, "dd mmm yyyy") + " เวลา " + format_date(this.state.evidence_allowed_document_date, "hh:mm") + " น." : ""}</td>
                                                        <td className="text-center">
                                                            <input className="d-none" type="file" id="file_evidence_allowed_document" accept="application/pdf,image/*" onChange={(e) => { this.import_file(e, "evidence_allowed_document") }} />
                                                            {this.state.evidence_allowed_document === "" ? (
                                                                <button className="btn btn-primary mx-1 px-4" onClick={() => { document.getElementById("file_evidence_allowed_document").click() }}>
                                                                    แนบไฟล์
                                                                </button>
                                                            ) : (
                                                                <div className="w-100 d-flex justify-content-center">
                                                                    <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                                        onClick={() => { this.setState({ modal_detail: true, attachment_name: "evidence_allowed_document" }) }}>
                                                                        <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                                    </div>
                                                                    <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                                        onClick={() => { this.setState({ modal_delete: true, attachment_name: "evidence_allowed_document" }) }}>
                                                                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr> */}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="d-flex text-warning">
                                            <span>หมายเหตุ :</span>
                                            <span>กรณีเลือกเวียดนาม ระบบบังคับให้แนบเอกสาร ดังนี้<br />
                                                - สำเนาหนังสือเดินทาง เฉพาะหน้าที่เห็นภาพใบหน้า ชื่อ-นามสกุล วันเดือนปีเกิดและสัญชาติ (หน้าแรก) โดยหนังสือเดินทางต้องมีอายุไม่น้อยกว่าวันที่ 13 กุมภาพันธ์ 2566<br />
                                                - สำเนาหลักฐานการอุญาตให้เข้ามาในราชอาณาจักร ที่มีตราประทับขาเข้าดวงล่าสุด
                                            </span>
                                        </div> */}

                    <hr />
                    {/* Passport */}
                    <h5 className="mb-3">
                      <b>Passport</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ passport_number: e.target.value });
                        }}
                        value={this.state.passport_number}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่ออก</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ passport_place: e.target.value });
                        }}
                        value={this.state.passport_place}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่เกิด</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ passport_born_place: e.target.value });
                        }}
                        value={this.state.passport_born_place}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเทศ</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_country}
                        onChange={(e) => {
                          this.setState({ passport_country: e });
                        }}
                        value={this.state.passport_country}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ออก</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ passport_allowed_date: e });
                        }}
                        value={this.state.passport_allowed_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่หมด</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ passport_expiry_date: e });
                        }}
                        value={this.state.passport_expiry_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {this.state.passport_image === "" ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_passport_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            {this.state.passport_image && this.state.passport_image.includes("://") && <img src={this.state.passport_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                            {this.state.passport_image && !this.state.passport_image.includes("://") && <img src={"data:application/png;base64," + this.state.passport_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                            {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => { this.setState({ modal_detail: true, attachment_name: "passport_image" }) }}>
                                                            <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                        </div> */}
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_delete: true, attachment_name: "passport_image" });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          id="file_passport_image"
                          accept="image/*"
                          onChange={(e) => {
                            this.import_file(e, "passport_image");
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    {/* Visa */}
                    <h5 className="mb-3">
                      <b>Visa</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ visa_number: e.target.value });
                        }}
                        value={this.state.visa_number}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่ออก</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={province.map((item) => {
                          return { value: item.province_name, label: item.province_name };
                        })}
                        onChange={(e) => {
                          this.setState({ visa_allowed_location: e });
                        }}
                        value={this.state.visa_allowed_location}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ออก</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ visa_allowed_date: e });
                        }}
                        value={this.state.visa_allowed_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่หมด</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ visa_expiry_date: e });
                        }}
                        value={this.state.visa_expiry_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {this.state.visa_image === "" ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_visa_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            {this.state.visa_image && this.state.visa_image.includes("://") && <img src={this.state.visa_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                            {this.state.visa_image && !this.state.visa_image.includes("://") && <img src={"data:application/png;base64," + this.state.visa_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                            {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => { this.setState({ modal_detail: true, attachment_name: "visa_image" }) }}>
                                                            <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                        </div> */}
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_delete: true, attachment_name: "visa_image" });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          id="file_visa_image"
                          accept="image/*"
                          onChange={(e) => {
                            this.import_file(e, "visa_image");
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    {/* Work permit */}
                    <h5 className="mb-3">
                      <b>Work permit</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ work_pm_number: e.target.value });
                        }}
                        value={this.state.work_pm_number}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่ออก</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={province.map((item) => {
                          return { value: item.province_name, label: item.province_name };
                        })}
                        onChange={(e) => {
                          this.setState({ work_pm_allowed_location: e });
                        }}
                        value={this.state.work_pm_allowed_location}
                      ></Select>
                    </div>

                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ออก</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ work_pm_allowed_date: e });
                        }}
                        value={this.state.work_pm_allowed_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่หมด</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ work_pm_expiry_date: e });
                        }}
                        value={this.state.work_pm_expiry_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {this.state.work_pm_image === "" ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_work_pm_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            {this.state.work_pm_image && this.state.work_pm_image.includes("://") && <img src={this.state.work_pm_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                            {this.state.work_pm_image && !this.state.work_pm_image.includes("://") && <img src={"data:application/png;base64," + this.state.work_pm_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                            {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => { this.setState({ modal_detail: true, attachment_name: "work_pm_image" }) }}>
                                                            <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                        </div> */}
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_delete: true, attachment_name: "work_pm_image" });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          id="file_work_pm_image"
                          accept="image/*"
                          onChange={(e) => {
                            this.import_file(e, "work_pm_image");
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    {/* ตรวจโรค */}
                    <h5 className="mb-3">
                      <b>ตรวจโรค</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อโรงพยาบาล</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ hospital_name: e.target.value });
                        }}
                        value={this.state.hospital_name}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ตรวจ</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ inspec_date: e });
                        }}
                        value={this.state.inspec_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {this.state.diagnose_image === "" ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_diagnose_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            {this.state.diagnose_image && this.state.diagnose_image.includes("://") && <img src={this.state.diagnose_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                            {this.state.diagnose_image && !this.state.diagnose_image.includes("://") && <img src={"data:application/png;base64," + this.state.diagnose_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                            {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => { this.setState({ modal_detail: true, attachment_name: "diagnose_image" }) }}>
                                                            <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                        </div> */}
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_delete: true, attachment_name: "diagnose_image" });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          id="file_diagnose_image"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "diagnose_image");
                          }}
                        />
                      </div>
                    </div>
                    <hr />
                    {/* เลือกประกัน */}
                    <div className="d-flex align-items-center mb-3">
                      <h5 className="wpx-150">
                        <b>ประกัน</b>
                      </h5>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({
                              insurance_type: 1,
                              health_number: "",
                              health_hospital_name: "",
                              health_allowed_date: "",
                              health_expiry_date: "",
                              health_image: "",
                            });
                          }}
                          checked={this.state.insurance_type === 1}
                        />
                        <span className="ms-2">ประกันสุขภาพรัฐ</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({
                              insurance_type: 2,
                              insurance_name: "",
                              insurance_number: "",
                              insurance_allowed_date: "",
                              insurance_expiry_date: "",
                              insurance_image: "",
                            });
                          }}
                          checked={this.state.insurance_type === 2}
                        />
                        <span className="ms-2">ประกันภัยเอกชน</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({
                              insurance_type: 3,
                              sso_number: "",
                              sso_image: "",
                            });
                          }}
                          checked={this.state.insurance_type === 3}
                        />
                        <span className="ms-2">ประกันสังคม</span>
                      </div>
                    </div>
                    {/* ประกันสุขภาพรัฐ */}
                    {this.state.insurance_type === 1 && (
                      <>
                        {/* <h5 className="mb-3"><b>ประกันสุขภาพรัฐ</b></h5> */}
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขกรมธรรม์</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ health_number: e.target.value });
                            }}
                            value={this.state.health_number}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อโรงพยาบาล</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ health_hospital_name: e.target.value });
                            }}
                            value={this.state.health_hospital_name}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออก</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ health_allowed_date: e });
                            }}
                            value={this.state.health_allowed_date}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมด</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ health_expiry_date: e });
                            }}
                            value={this.state.health_expiry_date}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {this.state.health_image === "" ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_health_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                {this.state.health_image && this.state.health_image.includes("://") && <img src={this.state.health_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                                {this.state.health_image && !this.state.health_image.includes("://") && <img src={"data:application/png;base64," + this.state.health_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                                {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                                onClick={() => { this.setState({ modal_detail: true, attachment_name: "health_image" }) }}>
                                                                <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                            </div> */}
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "health_image" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file_health_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "health_image");
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* ประกันภัยเอกชน */}
                    {this.state.insurance_type === 2 && (
                      <>
                        {/* <h5 className="mb-3"><b>ประกันภัยเอกชน</b></h5> */}
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อบริษัท</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_insurance}
                            onChange={(e) => {
                              this.setState({ insurance_name: e });
                            }}
                            value={this.state.insurance_name}
                          ></Select>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขกรมธรรม์</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ insurance_number: e.target.value });
                            }}
                            value={this.state.insurance_number}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออก</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ insurance_allowed_date: e });
                            }}
                            value={this.state.insurance_allowed_date}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมด</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ insurance_expiry_date: e });
                            }}
                            value={this.state.insurance_expiry_date}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {this.state.insurance_image === "" ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_insurance_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                {this.state.insurance_image && this.state.insurance_image.includes("://") && <img src={this.state.insurance_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                                {this.state.insurance_image && !this.state.insurance_image.includes("://") && <img src={"data:application/png;base64," + this.state.insurance_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                                {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                                onClick={() => { this.setState({ modal_detail: true, attachment_name: "insurance_image" }) }}>
                                                                <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                            </div> */}
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "insurance_image" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file_insurance_image"
                              accept="image/*"
                              onChange={(e) => {
                                this.import_file(e, "insurance_image");
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* ประกันสังคม */}
                    {this.state.insurance_type === 3 && (
                      <>
                        {/* <h5 className="mb-3"><b>ประกันสังคม</b></h5> */}
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขประกันสังคม</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ sso_number: e.target.value });
                            }}
                            value={this.state.sso_number}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {this.state.sso_image === "" ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_sso_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                {this.state.sso_image && this.state.sso_image.includes("://") && <img src={this.state.sso_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}
                                {this.state.sso_image && !this.state.sso_image.includes("://") && <img src={"data:application/png;base64," + this.state.sso_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />}

                                {/* <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                                onClick={() => { this.setState({ modal_detail: true, attachment_name: "sso_image" }) }}>
                                                                <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                            </div> */}
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "sso_image" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file_sso_image"
                              accept="image/*"
                              onChange={(e) => {
                                this.import_file(e, "sso_image");
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <hr />
                    {/* ประกันภัย */}
                    {/* <h5 className="mb-3"><b>ประกันภัย</b></h5>
                                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">ชื่อบริษัท</span>
                                            <input
                                                className="form-control mt-1"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({ company_name: e.target.value })
                                                }}
                                                value={this.state.company_name}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">เลขกรมธรรม์</span>
                                            <input
                                                className="form-control mt-1"
                                                type="text"
                                                onChange={(e) => {
                                                    this.setState({ policy_number: e.target.value })
                                                }}
                                                value={this.state.policy_number}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">วันที่ออก</span>
                                            <DatePicker
                                                className="form-control mt-1"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ allowed_stay_date: e });
                                                }}
                                                value={this.state.allowed_stay_date}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">วันที่หมด</span>
                                            <DatePicker
                                                className="form-control mt-1"
                                                placeholder="พิมพ์คำค้นหา..."
                                                onChange={(e) => {
                                                    this.setState({ expiry_date: e });
                                                }}
                                                value={this.state.expiry_date}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                                            <span className="wpx-150">แนบรูป</span>
                                            <div className="d-flex">
                                                {this.state.policy_image === "" ? (
                                                    <button className="btn btn-primary mx-1 px-4" onClick={() => { document.getElementById("file_policy_image").click() }}>
                                                        แนบไฟล์
                                                    </button>
                                                ) : (
                                                    <div className="w-100 d-flex justify-content-center">
                                                        {this.state.policy_image && this.state.policy_image.includes("://") && (<img src={this.state.policy_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />)}
                                                        {this.state.policy_image && !this.state.policy_image.includes("://") && (<img src={"data:application/png;base64," + this.state.policy_image} style={{ backgroundColor: "#cdcdcd", width: 150, height: 150, objectFit: "contain" }} className="img-resposive" />)}

                                                        <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => { this.setState({ modal_detail: true, attachment_name: "policy_image" }) }}>
                                                            <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                        </div>
                                                        <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => { this.setState({ modal_delete: true, attachment_name: "policy_image" }) }}>
                                                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                        </div>
                                                    </div>
                                                )}
                                                <input className="d-none" type="file" id="file_policy_image" accept="image/*" onChange={(e) => { this.import_file(e, "policy_image") }} />

                                            </div>
                                        </div>
                                        <hr /> */}
                  </div>
                </div>

                <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        {this.state.tab === 1 ? (
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mb-3"
                            onClick={() => {
                              window.location.href = "/setting/SendDocument/list";
                            }}
                          >
                            ยกเลิก
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mb-3"
                            onClick={() => {
                              this.setState({ tab: this.state.tab - 1 });
                            }}
                          >
                            ย้อนกลับ
                          </button>
                        )}
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (!this.state.place_work) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานที่ทำงาน");
                              return;
                            } else if (!this.state.agent) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกตัวแทน");
                              return;
                            } else if (!this.state.fw_number) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัตรประจำตัว");
                              return;
                            } else if (!this.state.nationality) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกสัญชาติ");
                              return;
                            } else if (!this.state.prefix_th) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(ไทย)");
                              return;
                            } else if (!this.state.first_name_th) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(ไทย)");
                              return;
                            } else if (!this.state.prefix_en) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(Eng)");
                              return;
                            } else if (!this.state.first_name_en) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(Eng)");
                              return;
                            } else if (!this.state.birth_date) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกวันเกิด");
                              return;
                              // } else if (!this.state.evidence_type) {
                              //     alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทหลักฐาน")
                              //     return
                              // } else if (!this.state.document_type_number) {
                              //     alert("warning", "แจ้งเตือน", "กรุณากรอกหมายเลขหลักฐาน")
                              //     return
                            } else if (!this.state.profile_image) {
                              alert("warning", "แจ้งเตือน", "กรุณาแนบรูปถ่ายของลูกจ้าง");
                              return;
                            }
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ดูข้อมูล */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>รายละเอียด</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            {/* profile_image */}
            {this.state.attachment_name === "profile_image" && this.state.profile_image.includes("://") && this.state.profile_image_type === "pdf" && <iframe src={this.state.profile_image} width="100%" height="550px"></iframe>}
            {this.state.attachment_name === "profile_image" && this.state.profile_image.includes("://") && this.state.profile_image_type !== "pdf" && <img src={this.state.profile_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "profile_image" && !this.state.profile_image.includes("://") && this.state.profile_image_type === "pdf" && <object data={"data:application/pdf;base64," + this.state.profile_image} type="application/pdf" width="100%" height="550px"></object>}
            {this.state.attachment_name === "profile_image" && !this.state.profile_image.includes("://") && this.state.profile_image_type !== "pdf" && <img src={"data:application/png;base64," + this.state.profile_image} width="100%" height="550px" className="img-resposive" />}
            {/* identity_document */}
            {this.state.attachment_name === "identity_document" && this.state.identity_document.includes("://") && this.state.identity_document_type === "pdf" && <iframe src={this.state.identity_document} width="100%" height="550px"></iframe>}
            {this.state.attachment_name === "identity_document" && this.state.identity_document.includes("://") && this.state.identity_document_type !== "pdf" && <img src={this.state.identity_document} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "identity_document" && !this.state.identity_document.includes("://") && this.state.identity_document_type === "pdf" && <object data={"data:application/pdf;base64," + this.state.identity_document} type="application/pdf" width="100%" height="550px"></object>}
            {this.state.attachment_name === "identity_document" && !this.state.identity_document.includes("://") && this.state.identity_document_type !== "pdf" && <img src={"data:application/png;base64," + this.state.identity_document} width="100%" height="550px" className="img-resposive" />}
            {/* evidence_allowed_document */}
            {this.state.attachment_name === "evidence_allowed_document" && this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type === "pdf" && <iframe src={this.state.evidence_allowed_document} width="100%" height="550px"></iframe>}
            {this.state.attachment_name === "evidence_allowed_document" && this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type !== "pdf" && <img src={this.state.evidence_allowed_document} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "evidence_allowed_document" && !this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type === "pdf" && <object data={"data:application/pdf;base64," + this.state.evidence_allowed_document} type="application/pdf" width="100%" height="550px"></object>}
            {this.state.attachment_name === "evidence_allowed_document" && !this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type !== "pdf" && <img src={"data:application/png;base64," + this.state.evidence_allowed_document} width="100%" height="550px" className="img-resposive" />}

            {this.state.attachment_name === "passport_image" && <img src={this.state.passport_image.includes("://") ? this.state.passport_image : "data:application/png;base64," + this.state.passport_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "visa_image" && <img src={this.state.visa_image.includes("://") ? this.state.visa_image : "data:application/png;base64," + this.state.visa_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "work_pm_image" && <img src={this.state.work_pm_image.includes("://") ? this.state.work_pm_image : "data:application/png;base64," + this.state.work_pm_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "health_image" && <img src={this.state.health_image.includes("://") ? this.state.health_image : "data:application/png;base64," + this.state.health_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "insurance_image" && <img src={this.state.insurance_image.includes("://") ? this.state.insurance_image : "data:application/png;base64," + this.state.insurance_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "sso_image" && <img src={this.state.sso_image.includes("://") ? this.state.sso_image : "data:application/png;base64," + this.state.sso_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "diagnose_image" && <img src={this.state.diagnose_image.includes("://") ? this.state.diagnose_image : "data:application/png;base64," + this.state.diagnose_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "policy_image" && <img src={this.state.policy_image.includes("://") ? this.state.policy_image : "data:application/png;base64," + this.state.policy_image} width="100%" height="550px" className="img-resposive" />}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-center">
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_detail: false });
                  }}
                >
                  ปิดหน้าต่าง
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_create}
          onHide={() => {
            this.setState({ modal_create: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มสถานที่ทำงานใหม่</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ address_number: e.target.value });
                  }}
                  value={this.state.address_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">หมู่ที่</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ moo: e.target.value });
                  }}
                  value={this.state.moo}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ซอย</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ soi: e.target.value });
                  }}
                  value={this.state.soi}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ถนน{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ street: e.target.value });
                  }}
                  value={this.state.street}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">จังหวัด{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={province.map((item) => {
                    return { value: item.province_name, label: item.province_name };
                  })}
                  onChange={(e) => {
                    this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150"> อำเภอ / เขต{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                  styles={select_style}
                  options={district
                    .filter((item) => this.state.province && this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.district_name, label: item.district_name };
                    })}
                  onChange={(e) => {
                    this.setState({ district: e, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.district}
                  isDisabled={!this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ตำบล / แขวง{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                  styles={select_style}
                  options={subdistrict
                    .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&& this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.sub_district_name, label: item.sub_district_name, zip_code: item.zip_code };
                    })}
                  onChange={(e) => {
                    this.setState({ sub_district: e, zipcode: e.zip_code.toString() });
                  }}
                  value={this.state.sub_district}
                  isDisabled={!this.state.district}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => {
                    this.setState({ zipcode: e.target.value });
                  }}
                  value={this.state.zipcode}
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ phone_create: e.target.value });
                  }}
                  value={this.state.phone_create}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ email_create: e.target.value });
                  }}
                  value={this.state.email_create}
                />
              </div>
            </div>
            {/* <hr />
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <span><span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)</span>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table-striped">
                                <thead className="bg-primary">
                                    <tr>
                                        <th className="text-center text-white">ลำดับ</th>
                                        <th className="text-center text-white">รายการ</th>
                                        <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                                        <th className="text-center text-white">ดำเนินการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td className="text-start">หลักฐานการเพิ่มสถานที่ทำงานใหม่และแผนที่ เช่น<br />
                                            - สำเนาทะเบียนบ้าน หรือ<br />
                                            - สำเนาเช่าสำนักงาน/ร้านค้า/โรงงาน หรือ<br />
                                            - สัญญาจ้างงานของโครงการก่อสร้าง หรือ<br />
                                            - สำเนาโฉนดที่ดิน {required}</td>
                                        <td className="text-center">{format_date(new Date(), "dd mmm yyyy")} เวลา {format_date(new Date(), "hh:mm")} น.</td>
                                        <td className="text-center">
                                            <button className="btn btn-primary mx-1 px-4" onClick={() => { }}>
                                                แนบไฟล์
                                            </button>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_detail: true, attachment_id: "", attachment_name: "", attachment_file: "" }) }}>
                                                    <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                </div>
                                                <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_delete: true, attachment_id: "", attachment_name: "" }) }}>
                                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span className="d-flex align-items-center">หมายเหตุ :
                            <div className="ms-3 me-2 wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-primary p-2">{"\uf15c"}</label>
                            </div>
                            แสดงเอกสารแนบ
                            <div className="ms-5 me-2 wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                            ลบเอกสาร
                        </span> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.address_number === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                      return;
                    } else if (this.state.street === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกถนน");
                      return;
                    } else if (!this.state.province) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                      return;
                    } else if (!this.state.district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                      return;
                    } else if (!this.state.sub_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                      return;
                    } else if (this.state.zipcode === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                      return;
                    } else if (this.state.phone_create === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์");
                      return;
                    } else if (this.state.phone_create.length < 9) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน");
                      return;
                    }
                    this.CreateWorkplace();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มตัวแทน */}
        <Modal
          show={this.state.modal_agent_create}
          onHide={() => {
            this.setState({ modal_agent_create: false });
          }}
          size="md"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มตัวแทน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ agent_name: e.target.value });
                  }}
                  value={this.state.agent_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ agent_phone: e.target.value });
                  }}
                  value={this.state.agent_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล{required}</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ agent_email: e.target.value });
                  }}
                  value={this.state.agent_email}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_agent_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.agent_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.agent_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.agent_email === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                      return;
                    }
                    this.CreateAgent();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบเอกสารแนบ</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบเอกสารแนบ “{this.state.attachment_name === "profile_image" ? "รูปถ่ายของลูกจ้าง" : this.state.attachment_name === "identity_document" ? "หลักฐานประจำตัวลูกจ้าง" : this.state.attachment_name === "evidence_allowed_document" ? "สำเนาหลักฐานการอนุญาตให้เข้ามาในราชอาณาจักร ทุกหน้าที่มีตราประทับ" : "รูปภาพ"}” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    if (this.state.attachment_name === "profile_image") {
                      this.setState({ profile_image: "", profile_image_type: "", profile_image_date: "", modal_delete: false });
                    } else if (this.state.attachment_name === "identity_document") {
                      this.setState({ identity_document: "", identity_document_type: "", identity_document_date: "", modal_delete: false });
                    } else if (this.state.attachment_name === "evidence_allowed_document") {
                      this.setState({ evidence_allowed_document: "", evidence_allowed_document_type: "", evidence_allowed_document_date: "", modal_delete: false });
                    } else if (this.state.attachment_name === "passport_image") {
                      this.setState({ passport_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "visa_image") {
                      this.setState({ visa_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "work_pm_image") {
                      this.setState({ work_pm_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "health_image") {
                      this.setState({ health_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "insurance_image") {
                      this.setState({ insurance_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "sso_image") {
                      this.setState({ sso_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "diagnose_image") {
                      this.setState({ diagnose_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "policy_image") {
                      this.setState({ policy_image: "", modal_delete: false });
                    }
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";

import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  format_date_excel,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  days_between,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_import: false,
      modal_detail: false,
      modal_delete: false,

      modal_update_wp: false,
      tab: 1,
      loading: false,

      data_business_type: [],
      data_work_type: [],
      data_prefix_th: [],
      data_prefix_en: [],
      // ข้อมูลนายจ้าง
      emp_id: "",
      employer_id: "",
      emp_user_type: 0,
      emp_type: 0,
      emp_number: "",
      prefix_th: "",
      prefix_en: "",
      register_capital: "",
      register_date: "",
      id_card: "",
      place_issue: "",
      issue_date: "",
      expiry_date: "",
      contact_name: "",
      full_name_th: "",
      full_name_en: "",
      business_type: "",
      work_type: "",
      business_description: "",
      address_number: "",
      moo: "",
      soi: "",
      street: "",
      province: "",
      district: "",
      sub_district: "",
      zipcode: "",
      phone: "",
      email: "",
      fax: "",
      mobile_phone: "",
      home_number: "",
      phone_2: "",
      fax_2: "",
      binding_authority_th: "",
      binding_authority_en: "",
      position_th: "",
      position_en: "",
      operator_type: 0,
      deputize_number: "",
      deputize_full_name: "",
      deputize_phone: "",
      username: "",
      password: "",
      confirm_password: "",

      // ไฟล์แนบ
      copy_company_certificate: "",
      copy_company_certificate_date: "",
      copy_power_attorney: "",
      copy_power_attorney_date: "",
      copy_tax_certificate: "",
      copy_tax_certificate_date: "",

      confirm_policy: false,

      // ผู้ประสานงาน
      coordinator_name: "",
      coordinator_phone: "",
      coordinator_email: "",
      modal_coordinator_create: false,
      modal_coordinator_update: false,
      modal_coordinator_delete: false,
      index: 0,

      phone_create: "",
      email_create: "",
      modal_create: false,
      data_workplace: [],
      data_workplace2: [],
      data_coordinator: [],

      data_deputize: [],
      attachment_file: "",

      copy_idcard_image: "",
      copy_idcard_type: "",

      data_request: [],
      fw_data: [],
      work_pm_number: "",
      data_search_2: "",
      id: "",
      place_work: "",
      receive_id: "",

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2: "",
      witness_contract_1: "",
      witness_contract_2: "",

      reason: "ประกาศรับสมัครงานแล้วไม่มีคนไทยมาสมัครงาน",
      // emp_period_y:"",
      // emp_period_m:"",
      // emp_period_d:"",
      // contract_until_date:"",

      copy_idcard_image2: "",
      attachment_file2: "",

      // ไฟล์
      import: null,
      currentSheet: null,
      data_import: null,

      data_immigration_office: [],
    };
  }
  async componentDidMount() {
    await this.GetImmigration();
    await this.GetBusinessType();
    await this.GetWorkType();
    this.setState({ loading: true });
    await this.GetPrefix();
    await this.GetAdmin();
    if (this.props.location.query.id) {
      await this.Get(this.props.location.query.id);
    }
    if (this.props.location.query.type === "name_list") {
      this.GetRequest();
    }
    if (this.props.location.query.receive_id) {
      await this.GetFW(this.props.location.query.receive_id);
    }
    this.setState({ loading: false });
  }
  GetImmigration = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_immigration_office: result.data.map((e) => {
          return { value: e.id.toString(), label: e.imm_name_th };
        }),
      });
    }
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetAdmin = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_admin: result.data,
      });
    }
  };
  Get = async (id) => {
    let body = {
      data_search: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/employer/list/page/0", body);
    if (result && result.status) {
      let item = result.data;

      this.setState({
        id: item.emp_id,
        emp_id: item.emp_id,
        emp_user_type: item.emp_user_type,
        emp_type: item.emp_type,
        emp_number: item.emp_number,
        prefix_th: item.prefix_th ? this.state.data_prefix_th.filter((e) => e.value === item.prefix_th.toString())[0] : "",
        prefix_en: item.prefix_en ? this.state.data_prefix_en.filter((e) => e.value === item.prefix_en.toString())[0] : "",
        register_capital: item.register_capital,
        register_date: item.register_date,
        id_card: item.id_card,
        place_issue: item.place_issue,
        issue_date: item.issue_date,
        expiry_date: item.expiry_date,
        contact_name: item.contact_name,
        full_name_th: item.full_name_th,
        full_name_en: item.full_name_en,
        business_type: item.business_type ? this.state.data_business_type.filter((e) => e.value === item.business_type.toString())[0] : "",
        work_type: item.work_type ? this.state.data_work_type.filter((e) => e.value === item.work_type.toString())[0] : "",
        business_description: item.business_description,
        address_number: item.address_number,
        moo: item.moo,
        soi: item.soi,
        street: item.street,
        province: { value: item.province, label: item.province, district: item.district },
        district: { value: item.district, label: item.district, sub_district: item.sub_district },
        sub_district: { value: item.sub_district, label: item.sub_district, zipcode: item.zipcode },
        zipcode: item.zipcode,
        phone: item.phone,
        email: item.email,
        fax: item.fax,
        mobile_phone: item.mobile_phone,
        home_number: item.home_number,
        phone_2: item.phone_2,
        fax_2: item.fax_2,
        binding_authority_th: item.binding_authority_th,
        binding_authority_en: item.binding_authority_en,
        position_th: item.position_th,
        position_en: item.position_en,
        data_workplace: item.pw_data,
        data_coordinator: item.coordinator_data,
        username: item.username,
        data_deputize: item.dpz_data ? item.dpz_data : [],
        copy_idcard_image: item.copy_idcard_image,
        fw_data: item.fw_data,
        reason: item.reason,
        // emp_period_y:item.emp_period_y,
        // emp_period_m:item.emp_period_m,
        // emp_period_d:item.emp_period_d,
        // contract_until_date:item.contract_until_date,
      });
    }
  };
  GetFW = async (id) => {
    let body = {
      data_search: "",
      receive_id: id,
      menu: "MOU",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      let item = result.data;
      let place_work = "";
      if (item.place_work) {
        place_work = item.pw_data.filter((e) => e.pw_id === item.place_work)[0];
      }
      this.setState({
        id: item.emp_data.emp_id,
        emp_id: item.emp_data.emp_id,
        emp_user_type: item.emp_data.emp_user_type,
        emp_type: item.emp_data.emp_type,
        emp_number: item.emp_data.emp_number,
        prefix_th: item.emp_data.prefix_th ? this.state.data_prefix_th.filter((e) => e.value === item.emp_data.prefix_th.toString())[0] : "",
        prefix_en: item.emp_data.prefix_en ? this.state.data_prefix_en.filter((e) => e.value === item.emp_data.prefix_en.toString())[0] : "",
        register_capital: item.emp_data.register_capital,
        register_date: item.emp_data.register_date,
        id_card: item.emp_data.id_card,
        place_issue: item.emp_data.place_issue,
        issue_date: item.emp_data.issue_date,
        expiry_date: item.emp_data.expiry_date,
        contact_name: item.emp_data.contact_name,
        full_name_th: item.emp_data.full_name_th,
        full_name_en: item.emp_data.full_name_en,
        business_type: item.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === item.emp_data.business_type.toString())[0] : "",
        work_type: item.work_type ? this.state.data_work_type.filter((e) => e.value === item.work_type.toString())[0] : "",
        business_description: item.emp_data.business_description,
        address_number: item.emp_data.address_number,
        moo: item.emp_data.moo,
        soi: item.emp_data.soi,
        street: item.emp_data.street,
        province: { value: item.emp_data.province, label: item.emp_data.province, district: item.emp_data.district },
        district: { value: item.emp_data.district, label: item.emp_data.district, sub_district: item.emp_data.sub_district },
        sub_district: { value: item.emp_data.sub_district, label: item.emp_data.sub_district, zipcode: item.emp_data.zipcode },
        zipcode: item.emp_data.zipcode,
        phone: item.emp_data.phone,
        email: item.emp_data.email,
        fax: item.emp_data.fax,
        mobile_phone: item.emp_data.mobile_phone,
        home_number: item.emp_data.home_number,
        phone_2: item.emp_data.phone_2,
        fax_2: item.emp_data.fax_2,
        binding_authority_th: item.emp_data.binding_authority_th,
        binding_authority_en: item.emp_data.binding_authority_en,
        position_th: item.emp_data.position_th,
        position_en: item.emp_data.position_en,
        data_workplace: item.emp_data.pw_data,
        data_coordinator: item.emp_data.coordinator_data,
        username: item.emp_data.username,
        data_deputize: item.emp_data.dpz_data ? item.emp_data.dpz_data : [],
        copy_idcard_image: item.emp_data.copy_idcard_image,
        fw_data: item.fw_data,
        place_work: place_work ? (place_work.address_number ? place_work.address_number : "") + (place_work.moo ? " หมู่ " + place_work.moo : "") + (place_work.soi ? " ซอย " + place_work.soi : "") + (place_work.street ? " ถนน " + place_work.street : "") + (place_work.sub_district ? " ตำบล " + place_work.sub_district : "") + (place_work.district ? " อำเภอ " + place_work.district : "") + (place_work.province ? " จังหวัด " + place_work.province : "") + (place_work.zipcode ? " รหัสไปรษณีย์ " + place_work.zipcode : "") : "",

        // พยาน
        witness_power_1: item.witness_power_1 ? this.state.data_admin.filter((e) => e.user_id === item.witness_power_1)[0] : "",
        witness_power_2_th: item.witness_power_2_th ? this.state.data_admin.filter((e) => e.user_id === item.witness_power_2_th)[0] : "",
        witness_power_2_en: "",
        witness_contract_1: item.witness_contract_1 ? this.state.data_admin.filter((e) => e.user_id === item.witness_contract_1)[0] : "",
        witness_contract_2_th: item.witness_contract_2_th,
        witness_contract_2_en: item.witness_contract_2_en,
        receive_id: id,
      });
    }
  };
  GetRequest = async () => {
    let body = {
      data_search: "",
      fw_id: "",
    };
    let result = await GET(tokens, "api/foreign-worker/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_request: result.data.filter((e) => e.emp_data.emp_id === this.props.location.query.id),
      });
    }
  };
  GetUser = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
    };
    let result = await GET(tokens, "api/employer/department-trade", body);
    if (result && result.status) {
      this.setState({
        emp_type: result.data.emp_type,
        emp_number: result.data.emp_number,
        full_name_th: result.data.full_name_th,
        address_number: result.data.address_number,
        moo: result.data.moo,
        soi: result.data.soi,
        street: result.data.street,
        province: result.data.province
          ? province
              .map((item) => {
                return { value: item.province_name, label: item.province_name };
              })
              .filter((item) => item.value === result.data.province)[0]
          : "",
        district: result.data.district
          ? district
              .map((item) => {
                return { value: item.district_name, label: item.district_name };
              })
              .filter((item) => item.value === result.data.district)[0]
          : "",
        sub_district: result.data.sub_district
          ? subdistrict
              .map((item) => {
                return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
              })
              .filter((item) => item.value === result.data.sub_district)[0]
          : "",
        zipcode: result.data.sub_district
          ? subdistrict
              .map((item) => {
                return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
              })
              .filter((item) => item.value === result.data.sub_district)[0].zipcode
          : "",
      });
    }
    this.setState({ loading: false });
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace2: result.data,
      });
    }
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      register_capital: this.state.register_capital,
      register_date: this.state.register_date,
      id_card: this.state.id_card,
      place_issue: this.state.place_issue,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      contact_name: this.state.contact_name,
      full_name_th: this.state.full_name_th,
      full_name_en: this.state.full_name_en,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      work_type: this.state.work_type ? Number(this.state.work_type.value) : "",
      business_description: this.state.business_description,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone,
      email: this.state.email,
      fax: this.state.fax,
      mobile_phone: this.state.mobile_phone,
      home_number: this.state.home_number,
      phone_2: this.state.phone_2,
      fax_2: this.state.fax_2,
      binding_authority_th: this.state.binding_authority_th,
      binding_authority_en: this.state.binding_authority_en,
      position_th: this.state.position_th,
      position_en: this.state.position_en,
      data_deputize: this.state.data_deputize,
      username: this.state.emp_number,
      password: this.state.emp_number,
      data_coordinator: this.state.data_coordinator,
      data_workplace: this.state.data_workplace,
      copy_idcard_image: this.state.copy_idcard_image,
      reason: this.state.reason,
      // emp_period_y:this.state.emp_period_y,
      // emp_period_m:this.state.emp_period_m,
      // emp_period_d:this.state.emp_period_d,
      // contract_until_date:this.state.contract_until_date,
    }; 
    let result = await POST(tokens, "api/employer/create", body);
    if (result && result.status) {
      success_url("/Customer/list") 
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      register_capital: this.state.register_capital,
      register_date: this.state.register_date,
      id_card: this.state.id_card,
      place_issue: this.state.place_issue,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      contact_name: this.state.contact_name,
      full_name_th: this.state.full_name_th,
      full_name_en: this.state.full_name_en,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      work_type: this.state.work_type ? Number(this.state.work_type.value) : "",
      business_description: this.state.business_description,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone,
      email: this.state.email,
      fax: this.state.fax,
      mobile_phone: this.state.mobile_phone,
      home_number: this.state.home_number,
      phone_2: this.state.phone_2,
      fax_2: this.state.fax_2,
      binding_authority_th: this.state.binding_authority_th,
      binding_authority_en: this.state.binding_authority_en,
      position_th: this.state.position_th,
      position_en: this.state.position_en,
      // operator_type: this.state.operator_type,
      // deputize_number: this.state.deputize_number,
      // deputize_full_name: this.state.deputize_full_name,
      // username: this.state.emp_number,
      // password: this.state.password,
      data_deputize: this.state.data_deputize,
      data_coordinator: this.state.data_coordinator,
      data_workplace: this.state.data_workplace,
      copy_idcard_image: this.state.copy_idcard_image,
      reason: this.state.reason,
      // emp_period_y:this.state.emp_period_y,
      // emp_period_m:this.state.emp_period_m,
      // emp_period_d:this.state.emp_period_d,
      // contract_until_date:this.state.contract_until_date,
    };
    let result = await PUT(tokens, "api/employer/update/" + this.state.id, body);
    if (result && result.status) {
      success_url("/Customer/list")
    }
    this.setState({ loading: false });
  }; 
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ทะเบียนนายจ้าง" page3="สร้างบัญชีระบบ" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
            <div className="p-3">
              {/* หัวข้อ และ ปุ่มเพิ่ม */}
              <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
                <h3 className="mb-3">
                  <b>สร้างบัญชีระบบ</b>
                </h3>
              </div>
              <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
                <div className="p-3">
                  <div className="w-100">
                    <h5 className="mb-3">
                      <b>ข้อมูลบัญชี</b>
                    </h5> 
                    <hr />
                    <div className="d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภท{required}</span>
                      <div className="d-flex align-items-center wpx-220">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 4, data_deputize: [], data_coordinator: [], register_date: "", register_capital: 0 });
                          }}
                          checked={this.state.emp_type === 4}
                        />
                        <span className="ms-2">บริษัทนำเข้าแรงงานต่างด้าว</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 5, copy_idcard_image: "", copy_idcard_type: "", data_coordinator: [], prefix_th: "", prefix_en: "" });
                          }}
                          checked={this.state.emp_type === 5}
                        />
                        <span className="ms-2">นายหน้า/ตัวแทน</span>
                      </div>
                      <div className="d-flex align-items-center wpx-180">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 6, copy_idcard_image: "", copy_idcard_type: "", data_coordinator: [], prefix_th: "", prefix_en: "" });
                          }}
                          checked={this.state.emp_type === 6}
                        />
                        <span className="ms-2">โรงงาน/ผู้ประกอบการ</span>
                      </div>
                    </div>   
                    <div className="row mt-3"> 
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อบัญชี{required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ full_name_th: e.target.value });
                          }}
                          value={this.state.full_name_th}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลขที่ผู้เสียภาษี{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขที่ผู้เสียภาษี"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div> 
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรศัพท์มือถือ</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ mobile_phone: e.target.value });
                          }}
                          value={this.state.mobile_phone}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">อีเมล</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          value={this.state.email}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ address_number: e.target.value });
                          }}
                          value={this.state.address_number}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมู่ที่</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ moo: e.target.value });
                          }}
                          value={this.state.moo}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ซอย</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ soi: e.target.value });
                          }}
                          value={this.state.soi}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ถนน</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ street: e.target.value });
                          }}
                          value={this.state.street}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จังหวัด{required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={province.map((item) => {
                            return { value: item.province_name, label: item.province_name };
                          })}
                          onChange={(e) => {
                            this.setState({ province: e, district: null, sub_district: null, zipcode: "" });
                          }}
                          value={this.state.province}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150"> อำเภอ / เขต{required}</span>
                        <Select
                          className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                          styles={select_style}
                          options={district
                            .filter((item) => this.state.province && this.state.province.value === item.province_name)
                            .map((item) => {
                              return { value: item.district_name, label: item.district_name };
                            })}
                          onChange={(e) => {
                            this.setState({ district: e, sub_district: null, zipcode: "" });
                          }}
                          value={this.state.district}
                          isDisabled={!this.state.province}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ตำบล / แขวง{required}</span>
                        <Select
                          className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                          styles={select_style}
                          options={subdistrict
                            .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province && this.state.province.value === item.province_name)
                            .map((item) => {
                              return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
                            })}
                          onChange={(e) => {
                            this.setState({ sub_district: e, zipcode: e.zipcode.toString() });
                          }}
                          value={this.state.sub_district}
                          isDisabled={!this.state.district}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="รหัสไปรษณีย์"
                          onChange={(e) => {
                            this.setState({ zipcode: e.target.value });
                          }}
                          value={this.state.zipcode}
                          disabled
                        />
                      </div> 
                    </div>  
                    <hr />
                    <h5 className="mb-1">
                      <b>ข้อมูลผู้ติดต่อ</b>
                    </h5>
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อผู้ติดต่อ</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ contact_name: e.target.value });
                          }}
                          value={this.state.contact_name}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลขรหัสประจำบ้าน ๑๑ หลัก</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ home_number: e.target.value });
                          }}
                          value={this.state.home_number}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรศัพท์</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ phone_2: e.target.value });
                          }}
                          value={this.state.phone_2}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรสาร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ fax_2: e.target.value });
                          }}
                          value={this.state.fax_2}
                        />
                      </div>
                    </div>
                    <hr /> 
                  </div>
                  <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                    <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                      <div className="d-flex mt-5">
                        <div className="w-50 pe-1">
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mb-3"
                            onClick={() => {
                              window.location.href = "/Customer/list";
                            }}
                          >
                            ยกเลิก
                          </button>
                        </div>
                        <div className="w-50 ps-1">
                          <button
                            type="button"
                            className="btn btn-primary w-100 mb-3"
                            onClick={() => {
                              if (this.state.emp_type === 0) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภท");
                                return;
                              }  else if (this.state.full_name_th === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                                return;
                              }  else if (this.state.address_number === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                                return;
                              } else if (!this.state.province) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                                return;
                              } else if (!this.state.district) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                                return;
                              } else if (!this.state.sub_district) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                                return;
                              } else if (this.state.zipcode === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                                return;
                              }   
                              if (this.props.location.query.id) {
                                this.Update();
                              } else {
                                this.Create();
                              }
                            }}
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
        </div> 
      </div>
    );
  }
}

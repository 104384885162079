import React, { Component } from "react";
import Navbar from "../../components/Navbar.js"; 
import { 
  tokens, 
  // alert 
  success_url,
  alert, 
  // function 
  format_date, 
  toFixed,
  total, 
  float,
  // api
  GET, 
  PUT, 
  convert_imagetopdf, 
  // component
  DatePicker,
} from "../../components/CustomComponent.js"; 

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_import: false,
      modal_detail: false,
      modal_delete: false,

      modal_update_wp: false,
      tab: 1,
      loading: false,

      id: "",
      billing_note_code: "",
      invoice_list: [],

      cash_status: false,
      transfer_status: false,
      check_status: false,
      total_price: "",
      cash_price: "",
      transfer_price: "",
      check_price: "",
      payment_date: "",
      payment_file: "",
      account_number: "",
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    if (this.props.location.query.id) {
      await this.Get(this.props.location.query.id);
    }
    this.setState({ loading: false });
  }
  Get = async (id) => {
    let body = {
      data_search: "",
      billing_note_id: id,
    };
    let result = await GET(tokens, "api/billing-note/list/page/0", body);
    if (result && result.status) {
      console.log(result.data);
      this.setState({
        id: id,
        invoice_list: JSON.parse(result.data.invoice_list),
        total_price: total(JSON.parse(result.data.invoice_list), "total_price"), 
        cash_price: result.data.cash_price??"",
        cash_status: result.data.cash_price ? true : false,
        transfer_price: result.data.transfer_price??"",
        transfer_status: result.data.transfer_price ? true : false,
        check_price: result.data.check_price??"",
        account_number: result.data.account_number??"",
        check_status: result.data.check_price ? true : false,
        payment_date: result.data.payment_date?result.data.payment_date.split("T")[0]:format_date(new Date(), "yyyy-mm-dd", 'en'),
        payment_file: result.data.payment_file,
      });
    }
  };
  Update = async () => {
    if(Number(this.state.cash_price)+Number(this.state.transfer_price)+Number(this.state.check_price) < this.state.total_price){
      alert("warning","แจ้งเตือน","กรุณากรอกยอดชำระรวมให้เท่ากับยอดเงินรวมทั้งหมด")
      return
    }
    this.setState({ loading: true });
    let body = {
      cash_price: Number(this.state.cash_price),
      transfer_price: Number(this.state.transfer_price),
      check_price: Number(this.state.check_price),
      account_number: this.state.account_number,
      payment_date: this.state.payment_date,
      payment_file: this.state.payment_file,
      status: 2
    };
    let result = await PUT(tokens, "api/billing-note/update/" + this.state.id, body);
    if (result && result.status) {
      success_url("/Receipt/list")
    }
    this.setState({ loading: false });
  };

  import_file = async (e) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let files = null;
        if (file.type.includes("pdf")) {
          files = e.target.result.split(",")[1];
        }else{
          files = await convert_imagetopdf(e.target.result);
        }
          this.setState({ payment_file: files });
      };
    
      document.getElementById("file").value = "";
      document.getElementById("file").file = null;
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ใบแจ้งหนี้" page3="Billing Note" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>{this.props.location.query.receive_code ? "Billing Note " + this.props.location.query.receive_code : "Billing Note"} </b>
              </h3>
            </div>

            <h5>
              <b>Billing Note</b>
            </h5>
            <hr />
            <div>
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-center text-white">#</th>
                    <th className="text-left text-white">วันที่สร้าง</th>
                    <th className="text-left text-white">เลขที่ใบแจ้งหนี้</th>
                    <th className="text-left text-white">เลขเรื่อง</th>
                    <th className="text-left text-white">ลูกค้า</th>
                    <th className="text-left text-white">บริการ</th>
                    <th className="text-left text-white">ค่าบริการ</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoice_list &&
                    this.state.invoice_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">งวดที่ {index + 1}</td>
                        <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                        <td className="text-left">{item.invoice_code ? item.invoice_code : "-"}</td>
                        <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                        <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                        <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                        <td className="text-left">{item.total_price ? toFixed(item.total_price) : "-"}</td>
                      </tr>
                    ))}
                  <tr>
                    <td className="text-center"></td>
                    <td className="text-left"></td>
                    <td className="text-left"></td>
                    <td className="text-left"></td>
                    <td className="text-left"></td>
                    <td className="text-left">ยอดรวมทั้งหมด</td>
                    <td className="text-left">{toFixed(total(this.state.invoice_list, "total_price"))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5>
              <b>การรับเงิน</b>
            </h5>
            <hr />
            <div className="row">
              <div className="mb-3 col-12 col-md-12 d-flex">
                <label className="wpx-180">
                  <b>จำนวนเงิน</b>
                </label>
                <input
                  type="text"
                  className="form-control wpx-200"
                  value={this.state.total_price}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ total_price: e.target.value });
                  }}
                  disabled
                />
              </div>
              <div className="mb-3 col-12 col-md-12 d-flex">
                <label className="wpx-180">
                  <b>วันที่</b>
                </label>
                <DatePicker
                  className="form-control wpx-200"
                  placeholder="พิมพ์คำค้นหา..."
                  onChange={(e) => {
                    this.setState({ payment_date: e });
                  }}
                  value={this.state.payment_date}
                />
                {/* <input
                  type="date"
                  className="form-control wpx-200"
                  value={this.state.payment_date}
                  onChange={(e) => {
                    this.setState({ payment_date: e.target.value });
                  }} 
                /> */}
              </div>
              <div className="mb-3 col-12 col-md-12">
                <label className="wpx-180 mb-3">
                  <b>วิธีรับเงิน</b>
                </label>
                <div className="d-flex mb-3">
                  <div className="wpx-150">
                    <input
                      type="checkbox"
                      className="mx-3"
                      checked={this.state.cash_status}
                      onChange={() => {
                        this.setState({ cash_status: !this.state.cash_status, cash_price: "" });
                      }}
                    />{" "}
                    เงินสด
                  </div>
                  <input
                    type="text"
                    className="form-control wpx-200"
                    value={this.state.cash_price}
                    onChange={(e) => {
                      if (!float(e.target.value)) {
                        return;
                      }
                      let total = Number(this.state.transfer_price) + Number(this.state.check_price)
                      let price = 0;
                      if(this.state.total_price<Number(e.target.value)+total){
                        price = this.state.total_price - total;
                      }else{
                        price = e.target.value;
                      }
                      this.setState({ cash_price: price });
                    }}
                    disabled={!this.state.cash_status}
                  />
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-150">
                    <input
                      type="checkbox"
                      className="mx-3"
                      checked={this.state.transfer_status}
                      onChange={() => {
                        this.setState({ transfer_status: !this.state.transfer_status, transfer_price: "", account_number: "" });
                      }}
                    />{" "}
                    รับเงินโอน
                  </div>
                  <input
                    type="text"
                    className="form-control wpx-200"
                    value={this.state.transfer_price}
                    onChange={(e) => {
                      if (!float(e.target.value)) {
                        return;
                      }
                      let total = Number(this.state.cash_price) + Number(this.state.check_price)
                      let price = 0;
                      if(this.state.total_price<Number(e.target.value)+total){
                        price = this.state.total_price - total;
                      }else{
                        price = e.target.value;
                      }
                      this.setState({ transfer_price: price });
                    }}
                    disabled={!this.state.transfer_status}
                  />
                  <input
                    type="text"
                    className="form-control wpx-200 mx-2"
                    value={this.state.account_number}
                    placeholder="เลขที่บัญชี"
                    onChange={(e) => {
                      if (!float(e.target.value)) {
                        return;
                      }
                      this.setState({ account_number: e.target.value });
                    }}
                    disabled={!this.state.transfer_status}
                  />
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-150">
                    <input
                      type="checkbox"
                      className="mx-3"
                      checked={this.state.check_status}
                      onChange={() => {
                        this.setState({ check_status: !this.state.check_status, check_price: "" });
                      }}
                    />{" "}
                    เช็ค
                  </div>
                  <input
                    type="text"
                    className="form-control wpx-200"
                    value={this.state.check_price}
                    onChange={(e) => {
                      if (!float(e.target.value)) {
                        return;
                      }
                      let total = Number(this.state.cash_price) + Number(this.state.transfer_price)
                      let price = 0;
                      if(this.state.total_price<Number(e.target.value)+total){
                        price = this.state.total_price - total;
                      }else{
                        price = e.target.value;
                      }
                      this.setState({ check_price: price });
                    }}
                    disabled={!this.state.check_status}
                  />
                </div>
              </div>
              <div className="mb-3 col-12 col-md-12 d-flex">
                <label className="wpx-180">
                  <b>แนบไฟล์</b>
                </label>
                <div className="d-flex">
                            {!this.state.payment_file ? (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  document.getElementById("file").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.payment_file.includes("://") ? this.state.payment_file :  "data:application/pdf;base64,"+this.state.payment_file} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({payment_file:"" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e);
                              }}
                            />
                          </div>
              </div>
            </div>
            <hr />
            <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
              <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                <div className="d-flex mt-5">
                  <div className="w-50 pe-1">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 mb-3"
                      onClick={() => {
                        window.location.href = "/BillingNote/list";
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                 
                  <div className="w-50 ps-1">
                    <button
                      type="button"
                      className="btn btn-primary w-100 mb-3"
                      onClick={() => {
                          this.Update();
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar.js";
import Pagination from "@mui/material/Pagination";
import Select from "react-select";
import { read, writeFileXLSX } from "xlsx";
import * as xlsx from "xlsx";
import dayjs from "dayjs";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaFileUpload } from "react-icons/fa";
import * as randomstring from "randomstring";

import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

function UploadJob() {
  // const [workValue, setWorkValue] = useState(10);
  // const [visaValue, setVisaValue] = useState(10);
  // const [servicValue, setServiceValue] = useState(10);
  // const [daysValue, setDaysValue] = useState(10);

  // const [dateStart, setDateStart] = useState("");
  // const [dateEnd, setDateEnd] = useState("");

  // const [fileDays, setFileDays] = useState();
  // const [fileDaysJson, setFileDaysJson] = useState();
  // const [fileVisa, setFileVisa] = useState();
  // const [fileVisaJson, setFileVisaJson] = useState();

  const [listJobType, setListJobType] = useState([]);
  const [jobType, setJobType] = useState({ value: "", value: "" });

  const [listCustomer, setListCustomer] = useState([]);
  const [customer, setCustomer] = useState({ value: "", value: "" });
  const [finding, setFinding] = useState({ value: "", value: "" });
  const [listJob, setListJob] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Job_Visa_Day_merge = async (customer_id, date_start, date_end) => {
    let body = {
      customer_id: customer_id,
      date_start: date_start,
      date_end: date_end,
    };

    let result = await GET(tokens, "api/urgent/Job_Visa_Day_merge", body);
    if (result.data != null) {
      return result.data;
    } else {
      return [];
    }
  };

  const getAllEmployers = async () => {
    let result = await GET(tokens, "api/urgent/Employers");
    if (result.data != null) {
      setListCustomer(
        result.data.map((d) => ({
          value: d.emp_id,
          label: d.full_name_th,
        }))
      );
    }
  };

  const getAllJobList = async (customer_id) => {
    let body = {
      customer_id: customer_id,
    };

    let result = await GET(tokens, "api/urgent/Job_List_select", []);
    if (result.data != null) {
      // setListJobType(result.data);
      setListJob(result.data);
    }
  };

  const Job_records_insert = async (body) => {
    let result = await POST(tokens, "api/urgent/Job_records_insert", body);
    if (result.data != null) {
      // setListJobType(result.data);
      // setListJob(result.data)
    }
  };

  const Job_report_select = async (customer_id, date_start, date_end) => {
    let body = {
      customer_id: customer_id,
      date_start: date_start,
      date_end: date_end,
    };

    let result = await GET(tokens, "api/urgent/Job_report_select", body);
    if (result.data != null) {
      return result.data;
    }
  };

  const job_day_insert = async (body) => {
    let result = await POST(tokens, "api/urgent/Job_Day_insert", body);
    if (result.data != null) {
    }
  };

  const job_visa_insert = async (body) => {
    let result = await POST(tokens, "api/urgent/Job_Visa_insert", body);
    if (result.data != null) {
    }
  };

  const excel2json = (e) => {
    var ListData = [];
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        return json;

        // setFileDaysJson(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      return reader;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    getAllEmployers();
    getAllJobList();
  }, []);
  return (
    <div className="w-100 min-vh-100 fade-in">
      {/* {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)} */}
      <Navbar page1="หน้าหลัก" page2="อัพโหลดไฟล์" />
      {/* BODY */}
      <div className="body bg-light min-vh-100">
        <div className="p-3">
          {/* หัวข้อ และ ปุ่มเพิ่ม */}
          <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
            <h3 className="mb-3">
              <b>สร้างงานต่อ 90 วัน และ ต่อ Visa</b>
            </h3>
            <div className="d-flex">
              {/* <button className="btn btn-secondary mx-1" onClick={() => { window.location.href = "/Employer/upload" }}>
                        อัพโหลดไฟล์นายจ้าง
                    </button> */}
              {/* <button className="btn btn-primary mx-1 update" onClick={() => { window.location.href = "/Employer/create" }}>
                        <label className="icon">{"\u002B"}</label> เพิ่มนายจ้าง
                    </button> */}
            </div>
          </div>

          <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
            <div className="p-3">
              <div className="w-100">
                {/* <h5 className="mb-3">
                  <b>อัพโหลดไฟล์ </b>
                </h5>
                <hr /> */}

                <div className="d-flex justify-content-between">
                  <div className="col-10 col-md-10 d-flex align-items-center mb-3">
                    <span className="wpx-150">เลือกชื่อลูกค้า {required}</span>
                    <Select
                      className="form-control select-search wpx-350"
                      styles={select_style}
                      options={listCustomer}
                      onChange={(e) => {
                        setCustomer(e);

                        // getAllJobList(e.value);
                      }}
                      value={customer}
                    ></Select>

                    <button
                      className="btn btn-primary mx-1 wpx-150"
                      onClick={() => {
                        // setCustomer({ value: "", label: "" });
                        setFinding(customer);
                      }}
                    >
                      ค้นหา
                    </button>

                    <button
                      className="btn btn-primary mx-1 wpx-150"
                      onClick={() => {
                        setFinding({ value: "", label: "" });
                      }}
                    >
                      รีเซต
                    </button>
                  </div>

                  <div className="col-2 col-md-2 d-flex align-items-center mb-3">
                    <button
                      className="btn btn-secondary mx-1 wpx-150"
                      onClick={() => {
                        // setCustomer({value:"", label:""})

                        window.location.href = "/urgent/create";
                      }}
                    >
                      เพิ่ม
                    </button>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                    {/* <span className="wpx-150">รายการ {required}</span> */}
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-center text-white">ชื่อลูกค้า</th>
                            <th className="text-center text-white">ประเภทของงาน</th>
                            <th className="text-center text-white">ค่า Work</th>
                            <th className="text-center text-white">ค่า Visa</th>
                            <th className="text-center text-white">ค่า 90 วัน</th>
                            <th className="text-center text-white">ค่าบริการ</th>
                            <th className="text-center text-white">วันที่เริ่ม</th>
                            <th className="text-center text-white">วันที่สิ้นสุด</th>

                            <th className="text-center text-white">ไฟล์ 90 วัน</th>

                            <th className="text-center text-white">ไฟล์ Visa</th>
                            <th className="text-center text-white">ไฟล์(Visa+Day)</th>
                            <th className="text-center text-white">ไฟล์ upload</th>
                            <th className="text-center text-white update">จัดการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listJob &&
                            listJob
                              .filter((data) => data.customer_id === finding.value || finding.value === "")
                              .map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-left">{item.full_name_th}</td>
                                  <td className="text-left">{item.job_item}</td>
                                  <td className="text-left">{item.work_value}</td>
                                  <td className="text-left">{item.visa_value}</td>
                                  <td className="text-left">{item.service_value}</td>
                                  <td className="text-left">{item.days_value}</td>
                                  <td className="text-left">{dayjs(item.date_start).format("YYYY-MM-DD")}</td>
                                  <td className="text-left">{dayjs(item.date_end).format("YYYY-MM-DD")}</td>
                                  <td className="text-center">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {
                                        let body = {
                                          customer_id: item.customer_id,
                                          date_start: dayjs(item.date_start).format("YYYY-MM-DD"),
                                          date_end: dayjs(item.date_end).format("YYYY-MM-DD"),
                                        };

                                        let result = await POST(tokens, "api/urgent/Job_Day_select", body);

                                        const new_excel_json = result.data.map((data, index) => {
                                          return {
                                            ["No."]: index + 1,
                                            ["NAME AND SURNAME"]: data.name,
                                            ["รหัสพนักงาน"]: data.emp_code,
                                            ["NATIONALITY"]: data.nationality,
                                            ["PASSPORT NO."]: data.passport_no,
                                            ["DATE OF ARRIVAL"]: data.date_arival,
                                            ["VISA NO."]: data.visa_no,
                                            ["TYPE OF VISA"]: data.type_visa,
                                            ["EXPIRE DATE OF STAY"]: data.expire_date,
                                            ["POINT OF ENTRY"]: data.point_entry,
                                            ["ARRIVAL CARD TM NO."]: data.arrival_card,
                                            ["EMPLOYER"]: data.employer,
                                            ["ADDRESS"]: data.address,
                                            ["REPORT TO 90 DAY"]: data.report_day,
                                          };
                                        });

                                        if (new_excel_json.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_json);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_days" + dayjs(item.dateStart).format("YYYY-MM-DD") + " " + dayjs(item.dateEnd).format("YYYY-MM-DD") + ".xlsx");
                                        }
                                      }}
                                    >
                                      <RiFileExcel2Line color="green" size={32} />
                                      ไฟล์ 90 วัน{" "}
                                    </span>

                                    <br />

                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {}}
                                    >
                                      <FaFileUpload
                                        color="black"
                                        size={32}
                                        onClick={() => {
                                          try {
                                            document.getElementById("file_day" + String(index)).click();
                                          } catch (error) {}
                                        }}
                                      />
                                    </span>
                                    <input
                                      className="d-none"
                                      type="file"
                                      id={"file_day" + String(index)}
                                      accept=".xlsx, .xls"
                                      onChange={(e) => {
                                        try {
                                          document.getElementById("file_name_day" + String(index)).innerHTML = e.target.files[0].name.substring(0, 10) + "...";
                                          const reader = new FileReader();
                                          reader.onload = async (e) => {
                                            const data = e.target.result;
                                            const workbook = xlsx.read(data, {
                                              type: "array",
                                            });
                                            const sheetName = workbook.SheetNames[0];
                                            const worksheet = workbook.Sheets[sheetName];
                                            const json = xlsx.utils.sheet_to_json(worksheet);

                                            const temp_json_day = json
                                              .map((value, index) => {
                                                if (value.__EMPTY_1 !== undefined && value.__EMPTY_2 !== undefined && value.__EMPTY_3 !== undefined && value.__EMPTY_4 !== undefined && value.__EMPTY_5 !== undefined && value.__EMPTY_6 !== undefined && value.__EMPTY_7 !== undefined && value.__EMPTY_8 !== undefined && value.__EMPTY_9 !== undefined && value.__EMPTY_10 !== undefined && value.__EMPTY_11 !== undefined && value.__EMPTY_12 !== undefined && value.__EMPTY_13 !== undefined) {
                                                  try {
                                                    return {
                                                      customer_id: item.customer_id,
                                                      date_start: dayjs(item.date_start).format("YYYY-MM-DD"),
                                                      date_end: dayjs(item.date_end).format("YYYY-MM-DD"),
                                                      passport_no: value.__EMPTY_4,

                                                      name: value.__EMPTY_1,
                                                      nationality: value.__EMPTY_3,
                                                      emp_code: value.__EMPTY_2,
                                                      date_arival: value.__EMPTY_5,
                                                      visa_no: value.__EMPTY_6,

                                                      type_visa: value.__EMPTY_7,
                                                      expire_date: value.__EMPTY_8,
                                                      point_entry: value.__EMPTY_9,
                                                      arrival_card: value.__EMPTY_10,
                                                      employer: value.__EMPTY_11,

                                                      address: value.__EMPTY_12,
                                                      report_day: value.__EMPTY_13,
                                                    };
                                                  } catch (error) {
                                                    return;
                                                  }
                                                }
                                              })
                                              .filter(function (element) {
                                                return element !== undefined;
                                              });

                                            await job_day_insert(temp_json_day);

                                            // setFileDaysJson(json);
                                          };
                                          reader.readAsArrayBuffer(e.target.files[0]);
                                        } catch (error) {}
                                      }}
                                    />
                                    <br />

                                    <div id={"file_name_day" + String(index)}></div>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {
                                        let body = {
                                          customer_id: item.customer_id,
                                          date_start: dayjs(item.date_start).format("YYYY-MM-DD"),
                                          date_end: dayjs(item.date_end).format("YYYY-MM-DD"),
                                        };

                                        let result = await POST(tokens, "api/urgent/Job_Visa_select", body);

                                        const new_excel_json = result.data.map((data, index) => {
                                          return {
                                            ["No."]: index + 1,
                                            ["หมายเลข Passport"]: data.passport_no,
                                            ["หมายเลข VISA"]: data.visa_no,
                                            ["ออกให้ที่"]: data.issued_at,
                                            ["ชื่อ - นามสกุล"]: data.name,
                                            ["รหัสพนักงาน"]: data.emp_code,
                                            ["สัญชาติ"]: data.nationality,
                                            ["เพศ"]: data.sex,
                                            ["ออกให้วันที่"]: data.issue_date,
                                            ["ใช้ได้ถึงวันที่"]: data.expire_date,
                                          };
                                        });

                                        if (new_excel_json.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_json);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_visa" + dayjs(item.dateStart).format("YYYY-MM-DD") + " " + dayjs(item.dateEnd).format("YYYY-MM-DD") + ".xlsx");
                                        }
                                      }}
                                    >
                                      <RiFileExcel2Line color="green" size={32} />
                                      ไฟล์ Visa{" "}
                                    </span>

                                    <br />

                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {}}
                                    >
                                      <FaFileUpload
                                        color="black"
                                        size={32}
                                        onClick={() => {
                                          try {
                                            document.getElementById("file_visa" + String(index)).click();
                                          } catch (error) {}
                                        }}
                                      />
                                    </span>
                                    <input
                                      className="d-none"
                                      type="file"
                                      id={"file_visa" + String(index)}
                                      accept=".xlsx, .xls"
                                      onChange={(e) => {
                                        try {
                                          document.getElementById("file_name_visa" + String(index)).innerHTML = e.target.files[0].name.substring(0, 10) + "...";

                                          const reader = new FileReader();
                                          reader.onload = async (e) => {
                                            const data = e.target.result;
                                            const workbook = xlsx.read(data, {
                                              type: "array",
                                            });
                                            const sheetName = workbook.SheetNames[0];
                                            const worksheet = workbook.Sheets[sheetName];
                                            const json = xlsx.utils.sheet_to_json(worksheet);

                                            let flag_json = 0;

                                            const temp_json_visa = json
                                              .map((value, index) => {
                                                try {
                                                  if (flag_json === 1 && value.__EMPTY_1 !== undefined && value.__EMPTY_2 !== undefined && value.__EMPTY_3 !== undefined && value.__EMPTY_4 !== undefined && value.__EMPTY_5 !== undefined && value.__EMPTY_6 !== undefined && value.__EMPTY_7 !== undefined && value.__EMPTY_8 !== undefined) {
                                                    try {
                                                      return {
                                                        customer_id: item.customer_id,
                                                        date_start: dayjs(item.date_start).format("YYYY-MM-DD"),
                                                        date_end: dayjs(item.date_end).format("YYYY-MM-DD"),
                                                        passport_no: value.__EMPTY,

                                                        visa_no: value.__EMPTY_1,
                                                        issued_at: value.__EMPTY_2,
                                                        name: value.__EMPTY_3,
                                                        emp_code: value.__EMPTY_4,
                                                        nationality: value.__EMPTY_5,

                                                        sex: value.__EMPTY_6,
                                                        issue_date: value.__EMPTY_7,
                                                        expire_date: value.__EMPTY_8,
                                                      };
                                                    } catch (error) {
                                                      return;
                                                    }
                                                  }

                                                  if ("ออกให้ที่" == value.__EMPTY_2 && flag_json == 0) {
                                                    flag_json = 1;
                                                  }
                                                } catch (error) {}
                                              })
                                              .filter(function (element) {
                                                return element !== undefined;
                                              });

                                            await job_visa_insert(temp_json_visa);

                                            // setFileDaysJson(json);
                                          };
                                          reader.readAsArrayBuffer(e.target.files[0]);
                                        } catch (error) {}
                                      }}
                                    />
                                    <br />

                                    <div id={"file_name_visa" + String(index)}></div>
                                  </td>

                                  <td className="text-center">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {
                                        const make_excel_json = await Job_Visa_Day_merge(item.customer_id, dayjs(item.date_start).format("YYYY-MM-DD"), dayjs(item.date_end).format("YYYY-MM-DD"));

                                        const new_excel_json = make_excel_json.map((data, index) => {
                                          return {
                                            ลำดับที่: index + 1,
                                            รหัสพนักงาน: data.emp_code,
                                            ชื่อลูกจ้าง: data.name,
                                            "PASSPORT NO.": data.passport_no,
                                            "ค่า Work": data.work_value,
                                            "ค่า Visa": data.visa_value,
                                            "ค่า 90 วัน": data.days_value,
                                            ค่าบริการ: data.service_value,
                                            รวมที่ต้องชำระ: {
                                              f: "SUM(E" + (index + 2) + ":H" + (index + 2) + ")",
                                            },
                                            สถานะเอกสาร: " ",
                                            หมายเหตุ: " ",
                                          };
                                        });

                                        for (let i = 0; i < new_excel_json.length; i++) {
                                          for (var d = new Date(item.date_start); d <= new Date(item.date_end); d.setDate(d.getDate() + 1)) {
                                            new_excel_json[i] = Object.assign(new_excel_json[i], {
                                              [dayjs(new Date(d)).format("YYYY-MM-DD")]: "",
                                            });
                                          }
                                        }

                                        for (let i = 0; i < new_excel_json.length; i++) {
                                          new_excel_json[i] = Object.assign(new_excel_json[i], { ["สรุป"]: "" });
                                        }

                                        if (new_excel_json.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_json);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_" + item.full_name_th + "_" + dayjs(item.dateStart).format("YYYY-MM-DD") + " " + dayjs(item.dateEnd).format("YYYY-MM-DD") + ".xlsx");
                                        }
                                      }}
                                    >
                                      <RiFileExcel2Line color="green" size={32} />
                                      ไฟล์(Visa+Day){" "}
                                    </span>
                                  </td>

                                  <td className="text-center">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {}}
                                    >
                                      <FaFileUpload
                                        color="black"
                                        size={32}
                                        onClick={() => {
                                          try {
                                            document.getElementById("file_" + String(index)).click();
                                          } catch (error) {}
                                        }}
                                      />
                                    </span>
                                    <input
                                      className="d-none"
                                      type="file"
                                      id={"file_" + String(index)}
                                      accept=".xlsx, .xls"
                                      onChange={(e) => {
                                        try {
                                          e.preventDefault();
                                          if (e.target.files) {
                                            // setFileDays(e, "file_days")
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              const data = e.target.result;
                                              const workbook = xlsx.read(data, {
                                                type: "array",
                                              });
                                              const sheetName = workbook.SheetNames[0];
                                              const worksheet = workbook.Sheets[sheetName];
                                              const json = xlsx.utils.sheet_to_json(worksheet);
                                              // setFileDaysJson(json);

                                              const temp_json = json.map((data) => {
                                                let temp_arr = [];

                                                for (var d = new Date(item.date_start); d <= new Date(item.date_end); d.setDate(d.getDate() + 1)) {
                                                  temp_arr.push(data[dayjs(d).format("YYYY-MM-DD")]);
                                                }

                                                try {
                                                  return {
                                                    customer_id: item.customer_id,
                                                    date_start: dayjs(item.date_start).format("YYYY-MM-DD"),
                                                    date_end: dayjs(item.date_end).format("YYYY-MM-DD"),
                                                    passport_no: data["PASSPORT NO."],
                                                    name: data["ชื่อลูกจ้าง"],
                                                    emp_code: data["รหัสพนักงาน"],
                                                    work_value: data["ค่า Work"],
                                                    visa_value: data["ค่า Visa"],
                                                    service_value: data["ค่าบริการ"],
                                                    days_value: data["ค่า 90 วัน"],
                                                    summarize_value: data["ค่า Work"] + data["ค่า Visa"] + data["ค่าบริการ"] + data["ค่า 90 วัน"],
                                                    doc: data["สถานะเอกสาร"],
                                                    remark: data["หมายเหตุ"],
                                                    result: temp_arr,
                                                    summarize_date: data["สรุป"],
                                                  };
                                                } catch (error) {
                                                  return;
                                                }
                                              });
                                              Job_records_insert(temp_json);
                                            };

                                            document.getElementById("file_name" + String(index)).innerHTML = e.target.files[0].name;
                                            reader.readAsArrayBuffer(e.target.files[0]);
                                          }
                                        } catch (error) {}
                                      }}
                                    />
                                    <br />

                                    <div id={"file_name" + String(index)}></div>
                                  </td>

                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={async () => {
                                        const temp_data = await Job_report_select(item.customer_id, dayjs(item.date_start).format("YYYY-MM-DD"), dayjs(item.date_end).format("YYYY-MM-DD"));

                                        const new_excel_day_g =
                                          temp_data.day_a.length > 0
                                            ? temp_data.day_a.map((data, index) => {
                                                return {
                                                  "NO.": index + 1,
                                                  "NAME AND SURNAME": data.name,
                                                  "EMPLOYEE CODE": data.emp_code,
                                                  NATIONALITY: data.nationality,
                                                  "PASSPORT NO.": data.passport_no,
                                                  "DATE OF ARRIVAL": data.date_arival,

                                                  "VISA NO.": data.visa_no,
                                                  "TYPE OF VISA": data.type_visa,
                                                  "EXPIRE DATE OF STAY": data.expire_date,
                                                  "POINT OF ENTRY": data.point_entry,
                                                  "ARRIVAL CARD TM NO.": data.arrival_card,
                                                  ADDRESS: data.address,
                                                  EMPLOYER: data.employer,
                                                  "REPORT TO 90 DAY": data.report_day,
                                                };
                                              })
                                            : [];

                                        if (new_excel_day_g.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_day_g);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_day G.xlsx");
                                        }

                                        const new_excel_day_ng =
                                          temp_data.day_b.length > 0
                                            ? temp_data.day_b.map((data, index) => {
                                                return {
                                                  "NO.": index + 1,
                                                  "NAME AND SURNAME": data.name,
                                                  "EMPLOYEE CODE": data.emp_code,
                                                  NATIONALITY: data.nationality,
                                                  "PASSPORT NO.": data.passport_no,
                                                  "DATE OF ARRIVAL": data.date_arival,

                                                  "VISA NO.": data.visa_no,
                                                  "TYPE OF VISA": data.type_visa,
                                                  "EXPIRE DATE OF STAY": data.expire_date,
                                                  "POINT OF ENTRY": data.point_entry,
                                                  "ARRIVAL CARD TM NO.": data.arrival_card,
                                                  ADDRESS: data.address,
                                                  EMPLOYER: data.employer,
                                                  "REPORT TO 90 DAY": data.report_day,
                                                };
                                              })
                                            : [];

                                        if (new_excel_day_ng.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_day_ng);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_day NG.xlsx");
                                        }

                                        const new_excel_visa_g =
                                          temp_data.visa_a.length > 0
                                            ? temp_data.visa_a.map((data, index) => {
                                                return {
                                                  "NO.": index + 1,
                                                  "หมายเลข Passport": data.passport_no,
                                                  "หมายเลข VISA": data.visa_no,
                                                  ออกให้ที่: data.issued_at,
                                                  "ชื่อ - นามสกุล": data.name,
                                                  รหัสพนักงาน: data.emp_code,

                                                  สัญชาติ: data.nationality,
                                                  เพศ: data.sex,
                                                  ออกให้วันที่: data.issue_date,
                                                  ใช้ได้ถึงวันที่: data.expire_date,
                                                };
                                              })
                                            : [];

                                        if (new_excel_visa_g.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_visa_g);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_visa G.xlsx");
                                        }

                                        const new_excel_visa_ng =
                                          temp_data.visa_b.length > 0
                                            ? temp_data.visa_b.map((data, index) => {
                                                return {
                                                  "NO.": index + 1,
                                                  "หมายเลข Passport": data.passport_no,
                                                  "หมายเลข VISA": data.visa_no,
                                                  ออกให้ที่: data.issued_at,
                                                  "ชื่อ - นามสกุล": data.name,
                                                  รหัสพนักงาน: data.emp_code,

                                                  สัญชาติ: data.nationality,
                                                  เพศ: data.sex,
                                                  ออกให้วันที่: data.issue_date,
                                                  ใช้ได้ถึงวันที่: data.expire_date,
                                                };
                                              })
                                            : [];

                                        if (new_excel_visa_ng.length > 0) {
                                          const worksheet = xlsx.utils.json_to_sheet(new_excel_visa_ng);
                                          const workbook = xlsx.utils.book_new();
                                          xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                                          xlsx.writeFile(workbook, "ae_visa NG.xlsx");
                                        }
                                      }}
                                    >
                                      <RiFileExcel2Line color="green" size={32} />
                                      ไฟล์สรุป
                                    </span>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadJob;

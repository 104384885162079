import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateUserPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_detail: false,
      modal_delete: false,
      tab: 1,
      loading: false,

      data_business_type: [],

      // ข้อมูลนายจ้าง
      emp_id: "",
      employer_id: "",
      emp_user_type: 0,
      emp_type: 0,
      emp_number: "",
      full_name_th: "",
      business_type: "",
      address_number: "",
      moo: "",
      soi: "",
      street: "",
      province: "",
      district: "",
      sub_district: "",
      zipcode: "",
      phone: "",
      email: "",
      operator_type: 0,
      deputize_number: "",
      deputize_full_name: "",
      deputize_phone: "",
      username: "",
      password: "",
      confirm_password: "",

      // ไฟล์แนบ
      copy_company_certificate: "",
      copy_company_certificate_date: "",
      copy_power_attorney: "",
      copy_power_attorney_date: "",
      copy_tax_certificate: "",
      copy_tax_certificate_date: "",

      confirm_policy: false,

      // ผู้ประสานงาน
      coordinator_name: "",
      coordinator_phone: "",
      coordinator_email: "",
      modal_coordinator_create: false,
      modal_coordinator_update: false,
      modal_coordinator_delete: false,
      index: 0,

      phone_create: "",
      email_create: "",
      modal_create: false,
      data_workplace: [],
      data_coordinator: [],

      data_deputize: [],
      attachment_file: "",

      copy_idcard_image: "",
      copy_idcard_type: "",
    };
  }
  async componentDidMount() {
    await this.GetBusinessType();
  }
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      full_name_th: this.state.full_name_th,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone,
      email: this.state.email,
      data_deputize: this.state.data_deputize,
      username: this.state.emp_number,
      password: this.state.password,
      data_coordinator: this.state.data_coordinator,
      data_workplace: this.state.data_workplace,
      copy_idcard_image: this.state.copy_idcard_image,
    };
    let result = await POST(tokens, "api/employer/create-public", body);
    if (result && result.status) {
      success_url("/Employer/create-public");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    await this.changePhoto(e.target.files[0], type);
    if (type === "copy_company_certificate_array") {
      document.getElementById("file_copy_company_certificate_array").value = "";
      document.getElementById("file_copy_company_certificate_array").file = null;
    } else if (type === "copy_power_attorney_array") {
      document.getElementById("file_copy_power_attorney_array").value = "";
      document.getElementById("file_copy_power_attorney_array").file = null;
    } else if (type === "copy_tax_certificate_array") {
      document.getElementById("file_copy_tax_certificate_array").value = "";
      document.getElementById("file_copy_tax_certificate_array").file = null;
    } else if (type === "copy_idcard_image") {
      document.getElementById("file_copy_idcard_image").value = "";
      document.getElementById("file_copy_idcard_image").file = null;
    }
  };
  changePhoto = async (file, type, file_type) => {
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    if (file.type.includes("pdf")) {
      if (file.size > 1500000) {
        alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 1.5 mb");
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        if (type === "copy_company_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_company_certificate = e.target.result.split(",")[1];
          data_deputize[this.state.index].copy_company_certificate_date = new Date();
          data_deputize[this.state.index].copy_company_certificate_type = file.type;
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_power_attorney_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_power_attorney = e.target.result.split(",")[1];
          data_deputize[this.state.index].copy_power_attorney_date = new Date();
          data_deputize[this.state.index].copy_power_attorney_type = file.type;
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_tax_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_tax_certificate = e.target.result.split(",")[1];
          data_deputize[this.state.index].copy_tax_certificate_date = new Date();
          data_deputize[this.state.index].copy_tax_certificate_type = file.type;
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_idcard_image") {
          this.setState({ copy_idcard_image: e.target.result.split(",")[1], copy_idcard_type: file.type });
        }
      };
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let files = await convert_imagetopdf(e.target.result);
        if (type === "copy_company_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_company_certificate = files;
          data_deputize[this.state.index].copy_company_certificate_date = new Date();
          data_deputize[this.state.index].copy_company_certificate_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_power_attorney_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_power_attorney = files;
          data_deputize[this.state.index].copy_power_attorney_date = new Date();
          data_deputize[this.state.index].copy_power_attorney_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_tax_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_tax_certificate = files;
          data_deputize[this.state.index].copy_tax_certificate_date = new Date();
          data_deputize[this.state.index].copy_tax_certificate_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_idcard_image") {
          this.setState({ copy_idcard_image: files, copy_idcard_type: "pdf" });
        }
      };
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        {/* BODY */}
        <div className="bg-light min-vh-100">
          <div className="p-3">
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>ฟอร์มทดสอบนายจ้าง</b>
                  </h5>
                  <hr />
                  <div className="d-flex align-items-center mb-3">
                    <span className="wpx-150">ประเภท{required}</span>
                    <div className="d-flex align-items-center wpx-150">
                      <input
                        type="radio"
                        name="type"
                        onChange={(e) => {
                          this.setState({ emp_type: 1, data_deputize: [] });
                        }}
                        checked={this.state.emp_type === 1}
                      />
                      <span className="ms-2">บุคคลธรรมดา</span>
                    </div>
                    <div className="d-flex align-items-center wpx-120">
                      <input
                        type="radio"
                        name="type"
                        onChange={(e) => {
                          this.setState({ emp_type: 2, copy_idcard_image: "", copy_idcard_type: "" });
                        }}
                        checked={this.state.emp_type === 2}
                      />
                      <span className="ms-2">นิติบุคคล</span>
                    </div>
                    <div className="d-flex align-items-center wpx-150">
                      <input
                        type="radio"
                        name="type"
                        onChange={(e) => {
                          this.setState({ emp_type: 3, copy_idcard_image: "", copy_idcard_type: "" });
                        }}
                        checked={this.state.emp_type === 3}
                      />
                      <span className="ms-2">นิติบุคคลต่างด้าว</span>
                    </div>
                  </div>
                  {this.state.emp_type === 1 && (
                    <div className="d-flex align-items-center">
                      <span className="wpx-150">เลขบัตรประชาชน{required}</span>
                      <input
                        className="form-control mt-5 mb-3"
                        type="text"
                        placeholder="เลขบัตรประชาชน"
                        maxLength={13}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ emp_number: e.target.value });
                        }}
                        value={this.state.emp_number}
                      />
                    </div>
                  )}
                  {this.state.emp_type === 2 && (
                    <div className="d-flex align-items-center">
                      <span className="wpx-150">เลขนิติบุคคล{required}</span>
                      <input
                        className="form-control mt-5 mb-3"
                        type="text"
                        placeholder="เลขนิติบุคคล"
                        maxLength={13}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ emp_number: e.target.value });
                        }}
                        value={this.state.emp_number}
                      />
                    </div>
                  )}
                  {this.state.emp_type === 3 && (
                    <div className="d-flex align-items-center">
                      <span className="wpx-150">เลขนิติบุคคลต่างด้าว{required}</span>
                      <input
                        className="form-control mt-5 mb-3"
                        type="text"
                        placeholder="เลขนิติบุคคลต่างด้าว"
                        maxLength={13}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ emp_number: e.target.value });
                        }}
                        value={this.state.emp_number}
                      />
                    </div>
                  )}
                  <hr />
                  {/* ข้อมูล */}
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-1">
                      <b>ข้อมูล</b>
                    </h5>
                    <span className="text-danger">
                      <b>ข้อมูลสำคัญ : </b>ประเภทกิจการที่ท่านเลือก ต้องสอดคล้องกับตำแหน่งงานของลูกจ้าง ท่านสามารถดูตารางเทียบตำแหน่งได้โดย{" "}
                      <a href="#" className="text-decoration-none">
                        คลิกที่นี่
                      </a>
                    </span>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อนายจ้าง/สถานประกอบการ{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ full_name_th: e.target.value });
                        }}
                        value={this.state.full_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทกิจการ{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_business_type}
                        onChange={(e) => {
                          this.setState({ business_type: e });
                        }}
                        value={this.state.business_type}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ address_number: e.target.value });
                        }}
                        value={this.state.address_number}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">หมู่ที่</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ moo: e.target.value });
                        }}
                        value={this.state.moo}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ซอย</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ soi: e.target.value });
                        }}
                        value={this.state.soi}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ถนน{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ street: e.target.value });
                        }}
                        value={this.state.street}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">จังหวัด{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={province.map((item) => {
                          return { value: item.province_name, label: item.province_name };
                        })}
                        onChange={(e) => {
                          this.setState({ province: e, district: null, sub_district: null, zipcode: "" });
                        }}
                        value={this.state.province}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150"> อำเภอ / เขต{required}</span>
                      <Select
                        className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                        styles={select_style}
                        options={district
                          .filter((item) => this.state.province && this.state.province.value === item.province_name)
                          .map((item) => {
                            return { value: item.district_name, label: item.district_name };
                          })}
                        onChange={(e) => {
                          this.setState({ district: e, sub_district: null, zipcode: "" });
                        }}
                        value={this.state.district}
                        isDisabled={!this.state.province}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ตำบล / แขวง{required}</span>
                      <Select
                        className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                        styles={select_style}
                        options={subdistrict
                          .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&& this.state.province.value === item.province_name)
                          .map((item) => {
                            return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
                          })}
                        onChange={(e) => {
                          this.setState({ sub_district: e, zipcode: e.zipcode.toString() });
                        }}
                        value={this.state.sub_district}
                        isDisabled={!this.state.district}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="รหัสไปรษณีย์"
                        onChange={(e) => {
                          this.setState({ zipcode: e.target.value });
                        }}
                        value={this.state.zipcode}
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">โทรศัพท์ที่ติดต่อได้{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        maxLength={10}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ phone: e.target.value });
                        }}
                        value={this.state.phone}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">อีเมล{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        value={this.state.email}
                      />
                    </div>
                    {this.state.emp_type === 1 && (
                      <div className="col-12 col-md-12 d-flex mb-3">
                        <span className="wpx-150">สำเนาบัตรประชาชน{required}</span>
                        <div className="d-flex">
                          {!this.state.copy_idcard_image ? (
                            <button
                              className="btn btn-primary px-4"
                              onClick={() => {
                                document.getElementById("file_copy_idcard_image").click();
                              }}
                            >
                              แนบไฟล์
                            </button>
                          ) : (
                            <div className="w-100 d-flex justify-content-center">
                              <iframe src={this.state.copy_idcard_image.includes("://") ? this.state.copy_idcard_image : "data:application/pdf;base64," + this.state.copy_idcard_image} width="150" height="150"></iframe>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.setState({ modal_detail: true, attachment_file: this.state.copy_idcard_image });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.setState({ modal_delete: true, attachment_name: "copy_idcard_image" });
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                              </div>
                            </div>
                          )}
                          <input
                            className="d-none"
                            type="file"
                            id="file_copy_idcard_image"
                            accept="application/pdf,image/*"
                            onChange={(e) => {
                              this.import_file(e, "copy_idcard_image");
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* กรรมการผู้มีอำนาจ */}
                  {this.state.emp_type !== 1 && (
                    <>
                      <hr />
                      <div className="d-flex justify-content-between mb-3">
                        <h5 className="mb-3">
                          <b>กรรมการผู้มีอำนาจ</b>
                        </h5>
                        <button
                          className="btn btn-primary px-4"
                          onClick={() => {
                            let data_deputize = this.state.data_deputize;
                            data_deputize.push({
                              operator_type: 2,
                              deputize_number: "",
                              deputize_full_name: "",
                              deputize_phone: "",
                              copy_company_certificate: "",
                              copy_company_certificate_date: "",
                              copy_power_attorney: "",
                              copy_power_attorney_date: "",
                              copy_tax_certificate: "",
                              copy_tax_certificate_date: "",
                            });
                            this.setState({ data_deputize: data_deputize });
                          }}
                        >
                          + เพิ่มกรรมการผู้มีอำนาจ
                        </button>
                      </div>
                      <div className="card bg-contain p-3 pb-0">
                        {this.state.data_deputize.map((item, index) => (
                          <div className="card p-3 mb-3 position-relative">
                            <div
                              className="wpx-30 hpx-30 rounded-circle d-flex align-items-center justify-content-center icon text-white pointer bg-danger position-absolute"
                              style={{ top: -8, right: -8 }}
                              onClick={() => {
                                let data_deputize = this.state.data_deputize;
                                data_deputize.splice(index, 1);
                                this.setState({ data_deputize: data_deputize });
                              }}
                            >
                              {"\uf00d"}
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                                <span className="wpx-220">ชื่อ - นามสกุล กรรมการผู้มีอำนาจ{item.operator_type === 2 ? required : null}</span>
                                <input
                                  className="form-control mt-1"
                                  type="text"
                                  onChange={(e) => {
                                    let data_deputize = this.state.data_deputize;
                                    data_deputize[index].deputize_full_name = e.target.value;
                                    this.setState({ data_deputize: data_deputize });
                                  }}
                                  value={item.deputize_full_name}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>
                                <span className="wpx-150">แนบไฟล์เอกสาร</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)
                              </span>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-borderless table-striped">
                                <thead className="bg-primary">
                                  <tr>
                                    <th className="text-center text-white">ลำดับ</th>
                                    <th className="text-left text-white">รายการ</th>
                                    <th className="text-left text-white">วันที่แนบเอกสารล่าสุด</th>
                                    <th className="text-center text-white">ดำเนินการ</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-center">1</td>
                                    <td className="text-left">สำเนากรรมการ{required}</td>
                                    <td className="text-left">{item.copy_power_attorney_date ? format_date(item.copy_power_attorney_date, "dd mmm yyyy") + " เวลา " + format_date(item.copy_power_attorney_date, "hh:mm") + " น." : ""}</td>
                                    <td className="text-center">
                                      {!item.copy_power_attorney ? (
                                        <button
                                          className="btn btn-primary mx-1 px-4"
                                          onClick={() => {
                                            this.setState({ index: index });
                                            document.getElementById("file_copy_power_attorney_array").click();
                                          }}
                                        >
                                          แนบไฟล์
                                        </button>
                                      ) : (
                                        <div className="w-100 d-flex justify-content-center">
                                          <iframe src={item.copy_power_attorney.includes("://") ? item.copy_power_attorney : "data:application/pdf;base64," + item.copy_power_attorney} width="150" height="150"></iframe>
                                          <div
                                            className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                            onClick={() => {
                                              this.setState({ modal_detail: true, attachment_file: this.state.copy_power_attorney });
                                            }}
                                          >
                                            <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                          </div>
                                          <div
                                            className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                            onClick={() => {
                                              this.setState({ modal_delete: true, index: index, attachment_name: "copy_power_attorney" });
                                            }}
                                          >
                                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                  {index === 0 && (
                                    <tr>
                                      <td className="text-center">2</td>
                                      <td className="text-left">หนังสือรับรองบริษัท{required}</td>
                                      <td className="text-left">{item.copy_company_certificate_date ? format_date(item.copy_company_certificate_date, "dd mmm yyyy") + " เวลา " + format_date(item.copy_company_certificate_date, "hh:mm") + " น." : ""}</td>
                                      <td className="text-center">
                                        {!item.copy_company_certificate ? (
                                          <button
                                            className="btn btn-primary mx-1 px-4"
                                            onClick={() => {
                                              this.setState({ index: index });
                                              document.getElementById("file_copy_company_certificate_array").click();
                                            }}
                                          >
                                            แนบไฟล์
                                          </button>
                                        ) : (
                                          <div className="w-100 d-flex justify-content-center">
                                            <iframe src={item.copy_company_certificate.includes("://") ? item.copy_company_certificate : "data:application/pdf;base64," + item.copy_company_certificate} width="150" height="150"></iframe>
                                            <div
                                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                              onClick={() => {
                                                this.setState({ modal_detail: true, attachment_file: this.state.copy_company_certificate });
                                              }}
                                            >
                                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                            </div>
                                            <div
                                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                              onClick={() => {
                                                this.setState({ modal_delete: true, index: index, attachment_name: "copy_company_certificate" });
                                              }}
                                            >
                                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  {index === 0 && (
                                    <tr>
                                      <td className="text-center">3</td>
                                      <td className="text-left">ใบทะเบียนภาษีมูลค่าเพิ่ม (ภ.พ.20){required}</td>
                                      <td className="text-left">{item.copy_tax_certificate_date ? format_date(item.copy_tax_certificate_date, "dd mmm yyyy") + " เวลา " + format_date(item.copy_tax_certificate_date, "hh:mm") + " น." : ""}</td>
                                      <td className="text-center">
                                        {!item.copy_tax_certificate ? (
                                          <button
                                            className="btn btn-primary mx-1 px-4"
                                            onClick={() => {
                                              this.setState({ index: index });
                                              document.getElementById("file_copy_tax_certificate_array").click();
                                            }}
                                          >
                                            แนบไฟล์
                                          </button>
                                        ) : (
                                          <div className="w-100 d-flex justify-content-center">
                                            <iframe src={item.copy_tax_certificate.includes("://") ? item.copy_tax_certificate : "data:application/pdf;base64," + item.copy_tax_certificate} width="150" height="150"></iframe>
                                            <div
                                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                              onClick={() => {
                                                this.setState({ modal_detail: true, attachment_file: this.state.copy_tax_certificate });
                                              }}
                                            >
                                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                            </div>
                                            <div
                                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                              onClick={() => {
                                                this.setState({ modal_delete: true, index: index, attachment_name: "copy_tax_certificate" });
                                              }}
                                            >
                                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}
                        <input
                          className="d-none"
                          type="file"
                          id="file_copy_company_certificate_array"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "copy_company_certificate_array");
                          }}
                        />
                        <input
                          className="d-none"
                          type="file"
                          id="file_copy_power_attorney_array"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "copy_power_attorney_array");
                          }}
                        />
                        <input
                          className="d-none"
                          type="file"
                          id="file_copy_tax_certificate_array"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "copy_tax_certificate_array");
                          }}
                        />
                      </div>
                    </>
                  )}
                  <hr />
                  {/* สถานที่ทำงาน */}
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-3">
                      <b>สถานที่ทำงาน</b>
                    </h5>
                    <button
                      className="btn btn-primary px-4"
                      onClick={() => {
                        this.setState({
                          emp_id: "",
                          address_number: "",
                          moo: "",
                          soi: "",
                          street: "",
                          province: "",
                          district: "",
                          sub_district: "",
                          zipcode: "",
                          phone_create: "",
                          email_create: "",
                          modal_workplace_create: true,
                        });
                      }}
                    >
                      + เพิ่มสถานที่ทำงาน
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped">
                      <thead className="bg-primary">
                        <tr>
                          <th className="text-center text-white">ลำดับ</th>
                          <th className="text-left text-white">รายการ</th>
                          <th className="text-left text-white">อีเมล</th>
                          <th className="text-left text-white">เบอร์โทร</th>
                          <th className="text-center text-white">ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data_workplace.map((item, index) => (
                          <tr>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-left">
                              {item.address_number}
                              {item.moo ? " หมู่ " + item.moo : ""}
                              {item.soi ? " ซอย " + item.soi : ""}
                              {item.street ? " ถนน " + item.street : ""}
                              {item.sub_district ? " ตำบล" + item.sub_district : ""}
                              {item.district ? " อำเภอ" + item.district : ""}
                              {item.province ? " จังหวัด" + item.province : ""}
                              {item.zipcode ? " รหัสไปรษณีย์ " + item.zipcode : ""}
                            </td>
                            <td className="text-left">{item.email ? item.email : "-"}</td>
                            <td className="text-left">{item.phone ? item.phone : "-"}</td>
                            <td className="text-center">
                              <div className="w-100 d-flex justify-content-center">
                                <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center" onClick={() => {}}>
                                  <label
                                    className="pointer icon text-primary p-2"
                                    onClick={() => {
                                      this.setState({
                                        index: index,
                                        emp_id: item.emp_id,
                                        address_number: item.address_number,
                                        moo: item.moo,
                                        soi: item.soi,
                                        street: item.street,
                                        province: province
                                          .map((e) => {
                                            return { value: e.province_name, label: e.province_name };
                                          })
                                          .filter((e) => e.value === item.province)[0],
                                        district: district
                                          .map((e) => {
                                            return { value: e.district_name, label: e.district_name };
                                          })
                                          .filter((e) => e.value === item.district)[0],
                                        sub_district: subdistrict
                                          .map((e) => {
                                            return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                          })
                                          .filter((e) => e.value === item.sub_district)[0],
                                        zipcode: item.zipcode,
                                        phone: item.phone_create,
                                        email: item.email_create,
                                        modal_workplace_update: true,
                                      });
                                    }}
                                  >
                                    {"\uf044"}
                                  </label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      index: index,
                                      emp_id: item.emp_id,
                                      address_number: item.address_number,
                                      moo: item.moo,
                                      soi: item.soi,
                                      street: item.street,
                                      province: province
                                        .map((e) => {
                                          return { value: e.province_name, label: e.province_name };
                                        })
                                        .filter((e) => e.value === item.province)[0],
                                      district: district
                                        .map((e) => {
                                          return { value: e.district_name, label: e.district_name };
                                        })
                                        .filter((e) => e.value === item.district)[0],
                                      sub_district: subdistrict
                                        .map((e) => {
                                          return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                        })
                                        .filter((e) => e.value === item.sub_district)[0],
                                      zipcode: item.zipcode,
                                      phone: item.phone_create,
                                      email: item.email_create,
                                      modal_workplace_delete: true,
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  {/* ผู้ประสานงาน */}
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-3">
                      <b>ผู้ประสานงาน</b>
                    </h5>
                    <button
                      className="btn btn-primary px-4"
                      onClick={() => {
                        this.setState({
                          coordinator_name: "",
                          coordinator_phone: "",
                          coordinator_email: "",
                          modal_coordinator_create: true,
                        });
                      }}
                    >
                      + เพิ่มผู้ประสานงาน
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped">
                      <thead className="bg-primary">
                        <tr>
                          <th className="text-center text-white">ลำดับ</th>
                          <th className="text-left text-white">ชื่อ - นามสกุล</th>
                          <th className="text-left text-white">เบอร์โทร</th>
                          <th className="text-left text-white">อีเมล</th>
                          <th className="text-center text-white">ดำเนินการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data_coordinator.map((item, index) => (
                          <tr>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-left">{item.coordinator_name ? item.coordinator_name : "-"}</td>
                            <td className="text-left">{item.coordinator_phone ? item.coordinator_phone : "-"}</td>
                            <td className="text-left">{item.coordinator_email ? item.coordinator_email : "-"}</td>
                            <td className="text-center">
                              <div className="w-100 d-flex justify-content-center">
                                <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center" onClick={() => {}}>
                                  <label
                                    className="pointer icon text-primary p-2"
                                    onClick={() => {
                                      this.setState({
                                        modal_coordinator_update: true,
                                        index: index,
                                        coordinator_name: item.coordinator_name,
                                        coordinator_phone: item.coordinator_phone,
                                        coordinator_email: item.coordinator_email,
                                      });
                                    }}
                                  >
                                    {"\uf044"}
                                  </label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      modal_coordinator_delete: true,
                                      index: index,
                                      coordinator_name: item.coordinator_name,
                                      coordinator_phone: item.coordinator_phone,
                                      coordinator_email: item.coordinator_email,
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (this.state.emp_type === 0) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภท");
                              return;
                            } else if (this.state.emp_number.trim() === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอก" + (this.state.emp_type === 1 ? "รหัสบัตรประชาชน" : this.state.emp_type === 2 ? "เลขนิติบุคคล" : this.state.emp_type === 3 ? "เลขที่หนังสือเดินทาง" : ""));
                              return;
                            } else if (this.state.full_name_th === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อนายจ้าง/สถานประกอบการ");
                              return;
                            } else if (!this.state.business_type) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทกิจการ");
                              return;
                            } else if (this.state.address_number === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                              return;
                            } else if (this.state.street === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกถนน");
                              return;
                            } else if (!this.state.province) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                              return;
                            } else if (!this.state.district) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                              return;
                            } else if (!this.state.sub_district) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                              return;
                            } else if (this.state.zipcode === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                              return;
                            } else if (this.state.phone === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ที่สามารถติดต่อได้");
                              return;
                            } else if (this.state.phone.length < 9) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน");
                              return;
                            } else if (this.state.email === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                              return;
                            }
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ดูข้อมูลเอกสาร */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="xl"
        >
          <Modal.Body className="p-2">
            <iframe src={this.state.attachment_file.includes("://") ? this.state.attachment_file : "data:application/pdf;base64," + this.state.attachment_file} width="100%" height={window.innerHeight - 70 + "px"}></iframe>
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_detail: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* เพิ่มสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_workplace_create || this.state.modal_workplace_update}
          onHide={() => {
            this.setState({ modal_workplace_create: false, modal_workplace_update: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มสถานที่ทำงานใหม่</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-1">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ address_number: e.target.value });
                  }}
                  value={this.state.address_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">หมู่ที่</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ moo: e.target.value });
                  }}
                  value={this.state.moo}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ซอย</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ soi: e.target.value });
                  }}
                  value={this.state.soi}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ถนน{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ street: e.target.value });
                  }}
                  value={this.state.street}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">จังหวัด{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={province.map((item) => {
                    return { value: item.province_name, label: item.province_name };
                  })}
                  onChange={(e) => {
                    this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150"> อำเภอ / เขต{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                  styles={select_style}
                  options={district
                    .filter((item) => this.state.province && this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.district_name, label: item.district_name };
                    })}
                  onChange={(e) => {
                    this.setState({ district: e, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.district}
                  isDisabled={!this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ตำบล / แขวง{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                  styles={select_style}
                  options={subdistrict
                    .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&& this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.sub_district_name, label: item.sub_district_name, zip_code: item.zip_code };
                    })}
                  onChange={(e) => {
                    this.setState({ sub_district: e, zipcode: e.zip_code.toString() });
                  }}
                  value={this.state.sub_district}
                  isDisabled={!this.state.district}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => {
                    this.setState({ zipcode: e.target.value });
                  }}
                  value={this.state.zipcode}
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ phone_create: e.target.value });
                  }}
                  value={this.state.phone_create}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ email_create: e.target.value });
                  }}
                  value={this.state.email_create}
                />
              </div>
            </div>
            {/* <hr />
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <span><span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)</span>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table-striped">
                                <thead className="bg-primary">
                                    <tr>
                                        <th className="text-center text-white">ลำดับ</th>
                                        <th className="text-center text-white">รายการ</th>
                                        <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                                        <th className="text-center text-white">ดำเนินการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td className="text-start">หลักฐานการเพิ่มสถานที่ทำงานใหม่และแผนที่ เช่น<br />
                                            - สำเนาทะเบียนบ้าน หรือ<br />
                                            - สำเนาเช่าสำนักงาน/ร้านค้า/โรงงาน หรือ<br />
                                            - สัญญาจ้างงานของโครงการก่อสร้าง หรือ<br />
                                            - สำเนาโฉนดที่ดิน {required}</td>
                                        <td className="text-center">{format_date(new Date(), "dd mmm yyyy")} เวลา {format_date(new Date(), "hh:mm")} น.</td>
                                        <td className="text-center">
                                            <button className="btn btn-primary mx-1 px-4" onClick={() => { }}>
                                                แนบไฟล์
                                            </button>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_workplace_detail: true, attachment_id: "", attachment_name: "", attachment_file: "" }) }}>
                                                    <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                </div>
                                                <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_workplace_delete: true, attachment_id: "", attachment_name: "" }) }}>
                                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span className="d-flex align-items-center">หมายเหตุ :
                            <div className="ms-3 me-2 wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-primary p-2">{"\uf15c"}</label>
                            </div>
                            แสดงเอกสารแนบ
                            <div className="ms-5 me-2 wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                            ลบเอกสาร
                        </span> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_workplace_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.address_number === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                      return;
                    } else if (this.state.street === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกถนน");
                      return;
                    } else if (!this.state.province) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                      return;
                    } else if (!this.state.district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                      return;
                    } else if (!this.state.sub_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                      return;
                    } else if (this.state.zipcode === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                      return;
                    } else if (this.state.phone_create === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์");
                      return;
                    } else if (this.state.phone_create.length < 9) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน");
                      return;
                    }
                    let data_workplace = this.state.data_workplace;
                    if (this.state.modal_workplace_create) {
                      data_workplace.push({
                        emp_id: this.state.emp_id,
                        address_number: this.state.address_number,
                        moo: this.state.moo,
                        soi: this.state.soi,
                        street: this.state.street,
                        province: this.state.province ? this.state.province.value : "",
                        district: this.state.district ? this.state.district.value : "",
                        sub_district: this.state.sub_district ? this.state.sub_district.value : "",
                        zipcode: this.state.zipcode,
                        phone: this.state.phone_create,
                        email: this.state.email_create,
                      });
                    } else {
                      data_workplace[this.state.index].emp_id = this.state.emp_id;
                      data_workplace[this.state.index].address_number = this.state.address_number;
                      data_workplace[this.state.index].moo = this.state.moo;
                      data_workplace[this.state.index].soi = this.state.soi;
                      data_workplace[this.state.index].street = this.state.street;
                      data_workplace[this.state.index].province = this.state.province ? this.state.province.value : "";
                      data_workplace[this.state.index].district = this.state.district ? this.state.district.value : "";
                      data_workplace[this.state.index].sub_district = this.state.sub_district ? this.state.sub_district.value : "";
                      data_workplace[this.state.index].zipcode = this.state.zipcode;
                      data_workplace[this.state.index].phone = this.state.phone_create;
                      data_workplace[this.state.index].email = this.state.email_create;
                    }
                    this.setState({ data_workplace: data_workplace, modal_workplace_create: false, modal_workplace_update: false });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_workplace_delete}
          onHide={() => {
            this.setState({ modal_workplace_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบสถานที่ทำงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.address_number} ” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_workplace = this.state.data_workplace;
                    data_workplace.splice(this.state.index, 1);
                    this.setState({ data_workplace: data_workplace, modal_workplace_delete: false });
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_workplace_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มผู้ประสานงาน */}
        <Modal
          show={this.state.modal_coordinator_create || this.state.modal_coordinator_update}
          onHide={() => {
            this.setState({ modal_coordinator_create: false, modal_coordinator_update: false });
          }}
          size="md"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มผู้ประสานงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ coordinator_name: e.target.value });
                  }}
                  value={this.state.coordinator_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ coordinator_phone: e.target.value });
                  }}
                  value={this.state.coordinator_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล{required}</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ coordinator_email: e.target.value });
                  }}
                  value={this.state.coordinator_email}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_coordinator_create: false, modal_coordinator_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.coordinator_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.coordinator_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.coordinator_email === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                      return;
                    }
                    let data_coordinator = this.state.data_coordinator;
                    if (this.state.modal_coordinator_create) {
                      data_coordinator.push({
                        coordinator_name: this.state.coordinator_name,
                        coordinator_phone: this.state.coordinator_phone,
                        coordinator_email: this.state.coordinator_email,
                      });
                    } else {
                      data_coordinator[this.state.index].coordinator_name = this.state.coordinator_name;
                      data_coordinator[this.state.index].coordinator_phone = this.state.coordinator_phone;
                      data_coordinator[this.state.index].coordinator_email = this.state.coordinator_email;
                    }
                    this.setState({ data_coordinator: data_coordinator, modal_coordinator_create: false, modal_coordinator_update: false });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบผู้ประสานงาน */}
        <Modal
          show={this.state.modal_coordinator_delete}
          onHide={() => {
            this.setState({ modal_coordinator_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบผู้ประสานงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.coordinator_name} ” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_coordinator = this.state.data_coordinator;
                    data_coordinator.splice(this.state.index, 1);
                    this.setState({ data_coordinator: data_coordinator, modal_coordinator_delete: false });
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_coordinator_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.attachment_name === "copy_company_certificate" || this.state.attachment_name === "copy_power_attorney" ? "ลบเอกสารแนบ" : ""}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.attachment_name === "copy_company_certificate" ? "สำเนาบัตรประชาชน " : this.state.attachment_name === "copy_power_attorney" ? "สำเนากรรมการผู้มีอำนาจ " : ""}” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_deputize = this.state.data_deputize;
                    if (this.state.attachment_name === "copy_company_certificate") {
                      data_deputize[this.state.index].copy_company_certificate = "";
                      data_deputize[this.state.index].copy_company_certificate_date = "";
                    } else if (this.state.attachment_name === "copy_power_attorney") {
                      data_deputize[this.state.index].copy_power_attorney = "";
                      data_deputize[this.state.index].copy_power_attorney_date = "";
                    } else if (this.state.attachment_name === "copy_tax_certificate") {
                      data_deputize[this.state.index].copy_tax_certificate = "";
                      data_deputize[this.state.index].copy_tax_certificate_date = "";
                    } else if (this.state.attachment_name === "copy_idcard_image") {
                      this.setState({ copy_idcard_image: "", modal_delete: false });
                    }
                    this.setState({ data_deputize: data_deputize, modal_delete: false });

                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { GET, tokens, document_receipt } from "../CustomComponent.js";
// ใบเสร็จ
export default class Loading extends Component {
  async componentDidMount() {
    let receipt_id = this.props.location.query.receipt_id;
    await this.GetHeaderDoc(receipt_id);
  }
  GetHeaderDoc = async (receipt_id) => {
    let result = await GET(tokens, "api/header-doc/list");
    if (result && result.status) {
      this.GetbyID(receipt_id, result.data);
    }
  };
  GetbyID = async (receipt_id, data_header) => {
    let body_receipt = {
      receipt_id: receipt_id,
    };
    let result_receipt = await GET(tokens, "api/receipt/list/page/0", body_receipt);
    if (result_receipt && result_receipt.status) {
      let body_receive = {
        data_search: "",
        receive_id: result_receipt.data.receive_id,
        menu: "",
      };
      let result_receive = await GET(tokens, "api/receive-matter/list/page/0", body_receive);
      if (result_receive && result_receive.status) {
        let body_invoice = {
          data_search: "",
          invoice_id: result_receipt.data.invoice_id,
          menu: "",
        };
        result_receive.data.rate_list = result_receive.data.rate_list.map((e) => {
          return {
            ...e,
            qty_price: e.qty_price - e.discount,
          };
        });
        let result_invoice = await GET(tokens, "api/invoice/list/page/0", body_invoice);
        if (result_invoice && result_invoice.status) {
          let obj = {
            ...result_receipt.data,
            receive_data: result_receive.data,
            invoice_data: result_invoice.data,
          };
          document_receipt(obj, data_header);
        }
      }
    }
  };
  render() {
    return <div></div>;
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      lms_id: "",
      lms_name: "",
      lms_category_id: "",
      title: "",
      description: "",
      start_date: "",
      end_date: "",
      key_search: "",
      link_list: [],
      survey_list: [],
      picture_list: [],
      is_active: true,
      create_by: "",
      category_data: {},
      createdAt: null,
    };
  }
  async componentDidMount() {
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
    }
  }
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      lms_id: id,
    };
    let result = await GET(tokens, "api/setting/main-lms/list/page/0", body);
    if (result && result.status) {
      this.setState({
        lms_id: result.data.lms_id,
        lms_name: result.data.lms_name,
        lms_category_id: result.data.lms_category_id,
        title: result.data.title,
        description: result.data.description,
        start_date: result.data.start_date,
        end_date: result.data.end_date,
        key_search: result.data.key_search,
        link_list: result.data.link_list,
        survey_list: result.data.survey_list,
        picture_list: result.data.picture_list,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        category_data: result.data.category_data,
        createdAt: result.data.createdAt,
      });
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ทะเบียนต่างด้าว" page3="ความรู้เบื้องต้น การใช้งานระบบ CLMV" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>ความรู้เบื้องต้น การใช้งานระบบ CLMV</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span className="wpx-150">ชื่อ</span>
                  <span>{this.state.lms_name}</span>
                </div>
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span className="wpx-150">วันที่สร้าง</span>
                  <span>{this.state.createdAt ? format_date(this.state.createdAt) : ""}</span>
                </div>
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span className="wpx-150">ผู้สร้าง</span>
                  <span>{this.state.create_by}</span>
                </div>
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span className="wpx-150">หมวดหมู่</span>
                  <span>{this.state.category_data ? this.state.category_data.category_name : ""}</span>
                </div>
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span className="wpx-150">รายละเอียด</span>
                </div>
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span>{this.state.description}</span>
                </div>
                <div className="d-flex align-items-center mx-0 mb-2">
                  <span className="wpx-150">คำค้นหา</span>
                  {this.state.key_search.split(",").map((e) => (
                    <span className="bg-secondary rounded text-white px-2 py-1 text-nowrap text-12 me-2">{e}</span>
                  ))}
                </div>
                <hr />
                <div className="row">
                  {this.state.picture_list.map((item, index) => (
                    <div className="col-6 col-md-4 col-lg-3">
                      <div className="w-100 bg-light">
                        <img alt="รูปโปรไฟล์" src={item.includes("https://") || item.includes("http://") ? item : "data:image/png;base64," + item} className="w-100 hpx-200 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                      </div>
                    </div>
                  ))}
                </div>
                <hr />
                <h5 className="mb-3">
                  <b>วิดีโอที่เกี่ยวข้อง</b>
                </h5>
                <div className="row">
                  {this.state.link_list.map((item, index) => (
                    <div className="col-6 col-md-4 col-lg-3">
                      <div className="w-100 bg-light">
                        <iframe width="100%" height="200" src={item}>
                          {" "}
                        </iframe>
                      </div>
                    </div>
                  ))}
                </div>
                <hr />
                <h5 className="mb-3">
                  <b>แบบทดสอบความเข้าใจ</b>
                </h5>

                {this.state.survey_list.map((item, index) => (
                  <div className="row mb-3">
                    <div className="col-12">
                      <b>
                        {index + 1}. {item.question}
                      </b>
                    </div>
                    <div className="col-6 col-md-3">
                      <label>1. {item.answer1}</label>
                    </div>
                    <div className="col-6 col-md-3">
                      <label>2. {item.answer2}</label>
                    </div>
                    <div className="col-6 col-md-3">
                      <label>3. {item.answer3}</label>
                    </div>
                    <div className="col-6 col-md-3">
                      <label>4. {item.answer4}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

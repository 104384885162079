/* eslint-disable jsx-a11y/anchor-is-valid */
import LOGO from "../assets/images/logo.png";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  DateTimePicker,
  TimePicker,
  MonthPicker,
} from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";
import "../assets/css/index.css";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
      if (user.role_name === "line_oa") {
        window.location.href = "/upload";
      } else if (user.user_type === "owner") {
        window.location.href = "/console";
      } else {
        window.location.href = "/Employer/list";
      }
    } else {
    }
  }

  Login = async () => {
    if (this.state.username.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขประจำตัวประชาชน/นิติบุคคล");
      return;
    } else if (this.state.password.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      username: this.state.username,
      password: this.state.password,
      remember: true,
    };
    let result = await POST("", "api/login", data);
    if (result && result.status) {
      if (result.data.department === "ตัวแทน") {
        alert("warning", "แจ้งเตือน", "สิทธิ์ถูกปฏิเสธการเข้าสู่ระบบ")
        this.setState({ loading: false })
        return
      }
      let user = {
        user_type: result.data.user_type,
        user_id: result.data.user_id,
        user_code: result.data.user_code,
        full_name: result.data.full_name,
        email: result.data.email,
        username: result.data.username,
        role_name: result.data.role_name,
      };

      localStorage.setItem("token", "token " + result.token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("permission", JSON.stringify(result.data.role_detail));
      if (result.data.user_type === "owner") {
        window.location.href = "/console";
      } else {
        localStorage.setItem("manage_role", "customer");
        window.location.href = "/Employer/list";
      }
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="row py-5 mx-0" style={{ height: "100vh" }}>
          <div className="col-12 col-md-8 mx-auto">
            <div className="d-flex justify-content-center mb-3">
              <img src={LOGO} style={{ width: 170 }} />
            </div>
            <h5 className="text-center mb-3">ระบบต่ออายุใบอนุญาตทำงานลูกจ้าง (4 สัญชาติ) ทางอิเล็กทรอนิกส์</h5>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="row mx-0">
                <div className="col-md-6 col-xs-12 p-3">
                  <h5 className="text-center">
                    <b>ประกาศ</b>
                  </h5>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="w-100 p-3">
                    <h3 className="mb-3">
                      <b>ลงชื่อเข้าใช้</b>
                    </h3>
                    <span className="mx-2">รหัสผู้ใช้งาน</span>
                    <input
                      className="form-control mb-3"
                      type="text"
                      placeholder="เลขประจำตัวประชาชน/นิติบุคคล"
                      onChange={(e) => {
                        this.setState({ username: e.target.value });
                      }}
                    />
                    <span className="mx-2">รหัสผ่าน</span>
                    <input
                      className="form-control mb-1"
                      type="password"
                      placeholder="รหัสผ่าน"
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          this.Login();
                        }
                      }}
                    />
                    <div className="w-100 mb-4 text-end">{/* <a href="/forgot-password" className="text-decoration-none">ลืมรหัสผ่าน ?</a> */}</div>
                    <button
                      type="button"
                      className="btn btn-primary w-100 mb-3"
                      onClick={() => {
                        this.Login();
                      }}
                    >
                      เข้าสู่ระบบ
                    </button>
                    <div className="d-flex align-items-center mb-3">
                      <div className="border-bottom w-100"></div>
                      <span className="mx-2">หรือ</span>
                      <div className="border-bottom w-100"></div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 mb-3"
                      onClick={() => {
                        window.location.href = "/register";
                      }}
                    >
                      ลงทะเบียน
                    </button>
                    <div className="w-100 text-center">
                      <small className="text-secondary">{global.version}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import LOGO from "../assets/images/logo.png";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  DateTimePicker,
  TimePicker,
  MonthPicker,
} from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";
import "../assets/css/index.css";
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      username: "",
      password: "",
      confirm_password: "",
      tab: 1,
      loading: false,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
      if (user.role_name === "line_oa") {
        window.location.href = "/upload";
      } else if (user.user_type === "owner") {
        window.location.href = "/console";
      } else {
        window.location.href = "/Employer/list";
      }
    } else {
    }
  }

  Register = async () => {
    if (this.state.full_name.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    } else if (this.state.username.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อผู้ใช้งาน");
      return;
    } else if (this.state.password.trim() === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
      return;
    }
    if (this.state.password !== this.state.confirm_password) {
      alert("warning", "แจ้งเตือน", "รหัสผ่านไม่ตรงกัน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      full_name: this.state.full_name,
      username: this.state.username,
      password: this.state.password,
      user_type: this.props.location.query.page === "line_oa" ? "line_oa" : "general",
    };
    let result = await POST("", "api/register/create", data);
    if (result && result.status) {
      if (this.props.location.query.page === "line_oa") {
        this.Login();
      } else {
        success_url("/login");
      }
    }
    this.setState({ loading: false });
  };
  Login = async () => {
    this.setState({ loading: true });
    let data = {
      username: this.state.username,
      password: this.state.password,
      remember: true,
    };
    let result = await POST("", "api/login", data);
    if (result && result.status) {
      let user = {
        user_type: result.data.user_type,
        user_id: result.data.user_id,
        full_name: result.data.full_name,
        email: result.data.email,
        username: result.data.username,
        role_name: result.data.role_name,
      };

      localStorage.setItem("token", "token " + result.token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("permission", JSON.stringify(result.data.role_detail));
      window.location.href = "/upload";
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="row py-5 mx-0" style={{ height: "100vh" }}>
          <div className="col-12 col-md-8 mx-auto">
            <div className="d-flex justify-content-center mb-3">
              <img src={LOGO} style={{ width: 170 }} />
            </div>
            <h5 className="text-center mb-3">ระบบต่ออายุใบอนุญาตทำงานลูกจ้าง (4 สัญชาติ) ทางอิเล็กทรอนิกส์</h5>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="row mx-0">
                <div className="col-12 col-sm-10 col-md-8 col-lg-8 mx-auto">
                  {this.state.tab === 1 && (
                    <div className="w-100 p-3">
                      <h5 className="mb-3">
                        <b>สร้างบัญชีผู้ใช้</b>
                      </h5>
                      <hr />
                      <b>ชื่อ-นามสกุล</b>
                      <input
                        className="form-control mt-1 mb-3"
                        type="text"
                        placeholder="ชื่อ-นามสกุล"
                        onChange={(e) => {
                          this.setState({ full_name: e.target.value });
                        }}
                      />
                      <b>รหัสผู้ใช้งาน</b>
                      <input
                        className="form-control mt-1 mb-3"
                        type="text"
                        placeholder="รหัสผู้ใช้งาน"
                        onChange={(e) => {
                          this.setState({ username: e.target.value });
                        }}
                      />
                      <b>ระบุรหัสผ่าน</b>
                      <input
                        className="form-control mt-1 mb-3"
                        type="password"
                        placeholder="รหัสผ่าน"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                      <b>ระบุรหัสผ่านอีกครั้ง</b>
                      <input
                        className="form-control mt-1 mb-3"
                        type="password"
                        placeholder="รหัสผ่าน"
                        onChange={(e) => {
                          this.setState({ confirm_password: e.target.value });
                        }}
                      />
                    </div>
                  )}
                  <div className="d-flex mt-5">
                    <div className="w-50 pe-1">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100 mb-3"
                        onClick={() => {
                          window.location.href = "/login";
                        }}
                      >
                        ยกเลิก
                      </button>
                    </div>
                    <div className="w-50 ps-1">
                      <button
                        type="button"
                        className="btn btn-primary w-100 mb-3"
                        onClick={() => {
                          this.Register();
                        }}
                      >
                        สร้างบัญชี
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

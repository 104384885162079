import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import Resizer from "react-image-file-resizer";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1,
      loading: false,

      file: "",
      file_name: "",
      file_date: "",
      file_type: "",

      name: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).full_name : "",

      detail_file: [],
    };
  }
  async componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
    } else {
      localStorage.clear();
      alert_url("/login", "warning", "แจ้งเตือน", "ยังไม่ได้เข้าสู่ระบบ");
    }
  }
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      name: this.state.name,

      file: this.state.file,
      file_name: this.state.file_name,
      file_date: this.state.file_date,
      file_type: this.state.file_type,

      detail_file: this.state.detail_file,
    };
    let result = await POST(tokens, "api/upload-work-file/create", body);
    if (result && result.status) {
      success_url("/upload");
    }
    this.setState({ loading: false });
  };
  import_file = async (e) => {
    if (e.target.id === "file") {
      await this.changePhoto(e.target.files[0]);
      this.setState({
        file_name: e.target.files[0].name.split(".")[0],
        file_date: format_date(new Date(), "yyyy-mm-dd hh:mm:ss", "en"),
        file_type: e.target.files[0].name.split(".").pop(),
      });
      document.getElementById("file").value = "";
      document.getElementById("file").file = null;
    } else {
      this.multiple_file(0, e.target.files.length, e.target.files);
    }
  };
  multiple_file = async (index, max, file) => {
    await this.changeMultiFile(file[index]);
    if (index + 1 < max) {
      setTimeout(() => {
        this.multiple_file(index + 1, max, file);
      }, 50);
    } else {
      document.getElementById("multifile").value = "";
      document.getElementById("multifile").file = null;
    }
  };
  changePhoto = async (file) => {
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({ file: reader.result.split(",")[1] });
    };
  };
  changeMultiFile = async (file) => {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let detail_file = this.state.detail_file;
        detail_file.push({
          file: reader.result.split(",")[1],
          file_type: file.name.split(".").pop(),
          file_name: file.name,
        });
        this.setState({ detail_file: detail_file });
      };
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        {/* BODY */}
        <div className="bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>อัพโหลดไฟล์</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>ชื่อ : {this.state.name}</b>
                  </h5>
                  {/* <h5 className="mb-3"><b>อัพโหลดไฟล์</b></h5> */}
                  {/* {this.state.file === "" ? (
                                        <div
                                            style={{ minHeight: "100%", borderRadius: 8 }}
                                            className="w-100 card border-2 border-primary d-flex align-items-center justify-content-center px-3 py-5 mb-3 pointer"
                                            onClick={() => {
                                                document.getElementById("file").click();
                                            }}>
                                            <label className="icon text-primary">{"\uf03e"}</label>
                                            <label className="text-primary">อัพโหลดภาพไฟล์</label>
                                        </div>
                                    ) : (
                                        <div
                                            className="w-100 card border-2 border-primary d-flex align-items-center justify-content-center p-2  mb-3"
                                            style={{ height: "100%" }}
                                        >
                                            <div className="position-relative w-100">
                                                <img
                                                    alt="ภาพไฟล์"
                                                    src={this.state.file.includes("https://") ? this.state.file : "data:image/png;base64," + this.state.file}
                                                    className="w-100 rounded overflow-hidden"
                                                    style={{ maxHeight: 300, objectFit: "contain" }}></img>
                                                <div className="icon bg-danger pointer position-absolute d-flex justify-content-center align-items-center text-light"
                                                    style={{ top: -8, right: -8, width: 30, height: 30, borderRadius: 15 }}
                                                    onClick={() => {
                                                        this.setState({
                                                            file: "",
                                                            file_name: "",
                                                            file_date: "",
                                                            file_type: "",
                                                        })
                                                    }}>
                                                    {"\uf00d"}
                                                </div>
                                            </div>
                                        </div>
                                    )} 
                                    <input className="d-none" type="file" id="file" accept="image/*" onChange={(e) => { this.import_file(e) }} />*/}
                  <div className="row">
                    {this.state.detail_file.map((item, index) => (
                      <div className="col-12 px-1">
                        <div className="w-100 card border-2 border-primary p-5 mb-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <span>{item.file_name}</span>
                            <div
                              className="icon pointer bg-danger wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle text-white"
                              onClick={() => {
                                let detail_file = this.state.detail_file;
                                detail_file.splice(index, 1);
                                this.setState({ detail_file: detail_file });
                              }}
                            >
                              {"\uf00d"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <input
                    className="d-none"
                    type="file"
                    id="multifile"
                    multiple
                    onChange={(e) => {
                      this.import_file(e);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary px-5"
                    onClick={() => {
                      document.getElementById("multifile").click();
                    }}
                  >
                    เพิ่มไฟล์
                  </button>
                  {this.state.detail_file.length !== 0 && (
                    <button
                      type="button"
                      className="btn btn-primary px-5"
                      onClick={() => {
                        // if (!this.state.name) {
                        //     alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                        //     return
                        // } else if (!this.state.file) {
                        //     alert("warning", "แจ้งเตือน", "กรุณาอัพโหลดไฟล์");
                        //     return
                        // }
                        this.Create();
                      }}
                    >
                      ส่ง
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

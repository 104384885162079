import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      search: "",

      data: null,
      data_role: [],

      modal_create: false,
      modal_update: false,

      emp_id: "",
      username: "",
      email: "",
      full_name: "",
      full_name_en: "",
      phone: "",
      role_id: "",
      password: "",
      confirm_password: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,
    };
  }
  async componentDidMount() {
    await this.GetAll();
    await this.GetRoleAll();
  }

  GetAll = async () => {
    let body = {
      data_search: this.state.data_search,
      emp_id: "",
    };
    let result = await GET(tokens, "api/employer/account-system-list/page/" + this.state.page, null);
    if (result && result.status) {
      this.setState({ data: result.data, end_index: result.count < result.end_index ? result.count : result.end_index, count: result.count, page_number: result.page_number });
    }
  };
  GetRoleAll = async () => {
    let body = {
      data_search: "",
      role_id: "",
    };
    let result = await GET(tokens, "api/role/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_role: result.data.map((e) => {
          return { value: e.role_id, label: e.role_name };
        }),
      });
    }
  };
  Create = async () => {
    if (this.state.full_name.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    } else if (this.state.username.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อผู้ใช้งาน");
      return;
    } else if (this.state.password.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
      return;
    }
    if (this.state.password !== this.state.confirm_password) {
      alert("primary", "แจ้งเตือน", "รหัสผ่านไม่ตรงกัน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      full_name: this.state.full_name,
      full_name_en: this.state.full_name_en,
      phone: this.state.phone,
      role_id: this.state.role_id ? this.state.role_id.value : "",
      user_type: "admin",
    };
    let result = await POST(tokens, "api/user/create", data);
    if (result && result.status) {
      success_url("/User/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    if (this.state.full_name.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    } else if (this.state.username.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อผู้ใช้งาน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      full_name: this.state.full_name,
      username: this.state.username,
      email: this.state.email,
      role_id: this.state.role_id ? this.state.role_id.value : "",
    };
    let result = await PUT(tokens, "api/user/update/" + this.state.emp_id, data);
    if (result && result.status) {
      success_url("/User/list");
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/user/delete/" + this.state.emp_id, null);
    if (result && result.status) {
      this.clear_form();
      success();
      this.GetAll();
    }
    this.setState({ loading: false, modal_delete: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="จัดการบัญชีระบบ" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>จัดการบัญชีระบบ</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    window.location.href = "/Customer/create"
                  }}
                >
                  <label className="icon">{"\u002B"}</label> สร้างบัญชีระบบ
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อบัญชี</th>
                      <th className="text-left text-white">ประเภท</th>
                      <th className="text-left text-white">เลขที่ผู้เสียภาษี</th>
                      <th className="text-left text-white">เบอร์โทร</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.full_name_th ? item.full_name_th : "-"}</td>
                          <td className="text-left">{item.emp_type ? item.emp_type : ""}</td>
                          <td className="text-left">{item.emp_code ? item.emp_code : "-"}</td>
                          <td className="text-left">{item.mobile_phone ? item.mobile_phone : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center update"
                                onClick={() => {
                                  window.location.href = "/Customer/update?id="+item.emp_id
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center delete"
                                onClick={() => {
                                  this.setState({ modal_delete: true, emp_id: item.emp_id, full_name_th: item.full_name_th });
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    {" "}
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ{" "}
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.GetAll();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* create */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.modal_create ? "เพิ่มผู้ใช้งาน" : "แก้ไขผู้ใช้งาน"}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <b>ชื่อ-นามสกุล{required}</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="ชื่อ-นามสกุล"
              onChange={(e) => {
                this.setState({ full_name: e.target.value });
              }}
              value={this.state.full_name}
            />
            <b>ชื่อ-นามสกุล Eng</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="ชื่อ-นามสกุล Eng"
              onChange={(e) => {
                this.setState({ full_name_en: e.target.value });
              }}
              value={this.state.full_name_en}
            />
            <b>อีเมล</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="อีเมล"
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              value={this.state.email}
            />
            <b>เบอร์โทรศัพท์</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="เบอร์โทรศัพท์"
              maxLength={10}
              onChange={(e) => {
                if (!float(e.target.value)) {
                  return;
                }
                this.setState({ phone: e.target.value });
              }}
              value={this.state.phone}
            />
            <b>สิทธิ์ผู้ใช้งาน</b>
            <Select
              className="form-control select-search mt-1 mb-3"
              styles={select_style}
              options={this.state.data_role}
              onChange={(e) => {
                this.setState({ role_id: e });
              }}
              value={this.state.role_id}
            ></Select>
            <b>รหัสผู้ใช้งาน{required}</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="รหัสผู้ใช้งาน"
              onChange={(e) => {
                this.setState({ username: e.target.value });
              }}
              value={this.state.username}
              readOnly={this.state.modal_update}
            />
            {this.state.modal_create && (
              <div>
                <b>ระบุรหัสผ่าน{required}</b>
                <input
                  className="form-control mt-1 mb-3"
                  type="password"
                  placeholder="รหัสผ่าน"
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  value={this.state.password}
                />
                <b>ระบุรหัสผ่านอีกครั้ง{required}</b>
                <input
                  className="form-control mt-1 mb-3"
                  type="password"
                  placeholder="รหัสผ่าน"
                  onChange={(e) => {
                    this.setState({ confirm_password: e.target.value });
                  }}
                  value={this.state.confirm_password}
                />
              </div>
            )}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.modal_update) {
                      this.Update();
                    } else {
                      this.Create();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบผู้ใช้งาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบผู้ใช้งาน “{this.state.full_name_th}”</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import LOGO from "../assets/images/logo.png";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  DateTimePicker,
  TimePicker,
  MonthPicker,
} from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";
import "../assets/css/index.css";
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_code: "",
      loading: false,
    };
  }

  componentDidMount() {
    // const token = localStorage.getItem("token");
    // const user = localStorage.getItem("user");
    // const permission = localStorage.getItem("permission");
    // if (token) {
    //   if (user.user_type === "customer") {
    //     window.location.href = "/Employer/list-rent";
    //   } else {
    //     localStorage.setItem("locale", "th");
    //     window.location.href = "/admin/dashboard";
    //   }
    // } else {
    //   removeStorage();
    // }
  }

  ForgotPassword = async () => {
    // if (this.state.username.trim() === "") {
    //   alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล/ชื่อผู้ใช้")
    //   return
    // } else if (this.state.password.trim() === "") {
    //   alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน")
    //   return
    // }
    // this.setState({ loading: true });
    // let data = {
    //   username: this.state.username,
    //   password: this.state.password,
    //   remember: true,
    // }
    // let result = await POST("", "v1/TH/login", data);
    // if (result && result.status) {
    //   let user = {
    //     user_id: result.data.detail.user_id,
    //     user_code: result.data.detail.user_code,
    //     user_type: result.data.detail.user_type,
    //     full_name: result.data.detail.full_name,
    //     address: result.data.detail.address,
    //     email: result.data.detail.email,
    //     phone: result.data.detail.phone,
    //     id_card: result.data.detail.id_card,
    //     address: result.data.detail.address,
    //     birthday: result.data.detail.birthday,
    //     district: result.data.detail.district,
    //     member_code: result.data.detail.member_code,
    //     member_id: result.data.detail.member_id,
    //     member_status: result.data.detail.member_status,
    //     picture: result.data.detail.picture,
    //     province: result.data.detail.province,
    //     sub_district: result.data.detail.sub_district,
    //     username: result.data.detail.username,
    //     zip_code: result.data.detail.zip_code,
    //   }

    //   localStorage.setItem("token", "token " + result.data.token);
    //   localStorage.setItem("user", JSON.stringify(user));

    //   if (!result.data.detail.user_type) {
    //     window.location.href = "/Employer/list-rent";
    //   } else {
    //     localStorage.setItem("permission", JSON.stringify(result.data.detail.role_data.permission));
    //     localStorage.setItem("locale", "th");
    //     window.location.href = "/admin/dashboard";
    //   }
    // }
    // this.setState({ loading: false });
    // if (this.state.username.trim() === "") {
    //   alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล/ชื่อผู้ใช้")
    //   return
    // } else if (this.state.password.trim() === "") {
    //   alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน")
    //   return
    // }
    // if (this.state.username !== "admin_grit" || this.state.password !== "grit1234") {
    //   alert("warning", "แจ้งเตือน", "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
    //   return
    // }
    // window.location.href = "/console";
    success_url("/login", "success", "สำเร็จ", "ระบบทำการส่งข้อมูลไปที่อีเมลขอท่านแล้ว");
  };
  render() {
    return (
      <div className="w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="row py-5 mx-0" style={{ height: "100vh" }}>
          <div className="col-12">
            <div className="d-flex justify-content-center mb-3">
              <img src={LOGO} style={{ width: 170 }} />
            </div>
            <h5 className="text-center mb-3">ระบบต่ออายุใบอนุญาตทำงานลูกจ้าง (4 สัญชาติ) ทางอิเล็กทรอนิกส์</h5>

            <div className="row mx-0">
              <div className="col-12 col-sm-9 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                <div className="card shadow border-0 mb-5 p-3" style={{ background: "#ffffff" }}>
                  <div className="w-100">
                    <h3 className="mb-3">
                      <b>กำหนดรหัสผ่าน</b>
                    </h3>
                    <hr />
                    <b>รหัสผู้ใช้งาน</b>
                    <input
                      className="form-control mt-1 mb-3"
                      type="text"
                      placeholder="รหัสผู้ใช้งาน"
                      onChange={(e) => {
                        this.setState({ user_code: e.target.value });
                      }}
                      value={this.state.user_code}
                    />
                  </div>
                  <div className="d-flex mt-5">
                    <div className="w-50 pe-1">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100 mb-3"
                        onClick={() => {
                          window.location.href = "/login";
                        }}
                      >
                        ยกเลิก
                      </button>
                    </div>
                    <div className="w-50 ps-1">
                      <button
                        type="button"
                        className="btn btn-primary w-100 mb-3"
                        onClick={() => {
                          this.ForgotPassword();
                        }}
                      >
                        ตรวจสอบ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_bt46,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_match: false,
      modal_update: false,
      modal_match2: false,
      modal_download: false,
      loading: false,
      data: [],

      data_search: "",

      count_e_work: 0,
      count_wp: 0,
      fw_data: [],
      checklist_data: [],
      receive_id: "",
      receive_code: "",
      receive_date: null,
      branch: "",
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      emp_number: "",
      process_matter: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: null,
      c_certificate_20: null,
      c_copy_committee: null,
      receive_status: 0,
      receive_date_s1: null,
      receive_by_s1: null,
      receive_date_s2: null,
      receive_by_s2: null,
      receive_date_s3: null,
      receive_by_s3: null,
      receive_date_s4: null,
      receive_by_s4: null,
      receive_date_s5: null,
      receive_by_s5: null,
      receive_date_s6: null,
      receive_by_s6: null,
      receive_date_s7: null,
      receive_by_s7: null,
      receive_date_s8: null,
      receive_by_s8: null,
      receive_date_s9: null,
      receive_by_s9: null,
      receive_date_s10: null,
      receive_by_s10: null,
      receive_date_s11: null,
      receive_by_s11: null,
      receive_date_s12: null,
      receive_by_s12: null,
      receive_date_s13: null,
      receive_by_s13: null,
      receive_date_s14: null,
      receive_by_s14: null,
      receive_date_s15: null,
      receive_by_s15: null,
      receive_date_s16: null,
      receive_by_s16: null,
      receive_date_s17: null,
      receive_by_s17: null,
      receive_date_s18: null,
      receive_by_s18: null,
      receive_date_s19: null,
      receive_by_s19: null,
      receive_date_s20: null,
      receive_by_s20: null,
      receive_date_s21: null,
      receive_by_s21: null,
      is_active: true,
      create_by: "",
      emp_data: null,
      menu_data: null,

      check_received_e_work: false,

      check_close: false,

      deliver_documents_s9: null,
      sending_document_date_s9: null,
      remark_s9: "",
      documents_s9: null,

      deliver_documents_s16: null,
      sending_document_date_s16: null,
      remark_s16: "",
      documents_s16: null,

      deliver_documents_s19: null,
      sending_document_date_s19: null,
      remark_s19: "",
      documents_s19: null,
      remark_s20: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,

      show_tab: 0,

      data_business_type: [],
      data_deputize: [],
      count_status: null,

      status_group: "",
      data_salary: [],

      data_workplace: [],

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      data_work_type: [],

      installment: []
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetWorkType();
    await this.GetBusinessType();
    await this.GetSalary();
    await this.GetAdmin();
    await this.GetPrefix();
    await this.Get();
    this.setState({ loading: false });
  }
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetAdmin = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
  };
  GetSalary = async () => {
    let body = {
      data_search: "",
      minimum_wage_id: "",
    };
    let result = await GET(tokens, "api/minimum-wage/list", body);
    if (result && result.status) {
      this.setState({
        data_salary: result.data,
      });
    }
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
          };
        }),
      });
    }
  };
  Get = async () => {
    let body = {
      data_search: this.state.data_search,
      receive_id: "",
      menu: "MOU",
      status_group: this.state.status_group,
      menu_id: 1,
    };
    let result = await GET(tokens, "api/receive-matter/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/foreign-worker/delete/" + this.state.receive_id, null);
    if (result && result.status) {
      success_url("/RecieveMOU/list");
    }
    this.setState({ loading: false });
  };
  clear_form = () => {
    this.setState({
      modal_delete: false,
      modal_adjust: false,
      modal_tranfer: false,

      loading: false,

      code_id: "",
      full_name: "",
    });
  };
  Status = (item) => {
    let component = null;
    switch (item.receive_status) {
      case 1:
        component = (
          <div
            className="pointer bg-danger-light rounded hpx-30 px-2 py-1 wpx-100"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_match: true });
            }}
          >
            รอ Matching
          </div>
        );
        break;
      case 2:
        component = <label>มอบอำนาจ Matching</label>;
        break;
      case 3:
        component = <label>ใช้ User ของนายจ้าง</label>;
        break;
      case 4:
        component = <label className="text-danger">Machting ไม่ได้</label>;
        break;
      case 5:
        component = <label className="text-success">Machting สำเร็จ</label>;
        break;
      case 6:
        component = <label className="text-info">บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}</label>;
        break;
      case 7:
        component = (
          <div
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-210"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            รอตรวจสอบ บต.46+มอบอำนาจ
          </div>
        );
        break;
      case 8:
        component = (
          <div
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-170"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            เอกสารไม่ถูกต้อง บต.46
          </div>
        );
        break;
      case 9:
        component = (
          <div
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-230"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            เอกสาร บต.46+มอบอำนาจ ถูกต้อง
          </div>
        );
        break;
      case 10:
        component = (
          <div
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-230"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ส่งเอกสาร บต.46+มอบอำนาจ แล้ว
          </div>
        );
        break;
      case 11:
        component =
          item.fw_data && item.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 ? (
            <div
              className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-320"
              onClick={() => {
                this.getById(item.receive_id);
                this.setState({ modal_update: true });
              }}
            >
              เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย
            </div>
          ) : (
            <div
              className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-280"
              onClick={() => {
                this.getById(item.receive_id);
                this.setState({ modal_update: true });
              }}
            >
              รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย
            </div>
          );
        break;
      case 12:
        component = (
          <div
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 wpx-170"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            เอกสารถูกต้อง พร้อมยื่น
          </div>
        );
        break;
      case 13:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-130 text-center"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ได้เลข WP {item.count_wp === item.total_qty ? "" : "(" + item.count_wp + "/" + item.total_qty + ")"}
          </div>
        );
        break;
      case 14:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-170"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ได้เลข WP แล้ว รออนุมัติ
          </div>
        );
        break;
      case 15:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-300"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน
          </div>
        );
        break;
      case 16:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-170"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            แนบใบเสร็จแล้ว รอจัดส่ง
          </div>
        );
        break;
      case 17:
        component = (
          <div
            className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-250"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ส่งใบเสร็จให้ลูกค้าแล้ว รอออก eWork
          </div>
        );
        break;
      case 18:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-180 text-center"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ออก eWork แล้ว {item.count_e_work === item.total_qty ? "" : "(" + item.count_e_work + "/" + item.total_qty + ")"}
          </div>
        );
        break;
      case 19:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-170"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            รับ eWork แล้ว รอจัดส่ง
          </div>
        );
        break;
      case 20:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-110"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ส่ง eWork แล้ว
          </div>
        );
        break;
      case 21:
        component = (
          <div
            className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-60"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ปิดงาน
          </div>
        );
        break;
        case 22:
          component = (
            <div
              className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-100"
              onClick={() => {
                this.getById(item.receive_id);
                this.setState({ modal_update: true });
              }}
            >
              เปิดเรื่องแล้ว
            </div>
          );
          break;
      default:
        break;
    }
    return component;
  };
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      this.setState({
        count_e_work: result.data.count_e_work,
        count_wp: result.data.count_wp,
        checklist_data: result.data.checklist_data,
        fw_data: result.data.fw_data,
        receive_id: result.data.receive_id,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch,
        immigration_office: result.data.immigration_office,
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.broker,
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        r_username: result.data.r_username,
        r_password: result.data.r_password,
        receive_status: result.data.receive_status,
        receive_date_s1: result.data.receive_date_s1,
        receive_by_s1: result.data.receive_by_s1,
        receive_date_s2: result.data.receive_date_s2,
        receive_by_s2: result.data.receive_by_s2,
        receive_date_s3: result.data.receive_date_s3,
        receive_by_s3: result.data.receive_by_s3,
        receive_date_s4: result.data.receive_date_s4,
        receive_by_s4: result.data.receive_by_s4,
        receive_date_s5: result.data.receive_date_s5,
        receive_by_s5: result.data.receive_by_s5,
        receive_date_s6: result.data.receive_date_s6,
        receive_by_s6: result.data.receive_by_s6,
        receive_date_s7: result.data.receive_date_s7,
        receive_by_s7: result.data.receive_by_s7,
        receive_date_s8: result.data.receive_date_s8,
        receive_by_s8: result.data.receive_by_s8,
        receive_date_s9: result.data.receive_date_s9,
        receive_by_s9: result.data.receive_by_s9,
        receive_date_s10: result.data.receive_date_s10,
        receive_by_s10: result.data.receive_by_s10,
        receive_date_s11: result.data.receive_date_s11,
        receive_by_s11: result.data.receive_by_s11,
        receive_date_s12: result.data.receive_date_s12,
        receive_by_s12: result.data.receive_by_s12,
        receive_date_s13: result.data.receive_date_s13,
        receive_by_s13: result.data.receive_by_s13,
        receive_date_s14: result.data.receive_date_s14,
        receive_by_s14: result.data.receive_by_s14,
        receive_date_s15: result.data.receive_date_s15,
        receive_by_s15: result.data.receive_by_s15,
        receive_date_s16: result.data.receive_date_s16,
        receive_by_s16: result.data.receive_by_s16,
        receive_date_s17: result.data.receive_date_s17,
        receive_by_s17: result.data.receive_by_s17,
        receive_date_s18: result.data.receive_date_s18,
        receive_by_s18: result.data.receive_by_s18,
        receive_date_s19: result.data.receive_date_s19,
        receive_by_s19: result.data.receive_by_s19,
        receive_date_s20: result.data.receive_date_s20,
        receive_by_s20: result.data.receive_by_s20,
        receive_date_s21: result.data.receive_date_s21,
        receive_by_s21: result.data.receive_by_s21,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
        data_deputize: result.data.dpz_data ? result.data.dpz_data : [],
        menu_data: result.data.menu_data,

        deliver_documents_s9: result.data.deliver_documents_s9,
        sending_document_date_s9: result.data.sending_document_date_s9,
        remark_s9: result.data.remark_s9,
        documents_s9: result.data.documents_s9,

        deliver_documents_s16: result.data.deliver_documents_s16,
        sending_document_date_s16: result.data.sending_document_date_s16,
        remark_s16: result.data.remark_s16,
        documents_s16: result.data.documents_s16,

        deliver_documents_s19: result.data.deliver_documents_s19,
        sending_document_date_s19: result.data.sending_document_date_s19,
        remark_s19: result.data.remark_s19,
        documents_s19: result.data.documents_s19,
        remark_s20: result.data.remark_s20,

        check_received_e_work: false,

        check_close: false,

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: result.data.witness_power_2_en,
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,
      });
      if(result.data.quotation_id){
        let body_2 = {
          data_search: "",
          quotation_id: result.data.quotation_id,
          menu: "",
          holder: 1,
        };
        let result_2 = await GET(tokens, "api/setting/quotation/list/page/0", body_2);
        if (result_2 && result_2.status) { 
console.log(result_2.data)
this.setState({installment:result_2.data.installment})
        }
      }else{
        this.setState({installment: []})
      }
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    // if (this.state.receive_status === 9 &&
    //     this.state.fw_data.filter(e => !e.bt_46_document || !e.power_attorney_document).length === 0) {
    //     receive_status = 15;
    // }
    // if (this.state.receive_status === 9) {
    //     receive_status = 15;
    // }
    if (this.state.receive_status === 9) {
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 10;
    }
    if (this.state.receive_status === 10 && this.state.fw_data.filter((e) => !e.af_bt_46_document).length === 0) {
      receive_status = 11;
    }
    // if (this.state.receive_status === 11) {
    //     receive_status = 12;
    // }
    if (this.state.fw_data.filter((e) => e.wp_number !== "" && e.wp_number !== null).length !== 0 && this.state.receive_status === 12) {
      receive_status = 13;
    }
    if (this.state.fw_data.filter((e) => !e.wp_number).length === 0 && this.state.receive_status === 12) {
      receive_status = 14;
    }
    if (this.state.fw_data.filter((e) => !e.wp_number).length === 0 && this.state.receive_status === 13) {
      receive_status = 14;
    }

    if (this.state.receive_status === 14 && this.state.fw_data.filter((e) => !e.bt_50_document || !e.fee_document || !e.is_download_s14).length === 0) {
      receive_status = 16;
    }

    if (this.state.receive_status === 16) {
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 17;
    }
    if (this.state.receive_status === 17 && this.state.fw_data.filter((e) => e.e_work_document || e.e_work_card_issued || e.e_work_card_received).length !== 0) {
      receive_status = 18;
    }
    if (this.state.receive_status === 17 && this.state.fw_data.filter((e) => !e.e_work_document || !e.e_work_card_issued || !e.e_work_card_received).length === 0) {
      receive_status = 19;
    }
    if (this.state.receive_status === 18 && this.state.fw_data.filter((e) => !e.e_work_document || !e.e_work_card_issued || !e.e_work_card_received).length === 0) {
      receive_status = 19;
    }

    if (this.state.receive_status === 19) {
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 20;
    }

    if (this.state.receive_status === 20 && this.state.check_close) {
      receive_status = 21;
    }

    let body = {
      receive_status: receive_status,
      fw_data: this.state.fw_data,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveMOU/list");
    }
    this.setState({ loading: false });
  };
  Update_up = async () => {
    let body = {
      r_username: this.state.r_username,
      r_password: this.state.r_password,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveMOU/list");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    await this.changePhoto(e.target.files[0], type);
    if (type === "bt_46_document") {
      document.getElementById("file_bt_46_document").value = "";
      document.getElementById("file_bt_46_document").file = null;
    } else if (type === "power_attorney_document") {
      document.getElementById("file_power_attorney_document").value = "";
      document.getElementById("file_power_attorney_document").file = null;
    } else if (type === "af_bt_46_document") {
      document.getElementById("file_af_bt_46_document").value = "";
      document.getElementById("file_af_bt_46_document").file = null;
    } else if (type === "af_power_attorney_document") {
      document.getElementById("file_af_power_attorney_document").value = "";
      document.getElementById("file_af_power_attorney_document").file = null;
    } else if (type === "af_contract_document") {
      document.getElementById("file_af_contract_document").value = "";
      document.getElementById("file_af_contract_document").file = null;
    } else if (type === "bt_50_document") {
      document.getElementById("file_bt_50_document").value = "";
      document.getElementById("file_bt_50_document").file = null;
    } else if (type === "fee_document") {
      document.getElementById("file_fee_document").value = "";
      document.getElementById("file_fee_document").file = null;
    } else if (type === "receipt_document") {
      document.getElementById("file_receipt_document").value = "";
      document.getElementById("file_receipt_document").file = null;
    } else if (type === "work_permit_document") {
      document.getElementById("file_work_permit_document").value = "";
      document.getElementById("file_work_permit_document").file = null;
    } else if (type === "e_work_document") {
      document.getElementById("file_e_work_document").value = "";
      document.getElementById("file_e_work_document").file = null;
    } else if (type === "e_received_document") {
      document.getElementById("file_e_received_document").value = "";
      document.getElementById("file_e_received_document").file = null;
    } else if (type === "documents_s9") {
      document.getElementById("file_documents_s9").value = "";
      document.getElementById("file_documents_s9").file = null;
    } else if (type === "documents_s16") {
      document.getElementById("file_documents_s16").value = "";
      document.getElementById("file_documents_s16").file = null;
    } else if (type === "documents_s19") {
      document.getElementById("file_documents_s19").value = "";
      document.getElementById("file_documents_s19").file = null;
    }
  };
  changePhoto = async (file, type) => {
    if (!file.type.includes("pdf") && !file.type.includes("image")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (file.type.includes("pdf")) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        let fw_data = this.state.fw_data;
        if (type === "bt_46_document") {
          fw_data[this.state.index].bt_46_document = e.target.result.split(",")[1];
        } else if (type === "power_attorney_document") {
          fw_data[this.state.index].power_attorney_document = e.target.result.split(",")[1];
        } else if (type === "af_bt_46_document") {
          fw_data[this.state.index].af_bt_46_document = e.target.result.split(",")[1];
        } else if (type === "af_power_attorney_document") {
          fw_data[this.state.index].af_power_attorney_document = e.target.result.split(",")[1];
        } else if (type === "af_contract_document") {
          fw_data[this.state.index].af_contract_document = e.target.result.split(",")[1];
        } else if (type === "bt_50_document") {
          fw_data[this.state.index].bt_50_document = e.target.result.split(",")[1];
        } else if (type === "fee_document") {
          fw_data[this.state.index].fee_document = e.target.result.split(",")[1];
        } else if (type === "receipt_document") {
          fw_data[this.state.index].receipt_document = e.target.result.split(",")[1];
        } else if (type === "work_permit_document") {
          fw_data[this.state.index].work_permit_document = e.target.result.split(",")[1];
        } else if (type === "e_work_document") {
          fw_data[this.state.index].e_work_document = e.target.result.split(",")[1];
        } else if (type === "e_received_document") {
          fw_data[this.state.index].e_received_document = e.target.result.split(",")[1];
        } else if (type === "documents_s9") {
          this.setState({ documents_s9: e.target.result.split(",")[1] });
        } else if (type === "documents_s16") {
          this.setState({ documents_s16: e.target.result.split(",")[1] });
        } else if (type === "documents_s19") {
          this.setState({ documents_s19: e.target.result.split(",")[1] });
        }
        this.setState({ fw_data: fw_data });
      };
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let files = await convert_imagetopdf(e.target.result);
        let fw_data = this.state.fw_data;
        if (type === "bt_46_document") {
          fw_data[this.state.index].bt_46_document = files;
        } else if (type === "power_attorney_document") {
          fw_data[this.state.index].power_attorney_document = files;
        } else if (type === "af_bt_46_document") {
          fw_data[this.state.index].af_bt_46_document = files;
        } else if (type === "af_power_attorney_document") {
          fw_data[this.state.index].af_power_attorney_document = files;
        } else if (type === "af_contract_document") {
          fw_data[this.state.index].af_contract_document = files;
        } else if (type === "bt_50_document") {
          fw_data[this.state.index].bt_50_document = files;
        } else if (type === "fee_document") {
          fw_data[this.state.index].fee_document = files;
        } else if (type === "receipt_document") {
          fw_data[this.state.index].receipt_document = files;
        } else if (type === "work_permit_document") {
          fw_data[this.state.index].work_permit_document = files;
        } else if (type === "e_work_document") {
          fw_data[this.state.index].e_work_document = files;
        } else if (type === "e_received_document") {
          fw_data[this.state.index].e_received_document = files;
        } else if (type === "documents_s9") {
          this.setState({ documents_s9: files });
        } else if (type === "documents_s16") {
          this.setState({ documents_s16: files });
        } else if (type === "documents_s19") {
          this.setState({ documents_s19: files });
        }
        this.setState({ fw_data: fw_data });
      };
    }
  };
  download = (list) => {
    let filename = "file";
    const zip = new JSZip();
    const folder = zip.folder("file");
    list.forEach((url) => {
      const blobPromise = fetch(url.link).then(function (response) {
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      });
      const name = url.name + url.link.substring(url.link.lastIndexOf("."));
      folder.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => saveAs(blob, filename))
      .catch((e) => console.log(e));
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ขอใบอนุญาตทำงาน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>ขอใบอนุญาตทำงาน</b>
              </h3>
              {/* <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    window.location.href = "/RecieveMOU/create";
                  }}
                >
                  <label className="icon">{"\u002B"}</label> เพิ่ม
                </button>
              </div> */}
            </div>
            <div className="row">
              <div className="col-6 col-lg-3 mb-3">
                <div
                  className={this.state.status_group === "" || this.state.status_group === 1 ? "card border-0 p-3 bg-warning-light text-orange pointer" : "card border-0 p-3 bg-secondary-light text-dark pointer"}
                  onClick={() => {
                    this.setState({ status_group: this.state.status_group === 1 ? "" : 1, loading: true });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <label>รอส่ง</label>
                    <h3>
                      <b>{this.state.count_status ? this.state.count_status.waiting_sent : "0"}</b>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3 mb-3">
                <div
                  className={this.state.status_group === "" ? "card border-0 p-3 bg-success-light text-success pointer" : "card border-0 p-3 bg-secondary-light text-dark pointer"}
                  onClick={() => {
                    window.location.href = "/ApproveQC/list";
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <label>รอ QC</label>
                    <h3>
                      <b>{this.state.count_status ? this.state.count_status.waiting_qc : "0"}</b>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3 mb-3">
                <div
                  className={this.state.status_group === "" || this.state.status_group === 2 ? "card border-0 p-3 bg-cyan-light text-primary pointer" : "card border-0 p-3 bg-secondary-light text-dark pointer"}
                  onClick={() => {
                    this.setState({ status_group: this.state.status_group === 2 ? "" : 2, loading: true });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <label>รอคีย์ข้อมูลลูกจ้าง</label>
                    <h3>
                      <b>{this.state.count_status ? this.state.count_status.waiting_emp : "0"}</b>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3 mb-3">
                <div
                  className={this.state.status_group === "" || this.state.status_group === 3 ? "card border-0 p-3 bg-danger-light text-danger pointer" : "card border-0 p-3 bg-secondary-light text-dark pointer"}
                  onClick={() => {
                    this.setState({ status_group: this.state.status_group === 3 ? "" : 3, loading: true });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <label>รอแนบเอกสาร</label>
                    <h3>
                      <b>{this.state.count_status ? this.state.count_status.waiting_document : "0"}</b>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่รับเรื่อง</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">สถานะงาน</th>
                      <th className="text-left text-white">ผู้บันทึกข้อมูล</th>
                      <th className="text-left text-white"></th>
                      <th className="text-center text-white">ใบรับเรื่อง</th>
                      <th className="text-center text-white">Name List</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.receive_date ? format_date(item.receive_date) : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.full_name_th : "-"}</td>
                          <td className="text-left">{this.Status(item)}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-center">
                            <div className="w-100 d-flex">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-success-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  await this.getById(item.receive_id);
                                  setTimeout(() => {
                                    this.GetWorkplace(this.state.emp_data.emp_id);
                                    this.setState({ modal_download: true });
                                  }, 10);
                                }}
                              >
                                <label className="pointer icon text-success p-2">{"\uf019"}</label>
                              </div>
                              {(item.r_username || item.r_password) && (
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={async () => {
                                    await this.getById(item.receive_id);
                                    setTimeout(() => {
                                      this.GetWorkplace(this.state.emp_data.emp_id);
                                      this.setState({ modal_match2: true });
                                    }, 10);
                                  }}
                                >
                                  <label className="pointer icon text-orange p-2">{"\uf084"}</label>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="text-center">
                            <div
                              className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-80 m-auto"
                              onClick={() => {
                                window.location.href = "/RecieveMOU/update?id=" + item.receive_id;
                              }}
                            >
                              ใบรับเรื่อง
                            </div>
                          </td>
                          <td className="text-center">
                            <div
                              className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-100 m-auto"
                              onClick={() => {
                                window.location.href = "/Employer/update?receive_id=" + item.receive_id + "&type=name_list&receive_code=" + item.receive_code;
                              }}
                            >
                              Name List
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <input
            className="d-none"
            type="file"
            id="file_bt_46_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_46_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_af_bt_46_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_bt_46_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_af_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_af_contract_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_contract_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_bt_50_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_50_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_fee_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "fee_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_receipt_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "receipt_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_work_permit_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "work_permit_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_e_work_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "e_work_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_e_received_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "e_received_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_documents_s9"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s9");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_documents_s16"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s16");
            }}
          />
          <input
            className="d-none"
            type="file"
            id="file_documents_s19"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s19");
            }}
          />
        </div>
        {/* Matching */}
        <Modal
          show={this.state.modal_match}
          onHide={() => {
            this.setState({ modal_match: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Matching {this.state.receive_code ? this.state.receive_code : ""}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              เลขประจำตัวนายจ้าง <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 5 });
                }}
                checked={this.state.receive_status === 5}
              />
              Matching ได้
              <input
                className="mt-1 mx-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 4 });
                }}
                checked={this.state.receive_status === 4}
              />
              Matching ไม่ได้
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        {/* สถานะงาน */}
        <Modal
          show={this.state.modal_update}
          onHide={() => {
            this.setState({ modal_update: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>สถานะงาน {this.state.receive_code ? this.state.receive_code : ""}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {/* ผู้มีอำนาจผูกพัน */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน</b></div>
                                                        {this.state.emp_data.binding_authority_th ? this.state.emp_data.binding_authority_th : "-"}
                                                    </div> */}
                          {/* ตำแหน่ง */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ตำแหน่ง</b></div>
                                                        {this.state.emp_data.position_th ? this.state.emp_data.position_th : "-"}
                                                    </div> */}
                          {/* ผู้มีอำนาจผูกพัน (English) */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน (English)</b></div>
                                                        {this.state.emp_data.binding_authority_en ? this.state.emp_data.binding_authority_en : "-"}
                                                    </div> */}
                          {/* ตำแหน่ง (English) */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ตำแหน่ง (English)</b></div>
                                                        {this.state.emp_data.position_en ? this.state.emp_data.position_en : "-"}
                                                    </div> */}
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* Matching */}
              {this.state.receive_status >= 5 && (
                <div>
                  <div
                    className={this.state.show_tab === 5 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 5 ? 0 : 5,
                      });
                    }}
                  >
                    {this.state.receive_date_s3 ? format_date(this.state.receive_date_s3, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s3 + " ใช้ USer Pass ของนายจ้าง" : this.state.receive_date_s5 ? format_date(this.state.receive_date_s5, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s5 + " Matching" : ""}
                  </div>
                  {this.state.show_tab === 5 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <b>{this.state.receive_date_s3 ? "ใช้ USer Pass ของนายจ้าง วันที่ " + format_date(this.state.receive_date_s3, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s3 : this.state.receive_date_s5 ? "Matching วันที่ " + format_date(this.state.receive_date_s5, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s5 : ""}</b>
                        {/* <div
                            className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              this.getById(this.state.receive_id);
                              this.setState({ modal_match: true,modal_update:false,receive_status:1 });
                            }}
                          >
                            <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* status 6 บันทึกลูกจ้างสำเร็จ */}
              {this.state.receive_status >= 6 && (
                <div>
                  <div
                    className={this.state.show_tab === 6 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 6 ? 0 : 6,
                      });
                    }}
                  >
                    {this.state.receive_date_s6 ? format_date(this.state.receive_date_s6, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s6} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 6 && (
                    <div className="card-body">
                      <b>
                        บันทึกลูกจ้างสำเร็จ วันที่ {this.state.receive_date_s6 ? format_date(this.state.receive_date_s6, "dd/mm/yyyy") : ""} {this.state.receive_by_s6}
                      </b>
                    </div>
                  )}
                </div>
              )}
              {/* status 7 แนบเอกสาร บต.46 + ใบมอบอำนาจแล้ว  */}
              {this.state.receive_status >= 7 && (
                <div>
                  <div
                    className={this.state.show_tab === 7 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 7 ? 0 : 7,
                      });
                    }}
                  >
                    {this.state.receive_date_s7 ? format_date(this.state.receive_date_s7, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s7} ตรวจสอบ บต.46+มอบอำนาจ
                  </div>
                  {this.state.show_tab === 7 && (
                    <div className="card-body">
                      {/* <table className="table table-borderless table-striped">
                                                <thead className="bg-primary">
                                                    <tr>
                                                        <th className="text-center text-white">ลำดับ</th>
                                                        <th className="text-left text-white">ชื่อ-นามสกุล</th>
                                                        <th className="text-left text-white">เลขบัตรประจำตัว</th>
                                                        <th className="text-left text-white">บต.46</th>
                                                        <th className="text-left text-white">ใบมอบอำนาจ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.fw_data && this.state.fw_data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center align-middle">{index + 1}</td>
                                                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                                            <td className="text-center align-middle">
                                                                {item.bt_46_document && (
                                                                    <a target="_blank" download href={(item.bt_46_document && item.bt_46_document.includes("://")) ? item.bt_46_document : "data:application/pdf;base64," + item.bt_46_document}>บต.46.pdf</a>
                                                                )}
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {item.power_attorney_document && (
                                                                    <a target="_blank" download href={(item.power_attorney_document && item.power_attorney_document.includes("://")) ? item.power_attorney_document : "data:application/pdf;base64," + item.power_attorney_document}>ใบมอบอำนาจ.pdf</a>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table> */}
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_date_s9 ? format_date(this.state.receive_date_s9, "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_by_s9}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* status 10 ส่งเอกสาร บต.46+มอบอำนาจ แล้ว  */}
              {this.state.receive_status >= 10 && this.state.receive_status !== 15 && (
                <div>
                  <div
                    className={this.state.show_tab === 10 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 10 ? 0 : 10,
                      });
                    }}
                  >
                    {this.state.receive_date_s10 ? format_date(this.state.receive_date_s10, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s10}
                    การจัดส่งเอกสาร บต.46 + มอบอำนาจ แล้ว
                  </div>
                  {this.state.show_tab === 10 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_by_s10}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 9 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* status 11 รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย */}
              {this.state.receive_status >= 11 && this.state.receive_status !== 15 && (
                <div>
                  <div
                    className={this.state.show_tab === 11 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 11 ? 0 : 11,
                      });
                    }}
                  >
                    {this.state.receive_date_s11 ? format_date(this.state.receive_date_s11, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s11} เอกสาร บต.46 + ใบมอบอำนาจ ที่ลูกค้าเซ็นแล้วเข้าสู่ระบบ
                  </div>
                  {this.state.show_tab === 11 && (
                    <div className="card-body">
                      <div className="w-100 d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 10 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>
                            <th className="text-left text-white">ใบมอบอำนาจ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.af_bt_46_document && (
                                    <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                      บต.46 + สัญญาจ้าง.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.af_power_attorney_document && (
                                    <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                                      ใบมอบอำนาจ.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 16 จัดส่งเอกสารใบเสร็จ  */}
              {this.state.receive_status >= 16 && (
                <div>
                  <div
                    className={this.state.show_tab === 16 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 16 ? 0 : 16,
                      });
                    }}
                  >
                    {this.state.receive_date_s16 ? format_date(this.state.receive_date_s16, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s16} เอกสารใบเสร็จ + ทะเบียนใบอนุญาตทำงาน
                  </div>
                  {this.state.show_tab === 16 && (
                    <div className="card-body">
                      <div className="w-100 d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 14 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">ใบเสร็จรับเงิน</th>
                            <th className="text-left text-white">ทะเบียนใบอนุญาตทำงาน</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.receipt_document && (
                                    <a target="_blank" download href={item.receipt_document && item.receipt_document.includes("://") ? item.receipt_document : "data:application/pdf;base64," + item.receipt_document}>
                                      ใบเสร็จรับเงิน.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.work_permit_document && (
                                    <a target="_blank" download href={item.work_permit_document && item.work_permit_document.includes("://") ? item.work_permit_document : "data:application/pdf;base64," + item.work_permit_document}>
                                      ทะเบียนใบอนุญาตทำงาน.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 17 ส่งใบเสร็จให้ลูกค้าแล้ว */}
              {this.state.receive_status >= 17 && (
                <div>
                  <div
                    className={this.state.show_tab === 17 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 17 ? 0 : 17,
                      });
                    }}
                  >
                    {this.state.receive_date_s17 ? format_date(this.state.receive_date_s17, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s17} ส่งเอกสารเอกสารใบเสร็จ + ทะเบียนใบอนุญาตทำงาน แล้ว
                  </div>
                  {this.state.show_tab === 17 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex mb-3">
                            <label className="wpx-150">ใบเสร็จ</label>
                            <label>
                              วันที่แนบไฟล์ {this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} ผู้แนบไฟล์ {this.state.receive_by_s17}
                            </label>
                          </div>
                          <b className="mb-3">การจัดส่งเอกสารใบเสร็จ</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_by_s17}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 16 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* status 19 รับ eWork แล้ว รอจัดส่ง   */}
              {(this.state.receive_status >= 19 || (this.state.receive_status === 18 && this.state.fw_data.filter((e) => !e.e_work_card_issued).length === 0)) && (
                <div>
                  {this.state.receive_status === 18 ? (
                    <div
                      className={this.state.show_tab === 19 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                      onClick={() => {
                        this.setState({
                          show_tab: this.state.show_tab === 19 ? 0 : 19,
                        });
                      }}
                    >
                      {this.state.receive_date_s18 ? format_date(this.state.receive_date_s18, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s18} ออก eWork แล้ว
                    </div>
                  ) : (
                    <div
                      className={this.state.show_tab === 19 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                      onClick={() => {
                        this.setState({
                          show_tab: this.state.show_tab === 19 ? 0 : 19,
                        });
                      }}
                    >
                      {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} ออก eWork แล้ว
                    </div>
                  )}
                  {this.state.show_tab === 19 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 18, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">eWork</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  <div className="d-flex align-items-center ps-4">
                                    <input className="mt-1 me-3" type="checkbox" checked={item.e_work_card_issued === true} disabled />
                                    ออกบัตรแล้ว
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 19 รับ eWork แล้ว รอจัดส่ง   */}
              {this.state.receive_status >= 19 && (
                <div>
                  <div
                    className={this.state.show_tab === 21 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 21 ? 0 : 21,
                      });
                    }}
                  >
                    {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} รับ eWork แล้ว
                  </div>
                  {this.state.show_tab === 21 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 18, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">รับ eWork</th>
                            <th className="text-left text-white">บัตร eWork</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  <div className="d-flex align-items-center ps-4">
                                    <input className="mt-1 me-3" type="checkbox" checked={item.e_work_card_received === true} disabled />
                                    รับบัตรแล้ว
                                  </div>
                                </td>
                                <td className="text-left align-middle">
                                  {item.e_received_document && (
                                    <a className="mx-3" target="_blank" download href={item.e_received_document && item.e_received_document.includes("://") ? item.e_received_document : "data:application/pdf;base64," + item.e_received_document}>
                                      เอกสารแนบ.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 20 ส่ง eWork แล้ว  */}
              {this.state.receive_status >= 20 && (
                <div>
                  <div
                    className={this.state.show_tab === 20 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 20 ? 0 : 20,
                      });
                    }}
                  >
                    {this.state.receive_date_s20 ? format_date(this.state.receive_date_s20, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s20} การจัดส่งเอกสาร eWork
                  </div>
                  {this.state.show_tab === 20 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร eWork</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_by_s20}
                          </label>
                          {this.state.documents_s19 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 19, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* status 21 ปิดงาน  */}
              {this.state.receive_status >= 21 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_date_s21 ? format_date(this.state.receive_date_s21, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s21} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 7 รอตรวจสอบ บต.46+มอบอำนาจ  */}
            {this.state.receive_status === 7 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รอตรวจสอบ บต.46 + หนังสือมอบอำนาจ </b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>
                      <th className="text-left text-white">ใบมอบอำนาจ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.bt_46_document && (
                              <a target="_blank" download href={item.bt_46_document && item.bt_46_document.includes("://") ? item.bt_46_document : "data:application/pdf;base64," + item.bt_46_document}>
                                บต.46 + สัญญาจ้าง.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.power_attorney_document && (
                              <a target="_blank" download href={item.power_attorney_document && item.power_attorney_document.includes("://") ? item.power_attorney_document : "data:application/pdf;base64," + item.power_attorney_document}>
                                ใบมอบอำนาจ.pdf
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 8 เอกสารไม่ถูกต้อง บต.46  */}
            {this.state.receive_status === 8 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต - ใบมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต.46 ไม่ถูกต้อง</label>
                <br />
                {/* {this.state.fw_data.map( (item) => (
                  <div>
                    <label className="text-danger mb-2">ชื่อลูกจ้าง : {item.first_name_th} {item.last_name_th} : {item.remark_document}</label>
                    <br />
                  </div>
                ))} */}
                <label>
                  ผู้ตรวจสอบ {this.state.receive_by_s8} วันที่ตรวจสอบ {this.state.receive_date_s8 ? format_date(this.state.receive_date_s8, "dd/mm/yyyy") : ""}
                </label>
              </div>
            )}
            {/* status 9 เอกสาร บต.46+มอบอำนาจ ถูกต้อง   */}
            {this.state.receive_status === 9 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสาร บต.46 + ใบมอบอำนาจ ให้ลูกค้า</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสารให้ลูกค้า{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Line" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s9 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s9: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s9 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s9: e });
                      }}
                      value={this.state.sending_document_date_s9}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s9 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s9").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s9: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s9: e.target.value });
                      }}
                      value={this.state.remark_s9}
                      rows={5}
                    />
                  </div>
                  <div>
                  {this.state.installment.length !== 0 && this.state.installment.filter(e=>e.paid === this.state.installment.length) ? (
                    <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span>
                  ) : (
                    <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>
                  ) }
                  </div>
                </div>
              </div>
            )}
            {/* status 10 ส่งเอกสาร บต.46+มอบอำนาจ แล้ว   */}
            {this.state.receive_status === 10 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>แนบ เอกสาร บต.46 + ใบมอบอำนาจ ที่ลูกค้าเซ็นแล้วเข้าสู่ระบบ </b>
                  </h6>
                  <div className="d-flex align-items-center">
                    {this.state.fw_data[0] && !this.state.fw_data[0].af_power_attorney_document ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          this.setState({ index: 0 });
                          document.getElementById("file_af_power_attorney_document").click();
                        }}
                      >
                        แนบไฟล์ ใบมอบอำนาจ
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.fw_data[0].af_power_attorney_document && this.state.fw_data[0].af_power_attorney_document.includes("://") ? this.state.fw_data[0].af_power_attorney_document : "data:application/pdf;base64," + this.state.fw_data[0].af_power_attorney_document}>
                          ใบมอบอำนาจ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            let fw_data = this.state.fw_data;
                            fw_data[0].af_power_attorney_document = "";
                            this.setState({ fw_data: fw_data });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      {/* <th className="text-left text-white">บต.46</th> */}
                      <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>
                      {/* <th className="text-left text-white">สัญญาจ้าง</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div className="d-flex align-items-center">
                              {!item.af_bt_46_document ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  accept="application/pdf"
                                  onClick={() => {
                                    this.setState({ index: index });
                                    document.getElementById("file_af_bt_46_document").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-start">
                                  <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                    บต.46 + สัญญาจ้าง.pdf
                                  </a>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                    onClick={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].af_bt_46_document = "";
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          {/* <td className="text-left align-middle">
                            <div className="d-flex align-items-center">
                              {!item.af_contract_document ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  accept="application/pdf"
                                  onClick={() => {
                                    this.setState({ index: index });
                                    document.getElementById("file_af_contract_document").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-start">
                                  <a target="_blank" download href={item.af_contract_document && item.af_contract_document.includes("://") ? item.af_contract_document : "data:application/pdf;base64," + item.af_contract_document}>
                                    สัญญาจ้าง.pdf
                                  </a>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                    onClick={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].af_contract_document = "";
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 11 เอกสาร บต.46+มอบอำนาจ ไม่ถูกต้อง   */}
            {this.state.receive_status === 11 && this.state.fw_data && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต - ใบมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต.46+มอบอำนาจ ครั้งสุดท้าย ไม่ถูกต้อง</label>
                <br />
                <label>
                  ผู้ตรวจสอบ {this.state.receive_by_s11} วันที่ตรวจสอบ {this.state.receive_date_s11 ? format_date(this.state.receive_date_s11, "dd/mm/yyyy") : ""}
                </label>
              </div>
            )}
            {/* status 12 เอกสารถูกต้อง พร้อมยื่น */}
            {this.state.receive_status === 12 && (
              <div>
                <h5 className="mb-3">
                  <b>เอกสารถูกต้อง พร้อมยื่น</b>
                </h5>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>
                      <th className="text-left text-white">ใบมอบอำนาจ</th>
                      <th className="text-center text-white">ผลการตรวจเอกสาร</th>
                      <th className="text-left text-white">เลข WP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.af_bt_46_document && (
                              <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                บต.46 + สัญญาจ้าง.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.af_power_attorney_document && (
                              <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                                ใบมอบอำนาจ.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-center align-middle">
                            <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                          </td>
                          <td className="text-center align-middle">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].wp_number = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.wp_number}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 13 ได้เลข WP */}
            {this.state.receive_status === 13 && (
              <div>
                <h5 className="mb-3">
                  <b>ตรวจเอกสารครั้งสุดท้าย</b>
                </h5>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">
                        เลข WP ({this.state.count_wp}/{this.state.fw_data.length})
                      </th>
                      <th className="text-left text-white">แก้ไขเลข WP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-center align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                          <td className="text-center align-middle">
                            <div
                              className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-120 m-auto"
                              onClick={() => {
                                this.setState({
                                  modal_update: false,
                                  modal_update_wp: true,
                                  index: index,
                                  wp_number: item.wp_number,
                                });
                              }}
                            >
                              แก้ไขเลข WP
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 14 ได้เลข WP แล้ว รออนุมัติ    */}
            {this.state.receive_status === 14 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>โหลด บต.50 และใบเสร็จรับเงิน</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">เลข WP</th>
                      <th className="text-left text-white">บต.50</th>
                      <th className="text-left text-white">ใบเสร็จรับเงิน</th>
                      <th className="text-left text-white">โหลด บต.50 และใบเสร็จรับเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div className="d-flex align-items-center">
                              {!item.bt_50_document ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  accept="application/pdf"
                                  onClick={() => {
                                    this.setState({ index: index });
                                    document.getElementById("file_bt_50_document").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-start">
                                  <a target="_blank" download href={item.bt_50_document && item.bt_50_document.includes("://") ? item.bt_50_document : "data:application/pdf;base64," + item.bt_50_document}>
                                    บต.50.pdf
                                  </a>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                    onClick={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].bt_50_document = "";
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div className="d-flex align-items-center">
                              {!item.fee_document ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  accept="application/pdf"
                                  onClick={() => {
                                    this.setState({ index: index });
                                    document.getElementById("file_fee_document").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-start">
                                  <a target="_blank" download href={item.fee_document && item.fee_document.includes("://") ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>
                                    ใบเสร็จรับเงิน.pdf
                                  </a>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                    onClick={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].fee_document = "";
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div className="d-flex align-items-center ps-4">
                              <input
                                className="mt-1 me-3"
                                type="checkbox"
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].is_download_s14 = !fw_data[index].is_download_s14;
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.is_download_s14 === true}
                              />
                              ดาวน์โหลดแล้ว
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            {/* status 16 จัดส่งเอกสารใบเสร็จ */}
            {this.state.receive_status === 16 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>จัดส่งเอกสารใบเสร็จ + ทะเบียนใบอนุญาต</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสารให้ลูกค้า{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Line" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s16 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s16: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s16 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s16: e });
                      }}
                      value={this.state.sending_document_date_s16}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s16 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s16").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s16: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s16: e.target.value });
                      }}
                      value={this.state.remark_s16}
                      rows={5}
                    />
                  </div>
                  <div>
                  {this.state.installment.length !== 0 && this.state.installment.filter(e=>e.paid === this.state.installment.length) ? (
                    <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span>
                  ) : (
                    <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>
                  ) }
                  </div>
                </div>
              </div>
            )}
            {/* status 17 // 18 ออก eWork แล้ว */}
            {(this.state.receive_status === 17 || this.state.receive_status === 18) && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รอรับ eWork</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เลข WP</th>
                      {/* <th className="text-left text-white">บต.50</th>
                                            <th className="text-left text-white">ใบเสร็จรับเงิน</th> */}
                      <th className="text-center text-white">ออก eWork</th>
                      <th className="text-center text-white">รับ eWork</th>
                      <th className="text-left text-white">แนบไฟล์ eWork</th>
                      {/* <th className="text-center text-white">แนบไฟล์ รับ eWork</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">{item.nationality ? item.nationality : "-"}</td>
                          <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                          {/* <td className="text-center align-middle">
                                                    {item.bt_50_document && (
                                                        <a target="_blank" download href={(item.bt_50_document && item.bt_50_document.includes("://")) ? item.bt_50_document : "data:application/pdf;base64," + item.bt_50_document}>บต.50.pdf</a>
                                                    )}
                                                    <br />
                                                    <label>{this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}</label>
                                                </td>
                                                <td className="text-center align-middle">
                                                    {item.fee_document && (
                                                        <a target="_blank" download href={(item.fee_document && item.fee_document.includes("://")) ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>ใบเสร็จรับเงิน.pdf</a>
                                                    )} <br />
                                                    <label>{this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}</label>
                                                </td> */}
                          <td className="text-center align-middle">
                            <div className="d-flex align-items-center ps-4">
                              <input
                                className="mt-1 me-3"
                                type="checkbox"
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].e_work_card_issued = !fw_data[index].e_work_card_issued;
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.e_work_card_issued === true}
                              />
                              ออกบัตรแล้ว
                            </div>
                            {item.e_work_card_issued_date ? format_date(item.e_work_card_issued_date, "dd/mm/yyyy hh:mm") : ""}
                          </td>
                          <td className="text-center align-middle">
                            <div className="d-flex align-items-center ps-4">
                              <input
                                className="mt-1 me-3"
                                type="checkbox"
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].e_work_card_received = !fw_data[index].e_work_card_received;
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.e_work_card_received === true}
                              />
                              รับบัตรแล้ว
                            </div>
                            {item.e_work_card_received_date ? format_date(item.e_work_card_received_date, "dd/mm/yyyy hh:mm") : ""}
                          </td>
                          <td className="text-center align-middle">
                            <div className="d-flex align-items-center">
                              {!item.e_work_document ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  accept="application/pdf"
                                  onClick={() => {
                                    this.setState({ index: index });
                                    document.getElementById("file_e_work_document").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-center">
                                  <a target="_blank" download href={item.e_work_document && item.e_work_document.includes("://") ? item.e_work_document : "data:application/pdf;base64," + item.e_work_document}>
                                    eWork.pdf
                                  </a>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                    onClick={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].e_work_document = "";
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                          {/* <td className="text-center align-middle">
                                                    <div className="d-flex align-items-center">
                                                        {!item.e_received_document ? (
                                                            <button
                                                                className="btn btn-primary mx-1 px-4"
                                                                accept="application/pdf"
                                                                onClick={() => { this.setState({ index: index }); document.getElementById("file_e_received_document").click() }}>
                                                                แนบไฟล์
                                                            </button>
                                                        ) : (
                                                            <div className="w-100 d-flex justify-content-center">
                                                                <a target="_blank" download href={(item.e_received_document && item.e_received_document.includes("://")) ? item.e_received_document : "data:application/pdf;base64," + item.e_received_document}>eWork.pdf</a>
                                                                <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                                                    onClick={() => {
                                                                        let fw_data = this.state.fw_data;
                                                                        fw_data[index].e_received_document = "";
                                                                        this.setState({ fw_data: fw_data });
                                                                    }}>
                                                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 19 รับ eWork แล้ว รอจัดส่ง */}
            {this.state.receive_status === 19 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>จัดส่งเอกสาร eWork</b>
                  </h6>

                  {/* <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={async () => { 
                          var merger = new PDFMerger();
                          let fw_data = this.state.fw_data; 
                          for(let fw of fw_data){
                              if(fw.e_work_document){  
                              await merger.add(fw.e_work_document);
                          }
                          }   
                          await merger.setMetadata({
                              producer: "pdf-merger-js based script",
                              author: "ใบนำส่ง eWork",
                              creator: "ใบนำส่ง eWork",
                              title: "ใบนำส่ง eWork"
                          });
                          const mergedPdf = await merger.saveAsBlob();
                          const url = URL.createObjectURL(mergedPdf);
                          var myWindow = window.open();
                          myWindow.document.write('<iframe src="'+url+'" title="pdf-viewer" width="100%" height="99%" ></iframe>');
                          // await merger.save('merged.pdf');
                        }}>
                      ใบนำส่ง eWork
                  </button> */}
                  <button
                    className="btn btn-primary mx-1 px-4"
                    accept="application/pdf"
                    onClick={async () => {
                      var merger = new PDFMerger();
                      let fw_data = this.state.fw_data;
                      for (let fw of fw_data) {
                        if (fw.e_work_document) {
                          await merger.add(fw.e_work_document);
                        }
                      }
                      await merger.setMetadata({
                        producer: "pdf-merger-js based script",
                        author: "ใบนำส่ง eWork",
                        creator: "ใบนำส่ง eWork",
                        title: "ใบนำส่ง eWork",
                      });
                      const mergedPdf = await merger.saveAsBlob();
                      const url = URL.createObjectURL(mergedPdf);
                      var myWindow = window.open();
                      myWindow.document.write('<iframe src="' + url + '" title="pdf-viewer" width="100%" height="99%" ></iframe>');
                      // await merger.save('merged.pdf');
                    }}
                  >
                    ใบนำส่ง eWork
                  </button>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสารให้ลูกค้า{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Line" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s19 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s19: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s19 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s19: e });
                      }}
                      value={this.state.sending_document_date_s19}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s19 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s19").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s19: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s19: e.target.value });
                      }}
                      value={this.state.remark_s19}
                      rows={5}
                    />
                  </div>
                  <div>
                  {this.state.installment.length !== 0 && this.state.installment.filter(e=>e.paid === this.state.installment.length) ? (
                    <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span>
                  ) : (
                    <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>
                  ) }
                  </div>
                </div>
              </div>
            )}
            {/* status 20 ส่ง eWork แล้ว */}
            {this.state.receive_status === 20 && (
              <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                <input
                  className="mt-1 me-3"
                  type="checkbox"
                  name="check_close"
                  onChange={(e) => {
                    this.setState({ check_close: !this.state.check_close });
                  }}
                  checked={this.state.check_close}
                />
                <span className="wpx-100">ปิดงาน</span>
                <input
                  className="form-control w-100"
                  type="text"
                  placeholder="หมายเหตุ ...."
                  onChange={(e) => {
                    this.setState({ remark_s20: e.target.value });
                  }}
                  value={this.state.remark_s20}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.receive_status !== 21 ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if([3,4,5,6].includes(this.state.menu_data.id) && this.state.receive_status === 14){
                        Swal.fire({
                          icon: "warning",
                          title: "ยืนยัน",
                          text: "ยืนยันบันทึกการรับเงินทั้งหมด",
                          confirmButtonText: "ยืนยัน",
                          showCancelButton: true,
                          cancelButtonText: "ยกเลิก",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.Update();
                          } 
                        });
                      }else{
                        if([9,16,19].includes(this.state.receive_status)){
                          if(this.state.installment.length === 0 || this.state.installment.filter(e=>e.paid === this.state.installment.length)){
                            this.Update();
                        }else{
                          Swal.fire({
                            icon: "warning",
                          title: "ยืนยัน",
                          text: "มียอดค้างชำระยืนยันการบันทึกข้อมูลหรือไม่",
                          confirmButtonText: "ยืนยัน",
                          showCancelButton: true,
                          cancelButtonText: "ยกเลิก",
                          }).then((re) => {
                            if (re.isConfirmed) {
                              this.Update();
                            }
                        })
                        }
                      }
                    }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* แก้ไขเลข WP */}
        <Modal
          show={this.state.modal_update_wp}
          onHide={() => {
            this.setState({ modal_update: true, modal_update_wp: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>เลข WP</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <span>แก้ไขเลข WP</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={13}
                  onChange={(e) => {
                    this.setState({ wp_number: number(e.target.value) });
                  }}
                  value={this.state.wp_number}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_update: true,
                      modal_update_wp: false,
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let fw_data = this.state.fw_data;
                    fw_data[this.state.index].wp_number = this.state.wp_number;
                    this.setState({
                      fw_data: fw_data,
                      modal_update: true,
                      modal_update_wp: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* status 2 มอบอำนาจ Matching */}
        <Modal
          show={this.state.modal_match2}
          onHide={() => {
            this.setState({ modal_match2: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>User ของนายจ้าง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              ใช้ User ของนายจ้าง เลขประจำตัว <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="row">
              <div className="col-6">
                <span className="wpx-150">User</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  value={this.state.r_username}
                  onChange={(e) => {
                    this.setState({ r_username: e.target.value });
                  }}
                />
              </div>
              <div className="col-6">
                <span className="wpx-150">Password</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  value={this.state.r_password}
                  onChange={(e) => {
                    this.setState({ r_password: e.target.value });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_match2: false,
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={async () => {
                    await this.Update_up();
                    setTimeout(() => {
                      this.setState({ modal_match2: false });
                    }, 10);
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* status 2 มอบอำนาจ Matching */}
        <Modal
          show={this.state.modal_download}
          onHide={() => {
            this.setState({ modal_download: false });
          }}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>
              <b>ดาวน์โหลดเอกสาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-center text-white">ลำดับ</th>
                    <th className="text-left text-white">ชื่อ-นามสกุล</th>
                    <th className="text-left text-white">เลขบัตรประจำตัว</th>
                    <th className="text-center text-white">
                      บต.46 + สัญญาจ้าง
                      <span
                        className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                        onClick={() => {
                          document_bt46(
                            this.state.fw_data,
                            this.state.emp_data,
                            this.state.data_workplace,
                            this.state.data_deputize,
                            this.state.data_salary,
                            {
                              witness_contract_1: this.state.witness_contract_1,
                              witness_contract_2_th: this.state.witness_contract_2_th,
                              witness_contract_2_en: this.state.witness_contract_2_en,
                            },
                            this.state.data_prefix_th,
                            this.state.data_prefix_en,
                            this.state.data_work_type,
                            this.state.data_business_type
                          );
                        }}
                      >
                        {"\uf019"}
                      </span>
                    </th>
                    <th className="text-left text-white">
                      บต.46 + สัญญาจ้าง เซ็นแล้ว
                      {this.state.fw_data.filter((e) => e.af_bt_46_document).length !== 0 && (
                        <span
                          className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                          onClick={() => {
                            document_bt46(
                              this.state.fw_data,
                              this.state.emp_data,
                              this.state.data_workplace,
                              this.state.data_deputize,
                              this.state.data_salary,
                              {
                                witness_contract_1: this.state.witness_contract_1,
                                witness_contract_2_th: this.state.witness_contract_2_th,
                                witness_contract_2_en: this.state.witness_contract_2_en,
                              },
                              this.state.data_prefix_th,
                              this.state.data_prefix_en,
                              this.state.data_work_type,
                              this.state.data_business_type
                            );
                          }}
                        >
                          {"\uf019"}
                        </span>
                      )}
                    </th>
                    <th className="text-center text-white">
                      ใบมอบอำนาจ
                      <span
                        className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                        onClick={() => {
                          document_contact(
                            this.state.fw_data,
                            this.state.emp_data,
                            this.state.data_workplace,
                            this.state.data_deputize,
                            {
                              witness_power_1: this.state.witness_power_1,
                              witness_power_2_th: this.state.witness_power_2_th,
                              witness_power_2_en: this.state.witness_power_2_en,
                            },
                            this.state.data_prefix_th,
                            this.state.data_prefix_en
                          );
                        }}
                      >
                        {"\uf019"}
                      </span>
                    </th>
                    <th className="text-left text-white">
                      ใบมอบอำนาจ เซ็นแล้ว
                      {this.state.fw_data.filter((e) => e.af_power_attorney_document).length !== 0 && (
                        <span
                          className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                          onClick={() => {
                            document_contact(
                              this.state.fw_data,
                              this.state.emp_data,
                              this.state.data_workplace,
                              this.state.data_deputize,
                              {
                                witness_power_1: this.state.witness_power_1,
                                witness_power_2_th: this.state.witness_power_2_th,
                                witness_power_2_en: this.state.witness_power_2_en,
                              },
                              this.state.data_prefix_th,
                              this.state.data_prefix_en
                            );
                          }}
                        >
                          {"\uf019"}
                        </span>
                      )}
                    </th>
                    {/* <th className="text-left text-white">สัญญาจ้าง</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.fw_data &&
                    this.state.fw_data.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle">{index + 1}</td>
                        <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                        <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                        <td className="text-center align-middle">
                          <span
                            className="text-primary pointer"
                            onClick={() => {
                              document_bt46(
                                [item],
                                this.state.emp_data,
                                this.state.data_workplace,
                                this.state.data_deputize,
                                this.state.data_salary,
                                {
                                  witness_contract_1: this.state.witness_contract_1,
                                  witness_contract_2_th: this.state.witness_contract_2_th,
                                  witness_contract_2_en: this.state.witness_contract_2_en,
                                },
                                this.state.data_prefix_th,
                                this.state.data_prefix_en,
                                this.state.data_work_type,
                                this.state.data_business_type
                              );
                            }}
                          >
                            บต.46 + สัญญาจ้าง.pdf
                          </span>
                        </td>
                        <td className="text-left align-middle">
                          {item.af_bt_46_document && (
                            <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                              บต.46 + สัญญาจ้าง.pdf
                            </a>
                          )}
                        </td>

                        <td className="text-center align-middle">
                          <span
                            className="text-primary pointer"
                            onClick={() => {
                              document_contact(
                                this.state.fw_data,
                                this.state.emp_data,
                                this.state.data_workplace,
                                this.state.data_deputize,
                                {
                                  witness_power_1: this.state.witness_power_1,
                                  witness_power_2_th: this.state.witness_power_2_th,
                                  witness_power_2_en: this.state.witness_power_2_en,
                                },
                                this.state.data_prefix_th,
                                this.state.data_prefix_en
                              );
                            }}
                          >
                            {" "}
                            ใบมอบอำนาจ.pdf
                          </span>
                        </td>
                        <td className="text-left align-middle">
                          {item.af_power_attorney_document && (
                            <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                              ใบมอบอำนาจ.pdf
                            </a>
                          )}
                        </td>
                        {/* <td className="text-left align-middle">
                            {item.af_contract_document && (
                              <a target="_blank" download target="_blank" href={item.af_contract_document && item.af_contract_document.includes("://") ? item.af_contract_document : "data:application/pdf;base64," + item.af_contract_document}>
                              สัญญาจ้าง.pdf
                            </a>
                            )}
                          </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <button
                className="btn btn-primary px-5 mx-auto"
                onClick={() => {
                  this.setState({ modal_download: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

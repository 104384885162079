import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date as fm_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      search: "",

      data: null,
      data_menu_work: [],

      modal_create: false,
      modal_update: false,

      running_id: "",
      menu_id: "",
      prefix: "",
      service_number: "",
      format_date: ["", "", ""],
      running_number: "",
      new_re_run: "",
      full_name: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,
    };
  }
  async componentDidMount() {
    let data = localStorage.getItem("detail_user") ? localStorage.getItem("detail_user") : localStorage.getItem("user");
    data = data ? JSON.parse(data) : null;
    if (data) {
      this.setState({ full_name: data.full_name });
    }
    await this.GetAll();
    await this.GetMenuWork();
  }

  GetAll = async () => {
    let body = {
      running_id: "",
    };
    let result = await GET(tokens, "api/running-number/list", body);
    if (result && result.status) {
      this.setState({ data: result.data, end_index: result.count < result.end_index ? result.count : result.end_index, count: result.count, page_number: result.page_number });
    }
  };

  GetMenuWork = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: [],
      menu_id: "",
    };
    let result = await GET(tokens, "api/receive-matter-menu/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data.map((e) => {
          return {
            value: e.menu_id,
            label: e.menu_name,
          };
        }),
      }); 
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    if(!this.state.menu_id){
      alert("warning","แจ้งเตือน","กรุณาเลือกชื่อบริการ")
      return
    }else if(!this.state.prefix){
      alert("warning","แจ้งเตือน","กรุณากรอก Prefix")
      return
    }else if(!this.state.service_number){
      alert("warning","แจ้งเตือน","กรุณากรอกเลขบริการ")
      return
    }else if(!this.state.running_number){
      alert("warning","แจ้งเตือน","กรุณากรอก running number")
      return
    }
    this.setState({ loading: true });
    let data = {
      menu_id: this.state.menu_id?this.state.menu_id.value:"",
      prefix: this.state.prefix,
      service_number: this.state.service_number,
      format_date: this.state.format_date.toString().replaceAll(","," ").replaceAll("  "," ").trim(),
      running_number: this.state.running_number,
      new_re_run: this.state.new_re_run,
    }; 
    let result = await POST(tokens, "api/running-number/create", data);
    if (result && result.status) {
      success_url("/Number/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    if(!this.state.menu_id){
      alert("warning","แจ้งเตือน","กรุณาเลือกชื่อบริการ")
      return
    }else if(!this.state.prefix){
      alert("warning","แจ้งเตือน","กรุณากรอก Prefix")
      return
    }else if(!this.state.service_number){
      alert("warning","แจ้งเตือน","กรุณากรอกเลขบริการ")
      return
    }else if(!this.state.running_number){
      alert("warning","แจ้งเตือน","กรุณากรอก running number")
      return
    }
    this.setState({ loading: true });
    let data = {
      running_id: this.state.running_id,
      menu_id: this.state.menu_id?this.state.menu_id.value:"",
      prefix: this.state.prefix,
      service_number: this.state.service_number,
      format_date: this.state.format_date.toString().replaceAll(","," ").replaceAll("  "," ").trim(),
      running_number: this.state.running_number,
      new_re_run: this.state.new_re_run,
    };
    let result = await PUT(tokens, "api/running-number/update/" + this.state.running_id, data);
    if (result && result.status) {
      success_url("/Number/list");
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/user/delete/" + this.state.running_id, null);
    if (result && result.status) {
      this.clear_form();
      success();
      this.GetAll();
    }
    this.setState({ loading: false, modal_delete: false });
  };
  format_date_select = (text) => {
    let format_date = this.state.format_date;
    if (text.includes("-")) {
      format_date[0] = format_date[0].includes("-")?text+format_date[0].split("-")[1]:text + format_date[0];
    } else if (text.includes("yy")) {
      if (text.includes("!")) {
        format_date[0] = "";
      } else {
        format_date[0] = format_date[0].includes("-")?format_date[0].split("-")[0]+"-"+text:"th-"+text;
      }
    } else if (text.includes("mm")) {
      if (text.includes("!")) {
        format_date[1] = "";
      } else {
        format_date[1] = text;
      }
    } else if (text.includes("dd")) {
      if (text.includes("!")) {
        format_date[2] = "";
      } else {
        format_date[2] = text;
      }
    }
    this.setState({ format_date: format_date });
  };
  show_format = (prefix, service_number, format_date, running_number) => {
    let text = "";
    text += prefix ?prefix+" ":"";
    text += service_number ?service_number+" ":"";
    text += format_date ?fm_date(new Date(),format_date.toString().replaceAll(","," ").replaceAll("th-","").replaceAll("en-",""),format_date[0].split("-")[0])+" ":"";
    if(running_number){
    for(let i = 0 ; i < Number(running_number) ; i++){
      text += 0;
    }
    text += " "
  }
  return text;
  }
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ตั้งค่าการออกเลขเรื่อง" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>ตั้งค่าการออกเลขเรื่อง</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    this.setState({
                      modal_create: true,
                      running_id: "",
                      menu_id: "",
                      prefix: "",
                      service_number: "",
                      format_date: ["","",""],
                      running_number: "",
                      new_re_run: "ทุกเดือน",
                    });
                  }}
                >
                  <label className="icon">{"\u002B"}</label> เพิ่ม
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">บัญชีระบบ</th>
                      <th className="text-left text-white">ตัวอย่างเลขเรื่อง</th>
                      <th className="text-left text-white">บริการ</th>
                      <th className="text-left text-white">วันที่สร้าง</th>
                      <th className="text-left text-white">ผู้สร้าง</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{this.state.full_name ? this.state.full_name : "-"}</td>
                          <td className="text-left">{this.show_format(item.prefix, item.service_number, item.format_date, item.running_number)}</td>
                          <td className="text-left">{item.menu_id ? this.state.data_menu_work.filter(e=>e.value ===item.menu_id)[0] ?this.state.data_menu_work.filter(e=>e.value ===item.menu_id)[0].label : "-" : "-"}</td>
                          <td className="text-left">{item.createdAt ? fm_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center update"
                                onClick={() => {
                                  this.setState({
                                    modal_update: true,
                                    running_id: item.running_id,
                                    menu_id: item.menu_id,
                                    prefix: item.prefix,
                                    service_number: item.service_number,
                                    format_date: item.format_date.split(" "),
                                    running_number: item.running_number,
                                    new_re_run: item.new_re_run,
                                  });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center delete"
                                onClick={() => {
                                  this.setState({ modal_delete: true, running_id: item.running_id, menu_id: item.full_name_th });
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.GetAll();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* create */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>{this.state.modal_create ? "เพิ่มการตั้งค่า" : "แก้ไขการตั้งค่า"}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 d-flex align-items-center">
              <div className="text-bold wpx-100">ระบบ{required}</div>
              <div className="text-bold wpx-100">{this.state.full_name}</div>
            </div>
            <div className="mb-3 d-flex align-items-center">
              <div className="text-bold wpx-100">ชื่อบริการ{required}</div>
              <Select
                className="form-control select-search"
                styles={select_style}
                options={this.state.data_menu_work}
                onChange={(e) => {
                  this.setState({
                    menu_id: e,
                  });
                }}
                value={this.state.menu_id}
              ></Select>
            </div>
            <div className="mb-3 d-flex align-items-center">
              <div className="text-bold wpx-100">Prefix{required}</div>
              <input
                className="form-control"
                type="text"
                placeholder="Prefix"
                onChange={(e) => {
                  this.setState({ prefix: e.target.value });
                }}
                value={this.state.prefix}
              />
            </div>
            <div className="mb-3 d-flex align-items-center">
              <div className="text-bold wpx-100">เลขบริการ{required}</div>
              <input
                className="form-control"
                type="text"
                placeholder="เลขบริการ"
                onChange={(e) => {
                  this.setState({ service_number: e.target.value });
                }}
                value={this.state.service_number}
              />
            </div>
            <div className="d-flex">
              <div className="text-bold wpx-100">ปี{required}</div>
              <div>
                <div className="mb-3 d-flex">
                  <input
                    type="radio"
                    onChange={(e) => {
                      this.format_date_select("yy");
                    }}
                    checked={this.state.format_date[0].includes("yy")}
                  />
                  <div className="wpx-100 px-2">ใช้งาน</div>
                  <input
                    type="radio"
                    onChange={(e) => {
                      this.format_date_select("!yy");
                    }}
                    checked={!this.state.format_date[0].includes("yy")}
                  />
                  <div className="wpx-100 px-2">ไม่ใช้งาน</div>
                </div>
                {this.state.format_date[0].includes("yy") && (
                  <div className="mb-3 d-flex">
                    <input
                      type="radio"
                      onChange={(e) => {
                        this.format_date_select("th-");
                      }}
                      checked={this.state.format_date[0].includes("th-")}
                    />
                    <div className="wpx-100 px-2">พ.ศ.</div>
                    <input
                      type="radio"
                      onChange={(e) => {
                        this.format_date_select("en-");
                      }}
                      checked={this.state.format_date[0].includes("en-")}
                    />
                    <div className="wpx-100 px-2">ค.ศ.</div>
                  </div>
                )}
                {this.state.format_date[0].includes("yy") && (
                  <div className="mb-3 d-flex">
                    <input
                      type="radio"
                      onChange={(e) => {
                        this.format_date_select("yy");
                      }}
                      checked={this.state.format_date[0].includes("yy") && !this.state.format_date[0].includes("yyyy")}
                    />
                    <div className="wpx-100 px-2">ตัวย่อ</div>
                    <input
                      type="radio"
                      onChange={(e) => {
                        this.format_date_select("yyyy");
                      }}
                      checked={this.state.format_date[0].includes("yyyy")}
                    />
                    <div className="wpx-100 px-2">ตัวเต็ม</div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 d-flex">
              <div className="text-bold wpx-100">เดือน{required}</div>
              <div className="d-flex">
                <input
                  type="radio"
                  onChange={(e) => {
                    this.format_date_select("mm");
                  }}
                  checked={this.state.format_date[1].includes("mm")}
                />
                <div className="wpx-100 px-2">ใช้งาน</div>
                <input
                  type="radio"
                  onChange={(e) => {
                    this.format_date_select("!mm");
                  }}
                  checked={!this.state.format_date[1].includes("mm")}
                />
                <div className="wpx-100 px-2">ไม่ใช้งาน</div>
              </div>
            </div>
            <div className="mb-3 d-flex">
              <div className="text-bold wpx-100">วัน{required}</div>
              <div className="d-flex">
                <input
                  className="mt-1 mb-3"
                  type="radio"
                  onChange={(e) => {
                    this.format_date_select("dd");
                  }}
                  checked={this.state.format_date[2].includes("dd")}
                />
                <div className="wpx-100 px-2">ใช้งาน</div>
                <input
                  className="mt-1 mb-3"
                  type="radio"
                  onChange={(e) => {
                    this.format_date_select("!dd");
                  }}
                  checked={!this.state.format_date[2].includes("dd")}
                />
                <div className="wpx-100 px-2">ไม่ใช้งาน</div>
              </div>
            </div>
            <div className="mb-3 d-flex align-items-center">
              <div className="text-bold wpx-100">running number{required}</div>
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  if(!float(e.target.value)){
                    return
                  } else if (Number(e.target.value) > 15) {
                    e.target.value = 15
                  }
                  this.setState({ running_number: e.target.value });
                }}
                value={this.state.running_number}
              />
              <div className="text-bold wpx-50 px-2 mb-3">ตัว</div>
            </div>
            <div className="mb-3 d-flex align-items-center">
              <div className="text-bold wpx-100">รันเลขใหม่{required}</div>
              <div className="d-flex">
                <input
                  className="mt-1 mb-3"
                  type="radio"
                  onChange={(e) => {
                    this.setState({ new_re_run: "ทุกเดือน" });
                  }}
                  checked={this.state.new_re_run === "ทุกเดือน"}
                />
                <div className="wpx-100 px-2">ทุกเดือน</div>
                <input
                  className="mt-1 mb-3"
                  type="radio"
                  onChange={(e) => {
                    this.setState({ new_re_run: "ทุกปี" });
                  }}
                  checked={this.state.new_re_run === "ทุกปี"}
                />
                <div className="wpx-100 px-2">ทุกปี</div>
              </div>
            </div>
            <h3 className="text-bold">{this.show_format(this.state.prefix, this.state.service_number, this.state.format_date, this.state.running_number)}</h3>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.modal_update) {
                      this.Update();
                    } else {
                      this.Create();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ลบผู้ใช้งาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบผู้ใช้งาน “{this.state.full_name_th}”</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import { Link } from "react-router";
import React, { Component } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Modal } from "react-bootstrap";
import LOGO from "../assets/images/logo.png";
import "../assets/css/bootstrap.css";
import "../assets/css/index.css";
import "../assets/css/sidebar.css";
import "./global.js";

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      modal_logout: false,
      full_name: "",
      email: "",
      user: null,
      manage_row: localStorage.getItem("manage_role"),
      role_detail: localStorage.getItem("permission") ? JSON.parse(localStorage.getItem("permission")) : [],
    };
  }
  componentDidMount() {
    let data = localStorage.getItem("detail_user") ? localStorage.getItem("detail_user") : localStorage.getItem("user");
    data = data ? JSON.parse(data) : null;
    if (data) {
      this.setState({ full_name: data.full_name, email: data.email });
    }
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;
    if (user) {
      this.setState({ user: user });
    }
    this.set_role();
  }
  componentDidUpdate() {
    this.set_role();
  }
  set_role = async () => {
    let data = localStorage.getItem("detail_user") ? localStorage.getItem("detail_user") : localStorage.getItem("user");
    data = data ? JSON.parse(data) : null;
    let role_name = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).role_name : "";
    if (role_name === "general") {
      let update = document.getElementsByClassName("update");
      for (let e of update) {
        e.classList.add("d-none");
      }
    }
    
    let menu = document.getElementsByClassName("pro-menu-item");
    let role_detail = this.state.role_detail;
    for (let role of role_detail) {
      for (let m of menu) {
        if (m.innerHTML.includes("/"+role.menu_name) && role.permission.can_view === false) {
          m.remove();
        }
      }
    }
    if (data && data.user_type && data.user_type === "admin") {
      let link = window.location.href;
      let permission = role_detail.find(e=>link.includes("/"+e.menu_name));
      if(permission){
        if(!permission.permission.can_add){
          let ele = document.getElementsByClassName("create");
          for (let e of ele) {
            e.classList.add("d-none");
          }
        }
        if(!permission.permission.can_edit){
          let ele = document.getElementsByClassName("update");
          for (let e of ele) {
            e.classList.add("d-none");
          }
        }
        if(!permission.permission.can_delete){
          let ele = document.getElementsByClassName("delete");
          for (let e of ele) {
            e.classList.add("d-none");
          }
        }
      }
      for(let role of role_detail){
        if(!role.permission.can_add){
          let ele = document.getElementsByClassName("create["+role.menu_name+"]");
          for (let e of ele) {
            e.classList.add("d-none");
          }
        }
        if(!role.permission.can_edit){
          let ele = document.getElementsByClassName("update["+role.menu_name+"]");
          for (let e of ele) {
            e.classList.add("d-none");
          }
        }
        if(!role.permission.can_delete){
          let ele = document.getElementsByClassName("delete["+role.menu_name+"]");
          for (let e of ele) {
            e.classList.add("d-none");
          }
        }
      }
    }
  };
  Logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  icon = (icon) => {
    return <label className="icon">{icon}</label>;
  };
  active = (path) => {
    return window.location.pathname.includes("/" + path);
  };
  disabled = (path) => {
    return this.state.role_detail.filter((e) => e.menu_name === path)[0]?.permission?.can_view;
  };
  render() {
    return (
      <div>
        <div className="w-100 px-0 position-fixed d-flex justify-content-between bg-primary shadow px-4 py-2 body" style={{ height: 60, zIndex: 1010 }}>
          <div className="d-flex align-items-center">
            {this.props.sidebar !== "false" && !window.location.pathname.includes("pos") && (
              <label
                className="d-block d-lg-none d-xl-none me-3"
                onClick={() => {
                  this.setState({ sidebar: !this.state.sidebar });
                }}
              >
                <span className="icon text-white text-18 pointer">{"\uf0c9"}</span>
              </label>
            )}
            {/* <div className="position-relative">
              <h3><b>S.K.SERVICE</b></h3>
              <small className="position-absolute" style={{ right: 0, bottom: -5 }}> {global.version}</small>
            </div> */}
            <div className="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex text-white">
              <small>{this.props.page1}</small>
              <small className="ms-2">{this.props.page2 ? " > " + this.props.page2 : ""}</small>
              <small className="ms-2">{this.props.page3 ? " > " + this.props.page3 : ""}</small>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {this.state.user && this.state.user.user_type && this.state.user.user_type === "owner" && (
              <button
                className="btn btn-outline-light px-3 py-0"
                onClick={() => {
                  window.location.href = "/console";
                }}
              >
                <label className="icon me-3">{"\uf005"}</label>
                หน้าคอนโซล
              </button>
            )}
            <div className="ps-3">
              <span className="me-2 align-items-center d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex text-white">{this.state.full_name}</span>
              <small className="me-2 align-items-center d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex text-white">{this.state.email}</small>
            </div>
            <div className="wpx-40 hpx-40 rounded-circle bg-secondary">
              <img alt="" src={LOGO} style={{ width: 40 }} />
            </div>
            <div className="dropdowns">
              <span className="dropbtns pointer">
                <label className="icon text-18 p-2 text-white">{"\uf078"}</label>
              </span>
              <div className="dropdowns-content wpx-250 bg-white">
                <a
                  className="pointer"
                  onClick={() => {
                    this.setState({ modal_logout: true });
                  }}
                >
                  <label className="icon mx-2">{"\uf2f5"}</label>ออกจากระบบ
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* MENU */}
        <ProSidebar
          className="full-screen pt-0"
          breakPoint="lg"
          toggled={this.state.sidebar}
          onFocus={() => {
            this.setState({ sidebar: true });
          }}
        >
          <div className="d-flex justify-content-center pt-2">
            <img alt="" src={LOGO} style={{ width: 170 }} />
          </div>
          <div className="w-100 text-center mb-3">
            <small className="text-secondary">{global.version}</small>
          </div>
          {this.state.manage_row === "owner" ? (
            <Menu className="overflow-scroll none-scroll sidebar-h">
              <SubMenu icon={this.icon("\uf013")} title="การตั้งค่า" defaultOpen={window.location.pathname.includes("/setting")}>
                <MenuItem active={this.active("setting/Prefix")}>
                  <Link to="/setting/Prefix/list" className="pro-button">
                    จัดการคำนำหน้าชื่อ
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("setting/ImmigrationOffice")}>
                  <Link to="/setting/ImmigrationOffice/list" className="pro-button">
                    จัดการรายการ ตม.
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("setting/WorkType")}>
                  <Link to="/setting/WorkType/list" className="pro-button">
                    จัดการประเภทงาน
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("setting/BusinessType")}>
                  <Link to="/setting/BusinessType/list" className="pro-button">
                    จัดการประเภทธุรกิจ
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("setting/Branch")}>
                  <Link to="/setting/Branch/list" className="pro-button">
                    จัดการสาขา
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("setting/Salary")}>
                  <Link to="/setting/Salary/list" className="pro-button">
                    จัดการค่าแรงขั้นต่ำ
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("setting/ListMenuWork")}>
                  <Link to="/setting/ListMenuWork/list" className="pro-button">
                    จัดการเมนูรายการรับเรื่อง
                  </Link>
                </MenuItem>
              </SubMenu>
            </Menu>
          ) : (
            <Menu className="overflow-scroll none-scroll sidebar-h">
              <MenuItem icon={this.icon("\uf15b")} active={this.active("RecieveAll")}>
                  <Link to="/RecieveAll/list" className="pro-button">
                    รายการรับเรื่องทั้งหมด
                  </Link>
                </MenuItem>
              <SubMenu icon={this.icon("\uf15b")} title="รายการรับเรื่อง" defaultOpen={window.location.pathname.includes("/RecieveMOU") || window.location.pathname.includes("/RenewLicense")}>
                <MenuItem active={this.active("RecieveMOU")}>
                  <Link to="/RecieveMOU/list" className="pro-button">
                    ขอใบอนุญาตทำงาน
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("RenewLicense")}>
                  <Link to="/RenewLicense/list" className="pro-button">
                    ต่อใบอนุญาตทำงาน
                  </Link>
                </MenuItem>
              </SubMenu>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("ApproveRecieveAll")}>
                <Link to="/ApproveRecieveAll/list">รายการ QC</Link>
              </MenuItem>
              {/* <SubMenu icon={this.icon("\uf15b")} title="รายการ QC" defaultOpen={window.location.pathname.includes("/ApproveRecieveAll") ||window.location.pathname.includes("/ApproveQC") || window.location.pathname.includes("/ApproveRenewLicense")}>
                <MenuItem active={this.active("ApproveRecieveAll")}>
                  <Link to="/ApproveRecieveAll/list" className="pro-button">
                  รายการรับเรื่องทั้งหมด
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("ApproveQC")}>
                  <Link to="/ApproveQC/list" className="pro-button">
                    ขอใบอนุญาตทำงาน
                  </Link>
                </MenuItem>
                <MenuItem active={this.active("ApproveRenewLicense")}>
                  <Link to="/ApproveRenewLicense/list" className="pro-button">
                    ต่อใบอนุญาตทำงาน
                  </Link>
                </MenuItem>
              </SubMenu> */}
              <MenuItem icon={this.icon("\uf15b")} active={this.active("Waiting")}>
                  <Link to="/Waiting/list" className="pro-button">
                  รายการรอยื่น
                  </Link>
                </MenuItem>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("Invoice")}>
                  <Link to="/Invoice/list" className="pro-button">
                  รายการใบแจ้งหนี้
                  </Link>
                </MenuItem>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("Receipt")}>
                  <Link to="/Receipt/list" className="pro-button">
                  รายการใบเสร็จ
                  </Link>
                </MenuItem>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("BillingNote")}>
                  <Link to="/BillingNote/list" className="pro-button">
                  Billing Note
                  </Link>
                </MenuItem>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("/Quotation")}>
                <Link to="/Quotation/list">ใบเสนอราคา</Link>
              </MenuItem>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("/RequestForPrice")}>
                <Link to="/RequestForPrice/list">รายการใบขอราคา</Link>
              </MenuItem>
              <MenuItem icon={this.icon("\uf15b")} active={this.active("/SaleDepartment")}>
                <Link to="/SaleDepartment/list">ฝ่ายการขาย</Link>
              </MenuItem>
             {/* <MenuItem icon={this.icon("\uf15b")} active={this.active("/LMSmain")}><Link to="/LMSmain/list">ระบบ LMS</Link></MenuItem> */}
              <MenuItem icon={this.icon("\uf007")} active={this.active("/Employer")}>
                <Link to="/Employer/list">ทะเบียนนายจ้าง</Link>
              </MenuItem>
              <MenuItem icon={this.icon("\uf007")} active={this.active("/Employee")}>
                <Link to="/Employee/list">ทะเบียนลูกจ้าง</Link>
              </MenuItem>
              <MenuItem icon={this.icon("\uf007")} active={this.active("/Agent")}>
                <Link to="/Agent/list">ทะเบียนตัวแทน</Link>
              </MenuItem>
              <MenuItem icon={this.icon("\uf0f8")} active={this.active("/Insurance")}>
                <Link to="/Insurance/list">ประกันภัย</Link>
              </MenuItem>
              <MenuItem icon={this.icon("\uf019")} active={this.active("/Download")}>
                <Link to="/Download/list">ดาวน์โหลดไฟล์</Link>
              </MenuItem>
              <SubMenu icon={this.icon("\uf013")} title="การตั้งค่า" defaultOpen={window.location.pathname.includes("/setting")}> 
                <MenuItem active={this.active("/Customer/list")}><Link to="/Customer/list" className="pro-button">จัดการบัญชีระบบ</Link></MenuItem>
                <MenuItem active={this.active("setting/LMS")}><Link to="/setting/LMS/list" className="pro-button">จัดการระบบ LMS</Link></MenuItem>
                <MenuItem active={this.active("setting/PostBoxNo")}><Link to="/setting/PostBoxNo/list" className="pro-button">เลขตู้</Link></MenuItem>
                <MenuItem active={this.active("setting/LMSCategory")}><Link to="/setting/LMSCategory/list" className="pro-button">จัดการหมวดหมู่ระบบ LMS</Link></MenuItem>
                {/* <MenuItem active={this.active("setting/Quotation")}><Link to="/setting/Quotation/list" className="pro-button">จัดการใบเสนอราคา</Link></MenuItem> */}
                {/* <MenuItem active={this.active("setting/ServiceType")}><Link to="/setting/ServiceType/list" className="pro-button">จัดการประเภทบริการ</Link></MenuItem> */}
                <MenuItem active={this.active("setting/ServiceRate")}><Link to="/setting/ServiceRate/list" className="pro-button">จัดการอัตราค่าบริการ</Link></MenuItem>
                {/* <MenuItem active={this.active("setting/DocumentName")}><Link to="/setting/DocumentName/list" className="pro-button">จัดการชื่อเอกสาร</Link></MenuItem> */}
                {/* <MenuItem active={this.active("setting/SendDocument")}><Link to="/setting/SendDocument/list" className="pro-button">จัดการการส่งเอกสาร</Link></MenuItem> */}
                <MenuItem active={this.active("setting/PaperHead")}><Link to="/setting/PaperHead/create" className="pro-button">จัดการหัวกระดาษ</Link></MenuItem>
                <MenuItem active={this.active("setting/EmpOffice")}><Link to="/setting/EmpOffice/list" className="pro-button">รายชื่อสำนักจัดหางาน</Link></MenuItem>
                <MenuItem active={this.active("setting/Bank")}><Link to="/setting/Bank/list" className="pro-button">บัญชีธนาคาร</Link></MenuItem>
                <MenuItem active={this.active("setting/Alert")}><Link to="/setting/Alert/create" className="pro-button">การแจ้งเตือนหมดอายุเอกสาร</Link></MenuItem>
                <MenuItem active={this.active("Number")}><Link to="/Number/list" className="pro-button">ตั้งค่าการออกเลขเรื่อง</Link></MenuItem>
                <MenuItem active={this.active("User/list")}><Link to="/User/list" className="pro-button">บัญชีระบบ</Link></MenuItem>
                <MenuItem active={this.active("Role/list")}><Link to="/Role/list" className="pro-button">สิทธิ์ผู้ใช้</Link></MenuItem>
              </SubMenu>
            </Menu>
          )}
        </ProSidebar>
        {/* MODAL */}
        <Modal
          show={this.state.modal_logout}
          onHide={() => {
            this.setState({ modal_logout: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>ออกจากระบบ</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100  mb-3">
              <label>ยืนยันการออกจากระบบหรือไม่</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_logout: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Logout();
                  }}
                >
                  ออกจากระบบ
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

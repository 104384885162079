import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import Switch from "react-switch";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import province from "../../../assets/files/province.json";
import district from "../../../assets/files/district.json";
import subdistrict from "../../../assets/files/all_province.json";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      data_category: [],

      lms_name: "",
      lms_category_id: "",
      title: "",
      description: "",
      start_date: null,
      end_date: null,
      key_search: "",
      picture_list: [],
      link_list: [],

      survey_list: [],
      is_active: false,
    };
  }
  async componentDidMount() {
    await this.GetCategory();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
    }
  }
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      lms_id: id,
    };
    let result = await GET(tokens, "api/setting/main-lms/list/page/0", body);
    if (result && result.status) {
      let item = result.data;
      this.setState({
        lms_id: item.lms_id,
        lms_name: item.lms_name,
        lms_category_id: item.category_data ? this.state.data_category.filter((e) => e.value === item.category_data.category_id)[0] : "",
        title: item.title,
        description: item.description,
        start_date: item.start_date,
        end_date: item.end_date,
        key_search: item.key_search,
        picture_list: item.picture_list,
        link_list: item.link_list,

        survey_list: item.survey_list,
        is_active: item.is_active,
      });
    }
    this.setState({ loading: false });
  };
  GetCategory = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      category_id: "",
    };
    let result = await GET(tokens, "api/setting/category-lms/list", body);
    if (result && result.status) {
      this.setState({
        data_category: result.data.map((e) => {
          return { value: e.category_id, label: e.category_name };
        }),
      });
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      lms_name: this.state.lms_name,
      lms_category_id: this.state.lms_category_id ? this.state.lms_category_id.value : "",
      title: this.state.title,
      description: this.state.description,
      start_date: this.state.start_date ? format_date(this.state.start_date, "yyyy-mm-dd", "en") : null,
      end_date: this.state.end_date ? format_date(this.state.end_date, "yyyy-mm-dd", "en") : null,
      key_search: this.state.key_search,
      link_list: this.state.link_list,
      survey_list: this.state.survey_list,
      picture_list: this.state.picture_list,
    };
    let result = await POST(tokens, "api/setting/main-lms/create", body);
    if (result && result.status) {
      success_url("/setting/LMS/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      lms_name: this.state.lms_name,
      lms_category_id: this.state.lms_category_id ? this.state.lms_category_id.value : "",
      title: this.state.title,
      description: this.state.description,
      start_date: this.state.start_date ? format_date(this.state.start_date, "yyyy-mm-dd", "en") : null,
      end_date: this.state.end_date ? format_date(this.state.end_date, "yyyy-mm-dd", "en") : null,
      key_search: this.state.key_search,
      link_list: this.state.link_list,
      survey_list: this.state.survey_list,
      picture_list: this.state.picture_list,
    };
    let result = await PUT(tokens, "api/setting/main-lms/update/" + this.state.lms_id, body);
    if (result && result.status) {
      success_url("/setting/LMS/list");
    }
    this.setState({ loading: false });
  };
  import_file = async (e) => {
    this.multiple_file(0, e.target.files.length, e.target.files);
  };
  multiple_file = async (index, max, file) => {
    await this.changeMultiFile(file[index]);
    if (index + 1 < max) {
      setTimeout(() => {
        this.multiple_file(index + 1, max, file);
      }, 50);
    } else {
      document.getElementById("multifile").value = "";
      document.getElementById("multifile").file = null;
    }
  };
  changeMultiFile = async (file) => {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let picture_list = this.state.picture_list;
        picture_list.push(reader.result.split(",")[1]);
        this.setState({ picture_list: picture_list });
      };
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="จัดการระบบ LMS" page3="สร้าง LMS" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>สร้าง LMS</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>สร้าง LMS</b>
                  </h5>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">ชื่อ LMS{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ lms_name: e.target.value });
                        }}
                        value={this.state.lms_name}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">หมวดหมู่ LMS{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_category}
                        onChange={(e) => {
                          this.setState({ lms_category_id: e });
                        }}
                        value={this.state.lms_category_id}
                      ></Select>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-100">หัวข้อ{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ title: e.target.value });
                        }}
                        value={this.state.title}
                      />
                    </div>
                    <div className="col-12 d-flex align-items-start mb-3">
                      <span className="wpx-100">รายละเอียด{required}</span>
                      <textarea
                        className="form-control mt-1"
                        onChange={(e) => {
                          this.setState({ description: e.target.value });
                        }}
                        value={this.state.description}
                        rows={10}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">วันที่เริ่ม{required}</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ start_date: e });
                        }}
                        value={this.state.start_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">วันที่สิ้นสุด{required}</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ end_date: e });
                        }}
                        value={this.state.end_date}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">คำค้นหา{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ key_search: e.target.value });
                        }}
                        value={this.state.key_search}
                      />
                    </div>
                  </div>
                  <h5 className="my-3">
                    <b>รูปที่เกี่ยวข้อง</b>
                  </h5>
                  <div className="card p-3">
                    <div className="row">
                      {this.state.picture_list.map((item, index) => (
                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mb-2 px-1">
                          <div className="w-100 hpx-150 card border-2 border-orange d-flex align-items-center justify-content-center p-2  mb-2">
                            <div className="position-relative w-100 hpx-150 d-flex justify-content-center align-items-center">
                              <img alt="รูปโปรไฟล์" src={item.includes("https://") ? item : "data:image/png;base64," + item} className="w-100 hpx-140 rounded overflow-hidden" style={{ objectFit: "contain" }}></img>
                              <button
                                className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle"
                                style={{ top: -8, right: -8 }}
                                onClick={() => {
                                  let picture_list = this.state.picture_list;
                                  picture_list.splice(index, 1);
                                  this.setState({ picture_list: picture_list });
                                }}
                              >
                                {"\uf00d"}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <input
                      className="d-none"
                      type="file"
                      id="multifile"
                      accept="image/*"
                      multiple
                      onChange={(e) => {
                        this.import_file(e);
                      }}
                    />
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary px-4"
                        onClick={() => {
                          document.getElementById("multifile").click();
                        }}
                      >
                        เพิ่มรูปภาพ
                      </button>
                    </div>
                  </div>
                  <h5 className="my-3">
                    <b>ลิ้งวิดีโอที่เกี่ยวข้อง</b>
                  </h5>
                  <div className="card p-3">
                    <div className="row">
                      {this.state.link_list.map((item, index) => (
                        <div className="d-flex align-items-center mb-3">
                          <div className="wpx-50">{index + 1}.</div>
                          <div className="mx-1 w-100">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let link_list = this.state.link_list;
                                link_list[index] = e.target.value;
                                this.setState({ link_list: link_list });
                              }}
                              value={item}
                            />
                          </div>
                          <div className="wpx-50 d-flex justify-content-center">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                let link_list = this.state.link_list;
                                link_list.splice(index, 1);
                                this.setState({ link_list: link_list });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary px-4"
                        onClick={() => {
                          let link_list = this.state.link_list;
                          link_list.push("");
                          this.setState({ link_list: link_list });
                        }}
                      >
                        เพิ่มลิ้ง
                      </button>
                    </div>
                  </div>
                  <h5 className="my-3">
                    <b>แบบทดสอบความเข้าใจ</b>
                  </h5>
                  <div className="card p-3">
                    {this.state.survey_list.map((item, index) => (
                      <div>
                        <div className="d-flex align-items-center mb-3">
                          <div className="wpx-50">ข้อที่ {index + 1}</div>
                          <div className="mx-1 w-100">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let survey_list = this.state.survey_list;
                                survey_list[index].question = e.target.value;
                                this.setState({ survey_list: survey_list });
                              }}
                              value={item.question}
                            />
                          </div>
                          <div className="wpx-50 d-flex justify-content-center">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                let survey_list = this.state.survey_list;
                                survey_list.splice(index, 1);
                                this.setState({ survey_list: survey_list });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-3">
                          <div className="wpx-50"></div>
                          <div className="mx-1 w-100 d-flex align-items-center">
                            <span className="wpx-120">ตัวเลือกที่ 1 {required}</span>
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let survey_list = this.state.survey_list;
                                survey_list[index].answer1 = e.target.value;
                                this.setState({ survey_list: survey_list });
                              }}
                              value={item.answer1}
                            />
                          </div>
                          <div className="mx-1 w-100 d-flex align-items-center">
                            <span className="wpx-120">ตัวเลือกที่ 2 {required}</span>
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let survey_list = this.state.survey_list;
                                survey_list[index].answer2 = e.target.value;
                                this.setState({ survey_list: survey_list });
                              }}
                              value={item.answer2}
                            />
                          </div>
                          <div className="wpx-50"></div>
                        </div>
                        <div className="d-flex mb-3">
                          <div className="wpx-50"></div>
                          <div className="mx-1 w-100 d-flex align-items-center">
                            <span className="wpx-120">ตัวเลือกที่ 3 {required}</span>
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let survey_list = this.state.survey_list;
                                survey_list[index].answer3 = e.target.value;
                                this.setState({ survey_list: survey_list });
                              }}
                              value={item.answer3}
                            />
                          </div>
                          <div className="mx-1 w-100 d-flex align-items-center">
                            <span className="wpx-120">ตัวเลือกที่ 4 {required}</span>
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let survey_list = this.state.survey_list;
                                survey_list[index].answer4 = e.target.value;
                                this.setState({ survey_list: survey_list });
                              }}
                              value={item.answer4}
                            />
                          </div>
                          <div className="wpx-50"></div>
                        </div>
                        <hr />
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary my-2 wpx-140"
                      onClick={() => {
                        let survey_list = this.state.survey_list;
                        survey_list.push({
                          question: "",
                          answer1: "",
                          answer2: "",
                          answer3: "",
                          answer4: "",
                        });
                        this.setState({ survey_list: survey_list });
                      }}
                    >
                      + เพิ่มคำถาม
                    </button>
                  </div>
                </div>
                <div className="w-100 d-flex align-items-center my-3">
                  <label className="wpx-100">สถานะ{required}:</label>
                  <Switch
                    onChange={(e) => {
                      this.setState({
                        is_active: e,
                      });
                    }}
                    checked={this.state.is_active}
                  />
                </div>
                <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/setting/LMS/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (!this.state.lms_name) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ LMS");
                              return;
                            } else if (!this.state.lms_category_id) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกหมวดหมู่ LMS");
                              return;
                            } else if (!this.state.title) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกหัวข้อ");
                              return;
                            } else if (!this.state.description) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกรายละเอียด");
                              return;
                            } else if (!this.state.start_date) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่ม");
                              return;
                            } else if (!this.state.end_date) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่สิ้นสุด");
                              return;
                            } else if (!this.state.key_search) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกคำค้นหา");
                              return;
                            }
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

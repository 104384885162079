import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar.js";
import Pagination from "@mui/material/Pagination";
import Select from "react-select";
import { read, writeFileXLSX } from "xlsx";
import * as xlsx from "xlsx";
import dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import swal from "sweetalert";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

function CreateJob() {
  var date = new Date();

  const [workValue, setWorkValue] = useState(0);
  const [visaValue, setVisaValue] = useState(0);
  const [servicValue, setServiceValue] = useState(0);
  const [daysValue, setDaysValue] = useState(0);

  const [dateStart, setDateStart] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = useState(dayjs(date.setDate(date.getDate() + 1)).format("YYYY-MM-DD"));

  const [fileDays, setFileDays] = useState();
  const [fileDaysJson, setFileDaysJson] = useState();
  const [fileVisa, setFileVisa] = useState();
  const [fileVisaJson, setFileVisaJson] = useState();

  const [listJobType, setListJobType] = useState([]);
  const [jobType, setJobType] = useState({ value: "", value: "" });

  const [listCustomer, setListCustomer] = useState([]);
  const [customer, setCustomer] = useState({ value: "", value: "" });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const getAllJob = async () => {
    try {
      let result = await GET(tokens, "api/urgent/job");
      if (result.data != null) {
        setListJobType(
          result.data.map((d) => ({
            value: d.id,
            label: d.item,
          }))
        );
      }
    } catch (error) {}
  };

  const getAllEmployers = async () => {
    try {
      let result = await GET(tokens, "api/urgent/Employers");
      if (result.data != null) {
        setListCustomer(
          result.data.map((d) => ({
            value: d.emp_id,
            label: d.full_name_th,
          }))
        );
      }
    } catch (error) {}
  };

  const job_detail_insert = async (customer_id, job_id, work_value, visa_value, service_value, days_value, date_start, date_end) => {
    let body = {
      customer_id: customer_id,
      job_id: job_id,
      work_value: work_value,
      visa_value: visa_value,
      service_value: service_value,
      days_value: days_value,
      date_start: date_start,
      date_end: date_end,
    };

    let result = await POST(tokens, "api/urgent/Job_Detail_insert", body);
    if (result.data != null) {
    }
  };

  const Job_Merge_insert = async (body) => {
    let result = await POST(tokens, "api/urgent/Job_Merge_insert", body);
    if (result.data != null) {
    }
  };

  const job_visa_insert = async (body) => {
    let result = await POST(tokens, "api/urgent/Job_Visa_insert", body);
    if (result.data != null) {
    }
  };

  const job_day_insert = async (body) => {
    let result = await POST(tokens, "api/urgent/Job_Day_insert", body);
    if (result.data != null) {
    }
  };

  const Job_Visa_Day_merge = async (customer_id, date_start, date_end) => {
    let body = {
      customer_id: customer_id,
      date_start: date_start,
      date_end: date_end,
    };

    let result = await GET(tokens, "api/urgent/Job_Visa_Day_merge", body);
    if (result.data != null) {
      return result.data;
    } else {
      return [];
    }
  };

  const readUploadFile_a = (e) => {
    try {
      e.preventDefault();
      if (e.target.files) {
        setFileDays(e, "file_days");
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          setFileDaysJson(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } catch (error) {}
  };

  const readUploadFile_b = (e) => {
    try {
      e.preventDefault();
      if (e.target.files) {
        setFileVisa(e, "file_visa");
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          setFileVisaJson(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    try {
      getAllJob();
      getAllEmployers();
    } catch (error) {}
  }, []);

  return (
    <div className="w-100 min-vh-100 fade-in">
      {/* {this.state.loading && (<div className="spinner-container"> <div className="loading-spinner"></div> </div>)} */}
      <Navbar page1="หน้าหลัก" page2="สร้างงาน ต่อ 90 และ ต่อ visa" />
      {/* BODY */}
      <div className="body bg-light min-vh-100">
        <div className="p-3">
          {/* หัวข้อ และ ปุ่มเพิ่ม */}
          <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
            <h3 className="mb-3">
              <b>สร้างงาน ต่อ 90 และ ต่อ visa</b>
            </h3>
            <div className="d-flex">
              {/* <button className="btn btn-secondary mx-1" onClick={() => { window.location.href = "/Employer/upload" }}>
                        อัพโหลดไฟล์นายจ้าง
                    </button> */}
              {/* <button className="btn btn-primary mx-1 update" onClick={() => { window.location.href = "/Employer/create" }}>
                        <label className="icon">{"\u002B"}</label> เพิ่มนายจ้าง
                    </button> */}
            </div>
          </div>

          <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
            <div className="p-3">
              <div className="w-100">
                <h5 className="mb-3">
                  <b>สร้างงานในระบบ </b>
                </h5>
                <hr />

                <div className="row mt-3">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">เลือกชื่อลูกค้า {required}</span>
                    <Select
                      className="form-control select-search"
                      styles={select_style}
                      options={listCustomer}
                      onChange={(e) => {
                        try {
                          setCustomer(e);
                        } catch (error) {}
                      }}
                      value={customer}
                    ></Select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ระบุงาน {required}</span>
                    <Select
                      className="form-control select-search"
                      styles={select_style}
                      options={listJobType}
                      onChange={(e) => {
                        try {
                          setJobType(e);
                        } catch (error) {}
                      }}
                      value={jobType}
                    ></Select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ค่าต่อ Work {required}</span>
                    <input
                      className="form-control mt-1"
                      type="number"
                      maxLength={6}
                      onChange={(e) => {
                        try {
                          if (!float(e.target.value)) {
                            return;
                          }
                          setWorkValue(e.target.value);
                        } catch (error) {}
                      }}
                      value={workValue}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ค่าต่อ VISA {required}</span>
                    <input
                      className="form-control mt-1"
                      type="number"
                      maxLength={6}
                      onChange={(e) => {
                        try {
                          if (!float(e.target.value)) {
                            return;
                          }
                          setVisaValue(e.target.value);
                        } catch (error) {}
                      }}
                      value={visaValue}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ค่าต่อ Service {required}</span>
                    <input
                      className="form-control mt-1"
                      type="number"
                      maxLength={6}
                      onChange={(e) => {
                        try {
                          if (!float(e.target.value)) {
                            return;
                          }
                          setServiceValue(e.target.value);
                        } catch (error) {}
                      }}
                      value={servicValue}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ค่าต่อ 90 วัน {required}</span>
                    <input
                      className="form-control mt-1"
                      type="number"
                      maxLength={6}
                      onChange={(e) => {
                        try {
                          if (!float(e.target.value)) {
                            return;
                          }
                          setDaysValue(e.target.value);
                        } catch (error) {}
                      }}
                      value={daysValue}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ช่วงเวลา {required}</span>
                    <DatePicker
                      className="form-control mt-1"
                      onChange={(e) => {
                        try {
                          setDateStart(e);
                        } catch (error) {}
                      }}
                      value={dateStart}
                    />
                    <span className="wpx-40 m-2">ถึง</span>
                    <DatePicker
                      className="form-control mt-1"
                      onChange={(e) => {
                        try {
                          setDateEnd(e);
                        } catch (error) {}
                      }}
                      value={dateEnd}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ไฟล์ 90 วัน {required}</span>
                    <input className="d-none" type="file" id="file_days" accept=".xlsx, .xls" onChange={readUploadFile_a} />

                    <button
                      className="btn btn-primary mx-1 px-4"
                      onClick={() => {
                        try {
                          document.getElementById("file_days").click();
                        } catch (error) {}
                      }}
                    >
                      แนบไฟล์
                    </button>

                    {fileDays && <div>{fileDays.target.files[0].name}</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">ไฟล์ต่อ VISA{required}</span>
                    <input className="d-none" type="file" id="file_visa" accept=".xlsx, .xls" onChange={readUploadFile_b} />

                    <button
                      className="btn btn-primary mx-1 px-4"
                      onClick={() => {
                        try {
                          document.getElementById("file_visa").click();
                        } catch (error) {}
                      }}
                    >
                      แนบไฟล์
                    </button>

                    {fileVisa && <div>{fileVisa.target.files[0].name}</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150"></span>

                    <button
                      className="btn btn-dark mx-1 px-4"
                      onClick={async () => {
                        try {
                          await job_detail_insert(customer.value, jobType.value, workValue, visaValue, servicValue, daysValue, dateStart, dateEnd);

                          let flag_day = 0;

                          const temp_json_day = fileDaysJson
                            .map((value, index) => {
                              try {
                                if (flag_day === 1 && value.__EMPTY_1 !== undefined && value.__EMPTY_2 !== undefined && value.__EMPTY_3 !== undefined && value.__EMPTY_4 !== undefined && value.__EMPTY_5 !== undefined && value.__EMPTY_6 !== undefined && value.__EMPTY_7 !== undefined && value.__EMPTY_8 !== undefined && value.__EMPTY_9 !== undefined && value.__EMPTY_10 !== undefined && value.__EMPTY_11 !== undefined && value.__EMPTY_12 !== undefined && value.__EMPTY_13 !== undefined) {
                                  try {
                                    return {
                                      customer_id: customer.value,
                                      date_start: dateStart,
                                      date_end: dateEnd,
                                      passport_no: value.__EMPTY_4,

                                      name: value.__EMPTY_1,
                                      nationality: value.__EMPTY_3,
                                      emp_code: value.__EMPTY_2,
                                      date_arival: value.__EMPTY_5,
                                      visa_no: value.__EMPTY_6,

                                      type_visa: value.__EMPTY_7,
                                      expire_date: value.__EMPTY_8,
                                      point_entry: value.__EMPTY_9,
                                      arrival_card: value.__EMPTY_10,
                                      employer: value.__EMPTY_11,

                                      address: value.__EMPTY_12,
                                      report_day: value.__EMPTY_13,
                                    };
                                  } catch (error) {
                                    return;
                                  }
                                }

                                if (flag_day === 0 && value.__EMPTY_11 == "EMPLOYER") {
                                  flag_day = 1;
                                }
                              } catch (error) {}
                            })
                            .filter(function (element) {
                              return element !== undefined;
                            });

                          await job_day_insert(temp_json_day);

                          let flag_visa = 0;

                          const temp_json_visa = fileVisaJson
                            .map((value, index) => {
                              try {
                                if (flag_visa === 1 && value.__EMPTY_1 !== undefined && value.__EMPTY_2 !== undefined && value.__EMPTY_3 !== undefined && value.__EMPTY_4 !== undefined && value.__EMPTY_5 !== undefined && value.__EMPTY_6 !== undefined && value.__EMPTY_7 !== undefined && value.__EMPTY_8 !== undefined) {
                                  try {
                                    return {
                                      customer_id: customer.value,
                                      date_start: dateStart,
                                      date_end: dateEnd,
                                      passport_no: value.__EMPTY,

                                      visa_no: value.__EMPTY_1,
                                      issued_at: value.__EMPTY_2,
                                      name: value.__EMPTY_3,
                                      emp_code: value.__EMPTY_4,
                                      nationality: value.__EMPTY_5,

                                      sex: value.__EMPTY_6,
                                      issue_date: value.__EMPTY_7,
                                      expire_date: value.__EMPTY_8,
                                    };
                                  } catch (error) {
                                    return;
                                  }
                                }

                                if ("ออกให้ที่" == value.__EMPTY_2 && flag_visa == 0) {
                                  flag_visa = 1;
                                }
                              } catch (error) {}
                            })
                            .filter(function (element) {
                              return element !== undefined;
                            });

                          await job_visa_insert(temp_json_visa);

                          swal("การบันทึก", "ทักข้อมูลสำเร็จ", "success");

                          // const make_excel_json = await Job_Visa_Day_merge(
                          //   customer.value,
                          //   dayjs(dateStart).format("YYYY-MM-DD"),
                          //   dayjs(dateEnd).format("YYYY-MM-DD")
                          // );

                          // const new_excel_json = make_excel_json.map(
                          //   (data, index) => {
                          //     try {
                          //       return {
                          //         ลำดับที่: index + 1,
                          //         รหัสพนักงาน: data.emp_code,
                          //         ชื่อลูกจ้าง: data.name,
                          //         "PASSPORT NO.": data.passport_no,
                          //         "ค่า Work": data.work_value,
                          //         "ค่า Visa": data.visa_value,
                          //         "ค่า 90 วัน": data.days_value,
                          //         ค่าบริการ: data.service_value,
                          //         รวมที่ต้องชำระ: {
                          //           f:
                          //             "SUM(E" +
                          //             (index + 2) +
                          //             ":H" +
                          //             (index + 2) +
                          //             ")",
                          //         },
                          //         สถานะเอกสาร: " ",
                          //         หมายเหตุ: " ",
                          //       };
                          //     } catch (error) {
                          //       return;
                          //     }
                          //   }
                          // );

                          // for (let i = 0; i < new_excel_json.length; i++) {
                          //   for (
                          //     var d = new Date(dateStart);
                          //     d <= new Date(dateEnd);
                          //     d.setDate(d.getDate() + 1)
                          //   ) {
                          //     new_excel_json[i] = Object.assign(
                          //       new_excel_json[i],
                          //       {
                          //         [dayjs(new Date(d)).format("YYYY-MM-DD")]: "",
                          //       }
                          //     );
                          //   }
                          // }

                          // for (let i = 0; i < new_excel_json.length; i++) {
                          //   new_excel_json[i] = Object.assign(
                          //     new_excel_json[i],
                          //     { ["สรุป"]: "" }
                          //   );
                          // }

                          // if (new_excel_json.length > 0) {
                          //   const worksheet =
                          //     xlsx.utils.json_to_sheet(new_excel_json);
                          //   const workbook = xlsx.utils.book_new();
                          //   xlsx.utils.book_append_sheet(
                          //     workbook,
                          //     worksheet,
                          //     "Sheet1"
                          //   );
                          //   xlsx.writeFile(
                          //     workbook,
                          //     "ae_" +
                          //       dayjs(dateStart).format("YYYY-MM-DD") +
                          //       " " +
                          //       dayjs(dateEnd).format("YYYY-MM-DD") +
                          //       ".xlsx"
                          //   );
                          // }
                          // window.open("/urgent/upload");
                        } catch (error) {}
                      }}
                    >
                      บันทีก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>บันทึกข้อมูล</Modal.Title>
        </Modal.Header>
        <Modal.Body>บันทีกรายการสำเร็จ</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            ปิด
          </Button>
          <Button variant="primary" onClick={handleClose}>
            ถัดไป
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreateJob;

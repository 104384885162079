import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";
import { saveAs } from "file-saver";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_delete: false,
      loading: false,
      data_search: "",

      folder: [],

      list_data: [],
    };
  }
  async componentDidMount() {
    this.GetFolder();
  }
  GetFolder = async () => {
    let data = { path: "public/storage/" };
    let result = await POST(tokens, "api/upload-work-file/read", data);
    if (result && result.status) {
      if (result.data.length > 0) {
        this.setState({
          folder: result.data.map((e) => {
            return { ...e };
          }),
        });
      }
      this.setState({
        list_data: result.wf_data,
      });
    }
  };
  Count = async (id) => {
    let result = await GET(tokens, "api/upload-work-file/count-download/" + id, null);
    if (result && result.status && result.message === "สำเร็จ") {
      console.log("Count:", result.data);
    }
  };
  line = (level) => {
    const line1 = <div style={{ width: 1, background: "#000000" }}></div>;
    const line2 = <div style={{ height: 1, minWidth: 15, width: 15, background: "#000000", margin: "auto 0px" }}></div>;
    const line3 = <div style={{ minWidth: 30, width: 30 }}></div>;
    const all_line = [];
    for (let i = 0; i < level; i++) {
      if (i === level - 1) {
        all_line.push(line1);
        all_line.push(line2);
      } else {
        all_line.push(line1);
        all_line.push(line3);
      }
    }
    return all_line;
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ดาวน์โหลดไฟล์" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>ดาวน์โหลดไฟล์</b>
              </h3>
            </div>
            <div className="d-flex mx-0 px-3 pt-3">
              <input
                className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                type="text"
                placeholder="กรอกข้อมูล"
                onChange={(e) => {
                  this.setState({ data_search: e.target.value });
                }}
                value={this.state.data_search}
              />
              <button
                onClick={() => {
                  this.setState({ data_search: "" });
                }}
                className="btn btn-outline-primary mx-1 mb-3"
              >
                รีเซ็ต
              </button>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="w-100">
                <div className="card p-3" style={{ minHeight: "100%", maxHeight: 700, overflow: "auto", background: "#9999" }}>
                  <div className="d-flex">
                    <div className="py-2 px-2 pointer">
                      <span className="bg-dark text-white rounded py-1 px-2 mr-2">แสดงรายการที่อยู่ไฟล์</span>
                    </div>
                  </div>
                  <div className="pl-4">
                    {/* ชั้นที่ 1 ปี*/}
                    {this.state.folder
                      .filter((e) => this.state.data_search === "" || JSON.stringify(e).includes(this.state.data_search))
                      .map((item1, index1) => (
                        <div>
                          <div className="d-flex">
                            {this.line(1)}
                            <div
                              className={item1.selected === true ? "py-2 px-2 pointer rounded bg-light" : "py-2 px-2 pointer"}
                              onClick={() => {
                                let folder = this.state.folder;
                                if (folder[index1].open) {
                                  folder[index1].open = !folder[index1].open;
                                } else {
                                  folder[index1].open = true;
                                }
                                if (folder[index1].open === false) {
                                  folder[index1].children = JSON.parse(JSON.stringify(folder[index1].children).replaceAll(":true", ":false"));
                                }
                                this.setState({ folder: folder });
                              }}
                            >
                              <span className="icon text-18 me-2">{"\uf07b"}</span>
                              <span className="mb-0">{item1.name}</span>
                            </div>
                          </div>
                          {/* ชั้นที่ 2 เดือน */}
                          {item1.open &&
                            item1.children
                              .filter((e) => this.state.data_search === "" || JSON.stringify(e).includes(this.state.data_search))
                              .map((item2, index2) => (
                                <div>
                                  <div className="d-flex">
                                    {this.line(2)}
                                    <div
                                      className={item2.selected === true ? "py-2 px-2 pointer rounded bg-light" : "py-2 px-2 pointer"}
                                      onClick={() => {
                                        let folder = this.state.folder;
                                        if (folder[index1].children[index2].open) {
                                          folder[index1].children[index2].open = !folder[index1].children[index2].open;
                                        } else {
                                          folder[index1].children[index2].open = true;
                                        }
                                        if (folder[index1].children[index2].open === false) {
                                          folder[index1].children[index2].children = JSON.parse(JSON.stringify(folder[index1].children[index2].children).replaceAll(":true", ":false"));
                                        }
                                        this.setState({ folder: folder });
                                      }}
                                    >
                                      <span className="icon text-18 me-2">{"\uf07b"}</span>
                                      <span className="mb-0">{item2.name}</span>
                                    </div>
                                  </div>
                                  {/* ชั้นที่ 3 วัน */}
                                  {item2.open &&
                                    item2.children
                                      .filter((e) => this.state.data_search === "" || JSON.stringify(e).includes(this.state.data_search))
                                      .map((item3, index3) => (
                                        <div>
                                          <div className="d-flex">
                                            {this.line(3)}
                                            <div
                                              className={item3.selected === true ? "py-2 px-2 pointer rounded bg-light" : "py-2 px-2 pointer"}
                                              onClick={() => {
                                                let folder = this.state.folder;
                                                if (folder[index1].children[index2].children[index3].open) {
                                                  folder[index1].children[index2].children[index3].open = !folder[index1].children[index2].children[index3].open;
                                                } else {
                                                  folder[index1].children[index2].children[index3].open = true;
                                                }
                                                if (folder[index1].children[index2].children[index3].open === false) {
                                                  folder[index1].children[index2].children[index3].children = JSON.parse(JSON.stringify(folder[index1].children[index2].children[index3].children).replaceAll(":true", ":false"));
                                                }
                                                this.setState({ folder: folder });
                                              }}
                                            >
                                              <span className="icon text-18 me-2">{"\uf07b"}</span>
                                              <span className="mb-0">{item3.name}</span>
                                            </div>
                                          </div>
                                          {/* ชั้นที่ 4 ชื่อ */}
                                          {item3.open &&
                                            item3.children
                                              .filter((e) => this.state.data_search === "" || JSON.stringify(e).includes(this.state.data_search))
                                              .map((item4, index4) => (
                                                <div>
                                                  <div className="d-flex">
                                                    {this.line(4)}
                                                    <div
                                                      className={item4.selected === true ? "py-2 px-2 pointer rounded bg-light" : "py-2 px-2 pointer"}
                                                      onClick={() => {
                                                        let folder = this.state.folder;
                                                        if (folder[index1].children[index2].children[index3].children[index4].open) {
                                                          folder[index1].children[index2].children[index3].children[index4].open = !folder[index1].children[index2].children[index3].children[index4].open;
                                                        } else {
                                                          folder[index1].children[index2].children[index3].children[index4].open = true;
                                                        }
                                                        if (folder[index1].children[index2].children[index3].children[index4].open === false) {
                                                          folder[index1].children[index2].children[index3].children[index4].children = JSON.parse(JSON.stringify(folder[index1].children[index2].children[index3].children[index4].children).replaceAll(":true", ":false"));
                                                        }
                                                        this.setState({ folder: folder });
                                                      }}
                                                    >
                                                      <span className="icon text-18 me-2">{"\uf07b"}</span>
                                                      <span className="mb-0">{item4.name}</span>
                                                    </div>
                                                  </div>
                                                  {/* ชั้นที่ 5 ไฟล์ */}
                                                  {item4.open &&
                                                    item4.children
                                                      .filter((e) => this.state.data_search === "" || JSON.stringify(e).includes(this.state.data_search))
                                                      .map((item5, index5) => (
                                                        <div>
                                                          <div className="d-flex">
                                                            {this.line(5)}
                                                            <div
                                                              className={item5.selected === true ? "py-2 px-2 pointer rounded bg-light" : "py-2 px-2 pointer"}
                                                              onClick={() => {
                                                                let link = this.state.list_data.filter((e) => e.path_file.includes(item5.path.slice(0, -1)));
                                                                if (link) {
                                                                  saveAs(link[0].file, item5.name);
                                                                  this.Count(link[0].id);
                                                                } else {
                                                                  alert("warning", "แจ้งเตือน", "ไม่พบไฟล์");
                                                                }
                                                              }}
                                                            >
                                                              <span className="icon text-18 me-2">{"\uf019"}</span>
                                                              <span className="mb-0">
                                                                {item5.name} {this.state.list_data.filter((e) => e.path_file.includes(item5.path.slice(0, -1))).length !== 0 ? " (จำนวนการดาวน์โหลด " + this.state.list_data.filter((e) => e.path_file.includes(item5.path.slice(0, -1)))[0].count_download + " ครั้ง)" : "0 ครั้ง)"}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}
                                                </div>
                                              ))}
                                        </div>
                                      ))}
                                </div>
                              ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

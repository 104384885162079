import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";

import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  format_date_excel,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  days_between,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_import: false,
      modal_detail: false,
      modal_delete: false,

      modal_update_wp: false,
      tab: 1,
      loading: false,

      datetime_create: new Date(), //วันที่สร้าง
      full_name: "", //ลูกค้า
      nationality: "", //สัญชาติ
      quantity: "", //จำนวน
      list_service: [], //ค่าบริการ
      total_price: 0, //รวมเป็นเงิน
      emp_data: null, //นายจ้าง
      menu_id: "", //นายจ้าง
      menu_name: "", //นายจ้าง
      receive_code: "",
      receive_id: "",
      quotation_id: "",
      total_qty: 0,
      broker_name: "",

      installment: [],
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    if (this.props.location.query.quotation_id) {
      await this.GetQuotation(this.props.location.query.quotation_id);
    }
    if (this.props.location.query.receive_id) {
      await this.GetReceive(this.props.location.query.receive_id);
    }
    this.setState({ loading: false });
  }
  GetQuotation = async (quotation_id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      quotation_id: quotation_id,
      holder: 1
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/0", body);
    if (result && result.status) { 
      let rate_list = result.data.rate_list.map(e=>{
        return {
          qty_price:e.qty_price,
          qty:e.qty,
          discount:e.discount,
          discount_percent:e.discount_percent,
          vat: e.vat,
          remark:e.remark,
          service_type:e.service_type,
          total_price: e.service_type === "รายหัว"?(e.qty_price*e.qty)-((e.qty_price*e.qty)*(e.discount_percent/100)):e.vat+(e.qty_price)-((e.vat+e.qty_price)*(e.discount_percent/100))
        }
      })
      this.setState({
        full_name: result.data.full_name, //ลูกค้า
        nationality: result.data.cambodia_qty?"กัมพูชา":result.data.laos_qty?"ลาว":result.data.myanmar_qty?"เมียนมา":result.data.vietnam_qty?"เวียดนาม":"", //สัญชาติ
        quantity: result.data.total_qty, //จำนวน
        list_service: rate_list, //ค่าบริการ
        total_price: total(rate_list,"total_price"), //รวมเป็นเงิน
        emp_data: result.data.emp_data, //นายจ้าง
        installment: result.data.installment.map(e=> {return {
          ...e,
        }}),
        total_qty: result.data.total_qty,
        menu_id: result.data.menu_id, //นายจ้าง
        menu_name: result.data.menu_name, //นายจ้าง
        receive_code:this.props.location.query.receive_code,
        receive_id:this.props.location.query.receive_id,
        quotation_id:this.props.location.query.quotation_id,
        broker_name:this.props.location.query.broker
      })
    }
  }
  GetReceive = async (receive_id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: receive_id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) { 
      console.log(result)
      this.setState({
       
      })
    }
  }
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      receive_code: this.state.receive_code,
      full_name: this.state.full_name,
      menu_id: this.state.menu_id,
      menu_name: this.state.menu_name,  
      receive_id: this.state.receive_id,
      quotation_id: this.state.quotation_id,
      total_qty: this.state.total_qty,
      broker_name: this.props.location.query.broker,
      installment: this.state.installment.map(e=>{
        return {
          ...e,
          selected: e.selected ? e.selected : false,
          checked: e.checked ? e.checked : e.selected ? e.selected : false
        }
      }),
    };
    let result = await POST(tokens, "api/invoice/create", body);
    if (result && result.status) {
      success_url("/Invoice/list")
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
    };
    let result = await PUT(tokens, "api/invoice/update/" + this.state.id, body);
    if (result && result.status) {
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ใบแจ้งหนี้" page3="รายการใบแจ้งหนี้" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>{this.props.location.query.receive_code ? "รายการใบแจ้งหนี้ " + this.props.location.query.receive_code : "รายการใบแจ้งหนี้"} </b>
              </h3>
            </div>
            <h5>
              <b>ข้อมูลรายละเอียดรับเรื่อง</b>
            </h5>
            <hr />
            <div className="row">
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>วันที่สร้าง</b>
                </label>
                <label>{format_date(this.state.datetime_create,"dd/mm/yyyy hh:mm")}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>ลูกค้า</b>
                </label>
                <label>{this.state.full_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>บริการที่เสนอราคา</b>
                </label>
                <label>{this.state.menu_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>สัญชาติ</b>
                </label>
                <label>{this.state.nationality}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>จำนวน</b>
                </label>
                <label>{this.state.quantity} คน</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>ตัวแทน</b>
                </label>
                <label>{this.state.broker_name}</label>
              </div>
              <div className="mb-2 col-12">
                <label className="wpx-180">
                  <b>ค่าบริการ</b>
                </label>
                <div>
                  {this.state.list_service.map((item, index) => (
                    <div className="flex-wrap">
                      <label className="wpx-300">{item.remark}</label>
                      <label className="wpx-300">อัตราค่าบริการ {item.total_price} บาท</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-2 col-12">
                <label className="wpx-180">
                  <b>รวมเป็นเงิน</b>
                </label>
                <label>
                  <b>{this.state.total_price}</b>
                </label>
              </div>
            </div>
            <hr />
            <h5>
              <b>งวดเก็บค่าบริการ</b>
            </h5>
            <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th className="text-left fw-bold">งวดที่</th>
                    <th className="text-left fw-bold">จำนวนยอด</th>
                    <th className="text-left fw-bold">คิดเป็น %</th>
                    <th className="text-left fw-bold"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.installment &&
                    this.state.installment.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left">งวดที่ {index + 1}</td>
                        <td className="text-left">{item.amount ? item.amount : "-"}</td>
                        <td className="text-left">{item.percent ? item.percent.toFixed(2)+" %" : "-"}</td>
                        <td className="text-left">
                          <input type="checkbox" className="mr-2" checked={item.selected || item.selected} onChange={()=>{
                            let installment = this.state.installment;
                            installment[index].selected = !installment[index].selected ? true : !installment[index].selected;
                            this.setState({installment:installment})
                          }}
                          disabled={item.checked}
                          /> ออกใบแจ้งหนี้
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            </div>
            <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
              <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
              {this.state.installment.findIndex(e=>e.checked === false) === -1 ?(
                 <div className="d-flex mt-5">
                 <div className="w-50 pe-1">
                   <button
                     type="button"
                     className="btn btn-outline-primary w-100 mb-3"
                     onClick={() => {
                       window.location.href = "/Invoice/list";
                     }}
                   >
                     ปิด
                   </button>
                 </div>
               </div>
              ):(
                <div className="d-flex mt-5">
                  <div className="w-50 pe-1">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100 mb-3"
                      onClick={() => {
                        window.location.href = "/Invoice/list";
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                 
                  <div className="w-50 ps-1">
                    <button
                      type="button"
                      className="btn btn-primary w-100 mb-3"
                      onClick={() => {
                        if (this.props.location.query.id) {
                          this.Update();
                        } else {
                          this.Create();
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "react-loading-overlay";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";

import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  format_date_excel,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  days_between,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_import: false,
      modal_detail: false,
      modal_delete: false,

      modal_update_wp: false,
      tab: 1,
      loading: false,

      data_business_type: [],
      data_work_type: [],
      data_prefix_th: [],
      data_prefix_en: [],
      // ข้อมูลนายจ้าง
      emp_id: "",
      employer_id: "",
      emp_user_type: 0,
      emp_type: 0,
      emp_number: "",
      prefix_th: "",
      prefix_en: "",
      register_capital: "",
      register_date: "",
      id_card: "",
      place_issue: "",
      issue_date: "",
      expiry_date: "",
      hours_working_day: "",
      working_day: "",
      number_holidays: "",
      number_holidays_day: "",
      take_annual_leave: "",
      contact_name: "",
      full_name_th: "",
      full_name_en: "",
      business_type: "",
      work_type: "",
      business_description: "",
      address_number: "",
      moo: "",
      soi: "",
      street: "",
      province: "",
      district: "",
      sub_district: "",
      zipcode: "",
      phone: "",
      email: "",
      fax: "",
      mobile_phone: "",
      home_number: "",
      phone_2: "",
      fax_2: "",
      binding_authority_th: "",
      binding_authority_en: "",
      position_th: "",
      position_en: "",
      operator_type: 0,
      deputize_number: "",
      deputize_full_name: "",
      deputize_phone: "",
      username: "",
      password: "",
      confirm_password: "",

      work_emp_id: "",
      work_address_number: "",
      work_home_number: "",
      work_moo: "",
      work_soi: "",
      work_street: "",
      work_province: "",
      work_district: "",
      work_sub_district: "",
      work_zipcode: "",
      work_phone_create: "",
      work_email_create: "",

      // ไฟล์แนบ
      copy_company_certificate: "",
      copy_company_certificate_date: "",
      copy_power_attorney: "",
      copy_power_attorney_date: "",
      copy_tax_certificate: "",
      copy_tax_certificate_date: "",

      confirm_policy: false,

      // ผู้ประสานงาน
      coordinator_name: "",
      coordinator_phone: "",
      coordinator_email: "",
      modal_coordinator_create: false,
      modal_coordinator_update: false,
      modal_coordinator_delete: false,
      index: 0,

      phone_create: "",
      email_create: "",
      modal_create: false,
      data_workplace: [],
      data_workplace2: [],
      data_coordinator: [],

      data_deputize: [],
      attachment_file: "",

      copy_idcard_image: "",
      copy_idcard_type: "",

      copy_house_registration: "",
      use_location_document: "",

      data_request: [],
      fw_data: [],
      work_pm_number: "",
      data_search_2: "",
      id: "",
      place_work: "",
      receive_id: "",

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2: "",
      witness_contract_1: "",
      witness_contract_2: "",

      reason: "ประกาศรับสมัครงานแล้วไม่มีคนไทยมาสมัครงาน",
      // emp_period_y:"",
      // emp_period_m:"",
      // emp_period_d:"",
      // contract_until_date:"",

      copy_idcard_image2: "",

      // ไฟล์
      import: null,
      currentSheet: null,
      data_import: null,

      data_immigration_office: [],
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetImmigration();
    await this.GetBusinessType();
    await this.GetWorkType();
    await this.GetPrefix();
    await this.GetAdmin();
    if (this.props.location.query.id) {
      await this.Get(this.props.location.query.id);
    }
    if (this.props.location.query.type === "name_list") {
      this.GetRequest();
    }
    if (this.props.location.query.receive_id) {
      await this.GetFW(this.props.location.query.receive_id);
    }
    this.setState({ loading: false });
  }
  GetImmigration = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_immigration_office: result.data.map((e) => {
          return { value: e.id.toString(), label: e.imm_name_th };
        }),
      });
    }
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetAdmin = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_admin: result.data,
      });
    }
  };
  Get = async (id) => {
    let body = {
      data_search: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/employer/list/page/0", body);
    if (result && result.status) {
      let item = result.data;

      this.setState({
        id: item.emp_id,
        emp_id: item.emp_id,
        emp_user_type: item.emp_user_type,
        emp_type: item.emp_type,
        emp_number: item.emp_number,
        prefix_th: item.prefix_th ? this.state.data_prefix_th.filter((e) => e.value === item.prefix_th.toString())[0] : "",
        prefix_en: item.prefix_en ? this.state.data_prefix_en.filter((e) => e.value === item.prefix_en.toString())[0] : "",
        register_capital: item.register_capital,
        register_date: item.register_date,
        id_card: item.id_card,
        place_issue: item.place_issue,
        issue_date: item.issue_date,
        expiry_date: item.expiry_date,
        hours_working_day: item.hours_working_day,
        number_holidays: item.number_holidays,
        working_day: item.working_day,
        number_holidays_day: item.number_holidays_day,
        take_annual_leave: item.take_annual_leave,
        contact_name: item.contact_name,
        full_name_th: item.full_name_th,
        full_name_en: item.full_name_en,
        business_type: item.business_type ? this.state.data_business_type.filter((e) => e.value === item.business_type.toString())[0] : "",
        work_type: item.work_type ? this.state.data_work_type.filter((e) => e.value === item.work_type.toString())[0] : "",
        business_description: item.business_description,
        address_number: item.address_number,
        moo: item.moo,
        soi: item.soi,
        street: item.street,
        province: { value: item.province, label: item.province, district: item.district },
        district: { value: item.district, label: item.district, sub_district: item.sub_district },
        sub_district: { value: item.sub_district, label: item.sub_district, zipcode: item.zipcode },
        zipcode: item.zipcode,
        phone: item.phone,
        email: item.email,
        fax: item.fax,
        mobile_phone: item.mobile_phone,
        home_number: item.home_number,
        phone_2: item.phone_2,
        fax_2: item.fax_2,
        binding_authority_th: item.binding_authority_th,
        binding_authority_en: item.binding_authority_en,
        position_th: item.position_th,
        position_en: item.position_en,
        data_workplace: item.pw_data,
        data_coordinator: item.coordinator_data,
        username: item.username,
        data_deputize: item.dpz_data ? item.dpz_data : [],
        copy_idcard_image: item.copy_idcard_image,
        copy_house_registration: item.copy_house_registration,
        use_location_document: item.use_location_document,
        fw_data: item.fw_data,
        reason: item.reason,
        // emp_period_y:item.emp_period_y,
        // emp_period_m:item.emp_period_m,
        // emp_period_d:item.emp_period_d,
        // contract_until_date:item.contract_until_date,
      });
    }
  };
  GetFW = async (id) => {
    let body = {
      data_search: "",
      receive_id: id,
      menu: "MOU",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      let item = result.data;
      let place_work = "";
      if (item.place_work) {
        place_work = item.pw_data.filter((e) => e.pw_id === item.place_work)[0];
      }
      this.setState({
        id: item.emp_data.emp_id,
        emp_id: item.emp_data.emp_id,
        emp_user_type: item.emp_data.emp_user_type,
        emp_type: item.emp_data.emp_type,
        emp_number: item.emp_data.emp_number,
        prefix_th: item.emp_data.prefix_th ? this.state.data_prefix_th.filter((e) => e.value === item.emp_data.prefix_th.toString())[0] : "",
        prefix_en: item.emp_data.prefix_en ? this.state.data_prefix_en.filter((e) => e.value === item.emp_data.prefix_en.toString())[0] : "",
        register_capital: item.emp_data.register_capital,
        register_date: item.emp_data.register_date,
        id_card: item.emp_data.id_card,
        place_issue: item.emp_data.place_issue,
        issue_date: item.emp_data.issue_date,
        expiry_date: item.emp_data.expiry_date,
        hours_working_day: item.emp_data.hours_working_day,
        number_holidays: item.emp_data.number_holidays,
        working_day: item.emp_data.working_day,
        number_holidays_day: item.emp_data.number_holidays_day,
        take_annual_leave: item.emp_data.take_annual_leave,
        contact_name: item.emp_data.contact_name,
        full_name_th: item.emp_data.full_name_th,
        full_name_en: item.emp_data.full_name_en,
        business_type: item.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === item.emp_data.business_type.toString())[0] : "",
        work_type: item.work_type ? this.state.data_work_type.filter((e) => e.value === item.work_type.toString())[0] : "",
        business_description: item.emp_data.business_description,
        address_number: item.emp_data.address_number,
        moo: item.emp_data.moo,
        soi: item.emp_data.soi,
        street: item.emp_data.street,
        province: { value: item.emp_data.province, label: item.emp_data.province, district: item.emp_data.district },
        district: { value: item.emp_data.district, label: item.emp_data.district, sub_district: item.emp_data.sub_district },
        sub_district: { value: item.emp_data.sub_district, label: item.emp_data.sub_district, zipcode: item.emp_data.zipcode },
        zipcode: item.emp_data.zipcode,
        phone: item.emp_data.phone,
        email: item.emp_data.email,
        fax: item.emp_data.fax,
        mobile_phone: item.emp_data.mobile_phone,
        home_number: item.emp_data.home_number,
        phone_2: item.emp_data.phone_2,
        fax_2: item.emp_data.fax_2,
        binding_authority_th: item.emp_data.binding_authority_th,
        binding_authority_en: item.emp_data.binding_authority_en,
        position_th: item.emp_data.position_th,
        position_en: item.emp_data.position_en,
        data_workplace: item.emp_data.pw_data,
        data_coordinator: item.emp_data.coordinator_data,
        username: item.emp_data.username,
        data_deputize: item.emp_data.dpz_data ? item.emp_data.dpz_data : [],
        copy_idcard_image: item.emp_data.copy_idcard_image,
        copy_house_registration: item.emp_data.copy_house_registration,
        use_location_document: item.emp_data.use_location_document,
        fw_data: item.fw_data,
        place_work: place_work ? (place_work.address_number ? place_work.address_number : "") + (place_work.moo ? " หมู่ " + place_work.moo : "") + (place_work.soi ? " ซอย " + place_work.soi : "") + (place_work.street ? " ถนน " + place_work.street : "") + (place_work.sub_district ? " ตำบล " + place_work.sub_district : "") + (place_work.district ? " อำเภอ " + place_work.district : "") + (place_work.province ? " จังหวัด " + place_work.province : "") + (place_work.zipcode ? " รหัสไปรษณีย์ " + place_work.zipcode : "") : "",

        // พยาน
        witness_power_1: item.witness_power_1 ? this.state.data_admin.filter((e) => e.user_id === item.witness_power_1)[0] : "",
        witness_power_2_th: item.witness_power_2_th ? this.state.data_admin.filter((e) => e.user_id === item.witness_power_2_th)[0] : "",
        witness_power_2_en: "",
        witness_contract_1: item.witness_contract_1 ? this.state.data_admin.filter((e) => e.user_id === item.witness_contract_1)[0] : "",
        witness_contract_2_th: item.witness_contract_2_th,
        witness_contract_2_en: item.witness_contract_2_en,
        receive_id: id,
      });
    }
  };
  GetRequest = async () => {
    let body = {
      data_search: "",
      fw_id: "",
    };
    let result = await GET(tokens, "api/foreign-worker/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_request: result.data.filter((e) => e.emp_data.emp_id === this.props.location.query.id),
      });
    }
  };
  GetUser = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
    };
    let result = await GET(tokens, "api/employer/department-trade", body);
    if (result && result.status) {
      this.setState({
        emp_type: result.data.emp_type,
        emp_number: result.data.emp_number,
        full_name_th: result.data.full_name_th,
        address_number: result.data.address_number,
        moo: result.data.moo,
        soi: result.data.soi,
        street: result.data.street,
        province: result.data.province
          ? province
              .map((item) => {
                return { value: item.province_name, label: item.province_name };
              })
              .filter((item) => item.value === result.data.province)[0]
          : "",
        district: result.data.district
          ? district
              .map((item) => {
                return { value: item.district_name, label: item.district_name };
              })
              .filter((item) => item.value === result.data.district)[0]
          : "",
        sub_district: result.data.sub_district
          ? subdistrict
              .map((item) => {
                return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
              })
              .filter((item) => item.value === result.data.sub_district)[0]
          : "",
        zipcode: result.data.sub_district
          ? subdistrict
              .map((item) => {
                return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
              })
              .filter((item) => item.value === result.data.sub_district)[0].zipcode
          : "",
      });
    }
    this.setState({ loading: false });
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace2: result.data,
      });
    }
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      register_capital: this.state.register_capital,
      register_date: this.state.register_date,
      id_card: this.state.id_card,
      place_issue: this.state.place_issue,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      hours_working_day: this.state.hours_working_day,
      number_holidays: this.state.number_holidays,
      working_day: this.state.working_day,
      number_holidays_day: this.state.number_holidays_day,
      take_annual_leave: this.state.take_annual_leave,
      contact_name: this.state.contact_name,
      full_name_th: this.state.full_name_th,
      full_name_en: this.state.full_name_en,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      work_type: this.state.work_type ? Number(this.state.work_type.value) : "",
      business_description: this.state.business_description,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone,
      email: this.state.email,
      fax: this.state.fax,
      mobile_phone: this.state.mobile_phone,
      home_number: this.state.home_number,
      phone_2: this.state.phone_2,
      fax_2: this.state.fax_2,
      binding_authority_th: this.state.binding_authority_th,
      binding_authority_en: this.state.binding_authority_en,
      position_th: this.state.position_th,
      position_en: this.state.position_en,
      data_deputize: this.state.data_deputize,
      username: this.state.emp_number,
      password: this.state.emp_number,
      data_coordinator: this.state.data_coordinator,
      data_workplace: this.state.data_workplace,
      copy_idcard_image: this.state.copy_idcard_image,
      copy_house_registration: this.state.copy_house_registration,
      use_location_document: this.state.use_location_document,
      reason: this.state.reason,
      // emp_period_y:this.state.emp_period_y,
      // emp_period_m:this.state.emp_period_m,
      // emp_period_d:this.state.emp_period_d,
      // contract_until_date:this.state.contract_until_date,
    };
    let result = await POST(tokens, "api/employer/create", body);
    if (result && result.status) {
      Swal.fire({
        icon: "success",
        title: "สำเร็จ",
        text: "การทำรายการสำเร็จแล้ว",
        confirmButtonText: "เพิ่มลูกจ้าง",
        showCancelButton: true,
        cancelButtonText: "ตกลง",
        cancelButtonColor: "#0F4C81",
      }).then((re) => {
        if (re.isConfirmed) {
          window.location.href = "/Employee/create?emp_number=" + this.state.emp_number;
          return;
        } else {
          window.location.href = "/Employer/list";
        }
      });
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      register_capital: this.state.register_capital,
      register_date: this.state.register_date,
      id_card: this.state.id_card,
      place_issue: this.state.place_issue,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      hours_working_day: this.state.hours_working_day,
      number_holidays: this.state.number_holidays,
      working_day: this.state.working_day,
      number_holidays_day: this.state.number_holidays_day,
      take_annual_leave: this.state.take_annual_leave,
      contact_name: this.state.contact_name,
      full_name_th: this.state.full_name_th,
      full_name_en: this.state.full_name_en,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      work_type: this.state.work_type ? Number(this.state.work_type.value) : "",
      business_description: this.state.business_description,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone,
      email: this.state.email,
      fax: this.state.fax,
      mobile_phone: this.state.mobile_phone,
      home_number: this.state.home_number,
      phone_2: this.state.phone_2,
      fax_2: this.state.fax_2,
      binding_authority_th: this.state.binding_authority_th,
      binding_authority_en: this.state.binding_authority_en,
      position_th: this.state.position_th,
      position_en: this.state.position_en,
      // operator_type: this.state.operator_type,
      // deputize_number: this.state.deputize_number,
      // deputize_full_name: this.state.deputize_full_name,
      // username: this.state.emp_number,
      // password: this.state.password,
      data_deputize: this.state.data_deputize,
      data_coordinator: this.state.data_coordinator,
      data_workplace: this.state.data_workplace,
      copy_idcard_image: this.state.copy_idcard_image,
      copy_house_registration: this.state.copy_house_registration,
      use_location_document: this.state.use_location_document,
      reason: this.state.reason,
      // emp_period_y:this.state.emp_period_y,
      // emp_period_m:this.state.emp_period_m,
      // emp_period_d:this.state.emp_period_d,
      // contract_until_date:this.state.contract_until_date,
    };
    let result = await PUT(tokens, "api/employer/update/" + this.state.id, body);
    if (result && result.status) {
      Swal.fire({
        icon: "success",
        title: "สำเร็จ",
        text: "การทำรายการสำเร็จแล้ว",
        confirmButtonText: "เพิ่มลูกจ้าง",
        showCancelButton: true,
        cancelButtonText: "ตกลง",
        cancelButtonColor: "#0F4C81",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/Employee/create?emp_number=" + this.state.emp_number;
        } else {
          window.location.href = "/Employer/list";
        }
      });
    }
    this.setState({ loading: false });
  };
  UpdateWP = async () => {
    this.setState({ loading: true });
    let body = {
      // Work permit
      wp_number: this.state.wp_number,
    };
    let result = await PUT(tokens, "api/foreign-worker/update/" + this.state.fw_id, body);
    if (result && result.status) {
      if(this.props.location.query.receive_codes){
        success_url("/Employer/update?receive_id=" + this.props.location.query.receive_id + "&type=name_list&receive_codes=" + this.props.location.query.receive_codes+ "&menu_id=" + this.props.location.query.menu_id+ "&menu_name=" + this.props.location.query.menu_name);
      } else {
        success_url("/Employer/update?id=" + this.state.emp_id + "&type=name_list");
      }
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let files = null;
        if (file.type.includes("pdf")) {
          files = e.target.result.split(",")[1];
        }else{
          files = await convert_imagetopdf(e.target.result);
        }
        if (type === "copy_company_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_company_certificate = files;
          data_deputize[this.state.index].copy_company_certificate_date = new Date();
          data_deputize[this.state.index].copy_company_certificate_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_power_attorney_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_power_attorney = files;
          data_deputize[this.state.index].copy_power_attorney_date = new Date();
          data_deputize[this.state.index].copy_power_attorney_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_tax_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_tax_certificate = files;
          data_deputize[this.state.index].copy_tax_certificate_date = new Date();
          data_deputize[this.state.index].copy_tax_certificate_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_idcard_image") {
          this.setState({ copy_idcard_image: files, copy_idcard_type: "pdf" });
        } else if (type === "copy_house_registration") {
          this.setState({ copy_house_registration: files });
        } else if (type === "use_location_document") {
          this.setState({ use_location_document: files });
        }
      };
    
    if (type === "copy_company_certificate_array") {
      document.getElementById("file_copy_company_certificate_array").value = "";
      document.getElementById("file_copy_company_certificate_array").file = null;
    } else if (type === "copy_power_attorney_array") {
      document.getElementById("file_copy_power_attorney_array").value = "";
      document.getElementById("file_copy_power_attorney_array").file = null;
    } else if (type === "copy_tax_certificate_array") {
      document.getElementById("file_copy_tax_certificate_array").value = "";
      document.getElementById("file_copy_tax_certificate_array").file = null;
    } else if (type === "copy_idcard_image") {
      document.getElementById("file_copy_idcard_image").value = "";
      document.getElementById("file_copy_idcard_image").file = null;
    } else if (type === "copy_house_registration") {
      document.getElementById("file_copy_house_registration").value = "";
      document.getElementById("file_copy_house_registration").file = null;
    } else if (type === "use_location_document") {
      document.getElementById("file_use_location_document").value = "";
      document.getElementById("file_use_location_document").file = null;
    }
  };
  import_excel = (e) => {
    readFile(e.target.files[0])
      .then((readedData) => {
        this.setState({ import: readedData });
        setTimeout(() => {
          this.Import();
        }, 10);
      })
      .catch((error) => console.error(error));
    document.getElementById("file").value = "";
    document.getElementById("file").file = null;
  };
  Import = async () => {
    this.setState({ loading: true });
    let currentSheet = this.state.currentSheet;
    currentSheet.Sheet1 = currentSheet.Sheet1.slice(1);
    let array2 = [];
    for (let item of currentSheet.Sheet1[0]) {
      if (array2.findIndex((e) => e === item) !== -1) {
        array2.push(item + "_");
      } else {
        array2.push(item);
      }
    }
    currentSheet.Sheet1[0] = array2;
    const result = generateObjects(currentSheet);
    // ตรวจสอบจำนวนลูกจ้าง
    if (this.state.fw_data.length < result.length) {
      alert("warning", "แจ้งเตือน", "ข้อมูลที่นำเข้ามีมากกว่าจำนวนลูกจ้างในระบบ");
      return;
    }
    let array_check = ["ลำดับที่", "นายจ้างเลขประจำตัว", "เลขประจำตัว 13 หลัก", "คำนำหน้า", "คำนำหน้า EN", "ลูกจ้างชื่อ EN", "สัญชาติ", "เพศ", "ว-ด-ป เกิด คศ"];
    for (let i = 0; i < array_check.length; i++) {
      if (this.state.currentSheet["Sheet1"][0][i] !== array_check[i]) {
        alert("warning", "แจ้งเตือน", 'หัวคอลัมน์ "' + array_check[i] + '" ไม่ถูกต้อง');
        this.setState({ loading: false });
        return;
      }
    }
    this.setState({ data_import: result.filter((item) => item["ลำดับที่"] !== ""), loading: false });
  };
  create_import = async () => {
    this.setState({ loading: true });
    let result = this.state.data_import;
    let address_list = [];
    for (let item of result) {
      if (address_list.findIndex((e) => e.address_number === item["สถานที่ทำงานเลขที่ "] && e.moo === item["หมู่_"] && e.soi === item["ซอย_"] && e.sub_district === item["ตำบล/แขวง_"] && e.district === item["อำเภอ/เขต_"] && e.province === item["จังหวัด_"]) === -1) {
        if (item["สถานที่ทำงานเลขที่ "] === "" && item["หมู่_"] === "" && item["ซอย_"] === "" && item["ตำบล/แขวง_"] === "" && item["อำเภอ/เขต_"] === "" && item["จังหวัด_"] === "") {
        } else {
          address_list.push({
            address_number: item["สถานที่ทำงานเลขที่ "],
            moo: item["หมู่_"],
            soi: item["ซอย_"],
            sub_district: item["ตำบล/แขวง_"],
            district: item["อำเภอ/เขต_"],
            province: item["จังหวัด_"],
          });
        }
      }
    }
    // สร้างที่อยู่
    if (address_list.length !== 0) {
      let body = {
        import_data: address_list.map((e) => {
          return {
            emp_number: this.state.emp_number,
            address_number: e.address_number,
            moo: e.moo,
            soi: e.soi,
            sub_district: e.sub_district,
            district: e.district,
            province: e.province,
            zipcode: e.sub_district ? subdistrict.filter((item) => item.sub_district_name === e.sub_district.trim())[0]?.zip_code : "",
          };
        }),
      };
      let result2 = await POST(tokens, "api/employer/import", body);
      if (result2 && result2.status) {
        await this.GetWorkplace(this.state.emp_id);
      }
    }
    let immigration_list = [];
    for (let item of result) {
      if (immigration_list.findIndex((e) => e === item["ออกให้ที่_"]) === -1 && item["ออกให้ที่_"] !== "" && this.state.data_immigration_office.findIndex((e) => e.label === item["ออกให้ที่_"]) === -1) {
        immigration_list.push(item["ออกให้ที่_"]);
      }
    }
    // สร้าง ตม.
    if (immigration_list.length !== 0) {
      let body = {
        import_data: immigration_list.map((e) => {
          return {
            imm_name_th: e,
            imm_name_en: "",
          };
        }),
      };
      let result2 = await POST(tokens, "api/setting/immigration/import", body);
      if (result2 && result2.status) {
        await this.GetImmigration();
      }
    }
    let fw_data_empty = this.state.fw_data.filter((e) => !e.fw_number);
    let fw_data_have = this.state.fw_data.filter((e) => e.fw_number);
    let fw_index = 0;

    var data = result
      .filter((item) => item["ลำดับที่"] !== "")
      .map((item, index) => {
        // สถานที่ทำงาน
        let place_work = this.state.data_workplace2.filter((e) => e.address_number === item["สถานที่ทำงานเลขที่ "] && e.moo === item["หมู่_"] && e.soi === item["ซอย_"] && e.sub_district === item["ตำบล/แขวง_"] && e.district === item["อำเภอ/เขต_"] && e.province === item["จังหวัด_"]);
        // อายุ
        let day = days_between(new Date(), item["ว-ด-ป เกิด คศ"] ? new Date(format_date_excel(item["ว-ด-ป เกิด คศ"], "date", "en")) : new Date()).day;
        let age = Number((day / 365).toString().split(".")[0]);

        let fw_data = fw_data_have.filter((e) => e.fw_number === item["เลขประจำตัว 13 หลัก"]);
        let fw_id = "";

        if (fw_data.length !== 0) {
          fw_id = fw_data[0].id;
        } else {
          fw_id = fw_data_empty[fw_index].id;
          fw_index += 1;
        }
        // จัดข้อมูล
        return {
          fw_id: fw_id,
          receive_id: this.state.receive_id,
          emp_id: this.state.emp_id,
          business_type: Number(this.state.business_type.value),
          province: item["จังหวัด"],
          place_work: place_work.length !== 0 ? place_work[0].id : "",
          phone: "",
          email: "",

          fw_number: item["เลขประจำตัว 13 หลัก"],
          nationality: item["สัญชาติ"] === "เมียนมา" ? 1 : item["สัญชาติ"] === "ลาว" ? 2 : item["สัญชาติ"] === "กัมพูชา" ? 3 : item["สัญชาติ"] === "เวียดนาม" ? 4 : "",
          prefix_th: item["คำนำหน้า"] ? Number(this.state.data_prefix_th.filter((e) => e.label === item["คำนำหน้า"])[0].value) : "",
          first_name_th: "",
          middle_name_th: "",
          last_name_th: "",
          prefix_en: item["คำนำหน้า"] ? Number(this.state.data_prefix_th.filter((e) => e.label === item["คำนำหน้า"])[0].value) : "",
          first_name_en: item["ลูกจ้างชื่อ EN"],
          middle_name_en: "",
          last_name_en: "",
          birth_date: item["ว-ด-ป เกิด คศ"] ? format_date_excel(item["ว-ด-ป เกิด คศ"], "yyyy-mm-dd", "en") : null,
          age: age,
          gender: item["เพศ"] === "M" ? "ชาย" : item["เพศ"] === "W" ? "หญิง" : "",
          job_position: "",

          // ข้อมูลที่อยู่ลูกจ้าง
          fw_housing_type: item["สถานที่พักอาศัยเลขที่ "] === item["สถานที่ทำงานเลขที่ "] ? "ที่เดียวกับสถานที่ทำงาน" : item["สถานที่พักอาศัยเลขที่ "] === this.state.address_number ? "ที่เดียวกับที่ตั้ง/ที่อยู่ของนายจ้าง" : "ที่อื่น",
          fw_house_number: item["สถานที่พักอาศัยเลขที่ "],
          fw_building_name: "",
          fw_moo: item["หมู่"],
          fw_soi: item["ซอย"],
          fw_street: item["ถนน"],
          fw_province: item["จังหวัด"].trim(),
          fw_district: item["อำเภอ/เขต"].trim(),
          fw_sub_district: item["ตำบล/แขวง"].trim(),
          fw_zipcode: item["ตำบล/แขวง"].trim() ? subdistrict.filter((e) => e.sub_district_name === item["ตำบล/แขวง"].trim())[0]?.zip_code : "",
          fw_phone: "",
          fw_fax: "",
          fw_entry_country_date: "",
          fw_entry_checkpoint: "",
          fw_place_of_birth: item["สถานที่เกิด"],
          fw_country: "",

          // Passport
          passport_type: item["Passport no."] ? "Passport" : "",
          passport_number: item["Passport no."],
          passport_place: item["ออกให้ที่"],
          passport_born_place: item["สถานที่เกิด"],
          passport_country: item["สัญชาติ"],
          passport_allowed_date: item["ออกให้วันที่"] ? format_date_excel(item["ออกให้วันที่"], "yyyy-mm-dd", "en") : null,
          passport_expiry_date: item["ใช้ได้ถึง"] ? format_date_excel(item["ใช้ได้ถึง"], "yyyy-mm-dd", "en") : null,
          passport_image: "",

          // Visa
          visa_number: item["VISA no."],
          visa_allowed_location: item["ออกให้ที่_"] ? Number(this.state.data_immigration_office.filter((e) => e.label === item["ออกให้ที่_"])[0].value) : "",
          visa_allowed_date: item["ออกให้วันที่_"] ? format_date_excel(item["ออกให้วันที่_"], "yyyy-mm-dd", "en") : null,
          visa_expiry_date: item["ใช้ได้ถึง_"] ? format_date_excel(item["ใช้ได้ถึง_"], "yyyy-mm-dd", "en") : null,
          visa_stay_kingdom_date: item["อนุญาตให้อยู่ในราชอาณาจักรได้ถึงวันที่"] ? format_date_excel(item["อนุญาตให้อยู่ในราชอาณาจักรได้ถึงวันที่"], "yyyy-mm-dd", "en") : null,
          visa_image: "",

          insurance_type: 3,
          sso_hospital_name: item["รพ.สปส."],
        };
      });
    let body = {
      import_data: data,
    };
    let result2 = await POST(tokens, "api/foreign-worker/import", body);
    if (result2 && result2.status) {
      Swal.fire({
        icon: "success",
        title: "สำเร็จ",
        text: "การทำรายการสำเร็จแล้ว",
        confirmButtonText: "ตกลง",
      }).then((re) => {
        window.location.reload();
      });
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ทะเบียนนายจ้าง" page3="เพิ่มนายจ้าง" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          {this.props.location.query.type === "name_list" ? (
            <div className="p-3">
              {/* หัวข้อ และ ปุ่มเพิ่ม */}
              <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
                <h3 className="mb-3">
                  <b>{this.props.location.query.receive_codes ? "รายละเอียดจากใบรับเรื่อง " + this.props.location.query.receive_codes : "รายชื่อลูกจ้าง"} </b>
                </h3>
              </div>
              <div
                className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                onClick={() => {
                  window.location.href = "/Employer/update?id=" + this.state.emp_id;
                }}
              >
                แก้ไขข้อมูลนายจ้าง
              </div>
              <div className="row">
                {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                <div className="mb-3 col-6">
                  <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                </div>
                <div className="mb-3 col-6">{this.state.emp_number ? this.state.emp_number : "-"}</div>
                {/* English */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>English</b>
                  </div>
                  {this.state.full_name_en ? this.state.full_name_en : "-"}
                </div>
                {/* ประเภทนายจ้าง */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ประเภทนายจ้าง</b>
                  </div>
                  {this.state.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_type === 2 ? "นิติบุคคล" : this.state.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                </div>
                {/* จดทะเบียนเมื่อ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>จดทะเบียนเมื่อ</b>
                  </div>
                  {this.state.register_date ? format_date(this.state.register_date) : "-"}
                </div>
                {/* ทุนจดทะเบียน (บาท) */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ทุนจดทะเบียน (บาท)</b>
                  </div>
                  {this.state.register_capital ? toFixed(this.state.register_capital) : "-"}
                </div>
                {/* ประเภทกิจการ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ประเภทกิจการ</b>
                  </div>
                  {this.state.business_type ? this.state.business_type.label : "-"}
                </div>
                {/* ชื่อผู้ติดต่อ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ชื่อผู้ติดต่อ</b>
                  </div>
                  {this.state.contact_name ? this.state.contact_name : "-"}
                </div>
                {/* หมายเลขโทรศัพท์ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์</b>
                  </div>
                  {this.state.phone ? this.state.phone : "-"}
                </div>
                {/* หมายเลขโทรสาร */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรสาร</b>
                  </div>
                  {this.state.fax ? this.state.fax : "-"}
                </div>
                {/* หมายเลขโทรศัพท์มือถือ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์มือถือ</b>
                  </div>
                  {this.state.phone ? this.state.phone : "-"}
                </div>
                {/* E-mail Address */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>E-mail Address</b>
                  </div>
                  {this.state.email ? this.state.email : "-"}
                </div>
                {/* เลขบ้าน ๑๑ หลัก */}
                {/* <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>เลขบ้าน ๑๑ หลัก</b>
                  </div>
                  {this.state.home_number ? this.state.home_number : "-"}
                </div> */}
                {/* ที่ตั้ง/ที่อยู่ */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>ที่ตั้ง/ที่อยู่</b>
                  </div>
                  {this.state.address_number ? this.state.address_number : ""}
                  {this.state.moo ? " หมู่ " + this.state.moo : ""}
                  {this.state.soi ? " ซอย " + this.state.soi : ""}
                  {this.state.street ? " ถนน " + this.state.street : ""}
                  {this.state.sub_district ? " ตำบล " + this.state.sub_district.label : ""}
                  {this.state.district ? " อำเภอ " + this.state.district.label : ""}
                  {this.state.province ? " จังหวัด " + this.state.province.label : ""}
                  {this.state.zipcode ? " รหัสไปรษณีย์ " + this.state.zipcode : ""}
                </div>
                {/* ที่ตั้ง/ที่อยู่ (English) */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>ที่ตั้ง/ที่อยู่ (English)</b>
                  </div>
                  {this.state.address_number ? this.state.address_number : ""}
                  {this.state.moo ? " Moo " + this.state.moo : ""}
                  {this.state.soi ? " Soi " + this.state.soi : ""}
                  {this.state.street ? " Street " + this.state.street : ""}
                  {this.state.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.sub_district.label)[0]?.sub_district_name_en : ""}
                  {this.state.district ? " , " + district.filter((e) => e.district_name === this.state.district.label)[0]?.district_name_en : ""}
                  {this.state.province ? " , " + province.filter((e) => e.province_name === this.state.province.label)[0]?.province_name_en : ""}
                  {this.state.zipcode ? " " + this.state.zipcode : ""}
                </div>
                {/* หมายเลขโทรศัพท์ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์</b>
                  </div>
                  {this.state.phone_2 ? this.state.phone_2 : "-"}
                </div>
                {/* หมายเลขโทรสาร */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรสาร</b>
                  </div>
                  {this.state.fax_2 ? this.state.fax_2 : "-"}
                </div>
                {/* สถานที่ทำงาน */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>สถานที่ทำงาน</b>
                  </div>
                  {this.state.place_work ? this.state.place_work : "-"}
                </div>
                {/* พยาน ใบมอบอำนาจ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน ใบมอบอำนาจ คนที่ 1 ไทย</b>
                  </div>
                  {this.state.witness_power_1 ? this.state.witness_power_1.full_name : "-"}
                </div>
                {/* พยาน ใบมอบอำนาจ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน ใบมอบอำนาจ คนที่ 1 English</b>
                  </div>
                  {this.state.witness_power_1 ? this.state.witness_power_1.full_name_en : "-"}
                </div>
                {/* พยาน ใบมอบอำนาจ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน ใบมอบอำนาจ คนที่ 2 ไทย</b>
                  </div>
                  {this.state.witness_power_2_th ? this.state.witness_power_2_th.full_name : "-"}
                </div>
                {/* พยาน ใบมอบอำนาจ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน ใบมอบอำนาจ คนที่ 2 English</b>
                  </div>
                  {this.state.witness_power_2_th ? this.state.witness_power_2_th.full_name_en : "-"}
                </div>
                {/* พยาน สัญญา 3 ภาษา */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน สัญญา 3 ภาษา คนที่ 1 ไทย</b>
                  </div>
                  {this.state.witness_contract_1 ? this.state.witness_contract_1.full_name : "-"}
                </div>
                {/* พยาน สัญญา 3 ภาษา */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน สัญญา 3 ภาษา คนที่ 1 English</b>
                  </div>
                  {this.state.witness_contract_1 ? this.state.witness_contract_1.full_name_en : "-"}
                </div>
                {/* พยาน สัญญา 3 ภาษา */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน สัญญา 3 ภาษา คนที่ 2 ไทย</b>
                  </div>
                  {this.state.witness_contract_2_th ? this.state.witness_contract_2_th : "-"}
                </div>
                {/* พยาน สัญญา 3 ภาษา */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>พยาน สัญญา 3 ภาษา คนที่ 2 English</b>
                  </div>
                  {this.state.witness_contract_2_en ? this.state.witness_contract_2_en : "-"}
                </div>
                {/* ผู้มีอำนาจผูกพัน */}
                {/* <div className="mb-3 d-flex col-6">
                                    <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน</b></div>
                                    {this.state.binding_authority_th ? this.state.binding_authority_th : "-"}
                                </div> */}
                {/* ตำแหน่ง */}
                {/* <div className="mb-3 d-flex col-6">
                                    <div className="wpx-220"><b>ตำแหน่ง</b></div>
                                    {this.state.position_th ? this.state.position_th : "-"}
                                </div> */}
                {/* ผู้มีอำนาจผูกพัน (English) */}
                {/* <div className="mb-3 d-flex col-6">
                                    <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน (English)</b></div>
                                    {this.state.binding_authority_en ? this.state.binding_authority_en : "-"}
                                </div> */}
                {/* ตำแหน่ง (English) */}
                {/* <div className="mb-3 d-flex col-6">
                                    <div className="wpx-220"><b>ตำแหน่ง (English)</b></div>
                                    {this.state.position_en ? this.state.position_en : "-"}
                                </div> */}
              </div>
              <div className="row">
                {this.state.data_deputize.map((item, index) => (
                  <div>
                    {/* ผู้มีอำนาจผูกพัน */}
                    <div className="mb-3 d-flex col-6">
                      <div className="wpx-220">
                        <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                      </div>
                      {item.deputize_full_name ? item.deputize_full_name : "-"}
                    </div>
                    {/* ตำแหน่ง */}
                    <div className="mb-3 d-flex col-6">
                      <div className="wpx-220">
                        <b>ตำแหน่ง</b>
                      </div>
                      {item.deputize_position ? item.deputize_position : "-"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-100 card border-0 p-0">
                <div className="d-flex justify-content-between">
                  <div className="d-flex mx-0 px-3">
                    <input
                      className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                      type="text"
                      placeholder="กรอกข้อมูล"
                      onChange={(e) => {
                        this.setState({ data_search_2: e.target.value });
                      }}
                      value={this.state.data_search_2}
                    />
                    <button className="btn btn-primary mx-1 mb-3">ค้นหา</button>
                    <button
                      onClick={() => {
                        this.setState({ data_search_2: "" });
                      }}
                      className="btn btn-outline-primary mx-1 mb-3"
                    >
                      รีเซ็ต
                    </button>
                  </div>
                  <button
                    className="btn btn-primary create[Employee]"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    นำเข้าไฟล์
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-borderless table-striped">
                    <thead className="bg-primary">
                      <tr>
                        <th className="text-center text-white">ลำดับ</th>
                        <th className="text-left text-white">ชื่อ-นามสกุล</th>
                        <th className="text-left text-white">เลขประจำตัว</th>
                        <th className="text-left text-white">สัญชาติ</th>
                        <th className="text-left text-white">เพศ</th>
                        <th className="text-left text-white">อายุ</th>
                        <th className="text-left text-white">ตำแหน่งงาน</th>
                        <th className="text-left text-white">เลข WP</th>
                        <th className="text-center text-white update[Employee]">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.fw_data &&
                        this.state.fw_data
                          .filter((item) => this.state.data_search_2 === "" || JSON.stringify(item).includes(this.state.data_search_2))
                          .map((item, index) => (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-left">
                                {item.first_name_th ? item.first_name_th : item.first_name_en ? item.first_name_en : ""} {item.middle_name_th ? item.middle_name_th : item.middle_name_en ? item.middle_name_en : ""} {item.last_name_th ? item.last_name_th : item.last_name_en ? item.last_name_en : ""}
                              </td>
                              <td className="text-left">{item.fw_number ? item.fw_number : "-"}</td>
                              <td className="text-left">
                                {item.nationality === 1 ? "เมียนมา" : ""}
                                {item.nationality === 2 ? "ลาว" : ""}
                                {item.nationality === 3 ? "กัมพูชา" : ""}
                                {item.nationality === 4 ? "เวียดนาม" : ""}
                              </td>
                              <td className="text-left">{item.gender ? item.gender : "-"}</td>
                              <td className="text-left">{item.age ? item.age : "-"}</td>
                              <td className="text-left"></td>
                              <td className="text-left">{item.wp_number ? item.wp_number : "-"}</td>
                              <td className="text-center update[Employee]">
                                <div className="w-100 d-flex justify-content-center">
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      if (this.props.location.query.receive_id) {
                                        window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive&receive_ids=" + this.props.location.query.receive_id + "&receive_codes=" + this.props.location.query.receive_codes+ "&menu_id=" + this.props.location.query.menu_id+ "&menu_name=" + this.props.location.query.menu_name;
                                      } else {
                                        window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive&ids=" + this.props.location.query.id;
                                      }
                                    }}
                                  >
                                    <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                                  </div>
                                  <div
                                    className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-100"
                                    onClick={() => {
                                      this.setState({ modal_update_wp: true, wp_number: item.wp_number, fw_id: item.fw_id });
                                    }}
                                  >
                                    แก้ไขเลข WP
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-3">
              {/* หัวข้อ และ ปุ่มเพิ่ม */}
              <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
                <h3 className="mb-3">
                  <b>{this.props.location.query.id ? "แก้ไขนายจ้าง" : "เพิ่มนายจ้าง"}</b>
                </h3>
                {/* {this.props.location.query.id &&(
                <button
                              className="btn btn-danger px-4 mx-1"
                              onClick={() => {
                                window.location.href = "/RecieveAll/create?emp_number=" + this.state.emp_number;
                              }}
                            >
                              ออกใบรับเรื่อง
                            </button>
                            )} */}
              </div>
              <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
                <div className="p-3">
                  <div className="w-100">
                    <h5 className="mb-3">
                      <b>ค้นหาจากกรมพัฒนาธุรกิจการค้า</b>
                    </h5>
                    <hr />
                    <div className="w-100 card border-0 p-0">
                      <div className="d-flex align-items-center mx-0">
                        <span className="me-2">เลขทะเบียนนิติบุคคล</span>
                        <input
                          className="form-control wpx-250 bg-contain border-0 mx-1"
                          type="text"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ data_search: e.target.value });
                          }}
                          value={this.state.data_search}
                        />
                        <button
                          className="btn btn-primary mx-1 px-4"
                          onClick={() => {
                            this.GetUser();
                          }}
                        >
                          ค้นหา
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภท{required}</span>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 1, data_deputize: [], data_coordinator: [], register_date: "", register_capital: 0 });
                          }}
                          checked={this.state.emp_type === 1}
                        />
                        <span className="ms-2">บุคคลธรรมดา</span>
                      </div>
                      <div className="d-flex align-items-center wpx-120">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 2, copy_idcard_image: "",copy_house_registration:"",
                              use_location_document:"", copy_idcard_type: "", data_coordinator: [], prefix_th: "", prefix_en: "" });
                          }}
                          checked={this.state.emp_type === 2}
                        />
                        <span className="ms-2">นิติบุคคล</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 3, copy_idcard_image: "",copy_house_registration:"",
                              use_location_document:"", copy_idcard_type: "", data_coordinator: [], prefix_th: "", prefix_en: "" });
                          }}
                          checked={this.state.emp_type === 3}
                        />
                        <span className="ms-2">นิติบุคคลต่างด้าว</span>
                      </div>
                    </div>
                    {this.state.emp_type === 1 && (
                      <div className="d-flex align-items-center">
                        <span className="wpx-150">เลขบัตรประชาชน{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขบัตรประชาชน"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div>
                    )}
                    {this.state.emp_type === 2 && (
                      <div className="d-flex align-items-center">
                        <span className="wpx-150">เลขนิติบุคคล{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขนิติบุคคล"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div>
                    )}
                    {this.state.emp_type === 3 && (
                      <div className="d-flex align-items-center">
                        <span className="wpx-150">เลขนิติบุคคลต่างด้าว{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขนิติบุคคลต่างด้าว"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div>
                    )}
                    <hr />
                    {/* ข้อมูล */}
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-1">
                        <b>ข้อมูล</b>
                      </h5>
                      <span className="text-danger">
                        <b>ข้อมูลสำคัญ : </b>ประเภทกิจการที่ท่านเลือก ต้องสอดคล้องกับตำแหน่งงานของลูกจ้าง ท่านสามารถดูตารางเทียบตำแหน่งได้โดย{" "}
                        <a href="#" className="text-decoration-none">
                          คลิกที่นี่
                        </a>
                      </span>
                    </div>
                    <div className="row mt-3">
                      {this.state.emp_type === 1 && (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">คำนำหน้า(ไทย){required}</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_prefix_th}
                            onChange={(e) => {
                              this.setState({ prefix_th: e, prefix_en: e ? this.state.data_prefix_en.filter((i) => i.value === e.value)[0] : "" });
                            }}
                            value={this.state.prefix_th}
                          ></Select>
                        </div>
                      )}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อนายจ้าง/สถานประกอบการ{required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ full_name_th: e.target.value });
                          }}
                          value={this.state.full_name_th}
                        />
                      </div>
                      {this.state.emp_type === 1 && (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">คำนำหน้า(English)</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_prefix_en}
                            onChange={(e) => {
                              this.setState({ prefix_en: e, prefix_th: e ? this.state.data_prefix_th.filter((i) => i.value === e.value)[0] : "" });
                            }}
                            value={this.state.prefix_en}
                          ></Select>
                        </div>
                      )}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อนายจ้าง/สถานประกอบการ EN</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ full_name_en: e.target.value });
                          }}
                          value={this.state.full_name_en}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ประเภทกิจการ</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_business_type}
                          onChange={(e) => {
                            this.setState({ business_type: e });
                          }}
                          value={this.state.business_type}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ประเภทงาน{required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_work_type}
                          onChange={(e) => {
                            this.setState({ work_type: e });
                          }}
                          value={this.state.work_type}
                        ></Select>
                      </div>
                      {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ลักษณะงาน{required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ business_description: e.target.value });
                          }}
                          value={this.state.business_description}
                        />
                      </div> */}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ address_number: e.target.value });
                          }}
                          value={this.state.address_number}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมู่ที่</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ moo: e.target.value });
                          }}
                          value={this.state.moo}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ซอย</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ soi: e.target.value });
                          }}
                          value={this.state.soi}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ถนน</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ street: e.target.value });
                          }}
                          value={this.state.street}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จังหวัด{required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={province.map((item) => {
                            return { value: item.province_name, label: item.province_name };
                          })}
                          onChange={(e) => {
                            this.setState({ province: e, district: null, sub_district: null, zipcode: "" });
                          }}
                          value={this.state.province}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150"> อำเภอ / เขต{required}</span>
                        <Select
                          className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                          styles={select_style}
                          options={district
                            .filter((item) => this.state.province && this.state.province.value === item.province_name)
                            .map((item) => {
                              return { value: item.district_name, label: item.district_name };
                            })}
                          onChange={(e) => {
                            this.setState({ district: e, sub_district: null, zipcode: "" });
                          }}
                          value={this.state.district}
                          isDisabled={!this.state.province}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ตำบล / แขวง{required}</span>
                        <Select
                          className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                          styles={select_style}
                          options={subdistrict
                            .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&&this.state.province.value === item.province_name)
                            .map((item) => {
                              return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
                            })}
                          onChange={(e) => {
                            this.setState({ sub_district: e, zipcode: e.zipcode.toString() });
                          }}
                          value={this.state.sub_district}
                          isDisabled={!this.state.district}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="รหัสไปรษณีย์"
                          onChange={(e) => {
                            this.setState({ zipcode: e.target.value });
                          }}
                          value={this.state.zipcode}
                          disabled
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">โทรศัพท์ที่ติดต่อได้</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          maxLength={10}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ phone: e.target.value });
                          }}
                          value={this.state.phone}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรสาร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ fax: e.target.value });
                          }}
                          value={this.state.fax}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรศัพท์มือถือ</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ mobile_phone: e.target.value });
                          }}
                          value={this.state.mobile_phone}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">อีเมล</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          value={this.state.email}
                        />
                      </div>

                      {/* {this.state.emp_type === 1 && ( */}
                        <div className="col-12 col-md-12 d-flex mb-3">
                          <span className="wpx-150">สำเนาทะเบียนบ้าน</span>
                          <div className="d-flex">
                            {!this.state.copy_house_registration ? (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  document.getElementById("file_copy_house_registration").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.copy_house_registration.includes("://") ? this.state.copy_house_registration :  "data:application/pdf;base64,"+this.state.copy_house_registration} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, attachment_file: this.state.copy_house_registration });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "copy_house_registration" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file_copy_house_registration"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "copy_house_registration");
                              }}
                            />
                          </div>
                        </div>
                      {/* )} */}
                      {/* {this.state.emp_type === 1 && ( */}
                        <div className="col-12 col-md-12 d-flex mb-3">
                          <span className="wpx-150">แบบฟอร์มให้ใช้สถานที่</span>
                          <div className="d-flex">
                            {!this.state.use_location_document ? (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  document.getElementById("file_use_location_document").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.use_location_document.includes("://") ? this.state.use_location_document :  "data:application/pdf;base64,"+this.state.use_location_document} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, attachment_file: this.state.use_location_document });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "use_location_document" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file_use_location_document"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "use_location_document");
                              }}
                            />
                          </div>
                        </div>
                      {/* )} */}
                      {/* {this.state.emp_type === 1 && ( */}
                        <div className="col-12 col-md-12 d-flex mb-3">
                          <span className="wpx-150">สำเนาบัตรประชาชน</span>
                          <div className="d-flex">
                            {!this.state.copy_idcard_image ? (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  document.getElementById("file_copy_idcard_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.copy_idcard_image.includes("://") ? this.state.copy_idcard_image :  "data:application/pdf;base64,"+this.state.copy_idcard_image} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, attachment_file: this.state.copy_idcard_image });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "copy_idcard_image" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              id="file_copy_idcard_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "copy_idcard_image");
                              }}
                            />
                          </div>
                        </div>
                      {/* )} */}
                    </div>
                    {(this.state.emp_type === 2 || this.state.emp_type === 3) && (
                      <div className="row">
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">จดทะเบียนเมื่อ{required}</span>
                          <DatePicker
                            type="th"
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ register_date: e });
                            }}
                            value={this.state.register_date}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ทุนจดทะเบียน(บาท)</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              if (!float(e.target.value)) {
                                return
                              }
                              this.setState({ register_capital: e.target.value });
                            }}
                            value={this.state.register_capital}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">บัตรประชาชนเลขที่</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          maxLength={13}
                          onChange={(e) => {
                            this.setState({ id_card: e.target.value });
                          }}
                          value={this.state.id_card}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">สถานที่ออกบัตร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ place_issue: e.target.value });
                          }}
                          value={this.state.place_issue}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันที่ออกบัตร</span>
                        <DatePicker
                          className="form-control mt-1"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(e) => {
                            this.setState({ issue_date: e });
                          }}
                          value={this.state.issue_date}
                          max={new Date()}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันที่หมดอายุ</span>
                        <DatePicker
                          className="form-control mt-1"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(e) => {
                            this.setState({ expiry_date: e });
                          }}
                          value={this.state.expiry_date}
                          min={new Date()}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชั่วโมง/วันการทำงาน</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ hours_working_day: e.target.value });
                          }}
                          value={this.state.hours_working_day}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จำนวนวันหยุด</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ number_holidays: e.target.value });
                          }}
                          value={this.state.number_holidays}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ใน 1 สัปดาห์ทำงาน(วัน)</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ working_day: e.target.value });
                          }}
                          value={this.state.working_day}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จำนวนวันหยุดประจำสัปดาห์(วัน)</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ number_holidays_day: e.target.value });
                          }}
                          value={this.state.number_holidays_day}
                        />
                      </div>

                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันหยุดพักผ่อนประจำปี</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ take_annual_leave: e.target.value });
                          }}
                          value={this.state.take_annual_leave}
                        />
                      </div>
                    </div>
                    <hr />
                    <h5 className="mb-1">
                      <b>ข้อมูลผู้ติดต่อ</b>
                    </h5>
                    <div className="row mb-3">
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อผู้ติดต่อ</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ contact_name: e.target.value });
                          }}
                          value={this.state.contact_name}
                        />
                      </div>
                      {/* {this.state.emp_type !== 2 &&(
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลขบ้าน ๑๑ หลัก</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ home_number: e.target.value });
                          }}
                          value={this.state.home_number}
                        />
                      </div>
                      )} */}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรศัพท์</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ phone_2: e.target.value });
                          }}
                          value={this.state.phone_2}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรสาร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ fax_2: e.target.value });
                          }}
                          value={this.state.fax_2}
                        />
                      </div>
                      {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ผู้มีอำนาจผูกพัน</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                                this.setState({ binding_authority_th: e.target.value })
                            }}
                            value={this.state.binding_authority_th}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ตำแหน่ง</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                                this.setState({ position_th: e.target.value })
                            }}
                            value={this.state.position_th}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ผู้มีอำนาจผูกพัน(English)</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                                this.setState({ binding_authority_en: e.target.value })
                            }}
                            value={this.state.binding_authority_en}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ตำแหน่ง(English)</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                                this.setState({ position_en: e.target.value })
                            }}
                            value={this.state.position_en}
                          />
                      </div> */}
                    </div>
                    <hr />
                    <h5 className="mb-1">
                      <b>กำหนดข้อมูลสัญญาจ้าง</b>
                    </h5>
                    <div className="row mb-3">
                      <div className="col-12 d-flex align-items-center mb-3">
                        <span className="wpx-150">เหตุผลไม่จ้างคนไทย</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ reason: e.target.value });
                          }}
                          value={this.state.reason}
                        />
                      </div>
                      {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ระยะเวลาการจ้าง</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            if(!float(e.target.value)){
                              return
                            }
                            this.setState({ emp_period_y: e.target.value });
                          }}
                          value={this.state.emp_period_y}
                          placeholder="ปี"
                        />
                         <span className="mx-2">ปี</span>
                         <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            if(!float(e.target.value)){
                              return
                            }
                            if(Number(e.target.value)>12){
                              e.target.value = 12
                            }
                            this.setState({ emp_period_m: e.target.value });
                          }}
                          value={this.state.emp_period_m}
                          placeholder="เดือน"
                        />
                        <span className="mx-2">เดือน</span>
                         <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            if(!float(e.target.value)){
                              return
                            }
                            if(Number(e.target.value)>31){
                              e.target.value = 31
                            }
                            this.setState({ emp_period_d: e.target.value });
                          }}
                          value={this.state.emp_period_d}
                          placeholder="วัน"
                        />
                        <span className="mx-2">วัน</span>
                      </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">สัญญาจ้างถึงวันที่</span>
                        <DatePicker
                          className="form-control mt-1"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(e) => {
                            this.setState({ contract_until_date: e });
                          }}
                          value={this.state.contract_until_date}
                        />
                      </div>*/}
                    </div>
                    {/* กรรมการผู้มีอำนาจ */}
                    {this.state.emp_type !== 1 && (
                      <>
                        <hr />
                        <div className="d-flex justify-content-between mb-3">
                          <h5 className="mb-3">
                            <b>กรรมการผู้มีอำนาจ</b>
                          </h5>
                          {this.state.data_deputize.length < 3 && (
                            <button
                              className="btn btn-primary px-4"
                              onClick={() => {
                                let data_deputize = this.state.data_deputize;
                                data_deputize.push({
                                  deputize_id: "",
                                  operator_type: 2,
                                  deputize_number: "",
                                  deputize_full_name: "",
                                  deputize_full_name_en: "",
                                  deputize_phone: "",
                                  deputize_position: "",
                                  deputize_position_en: "",
                                  deputize_id_card: "",
                                  copy_company_certificate: "",
                                  copy_company_certificate_date: "",
                                  copy_power_attorney: "",
                                  copy_power_attorney_date: "",
                                  copy_tax_certificate: "",
                                  copy_tax_certificate_date: "",
                                });
                                this.setState({ data_deputize: data_deputize });
                              }}
                            >
                              + เพิ่มกรรมการผู้มีอำนาจ
                            </button>
                          )}
                        </div>
                        <div className="card bg-contain p-3 pb-0">
                          {this.state.data_deputize.map((item, index) => (
                            <div className="card p-3 mb-3 position-relative">
                              <div
                                className="wpx-30 hpx-30 rounded-circle d-flex align-items-center justify-content-center icon text-white pointer bg-danger position-absolute"
                                style={{ top: -8, right: -8 }}
                                onClick={() => {
                                  let data_deputize = this.state.data_deputize;
                                  data_deputize.splice(index, 1);
                                  this.setState({ data_deputize: data_deputize });
                                }}
                              >
                                {"\uf00d"}
                              </div>
                              <div className="row">
                                <div className="col-12 col-lg-2 mb-3 px-1">
                                  <span>เลือกกรรมการ</span>
                                  <br />
                                  <Switch
                                    onChange={(e) => {
                                      let data_deputize = this.state.data_deputize;
                                      // for(let d of data_deputize){
                                      //   d.deputize_selected = false;
                                      // }
                                      data_deputize[index].deputize_selected = e;
                                      this.setState({ data_deputize: data_deputize });
                                    }}
                                    checked={item.deputize_selected}
                                  />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mb-3">
                                  <span>ชื่อ - นามสกุล ไทย{item.operator_type === 2 ? required : null}</span>
                                  <br />
                                  <input
                                    className="form-control mt-1"
                                    type="text"
                                    onChange={(e) => {
                                      let data_deputize = this.state.data_deputize;
                                      data_deputize[index].deputize_full_name = e.target.value;
                                      this.setState({ data_deputize: data_deputize });
                                    }}
                                    value={item.deputize_full_name}
                                  />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mb-3">
                                  <span>ชื่อ - นามสกุล English</span>
                                  <br />
                                  <input
                                    className="form-control mt-1"
                                    type="text"
                                    onChange={(e) => {
                                      let data_deputize = this.state.data_deputize;
                                      data_deputize[index].deputize_full_name_en = e.target.value;
                                      this.setState({ data_deputize: data_deputize });
                                    }}
                                    value={item.deputize_full_name_en}
                                  />
                                </div>
                                <div className="col-12 col-lg-2"></div>
                                <div className="col-12 col-md-6 col-lg-5 mb-3">
                                  <span>ตำแหน่ง ไทย{required}</span>
                                  <br />
                                  <input
                                    className="form-control mt-1"
                                    type="text"
                                    onChange={(e) => {
                                      let data_deputize = this.state.data_deputize;
                                      data_deputize[index].deputize_position = e.target.value;
                                      this.setState({ data_deputize: data_deputize });
                                    }}
                                    value={item.deputize_position}
                                  />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mb-3">
                                  <span>ตำแหน่ง EN</span>
                                  <br />
                                  <input
                                    className="form-control mt-1"
                                    type="text"
                                    onChange={(e) => {
                                      let data_deputize = this.state.data_deputize;
                                      data_deputize[index].deputize_position_en = e.target.value;
                                      this.setState({ data_deputize: data_deputize });
                                    }}
                                    value={item.deputize_position_en}
                                  />
                                </div>
                                <div className="col-12 col-lg-2"></div>
                                <div className="col-12 col-md-6 col-lg-5 mb-3">
                                  <span>เลข 13 หลัก{required}</span>
                                  <br />
                                  <input
                                    className="form-control mt-1"
                                    type="text"
                                    maxLength={13}
                                    onChange={(e) => {
                                      let data_deputize = this.state.data_deputize;
                                      data_deputize[index].deputize_id_card = e.target.value;
                                      this.setState({ data_deputize: data_deputize });
                                    }}
                                    value={item.deputize_id_card}
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <span>
                                  <span className="wpx-150">แนบไฟล์เอกสาร</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)
                                </span>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-borderless table-striped">
                                  <thead className="bg-primary">
                                    <tr>
                                      <th className="text-center text-white">ลำดับ</th>
                                      <th className="text-left text-white">รายการ</th>
                                      <th className="text-left text-white">วันที่แนบเอกสารล่าสุด</th>
                                      <th className="text-center text-white">ดำเนินการ</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="text-center">1</td>
                                      <td className="text-left">หนังสือรับรองบริษัท+สำเนาบัตรประชาชนกรรมการ{required}</td>
                                      <td className="text-left">{item.copy_power_attorney_date ? format_date(item.copy_power_attorney_date, "dd mmm yyyy") + " เวลา " + format_date(item.copy_power_attorney_date, "hh:mm") + " น." : ""}</td>
                                      <td className="text-center">
                                        {!item.copy_power_attorney ? (
                                          <button
                                            className="btn btn-primary mx-1 px-4"
                                            onClick={() => {
                                              this.setState({ index: index });
                                              document.getElementById("file_copy_power_attorney_array").click();
                                            }}
                                          >
                                            แนบไฟล์
                                          </button>
                                        ) : (
                                          <div className="w-100 d-flex justify-content-center">
                                            <iframe src={item.copy_power_attorney.includes("://") ? item.copy_power_attorney :  "data:application/pdf;base64,"+item.copy_power_attorney} width="150" height="150"></iframe>
                                            <div
                                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                              onClick={() => {
                                                this.setState({ modal_detail: true, attachment_file: this.state.copy_power_attorney });
                                              }}
                                            >
                                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                            </div>
                                            <div
                                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                              onClick={() => {
                                                this.setState({ modal_delete: true, index: index, attachment_name: "copy_power_attorney" });
                                              }}
                                            >
                                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                            </div>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                          <input
                            className="d-none"
                            type="file"
                            id="file_copy_company_certificate_array"
                            accept="application/pdf,image/*"
                            onChange={(e) => {
                              this.import_file(e, "copy_company_certificate_array");
                            }}
                          />
                          <input
                            className="d-none"
                            type="file"
                            id="file_copy_power_attorney_array"
                            accept="application/pdf,image/*"
                            onChange={(e) => {
                              this.import_file(e, "copy_power_attorney_array");
                            }}
                          />
                          <input
                            className="d-none"
                            type="file"
                            id="file_copy_tax_certificate_array"
                            accept="application/pdf,image/*"
                            onChange={(e) => {
                              this.import_file(e, "copy_tax_certificate_array");
                            }}
                          />
                        </div>
                      </>
                    )}
                    <hr />
                    {/* สถานที่ทำงาน */}
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-3">
                        <b>สถานที่ทำงาน</b>
                      </h5>
                      <button
                        className="btn btn-primary px-4"
                        onClick={() => {
                          this.setState({
                            work_emp_id: "",
                            work_address_number: "",
                            work_home_number: "",
                            work_moo: "",
                            work_soi: "",
                            work_street: "",
                            work_province: "",
                            work_district: "",
                            work_sub_district: "",
                            work_zipcode: "",
                            work_phone_create: "",
                            work_email_create: "",
                            modal_workplace_create: true,
                          });
                        }}
                      >
                        + เพิ่มสถานที่ทำงาน
                      </button>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">รายการ</th>
                            <th className="text-left text-white">อีเมล</th>
                            <th className="text-left text-white">เบอร์โทร</th>
                            <th className="text-center text-white">ดำเนินการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data_workplace.map((item, index) => (
                            <tr>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-left">
                                {item.address_number}
                                {item.moo ? " หมู่ " + item.moo : ""}
                                {item.soi ? " ซอย " + item.soi : ""}
                                {item.street ? " ถนน " + item.street : ""}
                                {item.sub_district ? " ตำบล" + item.sub_district : ""}
                                {item.district ? " อำเภอ" + item.district : ""}
                                {item.province ? " จังหวัด" + item.province : ""}
                                {item.zipcode ? " รหัสไปรษณีย์ " + item.zipcode : ""}
                              </td>
                              <td className="text-left">{item.email ? item.email : "-"}</td>
                              <td className="text-left">{item.phone ? item.phone : "-"}</td>
                              <td className="text-center">
                                <div className="w-100 d-flex justify-content-center">
                                  <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center" onClick={() => {}}>
                                    <label
                                      className="pointer icon text-primary p-2"
                                      onClick={() => {
                                        this.setState({
                                          index: index,
                                          work_emp_id: item.emp_id,
                                          work_address_number: item.address_number,
                                          work_home_number: item.home_number,
                                          work_moo: item.moo,
                                          work_soi: item.soi,
                                          work_street: item.street,
                                          work_province: province
                                            .map((e) => {
                                              return { value: e.province_name, label: e.province_name };
                                            })
                                            .filter((e) => e.value === item.province)[0],
                                          work_district: district
                                            .map((e) => {
                                              return { value: e.district_name, label: e.district_name };
                                            })
                                            .filter((e) => e.value === item.district)[0],
                                          work_sub_district: subdistrict
                                            .map((e) => {
                                              return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                            })
                                            .filter((e) => e.value === item.sub_district)[0],
                                          work_zipcode: item.zipcode,
                                          work_phone_create: item.phone,
                                          work_email_create: item.email,
                                          modal_workplace_update: true,
                                        });
                                      }}
                                    >
                                      {"\uf044"}
                                    </label>
                                  </div>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      this.setState({
                                        index: index,
                                        work_emp_id: item.emp_id,
                                        work_address_number: item.address_number,
                                        work_home_number: item.home_number,
                                        work_moo: item.moo,
                                        work_soi: item.soi,
                                        work_street: item.street,
                                        work_province: province
                                          .map((e) => {
                                            return { value: e.province_name, label: e.province_name };
                                          })
                                          .filter((e) => e.value === item.province)[0],
                                        work_district: district
                                          .map((e) => {
                                            return { value: e.district_name, label: e.district_name };
                                          })
                                          .filter((e) => e.value === item.district)[0],
                                        work_sub_district: subdistrict
                                          .map((e) => {
                                            return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                          })
                                          .filter((e) => e.value === item.sub_district)[0],
                                        work_zipcode: item.zipcode,
                                        work_phone: item.phone,
                                        work_email: item.email,
                                        modal_workplace_delete: true,
                                      });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    {/* ผู้ประสานงาน */}
                    {this.state.emp_type === 1 && (
                      <div>
                        <div className="d-flex justify-content-between mb-3">
                          <h5 className="mb-3">
                            <b>ผู้ประสานงาน</b>
                          </h5>
                          <button
                            className="btn btn-primary px-4"
                            onClick={() => {
                              this.setState({
                                coordinator_name: "",
                                coordinator_phone: "",
                                coordinator_email: "",
                                modal_coordinator_create: true,
                              });
                            }}
                          >
                            + เพิ่มผู้ประสานงาน
                          </button>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white">ลำดับ</th>
                                <th className="text-left text-white">ชื่อ - นามสกุล</th>
                                <th className="text-left text-white">เบอร์โทร</th>
                                <th className="text-left text-white">อีเมล</th>
                                <th className="text-center text-white">ดำเนินการ</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data_coordinator.map((item, index) => (
                                <tr>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-left">{item.coordinator_name ? item.coordinator_name : "-"}</td>
                                  <td className="text-left">{item.coordinator_phone ? item.coordinator_phone : "-"}</td>
                                  <td className="text-left">{item.coordinator_email ? item.coordinator_email : "-"}</td>
                                  <td className="text-center">
                                    <div className="w-100 d-flex justify-content-center">
                                      <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center" onClick={() => {}}>
                                        <label
                                          className="pointer icon text-primary p-2"
                                          onClick={() => {
                                            this.setState({
                                              modal_coordinator_update: true,
                                              index: index,
                                              coordinator_name: item.coordinator_name,
                                              coordinator_phone: item.coordinator_phone,
                                              coordinator_email: item.coordinator_email,
                                            });
                                          }}
                                        >
                                          {"\uf044"}
                                        </label>
                                      </div>
                                      <div
                                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                        onClick={() => {
                                          this.setState({
                                            modal_coordinator_delete: true,
                                            index: index,
                                            coordinator_name: item.coordinator_name,
                                            coordinator_phone: item.coordinator_phone,
                                            coordinator_email: item.coordinator_email,
                                          });
                                        }}
                                      >
                                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                    <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                      <div className="d-flex mt-5">
                        <div className="w-50 pe-1">
                          <button
                            type="button"
                            className="btn btn-outline-primary w-100 mb-3"
                            onClick={() => {
                              window.location.href = "/Employer/list";
                            }}
                          >
                            ยกเลิก
                          </button>
                        </div>
                        <div className="w-50 ps-1">
                          <button
                            type="button"
                            className="btn btn-primary w-100 mb-3"
                            onClick={() => {
                              if (this.state.emp_type === 0) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภท");
                                return;
                              } else if (this.state.emp_number.trim() === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอก" + (this.state.emp_type === 1 ? "รหัสบัตรประชาชน" : this.state.emp_type === 2 ? "เลขนิติบุคคล" : this.state.emp_type === 3 ? "เลขที่หนังสือเดินทาง" : ""));
                                return;
                              } else if (this.state.prefix_th === "" && this.state.emp_type === 1) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า");
                                return;
                              } else if (this.state.full_name_th === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อนายจ้าง/สถานประกอบการ");
                                return;
                              } else if (!this.state.work_type) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทงาน");
                                return;
                              // } else if (!this.state.business_description) {
                              //   alert("warning", "แจ้งเตือน", "กรุณากรอกลักษณะงาน");
                              //   return;
                              } else if (this.state.address_number === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                                return;
                              } else if (!this.state.province) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                                return;
                              } else if (!this.state.district) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                                return;
                              } else if (!this.state.sub_district) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                                return;
                              } else if (this.state.zipcode === "") {
                                alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                                return;
                              } else if (this.state.register_date === "" && this.state.emp_type !== 1) {
                                alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่จดทะเบียน");
                                return;
                              }
                              if (this.state.data_deputize.length !== 0) {
                                if (this.state.data_deputize.filter((e) => !e.deputize_full_name || !e.deputize_position).length !== 0) {
                                  alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อและตำแหน่งของกรรมการผู้มีอำนาจ ให้ครบ");
                                  return;
                                }
                                if (this.state.data_deputize.filter((e) => e.deputize_selected).length === 0) {
                                  alert("warning", "แจ้งเตือน", "กรุณาเลือกกรรมการอย่างน้อย 1 ท่าน");
                                  return;
                                }
                              }
                              if (this.props.location.query.id) {
                                this.Update();
                              } else {
                                this.Create();
                              }
                            }}
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* ดูข้อมูลเอกสาร */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="xl"
        >
          <Modal.Body className="p-2">
            <iframe src={this.state.attachment_file.includes("://") ? this.state.attachment_file :  "data:application/pdf;base64,"+this.state.attachment_file} width="100%" height={window.innerHeight - 70 + "px"}></iframe>
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_detail: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* เพิ่มสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_workplace_create || this.state.modal_workplace_update}
          onHide={() => {
            this.setState({ modal_workplace_create: false, modal_workplace_update: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มสถานที่ทำงานใหม่</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-1">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ work_address_number: e.target.value });
                  }}
                  value={this.state.work_address_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขบ้าน ๑๑ หลัก</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={11}
                  onChange={(e) => {
                    this.setState({ work_home_number: e.target.value });
                  }}
                  value={this.state.work_home_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">หมู่ที่</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ work_moo: e.target.value });
                  }}
                  value={this.state.work_moo}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ซอย</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ work_soi: e.target.value });
                  }}
                  value={this.state.work_soi}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ถนน</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ work_street: e.target.value });
                  }}
                  value={this.state.work_street}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">จังหวัด{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={province.map((item) => {
                    return { value: item.province_name, label: item.province_name };
                  })}
                  onChange={(e) => {
                    this.setState({ work_province: e, work_district: null, work_sub_district: null, work_zip_code: "" });
                  }}
                  value={this.state.work_province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150"> อำเภอ / เขต{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.work_province ? "" : " bg-contain")}
                  styles={select_style}
                  options={district
                    .filter((item) => this.state.work_province && this.state.work_province.value === item.province_name)
                    .map((item) => {
                      return { value: item.district_name, label: item.district_name };
                    })}
                  onChange={(e) => {
                    this.setState({ work_district: e, work_sub_district: null, work_zip_code: "" });
                  }}
                  value={this.state.work_district}
                  isDisabled={!this.state.work_province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ตำบล / แขวง{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.work_district ? "" : " bg-contain")}
                  styles={select_style}
                  options={subdistrict
                    .filter((item) => this.state.work_district && this.state.work_district.value === item.district_name && this.state.work_province&& this.state.work_province.value === item.province_name)
                    .map((item) => {
                      return { value: item.sub_district_name, label: item.sub_district_name, zip_code: item.zip_code };
                    })}
                  onChange={(e) => {
                    this.setState({ work_sub_district: e, work_zipcode: e.zip_code.toString() });
                  }}
                  value={this.state.work_sub_district}
                  isDisabled={!this.state.work_district}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => {
                    this.setState({ work_zipcode: e.target.value });
                  }}
                  value={this.state.work_zipcode}
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">โทรศัพท์</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ work_phone_create: e.target.value });
                  }}
                  value={this.state.work_phone_create}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ work_email_create: e.target.value });
                  }}
                  value={this.state.work_email_create}
                />
              </div>
            </div>
            {/* <hr />
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <span><span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)</span>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table-striped">
                                <thead className="bg-primary">
                                    <tr>
                                        <th className="text-center text-white">ลำดับ</th>
                                        <th className="text-center text-white">รายการ</th>
                                        <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                                        <th className="text-center text-white">ดำเนินการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td className="text-start">หลักฐานการเพิ่มสถานที่ทำงานใหม่และแผนที่ เช่น<br />
                                            - สำเนาทะเบียนบ้าน หรือ<br />
                                            - สำเนาเช่าสำนักงาน/ร้านค้า/โรงงาน หรือ<br />
                                            - สัญญาจ้างงานของโครงการก่อสร้าง หรือ<br />
                                            - สำเนาโฉนดที่ดิน {required}</td>
                                        <td className="text-center">{format_date(new Date(), "dd mmm yyyy")} เวลา {format_date(new Date(), "hh:mm")} น.</td>
                                        <td className="text-center">
                                            <button className="btn btn-primary mx-1 px-4" onClick={() => { }}>
                                                แนบไฟล์
                                            </button>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_workplace_detail: true, attachment_id: "", attachment_name: "", attachment_file: "" }) }}>
                                                    <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                </div>
                                                <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_workplace_delete: true, attachment_id: "", attachment_name: "" }) }}>
                                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span className="d-flex align-items-center">หมายเหตุ :
                            <div className="ms-3 me-2 wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-primary p-2">{"\uf15c"}</label>
                            </div>
                            แสดงเอกสารแนบ
                            <div className="ms-5 me-2 wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                            ลบเอกสาร
                        </span> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_workplace_create: false, modal_workplace_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.work_address_number === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                      return;
                    } else if (!this.state.work_province) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                      return;
                    } else if (!this.state.work_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                      return;
                    } else if (!this.state.work_sub_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                      return;
                    } else if (this.state.work_zipcode === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                      return;
                    }
                    let data_workplace = this.state.data_workplace;
                    if (this.state.modal_workplace_create) {
                      data_workplace.push({
                        pw_id: "",
                        emp_id: this.state.work_emp_id,
                        address_number: this.state.work_address_number,
                        home_number: this.state.work_home_number,
                        moo: this.state.work_moo,
                        soi: this.state.work_soi,
                        street: this.state.work_street,
                        province: this.state.work_province ? this.state.work_province.value : "",
                        district: this.state.work_district ? this.state.work_district.value : "",
                        sub_district: this.state.work_sub_district ? this.state.work_sub_district.value : "",
                        zipcode: this.state.work_zipcode,
                        phone: this.state.work_phone_create,
                        email: this.state.work_email_create,
                      });
                    } else {
                      data_workplace[this.state.index].emp_id = this.state.work_emp_id;
                      data_workplace[this.state.index].address_number = this.state.work_address_number;
                      data_workplace[this.state.index].home_number = this.state.work_home_number;
                      data_workplace[this.state.index].moo = this.state.work_moo;
                      data_workplace[this.state.index].soi = this.state.work_soi;
                      data_workplace[this.state.index].street = this.state.work_street;
                      data_workplace[this.state.index].province = this.state.work_province ? this.state.work_province.value : "";
                      data_workplace[this.state.index].district = this.state.work_district ? this.state.work_district.value : "";
                      data_workplace[this.state.index].sub_district = this.state.work_sub_district ? this.state.work_sub_district.value : "";
                      data_workplace[this.state.index].zipcode = this.state.work_zipcode;
                      data_workplace[this.state.index].phone = this.state.work_phone_create;
                      data_workplace[this.state.index].email = this.state.work_email_create;
                    }
                    this.setState({ data_workplace: data_workplace, modal_workplace_create: false, modal_workplace_update: false });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_workplace_delete}
          onHide={() => {
            this.setState({ modal_workplace_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบสถานที่ทำงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.address_number} ” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_workplace = this.state.data_workplace;
                    data_workplace.splice(this.state.index, 1);
                    this.setState({ data_workplace: data_workplace, modal_workplace_delete: false });
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_workplace_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มผู้ประสานงาน */}
        <Modal
          show={this.state.modal_coordinator_create || this.state.modal_coordinator_update}
          onHide={() => {
            this.setState({ modal_coordinator_create: false, modal_coordinator_update: false });
          }}
          size="md"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มผู้ประสานงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ coordinator_name: e.target.value });
                  }}
                  value={this.state.coordinator_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ coordinator_phone: e.target.value });
                  }}
                  value={this.state.coordinator_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล{required}</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ coordinator_email: e.target.value });
                  }}
                  value={this.state.coordinator_email}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_coordinator_create: false, modal_coordinator_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.coordinator_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.coordinator_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.coordinator_email === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                      return;
                    }
                    let data_coordinator = this.state.data_coordinator;
                    if (this.state.modal_coordinator_create) {
                      data_coordinator.push({
                        coordinator_id: "",
                        coordinator_name: this.state.coordinator_name,
                        coordinator_phone: this.state.coordinator_phone,
                        coordinator_email: this.state.coordinator_email,
                      });
                    } else {
                      data_coordinator[this.state.index].coordinator_name = this.state.coordinator_name;
                      data_coordinator[this.state.index].coordinator_phone = this.state.coordinator_phone;
                      data_coordinator[this.state.index].coordinator_email = this.state.coordinator_email;
                    }
                    this.setState({ data_coordinator: data_coordinator, modal_coordinator_create: false, modal_coordinator_update: false });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบผู้ประสานงาน */}
        <Modal
          show={this.state.modal_coordinator_delete}
          onHide={() => {
            this.setState({ modal_coordinator_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบผู้ประสานงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.coordinator_name} ” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_coordinator = this.state.data_coordinator;
                    data_coordinator.splice(this.state.index, 1);
                    this.setState({ data_coordinator: data_coordinator, modal_coordinator_delete: false });
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_coordinator_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.attachment_name === "copy_company_certificate" || this.state.attachment_name === "copy_power_attorney" ? "ลบเอกสารแนบ" : ""}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.attachment_name === "copy_company_certificate" ? "สำเนาบัตรประชาชน " : this.state.attachment_name === "copy_power_attorney" ? "สำเนากรรมการผู้มีอำนาจ " : ""}” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_deputize = this.state.data_deputize;
                    if (this.state.attachment_name === "copy_company_certificate") {
                      data_deputize[this.state.index].copy_company_certificate = "";
                      data_deputize[this.state.index].copy_company_certificate_date = "";
                    } else if (this.state.attachment_name === "copy_power_attorney") {
                      data_deputize[this.state.index].copy_power_attorney = "";
                      data_deputize[this.state.index].copy_power_attorney_date = "";
                    } else if (this.state.attachment_name === "copy_tax_certificate") {
                      data_deputize[this.state.index].copy_tax_certificate = "";
                      data_deputize[this.state.index].copy_tax_certificate_date = "";
                    } else if (this.state.attachment_name === "copy_idcard_image") {
                      this.setState({ copy_idcard_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "copy_house_registration") {
                      this.setState({ copy_house_registration: "", modal_delete: false });
                    } else if (this.state.attachment_name === "use_location_document") {
                      this.setState({ use_location_document: "", modal_delete: false });
                    }
                    this.setState({ data_deputize: data_deputize, modal_delete: false });

                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* แก้ไขเลข WP */}
        <Modal
          show={this.state.modal_update_wp}
          onHide={() => {
            this.setState({ modal_update_wp: false });
          }}
        >
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>
              {" "}
              <b>เลข WP</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <span>แก้ไขเลข WP</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={13}
                  onChange={(e) => {
                    this.setState({ wp_number: number(e.target.value) });
                  }}
                  value={this.state.wp_number}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.setState({ modal_update_wp: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.UpdateWP();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        {/* import */}
        <Modal
          show={this.state.modal_import}
          onHide={() => {
            this.setState({ modal_import: false });
          }}
          size="md"
        >
          <Modal.Body className="p-2">
            <div className="bg-light card py-5 border-dashed align-items-center">
              <b className="icon text-primary mt-4" style={{ fontSize: 48 }}>
                {" "}
                {"\uf56f"}{" "}
              </b>
              <b className="text-secondary">เลือกไฟล์ที่นี่</b>
              <button
                className="btn btn-primary wpx-120 mb-4"
                onClick={() => {
                  document.getElementById("file").click();
                }}
              >
                เลือกไฟล์
              </button>
              <input
                className="d-none"
                type="file"
                id="file"
                accept=".xls,.xlsx"
                onChange={(e) => {
                  this.import_excel(e);
                }}
              />
            </div>
            {this.state.data_import && (
              <div className="card border-0 mb-3">
                <div className="p-3">
                  <label>จำนวนที่นำเข้า {this.state.data_import.length} รายการ</label>
                </div>
              </div>
            )}
            <div className="d-none">
              <ReactExcel initialData={this.state.import} onSheetUpdate={(currentSheet) => this.setState({ currentSheet: currentSheet })} activeSheetClassName="d-none" reactExcelClassName="table table-borderless table-striped" />
            </div>
            <div className="w-100 d-flex justify-content-end mt-3">
              <button
                className="btn btn-primary px-4 me-3"
                onClick={() => {
                  this.create_import();
                }}
              >
                นำเข้าข้อมูล
              </button>
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_import: false, import: null, currentSheet: null, over: false, data_import: null });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import Switch from "react-switch";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_update: false,
      modal_delete: false,
      loading: false,
      data: [],

      data_search: "",

      imm_id: "",
      imm_name_th: "",
      imm_name_en: "",
      is_active: false,

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,
    };
  }
  async componentDidMount() {
    await this.Get();
  }

  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      imm_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
      });
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      imm_name_th: this.state.imm_name_th,
      imm_name_en: this.state.imm_name_en,
    };
    let result = await POST(tokens, "api/setting/immigration/create", body);
    if (result && result.status) {
      success_url("/setting/ImmigrationOffice/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      imm_name_th: this.state.imm_name_th,
      imm_name_en: this.state.imm_name_en,
    };
    let result = await PUT(tokens, "api/setting/immigration/update/" + this.state.imm_id, body);
    if (result && result.status) {
      success_url("/setting/ImmigrationOffice/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="จัดการรายการ ตม." />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>จัดการรายการ ตม.</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    this.setState({
                      modal_create: true,
                      imm_id: "",
                      imm_name_th: "",
                      imm_name_en: "",
                      is_active: false,
                    });
                  }}
                >
                  <label className="icon">{"\u002B"}</label> เพิ่ม ตม.
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white wpx-100">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ</th>
                      <th className="text-center text-white update wpx-100">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.imm_name_th ? item.imm_name_th : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.setState({
                                    modal_update: true,
                                    imm_id: item.imm_id,
                                    imm_name_th: item.imm_name_th,
                                  });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    {" "}
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ{" "}
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* create or update */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.modal_create ? "เพิ่มตม." : "แก้ไขตม."}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            {/* ตม. TH */}
            <div className="w-100 d-flex align-items-center mb-3">
              <label className="wpx-140">ชืื่อ ตม.{required}:</label>
              <input
                className="form-control validate"
                type="text"
                placeholder="กรอกข้อมูล"
                onChange={(e) => {
                  this.setState({ imm_name_th: e.target.value });
                }}
                value={this.state.imm_name_th}
              />
            </div>
            {/* ชืื่อ ตม. EN */}
            {/* <div className="w-100 d-flex align-items-center mb-3">
              <label className="wpx-140">ชืื่อ ตม. EN{required}:</label>
              <input
                className="form-control validate"
                type="text"
                placeholder="กรอกข้อมูล"
                onChange={(e) => {
                  this.setState({ imm_name_en: e.target.value });
                }}
                value={this.state.imm_name_en}
              />
            </div> */}
            {/* ปุ่ม */}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.imm_name_th === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ ตม.");
                      return;
                    }
                    if (this.state.modal_create) {
                      this.Create();
                    } else {
                      this.Update();
                    }
                  }}
                >
                  {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import province from "../../../assets/files/province.json";
import district from "../../../assets/files/district.json";
import subdistrict from "../../../assets/files/all_province.json";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      data_rate: [],

      data_search: "",

      quotation_id: "",
      full_name: "",
      address: "",
      phone: "",
      date: "",

      rate_list: [],
    };
  }
  async componentDidMount() {
    await this.GetRateAll();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
    } 
  }
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      quotation_id: id,
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/0", body);
    if (result && result.status) {
      let item = result.data;
      this.setState({
        quotation_id: item.quotation_id,
        full_name: item.full_name,
        address: item.address,
        phone: item.phone,
        date: item.date,

        rate_list: item.rate_list.map((e) => {
          return {
            rate_id: this.state.data_rate.filter((item) => item.value === e.rate_id)[0],
            qty: e.qty,
            remark: e.remark,
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  

  GetRateAll = async () => { 
  let body = {
    data_search: "",
    rate_id: "",
  };
  let result = await GET(tokens, "api/setting/service-rate/list/page/0", body);
  if (result && result.status) {
    this.setState({
      data_rate: result.data.map((e) => {
        return {
          value: e.rate_id,
          label: e.rate_name,
        };
      }),
    });
  } 
}; 
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      full_name: this.state.full_name,
      address: this.state.address,
      phone: this.state.phone,
      date: this.state.date,

      rate_list: this.state.rate_list.map((e) => {
        return {
          rate_id: e.rate_id ? e.rate_id.value : "",
          qty: e.qty,
          remark: e.remark,
        };
      }),
    };
    let result = await POST(tokens, "api/setting/quotation/create", body);
    if (result && result.status) {
      success_url("/setting/Quotation/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      full_name: this.state.full_name,
      address: this.state.address,
      phone: this.state.phone,
      date: this.state.date,

      rate_list: this.state.rate_list.map((e) => {
        return {
          rate_id: e.rate_id ? e.rate_id.value : "",
          qty: e.qty,
          remark: e.remark,
        };
      }),
    };
    let result = await PUT(tokens, "api/setting/quotation/update/" + this.state.quotation_id, body);
    if (result && result.status) {
      success_url("/setting/Quotation/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="จัดการใบเสนอราคา" page3="สร้างใบเสนอราคา" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>สร้างใบเสนอราคา</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>สร้างใบเสนอราคา</b>
                  </h5>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">ชื่อ - สกุล{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ full_name: e.target.value });
                        }}
                        value={this.state.full_name}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">ที่อยู่{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ address: e.target.value });
                        }}
                        value={this.state.address}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">เบอร์โทร{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        maxLength={10}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ phone: e.target.value });
                        }}
                        value={this.state.phone}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-100">วันที่{required}</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ date: e });
                        }}
                        value={this.state.date}
                      />
                    </div>
                  </div>
                  <hr />
                  <h5 className="mb-3">
                    <b>สร้างใบเสนอราคา</b>
                  </h5>
                  <div className="card p-3">
                    {this.state.rate_list.map((item, index) => (
                      <div className="d-flex mb-3">
                        <div className="wpx-30">{index + 1}</div>
                        <div className="mx-1 w-100"> 
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_rate}
                            onChange={(e) => {
                              let rate_list = this.state.rate_list;
                              rate_list[index].rate_id = e;
                              this.setState({ rate_list: rate_list });
                            }}
                            value={item.rate_id}
                          ></Select>
                        </div>
                        <div className="mx-1 w-100">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) => {
                              if (!float(e.target.value)) {
                                return;
                              }
                              let rate_list = this.state.rate_list;
                              rate_list[index].qty = Number(e.target.value);
                              this.setState({ rate_list: rate_list });
                            }}
                            value={item.qty}
                            placeholder="จำนวน"
                          />
                        </div>
                        <div className="mx-1 w-100">
                          <input
                            className="form-control"
                            type="text"
                            onChange={(e) => {
                              let rate_list = this.state.rate_list;
                              rate_list[index].remark = e.target.value;
                              this.setState({ rate_list: rate_list });
                            }}
                            value={item.remark}
                            placeholder="หมายเหตุ"
                          />
                        </div>
                        <div className="wpx-50 d-flex justify-content-center">
                          <div
                            className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              let rate_list = this.state.rate_list;
                              rate_list.splice(index, 1);
                              this.setState({ rate_list: rate_list });
                            }}
                          >
                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                          </div>
                        </div>
                      </div>
                    ))}
                    <hr />
                    <button
                      type="button"
                      className="btn btn-primary mb-3 wpx-140"
                      onClick={() => {
                        let rate_list = this.state.rate_list;
                        rate_list.push({
                          rate_id: "",
                          qty: "",
                          remark: "",
                        });
                        this.setState({ rate_list: rate_list });
                      }}
                    >
                      + เพิ่มค่าบริการ
                    </button>
                  </div>
                </div>

                <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/setting/Quotation/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (!this.state.full_name) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ");
                              return;
                            } else if (!this.state.address) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกที่อยู่");
                              return;
                            } else if (!this.state.phone) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                              return;
                            } else if (!this.state.date) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่");
                              return;
                            } else if (this.state.rate_list.length === 0) {
                              alert("warning", "แจ้งเตือน", "กรุณาเพิ่มค่าบริการอย่างน้อย 1 รายการ");
                              return;
                            }
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

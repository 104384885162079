import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import province from "../../../assets/files/province.json";
import district from "../../../assets/files/district.json";
import subdistrict from "../../../assets/files/all_province.json";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_delete: false,
      tab: 1,
      loading: false,

      data_search: "",

      id:"",
      work_permit_day: "",
      visa_day: "",
      staying_over_day: "",
      passport_day: "",
    };
  }
  async componentDidMount() {
      this.Get();
  }
  Get = async () => {
    this.setState({ loading: true }); 
    let result = await GET(tokens, "api/noti-expire-doc/list", null);
    if (result && result.status) {
      let item = result.data;
      this.setState({
        id: item.id,
        work_permit_day: item.work_permit_day,
        visa_day: item.visa_day,
        staying_over_day: item.staying_over_day,
        passport_day: item.passport_day,
      });
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      work_permit_day: this.state.work_permit_day,
      visa_day: this.state.visa_day,
      staying_over_day: this.state.staying_over_day,
      passport_day: this.state.passport_day,
    };
    let result = await PUT(tokens, "api/noti-expire-doc/update", body);
    if (result && result.status) {
      success_url("/setting/Alert/create");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ตั้งค่า" page3="จัดการ การแจ้งเตือนหมดอายุเอกสาร" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>จัดการ การแจ้งเตือนหมดอายุเอกสาร</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <div className="row mt-3">
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">Work permit{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                    return;
                  }
                          this.setState({ work_permit_day: e.target.value });
                        }}
                        value={this.state.work_permit_day}
                      />
                      <span className="wpx-120 ms-3">วัน ก่อนหมดอายุ</span>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">VISA{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                    return;
                  }
                          this.setState({ visa_day: e.target.value });
                        }}
                        value={this.state.visa_day}
                      />
                      <span className="wpx-120 ms-3">วัน ก่อนหมดอายุ</span>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">แจ้งอยู่เกิน 90 วัน{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                    return;
                  }
                          this.setState({ staying_over_day: e.target.value });
                        }}
                        value={this.state.staying_over_day}
                      />
                      <span className="wpx-120 ms-3">วัน ก่อนหมดอายุ</span>
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">Passport{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                    return;
                  }
                          this.setState({ passport_day: e.target.value });
                        }}
                        value={this.state.passport_day}
                      />
                      <span className="wpx-120 ms-3">วัน ก่อนหมดอายุ</span>
                    </div>
                  </div>
                </div>
                <div className="row update">
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                              this.Update();
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ดูข้อมูล */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>รายละเอียด</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            {/* profile_image */}
            {this.state.attachment_name === "profile_image" && this.state.profile_image.includes("://") && this.state.profile_image_type === "pdf" && <iframe src={this.state.profile_image} width="100%" height="550px"></iframe>}
            {this.state.attachment_name === "profile_image" && this.state.profile_image.includes("://") && this.state.profile_image_type !== "pdf" && <img src={this.state.profile_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "profile_image" && !this.state.profile_image.includes("://") && this.state.profile_image_type === "pdf" && <object data={"data:application/pdf;base64," + this.state.profile_image} type="application/pdf" width="100%" height="550px"></object>}
            {this.state.attachment_name === "profile_image" && !this.state.profile_image.includes("://") && this.state.profile_image_type !== "pdf" && <img src={"data:application/png;base64," + this.state.profile_image} width="100%" height="550px" className="img-resposive" />}
            {/* identity_document */}
            {this.state.attachment_name === "identity_document" && this.state.identity_document.includes("://") && this.state.identity_document_type === "pdf" && <iframe src={this.state.identity_document} width="100%" height="550px"></iframe>}
            {this.state.attachment_name === "identity_document" && this.state.identity_document.includes("://") && this.state.identity_document_type !== "pdf" && <img src={this.state.identity_document} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "identity_document" && !this.state.identity_document.includes("://") && this.state.identity_document_type === "pdf" && <object data={"data:application/pdf;base64," + this.state.identity_document} type="application/pdf" width="100%" height="550px"></object>}
            {this.state.attachment_name === "identity_document" && !this.state.identity_document.includes("://") && this.state.identity_document_type !== "pdf" && <img src={"data:application/png;base64," + this.state.identity_document} width="100%" height="550px" className="img-resposive" />}
            {/* evidence_allowed_document */}
            {this.state.attachment_name === "evidence_allowed_document" && this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type === "pdf" && <iframe src={this.state.evidence_allowed_document} width="100%" height="550px"></iframe>}
            {this.state.attachment_name === "evidence_allowed_document" && this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type !== "pdf" && <img src={this.state.evidence_allowed_document} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "evidence_allowed_document" && !this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type === "pdf" && <object data={"data:application/pdf;base64," + this.state.evidence_allowed_document} type="application/pdf" width="100%" height="550px"></object>}
            {this.state.attachment_name === "evidence_allowed_document" && !this.state.evidence_allowed_document.includes("://") && this.state.evidence_allowed_document_type !== "pdf" && <img src={"data:application/png;base64," + this.state.evidence_allowed_document} width="100%" height="550px" className="img-resposive" />}

            {this.state.attachment_name === "passport_day_image" && <img src={this.state.passport_day_image.includes("://") ? this.state.passport_day_image : "data:application/png;base64," + this.state.passport_day_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "visa_day_image" && <img src={this.state.visa_day_image.includes("://") ? this.state.visa_day_image : "data:application/png;base64," + this.state.visa_day_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "work_pm_image" && <img src={this.state.work_pm_image.includes("://") ? this.state.work_pm_image : "data:application/png;base64," + this.state.work_pm_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "health_image" && <img src={this.state.health_image.includes("://") ? this.state.health_image : "data:application/png;base64," + this.state.health_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "insurance_image" && <img src={this.state.insurance_image.includes("://") ? this.state.insurance_image : "data:application/png;base64," + this.state.insurance_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "sso_image" && <img src={this.state.sso_image.includes("://") ? this.state.sso_image : "data:application/png;base64," + this.state.sso_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "diagnose_image" && <img src={this.state.diagnose_image.includes("://") ? this.state.diagnose_image : "data:application/png;base64," + this.state.diagnose_image} width="100%" height="550px" className="img-resposive" />}
            {this.state.attachment_name === "policy_image" && <img src={this.state.policy_image.includes("://") ? this.state.policy_image : "data:application/png;base64," + this.state.policy_image} width="100%" height="550px" className="img-resposive" />}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-center">
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_detail: false });
                  }}
                >
                  ปิดหน้าต่าง
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_create}
          onHide={() => {
            this.setState({ modal_create: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มสถานที่ทำงานใหม่</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ staying_over_day_number: e.target.value });
                  }}
                  value={this.state.staying_over_day_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">หมู่ที่</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ moo: e.target.value });
                  }}
                  value={this.state.moo}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ซอย</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ soi: e.target.value });
                  }}
                  value={this.state.soi}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ถนน{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ street: e.target.value });
                  }}
                  value={this.state.street}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">จังหวัด{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={province.map((item) => {
                    return { value: item.province_name, label: item.province_name };
                  })}
                  onChange={(e) => {
                    this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150"> อำเภอ / เขต{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                  styles={select_style}
                  options={district
                    .filter((item) => this.state.province && this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.district_name, label: item.district_name };
                    })}
                  onChange={(e) => {
                    this.setState({ district: e, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.district}
                  isDisabled={!this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ตำบล / แขวง{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                  styles={select_style}
                  options={subdistrict
                    .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&& this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.sub_district_name, label: item.sub_district_name, zip_code: item.zip_code };
                    })}
                  onChange={(e) => {
                    this.setState({ sub_district: e, zipcode: e.zip_code.toString() });
                  }}
                  value={this.state.sub_district}
                  isDisabled={!this.state.district}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => {
                    this.setState({ zipcode: e.target.value });
                  }}
                  value={this.state.zipcode}
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ phone_create: e.target.value });
                  }}
                  value={this.state.phone_create}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ email_create: e.target.value });
                  }}
                  value={this.state.email_create}
                />
              </div>
            </div>
            {/* <hr />
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <span><span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)</span>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-borderless table-striped">
                                <thead className="bg-primary">
                                    <tr>
                                        <th className="text-center text-white">ลำดับ</th>
                                        <th className="text-center text-white">รายการ</th>
                                        <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                                        <th className="text-center text-white">ดำเนินการ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td className="text-start">หลักฐานการเพิ่มสถานที่ทำงานใหม่และแผนที่ เช่น<br />
                                            - สำเนาทะเบียนบ้าน หรือ<br />
                                            - สำเนาเช่าสำนักงาน/ร้านค้า/โรงงาน หรือ<br />
                                            - สัญญาจ้างงานของโครงการก่อสร้าง หรือ<br />
                                            - สำเนาโฉนดที่ดิน {required}</td>
                                        <td className="text-center">{format_date(new Date(), "dd mmm yyyy")} เวลา {format_date(new Date(), "hh:mm")} น.</td>
                                        <td className="text-center">
                                            <button className="btn btn-primary mx-1 px-4" onClick={() => { }}>
                                                แนบไฟล์
                                            </button>
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_detail: true, attachment_id: "", attachment_name: "", attachment_file: "" }) }}>
                                                    <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                                </div>
                                                <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                    onClick={() => { this.setState({ modal_delete: true, attachment_id: "", attachment_name: "" }) }}>
                                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span className="d-flex align-items-center">หมายเหตุ :
                            <div className="ms-3 me-2 wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-primary p-2">{"\uf15c"}</label>
                            </div>
                            แสดงเอกสารแนบ
                            <div className="ms-5 me-2 wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center">
                                <label className="icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                            ลบเอกสาร
                        </span> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.staying_over_day_number === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                      return;
                    } else if (this.state.street === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกถนน");
                      return;
                    } else if (!this.state.province) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                      return;
                    } else if (!this.state.district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                      return;
                    } else if (!this.state.sub_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                      return;
                    } else if (this.state.zipcode === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                      return;
                    } else if (this.state.phone_create === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์");
                      return;
                    } else if (this.state.phone_create.length < 9) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน");
                      return;
                    }
                    this.CreateWorkplace();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มตัวแทน */}
        <Modal
          show={this.state.modal_agent_create}
          onHide={() => {
            this.setState({ modal_agent_create: false });
          }}
          size="md"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มตัวแทน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ agent_name: e.target.value });
                  }}
                  value={this.state.agent_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ agent_phone: e.target.value });
                  }}
                  value={this.state.agent_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล{required}</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ agent_email: e.target.value });
                  }}
                  value={this.state.agent_email}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_agent_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.agent_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.agent_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.agent_email === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกอีเมล");
                      return;
                    }
                    this.CreateAgent();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบเอกสารแนบ</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบเอกสารแนบ “{this.state.attachment_name === "profile_image" ? "รูปถ่ายของลูกจ้าง" : this.state.attachment_name === "identity_document" ? "หลักฐานประจำตัวลูกจ้าง" : this.state.attachment_name === "evidence_allowed_document" ? "สำเนาหลักฐานการอนุญาตให้เข้ามาในราชอาณาจักร ทุกหน้าที่มีตราประทับ" : "รูปภาพ"}” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    if (this.state.attachment_name === "profile_image") {
                      this.setState({ profile_image: "", profile_image_type: "", profile_image_date: "", modal_delete: false });
                    } else if (this.state.attachment_name === "identity_document") {
                      this.setState({ identity_document: "", identity_document_type: "", identity_document_date: "", modal_delete: false });
                    } else if (this.state.attachment_name === "evidence_allowed_document") {
                      this.setState({ evidence_allowed_document: "", evidence_allowed_document_type: "", evidence_allowed_document_date: "", modal_delete: false });
                    } else if (this.state.attachment_name === "passport_day_image") {
                      this.setState({ passport_day_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "visa_day_image") {
                      this.setState({ visa_day_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "work_pm_image") {
                      this.setState({ work_pm_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "health_image") {
                      this.setState({ health_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "insurance_image") {
                      this.setState({ insurance_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "sso_image") {
                      this.setState({ sso_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "diagnose_image") {
                      this.setState({ diagnose_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "policy_image") {
                      this.setState({ policy_image: "", modal_delete: false });
                    }
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_delete: false,
      loading: false,
      data: [],
      data_user: [],

      data_search: "",

      emp_id: "",
      agent_id: "",
      agent_name: "",
      agent_phone: "",
      agent_email: "",
      agent_id_card: "",
      agent_file: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,
    };
  }
  async componentDidMount() {
    await this.Get();
  }

  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      agent_id: "",
    };
    let result = await GET(tokens, "api/agent/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
      });
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/agent/delete/" + this.state.agent_id, null);
    if (result && result.status) {
      success_url("/Agent/list");
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      agent_name: this.state.agent_name,
      agent_phone: this.state.agent_phone,
      agent_email: this.state.agent_email,
      agent_id_card: this.state.agent_id_card,
      agent_file: this.state.agent_file,
    };
    let result = await POST(tokens, "api/agent/create", body);
    if (result && result.status) {
      success_url("/Agent/list");
    }
    this.setState({ loading: false });
  };

  Update = async () => {
    this.setState({ loading: true });
    let body = {
      agent_name: this.state.agent_name,
      agent_phone: this.state.agent_phone,
      agent_email: this.state.agent_email,
      agent_id_card: this.state.agent_id_card,
      agent_file: this.state.agent_file,
    };
    let result = await PUT(tokens, "api/agent/update/" + this.state.agent_id, body);
    if (result && result.status) {
      success_url("/Agent/list");
    }
    this.setState({ loading: false });
  };
  clear_form = () => {
    this.setState({
      modal_delete: false,
      modal_adjust: false,
      modal_tranfer: false,

      loading: false,

      agent_name: "",
      agent_phone: "",
      agent_email: "",
    });
  };

  import_file = async (e) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("pdf") && !file.type.includes("image")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      const result = file.type.includes("pdf") ? e.target.result.split(",")[1] : await convert_imagetopdf(e.target.result);
      this.setState({ agent_file: result });
    };
    document.getElementById("agent_file").value = "";
    document.getElementById("agent_file").file = null;
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        <input
          className="d-none"
          type="file"
          id="agent_file"
          accept="application/pdf,image/*"
          onChange={(e) => {
            this.import_file(e);
          }}
        />
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ทะเบียนตัวแทน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>ทะเบียนตัวแทน</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 create"
                  onClick={() => {
                    this.setState({
                      modal_create: true,
                      agent_id: "",
                      agent_name: "",
                      agent_phone: "",
                      agent_email: "",
                    });
                  }}
                >
                  <label className="icon">{"\u002B"}</label> เพิ่มตัวแทน
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ - นามสกุล</th>
                      <th className="text-left text-white">เบอร์โทร</th>
                      <th className="text-left text-white">อีเมล</th>
                      <th className="text-left text-white">เลขบัตรประชาชน</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.agent_name ? item.agent_name : "-"}</td>
                          <td className="text-left">{item.agent_phone ? item.agent_phone : "-"}</td>
                          <td className="text-left">{item.agent_email ? item.agent_email : "-"}</td>
                          <td className="text-left">{item.agent_id_card ? item.agent_id_card : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => { 
                                  this.setState({
                                    modal_update: true,
                                    agent_id: item.agent_id,
                                    agent_name: item.agent_name,
                                    agent_phone: item.agent_phone,
                                    agent_email: item.agent_email,
                                    agent_id_card: item.agent_id_card,
                                    agent_file: item.agent_file,
                                  });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center delete"
                                onClick={() => {
                                  this.setState({
                                    modal_delete: true,
                                    agent_id: item.agent_id,
                                    agent_name: item.agent_name,
                                  });
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    {" "}
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ{" "}
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* เพิ่มตัวแทน */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
          size="md"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.modal_create ? "เพิ่ม" : "แก้ไข"}ตัวแทน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ agent_name: e.target.value });
                  }}
                  value={this.state.agent_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ agent_phone: e.target.value });
                  }}
                  value={this.state.agent_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ agent_email: e.target.value });
                  }}
                  value={this.state.agent_email}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">เลขบัตรประชาชน{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ agent_id_card: e.target.value });
                  }}
                  value={this.state.agent_id_card}
                  maxLength={13}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">แนบไฟล์</span>
                {!this.state.agent_file ? (
                  <button
                    className="btn btn-primary mx-1 px-4"
                    onClick={() => {
                      document.getElementById("agent_file").click();
                    }}
                  >
                    แนบไฟล์
                  </button>
                ) : (
                  <div className="w-100 d-flex">
                    <iframe src={this.state.agent_file.includes("://") ? this.state.agent_file : "data:application/pdf;base64," + this.state.agent_file} width="150" height="150"></iframe>
                    <div
                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        this.setState({ agent_file: "" });
                      }}
                    >
                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.agent_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.agent_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    } else if (this.state.agent_id_card === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัตรประชาชน");
                      return;
                    } else if (this.state.agent_id_card.length !== 13) {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัตรประชาชนให้ครบถ้วน");
                      return;
                    }
                    if (this.state.modal_create) {
                      this.Create();
                    } else {
                      this.Update();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบตัวแทน */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบตัวแทน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.agent_name} ” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListSaleDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_detail: false,
      loading: false,
      data: [],
      data_business_type: [],

      customer_type: "",
      customer_name: "",
      status: "",

      datetime_create: new Date(), //วันที่สร้าง
      full_name: "", //ลูกค้า
      request_for_price_type: "", //บริการที่เสนอราคา
      nationality: "", //สัญชาติ
      quantity: "", //จำนวน
      list_service: [], //ค่าบริการ
      total_price: 0, //รวมเป็นเงิน
      emp_data: null, //นายจ้าง

      page:1,
      data_search:""
    };
  }
  async componentDidMount() {
    await this.Get();
    await this.GetBusinessType();
  }
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      holder: 2,
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/" + this.state.page, body);
    if (result && result.status) {  
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      quotation_id: id,
      menu: "",
      holder: 2
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/0", body);
    if (result && result.status) { 
      this.setState({
        id: result.data.id,
        quotation_id: result.data.quotation_id,
        quotation_number: result.data.quotation_number,
        emp_id: result.data.emp_id,
        full_name: result.data.full_name,
        menu_id: result.data.menu_id,
        menu_name: result.data.menu_name,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        rate_list: result.data.rate_list,
        installment: result.data.installment,
        quotation_status: result.data.quotation_status,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
      });
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    let body = {};
    let result = await PUT(tokens, "api/quotation/update/" + this.state.quotation_id, body);
    if (result && result.status) {
      success_url("/SaleDepartment/list");
    }
    this.setState({ loading: false });
  };
  total = () => {
    if (this.state.rate_list) {
      let total = 0;
      for (let item of this.state.rate_list) {
        total += item.qty_price * item.qty;
      }
      return total;
    } else {
      return 0;
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ฝ่ายการขาย" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>ฝ่ายการขาย</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    window.location.href = "/SaleDepartment/create";
                  }}
                >
                  <label className="icon">{"\u002B"}</label> สร้างฝ่ายการขาย
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
              <select
                                  className="form-control mx-1 wpx-200"
                                  onChange={(e) => { 
                                    this.setState({ customer_type: e.target.value });
                                  }}
                                  value={this.state.customer_type}
                                >
                                  <option value="">ประเภทลูกค้า</option>
                                </select>
              <select
                                  className="form-control mx-1 wpx-200"
                                  onChange={(e) => { 
                                    this.setState({ customer_name: e.target.value });
                                  }}
                                  value={this.state.customer_name}
                                >
                                  <option value="">รายชื่อลูกค้า</option>
                                </select>
              <select
                                  className="form-control mx-1 wpx-200"
                                  onChange={(e) => { 
                                    this.setState({ status: e.target.value });
                                  }}
                                  value={this.state.status}
                                >
                                  <option value="">สถานะ</option>
                                </select>
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, customer_type:"",  customer_name:"", status:"", });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">รายชื่อลูกค้า</th>
                      <th className="text-left text-white">ประเภทลูกค้า</th>
                      <th className="text-left text-white">เลขที่ผู้เสียภาษี</th>
                      <th className="text-left text-white">วันที่เพิ่ม</th>
                      <th className="text-left text-white">สถานะ</th>
                      <th className="text-center text-white wpx-100 update">จัดการ</th> 
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{this.state.page ? (this.state.page - 1) * 10 + index + 1 : "-"}</td>
                          <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.emp_type === 1 ? "นิติบุคคล":item.emp_data.emp_type === 2 ? "บุคคลธรรมดา":item.emp_data.emp_type === 3 ? "เลขที่หนังสือเดินทาง":"-" : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.emp_number : "-"}</td>
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td> 
                          <td className="text-left">{item.quotation_status ? item.quotation_status === 1 ? "รอเปิดเรื่อง" :item.quotation_status === 2 ? "เปิดเรื่องแล้ว" :"-": "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  window.location.href = "/SaleDepartment/update?id=" + item.quotation_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  window.location.href = "/SaleDepartment/update?id=" + item.quotation_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div> 
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";
import Swal from "sweetalert2";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequestForPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_detail: false,
      loading: false,
      data: [],

      data_search: "",


      createdAt: null,
      id: null,
      req_price_id: "",
      quotation_number: "",
      emp_id: null,
      full_name: "",
      menu_id: "",
      menu_name: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0, 
      req_price_status: null,
      is_active: false,
      create_by: "",
      emp_data: null,
      data_business_type:[],

      page:1,

      data_rate: []
    };
  }
  async componentDidMount() {
    await this.Get();
    await this.GetBusinessType();
    await this.GetRateAll();
  }
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
    };
    let result = await GET(tokens, "api/request-price/list/page/" + this.state.page, body);
    if (result && result.status) { 
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };  

  GetRateAll = async () => { 
    let body = {
      data_search: "",
      rate_id: "",
    };
    let result = await GET(tokens, "api/setting/service-rate/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_rate: result.data 
      });
    } 
  }; 
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      req_price_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/request-price/list/page/0", body);
    if (result && result.status) { 
      this.setState({
        id: result.data.id,
        req_price_id: result.data.req_price_id,
        quotation_number: result.data.quotation_number,
        emp_id: result.data.emp_data?result.data.emp_data.emp_id:"",
        full_name: result.data.emp_data?result.data.emp_data.full_name_th:"",
        menu_id: result.data.menu_id,
        menu_name: result.data.menu_name,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty, 
        req_price_status: result.data.req_price_status,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data, 
      });
    }
    this.setState({ loading: false });
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  Update = async () => {
    this.setState({ loading: true });
    let rate_list = [];
    if(this.state.menu_name === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA"){
      let data_rate = this.state.data_rate.filter(e=>e.service_type ==="รายเรื่อง"&& (["งาน 90 วัน","งาน ต่อ Work","งาน ต่อ Visa"].includes(e.rate_name)))
      rate_list = [
        { remark: data_rate[2].rate_name, qty_price: data_rate[2].price, qty: this.state.total_qty, discount_percent: "", discount: "", total_price: data_rate[2].price, service_type:data_rate[2].service_type,vat:""},
        { remark: data_rate[0].rate_name, qty_price: data_rate[0].price, qty: this.state.total_qty, discount_percent: "", discount: "", total_price: data_rate[0].price, service_type:data_rate[0].service_type,vat:""},
        { remark: data_rate[1].rate_name, qty_price: data_rate[1].price, qty: this.state.total_qty, discount_percent: "", discount: "", total_price: data_rate[1].price, service_type:data_rate[1].service_type,vat:""},
      ];
    }
    let body = {
      req_price_id: this.state.id,
      emp_id: this.state.emp_data.id, // ถ้าส่ง 0 มา จะเป็นการสร้างข้อมูลนายจ้าง แต่ถ้าค้นหาแล้วส่งเป็น id นายจ้างมาจะไม่สร้างใหม่
      emp_user_type: this.state.emp_data.emp_user_type,
      emp_type: this.state.emp_data.emp_type,
      emp_number: this.state.emp_data.emp_number,
      prefix_th: this.state.emp_data.prefix_th,
      prefix_en: this.state.emp_data.prefix_en,
      work_type: this.state.emp_data.work_type,
      business_type: this.state.emp_data.business_type,
      business_description: this.state.emp_data.business_description,
      register_capital: this.state.emp_data.register_capital,
      register_date: this.state.emp_data.register_date,
      id_card: this.state.emp_data.id_card,
      place_issue: this.state.emp_data.place_issue,
      issue_date: this.state.emp_data.issue_date,
      expiry_date: this.state.emp_data.expiry_date,
      contact_name: this.state.emp_data.contact_name,
      full_name_th: this.state.emp_data.full_name_th,
      full_name_en: this.state.emp_data.full_name_en,
      address_number: this.state.emp_data.address_number,
      moo: this.state.emp_data.moo,
      soi: this.state.emp_data.soi,
      street: this.state.emp_data.street,
      province: this.state.emp_data.province,
      district: this.state.emp_data.district,
      sub_district: this.state.emp_data.sub_district,
      zipcode: this.state.emp_data.zipcode,
      email: this.state.emp_data.email,
      phone: this.state.emp_data.phone,
      fax: this.state.emp_data.fax,
      mobile_phone: this.state.emp_data.mobile_phone,
      home_number: this.state.emp_data.home_number,
      phone_2: this.state.emp_data.phone_2,
      fax_2: this.state.emp_data.fax_2,
      binding_authority_th: this.state.emp_data.binding_authority_th,
      binding_authority_en: this.state.emp_data.binding_authority_en,
      position_th: this.state.emp_data.position_th,
      position_en: this.state.emp_data.position_en,
      username: this.state.emp_data.username,
      password: this.state.emp_data.password,

      menu_id: this.state.menu_id,
      menu_name: this.state.menu_name,
      cambodia_qty: Number(this.state.cambodia_qty),
      laos_qty: Number(this.state.laos_qty),
      myanmar_qty: Number(this.state.myanmar_qty),
      vietnam_qty: Number(this.state.vietnam_qty),
      total_qty: Number(this.state.total_qty),
      rate_list: rate_list,
      installment: [],
      holder:1,
      is_vat: false
    }; 
    let result = await POST(tokens, "api/setting/quotation/create", body);
    if (result && result.status) { 
      let body2 = {
        emp_number:this.state.emp_data.emp_number,
        req_price_status: 2
      };
      let result2 = await PUT(tokens, "api/request-price/update/" + this.state.req_price_id, body2);
      if (result2 && result2.status) {
        success_url("/RequestForPrice/list");
      }
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="รายการใบขอราคา" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการใบขอราคา</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    window.location.href = "/RequestForPrice/create";
                  }}
                >
                  <label className="icon">{"\u002B"}</label> สร้างรายการใบขอราคา
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่ขอใบเสนอราคา</th>
                      <th className="text-left text-white">เลข 13 หลัก นายจ้าง</th>
                      <th className="text-left text-white">บริการ</th>
                      <th className="text-left text-white">จำนวนลูกจ้าง</th>
                      <th className="text-left text-white">ชื่อตัวแทน</th>
                      <th className="text-left text-white">สถานะ</th>
                      <th className="text-center text-white update">ใบรับเรื่อง</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{this.state.page ? (this.state.page - 1) * 10 + index + 1 : "-"}</td>
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.emp_number ? item.emp_number : "-"}</td>
                          <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                          <td className="text-left">{item.total_qty ? item.total_qty : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">{item.req_price_status ? item.req_price_status === 1 ?"รอออกใบเสนอราคา" :item.req_price_status === 1 ?"ออกใบเสนอราคาแล้ว": "-": "-"}</td>
                          <td className="text-center update"> 
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  window.location.href = "/RequestForPrice/update?id=" + item.req_price_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>  
                              {item.req_price_status === 1 && (
                              <div
                                className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-130 mx-1"
                                onClick={async () => {
                                  await this.getById(item.req_price_id);
                                  setTimeout(() => {
                                    this.setState({ modal_detail: true });
                                  }, 10);
                                }}
                              >
                                ออกใบเสนอราคา
                              </div>
                              )}
                              </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div> 
        {/* ออกใบเสนอราคา */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>ออกใบเสนอราคา</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              <b>ข้อมูลรายละเอียดรับเรื่อง</b>
            </h5>
            <hr />
            <div className="row">
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>วันที่สร้าง</b>
                </label>
                <label>{format_date(this.state.createdAt)}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>ลูกค้า</b>
                </label>
                <label>{this.state.full_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>บริการที่เสนอราคา</b>
                </label>
                <label>{this.state.menu_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-12 d-flex">
                <label className="wpx-180">
                  <b>สัญชาติ</b>
                </label>
                <div>
                {this.state.cambodia_qty !== 0 && (
                <div className="d-flex">
                <div className="wpx-120">กัมพูชา</div>
                <div className="wpx-120">จำนวน {this.state.cambodia_qty} คน</div>
                </div>
              )}
              {this.state.laos_qty !== 0 && (
                <div className="d-flex">
                <div className="wpx-120">ลาว</div>
                <div className="wpx-120">จำนวน {this.state.laos_qty} คน</div>
                </div>
              )}
              {this.state.myanmar_qty !== 0 && (
                <div className="d-flex">
                <div className="wpx-120">เมียนมา</div>
                <div className="wpx-120">จำนวน {this.state.myanmar_qty} คน</div>
                </div>
              )}
              {this.state.vietnam_qty !== 0 && (
                <div className="d-flex">
                <div className="wpx-120">เวียดนาม</div>
                <div className="wpx-120">จำนวน {this.state.vietnam_qty} คน</div>
                </div>
              )}
                </div>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>รวมทั้งสิ้น</b>
                </label>
                <label>{this.state.total_qty} คน</label>
              </div>
            </div>
            <hr />
            <h5>
              <b>ข้อมูลนายจ้าง</b>
            </h5>
            <hr />
            {this.state.emp_data && (
              <div>
                <div className="row">
                  {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                  <div className="mb-3 col-6">
                    <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                  </div>
                  <div className="mb-3 col-6">{this.state.emp_data.emp_status !== 1 ?(this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"):""}</div>
                  {/* English */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>English</b>
                    </div>
                    {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                  </div>
                  {/* ประเภทนายจ้าง */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>ประเภทนายจ้าง</b>
                    </div>
                    {this.state.emp_data.emp_status !== 1 ? (this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""):"-"}
                  </div>
                  {/* จดทะเบียนเมื่อ */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>จดทะเบียนเมื่อ</b>
                    </div>
                    {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                  </div>
                  {/* ทุนจดทะเบียน (บาท) */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>ทุนจดทะเบียน (บาท)</b>
                    </div>
                    {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                  </div>
                  {/* ประเภทกิจการ */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>ประเภทกิจการ</b>
                    </div>
                    {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                  </div>
                  {/* ชื่อผู้ติดต่อ */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>ชื่อผู้ติดต่อ</b>
                    </div>
                    {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                  </div>
                  {/* หมายเลขโทรศัพท์ */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>หมายเลขโทรศัพท์</b>
                    </div>
                    {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                  </div>
                  {/* หมายเลขโทรสาร */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>หมายเลขโทรสาร</b>
                    </div>
                    {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                  </div>
                  {/* หมายเลขโทรศัพท์มือถือ */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>หมายเลขโทรศัพท์มือถือ</b>
                    </div>
                    {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                  </div>
                  {/* E-mail Address */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>E-mail Address</b>
                    </div>
                    {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                  </div>
                  {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                  <div className="mb-3 d-flex col-12">
                    <div className="wpx-220">
                      <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                    </div>
                    {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                  </div>
                  {/* ที่ตั้ง/ที่อยู่ */}
                  <div className="mb-3 d-flex col-12">
                    <div className="wpx-220">
                      <b>ที่ตั้ง/ที่อยู่</b>
                    </div>
                    {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                    {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                    {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                    {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                    {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                    {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                    {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                    {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                  </div>
                  {/* ที่ตั้ง/ที่อยู่ (English) */}
                  <div className="mb-3 d-flex col-12">
                    <div className="wpx-220">
                      <b>ที่ตั้ง/ที่อยู่ (English)</b>
                    </div>
                    {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                    {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                    {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                    {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                    {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                    {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                    {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                    {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                  </div>
                  {/* หมายเลขโทรศัพท์ */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>หมายเลขโทรศัพท์</b>
                    </div>
                    {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                  </div>
                  {/* หมายเลขโทรสาร */}
                  <div className="mb-3 d-flex col-6">
                    <div className="wpx-220">
                      <b>หมายเลขโทรสาร</b>
                    </div>
                    {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                  </div>
                </div>
                {this.state.emp_data && this.state.emp_data.dpz_data && this.state.emp_data.dpz_data.filter(e=>e.deputize_selected).map((item,index)=>(
                <div className="row">
                {/* ผู้มีอำนาจผูกพัน */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>{index+1}. ผู้มีอำนาจผูกพัน</b>
                  </div>
                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                </div>
                {/* ตำแหน่ง */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ตำแหน่ง</b>
                  </div>
                  {item.deputize_position ? item.deputize_position : "-"}
                </div>
                 {/* ผู้มีอำนาจผูกพัน(English) */}
                 <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ผู้มีอำนาจผูกพัน(English)</b>
                  </div>
                  {item.deputize_full_name_en ? item.deputize_full_name_en : "-"}
                </div>
                 {/* ตำแหน่ง(English) */}
                 <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ตำแหน่ง(English)</b>
                  </div>
                  {item.deputize_position_en ? item.deputize_position_en : "-"}
                </div>
                 {/* เลข 13 หลัก */}
                 <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>เลข 13 หลัก</b>
                  </div>
                  {item.deputize_id_card ? item.deputize_id_card : "-"}
                </div>
                </div>
                 ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-end">
              <div className="wpx-150 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="wpx-180 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => { 
                    Swal.fire({
                      icon: "info",
                      title: "แจ้งเตือน",
                      text: "ยืนยันออกใบเสนอราคาใช่หรือไม่",
                      confirmButtonText: "ยืนยัน",
                      showCancelButton: true,
                      cancelButtonText: "ยกเลิก",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.Update();
                      } 
                    });
                  }}
                >
                  ยืนยันออกใบเสนอราคา
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

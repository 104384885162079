import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import Switch from "react-switch";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_update: false,
      modal_delete: false,
      loading: false,
      data: [],

      data_search: "",

      rate_id: "",
      rate_name: "",
      price: "",
      percent_vat: "",
      is_vat: false,
      service_type: "",
      service_choice: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,
    };
  }
  async componentDidMount() {
    await this.Get();
  }

  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      rate_id: "",
    };
    let result = await GET(tokens, "api/setting/service-rate/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
      });
    }
    this.setState({ loading: false });
  };

  Create = async () => {
    this.setState({ loading: true });
    let body = {
      rate_name: this.state.rate_name,
      price: Number(this.state.price),
      percent_vat: Number(this.state.percent_vat),
      is_vat: this.state.is_vat,
      service_type: this.state.service_type,
      service_choice: this.state.service_choice,
    };
    let result = await POST(tokens, "api/setting/service-rate/create", body);
    if (result && result.status) {
      success_url("/setting/ServiceRate/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      rate_name: this.state.rate_name,
      price: Number(this.state.price),
      percent_vat: Number(this.state.percent_vat),
      is_vat: this.state.is_vat,
      service_type: this.state.service_type,
      service_choice: this.state.service_choice,
    };
    let result = await PUT(tokens, "api/setting/service-rate/update/" + this.state.rate_id, body);
    if (result && result.status) {
      success_url("/setting/ServiceRate/list");
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/setting/service-rate/delete/" + this.state.rate_id, null);
    if (result && result.status) {
      success_url("/setting/ServiceRate/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="จัดการอัตราค่าบริการ" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>จัดการอัตราค่าบริการ</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    this.setState({
                      modal_create: true,
                      rate_id: "",
                      rate_name: "",
                      price: "",
                      percent_vat: "",
                      is_vat: false,
                      service_type: "",
                      service_choice: "",
                    });
                  }}
                >
                  <label className="icon">{"\u002B"}</label> เพิ่มอัตราค่าบริการ
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อค่าบริการ</th>
                      <th className="text-left text-white">ราคา</th>
                      <th className="text-left text-white">หัก ณ ที่จ่าย</th> 
                      <th className="text-left text-white">วันที่สร้าง</th>
                      <th className="text-left text-white">ผู้สร้าง</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.rate_name ? item.rate_name : "-"}</td>
                          <td className="text-left">{item.price ? toFixed(item.price) : "-"}</td>
                          <td className="text-left">{item.percent_vat ? item.percent_vat : "-"} %</td> 
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center update"
                                onClick={() => {
                                  this.setState({
                                    modal_update: true,
                                    rate_id: item.rate_id,
                                    rate_name: item.rate_name,
                                    price: item.price,
                                    percent_vat: item.percent_vat,
                                    is_vat: item.is_vat,
                                    service_type: item.service_type,
                                    service_choice: item.service_choice,
                                  });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              {/* <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                                            onClick={() => {
                                                                this.setState({
                                                                    modal_delete: true,
                                                                    rate_id: item.rate_id,
                                                                    rate_name: item.rate_name,
                                                                })
                                                            }}>
                                                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                                        </div> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    {" "}
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ{" "}
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* create or update */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.modal_create ? "เพิ่มค่าบริการ" : "แก้ไขค่าบริการ"}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            {/* ชื่อค่าบริการ */}
            <div className="w-100 d-flex align-items-center mb-3">
              <label className="wpx-140">ชื่อค่าบริการ{required}:</label> 
              <input
                className="form-control  validate"
                type="text"
                placeholder="กรอกข้อมูล"
                onChange={(e) => {
                  this.setState({ rate_name: e.target.value });
                }}
                value={this.state.rate_name}
                readOnly={["งาน 90 วัน","งาน ต่อ Work","งาน ต่อ Visa"].includes(this.state.rate_name)}
              />
            </div>
            {/* ราคา */}
            <div className="w-100 d-flex align-items-center mb-3">
              <label className="wpx-140">ราคา{required}:</label>
              <input
                className="form-control  validate"
                type="text"
                placeholder="กรอกข้อมูล"
                onChange={(e) => {
                  if (!float(e.target.value)) {
                    return;
                  }
                  this.setState({ price: e.target.value });
                }}
                value={this.state.price}
              />
            </div>
            {/* หัก ณ ที่จ่าย */}
            <div className="w-100 d-flex align-items-center mb-3">
              <label className="wpx-140">หัก ณ ที่จ่าย{required}:</label>
              <input
                className="form-control  validate"
                type="text"
                placeholder="กรอกข้อมูล"
                onChange={(e) => {
                  if (!float(e.target.value)) {
                    return;
                  }
                  if (Number(e.target.value) > 100) {
                    e.target.value = 100;
                  }
                  this.setState({ percent_vat: Number(e.target.value) });
                }}
                value={this.state.percent_vat}
              />
              <label className="wpx-30 px-2">%</label>
            </div> 
            <div className="d-flex align-items-center mb-3"> 
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="service_type"
                        onChange={(e) => {
                          this.setState({ service_type: "รายเรื่อง" });
                        }}
                        checked={this.state.service_type === "รายเรื่อง"}
                        disabled={this.state.readOnly}
                      />
                      รายเรื่อง
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="service_type"
                        onChange={(e) => {
                          this.setState({ service_type: "รายหัว" });
                        }}
                        checked={this.state.service_type === "รายหัว"}
                        disabled={this.state.readOnly}
                      />
                      รายหัว
                    </div>
            <div className="d-flex align-items-center mb-3"> 
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="service_choice"
                        onChange={(e) => {
                          this.setState({ service_choice: "ค่าบริการ" });
                        }}
                        checked={this.state.service_choice === "ค่าบริการ"}
                        disabled={this.state.readOnly}
                      />
                      ค่าบริการ
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="service_choice"
                        onChange={(e) => {
                          this.setState({ service_choice: "เงินสำรองจ่ายค่าธรรมเนียม" });
                        }}
                        checked={this.state.service_choice === "เงินสำรองจ่ายค่าธรรมเนียม"}
                        disabled={this.state.readOnly}
                      />
                      เงินสำรองจ่ายค่าธรรมเนียม
                    </div>
            {/* ปุ่ม */}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.modal_create) {
                      this.Create();
                    } else {
                      this.Update();
                    }
                  }}
                >
                  {this.state.modal_create ? "เพิ่ม" : "บันทึก"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ต้องการลูกจ้าง</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลูกจ้าง “{this.state.rate_name}”</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

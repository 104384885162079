import React, { Component } from "react";
import {GET,tokens,document_invoice_by_id } from "../CustomComponent.js";
// ใบแจ้งหนี้
export default class Loading extends Component {
  async componentDidMount() {
    let invoice_id = this.props.location.query.invoice_id;
    await this.GetHeaderDoc(invoice_id)
  }
  GetHeaderDoc = async (invoice_id) => {
    let result = await GET(tokens, "api/header-doc/list");
    if (result && result.status) {

      this.GetbyID(invoice_id,result.data)
    }
  };
  GetbyID = async (invoice_id,data_header) => {
    let body_invoice = {
      invoice_id: invoice_id
    };
    let result_invoice = await GET(tokens, "api/invoice/list/page/0", body_invoice);
    if (result_invoice && result_invoice.status) {
    let body_receive = {
      receive_id: result_invoice.data.receive_id
    };
    let result_receive = await GET(tokens, "api/receive-matter/list/page/0", body_receive);
    result_receive.data.rate_list = result_receive.data.rate_list.map(e=>{return {
      ...e,
      qty_price : e.qty_price - e.discount
    }})
    let obj = {
      ...result_invoice.data,
      receive_data: result_receive.data
    }
    document_invoice_by_id(obj, data_header)
  }
  }
  render() {
    return <div></div>;
  }
}
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Swal from "sweetalert2";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
  convert_imagetopdf,
  success_back,
} from "../../components/CustomComponent.js";
import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_add_regis: false,
      modal_create: false,
      modal_delete: false,
      tab: 1,
      loading: false,

      data_search: "",
      emp_data: null,
      data_user: [],
      data_insurance: [],
      data_business_type: [],
      data_immigration_office: [],
      data_workplace: [],
      data_agent: [],
      data_nationality: global.data_nationality,
      data_prefix_th: [],
      data_prefix_en: [],
      data_gender: global.data_gender,
      data_evidence_type: global.data_evidence_type,
      data_country: global.data_country,
      data_result_type: [
        { value: "1", label: "ปกติ" },
        { value: "2", label: "มีผลต้องติดตาม" },
      ],

      // นายจ้าง
      emp_number: "",
      emp_full_name: "",
      emp_address_number: "",
      emp_moo: "",
      emp_soi: "",
      emp_street: "",
      emp_province: "",
      emp_district: "",
      emp_sub_district: "",
      emp_zipcode: "",

      // ข้อมูลทั้งหมด
      fw_id: "",
      emp_id: "",
      insurance_type: 0,
      business_type: "",
      province: "",
      place_work: "",
      agent: "",
      phone: "",
      email: "",
      fw_number: "",
      nationality: "",
      prefix_th: "",
      first_name_th: "",
      middle_name_th: "",
      last_name_th: "",
      prefix_en: "",
      first_name_en: "",
      middle_name_en: "",
      last_name_en: "",
      birth_date: "",
      age: "",
      number_TM6: "",
      gender: "",
      evidence_type: "",
      document_type_number: "",
      fw_business_description: "",
      job_position: "",

      profile_image: "",
      identity_document: "",
      evidence_allowed_document: "",
      consent_document: "",

      profile_image_date: "",
      identity_document_date: "",
      evidence_allowed_document_date: "",

      profile_image_type: "",
      identity_document_type: "",
      evidence_allowed_document_type: "",
      consent_document_type: "",

      // เพิ่มสถานที่ทำงานใหม่
      address_number: "",
      home_number: "",
      moo: "",
      soi: "",
      street: "",
      province: "",
      district: "",
      sub_district: "",
      zipcode: "",
      phone_create: "",
      email_create: "",

      // ตัวแทน
      agent_name: "",
      agent_phone: "",
      agent_email: "",
      modal_agent_create: false,

      // ไฟล์แนบ
      attachment_name: "",

      // ข้อมูลที่อยู่ลูกจ้าง
      fw_housing_type: "",
      fw_house_number: "",
      fw_building_name: "",
      fw_moo: "",
      fw_soi: "",
      fw_street: "",
      fw_province: "",
      fw_district: "",
      fw_sub_district: "",
      fw_zipcode: "",
      fw_phone: "",
      fw_fax: "",
      fw_entry_country_date: "",
      fw_entry_checkpoint: "",
      fw_place_of_birth: "",
      fw_country: "",

      // Passport
      passport_type: "",
      passport_number: "",
      passport_place: "",
      passport_born_place: "",
      passport_country: "",
      passport_allowed_date: "",
      passport_expiry_date: "",
      passport_image: "",

      passport_old_image: "",

      // Visa
      visa_number: "",
      visa_allowed_location: "",
      visa_allowed_date: "",
      visa_expiry_date: "",
      visa_stay_kingdom_date: "",
      visa_image: "",

      // Work permit
      work_pm_number: "",
      work_pm_allowed_location: "",
      work_pm_allowed_date: "",
      work_pm_expiry_date: "",
      work_pm_image: "",

      // notice_90d
      notice_90d_number: "",
      notice_90d_allowed_location: "",
      notice_90d_allowed_date: "",
      notice_90d_expiry_date: "",
      notice_90d_image: "",

      // ประกันสุขภาพรัฐบาล
      health_number: "",
      health_hospital_name: "",
      health_allowed_date: "",
      health_expiry_date: "",
      health_image: "",

      // ประกันภัยเอกชน
      insurance_name: "",
      insurance_number: "",
      insurance_allowed_date: "",
      insurance_expiry_date: "",
      insurance_image: "",

      // ประกันสังคม
      sso_number: "",
      sso_image: "",
      sso_hospital_name: "",
      sso_province: "",

      // ข้อมูลผลการตรวจสุขภาพ
      result_health: "",
      result_type: "",
      health_province: "",
      health_remark: "",
      hospital_name: "",
      inspec_date: "",
      diagnose_image: "",

      // ประกันภัย
      company_name: "",
      policy_number: "",
      allowed_stay_date: "",
      expiry_date: "",
      policy_image: "",

      detail_image: "",
      document_history: [],

      // Passport
      edit_passport_image: "",
      edit_passport_number: "",
      edit_passport_place: "",
      edit_passport_allowed_date: "",
      edit_passport_expiry_date: "",

      // Visa
      edit_visa_image: "",
      edit_visa_number: "",
      edit_visa_allowed_location: "",
      edit_visa_allowed_date: "",
      edit_visa_expiry_date: "",

      // Work permit
      edit_work_pm_image: "",
      edit_work_pm_number: "",
      edit_work_pm_allowed_location: "",
      edit_work_pm_allowed_date: "",
      edit_work_pm_expiry_date: "",

      // notice_90d
      edit_notice_90d_image: "",
      edit_notice_90d_number: "",
      edit_notice_90d_allowed_location: "",
      edit_notice_90d_allowed_date: "",
      edit_notice_90d_expiry_date: "",

      copy_house_registration: "",
      copy_idcard_image: "",

      edit_index: -1,

      emp_code: "",
    };
  }
  async componentDidMount() {
    await this.GetImmigration();
    await this.GetBusinessType();
    await this.GetPrefix();
    await this.GetAgent();
    await this.GetInsurance();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
    }
    if (this.props.location.query.emp_number) {
      this.setState({ data_search: this.props.location.query.emp_number });
      setTimeout(() => {
        this.GetUser();
      }, 10);
    }
  }
  GetImmigration = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_immigration_office: result.data.map((e) => {
          return { value: e.id.toString(), label: e.imm_name_th };
        }),
      });
    }
    this.setState({ loading: false });
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetPrefix = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      if (this.props.location.query.menu_id == 20) {
        this.setState({
          data_prefix_th: result.data
            .filter((e) => [1, 2, 3].includes(e.id))
            .map((e) => {
              return { value: e.id.toString(), label: e.prefix_name_th };
            }),
          data_prefix_en: result.data
            .filter((e) => [1, 2, 3].includes(e.id))
            .map((e) => {
              return { value: e.id.toString(), label: e.prefix_name_en };
            }),
        });
      } else {
        this.setState({
          data_prefix_th: result.data.map((e) => {
            return { value: e.id.toString(), label: e.prefix_name_th };
          }),
          data_prefix_en: result.data.map((e) => {
            return { value: e.id.toString(), label: e.prefix_name_en };
          }),
        });
      }
    }
    this.setState({ loading: false });
  };
  GetInsurance = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      insurance_id: "",
    };
    let result = await GET(tokens, "api/insurance/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_insurance: result.data.map((e) => {
          return { value: e.insurance_id, label: e.company_name };
        }),
      });
    }
    this.setState({ loading: false });
  };
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      fw_id: id,
    };
    let result = await GET(tokens, "api/foreign-worker/list/page/0", body);
    if (result && result.status) {
      let item = result.data;
      console.log(item);
      this.setState({
        emp_id: item.emp_data.emp_id,

        emp_data: item.emp_data,
        emp_number: item.emp_data.emp_number,
        emp_full_name: item.emp_data.full_name_th,
        emp_address_number: item.emp_data.address_number,
        emp_moo: item.emp_data.moo,
        emp_soi: item.emp_data.soi,
        emp_street: item.emp_data.street,
        emp_province: item.emp_data.province,
        emp_district: item.emp_data.district,
        emp_sub_district: item.emp_data.sub_district,
        emp_zipcode: item.emp_data.zipcode,
      });
      setTimeout(async () => {
        await this.GetWorkplace(item.emp_data.emp_id);
        this.setState({
          fw_id: item.fw_id,

          data_deputize: item.emp_data.dpz_data ? item.emp_data.dpz_data : [],

          insurance_type: item.insurance_type,
          business_type: item.emp_data && item.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === item.emp_data.business_type.toString())[0] : "",
          province: item.province ? { value: item.province, label: item.province } : "",
          place_work: item.pw_data ? this.state.data_workplace.filter((e) => e.value === item.pw_data.pw_id.toString())[0] : "",
          agent: item.agent_data ? this.state.data_agent.filter((e) => e.value === item.agent_data.agent_id)[0] : "",

          phone: item.phone,
          email: item.email,

          fw_number: item.fw_number,
          nationality: item.nationality ? this.state.data_nationality.filter((e) => e.value === item.nationality.toString())[0] : "",
          prefix_th: item.prefix_th ? this.state.data_prefix_th.filter((e) => e.value === item.prefix_th.toString())[0] : "",
          first_name_th: item.first_name_th,
          middle_name_th: item.middle_name_th,
          last_name_th: item.last_name_th,
          prefix_en: item.prefix_en ? this.state.data_prefix_en.filter((e) => e.value === item.prefix_en.toString())[0] : "",
          first_name_en: item.first_name_en,
          middle_name_en: item.middle_name_en,
          last_name_en: item.last_name_en,
          birth_date: item.birth_date,
          age: item.age,
          number_TM6: item.number_TM6,
          gender: this.state.data_gender.filter((e) => e.value === item.gender)[0],
          evidence_type: item.evidence_type ? this.state.data_evidence_type.filter((e) => e.value === item.evidence_type.toString())[0] : "",
          document_type_number: item.document_type_number,
          country: item.country ? this.state.data_country.filter((e) => e.value === item.country)[0] : "",
          allowed_work_date: item.allowed_work_date,

          profile_image: item.profile_image,
          identity_document: item.identity_document,
          evidence_allowed_document: item.evidence_allowed_document,
          consent_document: item.consent_document,

          profile_image_type: item.profile_image ? item.profile_image.split(".").pop() : "",
          identity_document_type: item.identity_document ? item.identity_document.split(".").pop() : "",
          evidence_allowed_document_type: item.evidence_allowed_document ? item.evidence_allowed_document.split(".").pop() : "",
          consent_document_type: item.consent_document ? item.consent_document.split(".").pop() : "",

          // ลักษณะงาน
          fw_business_description: item.fw_business_description,

          // ข้อมูลที่อยู่ลูกจ้าง
          fw_housing_type: item.fw_housing_type,
          fw_house_number: item.fw_house_number,
          fw_building_name: item.fw_building_name,
          fw_moo: item.fw_moo,
          fw_soi: item.fw_soi,
          fw_street: item.fw_street,
          fw_province: item.fw_province
            ? province
                .map((e) => {
                  return { value: e.province_name, label: e.province_name };
                })
                .filter((e) => e.value === item.fw_province)[0]
            : "",
          fw_district: item.fw_district
            ? district
                .map((e) => {
                  return { value: e.district_name, label: e.district_name };
                })
                .filter((e) => e.value === item.fw_district)[0]
            : "",
          fw_sub_district: item.fw_sub_district
            ? subdistrict
                .map((e) => {
                  return {
                    value: e.sub_district_name,
                    label: e.sub_district_name,
                    zip_code: e.zip_code,
                  };
                })
                .filter((e) => e.value === item.fw_sub_district)[0]
            : "",
          fw_zipcode: item.fw_zipcode,
          fw_phone: item.fw_phone,
          fw_fax: item.fw_fax,
          fw_entry_country_date: item.fw_entry_country_date,
          fw_entry_checkpoint: item.fw_entry_checkpoint ? this.state.data_immigration_office.filter((e) => e.value === item.fw_entry_checkpoint)[0] : "",
          fw_place_of_birth: item.fw_place_of_birth,
          fw_country: item.fw_country ? this.state.data_country.filter((e) => e.value === item.fw_country)[0] : "",

          // Passport
          passport_type: item.passport_type,
          passport_number: item.passport_number,
          passport_place: item.passport_place,
          passport_born_place: item.passport_born_place,
          passport_country: item.passport_country ? this.state.data_country.filter((e) => e.value === item.passport_country)[0] : "",
          passport_allowed_date: item.passport_allowed_date,
          passport_expiry_date: item.passport_expiry_date,
          passport_image: item.passport_image,
          passport_old_image: item.passport_old_image,

          // Visa
          visa_number: item.visa_number,
          visa_allowed_location: item.visa_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === result.data.visa_allowed_location)[0] : "",
          visa_allowed_date: item.visa_allowed_date,
          visa_expiry_date: item.visa_expiry_date,
          visa_stay_kingdom_date: item.visa_stay_kingdom_date,
          visa_image: item.visa_image,

          // Work permit
          work_pm_number: item.work_pm_number,
          work_pm_allowed_location: item.work_pm_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === result.data.work_pm_allowed_location)[0] : "",
          work_pm_allowed_date: item.work_pm_allowed_date,
          work_pm_expiry_date: item.work_pm_expiry_date,
          work_pm_image: item.work_pm_image,

          // notice_90d
          notice_90d_number: item.notice_90d_number,
          notice_90d_allowed_location: item.notice_90d_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === result.data.notice_90d_allowed_location)[0] : "",
          notice_90d_allowed_date: item.notice_90d_allowed_date,
          notice_90d_expiry_date: item.notice_90d_expiry_date,
          notice_90d_image: item.notice_90d_image,

          copy_house_registration:item.copy_house_registration,
copy_idcard_image:item.copy_idcard_image,

          // ประกันสุขภาพรัฐบาล
          health_number: item.health_number,
          health_hospital_name: item.health_hospital_name,
          health_allowed_date: item.health_allowed_date,
          health_expiry_date: item.health_expiry_date,
          health_image: item.health_image,

          // ประกันภัยเอกชน
          insurance_name: item.insurance_data ? this.state.data_insurance.filter((e) => e.value === item.insurance_data.insurance_id) : "",
          insurance_number: item.insurance_number,
          insurance_allowed_date: item.insurance_allowed_date,
          insurance_expiry_date: item.insurance_expiry_date,
          insurance_image: item.insurance_image,

          // ประกันสังคม
          sso_number: item.sso_number,
          sso_image: item.sso_image,
          sso_hospital_name: item.sso_hospital_name,
          sso_province: item.sso_province
            ? province
                .map((e) => {
                  return { value: e.province_name, label: e.province_name };
                })
                .filter((e) => e.value === item.sso_province)[0]
            : "",

          // ข้อมูลผลการตรวจสุขภาพ
          result_health: item.result_health,
          result_type: item.result_type ? this.state.data_result_type.filter((e) => e.value === item.result_type)[0] : "",
          health_province: item.health_province
            ? province
                .map((e) => {
                  return { value: e.province_name, label: e.province_name };
                })
                .filter((e) => e.value === item.health_province)[0]
            : "",
          health_remark: item.health_remark,
          hospital_name: item.hospital_name,
          inspec_date: item.inspec_date,
          diagnose_image: item.diagnose_image,

          // ประกันภัย
          company_name: item.company_name,
          policy_number: item.policy_number,
          allowed_stay_date: item.allowed_stay_date,
          expiry_date: item.expiry_date,
          policy_image: item.policy_image,

          profile_image2: "",
          identity_document2: "",
          evidence_allowed_document2: "",
          consent_document2: "",
          passport_image2: "",
          visa_image2: "",
          work_pm_image2: "",
          health_image2: "",
          insurance_image2: "",
          sso_image2: "",
          diagnose_image2: "",
          policy_image2: "",
          document_history: item.document_history ? JSON.parse(item.document_history) : [],
          emp_code: item.emp_code,
        });
      }, 10);
    }
    this.setState({ loading: false });
  };
  GetUser = async () => {
    if (this.state.data_search === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขประจำตัวนายจ้าง");
      return;
    }
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      emp_id: "",
    };
    let result = await GET(tokens, "api/employer/list/page/0", body);
    if (result && result.status) {
      await this.GetWorkplace(result.data[0].emp_id);
      this.setState({
        emp_id: result.data[0].emp_id,
        emp_number: result.data[0].emp_number,
        emp_full_name: result.data[0].full_name_th,
        emp_address_number: result.data[0].address_number,
        emp_moo: result.data[0].moo,
        emp_soi: result.data[0].soi,
        emp_street: result.data[0].street,
        emp_province: result.data[0].province,
        emp_district: result.data[0].district,
        emp_sub_district: result.data[0].sub_district,
        emp_zipcode: result.data[0].zipcode,
        business_type: result.data[0].business_type ? this.state.data_business_type.filter((e) => e.value === result.data[0].business_type.toString())[0] : "",
        place_work: this.state.data_workplace[0],
      });

      if (this.state.data_workplace.length === 0) {
        this.setState({
          emp_id: result.data[0].emp_id,
          address_number: result.data[0].address_number,
          home_number: result.data[0].home_number,
          moo: result.data[0].moo,
          soi: result.data[0].soi,
          street: result.data[0].street,
          province: province
            .map((item) => {
              return { value: item.province_name, label: item.province_name };
            })
            .filter((item) => item.value === result.data[0].province)[0],
          district: district
            .map((item) => {
              return { value: item.district_name, label: item.district_name };
            })
            .filter((item) => item.value === result.data[0].district)[0],
          sub_district: subdistrict
            .map((item) => {
              return {
                value: item.sub_district_name,
                label: item.sub_district_name,
                zip_code: item.zip_code,
              };
            })
            .filter((item) => item.value === result.data[0].sub_district)[0],
          zipcode: result.data[0].zipcode,
          phone_create: result.data[0].phone,
          email_create: result.data[0].email,
        });
        await this.CreateWorkplace();
      }
    }
    this.setState({ loading: false });
  };
  GetWorkplace = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      let location = result.data;

      this.setState({
        data_workplace: location.map((e) => {
          return {
            value: e.pw_id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
            ...e,
          };
        }),
      });
      if (location.length === 1) {
        setTimeout(() => {
          this.setState({
            place_work: this.state.data_workplace[0],
          });
        }, 10);
      }
      if (location.length === 0) {
        this.setState({
          emp_id: this.state.emp_id,
          address_number: this.state.emp_address_number ? this.state.emp_address_number : "",
          home_number: this.state.emp_home_number ? this.state.emp_home_number : "",
          moo: this.state.emp_moo ? this.state.emp_moo : "",
          soi: this.state.emp_soi ? this.state.emp_soi : "",
          street: this.state.emp_street ? this.state.emp_street : "",
          province: this.state.emp_province ? { value: this.state.emp_province } : "",
          district: this.state.emp_district ? { value: this.state.emp_district } : "",
          sub_district: this.state.emp_sub_district ? { value: this.state.emp_sub_district } : "",
          zipcode: this.state.emp_zipcode ? this.state.emp_zipcode : "",
          phone_create: this.state.emp_data ? this.state.emp_data.phone : "",
          email_create: this.state.emp_data ? this.state.emp_data.email : "",
        });

        console.log("this.state.emp_province:", this.state.emp_province);
        setTimeout(async () => {
          console.log("this.state.province:", this.state.province);
          await this.CreateWorkplace();
        }, 20);
      }
    }
    this.setState({ loading: false });
  };
  GetAgent = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      agent_id: "",
    };
    let result = await GET(tokens, "api/agent/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_agent: result.data.map((e) => {
          return {
            value: e.agent_id,
            label: e.agent_name + " " + e.agent_phone + " " + e.agent_email,
            ...e,
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      insurance_type: this.state.insurance_type,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      province: this.state.province ? this.state.province.value : "",
      place_work: this.state.place_work ? this.state.place_work.value : "",
      agent: this.state.agent ? this.state.agent.value : "",
      phone: this.state.phone,
      email: this.state.email,

      fw_number: this.state.fw_number,
      nationality: this.state.nationality ? Number(this.state.nationality.value) : "",
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      first_name_th: this.state.first_name_th,
      middle_name_th: this.state.middle_name_th,
      last_name_th: this.state.last_name_th,
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      first_name_en: this.state.first_name_en,
      middle_name_en: this.state.middle_name_en,
      last_name_en: this.state.last_name_en,
      birth_date: this.state.birth_date,
      age: this.state.age,
      emp_code: this.state.emp_code,
      number_TM6: this.state.number_TM6,
      gender: this.state.gender ? this.state.gender.value : "",
      evidence_type: this.state.evidence_type ? Number(this.state.evidence_type.value) : "",
      document_type_number: this.state.document_type_number,
      job_position: this.state.job_position ? this.state.job_position.value : "",

      profile_image: this.state.profile_image,
      identity_document: this.state.identity_document,
      evidence_allowed_document: this.state.evidence_allowed_document,
      consent_document: this.state.consent_document,

      profile_image_type: this.state.profile_image_type ? this.state.profile_image_type : "",
      identity_document_type: this.state.identity_document_type ? this.state.identity_document_type : "",
      evidence_allowed_document_type: this.state.evidence_allowed_document_type ? this.state.evidence_allowed_document_type : "",
      consent_document_type: this.state.consent_document_type ? this.state.consent_document_type : "",

      // ลักษณะงาน
      fw_business_description: this.state.fw_business_description ? this.state.fw_business_description : "",

      // ข้อมูลที่อยู่ลูกจ้าง
      fw_housing_type: this.state.fw_housing_type ? this.state.fw_housing_type : "",
      fw_house_number: this.state.fw_house_number ? this.state.fw_house_number : "",
      fw_building_name: this.state.fw_building_name ? this.state.fw_building_name : "",
      fw_moo: this.state.fw_moo ? this.state.fw_moo : "",
      fw_soi: this.state.fw_soi ? this.state.fw_soi : "",
      fw_street: this.state.fw_street ? this.state.fw_street : "",
      fw_province: this.state.fw_province ? this.state.fw_province.value : "",
      fw_district: this.state.fw_district ? this.state.fw_district.value : "",
      fw_sub_district: this.state.fw_sub_district ? this.state.fw_sub_district.value : "",
      fw_zipcode: this.state.fw_zipcode ? this.state.fw_zipcode : "",
      fw_phone: this.state.fw_phone ? this.state.fw_phone : "",
      fw_fax: this.state.fw_fax ? this.state.fw_fax : "",
      fw_entry_country_date: this.state.fw_entry_country_date ? this.state.fw_entry_country_date : "",
      fw_entry_checkpoint: this.state.fw_entry_checkpoint ? this.state.fw_entry_checkpoint.value : "",
      fw_place_of_birth: this.state.fw_place_of_birth ? this.state.fw_place_of_birth : "",
      fw_country: this.state.fw_country ? this.state.fw_country.value : "",

      // Passport
      passport_type: this.state.passport_type,
      passport_number: this.state.passport_number,
      passport_place: this.state.passport_place,
      passport_born_place: this.state.passport_born_place,
      passport_country: this.state.passport_country ? this.state.passport_country.value : "",
      passport_allowed_date: this.state.passport_allowed_date,
      passport_expiry_date: this.state.passport_expiry_date,
      passport_image: this.state.passport_image,
      passport_old_image: this.state.passport_old_image,

      // Visa
      visa_number: this.state.visa_number,
      visa_allowed_location: this.state.visa_allowed_location ? this.state.visa_allowed_location.value : "",
      visa_allowed_date: this.state.visa_allowed_date,
      visa_expiry_date: this.state.visa_expiry_date,
      visa_stay_kingdom_date: this.state.visa_stay_kingdom_date,
      visa_image: this.state.visa_image,

      // Work permit
      work_pm_number: this.state.work_pm_number,
      work_pm_allowed_location: this.state.work_pm_allowed_location ? this.state.work_pm_allowed_location.value : "",
      work_pm_allowed_date: this.state.work_pm_allowed_date,
      work_pm_expiry_date: this.state.work_pm_expiry_date,
      work_pm_image: this.state.work_pm_image,

      // notice_90d
      notice_90d_number: this.state.notice_90d_number,
      notice_90d_allowed_location: this.state.notice_90d_allowed_location ? this.state.notice_90d_allowed_location.value : "",
      notice_90d_allowed_date: this.state.notice_90d_allowed_date,
      notice_90d_expiry_date: this.state.notice_90d_expiry_date,
      notice_90d_image: this.state.notice_90d_image,

      // ประกันสุขภาพรัฐบาล
      health_number: this.state.health_number,
      health_hospital_name: this.state.health_hospital_name,
      health_allowed_date: this.state.health_allowed_date,
      health_expiry_date: this.state.health_expiry_date,
      health_image: this.state.health_image,

      // ประกันภัยเอกชน
      insurance_name: this.state.insurance_name ? this.state.insurance_name.value : "",
      insurance_number: this.state.insurance_number,
      insurance_allowed_date: this.state.insurance_allowed_date,
      insurance_expiry_date: this.state.insurance_expiry_date,
      insurance_image: this.state.insurance_image,

      // ประกันสังคม
      sso_number: this.state.sso_number,
      sso_image: this.state.sso_image,
      sso_hospital_name: this.state.sso_hospital_name,
      sso_province: this.state.sso_province ? this.state.sso_province.value : "",

      // ข้อมูลผลการตรวจสุขภาพ
      result_health: this.state.result_health,
      result_type: this.state.result_type ? this.state.result_type.value : "",
      health_province: this.state.health_province ? this.state.health_province.value : "",
      health_remark: this.state.health_remark,
      hospital_name: this.state.hospital_name,
      inspec_date: this.state.inspec_date,
      diagnose_image: this.state.diagnose_image,

      // ประกันภัย
      company_name: this.state.company_name,
      policy_number: this.state.policy_number,
      allowed_stay_date: this.state.allowed_stay_date,
      expiry_date: this.state.expiry_date,
      policy_image: this.state.policy_image,

      document_history: this.state.document_history.length !== 0 ? JSON.stringify(this.state.document_history) : "",

      copy_house_registration: this.state.copy_house_registration,
      copy_idcard_image: this.state.copy_idcard_image,
    };
    let result = await POST(tokens, "api/foreign-worker/create", body);
    if (result && result.status) {
      success_url("/Employee/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      insurance_type: this.state.insurance_type,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : "",
      province: this.state.province ? this.state.province.value : "",
      place_work: this.state.place_work ? this.state.place_work.value : "",
      agent: this.state.agent ? this.state.agent.value : "",
      phone: this.state.phone,
      email: this.state.email,

      fw_number: this.state.fw_number,
      nationality: this.state.nationality ? Number(this.state.nationality.value) : "",
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : "",
      first_name_th: this.state.first_name_th,
      middle_name_th: this.state.middle_name_th,
      last_name_th: this.state.last_name_th,
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : "",
      first_name_en: this.state.first_name_en,
      middle_name_en: this.state.middle_name_en,
      last_name_en: this.state.last_name_en,
      birth_date: this.state.birth_date,
      age: this.state.age,
      emp_code: this.state.emp_code,
      number_TM6: this.state.number_TM6,
      gender: this.state.gender ? this.state.gender.value : "",
      evidence_type: this.state.evidence_type ? Number(this.state.evidence_type.value) : "",
      document_type_number: this.state.document_type_number,
      job_position: this.state.job_position ? this.state.job_position.value : "",

      profile_image: this.state.profile_image,
      identity_document: this.state.identity_document,
      evidence_allowed_document: this.state.evidence_allowed_document,
      consent_document: this.state.consent_document,

      profile_image_type: this.state.profile_image_type ? this.state.profile_image_type : "",
      identity_document_type: this.state.identity_document_type ? this.state.identity_document_type : "",
      evidence_allowed_document_type: this.state.evidence_allowed_document_type ? this.state.evidence_allowed_document_type : "",
      consent_document_type: this.state.consent_document_type ? this.state.consent_document_type : "",

      // ลักษณะงาน
      fw_business_description: this.state.fw_business_description ? this.state.fw_business_description : "",

      // ข้อมูลที่อยู่ลูกจ้าง
      fw_housing_type: this.state.fw_housing_type ? this.state.fw_housing_type : "",
      fw_house_number: this.state.fw_house_number ? this.state.fw_house_number : "",
      fw_building_name: this.state.fw_building_name ? this.state.fw_building_name : "",
      fw_moo: this.state.fw_moo ? this.state.fw_moo : "",
      fw_soi: this.state.fw_soi ? this.state.fw_soi : "",
      fw_street: this.state.fw_street ? this.state.fw_street : "",
      fw_province: this.state.fw_province ? this.state.fw_province.value : "",
      fw_district: this.state.fw_district ? this.state.fw_district.value : "",
      fw_sub_district: this.state.fw_sub_district ? this.state.fw_sub_district.value : "",
      fw_zipcode: this.state.fw_zipcode ? this.state.fw_zipcode : "",
      fw_phone: this.state.fw_phone ? this.state.fw_phone : "",
      fw_fax: this.state.fw_fax ? this.state.fw_fax : "",
      fw_entry_country_date: this.state.fw_entry_country_date ? this.state.fw_entry_country_date : "",
      fw_entry_checkpoint: this.state.fw_entry_checkpoint ? this.state.fw_entry_checkpoint.value : "",
      fw_place_of_birth: this.state.fw_place_of_birth ? this.state.fw_place_of_birth : "",
      fw_country: this.state.fw_country ? this.state.fw_country.value : "",

      // Passport
      passport_type: this.state.passport_type,
      passport_number: this.state.passport_number,
      passport_place: this.state.passport_place,
      passport_born_place: this.state.passport_born_place,
      passport_country: this.state.passport_country ? this.state.passport_country.value : "",
      passport_allowed_date: this.state.passport_allowed_date,
      passport_expiry_date: this.state.passport_expiry_date,
      passport_image: this.state.passport_image,
      passport_old_image: this.state.passport_old_image,

      // Visa
      visa_number: this.state.visa_number,
      visa_allowed_location: this.state.visa_allowed_location ? this.state.visa_allowed_location.value : "",
      visa_allowed_date: this.state.visa_allowed_date,
      visa_expiry_date: this.state.visa_expiry_date,
      visa_stay_kingdom_date: this.state.visa_stay_kingdom_date,
      visa_image: this.state.visa_image,

      // Work permit
      work_pm_number: this.state.work_pm_number,
      work_pm_allowed_location: this.state.work_pm_allowed_location ? this.state.work_pm_allowed_location.value : "",
      work_pm_allowed_date: this.state.work_pm_allowed_date,
      work_pm_expiry_date: this.state.work_pm_expiry_date,
      work_pm_image: this.state.work_pm_image,

      // notice_90d
      notice_90d_number: this.state.notice_90d_number,
      notice_90d_allowed_location: this.state.notice_90d_allowed_location ? this.state.notice_90d_allowed_location.value : "",
      notice_90d_allowed_date: this.state.notice_90d_allowed_date,
      notice_90d_expiry_date: this.state.notice_90d_expiry_date,
      notice_90d_image: this.state.notice_90d_image,

      // ประกันสุขภาพรัฐบาล
      health_number: this.state.health_number,
      health_hospital_name: this.state.health_hospital_name,
      health_allowed_date: this.state.health_allowed_date,
      health_expiry_date: this.state.health_expiry_date,
      health_image: this.state.health_image,

      // ประกันภัยเอกชน
      insurance_name: this.state.insurance_name ? this.state.insurance_name.value : "",
      insurance_number: this.state.insurance_number,
      insurance_allowed_date: this.state.insurance_allowed_date,
      insurance_expiry_date: this.state.insurance_expiry_date,
      insurance_image: this.state.insurance_image,

      // ประกันสังคม
      sso_number: this.state.sso_number,
      sso_image: this.state.sso_image,
      sso_hospital_name: this.state.sso_hospital_name,
      sso_province: this.state.sso_province ? this.state.sso_province.value : "",

      // ข้อมูลผลการตรวจสุขภาพ
      result_health: this.state.result_health,
      result_type: this.state.result_type ? this.state.result_type.value : "",
      health_province: this.state.health_province ? this.state.health_province.value : "",
      health_remark: this.state.health_remark,
      hospital_name: this.state.hospital_name,
      inspec_date: this.state.inspec_date,
      diagnose_image: this.state.diagnose_image,

      // ประกันภัย
      company_name: this.state.company_name,
      policy_number: this.state.policy_number,
      allowed_stay_date: this.state.allowed_stay_date,
      expiry_date: this.state.expiry_date,
      policy_image: this.state.policy_image,

      document_history: this.state.document_history.length !== 0 ? JSON.stringify(this.state.document_history) : "",

      copy_house_registration: this.state.copy_house_registration,
copy_idcard_image: this.state.copy_idcard_image,
    };
    let result = await PUT(tokens, "api/foreign-worker/update/" + this.state.fw_id, body);
    if (result && result.status) {
      if (this.props.location.query.type === "receive") {
        // success_back();
        if (this.props.location.query.ids) {
          success_url("/Employer/update?id=" + this.props.location.query.ids + "&type=name_list");
        } else if (this.props.location.query.receive_ids) {
          success_url("/Employer/update?receive_id=" + this.props.location.query.receive_ids + "&type=name_list&receive_codes=" + this.props.location.query.receive_codes + "&menu_id=" + this.props.location.query.menu_id + "&menu_name=" + this.props.location.query.menu_name);
        }
      } else if (this.props.location.query.type === "receive_all") {
        success_url("/RecieveAll/list");
      } else {
        success_url("/Employee/list");
      }
    }
    this.setState({ loading: false });
  };
  CreateWorkplace = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      address_number: this.state.address_number ? this.state.address_number : "",
      home_number: this.state.home_number ? this.state.home_number : "",
      moo: this.state.moo ? this.state.moo : "",
      soi: this.state.soi ? this.state.soi : "",
      street: this.state.street ? this.state.street : "",
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode ? this.state.zipcode : "",
      phone: this.state.phone_create ? this.state.phone_create : "",
      email: this.state.email_create ? this.state.email_create : "",
    };
    let result = await POST(tokens, "api/place-work/create", body);
    if (result && result.status) {
      // success();
      this.setState({
        address_number: "",
        home_number: "",
        moo: "",
        soi: "",
        street: "",
        province: "",
        district: "",
        sub_district: "",
        zipcode: "",
        phone_create: "",
        email_create: "",
        modal_create: false,
      });
      setTimeout(() => {
        this.GetWorkplace(this.state.emp_id);
      }, 1);
    }
    this.setState({ loading: false });
  };
  CreateAgent = async () => {
    this.setState({ loading: true });
    let body = {
      agent_name: this.state.agent_name,
      agent_phone: this.state.agent_phone,
      agent_email: this.state.agent_email,
    };
    let result = await POST(tokens, "api/agent/create", body);
    if (result && result.status) {
      success();
      this.setState({
        agent_name: "",
        agent_phone: "",
        agent_email: "",
        modal_agent_create: false,
      });
      setTimeout(() => {
        this.GetAgent();
      }, 1);
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    let file = e.target.files[0];

    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("pdf") && !file.type.includes("image")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      let files = null;
      if (file.type.includes("pdf")) {
        files = e.target.result.split(",")[1];
      } else {
        files = await convert_imagetopdf(e.target.result);
      }
      if (type === "profile_image") {
        this.setState({
          profile_image: files,
          profile_image_type: "pdf",
          profile_image_date: new Date(),
        });
      } else if (type === "identity_document") {
        this.setState({
          identity_document: files,
          identity_document_type: "pdf",
          identity_document_date: new Date(),
        });
      } else if (type === "evidence_allowed_document") {
        this.setState({
          evidence_allowed_document: files,
          evidence_allowed_document_type: "pdf",
          evidence_allowed_document_date: new Date(),
        });
      } else if (type === "consent_document") {
        this.setState({
          consent_document: files,
          consent_document_type: "pdf",
          consent_document_date: new Date(),
        });
      } else if (type === "passport_image") {
        this.setState({
          passport_image: files,
        });
      } else if (type === "passport_old_image") {
        this.setState({
          passport_old_image: files,
        });
      } else if (type === "visa_image") {
        this.setState({
          visa_image: files,
        });
      } else if (type === "work_pm_image") {
        this.setState({
          work_pm_image: files,
        });
      } else if (type === "notice_90d_image") {
        this.setState({
          notice_90d_image: files,
        });
      } else if (type === "health_image") {
        this.setState({
          health_image: files,
        });
      } else if (type === "insurance_image") {
        this.setState({
          insurance_image: files,
        });
      } else if (type === "sso_image") {
        this.setState({
          sso_image: files,
        });
      } else if (type === "diagnose_image") {
        this.setState({
          diagnose_image: files,
        });
      } else if (type === "policy_image") {
        this.setState({
          policy_image: files,
        });
      } else if (type === "edit_passport_image") {
        this.setState({
          edit_passport_image: files,
        });
      } else if (type === "edit_visa_image") {
        this.setState({
          edit_visa_image: files,
        });
      } else if (type === "edit_work_pm_image") {
        this.setState({
          edit_work_pm_image: files,
        });
      } else if (type === "edit_notice_90d_image") {
        this.setState({
          edit_notice_90d_image: files,
        });
      } else if (type === "copy_house_registration") {
        this.setState({
          copy_house_registration: files,
        });
      } else if (type === "copy_idcard_image") {
        this.setState({
          copy_idcard_image: files,
        });
      }
    };
    let array_file = document.getElementsByName("files");
    for(let item of array_file){
      item.value = "";
      item.files = null;
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ทะเบียนลูกจ้าง" page3={this.props.location.query.id ? "แก้ไขลูกจ้าง " + (this.props.location.query.menu_name ? this.props.location.query.menu_name + " " + this.props.location.query.receive_codes : "") : "เพิ่มลูกจ้าง"} />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>{this.props.location.query.id ? "แก้ไขลูกจ้าง " + (this.props.location.query.menu_name ? this.props.location.query.menu_name + " " + this.props.location.query.receive_codes : "") : "เพิ่มลูกจ้าง"}</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-3" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  {this.props.location.query.type === "receive" || this.props.location.query.type === "receive_all" ? (
                    this.state.emp_data ? (
                      <div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + (this.state.emp_data.sub_district.label ? this.state.emp_data.sub_district.label : this.state.emp_data.sub_district) : ""}
                            {this.state.emp_data.district ? " อำเภอ " + (this.state.emp_data.district.label ? this.state.emp_data.district.label : this.state.emp_data.district) : ""}
                            {this.state.emp_data.province ? " จังหวัด " + (this.state.emp_data.province.label ? this.state.emp_data.province.label : this.state.emp_data.province) : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === (this.state.emp_data.sub_district.label ? this.state.emp_data.sub_district.label : this.state.emp_data.sub_district))[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === (this.state.emp_data.district.label ? this.state.emp_data.district.label : this.state.emp_data.district))[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === (this.state.emp_data.province.label ? this.state.emp_data.province.label : this.state.emp_data.province))[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div>
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : null
                  ) : (
                    <div>
                      <h5 className="mb-3">
                        <b>ค้นหานายจ้าง</b>
                      </h5>
                      <hr />
                      <div className="w-100 card border-0 p-0">
                        <div className="d-flex align-items-center mx-0">
                          <span className="me-2">เลขประจำตัวนายจ้าง</span>
                          <input
                            className="form-control wpx-250 bg-contain border-0 mx-1"
                            type="text"
                            placeholder="กรอกข้อมูล"
                            onChange={(e) => {
                              this.setState({ data_search: e.target.value });
                            }}
                            value={this.state.data_search}
                          />
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              this.GetUser();
                            }}
                          >
                            ค้นหา
                          </button>
                        </div>
                      </div>
                      <hr />
                      <h5 className="mb-3">
                        <b>ข้อมูลนายจ้าง</b>
                      </h5>
                      <div className="row">
                        <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขที่ประจำตัวนายจ้าง</span>
                          <span>: {this.state.emp_number ? this.state.emp_number : ""}</span>
                        </div>
                        <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อนายจ้าง</span>
                          <span>: {this.state.emp_full_name ? this.state.emp_full_name : ""}</span>
                        </div>
                        <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ประเภทกิจการ</span>
                          <span>: {this.state.business_type ? this.state.business_type.label : ""}</span>
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">สถานที่ตั้ง</span>
                          <span>
                            : {this.state.emp_address_number ? this.state.emp_address_number : ""}
                            {this.state.emp_moo ? " หมู่ " + this.state.emp_moo : ""}
                            {this.state.emp_soi ? " ซอย " + this.state.emp_soi : ""}
                            {this.state.emp_street ? " ถนน " + this.state.emp_street : ""}
                            {this.state.emp_sub_district ? " ตำบล " + this.state.emp_sub_district : ""}
                            {this.state.emp_district ? " อำเภอ " + this.state.emp_district : ""}
                            {this.state.emp_province ? " จังหวัด" + this.state.emp_province : ""}
                            {this.state.emp_zipcode ? " รหัสไปรษณีย์ " + this.state.emp_zipcode : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr />
                  <h5 className="mb-3">
                    <b>เลือกสถานที่ทำงานของลูกจ้าง</b>
                  </h5>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สถานที่ทำงาน{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_workplace}
                        onChange={(e) => {
                          this.setState({ place_work: e });
                        }}
                        value={this.state.place_work}
                      ></Select>
                    </div>
                    {this.state.emp_number && (
                      <div className="col-12 col-md-6">
                        <button
                          className="btn btn-primary px-4"
                          onClick={() => {
                            this.setState({
                              emp_id: this.state.emp_id,
                              address_number: "",
                              home_number: "",
                              moo: "",
                              soi: "",
                              street: "",
                              province: "",
                              district: "",
                              sub_district: "",
                              zipcode: "",
                              phone_create: "",
                              email_create: "",
                              modal_create: true,
                            });
                          }}
                        >
                          + เพิ่มสถานที่ทำงานใหม่
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ลักษณะงาน</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ fw_business_description: e.target.value });
                        }}
                        value={this.state.fw_business_description}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ตัวแทน</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_agent}
                        onChange={(e) => {
                          this.setState({ agent: e });
                        }}
                        value={this.state.agent}
                      ></Select>
                    </div>
                    {this.state.emp_number && (
                      <div className="col-12 col-md-6">
                        <button
                          className="btn btn-primary px-4"
                          onClick={() => {
                            this.setState({
                              agent_name: "",
                              agent_phone: "",
                              agent_email: "",
                              modal_agent_create: true,
                            });
                          }}
                        >
                          + เพิ่มตัวแทน
                        </button>
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-3 mx-auto">{this.state.profile_image && <iframe src={this.state.profile_image.includes("://") ? this.state.profile_image : "§§" + this.state.profile_image} width="150" height="150"></iframe>}</div>
                  </div>
                  <h5 className="mb-3">
                    <b>กรอกข้อมูลลูกจ้าง</b>
                  </h5>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขบัตรประจำตัว{![20].includes(Number(this.props.location.query.menu_id)) && required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        maxLength={13}
                        onChange={(e) => {
                          if (!float(e.target.value)) {
                            return;
                          }
                          this.setState({ fw_number: e.target.value });
                        }}
                        value={this.state.fw_number}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สัญชาติ{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_nationality}
                        onChange={(e) => {
                          this.setState({ nationality: e });
                        }}
                        value={this.state.nationality}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">คำนำหน้า(ไทย){required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_prefix_th}
                        onChange={(e) => {
                          if (e.value === "1") {
                            this.setState({ gender: { value: "ชาย", label: "ชาย" } });
                          } else if (e.value === "2" || e.value === "3") {
                            this.setState({ gender: { value: "หญิง", label: "หญิง" } });
                          }
                          this.setState({ prefix_th: e, prefix_en: e ? this.state.data_prefix_en.filter((i) => i.value === e.value)[0] : "" });
                        }}
                        value={this.state.prefix_th}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อต้น(ไทย){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ first_name_th: e.target.value });
                        }}
                        value={this.state.first_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อกลาง(ไทย)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ middle_name_th: e.target.value });
                        }}
                        value={this.state.middle_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">นามสกุล(ไทย)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ last_name_th: e.target.value });
                        }}
                        value={this.state.last_name_th}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">คำนำหน้า(Eng){required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_prefix_en}
                        onChange={(e) => {
                          if (e.value === "1") {
                            this.setState({ gender: { value: "ชาย", label: "ชาย" } });
                          } else if (e.value === "2" || e.value === "3") {
                            this.setState({ gender: { value: "หญิง", label: "หญิง" } });
                          }
                          this.setState({ prefix_en: e, prefix_th: e ? this.state.data_prefix_th.filter((i) => i.value === e.value)[0] : "" });
                        }}
                        value={this.state.prefix_en}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อต้น(Eng){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ first_name_en: e.target.value });
                        }}
                        value={this.state.first_name_en}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อกลาง(Eng)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ middle_name_en: e.target.value });
                        }}
                        value={this.state.middle_name_en}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">นามสกุล(Eng)</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ last_name_en: e.target.value });
                        }}
                        value={this.state.last_name_en}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันเกิด{[20].includes(Number(this.props.location.query.menu_id)) && required}</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          if (e) {
                            let day = days_between(new Date(), new Date(e)).day;
                            this.setState({ birth_date: e, age: Number((day / 365).toString().split(".")[0]) });
                            if ([20].includes(Number(this.props.location.query.menu_id))) {
                              let year = Number((day / 365).toString().split(".")[0]);
                              if (year < 18 || 55 < year) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "แจ้งเตือน",
                                  text: "ลงทะเบียนไม่ได้ต้องประสานงานแจ้งกับทางลูกค้า",
                                  confirmButtonText: "ตกลง",
                                });
                              }
                            }
                          } else {
                            this.setState({ birth_date: null, age: 0 });
                          }
                        }}
                        value={this.state.birth_date}
                        max={new Date()}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">อายุ</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ age: e.target.value });
                        }}
                        value={this.state.age}
                        readOnly
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เพศ{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_gender}
                        onChange={(e) => {
                          this.setState({ gender: e });
                        }}
                        value={this.state.gender}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">รหัสพนักงาน</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ emp_code: e.target.value });
                        }}
                        value={this.state.emp_code}
                      />
                    </div>
                    {/* <div className="col-12 col-md-12 d-flex mb-3">
                          <span className="wpx-150">สำเนาทะเบียนบ้าน</span>
                          <div className="d-flex">
                            {!this.state.copy_house_registration ? (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  document.getElementById("file_copy_house_registration").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.copy_house_registration.includes("://") ? this.state.copy_house_registration :  "data:application/pdf;base64,"+this.state.copy_house_registration} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, attachment_file: this.state.copy_house_registration });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "copy_house_registration" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              name="file"
                              id="file_copy_house_registration"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "copy_house_registration");
                              }}
                            />
                          </div>
                        </div>
 <div className="col-12 col-md-12 d-flex mb-3">
                          <span className="wpx-150">สำเนาบัตรประชาชน</span>
                          <div className="d-flex">
                            {!this.state.copy_idcard_image ? (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  document.getElementById("file_copy_idcard_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.copy_idcard_image.includes("://") ? this.state.copy_idcard_image :  "data:application/pdf;base64,"+this.state.copy_idcard_image} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, attachment_file: this.state.copy_idcard_image });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_delete: true, attachment_name: "copy_idcard_image" });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              name="file"
                              id="file_copy_idcard_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "copy_idcard_image");
                              }}
                            />
                          </div>
                        </div> */}
                    <hr />
                    <h5 className="mb-3">
                      <b>ข้อมูลที่อยู่ลูกจ้าง</b>
                    </h5>
                    <div className="row">
                      <div className="col-12 d-flex align-items-center mb-3">
                        <span className="wpx-150">ที่อยู่อาศัย{required}</span>
                        <input
                          className="mt-1 mx-3"
                          type="radio"
                          name="fw_housing_type"
                          onChange={(e) => {
                            console.log(this.state.emp_data);
                            this.setState({
                              fw_housing_type: "ที่เดียวกับที่ตั้ง/ที่อยู่ของนายจ้าง",
                              fw_house_number: "",
                              fw_building_name: this.state.emp_data && this.state.emp_data.address_number ? this.state.emp_data.address_number : this.state.emp_address_number,
                              fw_moo: this.state.emp_data && this.state.emp_data.moo ? this.state.emp_data.moo : this.state.emp_moo,
                              fw_soi: this.state.emp_data && this.state.emp_data.soi ? this.state.emp_data.soi : this.state.emp_soi,
                              fw_street: this.state.emp_data && this.state.emp_data.street ? this.state.emp_data.street : this.state.emp_street,
                              fw_province:
                                this.state.emp_data && this.state.emp_data.province
                                  ? this.state.emp_data.province.value
                                    ? this.state.emp_data.province
                                    : province
                                        .map((e) => {
                                          return { value: e.province_name, label: e.province_name };
                                        })
                                        .find((e) => e.value === this.state.emp_data.province)
                                  : province
                                      .map((e) => {
                                        return { value: e.province_name, label: e.province_name };
                                      })
                                      .find((e) => e.value === this.state.emp_province),
                              fw_district:
                                this.state.emp_data && this.state.emp_data.district
                                  ? this.state.emp_data.district.value
                                    ? this.state.emp_data.district
                                    : district
                                        .map((e) => {
                                          return { value: e.district_name, label: e.district_name };
                                        })
                                        .find((e) => e.value === this.state.emp_data.district)
                                  : district
                                      .map((e) => {
                                        return { value: e.district_name, label: e.district_name };
                                      })
                                      .find((e) => e.value === this.state.emp_district),
                              fw_sub_district:
                                this.state.emp_data && this.state.emp_data.sub_district
                                  ? this.state.emp_data.sub_district.value
                                    ? this.state.emp_data.sub_district
                                    : subdistrict
                                        .map((e) => {
                                          return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                        })
                                        .find((e) => e.value === this.state.emp_data.sub_district)
                                  : subdistrict
                                      .map((e) => {
                                        return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                      })
                                      .find((e) => e.value === this.state.emp_sub_district),
                              fw_zipcode: this.state.emp_data && this.state.emp_data.zipcode ? this.state.emp_data.zipcode : this.state.emp_zipcode,
                              fw_phone: "",
                              fw_fax: "",
                            });
                          }}
                          checked={this.state.fw_housing_type === "ที่เดียวกับที่ตั้ง/ที่อยู่ของนายจ้าง"}
                          disabled={this.state.readOnly}
                        />
                        ที่เดียวกับที่ตั้ง/ที่อยู่ของนายจ้าง
                        <input
                          className="mt-1 mx-3"
                          type="radio"
                          name="fw_housing_type"
                          onChange={(e) => {
                            console.log(this.state.place_work);
                            this.setState({
                              fw_housing_type: "ที่เดียวกับสถานที่ทำงาน",
                              fw_house_number: "",
                              fw_building_name: this.state.place_work ? this.state.place_work.address_number : "",
                              fw_moo: this.state.place_work ? this.state.place_work.moo : "",
                              fw_soi: this.state.place_work ? this.state.place_work.soi : "",
                              fw_street: this.state.place_work ? this.state.place_work.street : "",
                              fw_province: this.state.place_work
                                ? province
                                    .map((e) => {
                                      return { value: e.province_name, label: e.province_name };
                                    })
                                    .find((e) => e.value === this.state.place_work.province)
                                : "",
                              fw_district: this.state.place_work
                                ? district
                                    .map((e) => {
                                      return { value: e.district_name, label: e.district_name };
                                    })
                                    .find((e) => e.value === this.state.place_work.district)
                                : "",
                              fw_sub_district: this.state.place_work
                                ? subdistrict
                                    .map((e) => {
                                      return { value: e.sub_district_name, label: e.sub_district_name, zip_code: e.zip_code };
                                    })
                                    .find((e) => e.value === this.state.place_work.sub_district)
                                : "",
                              fw_zipcode: this.state.place_work ? this.state.place_work.zipcode : "",
                              fw_phone: this.state.place_work ? this.state.place_work.phone : "",
                              fw_fax: "",
                            });
                          }}
                          checked={this.state.fw_housing_type === "ที่เดียวกับสถานที่ทำงาน"}
                          disabled={this.state.readOnly}
                        />
                        ที่เดียวกับสถานที่ทำงาน
                        <input
                          className="mt-1 mx-3"
                          type="radio"
                          name="fw_housing_type"
                          onChange={(e) => {
                            this.setState({
                              fw_housing_type: "ที่อื่น",
                              fw_house_number: "",
                              fw_building_name: "",
                              fw_moo: "",
                              fw_soi: "",
                              fw_street: "",
                              fw_province: "",
                              fw_district: "",
                              fw_sub_district: "",
                              fw_zipcode: "",
                              fw_phone: "",
                              fw_fax: "",
                            });
                          }}
                          checked={this.state.fw_housing_type === "ที่อื่น"}
                          disabled={this.state.readOnly}
                        />
                        ที่อื่น
                      </div>
                    </div>
                    {this.state.fw_housing_type !== "" && (
                      <div className="row">
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขรหัสประจำบ้าน</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                fw_house_number: e.target.value,
                              });
                            }}
                            value={this.state.fw_house_number}
                          />
                        </div>
                        <div className="col-12 col-md-6"></div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขที่/อาคาร{required}</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                fw_building_name: e.target.value,
                              });
                            }}
                            value={this.state.fw_building_name}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมู่ที่</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ fw_moo: e.target.value });
                            }}
                            value={this.state.fw_moo}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ซอย</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ fw_soi: e.target.value });
                            }}
                            value={this.state.fw_soi}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ถนน</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ fw_street: e.target.value });
                            }}
                            value={this.state.fw_street}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">จังหวัด{required}</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={province.map((item) => {
                              return {
                                value: item.province_name,
                                label: item.province_name,
                              };
                            })}
                            onChange={(e) => {
                              this.setState({
                                fw_province: e,
                                district: null,
                                sub_district: null,
                                zipcode: "",
                              });
                            }}
                            value={this.state.fw_province}
                          ></Select>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150"> อำเภอ / เขต</span>
                          <Select
                            className={"form-control select-search" + (this.state.fw_province ? "" : " bg-contain")}
                            styles={select_style}
                            options={district
                              .filter((item) => this.state.fw_province && this.state.fw_province.value === item.province_name)
                              .map((item) => {
                                return {
                                  value: item.district_name,
                                  label: item.district_name,
                                };
                              })}
                            onChange={(e) => {
                              this.setState({
                                fw_district: e,
                                sub_district: null,
                                zipcode: "",
                              });
                            }}
                            value={this.state.fw_district}
                            isDisabled={!this.state.fw_province}
                          ></Select>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ตำบล / แขวง</span>
                          <Select
                            className={"form-control select-search" + (this.state.fw_district ? "" : " bg-contain")}
                            styles={select_style}
                            options={subdistrict
                              .filter((item) => this.state.fw_district && this.state.fw_district.value === item.district_name && this.state.fw_province && this.state.fw_province.value === item.province_name)
                              .map((item) => {
                                return {
                                  value: item.sub_district_name,
                                  label: item.sub_district_name,
                                  zipcode: item.zip_code,
                                };
                              })}
                            onChange={(e) => {
                              this.setState({
                                fw_sub_district: e,
                                fw_zipcode: e.zipcode.toString(),
                              });
                            }}
                            value={this.state.fw_sub_district}
                            isDisabled={!this.state.fw_district}
                          ></Select>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">รหัสไปรษณีย์</span>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="รหัสไปรษณีย์"
                            onChange={(e) => {
                              this.setState({ fw_zipcode: e.target.value });
                            }}
                            value={this.state.fw_zipcode}
                            disabled
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">โทรศัพท์</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            maxLength={10}
                            onChange={(e) => {
                              if (!float(e.target.value)) {
                                return;
                              }
                              this.setState({ fw_phone: e.target.value });
                            }}
                            value={this.state.fw_phone}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เบอร์แฟกซ์</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ fw_fax: e.target.value });
                            }}
                            value={this.state.fw_fax}
                          />
                        </div>
                        <div className="col-12 hpx-30 bg-primary mb-3"></div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันที่เข้าประเทศ{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                        <DatePicker
                          className="form-control mt-1"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(e) => {
                            this.setState({ fw_entry_country_date: e });
                          }}
                          value={this.state.fw_entry_country_date}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ด่านที่เข้า{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_immigration_office}
                          onChange={(e) => {
                            this.setState({ fw_entry_checkpoint: e });
                          }}
                          value={this.state.fw_entry_checkpoint}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">สถานที่เกิด</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              fw_place_of_birth: e.target.value,
                            });
                          }}
                          value={this.state.fw_place_of_birth}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ประเทศ</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_country}
                          onChange={(e) => {
                            this.setState({ fw_country: e });
                          }}
                          value={this.state.fw_country}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลข ตม.6</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ number_TM6: e.target.value });
                          }}
                          value={this.state.number_TM6}
                        />
                      </div>
                      <div className="col-12 d-flex align-items-center mb-3">
                        <span className="wpx-150">หนังสือยินยอมจากเจ้าบ้าน</span>
                        <input
                          className="d-none"
                          type="file"
                          name="file"
                          id="file_consent_document"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "consent_document");
                          }}
                        />
                        {!this.state.consent_document ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_consent_document").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex">
                            <iframe src={this.state.consent_document.includes("://") ? this.state.consent_document : "data:application/pdf;base64," + this.state.consent_document} width="150" height="150"></iframe>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_detail: true, detail_image: this.state.consent_document });
                              }}
                            >
                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                            </div>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({
                                  modal_delete: true,
                                  attachment_name: "consent_document",
                                });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-3">
                      <span>
                        <span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF,JPG,PNG ขนาดไม่เกิน 3 MB เท่านั้น)
                      </span>
                    </div>
                    <div className="table-responsive mb-2">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-center text-white">รายการ</th>
                            <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                            <th className="text-center text-white">ดำเนินการ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">1</td>
                            <td className="text-start">รูปถ่ายของลูกจ้าง (รูปถ่ายหน้าตรง ไม่สวมหมวก ไม่ถือสิ่งของ เห็นหน้าชัดเจนและพื้นหลังต้องเป็นสีขาวเท่านั้น)</td>
                            <td className="text-center">{this.state.profile_image_date ? format_date(this.state.profile_image_date, "dd mmm yyyy") + " เวลา " + format_date(this.state.profile_image_date, "hh:mm") + " น." : ""}</td>
                            <td className="text-center">
                              <input
                                className="d-none"
                                type="file"
                                name="file"
                                id="file_profile_image"
                                accept="application/pdf,image/*"
                                onChange={(e) => {
                                  this.import_file(e, "profile_image");
                                }}
                              />
                              {!this.state.profile_image ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  onClick={() => {
                                    document.getElementById("file_profile_image").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-center">
                                  <iframe src={this.state.profile_image.includes("://") ? this.state.profile_image : "data:application/pdf;base64," + this.state.profile_image} width="150" height="150"></iframe>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      this.setState({ modal_detail: true, detail_image: this.state.profile_image });
                                    }}
                                  >
                                    <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                  </div>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      this.setState({
                                        modal_delete: true,
                                        attachment_name: "profile_image",
                                      });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow border-0 mb-3" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <div className="row d-none">
                    {/* Passport */}
                    <h5 className="mb-3">
                      <b>Passport</b>
                    </h5>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทหนังสือเดินทาง{required}</span>
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="passport_type"
                        onChange={(e) => {
                          this.setState({
                            passport_type: "ไม่มีหนังสือเดินทาง",
                            passport_number: "",
                            passport_place: "",
                            passport_born_place: "",
                            passport_country: "",
                            passport_allowed_date: "",
                            passport_expiry_date: "",
                            passport_image: "",
                          });
                        }}
                        checked={this.state.passport_type === "ไม่มีหนังสือเดินทาง"}
                      />
                      ไม่มีหนังสือเดินทาง
                      <input
                        className="mt-1 me-2 ms-5"
                        type="radio"
                        name="passport_type"
                        onChange={(e) => {
                          this.setState({ passport_type: "Passport" });
                        }}
                        checked={this.state.passport_type === "Passport"}
                      />
                      Passport
                      <input
                        className="mt-1 me-2 ms-5"
                        type="radio"
                        name="passport_type"
                        onChange={(e) => {
                          this.setState({ passport_type: "TD" });
                        }}
                        checked={this.state.passport_type === "TD"}
                      />
                      TD
                      <input
                        className="mt-1 me-2 ms-5"
                        type="radio"
                        name="passport_type"
                        onChange={(e) => {
                          this.setState({ passport_type: "CI" });
                        }}
                        checked={this.state.passport_type === "CI"}
                      />
                      CI
                      <input
                        className="mt-1 me-2 ms-5"
                        type="radio"
                        name="passport_type"
                        onChange={(e) => {
                          this.setState({ passport_type: "TP" });
                        }}
                        checked={this.state.passport_type === "TP"}
                      />
                      TP
                    </div>
                    {this.state.passport_type && this.state.passport_type !== "ไม่มีหนังสือเดินทาง" && (
                      <div className="row">
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขที่{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                passport_number: e.target.value,
                              });
                            }}
                            value={this.state.passport_number}
                          />
                        </div>
                        {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">สถานที่ออก</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                passport_place: e.target.value,
                              });
                            }}
                            value={this.state.passport_place}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">สถานที่เกิด</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                passport_born_place: e.target.value,
                              });
                            }}
                            value={this.state.passport_born_place}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ประเทศ</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_country}
                            onChange={(e) => {
                              this.setState({ passport_country: e });
                            }}
                            value={this.state.passport_country}
                          ></Select>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออก</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ passport_allowed_date: e });
                            }}
                            value={this.state.passport_allowed_date}
                            max={new Date()}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมด</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ passport_expiry_date: e });
                            }}
                            value={this.state.passport_expiry_date}
                            min={new Date()}
                          />
                        </div> */}
                        {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {!this.state.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.passport_image.includes("://") ? this.state.passport_image : "data:application/pdf;base64," + this.state.passport_image} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, detail_image: this.state.passport_image });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      modal_delete: true,
                                      attachment_name: "passport_image",
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              name="file"
                              id="file_passport_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "passport_image");
                              }}
                            />
                          </div>
                        </div> */}
                      </div>
                    )}
                    <hr />
                    {/* Visa */}
                    <h5 className="mb-3">
                      <b>Visa</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่ตรวจลงตรา{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ visa_number: e.target.value });
                        }}
                        value={this.state.visa_number}
                      />
                    </div>
                    {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ตม.</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_immigration_office}
                        onChange={(e) => {
                          this.setState({ visa_allowed_location: e });
                        }}
                        value={this.state.visa_allowed_location}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ออก</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ visa_allowed_date: e });
                        }}
                        value={this.state.visa_allowed_date}
                        max={new Date()}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่หมด</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ visa_expiry_date: e });
                        }}
                        value={this.state.visa_expiry_date}
                        min={new Date()}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ให้อยู่ในราชอาณาจักรได้ถึง</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ visa_stay_kingdom_date: e });
                        }}
                        value={this.state.visa_stay_kingdom_date}
                        min={new Date()}
                      />
                    </div> */}
                    {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {!this.state.visa_image ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_visa_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            <iframe src={this.state.visa_image.includes("://") ? this.state.visa_image : "data:application/pdf;base64," + this.state.visa_image} width="150" height="150"></iframe>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_detail: true, detail_image: this.state.visa_image });
                              }}
                            >
                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                            </div>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({
                                  modal_delete: true,
                                  attachment_name: "visa_image",
                                });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          name="file"
                          id="file_visa_image"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "visa_image");
                          }}
                        />
                      </div>
                    </div> */}
                    <hr />
                    {/* Work permit */}
                    <h5 className="mb-3">
                      <b>Work permit</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ work_pm_number: e.target.value });
                        }}
                        value={this.state.work_pm_number}
                      />
                    </div>
                    {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ตม.</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_immigration_office}
                        onChange={(e) => {
                          this.setState({ work_pm_allowed_location: e });
                        }}
                        value={this.state.work_pm_allowed_location}
                      ></Select>
                    </div>

                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ออก</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ work_pm_allowed_date: e });
                        }}
                        value={this.state.work_pm_allowed_date}
                        max={new Date()}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่หมด</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ work_pm_expiry_date: e });
                        }}
                        value={this.state.work_pm_expiry_date}
                        min={new Date()}
                      />
                    </div> */}
                    {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {!this.state.work_pm_image ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_work_pm_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            <iframe src={this.state.work_pm_image.includes("://") ? this.state.work_pm_image : "data:application/pdf;base64," + this.state.work_pm_image} width="150" height="150"></iframe>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_detail: true, detail_image: this.state.work_pm_image });
                              }}
                            >
                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                            </div>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({
                                  modal_delete: true,
                                  attachment_name: "work_pm_image",
                                });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          name="file"
                          id="file_work_pm_image"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "work_pm_image");
                          }}
                        />
                      </div>
                    </div> */}
                    <hr />
                    {/* แจ้ง 90 วันที่เข้าประเทศไทย */}
                    <h5 className="mb-3">
                      <b>แจ้ง 90 วันที่เข้าประเทศไทย</b>
                    </h5>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ notice_90d_number: e.target.value });
                        }}
                        value={this.state.notice_90d_number}
                      />
                    </div>
                    {/*
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ตม.</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_immigration_office}
                        onChange={(e) => {
                          this.setState({ notice_90d_allowed_location: e });
                        }}
                        value={this.state.notice_90d_allowed_location}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่ออก</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ notice_90d_allowed_date: e });
                        }}
                        value={this.state.notice_90d_allowed_date}
                        max={new Date()}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่หมด</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ notice_90d_expiry_date: e });
                        }}
                        value={this.state.notice_90d_expiry_date}
                        min={new Date()}
                      />
                    </div> */}
                    {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">แนบรูป</span>
                      <div className="d-flex">
                        {!this.state.notice_90d_image ? (
                          <button
                            className="btn btn-primary mx-1 px-4"
                            onClick={() => {
                              document.getElementById("file_notice_90d_image").click();
                            }}
                          >
                            แนบไฟล์
                          </button>
                        ) : (
                          <div className="w-100 d-flex justify-content-center">
                            <iframe src={this.state.notice_90d_image.includes("://") ? this.state.notice_90d_image : "data:application/pdf;base64," + this.state.notice_90d_image} width="150" height="150"></iframe>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({ modal_detail: true, detail_image: this.state.notice_90d_image });
                              }}
                            >
                              <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                            </div>
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.setState({
                                  modal_delete: true,
                                  attachment_name: "notice_90d_image",
                                });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        )}
                        <input
                          className="d-none"
                          type="file"
                          name="file"
                          id="file_notice_90d_image"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            this.import_file(e, "notice_90d_image");
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mb-3">
                    <h5>
                      <b>ประวัติการต่ออายุ</b>
                    </h5>
                    <button
                      className="btn btn-primary px-3"
                      onClick={() => {
                        this.setState({ modal_add_regis: true, edit_passport_type: "", edit_passport_number: "", edit_visa_number: "", edit_work_pm_number: "", edit_notice_90d_number: "", edit_passport_image: "", edit_passport_place: "", edit_passport_allowed_date: "", edit_passport_expiry_date: "", edit_visa_image: "", edit_visa_allowed_location: "", edit_visa_allowed_date: "", edit_visa_expiry_date: "", edit_work_pm_image: "", edit_work_pm_allowed_location: "", edit_work_pm_allowed_date: "", edit_work_pm_expiry_date: "", edit_notice_90d_image: "", edit_notice_90d_allowed_location: "", edit_notice_90d_allowed_date: "", edit_notice_90d_expiry_date: "", edit_index: -1 });
                      }}
                    >
                      เพิ่ม
                    </button>
                  </div>
                  {this.state.document_history &&
                    this.state.document_history.map((item, index) => (
                      <table className="table table-borderless table-striped">
                        <thead>
                          <tr>
                            <th className="text-24 fw-bold" colSpan={4}>
                              ต่ออายุครั้งที่ {index + 1}
                            </th>
                            <th>
                              <div className="w-100 d-flex justify-content-end">
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      modal_add_regis: true,
                                      edit_passport_type: item.passport_type,
                                      edit_passport_number: item.passport_number,
                                      edit_visa_number: item.visa_number,
                                      edit_work_pm_number: item.work_pm_number,
                                      edit_notice_90d_number: item.notice_90d_number,
                                      edit_passport_image: item.passport_image,
                                      edit_passport_place: item.passport_place,
                                      edit_passport_allowed_date: item.passport_allowed_date,
                                      edit_passport_expiry_date: item.passport_expiry_date,
                                      edit_visa_image: item.visa_image,
                                      edit_visa_allowed_location: item.visa_allowed_location ? this.state.data_immigration_office.filter((e) => e.label === item.visa_allowed_location)[0] : "",
                                      edit_visa_allowed_date: item.visa_allowed_date,
                                      edit_visa_expiry_date: item.visa_expiry_date,
                                      edit_work_pm_image: item.work_pm_image,
                                      edit_work_pm_allowed_location: item.work_pm_allowed_location ? this.state.data_immigration_office.filter((e) => e.label === item.work_pm_allowed_location)[0] : "",
                                      edit_work_pm_allowed_date: item.work_pm_allowed_date,
                                      edit_work_pm_expiry_date: item.work_pm_expiry_date,
                                      edit_notice_90d_image: item.notice_90d_image,
                                      edit_notice_90d_allowed_location: item.notice_90d_allowed_location ? this.state.data_immigration_office.filter((e) => e.label === item.notice_90d_allowed_location)[0] : "",
                                      edit_notice_90d_allowed_date: item.notice_90d_allowed_date,
                                      edit_notice_90d_expiry_date: item.notice_90d_expiry_date,
                                      edit_index: index,
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    Swal.fire({
                                      icon: "warning",
                                      title: "ยืนยัน",
                                      text: "ต้องการลบข้อมูลนี้หรือไม่",
                                      confirmButtonText: "ยืนยัน",
                                      showCancelButton: true,
                                      cancelButtonText: "ยกเลิก",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        let document_history = this.state.document_history;
                                        document_history.splice(index, 1);
                                        this.setState({ document_history: document_history });
                                      }
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bg-secondary">
                            <th className="text-light wpx-200">เอกสาร</th>
                            <th className="text-light wpx-200">เลขที่</th>
                            <th className="text-light wpx-300">สถานที่/ด่าน</th>
                            <th className="text-light">วันที่ออก/วันที่แจ้ง</th>
                            <th className="text-light">วันที่หมด/วันที่ครบกำหนด</th>
                          </tr>
                          <tr>
                            <td>Passport</td>
                            <td>{item.passport_number}</td>
                            <td>{item.passport_place}</td>
                            <td>{item.passport_allowed_date ? format_date(item.passport_allowed_date, "dd/mm/yyyy", "en") : ""}</td>
                            <td>{item.passport_expiry_date ? format_date(item.passport_expiry_date, "dd/mm/yyyy", "en") : ""}</td>
                          </tr>
                          <tr>
                            <td>Visa</td>
                            <td>{item.visa_number}</td>
                            <td>{item.visa_allowed_location}</td>
                            <td>{item.visa_allowed_date ? format_date(item.visa_allowed_date, "dd/mm/yyyy", "en") : ""}</td>
                            <td>{item.visa_expiry_date ? format_date(item.visa_expiry_date, "dd/mm/yyyy", "en") : ""}</td>
                          </tr>
                          <tr>
                            <td>Work permit</td>
                            <td>{item.work_pm_number}</td>
                            <td>{item.work_pm_allowed_location}</td>
                            <td>{item.work_pm_allowed_date ? format_date(item.work_pm_allowed_date, "dd/mm/yyyy", "en") : ""}</td>
                            <td>{item.work_pm_expiry_date ? format_date(item.work_pm_expiry_date, "dd/mm/yyyy", "en") : ""}</td>
                          </tr>
                          <tr>
                            <td>แจ้ง 90 วันที่เข้าประเทศไทย</td>
                            <td>{item.notice_90d_number}</td>
                            <td>{item.notice_90d_allowed_location}</td>
                            <td>{item.notice_90d_allowed_date ? format_date(item.notice_90d_allowed_date, "dd/mm/yyyy", "en") : ""}</td>
                            <td>{item.notice_90d_expiry_date ? format_date(item.notice_90d_expiry_date, "dd/mm/yyyy", "en") : ""}</td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                </div>
              </div>
            </div>

            {![3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && (
              <div className="card shadow border-0 mb-3" style={{ background: "#ffffff" }}>
                <div className="p-3">
                  <div className="w-100">
                    <div className="row">
                      {/* ข้อมูลผลการตรวจสุขภาพ */}
                      <h5 className="mb-3">
                        <b>ข้อมูลผลการตรวจสุขภาพ</b>
                      </h5>
                      <div className="col-12 d-flex align-items-center mb-3">
                        <span className="wpx-150">ผลการตรวจสุขภาพ</span>
                        <input
                          className="mt-1 me-3"
                          type="radio"
                          name="result_health"
                          onChange={(e) => {
                            this.setState({ result_health: "ผ่าน" });
                          }}
                          checked={this.state.result_health === "ผ่าน"}
                        />
                        ผ่าน
                        <input
                          className="mt-1 mx-3"
                          type="radio"
                          name="result_health"
                          onChange={(e) => {
                            this.setState({ result_health: "รอผลตรวจ", inspec_date: "", result_type: "", health_province: "", hospital_name: "", health_remark: "", diagnose_image: "" });
                          }}
                          checked={this.state.result_health === "รอผลตรวจ"}
                        />
                        รอผลตรวจ
                        <input
                          className="mt-1 mx-3"
                          type="radio"
                          name="result_health"
                          onChange={(e) => {
                            this.setState({ result_health: "ไม่ผ่าน" });
                          }}
                          checked={this.state.result_health === "ไม่ผ่าน"}
                        />
                        ไม่ผ่าน
                      </div>
                      {this.state.result_health && this.state.result_health !== "รอผลตรวจ" && (
                        <div className="row">
                          <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                            <span className="wpx-150">วันที่ตรวจ{required}</span>
                            <DatePicker
                              className="form-control mt-1"
                              placeholder="พิมพ์คำค้นหา..."
                              onChange={(e) => {
                                this.setState({ inspec_date: e });
                              }}
                              value={this.state.inspec_date}
                              max={new Date()}
                            />
                          </div>
                          <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                            <span className="wpx-150">ประเภทผลการตรวจ{required}</span>
                            <Select
                              className="form-control select-search"
                              styles={select_style}
                              options={this.state.data_result_type}
                              onChange={(e) => {
                                this.setState({ result_type: e });
                              }}
                              value={this.state.result_type}
                            ></Select>
                          </div>
                          <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                            <span className="wpx-150">จังหวัด{required}</span>
                            <Select
                              className="form-control select-search"
                              styles={select_style}
                              options={province.map((item) => {
                                return {
                                  value: item.province_name,
                                  label: item.province_name,
                                };
                              })}
                              onChange={(e) => {
                                this.setState({ health_province: e });
                              }}
                              value={this.state.health_province}
                            ></Select>
                          </div>
                          <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                            <span className="wpx-150">โรงพยาบาล{required}</span>
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                this.setState({ hospital_name: e.target.value });
                              }}
                              value={this.state.hospital_name}
                            />
                          </div>
                          <div className="col-12 d-flex align-items-start mb-3 mx-auto">
                            <span className="wpx-150">หมายเหตุ</span>
                            <textarea
                              className="form-control mt-1"
                              rows={5}
                              onChange={(e) => {
                                this.setState({ health_remark: e.target.value });
                              }}
                              value={this.state.health_remark}
                            />
                          </div>
                          <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                            <span className="wpx-150">แนบรูป</span>
                            <div className="d-flex">
                              {!this.state.diagnose_image ? (
                                <button
                                  className="btn btn-primary mx-1 px-4"
                                  onClick={() => {
                                    document.getElementById("file_diagnose_image").click();
                                  }}
                                >
                                  แนบไฟล์
                                </button>
                              ) : (
                                <div className="w-100 d-flex justify-content-center">
                                  <iframe src={this.state.diagnose_image.includes("://") ? this.state.diagnose_image : "data:application/pdf;base64," + this.state.diagnose_image} width="150" height="150"></iframe>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      this.setState({ modal_detail: true, detail_image: this.state.diagnose_image });
                                    }}
                                  >
                                    <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                  </div>
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      this.setState({
                                        modal_delete: true,
                                        attachment_name: "diagnose_image",
                                      });
                                    }}
                                  >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              )}
                              <input
                                className="d-none"
                                type="file"
                                name="file"
                                id="file_diagnose_image"
                                accept="application/pdf,image/*"
                                onChange={(e) => {
                                  this.import_file(e, "diagnose_image");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="card shadow border-0 mb-3" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <div className="row">
                    {/* เลือกประกัน */}
                    <h5 className="mb-3">
                      <b>ข้อมูลประกัน</b>
                    </h5>
                    <div className="d-flex align-items-center mb-3">
                      <span className="wpx-150">ประกันที่เลือก</span>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({
                              insurance_type: 1,
                              health_number: "",
                              health_hospital_name: "",
                              health_allowed_date: "",
                              health_expiry_date: "",
                              health_image: "",
                            });
                          }}
                          checked={this.state.insurance_type === 1}
                        />
                        <span className="ms-2">ประกันสุขภาพรัฐ</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({
                              insurance_type: 2,
                              insurance_name: "",
                              insurance_number: "",
                              insurance_allowed_date: "",
                              insurance_expiry_date: "",
                              insurance_image: "",
                            });
                          }}
                          checked={this.state.insurance_type === 2}
                        />
                        <span className="ms-2">ประกันภัยเอกชน</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({
                              insurance_type: 3,
                              sso_hospital_name: "",
                              sso_province: "",
                              sso_number: "",
                              sso_image: "",
                            });
                          }}
                          checked={this.state.insurance_type === 3}
                        />
                        <span className="ms-2">ประกันสังคม</span>
                      </div>
                    </div>
                    {/* ประกันสุขภาพรัฐ */}
                    {this.state.insurance_type === 1 && (
                      <>
                        {/* <h5 className="mb-3"><b>ประกันสุขภาพรัฐ</b></h5> */}
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขกรมธรรม์</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ health_number: e.target.value });
                            }}
                            value={this.state.health_number}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อโรงพยาบาล</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                health_hospital_name: e.target.value,
                              });
                            }}
                            value={this.state.health_hospital_name}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออก</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ health_allowed_date: e });
                            }}
                            value={this.state.health_allowed_date}
                            max={new Date()}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมด</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ health_expiry_date: e });
                            }}
                            value={this.state.health_expiry_date}
                            min={new Date()}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {!this.state.health_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_health_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.health_image.includes("://") ? this.state.health_image : "data:application/pdf;base64," + this.state.health_image} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, detail_image: this.state.health_image });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      modal_delete: true,
                                      attachment_name: "health_image",
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              name="file"
                              id="file_health_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "health_image");
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* ประกันภัยเอกชน */}
                    {this.state.insurance_type === 2 && (
                      <>
                        {/* <h5 className="mb-3"><b>ประกันภัยเอกชน</b></h5> */}
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อบริษัท</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_insurance}
                            onChange={(e) => {
                              this.setState({ insurance_name: e });
                            }}
                            value={this.state.insurance_name}
                          ></Select>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขกรมธรรม์</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                insurance_number: e.target.value,
                              });
                            }}
                            value={this.state.insurance_number}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออก</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ insurance_allowed_date: e });
                            }}
                            value={this.state.insurance_allowed_date}
                            max={new Date()}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมด</span>
                          <DatePicker
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ insurance_expiry_date: e });
                            }}
                            value={this.state.insurance_expiry_date}
                            min={new Date()}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {!this.state.insurance_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_insurance_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.insurance_image.includes("://") ? this.state.insurance_image : "data:application/pdf;base64," + this.state.insurance_image} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, detail_image: this.state.insurance_image });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      modal_delete: true,
                                      attachment_name: "insurance_image",
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              name="file"
                              id="file_insurance_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "insurance_image");
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* ประกันสังคม */}
                    {this.state.insurance_type === 3 && (
                      <>
                        {/* <h5 className="mb-3"><b>ประกันสังคม</b></h5> */}
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">เลขประกันสังคม</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({ sso_number: e.target.value });
                            }}
                            value={this.state.sso_number}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อโรงพยาบาล</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                sso_hospital_name: e.target.value,
                              });
                            }}
                            value={this.state.sso_hospital_name}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">จังหวัด</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={province.map((item) => {
                              return {
                                value: item.province_name,
                                label: item.province_name,
                              };
                            })}
                            onChange={(e) => {
                              this.setState({ sso_province: e });
                            }}
                            value={this.state.sso_province}
                          ></Select>
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">แนบรูป</span>
                          <div className="d-flex">
                            {!this.state.sso_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                onClick={() => {
                                  document.getElementById("file_sso_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-center">
                                <iframe src={this.state.sso_image.includes("://") ? this.state.sso_image : "data:application/pdf;base64," + this.state.sso_image} width="150" height="150"></iframe>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({ modal_detail: true, detail_image: this.state.sso_image });
                                  }}
                                >
                                  <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                </div>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    this.setState({
                                      modal_delete: true,
                                      attachment_name: "sso_image",
                                    });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                            <input
                              className="d-none"
                              type="file"
                              name="file"
                              id="file_sso_image"
                              accept="application/pdf,image/*"
                              onChange={(e) => {
                                this.import_file(e, "sso_image");
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={"row" + (window.location.href.includes("create") ? " create" : " update")}>
              <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                <div className="d-flex mt-5">
                  <div className="w-50 pe-1">
                    {this.state.tab === 1 ? (
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100 mb-3"
                        onClick={() => {
                          if (this.props.location.query.type === "receive") {
                            // success_back();
                            if (this.props.location.query.ids) {
                              window.location.href = "/Employer/update?id=" + this.props.location.query.ids + "&type=name_list";
                            } else if (this.props.location.query.receive_ids) {
                              window.location.href = "/Employer/update?receive_id=" + this.props.location.query.receive_ids + "&type=name_list&receive_codes=" + this.props.location.query.receive_codes + "&menu_id=" + this.props.location.query.menu_id + "&menu_name=" + this.props.location.query.menu_name;
                            }
                          } else if (this.props.location.query.type === "receive_all") {
                            window.location.href = "/RecieveAll/list";
                          } else {
                            window.location.href = "/Employee/list";
                          }
                          // window.location.href = "/Employee/list";
                        }}
                      >
                        ยกเลิก
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100 mb-3"
                        onClick={() => {
                          this.setState({ tab: this.state.tab - 1 });
                        }}
                      >
                        ย้อนกลับ
                      </button>
                    )}
                  </div>
                  <div className="w-50 ps-1">
                    <button
                      type="button"
                      className="btn btn-primary w-100 mb-3"
                      onClick={() => {
                        if ([3, 4, 5, 6].includes(Number(this.props.location.query.menu_id))) {
                          if (!this.state.place_work) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานที่ทำงาน");
                            return;
                          } else if (!this.state.fw_number) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัตรประจำตัว");
                            return;
                          } else if (!this.state.nationality) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกสัญชาติ");
                            return;
                          } else if (!this.state.prefix_th) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(ไทย)");
                            return;
                          } else if (!this.state.first_name_th) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(ไทย)");
                            return;
                          } else if (!this.state.prefix_en) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(Eng)");
                            return;
                          } else if (!this.state.first_name_en) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(Eng)");
                            return;
                          } else if (!this.state.gender) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกเพศ");
                            return;
                          } else if (!this.state.fw_entry_country_date) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เข้าประเทศ");
                            return;
                          } else if (!this.state.fw_entry_checkpoint) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกด่านที่เข้า");
                            return;
                          } else if (!this.state.passport_type) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทหนังสือเดินทาง");
                            return;
                          } else if (this.state.passport_type && this.state.passport_type !== "ไม่มีหนังสือเดินทาง") {
                            if (!this.state.passport_number) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่ passport");
                              return;
                            }
                          } else if (!this.state.visa_number) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่ตรวจลงตรา visa");
                            return;
                          } else if (!this.state.work_pm_number) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่ work permit");
                            return;
                          } else if (!this.state.notice_90d_number) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่ 90 วันที่เข้าประเทศไทย");
                            return;
                          }
                        } else if ([20].includes(Number(this.props.location.query.menu_id))) {
                          if (!this.state.prefix_th) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(ไทย)");
                            return;
                          } else if (!this.state.first_name_th) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(ไทย)");
                            return;
                          } else if (!this.state.prefix_en) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(Eng)");
                            return;
                          } else if (!this.state.first_name_en) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(Eng)");
                            return;
                          } else if (!this.state.birth_date) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกวันเกิด");
                            return;
                          } else if (!this.state.fw_housing_type) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกที่อยู่อาศัย");
                            return;
                          } else if (this.state.fw_housing_type && this.state.fw_housing_type === "ที่อื่น") {
                            if (!this.state.fw_building_name) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่/อาคาร ที่อยู่อาศัย");
                              return;
                            } else if (!this.state.province_name) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด ที่อยู่อาศัย");
                              return;
                            }
                          } else if (!this.state.passport_type) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทหนังสือเดินทาง");
                            return;
                          } else if (this.state.passport_type && this.state.passport_type !== "ไม่มีหนังสือเดินทาง") {
                            if (!this.state.passport_number) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่ passport");
                              return;
                            }
                          }
                        } else {
                          if (!this.state.fw_number) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกเลขบัตรประจำตัว");
                            return;
                          } else if (!this.state.nationality) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกสัญชาติ");
                            return;
                          } else if (!this.state.prefix_th) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(ไทย)");
                            return;
                          } else if (!this.state.first_name_th) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(ไทย)");
                            return;
                          } else if (!this.state.prefix_en) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า(Eng)");
                            return;
                          } else if (!this.state.first_name_en) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อต้น(Eng)");
                            return;
                          } else if (this.state.passport_type && this.state.passport_type !== "ไม่มีหนังสือเดินทาง") {
                            if (!this.state.passport_number) {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                              return;
                            }
                          }
                        }

                        if (this.state.result_health && this.state.result_health !== "รอผลตรวจ") {
                          if (!this.state.inspec_date) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกวันที่ตรวจ");
                            return;
                          } else if (!this.state.result_type) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทผลการตรวจ");
                            return;
                          } else if (!this.state.health_province) {
                            alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัดตรวจสุขภาพ");
                            return;
                          } else if (!this.state.hospital_name) {
                            alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อโรงพยาบาลตรวจสุขภาพ");
                            return;
                          }
                        }

                        if (this.props.location.query.id) {
                          this.Update();
                        } else {
                          this.Create();
                        }
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ดูข้อมูล */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="xl"
        >
          <Modal.Body className="p-2">
            <iframe src={this.state.detail_image.includes("://") ? this.state.detail_image : "data:application/pdf;base64," + this.state.detail_image} width="100%" height={window.innerHeight - 70 + "px"}></iframe>
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_detail: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* เพิ่มสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_create}
          onHide={() => {
            this.setState({ modal_create: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มสถานที่ทำงานใหม่</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ address_number: e.target.value });
                  }}
                  value={this.state.address_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขบ้าน ๑๑ หลัก</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ home_number: e.target.value });
                  }}
                  value={this.state.home_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">หมู่ที่</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ moo: e.target.value });
                  }}
                  value={this.state.moo}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ซอย</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ soi: e.target.value });
                  }}
                  value={this.state.soi}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ถนน</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ street: e.target.value });
                  }}
                  value={this.state.street}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">จังหวัด{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={province.map((item) => {
                    return {
                      value: item.province_name,
                      label: item.province_name,
                    };
                  })}
                  onChange={(e) => {
                    this.setState({
                      province: e,
                      district: null,
                      sub_district: null,
                      zip_code: "",
                    });
                  }}
                  value={this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150"> อำเภอ / เขต{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                  styles={select_style}
                  options={district
                    .filter((item) => this.state.province && this.state.province.value === item.province_name)
                    .map((item) => {
                      return {
                        value: item.district_name,
                        label: item.district_name,
                      };
                    })}
                  onChange={(e) => {
                    this.setState({
                      district: e,
                      sub_district: null,
                      zip_code: "",
                    });
                  }}
                  value={this.state.district}
                  isDisabled={!this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ตำบล / แขวง{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                  styles={select_style}
                  options={subdistrict
                    .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province && this.state.province.value === item.province_name)
                    .map((item) => {
                      return {
                        value: item.sub_district_name,
                        label: item.sub_district_name,
                        zip_code: item.zip_code,
                      };
                    })}
                  onChange={(e) => {
                    this.setState({
                      sub_district: e,
                      zipcode: e.zip_code.toString(),
                    });
                  }}
                  value={this.state.sub_district}
                  isDisabled={!this.state.district}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => {
                    this.setState({ zipcode: e.target.value });
                  }}
                  value={this.state.zipcode}
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">โทรศัพท์</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ phone_create: e.target.value });
                  }}
                  value={this.state.phone_create}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ email_create: e.target.value });
                  }}
                  value={this.state.email_create}
                />
              </div>
            </div>
            {/* <hr />
              <div className="d-flex align-items-center justify-content-between mb-3">
                  <span><span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)</span>
              </div>
              <div className="table-responsive">
                  <table className="table table-borderless table-striped">
                      <thead className="bg-primary">
                          <tr>
                              <th className="text-center text-white">ลำดับ</th>
                              <th className="text-center text-white">รายการ</th>
                              <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                              <th className="text-center text-white">ดำเนินการ</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td className="text-center">1</td>
                              <td className="text-start">หลักฐานการเพิ่มสถานที่ทำงานใหม่และแผนที่ เช่น<br />
                                  - สำเนาทะเบียนบ้าน หรือ<br />
                                  - สำเนาเช่าสำนักงาน/ร้านค้า/โรงงาน หรือ<br />
                                  - สัญญาจ้างงานของโครงการก่อสร้าง หรือ<br />
                                  - สำเนาโฉนดที่ดิน {required}</td>
                              <td className="text-center">{format_date(new Date(), "dd mmm yyyy")} เวลา {format_date(new Date(), "hh:mm")} น.</td>
                              <td className="text-center">
                                  <button className="btn btn-primary mx-1 px-4" onClick={() => { }}>
                                      แนบไฟล์
                                  </button>
                                  <div className="w-100 d-flex justify-content-center">
                                      <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                          onClick={() => { this.setState({ modal_detail: true, attachment_id: "", attachment_name: "", attachment_file: "" }) }}>
                                          <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                      </div>
                                      <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                          onClick={() => { this.setState({ modal_delete: true, attachment_id: "", attachment_name: "" }) }}>
                                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <span className="d-flex align-items-center">หมายเหตุ :
                  <div className="ms-3 me-2 wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center">
                      <label className="icon text-primary p-2">{"\uf15c"}</label>
                  </div>
                  แสดงเอกสารแนบ
                  <div className="ms-5 me-2 wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center">
                      <label className="icon text-danger p-2">{"\uf2ed"}</label>
                  </div>
                  ลบเอกสาร
              </span> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.address_number === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                      return;
                    } else if (!this.state.province) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                      return;
                    } else if (!this.state.district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                      return;
                    } else if (!this.state.sub_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                      return;
                    } else if (this.state.zipcode === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                      return;
                    }
                    this.CreateWorkplace();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มประวัติการต่ออายุ */}
        <Modal
          show={this.state.modal_add_regis}
          onHide={() => {
            this.setState({ modal_add_regis: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มประวัติการต่ออายุ</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <input
              className="d-none"
              type="file"
              name="file"
              id="file_edit_passport_image"
              accept="application/pdf,image/*"
              onChange={(e) => {
                this.import_file(e, "edit_passport_image");
              }}
            />
            <input
              className="d-none"
              type="file"
              name="file"
              id="file_edit_visa_image"
              accept="application/pdf,image/*"
              onChange={(e) => {
                this.import_file(e, "edit_visa_image");
              }}
            />
            <input
              className="d-none"
              type="file"
              name="file"
              id="file_edit_work_pm_image"
              accept="application/pdf,image/*"
              onChange={(e) => {
                this.import_file(e, "edit_work_pm_image");
              }}
            />
            <input
              className="d-none"
              type="file"
              name="file"
              id="file_edit_notice_90d_image"
              accept="application/pdf,image/*"
              onChange={(e) => {
                this.import_file(e, "edit_notice_90d_image");
              }}
            />
            <h4 className="fw-bold">Passport</h4>
            <div className="row mt-3">
              <div className="col-12 d-flex align-items-center mb-3">
                <span className="wpx-150">ประเภทหนังสือเดินทาง{required}</span>
                <input
                  className="mt-1 mx-3"
                  type="radio"
                  name="edit_passport_type"
                  onChange={(e) => {
                    this.setState({
                      edit_passport_type: "ไม่มีหนังสือเดินทาง",
                      edit_passport_number: "",
                      edit_passport_place: "",
                      edit_passport_allowed_date: "",
                      edit_passport_expiry_date: "",
                      edit_passport_image: "",
                    });
                  }}
                  checked={this.state.edit_passport_type === "ไม่มีหนังสือเดินทาง"}
                />
                ไม่มีหนังสือเดินทาง
                <input
                  className="mt-1 me-2 ms-5"
                  type="radio"
                  name="edit_passport_type"
                  onChange={(e) => {
                    this.setState({ edit_passport_type: "Passport" });
                  }}
                  checked={this.state.edit_passport_type === "Passport"}
                />
                Passport
                <input
                  className="mt-1 me-2 ms-5"
                  type="radio"
                  name="edit_passport_type"
                  onChange={(e) => {
                    this.setState({ edit_passport_type: "TD" });
                  }}
                  checked={this.state.edit_passport_type === "TD"}
                />
                TD
                <input
                  className="mt-1 me-2 ms-5"
                  type="radio"
                  name="edit_passport_type"
                  onChange={(e) => {
                    this.setState({ edit_passport_type: "CI" });
                  }}
                  checked={this.state.edit_passport_type === "CI"}
                />
                CI
                <input
                  className="mt-1 me-2 ms-5"
                  type="radio"
                  name="edit_passport_type"
                  onChange={(e) => {
                    this.setState({ edit_passport_type: "TP" });
                  }}
                  checked={this.state.edit_passport_type === "TP"}
                />
                TP
              </div>
            </div>
            {this.state.edit_passport_type !== "ไม่มีหนังสือเดินทาง" && (
              <div className="row mt-3">
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                  <span className="wpx-120">เลขที่{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                  <input
                    className="form-control mt-1"
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        edit_passport_number: e.target.value,
                      });
                    }}
                    value={this.state.edit_passport_number}
                  />
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                  <span className="wpx-120">สถานที่ออก{required}</span>
                  <input
                    className="form-control mt-1"
                    type="text"
                    onChange={(e) => {
                      this.setState({ edit_passport_place: e.target.value });
                    }}
                    value={this.state.edit_passport_place}
                  />
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                  <span className="wpx-120">วันที่ออก{required}</span>
                  <input
                    className="form-control mt-1"
                    type="date"
                    onChange={(e) => {
                      this.setState({ edit_passport_allowed_date: e.target.value });
                    }}
                    value={this.state.edit_passport_allowed_date}
                  />
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                  <span className="wpx-120">วันที่หมด{required}</span>
                  <input
                    className="form-control mt-1"
                    type="date"
                    onChange={(e) => {
                      this.setState({ edit_passport_expiry_date: e.target.value });
                    }}
                    value={this.state.edit_passport_expiry_date}
                  />
                </div>
                <div className="col-12 d-flex align-items-center mb-3">
                  <span className="wpx-120">แนบรูป</span>
                  <div className="d-flex">
                    {!this.state.edit_passport_image ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        onClick={() => {
                          document.getElementById("file_edit_passport_image").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-center">
                        <iframe src={this.state.edit_passport_image.includes("://") ? this.state.edit_passport_image : "data:application/pdf;base64," + this.state.edit_passport_image} width="150" height="150"></iframe>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ modal_detail: true, detail_image: this.state.edit_passport_image });
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({
                              edit_passport_image: ""
                            });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                    <input
                      className="d-none"
                      type="file"
                      name="file"
                      id="file_passport_image"
                      accept="application/pdf,image/*"
                      onChange={(e) => {
                        this.import_file(e, "passport_image");
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <h4 className="fw-bold">Visa</h4>
            <div className="row mt-3">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">เลขที่ตรวจลงตรา{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ edit_visa_number: e.target.value });
                  }}
                  value={this.state.edit_visa_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">สถานที่ต่ออายุ{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={this.state.data_immigration_office}
                  onChange={(e) => {
                    this.setState({ edit_visa_allowed_location: e });
                  }}
                  value={this.state.edit_visa_allowed_location}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">วันที่ออก{required}</span>
                <input
                  className="form-control mt-1"
                  type="date"
                  onChange={(e) => {
                    this.setState({ edit_visa_allowed_date: e.target.value });
                  }}
                  value={this.state.edit_visa_allowed_date}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">วันที่หมด{required}</span>
                <input
                  className="form-control mt-1"
                  type="date"
                  onChange={(e) => {
                    this.setState({ edit_visa_expiry_date: e.target.value });
                  }}
                  value={this.state.edit_visa_expiry_date}
                />
              </div>
              <div className="col-12 d-flex align-items-center mb-3">
                <span className="wpx-120">แนบรูป</span>
                <div className="d-flex">
                  {!this.state.edit_visa_image ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      onClick={() => {
                        document.getElementById("file_edit_visa_image").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <iframe src={this.state.edit_visa_image.includes("://") ? this.state.edit_visa_image : "data:application/pdf;base64," + this.state.edit_visa_image} width="150" height="150"></iframe>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          this.setState({ modal_detail: true, detail_image: this.state.edit_visa_image });
                        }}
                      >
                        <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                      </div>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          this.setState({
                            edit_visa_image: ""
                          });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                  <input
                    className="d-none"
                    type="file"
                    name="file"
                    id="file_passport_image"
                    accept="application/pdf,image/*"
                    onChange={(e) => {
                      this.import_file(e, "passport_image");
                    }}
                  />
                </div>
              </div>
            </div>
            <h4 className="fw-bold">Work Permit</h4>
            <div className="row mt-3">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">เลขที่{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ edit_work_pm_number: e.target.value });
                  }}
                  value={this.state.edit_work_pm_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">สถานที่ต่ออายุ{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={this.state.data_immigration_office}
                  onChange={(e) => {
                    this.setState({ edit_work_pm_allowed_location: e });
                  }}
                  value={this.state.edit_work_pm_allowed_location}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">วันที่ออก{required}</span>
                <input
                  className="form-control mt-1"
                  type="date"
                  onChange={(e) => {
                    this.setState({ edit_work_pm_allowed_date: e.target.value });
                  }}
                  value={this.state.edit_work_pm_allowed_date}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">วันที่หมด{required}</span>
                <input
                  className="form-control mt-1"
                  type="date"
                  onChange={(e) => {
                    this.setState({ edit_work_pm_expiry_date: e.target.value });
                  }}
                  value={this.state.edit_work_pm_expiry_date}
                />
              </div>
              <div className="col-12 d-flex align-items-center mb-3">
                <span className="wpx-120">แนบรูป</span>
                <div className="d-flex">
                  {!this.state.edit_work_pm_image ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      onClick={() => {
                        document.getElementById("file_edit_work_pm_image").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <iframe src={this.state.edit_work_pm_image.includes("://") ? this.state.edit_work_pm_image : "data:application/pdf;base64," + this.state.edit_work_pm_image} width="150" height="150"></iframe>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          this.setState({ modal_detail: true, detail_image: this.state.edit_work_pm_image });
                        }}
                      >
                        <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                      </div>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          this.setState({
                            edit_work_pm_image: ""
                          });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                  <input
                    className="d-none"
                    type="file"
                    name="file"
                    id="file_passport_image"
                    accept="application/pdf,image/*"
                    onChange={(e) => {
                      this.import_file(e, "passport_image");
                    }}
                  />
                </div>
              </div>
            </div>
            <h4 className="fw-bold">แจ้ง 90 วันที่เข้าประเทศไทย</h4>
            <div className="row mt-3">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">เลขที่{[3, 4, 5, 6].includes(Number(this.props.location.query.menu_id)) && required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ edit_notice_90d_number: e.target.value });
                  }}
                  value={this.state.edit_notice_90d_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">ด่านที่เข้า{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={this.state.data_immigration_office}
                  onChange={(e) => {
                    this.setState({ edit_notice_90d_allowed_location: e });
                  }}
                  value={this.state.edit_notice_90d_allowed_location}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">วันที่แจ้ง{required}</span>
                <input
                  className="form-control mt-1"
                  type="date"
                  onChange={(e) => {
                    this.setState({ edit_notice_90d_allowed_date: e.target.value });
                  }}
                  value={this.state.edit_notice_90d_allowed_date}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-120">วันที่ครบกำหนด 90 วัน{required}</span>
                <input
                  className="form-control mt-1"
                  type="date"
                  onChange={(e) => {
                    this.setState({ edit_notice_90d_expiry_date: e.target.value });
                  }}
                  value={this.state.edit_notice_90d_expiry_date}
                />
              </div>
              <div className="col-12 d-flex align-items-center mb-3">
                <span className="wpx-120">แนบรูป</span>
                <div className="d-flex">
                  {!this.state.edit_notice_90d_image ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      onClick={() => {
                        document.getElementById("file_edit_notice_90d_image").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-center">
                      <iframe src={this.state.edit_notice_90d_image.includes("://") ? this.state.edit_notice_90d_image : "data:application/pdf;base64," + this.state.edit_notice_90d_image} width="150" height="150"></iframe>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          this.setState({ modal_detail: true, detail_image: this.state.edit_notice_90d_image });
                        }}
                      >
                        <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                      </div>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          this.setState({
                            edit_notice_90d_image: ""
                          });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                  <input
                    className="d-none"
                    type="file"
                    name="file"
                    id="file_passport_image"
                    accept="application/pdf,image/*"
                    onChange={(e) => {
                      this.import_file(e, "passport_image");
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_add_regis: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.edit_index === -1) {
                      let document_history = this.state.document_history;
                      if(document_history.length !== 0){
                        this.setState({passport_old_image: document_history[document_history.length-1].passport_image})
                      }
                      document_history.push({
                        passport_type: this.state.edit_passport_type,
                        passport_number: this.state.edit_passport_number,
                        visa_number: this.state.edit_visa_number,
                        work_pm_number: this.state.edit_work_pm_number,
                        notice_90d_number: this.state.edit_notice_90d_number,
                        passport_image: this.state.edit_passport_image,
                        passport_place: this.state.edit_passport_place,
                        passport_allowed_date: this.state.edit_passport_allowed_date,
                        passport_expiry_date: this.state.edit_passport_expiry_date,
                        visa_image: this.state.edit_visa_image,
                        visa_allowed_location: this.state.edit_visa_allowed_location ? this.state.edit_visa_allowed_location.label : "",
                        visa_allowed_date: this.state.edit_visa_allowed_date,
                        visa_expiry_date: this.state.edit_visa_expiry_date,
                        work_pm_image: this.state.edit_work_pm_image,
                        work_pm_allowed_location: this.state.edit_work_pm_allowed_location ? this.state.edit_work_pm_allowed_location.label : "",
                        work_pm_allowed_date: this.state.edit_work_pm_allowed_date,
                        work_pm_expiry_date: this.state.edit_work_pm_expiry_date,
                        notice_90d_image: this.state.edit_notice_90d_image,
                        notice_90d_allowed_location: this.state.edit_notice_90d_allowed_location ? this.state.edit_notice_90d_allowed_location.label : "",
                        notice_90d_allowed_date: this.state.edit_notice_90d_allowed_date,
                        notice_90d_expiry_date: this.state.edit_notice_90d_expiry_date,
                      });
                      this.setState({
                        document_history: document_history,
                        modal_add_regis: false,
                        passport_type: this.state.edit_passport_type,
                        passport_number: this.state.edit_passport_number,
                        visa_number: this.state.edit_visa_number,
                        work_pm_number: this.state.edit_work_pm_number,
                        notice_90d_number: this.state.edit_notice_90d_number,
                        passport_image: this.state.edit_passport_image,
                        passport_place: this.state.edit_passport_place,
                        passport_allowed_date: this.state.edit_passport_allowed_date,
                        passport_expiry_date: this.state.edit_passport_expiry_date,
                        visa_image: this.state.edit_visa_image,
                        visa_allowed_location: this.state.edit_visa_allowed_location,
                        visa_allowed_date: this.state.edit_visa_allowed_date,
                        visa_expiry_date: this.state.edit_visa_expiry_date,
                        work_pm_image: this.state.edit_work_pm_image,
                        work_pm_allowed_location: this.state.edit_work_pm_allowed_location,
                        work_pm_allowed_date: this.state.edit_work_pm_allowed_date,
                        work_pm_expiry_date: this.state.edit_work_pm_expiry_date,
                        notice_90d_image: this.state.edit_notice_90d_image,
                        notice_90d_allowed_location: this.state.edit_notice_90d_allowed_location,
                        notice_90d_allowed_date: this.state.edit_notice_90d_allowed_date,
                        notice_90d_expiry_date: this.state.edit_notice_90d_expiry_date,
                      });
                    } else {
                      let document_history = this.state.document_history;
                      document_history[this.state.edit_index].passport_type = this.state.edit_passport_type;
                      document_history[this.state.edit_index].passport_number = this.state.edit_passport_number;
                      document_history[this.state.edit_index].visa_number = this.state.edit_visa_number;
                      document_history[this.state.edit_index].work_pm_number = this.state.edit_work_pm_number;
                      document_history[this.state.edit_index].notice_90d_number = this.state.edit_notice_90d_number;
                      document_history[this.state.edit_index].passport_image = this.state.edit_passport_image;
                      document_history[this.state.edit_index].visa_image = this.state.edit_visa_image;
                      document_history[this.state.edit_index].work_pm_image = this.state.edit_work_pm_image;
                      document_history[this.state.edit_index].notice_90d_image = this.state.edit_notice_90d_image;
                      document_history[this.state.edit_index].passport_place = this.state.edit_passport_place;
                      document_history[this.state.edit_index].passport_allowed_date = this.state.edit_passport_allowed_date;
                      document_history[this.state.edit_index].passport_expiry_date = this.state.edit_passport_expiry_date;
                      document_history[this.state.edit_index].visa_allowed_location = this.state.edit_visa_allowed_location ? this.state.edit_visa_allowed_location.label : "";
                      document_history[this.state.edit_index].visa_allowed_date = this.state.edit_visa_allowed_date;
                      document_history[this.state.edit_index].visa_expiry_date = this.state.edit_visa_expiry_date;
                      document_history[this.state.edit_index].work_pm_allowed_location = this.state.edit_work_pm_allowed_location ? this.state.edit_work_pm_allowed_location.label : "";
                      document_history[this.state.edit_index].work_pm_allowed_date = this.state.edit_work_pm_allowed_date;
                      document_history[this.state.edit_index].work_pm_expiry_date = this.state.edit_work_pm_expiry_date;
                      document_history[this.state.edit_index].notice_90d_allowed_location = this.state.edit_notice_90d_allowed_location ? this.state.edit_notice_90d_allowed_location.label : "";
                      document_history[this.state.edit_index].notice_90d_allowed_date = this.state.edit_notice_90d_allowed_date;
                      document_history[this.state.edit_index].notice_90d_expiry_date = this.state.edit_notice_90d_expiry_dat;
                      this.setState({
                        document_history: document_history,
                        modal_add_regis: false,
                      });
                      if(document_history.length - 1 === this.state.edit_index){
                      this.setState({
                        passport_type: this.state.edit_passport_type,
                        passport_number: this.state.edit_passport_number,
                        visa_number: this.state.edit_visa_number,
                        work_pm_number: this.state.edit_work_pm_number,
                        notice_90d_number: this.state.edit_notice_90d_number,
                        passport_image: this.state.edit_passport_image,
                        passport_place: this.state.edit_passport_place,
                        passport_allowed_date: this.state.edit_passport_allowed_date,
                        passport_expiry_date: this.state.edit_passport_expiry_date,
                        visa_image: this.state.edit_visa_image,
                        visa_allowed_location: this.state.edit_visa_allowed_location,
                        visa_allowed_date: this.state.edit_visa_allowed_date,
                        visa_expiry_date: this.state.edit_visa_expiry_date,
                        work_pm_image: this.state.edit_work_pm_image,
                        work_pm_allowed_location: this.state.edit_work_pm_allowed_location,
                        work_pm_allowed_date: this.state.edit_work_pm_allowed_date,
                        work_pm_expiry_date: this.state.edit_work_pm_expiry_date,
                        notice_90d_image: this.state.edit_notice_90d_image,
                        notice_90d_allowed_location: this.state.edit_notice_90d_allowed_location,
                        notice_90d_allowed_date: this.state.edit_notice_90d_allowed_date,
                        notice_90d_expiry_date: this.state.edit_notice_90d_expiry_date,
                      });
                    }
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มตัวแทน */}
        <Modal
          show={this.state.modal_agent_create}
          onHide={() => {
            this.setState({ modal_agent_create: false });
          }}
          size="md"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มตัวแทน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ agent_name: e.target.value });
                  }}
                  value={this.state.agent_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ agent_phone: e.target.value });
                  }}
                  value={this.state.agent_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ agent_email: e.target.value });
                  }}
                  value={this.state.agent_email}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_agent_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.agent_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.agent_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    }
                    this.CreateAgent();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบเอกสารแนบ</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบเอกสารแนบ “{this.state.attachment_name === "profile_image" ? "รูปถ่ายของลูกจ้าง" : this.state.attachment_name === "identity_document" ? "หลักฐานประจำตัวลูกจ้าง" : this.state.attachment_name === "evidence_allowed_document" ? "สำเนาหลักฐานการอนุญาตให้เข้ามาในราชอาณาจักร ทุกหน้าที่มีตราประทับ" : this.state.attachment_name === "consent_document" ? "หนังสือยินยอมจากเจ้าบ้าน" : "รูปภาพ"}” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    if (this.state.attachment_name === "profile_image") {
                      this.setState({
                        profile_image: "",
                        profile_image_type: "",
                        profile_image_date: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "identity_document") {
                      this.setState({
                        identity_document: "",
                        identity_document_type: "",
                        identity_document_date: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "evidence_allowed_document") {
                      this.setState({
                        evidence_allowed_document: "",
                        evidence_allowed_document_type: "",
                        evidence_allowed_document_date: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "consent_document") {
                      this.setState({
                        consent_document: "",
                        consent_document_type: "",
                        consent_document_date: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "passport_image") {
                      this.setState({
                        passport_image: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "visa_image") {
                      this.setState({ visa_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "work_pm_image") {
                      this.setState({ work_pm_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "notice_90d_image") {
                      this.setState({ notice_90d_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "health_image") {
                      this.setState({ health_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "insurance_image") {
                      this.setState({
                        insurance_image: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "sso_image") {
                      this.setState({ sso_image: "", modal_delete: false });
                    } else if (this.state.attachment_name === "diagnose_image") {
                      this.setState({
                        diagnose_image: "",
                        modal_delete: false,
                      });
                    } else if (this.state.attachment_name === "policy_image") {
                      this.setState({ policy_image: "", modal_delete: false });
                    }
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
  document_invoice,
  document_invoice_by_id
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_print: false,
      loading: false,
      data: [],
      data_header: null,

      data_search: "",
      datetime_search: "",
      page: 0,

      datetime_create: new Date(), //วันที่สร้าง
      full_name: "", //ลูกค้า
      invoice_type: "", //บริการที่แจ้งหนี้
      nationality: "", //สัญชาติ
      quantity: "", //จำนวน
      list_service: [], //ค่าบริการ
      total_price: 0, //รวมเป็นเงิน
      emp_data: null, //นายจ้าง
    };
  }
  async componentDidMount() {
    await this.Get();
    await this.GetHeaderDoc();
  }
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      datetime_search: this.state.datetime_search,
    };
    let result = await GET(tokens, "api/invoice/list/page/" + this.state.page, body);
    if (result && result.status) {
      result.data = result.data.filter(e => { return e.status === 1})
      console.log(result.data)
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };
  GetHeaderDoc = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "api/header-doc/list");
    if (result && result.status) {
      this.setState({
        data_header: result.data
      });
    }
    this.setState({ loading: false });
  };
  GetbyID = async (item) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: item.receive_id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    console.log(result)
    result.data.rate_list = result.data.rate_list.map(e=>{return {
      ...e,
      qty_price : e.qty_price - e.discount
    }})
    let obj = {
      ...item,
      receive_data: result.data
    }
    console.log("OBJ:",obj)
    console.log("this.state.data_header:",this.state.data_header)
    document_invoice_by_id(obj, this.state.data_header)
    this.setState({ loading: false });
  }
  Create = async () => {
    let body = {
        invoice_list: this.state.data.filter(e=>e.selected).map(item=>{return {
        invoice_id: item.invoice_id,
        createdAt:item.createdAt,
        invoice_code:item.invoice_code,
        receive_code: item.receive_code,
        quotation_id: item.quotation_id,
        receive_id: item.receive_id,
        full_name: item.full_name,
        menu_name: item.menu_name,
        create_by: item.create_by,
        total_price: (total(JSON.parse(item.installment).filter(e=>e.selected),"amount"))
      }})
    };
    let result = await POST(tokens, "api/billing-note/create", body);
    if (result && result.status) {
      success_url("/BillingNote/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="รายการใบแจ้งหนี้" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการใบแจ้งหนี้</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3 align-items-center">
                <span className="me-3 mb-3">ค้นหา</span>
                <input
                  className="form-control wpx-250 bg-contain border-0 me-3 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <span className="me-3 mb-3">ปี / เดือน</span>
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="month"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ datetime_search: e.target.value });
                  }}
                  value={this.state.datetime_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "",datetime_search:"" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="mb-3">
                <button
                  className="btn btn-orange mx-1 update"
                  onClick={() => {
                    if(this.state.data && this.state.data.filter(e=>e.selected).length === 0){
                      alert("warning","แจ้งเตือน","ยังไม่ได้เลือกใบแจ้งหนี้")
                      return
                    }
                    this.setState({modal_create: true})
                  }}
                >
                  สร้าง Billing Note
                </button>
                <button
                  className="btn btn-info px-4 mx-1"
                  onClick={() => {
                    if(this.state.data && this.state.data.filter(e=>e.selected).length === 0){
                      alert("warning","แจ้งเตือน","ยังไม่ได้เลือกใบแจ้งหนี้")
                      return
                    }
                    this.setState({modal_print: true})
                  }}
                >
                  สร้างใบปะหน้า
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">#</th>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่สร้าง</th>
                      <th className="text-left text-white">เลขที่ใบแจ้งหนี้</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">บริการ</th>
                      <th className="text-left text-white">จำนวนลูกจ้าง</th>
                      <th className="text-left text-white">ผู้ขาย</th>
                      <th className="text-left text-white">ตัวแทน</th>
                      <th className="text-left text-white">สถานะ</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">
                          <input type="checkbox" checked={item.selected} onChange={()=>{
                            let data = this.state.data;
                            data[index].selected = data[index].selected ? !data[index].selected : true
                            this.setState({data: data})
                          }}/>
                          </td>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.invoice_code ? item.invoice_code : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                          <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                          <td className="text-left">{item.total_qty ? item.total_qty : "0"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">{item.broker_name ? item.broker_name : "-"}</td>
                          <td className="text-left">{item.status === 1 ?"รอรับเงิน" : "รับเงินแล้ว"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  console.log(item)
                                  this.GetbyID(item)
                                 
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf1c1"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* ยืนยันสร้างรายการ Billing Note */}
        <Modal
          show={this.state.modal_create}
          onHide={() => {
            this.setState({ modal_create: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>ยืนยันสร้างรายการ Billing Note</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              เลือกรายการใบแจ้งหนี้ <b className="mx-2">{this.state.data && this.state.data.filter(e=>e.selected).length}</b> รายการ
            </label>
            <div className="table-responsive">
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-left text-white">#</th>
                    <th className="text-left text-white">วันที่สร้าง</th>
                    <th className="text-left text-white">เลขที่ใบแจ้งหนี้</th>
                    <th className="text-left text-white">เลขเรื่อง</th>
                    <th className="text-left text-white">ลูกค้า</th>
                    <th className="text-left text-white">บริการ</th>
                    <th className="text-center text-white">ค่าบริการ</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data &&
                    this.state.data.filter(e=>e.selected).map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.invoice_code ? item.invoice_code : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                          <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                          <td className="text-left">{item.installment ? toFixed(total(JSON.parse(item.installment).filter(e=>e.selected),"amount")) : "0.00"}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Create();
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ใบปะหน้า */}
        <Modal
          show={this.state.modal_print}
          onHide={() => {
            this.setState({ modal_print: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>สรุปใบแจ้งหนี้</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-center text-white">ลำดับ</th>
                    <th className="text-left text-white">วันที่รับงาน</th>
                    <th className="text-left text-white">ตัวแทน</th>
                    <th className="text-left text-white">ชื่อนายจ้าง</th>
                    <th className="text-left text-white">จำนวน</th>
                    <th className="text-left text-white">เลขที่รับเรื่อง</th>
                    <th className="text-left text-white">เรื่องที่ดำเนินการ</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data &&
                    this.state.data.filter(e=>e.selected).map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                        <td className="text-left">{item.broker_name ? item.broker_name : "-"}</td>
                        <td className="text-left"></td>
                        <td className="text-center">{item.total_qty ? item.total_qty : 0}</td>
                        <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                        <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                      </tr>
                    ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>รวม</td>
                        <td className="text-center">{this.state.data ? total(this.state.data.filter(e=>e.selected),"total_qty"):0}</td>
                        <td></td>
                        <td></td>
                      </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_print: false });
                  }}
                >
                  ปิด
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    document_invoice(this.state.data.filter(e=>e.selected));
                  }}
                >
                  พิมพ์เอกสาร
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  format_date_excel,
  tokens,
  days_between,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_bt46,
  document_contact,
  document_receipt_by_person,
  document_receipt,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_match: false,
      modal_update: false,
      modal_update_6: false,
      modal_update_7: false,
      modal_update_8: false,
      modal_update_11: false,
      modal_update_13: false,
      modal_match2: false,
      modal_download: false,
      loading: false,
      data: [],
      data_menu_work: [],

      data_immigration_office: [],
      data_user: [],
      search: "",

      data_search: "",
      status_search: "",

      select_90d_date: "",
      select_visa_date: "",
      select_work_date: "",
      count_e_work: 0,
      count_wp: 0,
      fw_data: [],
      checklist_data: [],
      receive_id: "",
      receive_code: "",
      receive_date: null,
      branch: "",
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      emp_number: "",
      process_matter: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: null,
      c_certificate_20: null,
      c_copy_committee: null,
      receive_status: 0,
      receive_date_s1: null,
      receive_by_s1: null,
      receive_date_s2: null,
      receive_by_s2: null,
      receive_date_s3: null,
      receive_by_s3: null,
      receive_date_s4: null,
      receive_by_s4: null,
      receive_date_s5: null,
      receive_by_s5: null,
      receive_date_s6: null,
      receive_by_s6: null,
      receive_date_s7: null,
      receive_by_s7: null,
      receive_date_s8: null,
      receive_by_s8: null,
      receive_date_s9: null,
      receive_by_s9: null,
      receive_date_s10: null,
      receive_by_s10: null,
      receive_date_s11: null,
      receive_by_s11: null,
      receive_date_s12: null,
      receive_by_s12: null,
      receive_date_s13: null,
      receive_by_s13: null,
      receive_date_s14: null,
      receive_by_s14: null,
      receive_date_s15: null,
      receive_by_s15: null,
      receive_date_s16: null,
      receive_by_s16: null,
      receive_date_s17: null,
      receive_by_s17: null,
      receive_date_s18: null,
      receive_by_s18: null,
      receive_date_s19: null,
      receive_by_s19: null,
      receive_date_s20: null,
      receive_by_s20: null,
      receive_date_s21: null,
      receive_by_s21: null,
      receive_status_date: null,
      receive_status_by: null,
      is_active: true,
      create_by: "",
      emp_data: null,
      menu_data: null,

      check_received_e_work: false,

      check_close: false,

      deliver_documents_s9: null,
      sending_document_date_s9: null,
      sending_messenger_s9: null,
      remark_s9: "",
      documents_s9: null,

      deliver_documents_s16: null,
      sending_document_date_s16: null,
      sending_messenger_s16: null,
      remark_s16: "",
      documents_s16: null,

      deliver_documents_s19: null,
      sending_document_date_s19: null,
      sending_messenger_s19: null,
      remark_s19: "",
      documents_s19: null,
      remark_s20: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,

      show_tab: 0,

      data_business_type: [],
      data_deputize: [],
      count_status: null,

      status_group: "",
      data_salary: [],
      data_post_box: [],

      data_workplace: [],

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      documents_employer_s22: "",
      documents_power_s22: "",
      documents_sub_power_s22: "",
      documents_scan_name: "",
      documents_employer_receipt: "",
      documents_employer_receipt_after: "",

      data_work_type: [],

      admin_fullname: "",

      rate_list: [],
      due_date: "",
      due_location: "",
      due_remark: "",
      save_name_list: "",

      installment: [],

      data_header: null,
      payment_person: "",

      house_number: "",
      due_time: "",
      due_province: "",
      due_district: "",
      bt_52_document_before: "",
      bt_52_document_after: "",
      bt_53_document_before: "",
      bt_53_document_after: "",
      bt_55_document: "",
      power_attorney_document: "",
      calling_visa_document: "",
      consent_document: "",
      house_registration_document: "",

      report_out: "",
      post_box_no: [
        { document_name: "สำเนาเอกสารนายจ้าง", no: "", remark: "" },
        { document_name: "สำเนาเอกสารลูกจ้าง", no: "", remark: "" },
        { document_name: "เอกสารการเงิน", no: "", remark: "" },
      ],
    };
  }
  async componentDidMount() {
    let data = localStorage.getItem("detail_user") ? localStorage.getItem("detail_user") : localStorage.getItem("user");
    data = data ? JSON.parse(data) : null;
    if (data) {
      this.setState({ admin_fullname: data.full_name });
    }
    this.setState({ loading: true });
    await this.Get();
    this.setState({ loading: false });
    await this.GetImmigration();
    await this.GetAllData();
    await this.GetUserAll();
  }
  // GET
  Get = async () => {
    let body = {
      data_search: this.state.data_search,
      receive_id: "",
      menu: "MOU",
      status_group: this.state.status_group,
      menu_id: Number(this.state.status_search),
    };
    let result = await GET(tokens, "api/receive-matter/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
  };
  GetUserAll = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({ data_user: result.data.filter((e) => e.department === "แมสเซนเจอร์") });
    }
  };
  GetImmigration = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_immigration_office: result.data.map((e) => {
          return { value: e.id.toString(), label: e.imm_name_th };
        }),
      });
    }
  };
  GetAllData = async () => {
    let result = await GET(tokens, "api/header-doc/list");
    if (result && result.status) {
      this.setState({
        data_header: result.data,
      });
    }
    result = await GET(tokens, "api/receive-matter-menu/list/page/" + this.state.page, {
      data_search: [],
      menu_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data,
      });
    }
    result = await GET(tokens, "api/setting/business-type/list/page/0", {
      data_search: "",
      business_type_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
    result = await GET(tokens, "api/setting/work-type/list/page/0", {
      data_search: "",
      work_type_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
    result = await GET(tokens, "api/setting/prefix/list/page/0", {
      data_search: "",
      prefix_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
    result = await GET(tokens, "api/user/list/page/0", {
      data_search: "",
      user_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
    result = await GET(tokens, "api/minimum-wage/list", {
      data_search: "",
      minimum_wage_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_salary: result.data,
      });
    }
    result = await GET(tokens, "api/setting/cabinet-number/list", {
      data_search: "",
      cabinet_number_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_post_box: result.data,
      });
    }
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
          };
        }),
      });
    }
  };
  GetByID = async (id) => {
    this.setState({
      loading: true,
      select_90d_date: "",
      select_visa_date: "",
      select_work_date: "",
    });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      console.log(result.data);
      this.setState({
        installment: result.data.installment,
        count_e_work: result.data.count_e_work,
        count_wp: result.data.count_wp,
        checklist_data: result.data.checklist_data,
        fw_data: result.data.fw_data.map((e) => {
          return {
            ...e,
            visa_allowed_date: e.visa_allowed_date ? e.visa_allowed_date.split("T")[0] : "",
            visa_expiry_date: e.visa_expiry_date ? e.visa_expiry_date.split("T")[0] : "",
          };
        }),
        receive_id: result.data.receive_id,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch,
        immigration_office: result.data.immigration_office,
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.broker,
        payment_person: result.data.payment_person,
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        r_username: result.data.r_username,
        r_password: result.data.r_password,
        receive_status: result.data.receive_status,
        receive_date_s1: result.data.receive_date_s1,
        receive_by_s1: result.data.receive_by_s1,
        receive_date_s2: result.data.receive_date_s2,
        receive_by_s2: result.data.receive_by_s2,
        receive_date_s3: result.data.receive_date_s3,
        receive_by_s3: result.data.receive_by_s3,
        receive_date_s4: result.data.receive_date_s4,
        receive_by_s4: result.data.receive_by_s4,
        receive_date_s5: result.data.receive_date_s5,
        receive_by_s5: result.data.receive_by_s5,
        receive_date_s6: result.data.receive_date_s6,
        receive_by_s6: result.data.receive_by_s6,
        receive_date_s7: result.data.receive_date_s7,
        receive_by_s7: result.data.receive_by_s7,
        receive_date_s8: result.data.receive_date_s8,
        receive_by_s8: result.data.receive_by_s8,
        receive_date_s9: result.data.receive_date_s9,
        receive_by_s9: result.data.receive_by_s9,
        receive_date_s10: result.data.receive_date_s10,
        receive_by_s10: result.data.receive_by_s10,
        receive_date_s11: result.data.receive_date_s11,
        receive_by_s11: result.data.receive_by_s11,
        receive_date_s12: result.data.receive_date_s12,
        receive_by_s12: result.data.receive_by_s12,
        receive_date_s13: result.data.receive_date_s13,
        receive_by_s13: result.data.receive_by_s13,
        receive_date_s14: result.data.receive_date_s14,
        receive_by_s14: result.data.receive_by_s14,
        receive_date_s15: result.data.receive_date_s15,
        receive_by_s15: result.data.receive_by_s15,
        receive_date_s16: result.data.receive_date_s16,
        receive_by_s16: result.data.receive_by_s16,
        receive_date_s17: result.data.receive_date_s17,
        receive_by_s17: result.data.receive_by_s17,
        receive_date_s18: result.data.receive_date_s18,
        receive_by_s18: result.data.receive_by_s18,
        receive_date_s19: result.data.receive_date_s19,
        receive_by_s19: result.data.receive_by_s19,
        receive_date_s20: result.data.receive_date_s20,
        receive_by_s20: result.data.receive_by_s20,
        receive_date_s21: result.data.receive_date_s21,
        receive_by_s21: result.data.receive_by_s21,

        receive_status_date: result.data.receive_status_date ? JSON.parse(result.data.receive_status_date) : {},
        receive_status_by: result.data.receive_status_by ? JSON.parse(result.data.receive_status_by) : {},
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
        data_deputize: result.data.dpz_data ? result.data.dpz_data : [],
        menu_data: result.data.menu_data,

        deliver_documents_s9: result.data.deliver_documents_s9,
        sending_document_date_s9: result.data.sending_document_date_s9,
        sending_messenger_s9: result.data.sending_messenger_s9,
        remark_s9: result.data.remark_s9,
        documents_s9: result.data.documents_s9,

        deliver_documents_s16: result.data.deliver_documents_s16,
        sending_document_date_s16: result.data.sending_document_date_s16,
        sending_messenger_s16: result.data.sending_messenger_s16,
        remark_s16: result.data.remark_s16,
        documents_s16: result.data.documents_s16,

        deliver_documents_s19: result.data.deliver_documents_s19,
        sending_document_date_s19: result.data.sending_document_date_s19,
        sending_messenger_s19: result.data.sending_messenger_s19,
        remark_s19: result.data.remark_s19,
        documents_s19: result.data.documents_s19,
        remark_s20: result.data.remark_s20,

        documents_employer_s22: result.data.documents_employer_s22,
        documents_power_s22: result.data.documents_power_s22,
        documents_sub_power_s22: result.data.documents_sub_power_s22,
        documents_scan_name: result.data.documents_scan_name,
        documents_employer_receipt: result.data.documents_employer_receipt,
        documents_employer_receipt_after: result.data.documents_employer_receipt_after,

        check_received_e_work: false,

        check_close: false,

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: result.data.witness_power_2_en,
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,

        due_date: result.data.due_date,
        due_location: result.data.due_location,
        due_remark: result.data.due_remark,
        save_name_list: result.data.save_name_list,

        house_number: result.data.house_number,
        due_time: result.data.due_time,
        due_province: result.data.due_province,
        due_district: result.data.due_district,
        bt_52_document_before: result.data.bt_52_document_before,
        bt_52_document_after: result.data.bt_52_document_after,
        bt_53_document_before: result.data.bt_53_document_before,
        bt_53_document_after: result.data.bt_53_document_after,
        bt_55_document: result.data.bt_55_document,
        power_attorney_document: result.data.power_attorney_document,
        calling_visa_document: result.data.calling_visa_document,
        consent_document: result.data.consent_document,
        house_registration_document: result.data.house_registration_document,

        report_out: result.data.report_out,

        post_box_no: result.data.post_box_no
          ? JSON.parse(result.data.post_box_no)
          : [
              { document_name: "สำเนาเอกสารนายจ้าง", no: "", remark: "" },
              { document_name: "สำเนาเอกสารลูกจ้าง", no: "", remark: "" },
              { document_name: "เอกสารการเงิน", no: "", remark: "" },
            ],

        rate_list: result.data.rate_list
          ? result.data.rate_list.map((e) => {
              return {
                qty_price: e.qty_price,
                qty: e.qty,
                discount: e.discount,
                discount_percent: e.discount_percent,
                vat: e.vat,
                remark: e.remark,
                service_type: e.service_type,
                total_price: e.service_type === "รายหัว" ? e.qty_price * e.qty - e.qty_price * e.qty * (e.discount_percent / 100) : e.vat + e.qty_price - (e.vat + e.qty_price) * (e.discount_percent / 100),
              };
            })
          : [],
      });
      if (result.data.quotation_id) {
        let body_2 = {
          data_search: "",
          quotation_id: result.data.quotation_id,
          menu: "",
          holder: 1,
        };
        let result_2 = await GET(tokens, "api/setting/quotation/list/page/0", body_2);
        if (result_2 && result_2.status) {
          console.log(result_2.data);
          this.setState({ installment: result_2.data.installment });
        }
      } else {
        this.setState({ installment: [] });
      }
    }
    this.setState({ loading: false });
  };
  Status = (item) => {
    let component = null;
    let class_style = "pointer rounded hpx-30 px-2 py-1 text-nowrap ";
    let Match_modal = () => {
      this.GetByID(item.receive_id);
      this.setState({ modal_match: true });
    };
    let Update_modal = () => {
      this.GetByID(item.receive_id);
      this.setState({ modal_update: true });
    };
    let Update_6_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_6: true });
      if (item.receive_status === 61) {
        this.setState({
          fw_data: this.state.fw_data.map((e) => {
            return { ...e, status_90d: true, status_visa: true, status_work: true };
          }),
        });
      }
    };
    let Update_7_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_7: true });
    };
    let Update_8_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_8: true });
    };
    let Update_11_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_11: true });
    };
    let Update_13_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_13: true });
    };
    switch (item.receive_status) {
      case 1:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Match_modal()}>
            รอ Matching
          </span>
        );
        break;
      case 2:
        component = <span>มอบอำนาจ Matching</span>;
        break;
      case 3:
        component = <span>ใช้ User ของนายจ้าง</span>;
        break;
      case 4:
        component = <span className="text-danger">Machting ไม่ได้</span>;
        break;
      case 5:
        component = <span className="text-success">Machting สำเร็จ</span>;
        break;
      case 6:
        component = (
          <span className="text-info pointer" onClick={() => Update_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 7:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            รอตรวจสอบ บต.46+มอบอำนาจ
          </span>
        );
        break;
      case 8:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "เอกสารไม่ถูกต้อง" : item.menu_data.id === 6 ? "เอกสารไม่ถูกต้อง" : "เอกสารไม่ถูกต้อง บต.46"}
          </span>
        );
        break;
      case 9:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "เอกสารถูกต้องพร้อมยื่น" : item.menu_data.id === 6 ? "เอกสารถูกต้อง" : "เอกสาร บต.46+มอบอำนาจ ถูกต้อง"}
          </span>
        );
        break;
      case 10:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ แล้ว" : item.menu_data.id === 6 ? "ส่งเอกสารแล้ว" : "ส่งเอกสาร บต.46+มอบอำนาจ แล้ว"}
          </span>
        );
        break;
      case 11:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.fw_data && item.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 ? "เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย" : "รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย"}
          </span>
        );
        break;
      case 12:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 20 ? "รอบันทึกเลขประจำตัว" : "เอกสารถูกต้อง พร้อมยื่น"}
          </span>
        );
        break;
      case 13:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            ได้เลข WP {item.count_wp === item.total_qty ? "" : "(" + item.count_wp + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 14:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {[3, 4, 5, 6].includes(item.menu_data.id) ? (item.fw_data.filter((e) => e.fee_status).length === 0 ? "รอเก็บค่าบริการ" : "บันทึกรับเงินแล้ว " + "(" + item.fw_data.filter((e) => e.fee_status).length + "/" + item.fw_data.length + ")") : item.menu_data.id === 20 ? "รอบันทึกรับเงิน" : "ได้เลข WP แล้ว รออนุมัติ"}
          </span>
        );
        break;
      case 15:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน
          </span>
        );
        break;
      case 16:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {[3, 4, 5, 6].includes(item.menu_data.id) ? "ต่ออายุสำเร็จ" : item.menu_data.id === 8 ? "กรอกข้อมูล VISA แล้ว" : item.menu_data.id === 7 ? "เอกสาร ตม.๒๘ + ตม.๓๐ ถูกต้อง" : "แนบใบเสร็จแล้ว รอจัดส่ง"}
          </span>
        );
        break;
      case 17:
        component = (
          <span className={class_style + "bg-warning-light text-primary"} onClick={() => Update_modal()}>
            ส่งใบเสร็จให้ลูกค้าแล้ว รอออก eWork
          </span>
        );
        break;
      case 18:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ แล้ว" : "ออก eWork แล้ว" + (item.count_e_work === item.total_qty ? "" : "(" + item.count_e_work + "/" + item.total_qty + ")")}
          </span>
        );
        break;
      case 19:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {item.menu_data.id === 11 ? "รับ Passport แล้ว" : item.menu_data.id === 7 ? "รับ ตม.๒๘ + ตม.๓๐ แล้ว" : "รับ eWork แล้ว รอจัดส่ง"}
          </span>
        );
        break;
      case 20:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {[3, 4, 5, 6].includes(item.menu_data.id) ? "ส่งเอกสารคืน" : [7, 8, 20].includes(item.menu_data.id) ? "จัดส่งเอกสารแล้ว" : item.menu_data.id === 11 ? "จัดส่ง Passport แล้ว" : item.menu_data.id === 7 ? "" : "ส่ง eWork แล้ว"}
          </span>
        );
        break;
      case 21:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 22:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 23:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            รอต่ออายุ
          </span>
        );
        break;
      case 24:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            ลงชื่อ scan
          </span>
        );
        break;
      case 25:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            รอกรอกข้อมูล VISA
          </span>
        );
        break;
      case 26:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            เอกสารถูกต้องพร้อมยื่น
          </span>
        );
        break;
      case 27:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            บันทึกวันนัดรับ Passport แล้ว
          </span>
        );
        break;
      case 60:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_6_modal()}>
            {item.fw_data.filter((e) => e.status_complete_info).length === 0 ? "เปิดเรื่องแล้ว" : "บันทึกลูกจ้างสำเร็จ " + (item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")")}
          </span>
        );
        break;
      case 61:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            เก็บค่าบริการ
          </span>
        );
        break;
      case 62:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            เก็บค่าบริการ
          </span>
        );
        break;
      case 63:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 64:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 65:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            เอกสารไม่ถูกต้อง
          </span>
        );
        break;
      case 66:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบเอกสาร
          </span>
        );
        break;
      case 67:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบใบเสร็จรับเงินแล้ว
          </span>
        );
        break;
      case 68:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            ต่ออายุสำเร็จ
          </span>
        );
        break;
      case 69:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบใบเสร็จ
          </span>
        );
        break;
      case 610:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบใบเสร็จแล้ว
          </span>
        );
        break;
      case 611:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 70:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 71:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 72:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            ตรวจสอบเอกสาร
          </span>
        );
        break;
      case 73:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 74:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจถูกต้อง
          </span>
        );
        break;
      case 75:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจไม่ถูกต้อง
          </span>
        );
        break;
      case 76:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 77:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            ส่งเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง แล้ว
          </span>
        );
        break;
      case 78:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง
          </span>
        );
        break;
      case 79:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 710:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 711:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            แนบ ตม.28+ตม.30 ที่ประทับตราเข้าสู่ระบบ
          </span>
        );
        break;
      case 712:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 713:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 80:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 81:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 82:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            ตรวจสอบเอกสาร และ แนบไฟล์
          </span>
        );
        break;
      case 83:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
        case 84:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 85:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เอกสาร ย้ายตรา visa ไม่ถูกต้อง
          </span>
        );
        break;
      case 86:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 87:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            ส่งเอกสาร ย้ายตรา visa แล้ว
          </span>
        );
        break;
      case 88:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เอกสาร ย้ายตรา visa ไม่ถูกต้อง
          </span>
        );
        break;
      case 89:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 810:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            กรอกข้อมูล visa เล่มใหม่แล้ว
          </span>
        );
        break;
      case 811:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 812:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 110:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            {item.fw_data.filter((e) => e.status_complete_info).length === 0 ? "เปิดเรื่องแล้ว" : "บันทึกลูกจ้างสำเร็จ " + (item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")")}
          </span>
        );
        break;
      case 111:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            รอกรอกเลขตู้
          </span>
        );
        break;
      case 112:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 113:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            เอกสารไม่ถูกต้อง
          </span>
        );
        break;
      case 114:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            จัดส่งเอกสาร
          </span>
        );
        break;
      case 115:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            แนบเอกสารจากสถานฑูต
          </span>
        );
        break;
      case 116:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            แนบเอกสารแล้ว
          </span>
        );
        break;
      case 117:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 118:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 130:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 131:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 132:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            ตรวจสอบเอกสาร
          </span>
        );
        break;
      case 133:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 134:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
          </span>
        );
        break;
      case 135:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสาร บต.53+หนังสือมอบอำนาจ ไม่ถูกต้อง
          </span>
        );
        break;
      case 136:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 137:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            ส่งเอกสาร บต.53+หนังสือมอบอำนาจช่วง แล้ว
          </span>
        );
        break;
      case 138:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสาร บต.53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง
          </span>
        );
        break;
      case 139:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 1310:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 1311:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            แนบไฟล์ใบแจ้งออกแล้ว
          </span>
        );
        break;
      case 1312:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 1313:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            ปิดงาน
          </span>
        );
        break;

      default:
        break;
    }
    return component;
  };
  Update = async () => {
    this.setState({ loading: true });
    let fw_data = this.state.fw_data;
    let receive_status = this.state.receive_status;
    if (this.state.receive_status === 9) {
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 10;
    }
    if (this.state.receive_status === 10 && fw_data.filter((e) => !e.af_bt_46_document).length === 0) {
      if (this.state.menu_data.id === 6) {
        receive_status = 23;
      } else {
        receive_status = 11;
      }
    }
    if (fw_data.filter((e) => e.wp_number !== "" && e.wp_number !== null).length !== 0 && this.state.receive_status === 12) {
      receive_status = 13;
    }
    if (this.state.menu_data.id === 20) {
      if (fw_data.filter((e) => !e.fw_number).length === 0 && this.state.receive_status === 12) {
        receive_status = 14;
      }
      if (fw_data.filter((e) => !e.fw_number).length === 0 && this.state.receive_status === 13) {
        receive_status = 14;
      }
    } else {
      if (fw_data.filter((e) => !e.wp_number).length === 0 && this.state.receive_status === 12) {
        receive_status = 14;
      }
      if (fw_data.filter((e) => !e.wp_number).length === 0 && this.state.receive_status === 13) {
        receive_status = 14;
      }
    }
    if (this.state.menu_data.id === 7) {
      for (let item of fw_data) {
        if (item.passport_image && item.work_pm_image && item.visa_image) {
          item.status_complete_info = true;
        }
      }
      if ((this.state.receive_status === 6 || this.state.receive_status === 22) && fw_data.filter((e) => e.first_name_th && e.fw_number && e.nationality && e.passport_image && e.work_pm_image && e.visa_image).length === fw_data.length) {
        receive_status = 7;
      } else if (this.state.receive_status === 9) {
        if (!this.state.deliver_documents_s9) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s9) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s9) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 11;
      } else if (this.state.receive_status === 10 && fw_data.filter((e) => !e.tm_28_document || !e.tm_30_document).length === 0) {
        receive_status = 11;
      } else if (this.state.receive_status === 16) {
        if (!this.state.deliver_documents_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s16) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 18;
      } else if (this.state.receive_status === 18 && fw_data.filter((e) => !e.tm_28_document_after || !e.tm_30_document_after).length === 0) {
        receive_status = 19;
      } else if (this.state.receive_status === 19) {
        if (!this.state.deliver_documents_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s19) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    } else if (this.state.menu_data.id === 3) {
      if (this.state.receive_status === 14 && fw_data.filter((e) => !e.fee_document).length === 0) {
        receive_status = 20;
      }
    } else if (this.state.menu_data.id === 8) {
      if ((this.state.receive_status === 22 || this.state.receive_status === 6) && fw_data.filter((e) => !e.passport_image || !e.passport_old_image).length === 0) {
        receive_status = 7;
      }
    } else if (this.state.menu_data.id === 11) {
      if ((this.state.receive_status === 22 || this.state.receive_status === 6) && fw_data.filter((e) => !e.profile_image || !e.passport_old_image || !e.work_pm_image || !e.visa_image || !e.id_card_image || !e.attachment_name).length === 0) {
        receive_status = 7;
      }
      if (this.state.receive_status === 26) {
        if (!this.state.due_date) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือกวันนัด");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.due_location) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานที่นัด");
          this.setState({ loading: false });
          return;
        }
        receive_status = 27;
      }
      if (this.state.receive_status === 27 && fw_data.filter((e) => !e.passport_image).length === 0) {
        receive_status = 19;
      }
      if (this.state.receive_status === 19) {
        if (!this.state.deliver_documents_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s19) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    } else if (this.state.receive_status === 16) {
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 17;
    } else {
      if (this.state.receive_status === 14 && fw_data.filter((e) => !e.bt_50_document || !e.fee_document || !e.is_download_s14).length === 0) {
        receive_status = 16;
      }
    }
    if (this.state.receive_status === 17 && fw_data.filter((e) => e.e_work_document || e.e_work_card_issued || e.e_work_card_received).length !== 0) {
      receive_status = 18;
    }
    if (this.state.receive_status === 17 && fw_data.filter((e) => !e.e_work_document || !e.e_work_card_issued || !e.e_work_card_received).length === 0) {
      receive_status = 19;
    }
    if (this.state.receive_status === 18 && fw_data.filter((e) => !e.e_work_document || !e.e_work_card_issued || !e.e_work_card_received).length === 0) {
      receive_status = 19;
    }
    if (this.state.receive_status === 19) {
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 20;
    }
    if (this.state.receive_status === 20 && this.state.check_close) {
      receive_status = 21;
    }
    if ([3, 4, 5, 6, 20].includes(this.state.menu_data.id)) {
      if ([3, 4, 5, 6].includes(this.state.menu_data.id) && this.state.receive_status === 14) {
        receive_status = 23;
      } else if ([3, 4, 5, 6].includes(this.state.menu_data.id) && this.state.receive_status === 23 && fw_data.filter((e) => !e.document_renewal_status).length === 0) {
        receive_status = 16;
        for (let item of fw_data) {
          item.document_history = item.document_history ? JSON.parse(item.document_history) : [];
          item.document_history.push({
            passport_place: item.passport_place,
            passport_allowed_date: item.passport_allowed_date,
            passport_expiry_date: item.passport_expiry_date,
            visa_allowed_location: item.visa_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.visa_allowed_location)[0].label : "",
            visa_allowed_date: item.visa_allowed_date,
            visa_expiry_date: item.visa_expiry_date,
            work_pm_allowed_location: item.work_pm_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.work_pm_allowed_location)[0].label : "",
            work_pm_allowed_date: item.work_pm_allowed_date,
            work_pm_expiry_date: item.work_pm_expiry_date,
            notice_90d_allowed_location: item.notice_90d_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.notice_90d_allowed_location)[0].label : "",
            notice_90d_allowed_date: item.notice_90d_allowed_date,
            notice_90d_expiry_date: item.notice_90d_expiry_date,
          });
          item.document_history = item.document_history.length !== 0 ? JSON.stringify(item.document_history) : "";
        }
      } else if ([20].includes(this.state.menu_data.id) && this.state.receive_status === 22 && this.state.documents_employer_s22 && this.state.documents_power_s22 && this.state.documents_sub_power_s22) {
        receive_status = 6;
      } else if ([20].includes(this.state.menu_data.id) && this.state.receive_status === 14 && fw_data.filter((e) => !e.fee_status).length === 0) {
        receive_status = 16;
      } else if (this.state.receive_status === 14 && fw_data.filter((e) => !e.fee_document || !e.fee_status).length === 0) {
        receive_status = 16;
      } else if (this.state.receive_status === 16) {
        if (!this.state.deliver_documents_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s16) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    }
    if ([8].includes(this.state.menu_data.id)) {
      if (this.state.receive_status === 24 && this.state.documents_scan_name) {
        receive_status = 25;
      } else if (this.state.receive_status === 25 && fw_data.filter((e) => !e.visa_number || !e.visa_allowed_date || !e.visa_allowed_location || !e.visa_expiry_date).length === 0) {
        receive_status = 16;
      } else if (this.state.receive_status === 16) {
        if (!this.state.deliver_documents_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s16) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    }
    // แจ้งคนต่างด้าวออกจากงาน บต.53
    if (this.state.menu_data.id === 13) {
      if (fw_data.filter((e) => !e.fw_number || !e.work_permit_document || !e.passport_image).length === 0) {
        receive_status = 130;
      }
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      documents_employer_s22: this.state.documents_employer_s22,
      documents_power_s22: this.state.documents_power_s22,
      documents_sub_power_s22: this.state.documents_sub_power_s22,
      documents_scan_name: this.state.documents_scan_name,
      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,

      house_number: this.state.house_number,
      due_time: this.state.due_time,
      due_province: this.state.due_province,
      due_district: this.state.due_district,
      bt_52_document_before: this.state.bt_52_document_before,
      bt_52_document_after: this.state.bt_52_document_after,
      bt_53_document_before: this.state.bt_53_document_before,
      bt_53_document_after: this.state.bt_53_document_after,
      bt_55_document: this.state.bt_55_document,
      power_attorney_document: this.state.power_attorney_document,
      calling_visa_document: this.state.calling_visa_document,
      consent_document: this.state.consent_document,
      house_registration_document: this.state.house_registration_document,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_6 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 61) {
      // STATUS 61 นายจ้าง
      receive_status = 63;
    } else if (receive_status === 62) {
      // STATUS 62 ลูกจ้าง
      receive_status = 63;
    } else if (receive_status === 66) {
      // STATUS 66 ลูกจ้าง
      if (this.state.documents_employer_receipt) {
        receive_status = 67;
      }
    } else if (receive_status === 67) {
      if (fw_data.filter((e) => !e.document_renewal_status).length === 0) {
        receive_status = 68;
        for (let item of fw_data) {
          item.document_history = item.document_history ? JSON.parse(item.document_history) : [];
          item.document_history.push({
            passport_place: item.passport_place,
            passport_allowed_date: item.passport_allowed_date,
            passport_expiry_date: item.passport_expiry_date,
            visa_allowed_location: item.visa_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.visa_allowed_location)[0].label : "",
            visa_allowed_date: item.visa_allowed_date,
            visa_expiry_date: item.visa_expiry_date,
            work_pm_allowed_location: item.work_pm_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.work_pm_allowed_location)[0].label : "",
            work_pm_allowed_date: item.work_pm_allowed_date,
            work_pm_expiry_date: item.work_pm_expiry_date,
            notice_90d_allowed_location: item.notice_90d_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.notice_90d_allowed_location)[0].label : "",
            notice_90d_allowed_date: item.notice_90d_allowed_date,
            notice_90d_expiry_date: item.notice_90d_expiry_date,
          });
          item.document_history = item.document_history.length !== 0 ? JSON.stringify(item.document_history) : "";
        }
      }
    } else if (receive_status === 68) {
      // STATUS 68 ต่ออายุสำเร็จ
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 69;
    } else if (receive_status === 69) {
      // STATUS 69 แนบใบเสร็จ
      if (this.state.documents_employer_receipt_after) {
        receive_status = 610;
      }
    } else if (this.state.receive_status === 610 && this.state.check_close) {
      // STATUS 611 แนบใบเสร็จแล้ว
      receive_status = 611;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      // 130
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_7 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 70) {
      // STATUS 70 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 72;
      }
    } else if (receive_status === 71) {
      // STATUS 71 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 72;
      }
    } else if (receive_status === 72) {
      // STATUS 72 ตรวจสอบเอกสาร
      receive_status = 73;
    } else if (receive_status === 74) {
      // STATUS 74 เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 76;
    } else if (receive_status === 710) {
      if (this.state.report_out) {
        receive_status = 711;
      }
    } else if (receive_status === 711) {
      // STATUS 711 แนบไฟล์ใบแจ้งออกแล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 712;
    } else if (this.state.receive_status === 712 && this.state.check_close) {
      // STATUS 712 จัดส่งเอกสารแล้ว
      receive_status = 713;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_8 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 80) {
      // STATUS 80 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.passport_old_image && fw.work_pm_image && fw.profile_image) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 82;
      }
    } else if (receive_status === 81) {
      // STATUS 81 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.passport_old_image && fw.work_pm_image && fw.profile_image) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 82;
      }
    } else if (receive_status === 82) {
      // STATUS 82 ตรวจสอบเอกสาร และ แนบไฟล์
      if (fw_data.filter((e) => e.document_evidence).length === fw_data.length) {
        receive_status = 83;
      }
    } else if (receive_status === 89) {
      // STATUS 89 เอกสารถูกต้อง
      if (fw_data.filter((e) => e.visa_image && e.visa_allowed_date && e.visa_allowed_location && e.visa_expiry_date && e.visa_number).length === fw_data.length) {
        receive_status = 810;
      }
    } else if (receive_status === 810) {
      // STATUS 810 กรอกข้อมูล visa เล่มใหม่แล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 811;
    } else if (this.state.receive_status === 811 && this.state.check_close) {
      // STATUS 811 จัดส่งเอกสารแล้ว
      receive_status = 812;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_11 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 115) {
      // STATUS 115 แนบเอกสารจากสถานฑูต
      if (this.state.documents_employer_receipt) {
        receive_status = 116;
      }
    } else if (receive_status === 116) {
      // STATUS 116 แนบเอกสารแล้ว
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 117;
    } else if (this.state.receive_status === 117 && this.state.check_close) {
      // STATUS 117 จัดส่งเอกสารแล้ว
      receive_status = 118;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      // 130
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_13 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 130) {
      // STATUS 130 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 132;
      }
    } else if (receive_status === 131) {
      // STATUS 131 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 132;
      }
    } else if (receive_status === 132) {
      // STATUS 132 ตรวจสอบเอกสาร
      receive_status = 133;
    } else if (receive_status === 134) {
      // STATUS 134 เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 136;
    } else if (receive_status === 1310) {
      if (this.state.report_out) {
        receive_status = 1311;
      }
    } else if (receive_status === 1311) {
      // STATUS 1311 แนบไฟล์ใบแจ้งออกแล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 1312;
    } else if (this.state.receive_status === 1312 && this.state.check_close) {
      // STATUS 1312 จัดส่งเอกสารแล้ว
      receive_status = 1313;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_fw = async () => {
    this.setState({ loading: true });
    let body = {
      receive_status: 14,
      fw_data: this.state.fw_data,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    this.setState({ loading: false });
  };
  Update_fw_6 = async () => {
    this.setState({ loading: true });
    let body = {
      receive_status: this.state.receive_status,
      fw_data: this.state.fw_data,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    this.setState({ loading: false });
  };
  Update_up = async () => {
    let body = {
      r_username: this.state.r_username,
      r_password: this.state.r_password,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      let files = file.type.includes("pdf") ? e.target.result.split(",")[1] : await convert_imagetopdf(e.target.result);
      let fw_data = this.state.fw_data;
      if (type === "bt_46_document") {
        fw_data[this.state.index].bt_46_document = files;
      } else if (type === "power_attorney_document") {
        fw_data[this.state.index].power_attorney_document = files;
      } else if (type === "af_bt_46_document") {
        fw_data[this.state.index].af_bt_46_document = files;
      } else if (type === "profile_image") {
        fw_data[this.state.index].profile_image = files;
      } else if (type === "id_card_image") {
        fw_data[this.state.index].id_card_image = files;
      } else if (type === "af_power_attorney_document") {
        fw_data[this.state.index].af_power_attorney_document = files;
      } else if (type === "af_contract_document") {
        fw_data[this.state.index].af_contract_document = files;
      } else if (type === "bt_50_document") {
        fw_data[this.state.index].bt_50_document = files;
      } else if (type === "fee_document") {
        fw_data[this.state.index].fee_document = files;
      } else if (type === "receipt_document") {
        fw_data[this.state.index].receipt_document = files;
      } else if (type === "work_permit_document") {
        fw_data[this.state.index].work_permit_document = files;
      } else if (type === "e_work_document") {
        fw_data[this.state.index].e_work_document = files;
      } else if (type === "e_received_document") {
        fw_data[this.state.index].e_received_document = files;
      } else if (type === "documents_employee_receipt") {
        fw_data[this.state.index].documents_employee_receipt = files;
      } else if (type === "visa_image") {
        fw_data[this.state.index].visa_image = files;
      } else if (type === "work_pm_image") {
        fw_data[this.state.index].work_pm_image = files;
      } else if (type === "passport_image") {
        fw_data[this.state.index].passport_image = files;
      } else if (type === "passport_old_image") {
        fw_data[this.state.index].passport_old_image = files;
      } else if (type === "attachment_name") {
        fw_data[this.state.index].attachment_name = files;
      } else if (type === "bt_44_document") {
        fw_data[this.state.index].bt_44_document = files;
      } else if (type === "bt_54_document") {
        fw_data[this.state.index].bt_54_document = files;
      } else if (type === "bt_55_document") {
        fw_data[this.state.index].bt_55_document = files;
      } else if (type === "document_evidence") {
        fw_data[this.state.index].document_evidence = files;
      } else if (type === "bt_44_update") {
        fw_data[this.state.index].bt_44_update = files;
      } else if (type === "bt_44_to") {
        fw_data[this.state.index].bt_44_to = files;
      } else if (type === "bt_44_due_to") {
        fw_data[this.state.index].bt_44_due_to = files;
      } else if (type === "new_register_permit_document") {
        fw_data[this.state.index].new_register_permit_document = files;
      } else if (type === "tm_6_document") {
        fw_data[this.state.index].tm_6_document = files;
      } else if (type === "tm_7_document") {
        fw_data[this.state.index].tm_7_document = files;
      } else if (type === "tm_28_document") {
        fw_data[this.state.index].tm_28_document = files;
      } else if (type === "tm_30_document") {
        fw_data[this.state.index].tm_30_document = files;
      } else if (type === "tm_28_document_after") {
        fw_data[this.state.index].tm_28_document_after = files;
      } else if (type === "tm_30_document_after") {
        fw_data[this.state.index].tm_30_document_after = files;
      } else if (type === "photo_15inch_document") {
        fw_data[this.state.index].photo_15inch_document = files;
      } else if (type === "training_book_document") {
        fw_data[this.state.index].training_book_document = files;
      } else if (type === "pink_card_document") {
        fw_data[this.state.index].pink_card_document = files;
      } else if (type === "documents_s9") {
        this.setState({ documents_s9: files });
      } else if (type === "documents_s16") {
        this.setState({ documents_s16: files });
      } else if (type === "documents_s19") {
        this.setState({ documents_s19: files });
      } else if (type === "documents_employer_s22") {
        this.setState({ documents_employer_s22: files });
      } else if (type === "documents_power_s22") {
        this.setState({ documents_power_s22: files });
      } else if (type === "documents_sub_power_s22") {
        this.setState({ documents_sub_power_s22: files });
      } else if (type === "documents_scan_name") {
        this.setState({ documents_scan_name: files });
      } else if (type === "documents_employer_receipt") {
        this.setState({ documents_employer_receipt: files });
      } else if (type === "documents_employer_receipt_after") {
        this.setState({ documents_employer_receipt_after: files });
      } else if (type === "bt_52_document_before") {
        this.setState({ bt_52_document_before: files });
      } else if (type === "bt_52_document_after") {
        this.setState({ bt_52_document_after: files });
      } else if (type === "bt_53_document_before") {
        this.setState({ bt_53_document_before: files });
      } else if (type === "bt_53_document_after") {
        this.setState({ bt_53_document_after: files });
      } else if (type === "bt_55_document") {
        this.setState({ bt_55_document: files });
      } else if (type === "power_attorney_document2") {
        this.setState({ power_attorney_document: files });
      } else if (type === "calling_visa_document") {
        this.setState({ calling_visa_document: files });
      } else if (type === "consent_document") {
        this.setState({ consent_document: files });
      } else if (type === "house_registration_document") {
        this.setState({ house_registration_document: files });
      } else if (type === "report_out") {
        this.setState({ report_out: files });
      }
      this.setState({ fw_data: fw_data });
    };
    let array_file = document.getElementsByName("file");
    for (let item of array_file) {
      item.value = "";
      item.file = null;
    }
  };
  import_excel = (e) => {
    readFile(e.target.files[0])
      .then((readedData) => {
        this.setState({ import: readedData });
        setTimeout(() => {
          this.setState({ loading: true });
          let currentSheet = this.state.currentSheet;
          currentSheet.Sheet1 = currentSheet.Sheet1.slice(1);
          let array2 = [];
          for (let item of currentSheet.Sheet1[0]) {
            if (array2.findIndex((e) => e === item) !== -1) {
              array2.push(item + "_");
            } else {
              array2.push(item);
            }
          }
          currentSheet.Sheet1[0] = array2;
          const result = generateObjects(currentSheet);
          // ตรวจสอบจำนวนลูกจ้าง
          if (this.state.fw_data.length < result.length) {
            alert("warning", "แจ้งเตือน", "ข้อมูลที่นำเข้ามีมากกว่าจำนวนลูกจ้างในระบบ");
            return;
          }
          let array_check = ["ลำดับที่", "นายจ้างเลขประจำตัว", "เลขประจำตัว 13 หลัก", "คำนำหน้า", "คำนำหน้า EN", "ลูกจ้างชื่อ EN", "สัญชาติ", "เพศ", "ว-ด-ป เกิด คศ"];
          for (let i = 0; i < array_check.length; i++) {
            if (this.state.currentSheet["Sheet1"][0][i] !== array_check[i]) {
              alert("warning", "แจ้งเตือน", 'หัวคอลัมน์ "' + array_check[i] + '" ไม่ถูกต้อง');
              this.setState({ loading: false });
              return;
            }
          }
          this.setState({ data_import: result.filter((item) => item["ลำดับที่"] !== ""), loading: false });
        }, 10);
      })
      .catch((error) => console.error(error));
    document.getElementById("file").value = "";
    document.getElementById("file").file = null;
  };
  create_import = async () => {
    this.setState({ loading: true });
    let result = this.state.data_import;
    let address_list = [];
    for (let item of result) {
      if (address_list.findIndex((e) => e.address_number === item["สถานที่ทำงานเลขที่ "] && e.moo === item["หมู่_"] && e.soi === item["ซอย_"] && e.sub_district === item["ตำบล/แขวง_"] && e.district === item["อำเภอ/เขต_"] && e.province === item["จังหวัด_"]) === -1) {
        if (item["สถานที่ทำงานเลขที่ "] === "" && item["หมู่_"] === "" && item["ซอย_"] === "" && item["ตำบล/แขวง_"] === "" && item["อำเภอ/เขต_"] === "" && item["จังหวัด_"] === "") {
        } else {
          address_list.push({
            address_number: item["สถานที่ทำงานเลขที่ "],
            moo: item["หมู่_"],
            soi: item["ซอย_"],
            sub_district: item["ตำบล/แขวง_"],
            district: item["อำเภอ/เขต_"],
            province: item["จังหวัด_"],
          });
        }
      }
    }
    // สร้างที่อยู่
    if (address_list.length !== 0) {
      let body = {
        import_data: address_list.map((e) => {
          return {
            emp_number: this.state.emp_number,
            address_number: e.address_number,
            moo: e.moo,
            soi: e.soi,
            sub_district: e.sub_district,
            district: e.district,
            province: e.province,
            zipcode: e.sub_district ? subdistrict.filter((item) => item.sub_district_name === e.sub_district.trim())[0]?.zip_code : "",
          };
        }),
      };
      let result2 = await POST(tokens, "api/employer/import", body);
      if (result2 && result2.status) {
        await this.GetWorkplace(this.state.emp_id);
      }
    }
    let immigration_list = [];
    for (let item of result) {
      if (immigration_list.findIndex((e) => e === item["ออกให้ที่_"]) === -1 && item["ออกให้ที่_"] !== "" && this.state.data_immigration_office.findIndex((e) => e.label === item["ออกให้ที่_"]) === -1) {
        immigration_list.push(item["ออกให้ที่_"]);
      }
    }
    // สร้าง ตม.
    if (immigration_list.length !== 0) {
      let body = {
        import_data: immigration_list.map((e) => {
          return {
            imm_name_th: e,
            imm_name_en: "",
          };
        }),
      };
      let result2 = await POST(tokens, "api/setting/immigration/import", body);
      if (result2 && result2.status) {
        await this.GetImmigration();
      }
    }
    let fw_data_empty = this.state.fw_data.filter((e) => !e.fw_number);
    let fw_data_have = this.state.fw_data.filter((e) => e.fw_number);
    let fw_index = 0;

    var data = result
      .filter((item) => item["ลำดับที่"] !== "")
      .map((item, index) => {
        // สถานที่ทำงาน
        let place_work = this.state.data_workplace2.filter((e) => e.address_number === item["สถานที่ทำงานเลขที่ "] && e.moo === item["หมู่_"] && e.soi === item["ซอย_"] && e.sub_district === item["ตำบล/แขวง_"] && e.district === item["อำเภอ/เขต_"] && e.province === item["จังหวัด_"]);
        // อายุ
        let day = days_between(new Date(), item["ว-ด-ป เกิด คศ"] ? new Date(format_date_excel(item["ว-ด-ป เกิด คศ"], "date", "en")) : new Date()).day;
        let age = Number((day / 365).toString().split(".")[0]);

        let fw_data = fw_data_have.filter((e) => e.fw_number === item["เลขประจำตัว 13 หลัก"]);
        let fw_id = "";

        if (fw_data.length !== 0) {
          fw_id = fw_data[0].id;
        } else {
          fw_id = fw_data_empty[fw_index].id;
          fw_index += 1;
        }
        // จัดข้อมูล
        return {
          fw_id: fw_id,
          receive_id: this.state.receive_id,
          emp_id: this.state.emp_id,
          business_type: Number(this.state.business_type.value),
          province: item["จังหวัด"],
          place_work: place_work.length !== 0 ? place_work[0].id : "",
          phone: "",
          email: "",

          fw_number: item["เลขประจำตัว 13 หลัก"],
          nationality: item["สัญชาติ"] === "เมียนมา" ? 1 : item["สัญชาติ"] === "ลาว" ? 2 : item["สัญชาติ"] === "กัมพูชา" ? 3 : item["สัญชาติ"] === "เวียดนาม" ? 4 : "",
          prefix_th: item["คำนำหน้า"] ? Number(this.state.data_prefix_th.filter((e) => e.label === item["คำนำหน้า"])[0].value) : "",
          first_name_th: "",
          middle_name_th: "",
          last_name_th: "",
          prefix_en: item["คำนำหน้า"] ? Number(this.state.data_prefix_th.filter((e) => e.label === item["คำนำหน้า"])[0].value) : "",
          first_name_en: item["ลูกจ้างชื่อ EN"],
          middle_name_en: "",
          last_name_en: "",
          birth_date: item["ว-ด-ป เกิด คศ"] ? format_date_excel(item["ว-ด-ป เกิด คศ"], "yyyy-mm-dd", "en") : null,
          age: age,
          gender: item["เพศ"] === "M" ? "ชาย" : item["เพศ"] === "W" ? "หญิง" : "",
          job_position: "",

          // ข้อมูลที่อยู่ลูกจ้าง
          fw_housing_type: item["สถานที่พักอาศัยเลขที่ "] === item["สถานที่ทำงานเลขที่ "] ? "ที่เดียวกับสถานที่ทำงาน" : item["สถานที่พักอาศัยเลขที่ "] === this.state.address_number ? "ที่เดียวกับที่ตั้ง/ที่อยู่ของนายจ้าง" : "ที่อื่น",
          fw_house_number: item["สถานที่พักอาศัยเลขที่ "],
          fw_building_name: "",
          fw_moo: item["หมู่"],
          fw_soi: item["ซอย"],
          fw_street: item["ถนน"],
          fw_province: item["จังหวัด"].trim(),
          fw_district: item["อำเภอ/เขต"].trim(),
          fw_sub_district: item["ตำบล/แขวง"].trim(),
          fw_zipcode: item["ตำบล/แขวง"].trim() ? subdistrict.filter((e) => e.sub_district_name === item["ตำบล/แขวง"].trim())[0]?.zip_code : "",
          fw_phone: "",
          fw_fax: "",
          fw_entry_country_date: "",
          fw_entry_checkpoint: "",
          fw_place_of_birth: item["สถานที่เกิด"],
          fw_country: "",

          // Passport
          passport_type: item["Passport no."] ? "Passport" : "",
          passport_number: item["Passport no."],
          passport_place: item["ออกให้ที่"],
          passport_born_place: item["สถานที่เกิด"],
          passport_country: item["สัญชาติ"],
          passport_allowed_date: item["ออกให้วันที่"] ? format_date_excel(item["ออกให้วันที่"], "yyyy-mm-dd", "en") : null,
          passport_expiry_date: item["ใช้ได้ถึง"] ? format_date_excel(item["ใช้ได้ถึง"], "yyyy-mm-dd", "en") : null,
          passport_image: "",

          passport_old_image: "",

          // Visa
          visa_number: item["VISA no."],
          visa_allowed_location: item["ออกให้ที่_"] ? Number(this.state.data_immigration_office.filter((e) => e.label === item["ออกให้ที่_"])[0].value) : "",
          visa_allowed_date: item["ออกให้วันที่_"] ? format_date_excel(item["ออกให้วันที่_"], "yyyy-mm-dd", "en") : null,
          visa_expiry_date: item["ใช้ได้ถึง_"] ? format_date_excel(item["ใช้ได้ถึง_"], "yyyy-mm-dd", "en") : null,
          visa_stay_kingdom_date: item["อนุญาตให้อยู่ในราชอาณาจักรได้ถึงวันที่"] ? format_date_excel(item["อนุญาตให้อยู่ในราชอาณาจักรได้ถึงวันที่"], "yyyy-mm-dd", "en") : null,
          visa_image: "",

          insurance_type: 3,
          sso_hospital_name: item["รพ.สปส."],
        };
      });
    let body = {
      import_data: data,
    };
    let result2 = await POST(tokens, "api/foreign-worker/import", body);
    if (result2 && result2.status) {
      Swal.fire({
        icon: "success",
        title: "สำเร็จ",
        text: "การทำรายการสำเร็จแล้ว",
        confirmButtonText: "ตกลง",
      }).then((re) => {
        window.location.reload();
      });
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ขอใบอนุญาตทำงาน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการรับเรื่องทั้งหมด</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3 justify-content-between">
                <select
                  className="form-control w-auto bg-contain border-0 mx-1 mb-3"
                  onChange={(e) => {
                    this.setState({
                      status_search: e.target.value,
                    });
                    setTimeout(() => {
                      this.Get();
                    }, 1);
                  }}
                  value={this.state.status_search}
                >
                  <option value="">รายการรับเรื่องทั้งหมด</option>
                  {this.state.data_menu_work.map((item, index) => (
                    <option value={item.id}>{item.menu_name}</option>
                  ))}
                </select>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่รับเรื่อง</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ชื่อประเภทบริการ</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">สถานะงาน</th>
                      <th className="text-left text-white">ผู้บันทึกข้อมูล</th>
                      <th className="text-center text-white">ใบรับเรื่อง</th>
                      <th className="text-center text-white">Name List</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.receive_date ? format_date(item.receive_date) : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.menu_data ? item.menu_data.menu_name : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.full_name_th : "-"}</td>
                          <td className="text-left">
                            {item.receive_status} {this.Status(item)}
                          </td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-center">
                            <div
                              className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-80 m-auto"
                              onClick={() => {
                                window.location.href = "/RecieveAll/update?id=" + item.receive_id;
                              }}
                            >
                              ใบรับเรื่อง
                            </div>
                          </td>
                          <td className="text-center">
                            <div
                              className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-100 m-auto"
                              onClick={() => {
                                window.location.href = "/Employer/update?receive_id=" + item.receive_id + "&type=name_list&receive_codes=" + item.receive_code + "&menu_id=" + item.menu_data.id + "&menu_name=" + item.menu_data.menu_name;
                              }}
                            >
                              Name List
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employer_receipt"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employer_receipt");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employer_receipt_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employer_receipt_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employee_receipt"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employee_receipt");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_passport_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "passport_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_passport_old_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "passport_old_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_work_pm_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "work_pm_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_visa_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "visa_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_attachment_name"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "attachment_name");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_46_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_46_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_power_attorney_document2"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document2");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_af_bt_46_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_bt_46_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_profile_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "profile_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_id_card_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "id_card_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_af_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_af_contract_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_contract_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_50_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_50_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_fee_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "fee_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_receipt_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "receipt_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_work_permit_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "work_permit_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_e_work_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "e_work_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_e_received_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "e_received_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_s9"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s9");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_s16"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s16");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_s19"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s19");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employer_s22"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employer_s22");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_power_s22"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_power_s22");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_sub_power_s22"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_sub_power_s22");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_scan_name"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_scan_name");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_scan_name"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_scan_name");
            }}
          />

          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_44_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_44_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_54_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_54_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_55_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_55_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_document_evidence"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "document_evidence");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_new_register_permit_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "new_register_permit_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_6_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_6_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_7_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_7_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_28_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_28_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_30_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_30_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_28_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_28_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_30_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_30_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_photo_15inch_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "photo_15inch_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_training_book_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "training_book_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_pink_card_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "pink_card_document");
            }}
          />

          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_52_document_before"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_52_document_before");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_52_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_52_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_53_document_before"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_53_document_before");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_53_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_53_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_55_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_55_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_calling_visa_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "calling_visa_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_consent_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "consent_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_house_registration_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "house_registration_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_report_out"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "report_out");
            }}
          />
        </div>
        {/* แก้ไขเลข WP */}
        <Modal
          show={this.state.modal_update_wp}
          onHide={() => {
            this.setState({ modal_update: true, modal_update_wp: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>เลข WP</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <span>แก้ไขเลข WP</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={13}
                  onChange={(e) => {
                    this.setState({ wp_number: number(e.target.value) });
                  }}
                  value={this.state.wp_number}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_update: true,
                      modal_update_wp: false,
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let fw_data = this.state.fw_data;
                    fw_data[this.state.index].wp_number = this.state.wp_number;
                    this.setState({
                      fw_data: fw_data,
                      modal_update: true,
                      modal_update_wp: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Matching */}
        <Modal
          show={this.state.modal_match}
          onHide={() => {
            this.setState({ modal_match: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Matching {this.state.receive_code ? this.state.receive_code : ""}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              เลขประจำตัวนายจ้าง <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 5 });
                }}
                checked={this.state.receive_status === 5}
              />
              Matching ได้
              <input
                className="mt-1 mx-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 4 });
                }}
                checked={this.state.receive_status === 4}
              />
              Matching ไม่ได้
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* status 2 มอบอำนาจ Matching */}
        <Modal
          show={this.state.modal_match2}
          onHide={() => {
            this.setState({ modal_match2: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>User ของนายจ้าง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              ใช้ User ของนายจ้าง เลขประจำตัว <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="row">
              <div className="col-6">
                <span className="wpx-150">User</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  value={this.state.r_username}
                  onChange={(e) => {
                    this.setState({ r_username: e.target.value });
                  }}
                />
              </div>
              <div className="col-6">
                <span className="wpx-150">Password</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  value={this.state.r_password}
                  onChange={(e) => {
                    this.setState({ r_password: e.target.value });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_match2: false,
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={async () => {
                    await this.Update_up();
                    setTimeout(() => {
                      this.setState({ modal_match2: false });
                    }, 10);
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ดาวน์โหลดเอกสาร */}
        <Modal
          show={this.state.modal_download}
          onHide={() => {
            this.setState({ modal_download: false });
          }}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>
              <b>ดาวน์โหลดเอกสาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-center text-white">ลำดับ</th>
                    <th className="text-left text-white">ชื่อ-นามสกุล</th>
                    <th className="text-left text-white">เลขบัตรประจำตัว</th>
                    <th className="text-center text-white">
                      บต.46 + สัญญาจ้าง
                      <span
                        className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                        onClick={() => {
                          document_bt46(
                            this.state.fw_data,
                            this.state.emp_data,
                            this.state.data_workplace,
                            this.state.data_deputize,
                            this.state.data_salary,
                            {
                              witness_contract_1: this.state.witness_contract_1,
                              witness_contract_2_th: this.state.witness_contract_2_th,
                              witness_contract_2_en: this.state.witness_contract_2_en,
                            },
                            this.state.data_prefix_th,
                            this.state.data_prefix_en,
                            this.state.data_work_type,
                            this.state.data_business_type
                          );
                        }}
                      >
                        {"\uf019"}
                      </span>
                    </th>
                    <th className="text-left text-white">
                      บต.46 + สัญญาจ้าง เซ็นแล้ว
                      {this.state.fw_data.filter((e) => e.af_bt_46_document).length !== 0 && (
                        <span
                          className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                          onClick={() => {
                            document_bt46(
                              this.state.fw_data,
                              this.state.emp_data,
                              this.state.data_workplace,
                              this.state.data_deputize,
                              this.state.data_salary,
                              {
                                witness_contract_1: this.state.witness_contract_1,
                                witness_contract_2_th: this.state.witness_contract_2_th,
                                witness_contract_2_en: this.state.witness_contract_2_en,
                              },
                              this.state.data_prefix_th,
                              this.state.data_prefix_en,
                              this.state.data_work_type,
                              this.state.data_business_type
                            );
                          }}
                        >
                          {"\uf019"}
                        </span>
                      )}
                    </th>
                    <th className="text-center text-white">
                      ใบมอบอำนาจ
                      <span
                        className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                        onClick={() => {
                          document_contact(
                            this.state.fw_data,
                            this.state.emp_data,
                            this.state.data_workplace,
                            this.state.data_deputize,
                            {
                              witness_power_1: this.state.witness_power_1,
                              witness_power_2_th: this.state.witness_power_2_th,
                              witness_power_2_en: this.state.witness_power_2_en,
                            },
                            this.state.data_prefix_th,
                            this.state.data_prefix_en
                          );
                        }}
                      >
                        {"\uf019"}
                      </span>
                    </th>
                    <th className="text-left text-white">
                      ใบมอบอำนาจ เซ็นแล้ว
                      {this.state.fw_data.filter((e) => e.af_power_attorney_document).length !== 0 && (
                        <span
                          className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                          onClick={() => {
                            document_contact(
                              this.state.fw_data,
                              this.state.emp_data,
                              this.state.data_workplace,
                              this.state.data_deputize,
                              {
                                witness_power_1: this.state.witness_power_1,
                                witness_power_2_th: this.state.witness_power_2_th,
                                witness_power_2_en: this.state.witness_power_2_en,
                              },
                              this.state.data_prefix_th,
                              this.state.data_prefix_en
                            );
                          }}
                        >
                          {"\uf019"}
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.fw_data &&
                    this.state.fw_data.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle">{index + 1}</td>
                        <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                        <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                        <td className="text-center align-middle">
                          <span
                            className="text-primary pointer"
                            onClick={() => {
                              document_bt46(
                                [item],
                                this.state.emp_data,
                                this.state.data_workplace,
                                this.state.data_deputize,
                                this.state.data_salary,
                                {
                                  witness_contract_1: this.state.witness_contract_1,
                                  witness_contract_2_th: this.state.witness_contract_2_th,
                                  witness_contract_2_en: this.state.witness_contract_2_en,
                                },
                                this.state.data_prefix_th,
                                this.state.data_prefix_en,
                                this.state.data_work_type,
                                this.state.data_business_type
                              );
                            }}
                          >
                            บต.46 + สัญญาจ้าง.pdf
                          </span>
                        </td>
                        <td className="text-left align-middle">
                          {item.af_bt_46_document && (
                            <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                              บต.46 + สัญญาจ้าง.pdf
                            </a>
                          )}
                        </td>

                        <td className="text-center align-middle">
                          <span
                            className="text-primary pointer"
                            onClick={() => {
                              document_contact(
                                this.state.fw_data,
                                this.state.emp_data,
                                this.state.data_workplace,
                                this.state.data_deputize,
                                {
                                  witness_power_1: this.state.witness_power_1,
                                  witness_power_2_th: this.state.witness_power_2_th,
                                  witness_power_2_en: this.state.witness_power_2_en,
                                },
                                this.state.data_prefix_th,
                                this.state.data_prefix_en
                              );
                            }}
                          >
                            {" "}
                            ใบมอบอำนาจ.pdf
                          </span>
                        </td>
                        <td className="text-left align-middle">
                          {item.af_power_attorney_document && (
                            <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                              ใบมอบอำนาจ.pdf
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <button
                className="btn btn-primary px-5 mx-auto"
                onClick={() => {
                  this.setState({ modal_download: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* import file */}
        <Modal
          show={this.state.modal_import}
          onHide={() => {
            this.setState({ modal_import: false });
          }}
          size="md"
        >
          <Modal.Body className="p-2">
            <div className="bg-light card py-5 border-dashed align-items-center">
              <b className="icon text-primary mt-4" style={{ fontSize: 48 }}>
                {" "}
                {"\uf56f"}{" "}
              </b>
              <b className="text-secondary">เลือกไฟล์ที่นี่</b>
              <button
                className="btn btn-primary wpx-120 mb-4"
                onClick={() => {
                  document.getElementById("file").click();
                }}
              >
                เลือกไฟล์
              </button>
              <input
                className="d-none"
                type="file"
                name="file"
                id="file"
                accept=".xls,.xlsx"
                onChange={(e) => {
                  this.import_excel(e);
                }}
              />
            </div>
            {this.state.data_import && (
              <div className="card border-0 mb-3">
                <div className="p-3">
                  <label>จำนวนที่นำเข้า {this.state.data_import.length} รายการ</label>
                </div>
              </div>
            )}
            <div className="d-none">
              <ReactExcel initialData={this.state.import} onSheetUpdate={(currentSheet) => this.setState({ currentSheet: currentSheet })} activeSheetClassName="d-none" reactExcelClassName="table table-borderless table-striped" />
            </div>
            <div className="w-100 d-flex justify-content-end mt-3">
              <button
                className="btn btn-primary px-4 me-3"
                onClick={() => {
                  this.create_import();
                }}
              >
                นำเข้าข้อมูล
              </button>
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_import: false, import: null, currentSheet: null, over: false, data_import: null });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* สถานะงาน น้อยกว่า 50 */}
        <Modal
          show={this.state.modal_update}
          onHide={() => {
            this.setState({ modal_update: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* Matching */}
              {this.state.receive_status >= 5 && this.state.receive_status < 22 && ![3, 4, 5, 6, 7, 8, 11, 20].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 5 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 5 ? 0 : 5,
                      });
                    }}
                  >
                    {this.state.receive_date_s3 ? format_date(this.state.receive_date_s3, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s3 + " ใช้ USer Pass ของนายจ้าง" : this.state.receive_date_s5 ? format_date(this.state.receive_date_s5, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s5 + " Matching" : ""}
                  </div>
                  {this.state.show_tab === 5 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <b>{this.state.receive_date_s3 ? "ใช้ USer Pass ของนายจ้าง วันที่ " + format_date(this.state.receive_date_s3, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s3 : this.state.receive_date_s5 ? "Matching วันที่ " + format_date(this.state.receive_date_s5, "dd/mm/yyyy hh:mm") + " " + this.state.receive_by_s5 : ""}</b>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab 6 บันทึกลูกจ้างสำเร็จ */}
              {this.state.receive_status >= 6 && this.state.receive_status < 24 && this.state.receive_date_s6 && (
                <div>
                  <div
                    className={this.state.show_tab === 6 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 6 ? 0 : 6,
                      });
                    }}
                  >
                    {this.state.receive_date_s6 ? format_date(this.state.receive_date_s6, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s6} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 6 && (
                    <div className="card-body">
                      <b>
                        บันทึกลูกจ้างสำเร็จ วันที่ {this.state.receive_date_s6 ? format_date(this.state.receive_date_s6, "dd/mm/yyyy") : ""} {this.state.receive_by_s6}
                      </b>
                    </div>
                  )}
                </div>
              )}
              {/* tab 7 แนบเอกสาร บต.46 + ใบมอบอำนาจแล้ว  */}
              {this.state.receive_status >= 7 && this.state.receive_status < 22 && ![3, 4, 5, 6, 8, 11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 7 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 7 ? 0 : 7,
                      });
                    }}
                  >
                    {this.state.receive_date_s7 ? format_date(this.state.receive_date_s7, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s7} {this.state.menu_data.id === 7 ? "ตรวจสอบ ตม.๒๘ + ตม.๓๐" : "ตรวจสอบ บต.46+มอบอำนาจ"}
                  </div>
                  {this.state.show_tab === 7 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_date_s9 ? format_date(this.state.receive_date_s9, "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_by_s9}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab 9 */}
              {this.state.receive_status >= 9 && [11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 9 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 9 ? 0 : 9,
                      });
                    }}
                  >
                    {this.state.receive_date_s9 ? format_date(this.state.receive_date_s9, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s9} แนบเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 9 && (
                    <div className="card-body">
                      <div className="w-100 d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 9 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สำเนา Work</th>
                            <th className="text-left text-white">สำเนา Passport</th>
                            <th className="text-left text-white">สำเนา VISA</th>
                            <th className="text-left text-white">ทะเบียนบ้าน</th>
                            <th className="text-left text-white">บัตรประชาชน</th>
                            <th className="text-left text-white">รูปถ่ายคนงานพื้นขาว</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <th className="text-left text-white">
                                  {item.work_pm_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                        Work.pdf
                                      </a>
                                    </div>
                                  )}
                                </th>
                                <th className="text-left text-white">
                                  {item.passport_old_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                        Passport.pdf
                                      </a>
                                    </div>
                                  )}
                                </th>
                                <th className="text-left text-white">
                                  {item.visa_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                        VISA.pdf
                                      </a>
                                    </div>
                                  )}
                                </th>
                                <th className="text-left text-white">
                                  {item.attachment_name && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.attachment_name && item.attachment_name.includes("://") ? item.attachment_name : "data:application/pdf;base64," + item.attachment_name}>
                                        ทะเบียนบ้าน.pdf
                                      </a>
                                    </div>
                                  )}
                                </th>
                                <th className="text-left text-white">
                                  {item.id_card_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.id_card_image && item.id_card_image.includes("://") ? item.id_card_image : "data:application/pdf;base64," + item.id_card_image}>
                                        บัตรประชาชน.pdf
                                      </a>
                                    </div>
                                  )}
                                </th>
                                <th className="text-left text-white">
                                  {item.profile_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                        รูปถ่ายคนงานพื้นขาว.pdf
                                      </a>
                                    </div>
                                  )}
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 10 ส่งเอกสาร บต.46+มอบอำนาจ แล้ว  */}
              {this.state.receive_status >= 10 && this.state.receive_status !== 15 && this.state.receive_status < 22 && ![3, 4, 5, 6, 8, 11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 10 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 10 ? 0 : 10,
                      });
                    }}
                  >
                    {this.state.receive_date_s10 ? format_date(this.state.receive_date_s10, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s10}
                    {this.state.menu_data.id === 7 ? "จัดส่งเอกสารแล้ว" : "การจัดส่งเอกสาร บต.46 + มอบอำนาจ แล้ว"}
                  </div>
                  {this.state.show_tab === 10 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_by_s10}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 9 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab 11 รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย */}
              {this.state.receive_status >= 11 && this.state.receive_status !== 15 && this.state.receive_status < 22 && ![3, 4, 5, 6, 8, 11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 11 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 11 ? 0 : 11,
                      });
                    }}
                  >
                    {this.state.receive_date_s11 ? format_date(this.state.receive_date_s11, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s11} {this.state.menu_data.id === 7 ? "เอกสาร ตม.๒๘ + ตม.๓๐  ที่ลูกค้าเซ็นแล้วเข้าสู่ระบบ" : "เอกสาร บต.46 + ใบมอบอำนาจ ที่ลูกค้าเซ็นแล้วเข้าสู่ระบบ"}
                  </div>
                  {this.state.show_tab === 11 && (
                    <div className="card-body">
                      <div className="w-100 d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 10 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      {this.state.save_name_list ? (
                        <div className="my-3">
                          <b>บันทึก Name List :</b> {this.state.save_name_list}
                        </div>
                      ) : (
                        ""
                      )}
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            {![7].includes(this.state.menu_data.id) && <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>}
                            {![7].includes(this.state.menu_data.id) && <th className="text-left text-white">ใบมอบอำนาจ</th>}
                            {[7].includes(this.state.menu_data.id) && <th className="text-left text-white">ตม.๒๘</th>}
                            {[7].includes(this.state.menu_data.id) && <th className="text-left text-white">ตม.๓๐</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                {![7].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    {item.af_bt_46_document && (
                                      <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                        บต.46 + สัญญาจ้าง.pdf
                                      </a>
                                    )}
                                  </td>
                                )}
                                {![7].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    {item.af_power_attorney_document && (
                                      <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                                        ใบมอบอำนาจ.pdf
                                      </a>
                                    )}
                                  </td>
                                )}
                                {[7].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    {item.tm_28_document && (
                                      <a target="_blank" download href={item.tm_28_document && item.tm_28_document.includes("://") ? item.tm_28_document : "data:application/pdf;base64," + item.tm_28_document}>
                                        ตม.๒๘.pdf
                                      </a>
                                    )}
                                  </td>
                                )}
                                {[7].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    {item.tm_30_document && (
                                      <a target="_blank" download href={item.tm_30_document && item.tm_30_document.includes("://") ? item.tm_30_document : "data:application/pdf;base64," + item.tm_30_document}>
                                        ตม.๓๐.pdf
                                      </a>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 24 นายจ้างแนบเอกสารแล้ว  */}
              {(this.state.receive_status >= 24 || this.state.receive_status === 16 || this.state.receive_status === 20 || this.state.receive_status === 21) && [8].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 24 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 24 ? 0 : 24,
                      });
                    }}
                  >
                    บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 24 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                            <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">Passport เล่มเก่า</th>
                            <th className="text-left text-white">Passport เล่มใหม่</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                                <td className="text-left align-middle">{item.birth_date ? format_date(item.birth_date, "dd/mm/yyyy", "en") : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">
                                  {item.passport_old_image && (
                                    <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                      passport.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.passport_image && (
                                    <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                      passport.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 25 เอกสารลูกจ้างถูกต้อง  */}
              {(this.state.receive_status >= 25 || this.state.receive_status === 16 || this.state.receive_status === 20 || this.state.receive_status === 21) && [8].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 25 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 25 ? 0 : 25,
                      });
                    }}
                  >
                    ลงชื่อ Scan
                  </div>
                  {this.state.show_tab === 25 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        {this.state.documents_scan_name && (
                          <a target="_blank" download href={this.state.documents_scan_name && this.state.documents_scan_name.includes("://") ? this.state.documents_scan_name : "data:application/pdf;base64," + this.state.documents_scan_name}>
                            ลงชื่อ Scan.pdf
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab other นายจ้างแนบเอกสารแล้ว  */}
              {(this.state.receive_status === 16 || this.state.receive_status === 20 || this.state.receive_status === 21) && [8].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === "other" ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === "other" ? 0 : "other",
                      });
                    }}
                  >
                    บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === "other" && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">เลข VISA</th>
                            <th className="text-left text-white">วันที่เริ่ม</th>
                            <th className="text-left text-white">สถานที่ต่ออายุ</th>
                            <th className="text-left text-white">วันที่หมดอายุ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">{item.visa_number ? item.visa_number : "-"}</td>
                                <td className="text-left align-middle">{item.visa_allowed_date ? item.visa_allowed_date : "-"}</td>
                                <td className="text-left align-middle">{item.visa_allowed_location ? item.visa_allowed_location : "-"}</td>
                                <td className="text-left align-middle">{item.visa_expiry_date ? item.visa_expiry_date : "-"}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 15 แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน */}
              {this.state.receive_status >= 15 && this.state.receive_date_s15 && ![7, 8, 11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 15 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({ show_tab: this.state.show_tab === 15 ? 0 : 15 });
                    }}
                  >
                    {this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s15}
                    {[3, 4, 5, 6, 20].includes(this.state.menu_data.id) ? " รับเงินครบแล้ว" : " ดาวน์โหลด บต.50 + ใบเสร็จรับเงินแล้ว"}
                  </div>
                  {this.state.show_tab === 15 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">{[3, 4, 5, 6].includes(this.state.menu_data.id) ? "เลข passport" : "เลขบัตรประจำตัว"}</th>
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">เลข WP</th>}
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">บต.50</th>}
                            {[20].includes(this.state.menu_data.id) && <th className="text-left text-white">บันทึกรับเงิน</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ค่าต่อ 90 วัน</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ค่าต่อ VISA</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ค่าต่อ WORK</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">รวม</th>}
                            {![3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ใบเสร็จรับเงิน</th>}
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">ดาวน์โหลดแล้ว</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{[3, 4, 5, 6].includes(this.state.menu_data.id) ? (item.passport_number ? item.passport_number : "-") : item.fw_number ? item.fw_number : "-"}</td>
                                {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <td className="text-left align-middle">{item.wp_number ? item.fw_number : "-"}</td>}
                                {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                                  <td className="text-center align-middle">
                                    {item.bt_50_document && (
                                      <a target="_blank" download href={item.bt_50_document && item.bt_50_document.includes("://") ? item.bt_50_document : "data:application/pdf;base64," + item.bt_50_document}>
                                        บต.50.pdf
                                      </a>
                                    )}
                                    <br />
                                    <label>
                                      {this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}
                                    </label>
                                  </td>
                                )}
                                {[20].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    <div>
                                      <input className="me-2" type="checkbox" checked={item.fee_status} readOnly /> รับเงินแล้ว
                                    </div>
                                  </td>
                                )}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    <div>
                                      <input className="me-2" type="checkbox" checked={item.status_90d} readOnly /> ค่าต่อ 90 วัน
                                    </div>
                                  </td>
                                )}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    <div>
                                      <input className="me-2" type="checkbox" checked={item.status_visa} readOnly /> ค่าต่อ VISA
                                    </div>
                                  </td>
                                )}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    <div>
                                      <input className="me-2" type="checkbox" checked={item.status_work} readOnly /> ค่าต่อ WORK
                                    </div>
                                  </td>
                                )}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left align-middle">{Number(item.status_90d ? this.state.rate_list.filter((e) => e.remark.includes("90"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("90"))[0].vat : 0) + Number(item.status_visa ? this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].vat : 0) + Number(item.status_work ? this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].vat : 0)}</td>}
                                {![3, 4, 5, 6].includes(this.state.menu_data.id) && (
                                  <td className="text-center align-middle">
                                    {item.fee_document && (
                                      <a target="_blank" download href={item.fee_document && item.fee_document.includes("://") ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>
                                        ใบเสร็จรับเงิน.pdf
                                      </a>
                                    )}{" "}
                                    <br />
                                    <label>
                                      {this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}
                                    </label>
                                  </td>
                                )}
                                {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    <div className="d-flex align-items-center ps-4">
                                      <input className="mt-1 me-3" type="checkbox" disabled checked={item.is_download_s14 === true} />
                                      ดาวน์โหลดแล้ว
                                    </div>
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 16 จัดส่งเอกสารใบเสร็จ  */}
              {this.state.receive_status >= 16 && this.state.receive_status < 22 && ![7, 8, 11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 16 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 16 ? 0 : 16,
                      });
                    }}
                  >
                    {this.state.receive_date_s16 ? format_date(this.state.receive_date_s16, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s16}
                    {[3, 4, 5, 6].includes(this.state.menu_data.id) ? " ต่ออายุสำเร็จ" : this.state.menu_data.id === 7 ? "ตรวจสอบ ตม.๒๘ + ตม.๓๐ ครั้งสุดท้าย" : " เอกสารใบเสร็จ + ทะเบียนใบอนุญาตทำงาน"}
                  </div>
                  {this.state.show_tab === 16 && (
                    <div className="card-body">
                      <div className="w-100 d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: [3, 4, 5, 6].includes(this.state.menu_data.id) ? 23 : 14 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">เพศ</th>
                            <th className="text-left text-white">อายุ</th>
                            {![3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">เลข WP</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">วันที่หมดอายุ 90 วัน</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">วันที่หมดอายุ VISA</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">วันที่หมดอายุ WORK</th>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ต่ออายุสำเร็จ</th>}
                            <th className="text-left text-white">{![3, 4, 5, 6].includes(this.state.menu_data.id) ? "ใบเสร็จรับเงิน" : ""}</th>
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">ทะเบียนใบอนุญาตทำงาน</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left align-middle">{item.notice_90d_expiry_date ? format_date(item.notice_90d_expiry_date, "dd/mm/yyyy", "en") : "-"}</td>}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left align-middle">{item.visa_expiry_date ? format_date(item.visa_expiry_date, "dd/mm/yyyy", "en") : "-"}</td>}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left align-middle">{item.work_pm_expiry_date ? format_date(item.work_pm_expiry_date, "dd/mm/yyyy", "en") : "-"}</td>}
                                {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    <input type="checkbox" checked={item.document_renewal_status} readOnly />
                                  </td>
                                )}

                                {![3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>}
                                <td className="text-left align-middle">
                                  {item.fee_document && (
                                    <a target="_blank" download href={item.fee_document && item.fee_document.includes("://") ? item.fee_document : "data:application/pdf;base64," + item.receipt_document}>
                                      ใบเสร็จรับเงิน.pdf
                                    </a>
                                  )}
                                </td>
                                {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                                  <td className="text-left align-middle">
                                    {item.work_permit_document && (
                                      <a target="_blank" download href={item.work_permit_document && item.work_permit_document.includes("://") ? item.work_permit_document : "data:application/pdf;base64," + item.work_permit_document}>
                                        ทะเบียนใบอนุญาตทำงาน.pdf
                                      </a>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 17 ส่งใบเสร็จให้ลูกค้าแล้ว */}
              {this.state.receive_status >= 17 && this.state.receive_status < 22 && ![8, 11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 17 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 17 ? 0 : 17,
                      });
                    }}
                  >
                    {this.state.receive_date_s17 || this.state.receive_date_s16 ? format_date(this.state.receive_date_s17 || this.state.receive_date_s16, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s17 || this.state.receive_by_s16}
                    {[3, 4, 5, 6].includes(this.state.menu_data.id) ? " การจัดส่งเอกสาร" : this.state.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ แล้ว" : "ส่งเอกสารใบเสร็จ + ทะเบียนใบอนุญาตทำงาน แล้ว"}
                  </div>
                  {this.state.show_tab === 17 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          {this.state.menu_data.id !== 7 && (
                            <div className="d-flex mb-3">
                              <label className="wpx-150">ใบเสร็จ</label>
                              <label>
                                วันที่แนบไฟล์ {this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} ผู้แนบไฟล์ {this.state.receive_by_s17 || this.state.receive_by_s1}
                              </label>
                            </div>
                          )}
                          <b className="mb-3">{this.state.menu_data.id === 7 ? "การจัดส่งเอกสาร ตม.๒๘ + ตม.๓๐" : "การจัดส่งเอกสารใบเสร็จ"}</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_by_s17 || this.state.receive_by_s1}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 16 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab 19 รับ eWork แล้ว รอจัดส่ง   */}
              {(this.state.receive_status >= 19 || (this.state.receive_status === 18 && this.state.fw_data.filter((e) => !e.e_work_card_issued).length === 0)) && this.state.receive_status < 22 && ![3, 4, 5, 6, 8, 11, 20].includes(this.state.menu_data.id) && (
                <div>
                  {this.state.receive_status === 18 ? (
                    <div
                      className={this.state.show_tab === 19 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                      onClick={() => {
                        this.setState({
                          show_tab: this.state.show_tab === 19 ? 0 : 19,
                        });
                      }}
                    >
                      {this.state.receive_date_s18 ? format_date(this.state.receive_date_s18, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s18} {this.state.menu_data.id === 7 ? "เอกสาร ตม.๒๘ + ตม.๓๐ ที่มีตราประทับแล้วเข้าสู่ระบบ" : "ออก eWork แล้ว"}
                    </div>
                  ) : (
                    <div
                      className={this.state.show_tab === 19 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                      onClick={() => {
                        this.setState({
                          show_tab: this.state.show_tab === 19 ? 0 : 19,
                        });
                      }}
                    >
                      {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} {this.state.menu_data.id === 7 ? "เอกสาร ตม.๒๘ + ตม.๓๐ ที่มีตราประทับแล้วเข้าสู่ระบบ" : "ออก eWork แล้ว"}
                    </div>
                  )}
                  {this.state.show_tab === 19 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 18, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      {[7].includes(this.state.menu_data.id) ? (
                        <table className="table table-borderless table-striped">
                          <thead className="bg-primary">
                            <tr>
                              <th className="text-center text-white">ลำดับ</th>
                              <th className="text-left text-white">ชื่อ-นามสกุล</th>
                              <th className="text-left text-white">เลขบัตรประจำตัว</th>
                              <th className="text-left text-white">ตม.๒๘</th>
                              <th className="text-left text-white">ตม.๓๐</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.fw_data &&
                              this.state.fw_data.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">{index + 1}</td>
                                  <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                  <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                  <td className="text-left align-middle">
                                    <div className="d-flex align-items-center">
                                      {!item.tm_28_document_after ? (
                                        <button
                                          className="btn btn-primary mx-1 px-4"
                                          accept="application/pdf"
                                          onClick={() => {
                                            this.setState({ index: index });
                                            document.getElementById("file_tm_28_document_after").click();
                                          }}
                                        >
                                          แนบไฟล์
                                        </button>
                                      ) : (
                                        <div className="w-100 d-flex justify-content-start">
                                          <a target="_blank" download href={item.tm_28_document_after && item.tm_28_document_after.includes("://") ? item.tm_28_document_after : "data:application/pdf;base64," + item.tm_28_document_after}>
                                            ตม.๒๘.pdf
                                          </a>
                                          <div
                                            className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                            onClick={() => {
                                              let fw_data = this.state.fw_data;
                                              fw_data[index].tm_28_document_after = "";
                                              this.setState({ fw_data: fw_data });
                                            }}
                                          >
                                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td className="text-left align-middle">
                                    <div className="d-flex align-items-center">
                                      {!item.tm_30_document_after ? (
                                        <button
                                          className="btn btn-primary mx-1 px-4"
                                          accept="application/pdf"
                                          onClick={() => {
                                            this.setState({ index: index });
                                            document.getElementById("file_tm_30_document_after").click();
                                          }}
                                        >
                                          แนบไฟล์
                                        </button>
                                      ) : (
                                        <div className="w-100 d-flex justify-content-start">
                                          <a target="_blank" download href={item.tm_30_document_after && item.tm_30_document_after.includes("://") ? item.tm_30_document_after : "data:application/pdf;base64," + item.tm_30_document_after}>
                                            ตม.๓๐.pdf
                                          </a>
                                          <div
                                            className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                            onClick={() => {
                                              let fw_data = this.state.fw_data;
                                              fw_data[index].tm_30_document_after = "";
                                              this.setState({ fw_data: fw_data });
                                            }}
                                          >
                                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <table className="table table-borderless table-striped">
                          <thead className="bg-primary">
                            <tr>
                              <th className="text-center text-white">ลำดับ</th>
                              <th className="text-left text-white">ชื่อ-นามสกุล</th>
                              <th className="text-left text-white">เลขบัตรประจำตัว</th>
                              <th className="text-left text-white">เลข WP</th>
                              <th className="text-left text-white">eWork</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.fw_data &&
                              this.state.fw_data.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">{index + 1}</td>
                                  <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                  <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                  <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                  <td className="text-left align-middle">
                                    <div className="d-flex align-items-center ps-4">
                                      <input className="mt-1 me-3" type="checkbox" checked={item.e_work_card_issued === true} disabled />
                                      ออกบัตรแล้ว
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* tab 26   */}
              {this.state.receive_status >= 19 && this.state.receive_status < 22 && [11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 26 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 26 ? 0 : 26,
                      });
                    }}
                  >
                    {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} วันเวลานัดรับ passport
                  </div>
                  {this.state.show_tab === 26 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex mb-3">
                            <label className="wpx-150">วันเวลานัดรับ</label>
                            <label>
                              {this.state.due_location ? this.state.due_location : ""} <br />
                              {this.state.due_date ? format_date(this.state.due_date, "dd/mm/yyyy") : ""} <br />
                              {this.state.due_remark ? this.state.due_remark : ""}
                            </label>
                          </div>
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 16 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab 19 รับ eWork แล้ว รอจัดส่ง   */}
              {this.state.receive_status >= 19 && this.state.receive_status < 22 && ![3, 4, 5, 6, 7, 8, 11, 20].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 21 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 21 ? 0 : 21,
                      });
                    }}
                  >
                    {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} รับ eWork แล้ว
                  </div>
                  {this.state.show_tab === 21 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 18, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">รับ eWork</th>
                            <th className="text-left text-white">บัตร eWork</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  <div className="d-flex align-items-center ps-4">
                                    <input className="mt-1 me-3" type="checkbox" checked={item.e_work_card_received === true} disabled />
                                    รับบัตรแล้ว
                                  </div>
                                </td>
                                <td className="text-left align-middle">
                                  {item.e_received_document && (
                                    <a className="mx-3" target="_blank" download href={item.e_received_document && item.e_received_document.includes("://") ? item.e_received_document : "data:application/pdf;base64," + item.e_received_document}>
                                      เอกสารแนบ.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 19 รับ eWork แล้ว รอจัดส่ง   */}
              {this.state.receive_status >= 19 && this.state.receive_status < 22 && [11].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 28 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 28 ? 0 : 28,
                      });
                    }}
                  >
                    {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19}
                    รับ Passport แล้ว
                  </div>
                  {this.state.show_tab === 28 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-end mb-3">
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 18, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">Scan ไฟล์ Passport</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">
                                  {item.passport_image && (
                                    <a className="mx-3" target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                      Passport.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* tab 20 ส่ง eWork แล้ว  */}
              {this.state.receive_status >= 20 && this.state.receive_status < 22 && ![3, 4, 5, 6, 8, 11, 20].includes(this.state.menu_data.id) && (
                <div>
                  <div
                    className={this.state.show_tab === 20 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 20 ? 0 : 20,
                      });
                    }}
                  >
                    {this.state.receive_date_s20 || this.state.receive_date_s19 ? format_date(this.state.receive_date_s20 || this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s20 || this.state.receive_by_s19} {[3, 4, 5, 6].includes(this.state.menu_data.id) ? "รับเงินครบแล้ว" : this.state.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ ให้นายจ้างแล้ว" : "การจัดส่งเอกสาร eWork"}
                  </div>
                  {this.state.show_tab === 20 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">{[3, 4, 5, 6].includes(this.state.menu_data.id) ? "รับเงินครบแล้ว" : this.state.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ ให้นายจ้างแล้ว" : "การจัดส่งเอกสาร eWork"}</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_by_s20 || this.state.receive_by_s19}
                          </label>
                          {this.state.documents_s19 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 19, show_tab: "" });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* tab 21 ปิดงาน  */}
              {this.state.receive_status >= 21 && this.state.receive_status < 22 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_date_s21 ? format_date(this.state.receive_date_s21, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s21} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 7 รอตรวจสอบ บต.46+มอบอำนาจ  */}
            {this.state.receive_status === 7 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รอตรวจสอบ บต.46 + หนังสือมอบอำนาจ </b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>
                      <th className="text-left text-white">ใบมอบอำนาจ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.bt_46_document && (
                              <a target="_blank" download href={item.bt_46_document && item.bt_46_document.includes("://") ? item.bt_46_document : "data:application/pdf;base64," + item.bt_46_document}>
                                บต.46 + สัญญาจ้าง.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.power_attorney_document && (
                              <a target="_blank" download href={item.power_attorney_document && item.power_attorney_document.includes("://") ? item.power_attorney_document : "data:application/pdf;base64," + item.power_attorney_document}>
                                ใบมอบอำนาจ.pdf
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 8 เอกสารไม่ถูกต้อง บต.46  */}
            {this.state.receive_status === 8 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต - ใบมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต.46 ไม่ถูกต้อง</label>
                <br />
                <label>
                  ผู้ตรวจสอบ {this.state.receive_by_s8} วันที่ตรวจสอบ {this.state.receive_date_s8 ? format_date(this.state.receive_date_s8, "dd/mm/yyyy") : ""}
                </label>
              </div>
            )}
            {/* status 9 เอกสาร บต.46+มอบอำนาจ ถูกต้อง   */}
            {this.state.receive_status === 9 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>{this.state.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ ให้ลูกค้า" : "ส่งเอกสาร บต.46 + ใบมอบอำนาจ ให้ลูกค้า"}</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Line" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s9 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s9: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s9 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s9 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s9: e.target.value });
                        }}
                        value={this.state.sending_messenger_s9}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s9: e });
                      }}
                      value={this.state.sending_document_date_s9}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s9 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s9").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s9: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s9: e.target.value });
                      }}
                      value={this.state.remark_s9}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid) === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 10 ส่งเอกสาร บต.46+มอบอำนาจ แล้ว   */}
            {this.state.receive_status === 10 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>{[7].includes(this.state.menu_data.id) ? "แนบ เอกสาร ตม.๒๘ + ตม.๓๐" : "แนบ เอกสาร บต.46 + ใบมอบอำนาจ ที่ลูกค้าเซ็นแล้วเข้าสู่ระบบ"} </b>
                  </h6>
                  {this.state.menu_data.id !== 6 && (
                    <div className="d-flex align-items-center">
                      {this.state.fw_data[0] && !this.state.fw_data[0].af_power_attorney_document ? (
                        <button
                          className="btn btn-primary mx-1 px-4"
                          accept="application/pdf"
                          onClick={() => {
                            this.setState({ index: 0 });
                            document.getElementById("file_af_power_attorney_document").click();
                          }}
                        >
                          แนบไฟล์ ใบมอบอำนาจ
                        </button>
                      ) : (
                        <div className="w-100 d-flex justify-content-start">
                          <a target="_blank" download href={this.state.fw_data[0].af_power_attorney_document && this.state.fw_data[0].af_power_attorney_document.includes("://") ? this.state.fw_data[0].af_power_attorney_document : "data:application/pdf;base64," + this.state.fw_data[0].af_power_attorney_document}>
                            ใบมอบอำนาจ.pdf
                          </a>
                          <div
                            className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                            onClick={() => {
                              let fw_data = this.state.fw_data;
                              fw_data[0].af_power_attorney_document = "";
                              this.setState({ fw_data: fw_data });
                            }}
                          >
                            <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      {![7].includes(this.state.menu_data.id) && <th className="text-left text-white">{this.state.menu_data.id === 6 ? "เอกสาร" : "บต.46 + สัญญาจ้าง"}</th>}
                      {[7].includes(this.state.menu_data.id) && <th className="text-left text-white">ตม.๒๘</th>}
                      {[7].includes(this.state.menu_data.id) && <th className="text-left text-white">ตม.๓๐</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          {![7].includes(this.state.menu_data.id) && (
                            <td className="text-left align-middle">
                              <div className="d-flex align-items-center">
                                {!item.af_bt_46_document ? (
                                  <button
                                    className="btn btn-primary mx-1 px-4"
                                    accept="application/pdf"
                                    onClick={() => {
                                      this.setState({ index: index });
                                      document.getElementById("file_af_bt_46_document").click();
                                    }}
                                  >
                                    แนบไฟล์
                                  </button>
                                ) : (
                                  <div className="w-100 d-flex justify-content-start">
                                    <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                      {this.state.menu_data.id === 6 ? "เอกสาร.pdf" : "บต.46 + สัญญาจ้าง.pdf"}
                                    </a>
                                    <div
                                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                      onClick={() => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].af_bt_46_document = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                    >
                                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                          {[7].includes(this.state.menu_data.id) && (
                            <td className="text-left align-middle">
                              <div className="d-flex align-items-center">
                                {!item.tm_28_document ? (
                                  <button
                                    className="btn btn-primary mx-1 px-4"
                                    accept="application/pdf"
                                    onClick={() => {
                                      this.setState({ index: index });
                                      document.getElementById("file_tm_28_document").click();
                                    }}
                                  >
                                    แนบไฟล์
                                  </button>
                                ) : (
                                  <div className="w-100 d-flex justify-content-start">
                                    <a target="_blank" download href={item.tm_28_document && item.tm_28_document.includes("://") ? item.tm_28_document : "data:application/pdf;base64," + item.tm_28_document}>
                                      ตม.๒๘.pdf
                                    </a>
                                    <div
                                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                      onClick={() => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].tm_28_document = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                    >
                                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                          {[7].includes(this.state.menu_data.id) && (
                            <td className="text-left align-middle">
                              <div className="d-flex align-items-center">
                                {!item.tm_30_document ? (
                                  <button
                                    className="btn btn-primary mx-1 px-4"
                                    accept="application/pdf"
                                    onClick={() => {
                                      this.setState({ index: index });
                                      document.getElementById("file_tm_30_document").click();
                                    }}
                                  >
                                    แนบไฟล์
                                  </button>
                                ) : (
                                  <div className="w-100 d-flex justify-content-start">
                                    <a target="_blank" download href={item.tm_30_document && item.tm_30_document.includes("://") ? item.tm_30_document : "data:application/pdf;base64," + item.tm_30_document}>
                                      ตม.๓๐.pdf
                                    </a>
                                    <div
                                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                      onClick={() => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].tm_30_document = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                    >
                                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 11 เอกสาร บต.46+มอบอำนาจ ไม่ถูกต้อง   */}
            {this.state.receive_status === 11 && this.state.fw_data && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต - ใบมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต.46+มอบอำนาจ ครั้งสุดท้าย ไม่ถูกต้อง</label>
                <br />
                <label>
                  ผู้ตรวจสอบ {this.state.receive_by_s11} วันที่ตรวจสอบ {this.state.receive_date_s11 ? format_date(this.state.receive_date_s11, "dd/mm/yyyy") : ""}
                </label>
              </div>
            )}
            {/* status 12 เอกสารถูกต้อง พร้อมยื่น */}
            {this.state.receive_status === 12 && (
              <div>
                <h5 className="mb-3">
                  <b>เอกสารถูกต้อง พร้อมยื่น</b>
                </h5>
                {this.state.menu_data.id === 20 && (
                  <div className="d-flex align-items-center mb-3">
                    <span>บันทึก Name List</span>
                    <input
                      className="form-control mx-3 wpx-300"
                      type="text"
                      onChange={(e) => {
                        this.setState({ save_name_list: e.target.value });
                      }}
                      value={this.state.save_name_list}
                    />
                  </div>
                )}
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">บต.46 + สัญญาจ้าง</th>
                      <th className="text-left text-white">ใบมอบอำนาจ</th>
                      <th className="text-center text-white">ผลการตรวจเอกสาร</th>
                      <th className="text-left text-white">{this.state.menu_data.id === 20 ? "บันทึกเลขประจำตัว" : "เลข WP"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.af_bt_46_document && (
                              <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                บต.46 + สัญญาจ้าง.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.af_power_attorney_document && (
                              <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                                ใบมอบอำนาจ.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-center align-middle">
                            <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                          </td>
                          <td className="text-center align-middle">
                            {this.state.menu_data.id === 20 ? (
                              <input
                                className="form-control mt-1"
                                type="text"
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].fw_number = e.target.value;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.fw_number}
                              />
                            ) : (
                              <input
                                className="form-control mt-1"
                                type="text"
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].wp_number = e.target.value;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.wp_number}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 13 ได้เลข WP */}
            {this.state.receive_status === 13 && (
              <div>
                <h5 className="mb-3">
                  <b>ตรวจเอกสารครั้งสุดท้าย</b>
                </h5>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">
                        เลข WP ({this.state.count_wp}/{this.state.fw_data.length})
                      </th>
                      <th className="text-left text-white">แก้ไขเลข WP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-center align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                          <td className="text-center align-middle">
                            <div
                              className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-120 m-auto"
                              onClick={() => {
                                this.setState({
                                  modal_update: false,
                                  modal_update_wp: true,
                                  index: index,
                                  wp_number: item.wp_number,
                                });
                              }}
                            >
                              แก้ไขเลข WP
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 14 ได้เลข WP แล้ว รออนุมัติ    */}
            {this.state.receive_status === 14 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>โหลด บต.50 และใบเสร็จรับเงิน</b>
                  </h6>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control wpx-200"
                    placeholder="ค้นหา..."
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    value={this.state.search}
                  />
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                      <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">{[3, 4, 5, 6].includes(this.state.menu_data.id) ? "เลข passport" : "เลขบัตรประจำตัว"}</th>
                      {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">เลข WP</th>}
                      {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">บต.50</th>}
                      {[20].includes(this.state.menu_data.id) && (
                        <th className="text-left text-white">
                          <input
                            type="checkbox"
                            className="me-3"
                            checked={this.state.fw_data.findIndex((e) => !e.fee_status) === -1}
                            onChange={(event) => {
                              let fw_data = this.state.fw_data;
                              fw_data = fw_data.map((e) => {
                                return { ...e, fee_status: event.target.checked };
                              });
                              this.setState({ fw_data: fw_data });
                            }}
                          />
                          บันทึกรับเงิน
                        </th>
                      )}
                      {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ค่าต่อ 90 วัน</th>}
                      {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ค่าต่อ VISA</th>}
                      {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">ค่าต่อ WORK</th>}
                      {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white">รวม</th>}
                      {[3, 4, 5, 6].includes(this.state.menu_data.id) && <th className="text-left text-white"></th>}
                      {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">ใบเสร็จรับเงิน</th>}
                      {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <th className="text-left text-white">โหลด บต.50 และใบเสร็จรับเงิน</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data
                        .filter((e) => (e.first_name_th && e.first_name_th.includes(this.state.search)) || (e.first_name_en && e.first_name_en.includes(this.state.search)) || (e.fw_number && e.fw_number.includes(this.state.search)))
                        .map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">{index + 1}</td>
                            <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                            <td className="text-left align-middle">{item.birth_date ? format_date(item.birth_date, "dd/mm/yyyy", "en") : "-"}</td>
                            <td className="text-left align-middle">
                              {item.nationality === 1 ? "เมียนมา" : ""}
                              {item.nationality === 2 ? "ลาว" : ""}
                              {item.nationality === 3 ? "กัมพูชา" : ""}
                              {item.nationality === 4 ? "เวียดนาม" : ""}
                            </td>
                            <td className="text-left align-middle">{[3, 4, 5, 6].includes(this.state.menu_data.id) ? (item.passport_number ? item.passport_number : "-") : item.fw_number ? item.fw_number : "-"}</td>
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>}
                            {[20].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={item.fee_status}
                                    onChange={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].fee_status = !fw_data[index].fee_status;
                                      if (fw_data[index].fee_status) {
                                        fw_data[index].fee_date = format_date(new Date(), "yyyy-mm-dd hh:mm");
                                        fw_data[index].fee_udpdate_by = this.state.admin_fullname;
                                      } else {
                                        fw_data[index].fee_date = null;
                                        fw_data[index].fee_udpdate_by = null;
                                      }
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  />{" "}
                                  รับเงินแล้ว
                                </div>
                              </td>
                            )}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={item.status_90d}
                                    onChange={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].status_90d = !fw_data[index].status_90d;
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  />{" "}
                                  ค่าต่อ 90 วัน
                                </div>
                              </td>
                            )}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={item.status_visa}
                                    onChange={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].status_visa = !fw_data[index].status_visa;
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  />{" "}
                                  ค่าต่อ VISA
                                </div>
                              </td>
                            )}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    checked={item.status_work}
                                    onChange={() => {
                                      let fw_data = this.state.fw_data;
                                      fw_data[index].status_work = !fw_data[index].status_work;
                                      this.setState({ fw_data: fw_data });
                                    }}
                                  />{" "}
                                  ค่าต่อ WORK
                                </div>
                              </td>
                            )}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left align-middle">{Number(item.status_90d ? this.state.rate_list.filter((e) => e.remark.includes("90"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("90"))[0].vat : 0) + Number(item.status_visa ? this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].vat : 0) + Number(item.status_work ? this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].vat : 0)}</td>}
                            {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle d-flex">
                                <button
                                  className="btn btn-sm btn-primary px-3 me-2"
                                  onClick={() => {
                                    console.log(item);
                                    console.log(this.state.rate_list);
                                    let rate_list = [];
                                    if (item.status_90d) {
                                      rate_list.push(this.state.rate_list.find((e) => e.remark === "งาน 90 วัน"));
                                    }
                                    if (item.status_visa) {
                                      rate_list.push(this.state.rate_list.find((e) => e.remark === "งาน ต่อ Visa"));
                                    }
                                    if (item.status_work) {
                                      rate_list.push(this.state.rate_list.find((e) => e.remark === "งาน ต่อ Work"));
                                    }
                                    let data_item = {
                                      rate_list: rate_list,
                                      ...item,
                                    };
                                    document_receipt_by_person(data_item, this.state.data_header);
                                  }}
                                >
                                  พิมพ์ใบเสร็จ
                                </button>
                                <button
                                  className="btn btn-sm btn-primary px-3"
                                  onClick={() => {
                                    this.Update_fw();
                                  }}
                                >
                                  บันทึก
                                </button>
                              </td>
                            )}
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div className="d-flex align-items-center">
                                  {item.bt_50_document ? (
                                    <button
                                      className="btn btn-primary mx-1 px-4"
                                      accept="application/pdf"
                                      onClick={() => {
                                        this.setState({ index: index });
                                        document.getElementById("file_bt_50_document").click();
                                      }}
                                    >
                                      แนบไฟล์
                                    </button>
                                  ) : (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.bt_50_document && item.bt_50_document.includes("://") ? item.bt_50_document : "data:application/pdf;base64," + item.bt_50_document}>
                                        บต.50.pdf
                                      </a>
                                      <div
                                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                        onClick={() => {
                                          let fw_data = this.state.fw_data;
                                          fw_data[index].bt_50_document = "";
                                          this.setState({ fw_data: fw_data });
                                        }}
                                      >
                                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div className="d-flex align-items-center">
                                  {!item.fee_document ? (
                                    <button
                                      className="btn btn-primary mx-1 px-4"
                                      accept="application/pdf"
                                      onClick={() => {
                                        this.setState({ index: index });
                                        document.getElementById("file_fee_document").click();
                                      }}
                                    >
                                      แนบไฟล์
                                    </button>
                                  ) : (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.fee_document && item.fee_document.includes("://") ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>
                                        ใบเสร็จรับเงิน.pdf
                                      </a>
                                      <div
                                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                        onClick={() => {
                                          let fw_data = this.state.fw_data;
                                          fw_data[index].fee_document = "";
                                          this.setState({ fw_data: fw_data });
                                        }}
                                      >
                                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                            {/* {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left">{item.fee_date ?format_date(item.fee_date,"dd/mm/yyyy hh:mm","en"):"-"}</td>} */}
                            {/* {[3, 4, 5, 6].includes(this.state.menu_data.id) && <td className="text-left">{item.fee_udpdate_by ? item.fee_udpdate_by:"-"}</td>} */}
                            {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                              <td className="text-left align-middle">
                                <div className="d-flex align-items-center">
                                  {item.fee_document && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.fee_document && item.fee_document.includes("://") ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>
                                        ใบเสร็จรับเงิน.pdf
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                            {/* {![3, 4, 5, 6, 20].includes(this.state.menu_data.id) && (
                            <td className="text-left align-middle">
                              <div className="d-flex align-items-center ps-4">
                                <input
                                  className="mt-1 me-3"
                                  type="checkbox"
                                  onChange={(e) => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].is_download_s14 = !fw_data[index].is_download_s14;
                                    this.setState({ fw_data: fw_data });
                                  }}
                                  checked={item.is_download_s14 === true}
                                />
                                ดาวน์โหลดแล้ว
                              </div>
                            </td>
                          )} */}
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 16 จัดส่งเอกสารใบเสร็จ */}
            {this.state.receive_status === 16 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>{this.state.menu_data.id === 7 ? "จัดส่งเอกสาร ตม.๒๘ + ตม.๓๐" : "จัดส่งเอกสารใบเสร็จ + ทะเบียนใบอนุญาต"}</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Line" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s16 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s16: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s16 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s16 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s16: e.target.value });
                        }}
                        value={this.state.sending_messenger_s16}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s16: e });
                      }}
                      value={this.state.sending_document_date_s16}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s16 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s16").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s16: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s16: e.target.value });
                      }}
                      value={this.state.remark_s16}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 17 // 18 ออก eWork แล้ว */}
            {(this.state.receive_status === 17 || this.state.receive_status === 18) && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>{this.state.menu_data.id === 7 ? "แนบเอกสาร ตม.๒๘ + ตม.๓๐ ที่ประทับตราเข้าสู่ระบบ" : "รอรับ eWork"}</b>
                  </h6>
                </div>
                {[7].includes(this.state.menu_data.id) ? (
                  <table className="table table-borderless table-striped">
                    <thead className="bg-primary">
                      <tr>
                        <th className="text-center text-white">ลำดับ</th>
                        <th className="text-left text-white">ชื่อ-นามสกุล</th>
                        <th className="text-left text-white">เลขบัตรประจำตัว</th>
                        <th className="text-left text-white">ตม.๒๘</th>
                        <th className="text-left text-white">ตม.๓๐</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.fw_data &&
                        this.state.fw_data.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">{index + 1}</td>
                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                            <td className="text-left align-middle">
                              <div className="d-flex align-items-center">
                                {!item.tm_28_document_after ? (
                                  <button
                                    className="btn btn-primary mx-1 px-4"
                                    accept="application/pdf"
                                    onClick={() => {
                                      this.setState({ index: index });
                                      document.getElementById("file_tm_28_document_after").click();
                                    }}
                                  >
                                    แนบไฟล์
                                  </button>
                                ) : (
                                  <div className="w-100 d-flex justify-content-start">
                                    <a target="_blank" download href={item.tm_28_document_after && item.tm_28_document_after.includes("://") ? item.tm_28_document_after : "data:application/pdf;base64," + item.tm_28_document_after}>
                                      ตม.๒๘.pdf
                                    </a>
                                    <div
                                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                      onClick={() => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].tm_28_document_after = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                    >
                                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="text-left align-middle">
                              <div className="d-flex align-items-center">
                                {!item.tm_30_document_after ? (
                                  <button
                                    className="btn btn-primary mx-1 px-4"
                                    accept="application/pdf"
                                    onClick={() => {
                                      this.setState({ index: index });
                                      document.getElementById("file_tm_30_document_after").click();
                                    }}
                                  >
                                    แนบไฟล์
                                  </button>
                                ) : (
                                  <div className="w-100 d-flex justify-content-start">
                                    <a target="_blank" download href={item.tm_30_document_after && item.tm_30_document_after.includes("://") ? item.tm_30_document_after : "data:application/pdf;base64," + item.tm_30_document_after}>
                                      ตม.๓๐.pdf
                                    </a>
                                    <div
                                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                      onClick={() => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].tm_30_document_after = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                    >
                                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <table className="table table-borderless table-striped">
                    <thead className="bg-primary">
                      <tr>
                        <th className="text-center text-white">ลำดับ</th>
                        <th className="text-left text-white">ชื่อ-นามสกุล</th>
                        <th className="text-left text-white">เลขบัตรประจำตัว</th>
                        <th className="text-left text-white">สัญชาติ</th>
                        <th className="text-left text-white">เลข WP</th>
                        {/* <th className="text-left text-white">บต.50</th>
                                            <th className="text-left text-white">ใบเสร็จรับเงิน</th> */}
                        <th className="text-center text-white">ออก eWork</th>
                        <th className="text-center text-white">รับ eWork</th>
                        <th className="text-left text-white">แนบไฟล์ eWork</th>
                        {/* <th className="text-center text-white">แนบไฟล์ รับ eWork</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.fw_data &&
                        this.state.fw_data.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">{index + 1}</td>
                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                            <td className="text-left align-middle">
                              {item.nationality === 1 ? "เมียนมา" : ""}
                              {item.nationality === 2 ? "ลาว" : ""}
                              {item.nationality === 3 ? "กัมพูชา" : ""}
                              {item.nationality === 4 ? "เวียดนาม" : ""}
                            </td>
                            <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                            {/* <td className="text-center align-middle">
                                {item.bt_50_document && (
                                    <a target="_blank" download href={(item.bt_50_document && item.bt_50_document.includes("://")) ? item.bt_50_document : "data:application/pdf;base64," + item.bt_50_document}>บต.50.pdf</a>
                                )}
                                <br />
                                <label>{this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}</label>
                            </td>
                            <td className="text-center align-middle">
                                {item.fee_document && (
                                    <a target="_blank" download href={(item.fee_document && item.fee_document.includes("://")) ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>ใบเสร็จรับเงิน.pdf</a>
                                )} <br />
                                <label>{this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}</label>
                            </td> */}
                            <td className="text-center align-middle">
                              <div className="d-flex align-items-center ps-4">
                                <input
                                  className="mt-1 me-3"
                                  type="checkbox"
                                  onChange={(e) => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].e_work_card_issued = !fw_data[index].e_work_card_issued;
                                    this.setState({ fw_data: fw_data });
                                  }}
                                  checked={item.e_work_card_issued === true}
                                />
                                ออกบัตรแล้ว
                              </div>
                              {item.e_work_card_issued_date ? format_date(item.e_work_card_issued_date, "dd/mm/yyyy hh:mm") : ""}
                            </td>
                            <td className="text-center align-middle">
                              <div className="d-flex align-items-center ps-4">
                                <input
                                  className="mt-1 me-3"
                                  type="checkbox"
                                  onChange={(e) => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].e_work_card_received = !fw_data[index].e_work_card_received;
                                    this.setState({ fw_data: fw_data });
                                  }}
                                  checked={item.e_work_card_received === true}
                                />
                                รับบัตรแล้ว
                              </div>
                              {item.e_work_card_received_date ? format_date(item.e_work_card_received_date, "dd/mm/yyyy hh:mm") : ""}
                            </td>
                            <td className="text-center align-middle">
                              <div className="d-flex align-items-center">
                                {!item.e_work_document ? (
                                  <button
                                    className="btn btn-primary mx-1 px-4"
                                    accept="application/pdf"
                                    onClick={() => {
                                      this.setState({ index: index });
                                      document.getElementById("file_e_work_document").click();
                                    }}
                                  >
                                    แนบไฟล์
                                  </button>
                                ) : (
                                  <div className="w-100 d-flex justify-content-center">
                                    <a target="_blank" download href={item.e_work_document && item.e_work_document.includes("://") ? item.e_work_document : "data:application/pdf;base64," + item.e_work_document}>
                                      eWork.pdf
                                    </a>
                                    <div
                                      className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                      onClick={() => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].e_work_document = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                    >
                                      <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            {/* <td className="text-center align-middle">
                              <div className="d-flex align-items-center">
                                  {!item.e_received_document ? (
                                      <button
                                          className="btn btn-primary mx-1 px-4"
                                          accept="application/pdf"
                                          onClick={() => { this.setState({ index: index }); document.getElementById("file_e_received_document").click() }}>
                                          แนบไฟล์
                                      </button>
                                  ) : (
                                      <div className="w-100 d-flex justify-content-center">
                                          <a target="_blank" download href={(item.e_received_document && item.e_received_document.includes("://")) ? item.e_received_document : "data:application/pdf;base64," + item.e_received_document}>eWork.pdf</a>
                                          <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                                              onClick={() => {
                                                  let fw_data = this.state.fw_data;
                                                  fw_data[index].e_received_document = "";
                                                  this.setState({ fw_data: fw_data });
                                              }}>
                                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                          </div>
                                      </div>
                                  )}
                              </div>
                          </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
            {/* status 19 รับ eWork แล้ว รอจัดส่ง */}
            {this.state.receive_status === 19 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                {this.state.menu_data.id === 11 ? (
                  <h6 className="mb-3">
                    <b>จัดส่งเอกสาร Passport</b>
                  </h6>
                ) : this.state.menu_data.id === 7 ? (
                  <h6 className="mb-3">
                    <b>จัดส่งเอกสาร ตม.๒๘ + ตม.๓๐</b>
                  </h6>
                ) : (
                  <div className="d-flex align-items-center mb-3">
                    <h6 className="mb-0">
                      <b>จัดส่งเอกสาร eWork</b>
                    </h6>
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={async () => {
                        var merger = new PDFMerger();
                        let fw_data = this.state.fw_data;
                        for (let fw of fw_data) {
                          if (fw.e_work_document) {
                            await merger.add(fw.e_work_document);
                          }
                        }
                        await merger.setMetadata({
                          producer: "pdf-merger-js based script",
                          author: "ใบนำส่ง eWork",
                          creator: "ใบนำส่ง eWork",
                          title: "ใบนำส่ง eWork",
                        });
                        const mergedPdf = await merger.saveAsBlob();
                        const url = URL.createObjectURL(mergedPdf);
                        var myWindow = window.open();
                        myWindow.document.write('<iframe src="' + url + '" title="pdf-viewer" width="100%" height="99%" ></iframe>');
                      }}
                    >
                      ใบนำส่ง eWork
                    </button>
                  </div>
                )}
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Line" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s19 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s19: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s19 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s19 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s19: e.target.value });
                        }}
                        value={this.state.sending_messenger_s19}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s19: e });
                      }}
                      value={this.state.sending_document_date_s19}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s19 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s19").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s19: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s19: e.target.value });
                      }}
                      value={this.state.remark_s19}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 20 ส่ง eWork แล้ว */}
            {this.state.receive_status === 20 && (
              <div>
                {[3, 4, 5, 6].includes(this.state.menu_data.id) && (
                  <div>
                    <h5 className="mb-3">
                      <b>สถานะงานถัดไป</b>
                    </h5>
                    <div className="d-flex align-items-center mb-3">
                      <h6 className="mb-0">
                        <b>ส่ง eWork แล้ว</b>
                      </h6>
                    </div>
                    {![3, 4, 5, 6].includes(this.state.menu_data.id) && (
                      <div>
                        <div className="mb-3 d-flex">
                          <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                        </div>
                        <table className="table table-borderless table-striped">
                          <thead className="bg-primary">
                            <tr>
                              <th className="text-center text-white">ลำดับ</th>
                              <th className="text-left text-white">ชื่อ-นามสกุล</th>
                              <th className="text-left text-white">เลขบัตรประจำตัว</th>
                              <th className="text-left text-white">สัญชาติ</th>
                              <th className="text-left text-white">เพศ</th>
                              <th className="text-left text-white">อายุ</th>
                              <th className="text-left text-white">วันที่หมดอายุเอกสาร</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.fw_data &&
                              this.state.fw_data.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">{index + 1}</td>
                                  <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                  <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                  <td className="text-left align-middle">
                                    {item.nationality === 1 ? "เมียนมา" : ""}
                                    {item.nationality === 2 ? "ลาว" : ""}
                                    {item.nationality === 3 ? "กัมพูชา" : ""}
                                    {item.nationality === 4 ? "เวียดนาม" : ""}
                                  </td>
                                  <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                                  <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                                  <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {(this.state.receive_status === 6 || this.state.receive_status === 22) && ![7, 8, 11, 13, 20].includes(this.state.menu_data.id) && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เพศ</th>
                      <th className="text-left text-white">อายุ</th>
                      <th className="text-left text-white">วันที่หมดอายุเอกสาร</th>
                      <th className="text-left text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                          <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={async () => {
                                window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive_all&receive_ids=" + this.state.receive_id + "&receive_codes=" + this.state.receive_code + "&menu_id=" + this.state.menu_data.id + "&menu_name=" + this.state.menu_data.menu_name;
                              }}
                            >
                              <label className="pointer icon text-orange p-2">{"\uf044"}</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {(this.state.receive_status === 6 || this.state.receive_status === 22) && this.state.menu_data.id === 7 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                {this.state.receive_status === 6 && (
                  <div className="d-flex justify-content-end mb-2">
                    <div
                      className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-140 mx-2"
                      onClick={() => {
                        document_contact(
                          this.state.fw_data,
                          this.state.emp_data,
                          this.state.data_workplace,
                          this.state.dpz_data,
                          {
                            witness_power_1: this.state.witness_power_1,
                            witness_power_2_th: this.state.witness_power_2_th,
                            witness_power_2_en: this.state.witness_power_2_en,
                          },
                          this.state.data_prefix_th,
                          this.state.data_prefix_en
                        );
                      }}
                    >
                      พิมพ์ ใบมอบอำนาจ
                    </div>
                  </div>
                )}
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เพศ</th>
                      <th className="text-left text-white">อายุ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">Visa</th>
                      {this.state.receive_status === 6 && <th className="text-left text-white">เอกสาร</th>}
                      {/* <th className="text-left text-white">แบบฟอร์มคำร้องของลูกจ้าง</th> */}
                      {/* <th className="text-left text-white"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                          <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4 d-none"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                {/* <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                    }}
                                    >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div> */}
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4 d-none"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  work.pdf
                                </a>
                                {/* <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                    }}
                                    >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div> */}
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4 d-none"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                                {/* <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                    }}
                                    >
                                    <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                    </div> */}
                              </div>
                            )}
                          </td>
                          {this.state.receive_status === 6 && (
                            <td className="text-left text-white">
                              <div className="d-flex">
                                <div
                                  className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1"
                                  onClick={() => {
                                    document_bt46(
                                      [item],
                                      this.state.emp_data,
                                      this.state.data_workplace,
                                      this.state.dpz_data,
                                      this.state.data_salary,
                                      {
                                        witness_contract_1: this.state.witness_contract_1,
                                        witness_contract_2_th: this.state.witness_contract_2_th,
                                        witness_contract_2_en: this.state.witness_contract_2_en,
                                      },
                                      this.state.data_prefix_th,
                                      this.state.data_prefix_en,
                                      this.state.data_work_type,
                                      this.state.data_business_type
                                    );
                                  }}
                                >
                                  ตม.๒๘
                                </div>
                                <div
                                  className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1"
                                  onClick={() => {
                                    document_bt46(
                                      [item],
                                      this.state.emp_data,
                                      this.state.data_workplace,
                                      this.state.dpz_data,
                                      this.state.data_salary,
                                      {
                                        witness_contract_1: this.state.witness_contract_1,
                                        witness_contract_2_th: this.state.witness_contract_2_th,
                                        witness_contract_2_en: this.state.witness_contract_2_en,
                                      },
                                      this.state.data_prefix_th,
                                      this.state.data_prefix_en,
                                      this.state.data_work_type,
                                      this.state.data_business_type
                                    );
                                  }}
                                >
                                  ตม.๓๐
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {(this.state.receive_status === 6 || this.state.receive_status === 22) && this.state.menu_data.id === 8 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เพศ</th>
                      <th className="text-left text-white">อายุ</th>
                      <th className="text-left text-white">Passport เล่มเก่า</th>
                      <th className="text-left text-white">Passport เล่มใหม่</th>
                      <th className="text-left text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                          <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                          <td className="text-left align-middle">
                            {!item.passport_old_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_old_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_old_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={async () => {
                                window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive_all&receive_ids=" + this.state.receive_id + "&receive_codes=" + this.state.receive_code + "&menu_id=" + this.state.menu_data.id + "&menu_name=" + this.state.menu_data.menu_name;
                              }}
                            >
                              <label className="pointer icon text-orange p-2">{"\uf044"}</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {(this.state.receive_status === 6 || this.state.receive_status === 22) && this.state.menu_data.id === 11 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รอแนบเอกสาร</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">สำเนา Work</th>
                      <th className="text-left text-white">สำเนา Passport</th>
                      <th className="text-left text-white">สำเนา VISA</th>
                      <th className="text-left text-white">ทะเบียนบ้าน</th>
                      <th className="text-left text-white">บัตรประชาชน</th>
                      <th className="text-left text-white">รูปถ่ายคนงานพื้นขาว</th>
                      <th className="text-left text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_old_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_old_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_old_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.attachment_name ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_attachment_name").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.attachment_name && item.attachment_name.includes("://") ? item.attachment_name : "data:application/pdf;base64," + item.attachment_name}>
                                  ทะเบียนบ้าน.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].attachment_name = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.id_card_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_id_card_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.id_card_image && item.id_card_image.includes("://") ? item.id_card_image : "data:application/pdf;base64," + item.id_card_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].id_card_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.profile_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_profile_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                  รูปถ่ายคนงานพื้นขาว.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].profile_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={async () => {
                                window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive_all&receive_ids=" + this.state.receive_id + "&receive_codes=" + this.state.receive_code + "&menu_id=" + this.state.menu_data.id + "&menu_name=" + this.state.menu_data.menu_name;
                              }}
                            >
                              <label className="pointer icon text-orange p-2">{"\uf044"}</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {(this.state.receive_status === 6 || this.state.receive_status === 22) && this.state.menu_data.id === 13 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">วันที่ออก</th>
                      <th className="text-left text-white">สาเหตุที่ออก</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            <DatePicker
                              className="form-control wpx-150 ms-3"
                              placeholder="พิมพ์คำค้นหา..."
                              onChange={(date) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].issue_date = date;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.issue_date}
                            />
                          </td>
                          <td className="text-left align-middle">
                            <input
                              tyle="text"
                              className="form-control"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].issue_reason = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.issue_reason}
                            />
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_permit_document ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_permit_document").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_permit_document && item.work_permit_document.includes("://") ? item.work_permit_document : "data:application/pdf;base64," + item.work_permit_document}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_permit_document = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={async () => {
                                window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive_all&receive_ids=" + this.state.receive_id + "&receive_codes=" + this.state.receive_code + "&menu_id=" + this.state.menu_data.id + "&menu_name=" + this.state.menu_data.menu_name;
                              }}
                            >
                              <label className="pointer icon text-orange p-2">{"\uf044"}</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {this.state.receive_status === 6 && this.state.menu_data.id === 20 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เพศ</th>
                      <th className="text-left text-white">อายุ</th>
                      <th className="text-left text-white">วันที่หมดอายุเอกสาร</th>
                      <th className="text-left text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                          <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={async () => {
                                window.location.href = "/Employee/update?id=" + item.fw_id + "&type=receive_all&receive_ids=" + this.state.receive_id + "&receive_codes=" + this.state.receive_code + "&menu_id=" + this.state.menu_data.id + "&menu_name=" + this.state.menu_data.menu_name;
                              }}
                            >
                              <label className="pointer icon text-orange p-2">{"\uf044"}</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 22 บันทึกรายชื่อลูกจ้าง  */}
            {this.state.receive_status === 22 && this.state.menu_data.id === 20 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบเอกสารนายจ้าง</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">แนบเอกสารนายจ้าง (บุคคลธรรมดา นิติบุคคล)</div>
                  {!this.state.documents_employer_s22 ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_employer_s22").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_employer_s22 && this.state.documents_employer_s22.includes("://") ? this.state.documents_employer_s22 : "data:application/pdf;base64," + this.state.documents_employer_s22}>
                        เอกสารนายจ้าง.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_employer_s22: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">หนังสือมอบอำนาจจากนายจ้าง</div>
                  {!this.state.documents_power_s22 ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_power_s22").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_power_s22 && this.state.documents_power_s22.includes("://") ? this.state.documents_power_s22 : "data:application/pdf;base64," + this.state.documents_power_s22}>
                        หนังสือมอบอำนาจจากนายจ้าง.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_power_s22: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">หนังสือมอบอำนาจช่วง</div>
                  {!this.state.documents_sub_power_s22 ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_sub_power_s22").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_sub_power_s22 && this.state.documents_sub_power_s22.includes("://") ? this.state.documents_sub_power_s22 : "data:application/pdf;base64," + this.state.documents_sub_power_s22}>
                        หนังสือมอบอำนาจช่วง.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_sub_power_s22: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 23 ส่งเอกสาร บต.46+มอบอำนาจ แล้ว   */}
            {this.state.receive_status === 23 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>พิมพ์รายชื่อลูกจ้าง ส่ง ต.ม. ต่ออายุ 90 วัน</b>
                  </h6>
                  <button className="btn btn-primary px-4 mx-3 ">พิมพ์รายชื่อลูกจ้าง</button>
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <input
                    type="text"
                    className="form-control wpx-200"
                    placeholder="ค้นหา..."
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    value={this.state.search}
                  />
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white" rowSpan={2}>
                        ลำดับ
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        ชื่อ-นามสกุล
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        เลขบัตรประจำตัว
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        สัญชาติ
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        เพศ
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        อายุ
                      </th>
                      <th className="text-center text-white" colSpan={3}>
                        วันที่หมดอายุเอกสาร
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        <input
                          type="checkbox"
                          className="me-3"
                          checked={this.state.fw_data.findIndex((e) => !e.document_renewal_status) === -1}
                          onChange={(event) => {
                            let fw_data = this.state.fw_data;
                            fw_data = fw_data.map((e) => {
                              return { ...e, document_renewal_status: event.target.checked };
                            });
                            this.setState({ fw_data: fw_data });
                          }}
                        />
                        ต่ออายุสำเร็จ
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center">
                        <span className="text-white">90 วัน</span>
                        <DatePicker
                          className="form-control wpx-150 ms-3"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(date) => {
                            let fw_data = this.state.fw_data;
                            for (let item of fw_data) {
                              item.notice_90d_expiry_date = date;
                            }
                            this.setState({
                              fw_data: fw_data,
                              select_90d_date: date,
                            });
                          }}
                          value={this.state.select_90d_date}
                        />
                        {/* <input type="date" className="form-control wpx-150 ms-3" onChange={(e)=>{
                          let fw_data = this.state.fw_data;
                          for(let item of fw_data){ 
                            item.notice_90d_expiry_date = e.target.value;
                          }
                          this.setState({
                            fw_data: fw_data,
                            select_90d_date:e.target.value
                          })
                        }} value={this.state.select_90d_date}/> */}
                      </th>
                      <th className="text-center">
                        <span className="text-white">VISA</span>
                        <DatePicker
                          className="form-control wpx-150 ms-3"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(date) => {
                            let fw_data = this.state.fw_data;
                            for (let item of fw_data) {
                              item.visa_expiry_date = date;
                            }
                            this.setState({
                              fw_data: fw_data,
                              select_visa_date: date,
                            });
                          }}
                          value={this.state.select_visa_date}
                        />
                        {/* <input type="date" className="form-control wpx-150 ms-3" onChange={(e)=>{
                          let fw_data = this.state.fw_data;
                          for(let item of fw_data){ 
                            item.visa_expiry_date = e.target.value;
                          }
                          this.setState({
                            fw_data: fw_data,
                            select_visa_date:e.target.value
                          })
                        }} value={this.state.select_visa_date}/> */}
                      </th>
                      <th className="text-center">
                        <span className="text-white">WORK</span>
                        <DatePicker
                          className="form-control wpx-150 ms-3"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(date) => {
                            let fw_data = this.state.fw_data;
                            for (let item of fw_data) {
                              item.work_pm_expiry_date = date;
                            }
                            this.setState({
                              fw_data: fw_data,
                              select_work_date: date,
                            });
                          }}
                          value={this.state.select_work_date}
                        />
                        {/* <input type="date" className="form-control wpx-150 ms-3" onChange={(e)=>{
                          let fw_data = this.state.fw_data;
                          for(let item of fw_data){ 
                            item.work_pm_expiry_date = e.target.value;
                          }
                          this.setState({
                            fw_data: fw_data,
                            select_work_date:e.target.value
                          })
                        }} value={this.state.select_work_date}/> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data
                        .filter((e) => (e.first_name_th && e.first_name_th.includes(this.state.search)) || (e.fw_number && e.fw_number.includes(this.state.search)))
                        .map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">{index + 1}</td>
                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                            <td className="text-left align-middle">
                              {item.nationality === 1 ? "เมียนมา" : ""}
                              {item.nationality === 2 ? "ลาว" : ""}
                              {item.nationality === 3 ? "กัมพูชา" : ""}
                              {item.nationality === 4 ? "เวียดนาม" : ""}
                            </td>
                            <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                            <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                            <td className="text-left align-middle">
                              <DatePicker
                                className="form-control wpx-150 ms-3"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].notice_90d_expiry_date = e;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.notice_90d_expiry_date}
                              />
                              {/* <input type="date" className="form-control wpx-150 ms-3" onChange={(e)=>{
                              let fw_data = this.state.fw_data;
                              fw_data[index].notice_90d_expiry_date = e.target.value;
                              this.setState({fw_data: fw_data})
                            }} value={item.notice_90d_expiry_date}/> */}
                            </td>
                            <td className="text-left align-middle">
                              <DatePicker
                                className="form-control wpx-150 ms-3"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].visa_expiry_date = e;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.visa_expiry_date}
                              />
                              {/* <input type="date" className="form-control wpx-150 ms-3" onChange={(e)=>{
                              let fw_data = this.state.fw_data;
                              fw_data[index].visa_expiry_date = e.target.value;
                              this.setState({fw_data: fw_data})
                            }} value={item.visa_expiry_date}/> */}
                            </td>
                            <td className="text-left align-middle">
                              <DatePicker
                                className="form-control wpx-150 ms-3"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].work_pm_expiry_date = e;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.work_pm_expiry_date}
                              />
                              {/* <input type="date" className="form-control wpx-150 ms-3" onChange={(e)=>{
                              let fw_data = this.state.fw_data;
                              fw_data[index].work_pm_expiry_date = e.target.value;
                              this.setState({fw_data: fw_data})
                            }} value={item.work_pm_expiry_date}/> */}
                            </td>
                            <td className="text-left align-middle">
                              <div>
                                <input
                                  className="me-2"
                                  type="checkbox"
                                  checked={item.document_renewal_status}
                                  onChange={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].document_renewal_status = !fw_data[index].document_renewal_status;
                                    if (!fw_data[index].document_renewal_status) {
                                      fw_data[index].document_expiry_date = "";
                                    }
                                    this.setState({ fw_data: fw_data });
                                  }}
                                />{" "}
                                ต่ออายุสำเร็จ
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 24 เอกสารถูกต้อง พร้อมยื่น */}
            {this.state.receive_status === 24 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ลงชื่อ Scan</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">ลงชื่อ Scan </div>
                  {!this.state.documents_scan_name ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_scan_name").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_scan_name && this.state.documents_scan_name.includes("://") ? this.state.documents_scan_name : "data:application/pdf;base64," + this.state.documents_scan_name}>
                        ลงชื่อ Scan.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_scan_name: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 25 รอบันทึกเลข */}
            {this.state.receive_status === 25 && (
              <div>
                <h5 className="mb-3">
                  <b>รอบันทึกเลข</b>
                </h5>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เลข VISA</th>
                      <th className="text-left text-white">วันที่เริ่ม</th>
                      <th className="text-left text-white">สถานที่ต่ออายุ</th>
                      <th className="text-left text-white">วันที่หมดอายุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-center align-middle">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].visa_number = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.visa_number}
                            />
                          </td>
                          <td className="text-center align-middle">
                            <input
                              className="form-control mt-1"
                              type="date"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].visa_allowed_date = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.visa_allowed_date}
                            />
                          </td>
                          <td className="text-center align-middle">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].visa_allowed_location = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.visa_allowed_location}
                            />
                          </td>
                          <td className="text-center align-middle">
                            <input
                              className="form-control mt-1"
                              type="date"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].visa_expiry_date = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.visa_expiry_date}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 26 รอจ่ายเงิน */}
            {this.state.receive_status === 26 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึก วันและเวลานัดรับ Passport</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex mb-3">
                    <div className="wpx-150">วันที่นัด{required}</div>
                    <input
                      type="date"
                      className="form-control w-50"
                      onChange={(e) => {
                        this.setState({ due_date: e.target.value });
                      }}
                      value={this.state.due_date}
                    />
                  </div>
                  {this.state.menu_data.id !== 11 && (
                    <div className="d-flex mb-3">
                      <div className="wpx-150">เวลาที่นัด{required}</div>
                      <input
                        type="time"
                        className="form-control w-50"
                        onChange={(e) => {
                          this.setState({ due_time: e.target.value });
                        }}
                        value={this.state.due_time}
                      />
                    </div>
                  )}
                  <div className="d-flex mb-3">
                    <div className="wpx-150">สถานที่{required}</div>
                    <input
                      type="text"
                      className="form-control w-50"
                      onChange={(e) => {
                        this.setState({ due_location: e.target.value });
                      }}
                      value={this.state.due_location}
                    />
                  </div>
                  <div className="d-flex mb-3">
                    <div className="wpx-150">หมายเหตุ</div>
                    <textarea
                      className="form-control w-50"
                      onChange={(e) => {
                        this.setState({ due_remark: e.target.value });
                      }}
                      value={this.state.due_remark}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* status 27 รอแนบ บต 50 */}
            {this.state.receive_status === 27 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รอแนบ บต 50</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Scan ไฟล์ Passport</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.receive_status !== 21 && !(this.state.receive_status === 22 && this.state.menu_data.id === 7) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if ([3, 4, 5, 6].includes(this.state.menu_data.id) && this.state.receive_status === 14) {
                        Swal.fire({
                          icon: "warning",
                          title: "ยืนยัน",
                          text: "ยืนยันบันทึกการรับเงินทั้งหมด",
                          confirmButtonText: "ยืนยัน",
                          showCancelButton: true,
                          cancelButtonText: "ยกเลิก",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.Update();
                          }
                        });
                      } else {
                        if ([9, 16, 19].includes(this.state.receive_status)) {
                          if (this.state.installment.length === 0 || this.state.installment.filter((e) => e.paid === this.state.installment.length)) {
                            this.Update();
                          } else {
                            Swal.fire({
                              icon: "warning",
                              title: "ยืนยัน",
                              text: "มียอดค้างชำระยืนยันการบันทึกข้อมูลหรือไม่",
                              confirmButtonText: "ยืนยัน",
                              showCancelButton: true,
                              cancelButtonText: "ยกเลิก",
                            }).then((re) => {
                              if (re.isConfirmed) {
                                this.Update();
                              }
                            });
                          }
                        } else {
                          this.Update();
                        }
                      }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* สถานะงาน 6 แจ้งอยู่เกิน 90 วัน ต่อ Work ต่อ VISA */}
        <Modal
          show={this.state.modal_update_6}
          onHide={() => {
            this.setState({ modal_update_6: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {this.state.receive_status >= 61 && (
                <div>
                  <div
                    className={this.state.show_tab === 60 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 60 ? 0 : 60,
                      });
                    }}
                  >
                    {this.state.receive_status_date[60] ? format_date(this.state.receive_status_date[60], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[60]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 60 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                            <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">เลข passport</th>
                            <th className="text-left text-white">ค่าต่อ 90 วัน</th>
                            <th className="text-left text-white">ค่าต่อ VISA</th>
                            <th className="text-left text-white">ค่าต่อ WORK</th>
                            <th className="text-left text-white">รวม</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                                <td className="text-left align-middle">{item.birth_date ? format_date(item.birth_date, "dd/mm/yyyy", "en") : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">{item.passport_number ? item.passport_number : "-"}</td>
                                <td className="text-left align-middle">
                                  <div>
                                    <input className="me-2" type="checkbox" checked={item.status_90d} disabled /> ค่าต่อ 90 วัน
                                  </div>
                                </td>
                                <td className="text-left align-middle">
                                  <div>
                                    <input className="me-2" type="checkbox" checked={item.status_visa} disabled /> ค่าต่อ VISA
                                  </div>
                                </td>
                                <td className="text-left align-middle">
                                  <div>
                                    <input className="me-2" type="checkbox" checked={item.status_work} disabled /> ค่าต่อ WORK
                                  </div>
                                </td>
                                <td className="text-left align-middle">{Number(item.status_90d ? this.state.rate_list.filter((e) => e.remark.includes("90"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("90"))[0].vat : 0) + Number(item.status_visa ? this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].vat : 0) + Number(item.status_work ? this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].vat : 0)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 66 && (
                <div>
                  <div
                    className={this.state.show_tab === 64 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 64 ? 0 : 64,
                      });
                    }}
                  >
                    {this.state.receive_status_date[64] ? format_date(this.state.receive_status_date[64], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[64]} ตรวจสอบเอกสาร
                  </div>
                  {this.state.show_tab === 64 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[64] ? format_date(this.state.receive_status_date[64], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[64]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 66 && (
                <div>
                  <div
                    className={this.state.show_tab === 66 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 66 ? 0 : 66,
                      });
                    }}
                  >
                    {this.state.receive_status_date[66] ? format_date(this.state.receive_status_date[66], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[66]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 66 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_status_by[66]}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 67 && (
                <div>
                  <div
                    className={this.state.show_tab === 67 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 67 ? 0 : 67,
                      });
                    }}
                  >
                    {this.state.receive_status_date[67] ? format_date(this.state.receive_status_date[67], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[67]}
                    แนบเอกสาร
                  </div>
                  {this.state.show_tab === 67 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">แนบเอกสาร</b>
                          <br />
                          {this.state.documents_employer_receipt && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_employer_receipt && this.state.documents_employer_receipt.includes("://") ? this.state.documents_employer_receipt : "data:application/pdf;base64," + this.state.documents_employer_receipt}>
                              เอกสาร.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 66 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 68 && (
                <div>
                  <div
                    className={this.state.show_tab === 68 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 68 ? 0 : 68,
                      });
                    }}
                  >
                    {this.state.receive_status_date[68] ? format_date(this.state.receive_status_date[68], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[68]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 68 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white" rowSpan={2}>
                              ลำดับ
                            </th>
                            <th className="text-left text-white" rowSpan={2}>
                              ชื่อ-นามสกุล
                            </th>
                            <th className="text-left text-white" rowSpan={2}>
                              เลขบัตรประจำตัว
                            </th>
                            <th className="text-left text-white" rowSpan={2}>
                              สัญชาติ
                            </th>
                            <th className="text-left text-white" rowSpan={2}>
                              เพศ
                            </th>
                            <th className="text-left text-white" rowSpan={2}>
                              อายุ
                            </th>
                            <th className="text-center text-white" colSpan={3}>
                              วันที่หมดอายุเอกสาร
                            </th>
                            <th className="text-left text-white" rowSpan={2}>
                              ต่ออายุสำเร็จ
                            </th>
                          </tr>
                          <tr>
                            <th className="text-center">
                              <span className="text-white">90 วัน</span>
                            </th>
                            <th className="text-center">
                              <span className="text-white">VISA</span>
                            </th>
                            <th className="text-center">
                              <span className="text-white">WORK</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data
                              .filter((e) => (e.first_name_th && e.first_name_th.includes(this.state.search)) || (e.fw_number && e.fw_number.includes(this.state.search)))
                              .map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">{index + 1}</td>
                                  <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                  <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                  <td className="text-left align-middle">
                                    {item.nationality === 1 ? "เมียนมา" : ""}
                                    {item.nationality === 2 ? "ลาว" : ""}
                                    {item.nationality === 3 ? "กัมพูชา" : ""}
                                    {item.nationality === 4 ? "เวียดนาม" : ""}
                                  </td>
                                  <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                                  <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                                  <td className="text-left align-middle">{format_date(item.notice_90d_expiry_date)}</td>
                                  <td className="text-left align-middle">{format_date(item.visa_expiry_date)}</td>
                                  <td className="text-left align-middle">{format_date(item.work_pm_expiry_date)}</td>
                                  <td className="text-left align-middle">
                                    <div>
                                      <input className="me-2" type="checkbox" checked={item.document_renewal_status} disabled />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 69 && (
                <div>
                  <div
                    className={this.state.show_tab === 69 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 69 ? 0 : 69,
                      });
                    }}
                  >
                    {this.state.receive_status_date[69] ? format_date(this.state.receive_status_date[69], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[69]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 69 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[69]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 611 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_status_date[611] ? format_date(this.state.receive_status_date[611], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[611]} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 60 เปิดเรื่อง */}
            {this.state.receive_status === 60 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เพศ</th>
                      <th className="text-left text-white">อายุ</th>
                      <th className="text-left text-white">วันที่หมดอายุเอกสาร</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                          <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 61 เก็บค่าบริการ (นายจ้าง) */}
            {this.state.receive_status === 61 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>แนบ เอกสารใบรับเงิน </b>
                  </h6>
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <button
                    className="btn btn-sm btn-primary px-3 me-2"
                    onClick={async () => {
                      this.setState({ loading: true });
                      let body = {
                        data_search: "",
                        receive_id: this.state.receive_id,
                        menu: "",
                      };
                      let result = await GET(tokens, "api/receive-matter/list/page/0", body);
                      if (result && result.status) {
                        result.data.rate_list = result.data.rate_list.map((e) => {
                          return {
                            ...e,
                            qty_price: e.qty_price - e.discount,
                          };
                        });
                        let obj = {
                          receive_data: result.data,
                          invoice_data: {
                            installment: this.state.installment
                              ? JSON.stringify(
                                  this.state.installment.map((e) => {
                                    return { ...e, selected: true };
                                  })
                                )
                              : [],
                          },
                        };
                        console.log(obj);
                        document_receipt(obj, this.state.data_header);
                      }
                      this.setState({ loading: false });
                    }}
                  >
                    พิมพ์ใบเสร็จ
                  </button>
                </div>

                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                      <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เลข passport</th>
                      <th className="text-left text-white">ค่าต่อ 90 วัน</th>
                      <th className="text-left text-white">ค่าต่อ VISA</th>
                      <th className="text-left text-white">ค่าต่อ WORK</th>
                      <th className="text-left text-white">รวม</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                          <td className="text-left align-middle">{item.birth_date ? format_date(item.birth_date, "dd/mm/yyyy", "en") : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.passport_number ? item.passport_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={item.status_90d}
                                onChange={() => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_90d = !fw_data[index].status_90d;
                                  this.setState({ fw_data: fw_data });
                                }}
                                disabled
                              />{" "}
                              ค่าต่อ 90 วัน
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={item.status_visa}
                                onChange={() => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_visa = !fw_data[index].status_visa;
                                  this.setState({ fw_data: fw_data });
                                }}
                                disabled
                              />{" "}
                              ค่าต่อ VISA
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={item.status_work}
                                onChange={() => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_work = !fw_data[index].status_work;
                                  this.setState({ fw_data: fw_data });
                                }}
                                disabled
                              />{" "}
                              ค่าต่อ WORK
                            </div>
                          </td>
                          <td className="text-left align-middle">{Number(item.status_90d ? this.state.rate_list.filter((e) => e.remark.includes("90"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("90"))[0].vat : 0) + Number(item.status_visa ? this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].vat : 0) + Number(item.status_work ? this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].vat : 0)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 62 เก็บค่าบริการ (ลูกจ้าง) */}
            {this.state.receive_status === 62 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>แนบ เอกสารใบรับเงิน </b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                      <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เลข passport</th>
                      <th className="text-left text-white">ค่าต่อ 90 วัน</th>
                      <th className="text-left text-white">ค่าต่อ VISA</th>
                      <th className="text-left text-white">ค่าต่อ WORK</th>
                      <th className="text-left text-white">รวม</th>
                      <th className="text-left text-white"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                          <td className="text-left align-middle">{item.birth_date ? format_date(item.birth_date, "dd/mm/yyyy", "en") : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.passport_number ? item.passport_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={item.status_90d}
                                onChange={() => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_90d = !fw_data[index].status_90d;
                                  this.setState({ fw_data: fw_data });
                                }}
                              />{" "}
                              ค่าต่อ 90 วัน
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={item.status_visa}
                                onChange={() => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_visa = !fw_data[index].status_visa;
                                  this.setState({ fw_data: fw_data });
                                }}
                              />{" "}
                              ค่าต่อ VISA
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div>
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={item.status_work}
                                onChange={() => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_work = !fw_data[index].status_work;
                                  this.setState({ fw_data: fw_data });
                                }}
                              />{" "}
                              ค่าต่อ WORK
                            </div>
                          </td>
                          <td className="text-left align-middle">{Number(item.status_90d ? this.state.rate_list.filter((e) => e.remark.includes("90"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("90"))[0].vat : 0) + Number(item.status_visa ? this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Visa"))[0].vat : 0) + Number(item.status_work ? this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].qty_price + this.state.rate_list.filter((e) => e.remark.includes("Work"))[0].vat : 0)}</td>
                          <td className="text-left align-middle d-flex">
                            <button
                              className="btn btn-sm btn-primary px-3 me-2"
                              onClick={() => {
                                console.log(item);
                                console.log(this.state.rate_list);
                                let rate_list = [];
                                if (item.status_90d) {
                                  rate_list.push(this.state.rate_list.find((e) => e.remark === "งาน 90 วัน"));
                                }
                                if (item.status_visa) {
                                  rate_list.push(this.state.rate_list.find((e) => e.remark === "งาน ต่อ Visa"));
                                }
                                if (item.status_work) {
                                  rate_list.push(this.state.rate_list.find((e) => e.remark === "งาน ต่อ Work"));
                                }
                                let data_item = {
                                  rate_list: rate_list,
                                  ...item,
                                };
                                document_receipt_by_person(data_item, this.state.data_header);
                              }}
                            >
                              พิมพ์ใบเสร็จ
                            </button>
                            <button
                              className="btn btn-sm btn-primary px-3"
                              onClick={() => {
                                this.Update_fw_6();
                              }}
                            >
                              บันทึก
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 65 เอกสารไม่ถูกต้อง */}
            {this.state.receive_status === 65 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร ไม่ถูกต้อง</label>
                <br />
                <label>
                  ผู้ตรวจสอบ {this.state.receive_status_by[65]} วันที่ตรวจสอบ {this.state.receive_status_date[65] ? format_date(this.state.receive_status_date[65], "dd/mm/yyyy") : ""}
                </label>
              </div>
            )}
            {/* status 66 แนบเอกสาร */}
            {this.state.receive_status === 66 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบเอกสารที่นายจ้างเซ็นกลับมา</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">แนบเอกสารที่นายจ้างเซ็นกลับมา</div>
                  {!this.state.documents_employer_receipt ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_employer_receipt").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_employer_receipt && this.state.documents_employer_receipt.includes("://") ? this.state.documents_employer_receipt : "data:application/pdf;base64," + this.state.documents_employer_receipt}>
                        เอกสาร.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_employer_receipt: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 67 แนบใบเสร็จรับเงินแล้ว */}
            {this.state.receive_status === 67 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>พิมพ์รายชื่อลูกจ้าง ส่ง ต.ม. ต่ออายุ 90 วัน</b>
                  </h6>
                  <button className="btn btn-primary px-4 mx-3 ">พิมพ์รายชื่อลูกจ้าง</button>
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <input
                    type="text"
                    className="form-control wpx-200"
                    placeholder="ค้นหา..."
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    value={this.state.search}
                  />
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white" rowSpan={2}>
                        ลำดับ
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        ชื่อ-นามสกุล
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        เลขบัตรประจำตัว
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        สัญชาติ
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        เพศ
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        อายุ
                      </th>
                      <th className="text-center text-white" colSpan={3}>
                        วันที่หมดอายุเอกสาร
                      </th>
                      <th className="text-left text-white" rowSpan={2}>
                        <input
                          type="checkbox"
                          className="me-3"
                          checked={this.state.fw_data.findIndex((e) => !e.document_renewal_status) === -1}
                          onChange={(event) => {
                            let fw_data = this.state.fw_data;
                            fw_data = fw_data.map((e) => {
                              return { ...e, document_renewal_status: event.target.checked };
                            });
                            this.setState({ fw_data: fw_data });
                          }}
                        />
                        ต่ออายุสำเร็จ
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center">
                        <span className="text-white">90 วัน</span>
                        <DatePicker
                          className="form-control wpx-150 ms-3"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(date) => {
                            let fw_data = this.state.fw_data;
                            for (let item of fw_data) {
                              item.notice_90d_expiry_date = date;
                            }
                            this.setState({
                              fw_data: fw_data,
                              select_90d_date: date,
                            });
                          }}
                          value={this.state.select_90d_date}
                        />
                      </th>
                      <th className="text-center">
                        <span className="text-white">VISA</span>
                        <DatePicker
                          className="form-control wpx-150 ms-3"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(date) => {
                            let fw_data = this.state.fw_data;
                            for (let item of fw_data) {
                              item.visa_expiry_date = date;
                            }
                            this.setState({
                              fw_data: fw_data,
                              select_visa_date: date,
                            });
                          }}
                          value={this.state.select_visa_date}
                        />
                      </th>
                      <th className="text-center">
                        <span className="text-white">WORK</span>
                        <DatePicker
                          className="form-control wpx-150 ms-3"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(date) => {
                            let fw_data = this.state.fw_data;
                            for (let item of fw_data) {
                              item.work_pm_expiry_date = date;
                            }
                            this.setState({
                              fw_data: fw_data,
                              select_work_date: date,
                            });
                          }}
                          value={this.state.select_work_date}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data
                        .filter((e) => (e.first_name_th && e.first_name_th.includes(this.state.search)) || (e.fw_number && e.fw_number.includes(this.state.search)))
                        .map((item, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">{index + 1}</td>
                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                            <td className="text-left align-middle">
                              {item.nationality === 1 ? "เมียนมา" : ""}
                              {item.nationality === 2 ? "ลาว" : ""}
                              {item.nationality === 3 ? "กัมพูชา" : ""}
                              {item.nationality === 4 ? "เวียดนาม" : ""}
                            </td>
                            <td className="text-left align-middle">{item.gender ? item.gender : "-"}</td>
                            <td className="text-left align-middle">{item.age ? item.age : "-"}</td>
                            <td className="text-left align-middle">
                              <DatePicker
                                className="form-control wpx-150 ms-3"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].notice_90d_expiry_date = e;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.notice_90d_expiry_date}
                              />
                            </td>
                            <td className="text-left align-middle">
                              <DatePicker
                                className="form-control wpx-150 ms-3"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].visa_expiry_date = e;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.visa_expiry_date}
                              />
                            </td>
                            <td className="text-left align-middle">
                              <DatePicker
                                className="form-control wpx-150 ms-3"
                                placeholder="พิมพ์คำค้นหา..."
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].work_pm_expiry_date = e;
                                  this.setState({ fw_data: fw_data });
                                }}
                                value={item.work_pm_expiry_date}
                              />
                            </td>
                            <td className="text-left align-middle">
                              <div>
                                <input
                                  className="me-2"
                                  type="checkbox"
                                  checked={item.document_renewal_status}
                                  onChange={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].document_renewal_status = !fw_data[index].document_renewal_status;
                                    if (!fw_data[index].document_renewal_status) {
                                      fw_data[index].document_expiry_date = "";
                                    }
                                    this.setState({ fw_data: fw_data });
                                  }}
                                />{" "}
                                ต่ออายุสำเร็จ
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 68 ต่ออายุสำเร็จ */}
            {this.state.receive_status === 68 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>จัดส่งเอกสารใบรับเงิน</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Line" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s16 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s16: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s16 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s16 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s16: e.target.value });
                        }}
                        value={this.state.sending_messenger_s16}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s16: e });
                      }}
                      value={this.state.sending_document_date_s16}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s16 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s16").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s16: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s16: e.target.value });
                      }}
                      value={this.state.remark_s16}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 69 แนบใบเสร็จ */}
            {this.state.receive_status === 69 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบเอกสารที่นายจ้างเซ็นกลับมา</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">แนบเอกสารที่นายจ้างเซ็นกลับมา</div>
                  {!this.state.documents_employer_receipt_after ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_employer_receipt_after").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_employer_receipt_after && this.state.documents_employer_receipt_after.includes("://") ? this.state.documents_employer_receipt_after : "data:application/pdf;base64," + this.state.documents_employer_receipt_after}>
                        เอกสาร.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_employer_receipt_after: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 610 แนบใบเสร็จแล้ว */}
            {this.state.receive_status === 610 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบใบเสร็จแล้ว</b>
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {![63, 64, 65, 611].includes(this.state.receive_status) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update_6: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if (this.state.receive_status === 61 || this.state.receive_status === 62) {
                        Swal.fire({
                          icon: "warning",
                          title: "ยืนยัน",
                          text: "ยืนยันบันทึกการรับเงินทั้งหมด",
                          confirmButtonText: "ยืนยัน",
                          showCancelButton: true,
                          cancelButtonText: "ยกเลิก",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.Update_6();
                          }
                        });
                      } else {
                        this.Update_6();
                      }
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update_6: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* สถานะงาน 7 แจ้งเข้าที่พัก (ตม.) */}
        <Modal
          show={this.state.modal_update_7}
          onHide={() => {
            this.setState({ modal_update_7: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {this.state.receive_status >= 72 && (
                <div>
                  <div
                    className={this.state.show_tab === 72 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 72 ? 0 : 72,
                      });
                    }}
                  >
                    {this.state.receive_status_date[72] ? format_date(this.state.receive_status_date[72], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[70]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 72 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">วันที่ออก</th>
                            <th className="text-left text-white">สาเหตุที่ออก</th>
                            <th className="text-left text-white">Passport</th>
                            <th className="text-left text-white">Work</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">{item.issue_date}</td>
                                <td className="text-left align-middle">{item.issue_reason}</td>
                                <td className="text-left align-middle">
                                  {item.passport_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                        passport.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.work_pm_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                        Work.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 74 && this.state.receive_status !== 75 && (
                <div>
                  <div
                    className={this.state.show_tab === 74 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 74 ? 0 : 74,
                      });
                    }}
                  >
                    {this.state.receive_status_date[74] ? format_date(this.state.receive_status_date[74], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[74]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจ
                  </div>
                  {this.state.show_tab === 74 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[74] ? format_date(this.state.receive_status_date[74], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[74]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 76 && (
                <div>
                  <div
                    className={this.state.show_tab === 76 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 76 ? 0 : 76,
                      });
                    }}
                  >
                    {this.state.receive_status_date[76] ? format_date(this.state.receive_status_date[76], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[76]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 76 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_status_by[76]}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 74 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 77 && this.state.receive_status !== 78 && (
                <div>
                  <div
                    className={this.state.show_tab === 79 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 79 ? 0 : 79,
                      });
                    }}
                  >
                    {this.state.receive_status_date[79] ? format_date(this.state.receive_status_date[79], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[79]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจ
                  </div>
                  {this.state.show_tab === 79 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[79] ? format_date(this.state.receive_status_date[79], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[79]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 77 && this.state.receive_status !== 79 && (
                <div>
                  <div
                    className={this.state.show_tab === 78 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 78 ? 0 : 78,
                      });
                    }}
                  >
                    {this.state.receive_status_date[77] ? format_date(this.state.receive_status_date[77], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[77]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 78 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[77]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 79 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 77 && this.state.receive_status !== 78 && (
                <div>
                  <div
                    className={this.state.show_tab === 710 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 710 ? 0 : 710,
                      });
                    }}
                  >
                    {this.state.receive_status_date[710] ? format_date(this.state.receive_status_date[710], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[710]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจช่วง
                  </div>
                  {this.state.show_tab === 710 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[710] ? format_date(this.state.receive_status_date[710], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[710]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 710 && (
                <div>
                  <div
                    className={this.state.show_tab === 711 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 711 ? 0 : 711,
                      });
                    }}
                  >
                    {this.state.receive_status_date[711] ? format_date(this.state.receive_status_date[711], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[711]}
                    แนบเอกสาร ตม.28+ตม.30 ที่ประทับตราเข้าสู่ระบบ
                  </div>
                  {this.state.show_tab === 711 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">แนบเอกสาร ตม.28+ตม.30 ที่ประทับตราเข้าสู่ระบบ</b>
                          <br />
                          {this.state.report_out && (
                            <a className="mx-3" target="_blank" download href={this.state.report_out && this.state.report_out.includes("://") ? this.state.report_out : "data:application/pdf;base64," + this.state.report_out}>
                              ตม.28+ตม.30.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 79 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 712 && (
                <div>
                  <div
                    className={this.state.show_tab === 712 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 712 ? 0 : 712,
                      });
                    }}
                  >
                    {this.state.receive_status_date[712] ? format_date(this.state.receive_status_date[712], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[712]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 712 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_status_by[711]}
                          </label>
                          {this.state.documents_s19 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 79 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 713 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_status_date[713] ? format_date(this.state.receive_status_date[713], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[713]} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 70 เปิดเรื่องแล้ว  */}
            {this.state.receive_status === 70 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 71 บันทึกลูกจ้างสำเร็จ 1/2  */}
            {this.state.receive_status === 71 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 72 ตรวจสอบเอกสาร */}
            {this.state.receive_status === 72 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                  <div
                    className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-140 mx-2"
                    onClick={() => {
                      document_contact(
                        this.state.fw_data,
                        this.state.emp_data,
                        this.state.data_workplace,
                        this.state.dpz_data,
                        {
                          witness_power_1: this.state.witness_power_1,
                          witness_power_2_th: this.state.witness_power_2_th,
                          witness_power_2_en: this.state.witness_power_2_en,
                        },
                        this.state.data_prefix_th,
                        this.state.data_prefix_en
                      );
                    }}
                  >
                    พิมพ์ ใบมอบอำนาจ
                  </div>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">เอกสาร</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {item.passport_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.work_pm_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            <td className="text-left text-white">
                              <div className="d-flex">
                                <div
                                  className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1"
                                  onClick={() => {
                                    document_bt46(
                                      [item],
                                      this.state.emp_data,
                                      this.state.data_workplace,
                                      this.state.dpz_data,
                                      this.state.data_salary,
                                      {
                                        witness_contract_1: this.state.witness_contract_1,
                                        witness_contract_2_th: this.state.witness_contract_2_th,
                                        witness_contract_2_en: this.state.witness_contract_2_en,
                                      },
                                      this.state.data_prefix_th,
                                      this.state.data_prefix_en,
                                      this.state.data_work_type,
                                      this.state.data_business_type
                                    );
                                  }}
                                >
                                  ตม.28
                                </div>
                                <div
                                  className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1"
                                  onClick={() => {
                                    document_bt46(
                                      [item],
                                      this.state.emp_data,
                                      this.state.data_workplace,
                                      this.state.dpz_data,
                                      this.state.data_salary,
                                      {
                                        witness_contract_1: this.state.witness_contract_1,
                                        witness_contract_2_th: this.state.witness_contract_2_th,
                                        witness_contract_2_en: this.state.witness_contract_2_en,
                                      },
                                      this.state.data_prefix_th,
                                      this.state.data_prefix_en,
                                      this.state.data_work_type,
                                      this.state.data_business_type
                                    );
                                  }}
                                >
                                  ตม.30
                                </div>
                              </div>
                            </td>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 73 รอตรวจสอบเอกสาร */}
            {this.state.receive_status === 73 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบ ตม.28+ตม.30 เข้าระบบ</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">ตม.28</div>
                  {!this.state.tm_28_document ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_tm_28_document").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.tm_28_document && this.state.tm_28_document.includes("://") ? this.state.tm_28_document : "data:application/pdf;base64," + this.state.tm_28_document}>
                        ตม.28.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ tm_28_document: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">ตม.30</div>
                  {!this.state.tm_30_document ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_tm_30_document").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.tm_30_document && this.state.tm_30_document.includes("://") ? this.state.tm_30_document : "data:application/pdf;base64," + this.state.tm_30_document}>
                        ตม.30.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ tm_30_document: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 74 เอกสารถูกต้อง */}
            {this.state.receive_status === 74 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Line" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s9 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s9: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s9 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s9 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s9: e.target.value });
                        }}
                        value={this.state.sending_messenger_s9}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s9: e });
                      }}
                      value={this.state.sending_document_date_s9}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s9 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s9").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s9: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s9: e.target.value });
                      }}
                      value={this.state.remark_s9}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 75 เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจ ไม่ถูกต้อง */}
            {this.state.receive_status === 75 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจ ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 78 เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง */}
            {this.state.receive_status === 78 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 710 ส่งเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง แล้ว */}
            {this.state.receive_status === 710 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>

                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบ ตม.28+ตม.30 ที่ประทับตราเข้าสู่ระบบ</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">ตม.28+ตม.30</div>
                  {!this.state.report_out ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_report_out").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.report_out && this.state.report_out.includes("://") ? this.state.report_out : "data:application/pdf;base64," + this.state.report_out}>
                        ไฟล์แนบ.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ report_out: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 711 แนบไฟล์ใบแจ้งออกแล้ว  */}
            {this.state.receive_status === 711 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Line" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s19 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s19: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s19 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s19 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s19: e.target.value });
                        }}
                        value={this.state.sending_messenger_s19}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s19: e });
                      }}
                      value={this.state.sending_document_date_s19}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s19 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s19").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s19: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s19: e.target.value });
                      }}
                      value={this.state.remark_s19}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 712 จัดส่งเอกสารแล้ว */}
            {this.state.receive_status === 712 && (
              <div>
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {[70, 71, 72, 74, 76, 77, 79, 710, 711, 712].includes(this.state.receive_status) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update_7: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      this.Update_7();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update_7: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* สถานะงาน 8 ย้ายดวงตรา VISA */}
        <Modal
          show={this.state.modal_update_8}
          onHide={() => {
            this.setState({ modal_update_8: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (8 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (8 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {this.state.receive_status >= 82 && (
                <div>
                  <div
                    className={this.state.show_tab === 82 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 82 ? 0 : 82,
                      });
                    }}
                  >
                    {this.state.receive_status_date[82] ? format_date(this.state.receive_status_date[82], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[80]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 82 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport เล่มเก่า</th>
                      <th className="text-left text-white">Passport เล่มใหม่</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">รูปถ่าย</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                          <td className="text-left align-middle">
                            {item.passport_old_image&& (
                                <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                  passport.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.passport_image&& (
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.work_pm_image && (
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.profile_image && (
                                <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 84 && this.state.receive_status !== 85 && (
                <div>
                  <div
                    className={this.state.show_tab === 84 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 84 ? 0 : 84,
                      });
                    }}
                  >
                    {this.state.receive_status_date[84] ? format_date(this.state.receive_status_date[84], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[84]} ตรวจสอบ ย้ายตรา visa + หนังสือมอบอำนาจ
                  </div>
                  {this.state.show_tab === 84 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[84] ? format_date(this.state.receive_status_date[84], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[84]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 86 && (
                <div>
                  <div
                    className={this.state.show_tab === 86 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 86 ? 0 : 86,
                      });
                    }}
                  >
                    {this.state.receive_status_date[86] ? format_date(this.state.receive_status_date[86], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[86]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 86 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_status_by[86]}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 84 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )} 
              {this.state.receive_status >= 87 && this.state.receive_status !== 88 && (
                <div>
                  <div
                    className={this.state.show_tab === 89 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 89 ? 0 : 89,
                      });
                    }}
                  >
                    {this.state.receive_status_date[89] ? format_date(this.state.receive_status_date[89], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[89]} ตรวจสอบ ย้ายตรา visa
                  </div>
                  {this.state.show_tab === 89 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[89] ? format_date(this.state.receive_status_date[89], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[89]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 810 && (
                <div>
                  <div
                    className={this.state.show_tab === 810 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 810 ? 0 : 810,
                      });
                    }}
                  >
                    {this.state.receive_status_date[810] ? format_date(this.state.receive_status_date[810], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[80]} กรอกข้อมูล Visa เล่มใหม่สำเร็จ
                  </div>
                  {this.state.show_tab === 810 && (
                    <div className="card-body">
                     
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">เลขที่ตรวจลงตรา visa</th>
                      <th className="text-left text-white">สถานที่ต่ออายุ</th>
                      <th className="text-left text-white">วันที่ออก</th>
                      <th className="text-left text-white">วันที่หมด</th>
                      <th className="text-left text-white">Visa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.passport_number}</td>
                          <td className="text-left align-middle">{item.visa_number}</td>
                          <td className="text-left align-middle">{item.visa_allowed_location}</td>
                          <td className="text-left align-middle">{format_date(item.visa_allowed_date)}</td>
                          <td className="text-left align-middle">{format_date(item.visa_expiry_date)}</td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 811 && (
                <div>
                  <div
                    className={this.state.show_tab === 811 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 811 ? 0 : 811,
                      });
                    }}
                  >
                    {this.state.receive_status_date[811] ? format_date(this.state.receive_status_date[811], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[811]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 811 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_status_by[811]}
                          </label>
                          {this.state.documents_s19 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 89 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 812 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_status_date[812] ? format_date(this.state.receive_status_date[812], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[812]} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 80 เปิดเรื่องแล้ว  */}
            {this.state.receive_status === 80 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport เล่มเก่า</th>
                      <th className="text-left text-white">Passport เล่มใหม่</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">รูปถ่าย</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_old_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_old_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_old_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  Visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.profile_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_profile_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                  รูปถ่าย.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].profile_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 81 บันทึกลูกจ้างสำเร็จ 1/2  */}
            {this.state.receive_status === 81 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport เล่มเก่า</th>
                      <th className="text-left text-white">Passport เล่มใหม่</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">รูปถ่าย</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_old_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_old_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_old_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  Visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.profile_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_profile_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                  รูปถ่าย.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].profile_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 82 ตรวจสอบเอกสาร และ แนบไฟล์ */}
            {this.state.receive_status === 82 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport เล่มเก่า</th>
                      <th className="text-left text-white">Passport เล่มใหม่</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">รูปถ่าย</th>
                      <th className="text-left text-white">แบบฟอร์ม</th>
                      <th className="text-left text-white">แนบไฟล์</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {item.passport_old_image&& (
                                <a target="_blank" download href={item.passport_old_image && item.passport_old_image.includes("://") ? item.passport_old_image : "data:application/pdf;base64," + item.passport_old_image}>
                                  passport.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.passport_image&& (
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.work_pm_image && (
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.profile_image && (
                                <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                                <div
                                  className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1"
                                  onClick={() => {
                                    document_bt46(
                                      [item],
                                      this.state.emp_data,
                                      this.state.data_workplace,
                                      this.state.dpz_data,
                                      this.state.data_salary,
                                      {
                                        witness_contract_1: this.state.witness_contract_1,
                                        witness_contract_2_th: this.state.witness_contract_2_th,
                                        witness_contract_2_en: this.state.witness_contract_2_en,
                                      },
                                      this.state.data_prefix_th,
                                      this.state.data_prefix_en,
                                      this.state.data_work_type,
                                      this.state.data_business_type
                                    );
                                  }}
                                >
                                 ขอย้ายตรา
                                </div>
                          </td>
                          <td className="text-left align-middle">
                            {!item.document_evidence ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_document_evidence").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.document_evidence && item.document_evidence.includes("://") ? item.document_evidence : "data:application/pdf;base64," + item.document_evidence}>
                                ขอย้ายตรา(เซ็นแล้ว).pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].document_evidence = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 83 รอตรวจสอบเอกสาร */}
            {this.state.receive_status === 83 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบ บต.53 เข้าระบบ</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">บต. 53</div>
                  {!this.state.bt_53_document_after ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_bt_53_document_after").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.bt_53_document_after && this.state.bt_53_document_after.includes("://") ? this.state.bt_53_document_after : "data:application/pdf;base64," + this.state.bt_53_document_after}>
                        บต. 53.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ bt_53_document_after: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 84 จัดส่งเอกสารใบเสร็จ */}
            {this.state.receive_status === 84 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Line" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s9 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s9: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s9 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s9 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s9: e.target.value });
                        }}
                        value={this.state.sending_messenger_s9}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s9: e });
                      }}
                      value={this.state.sending_document_date_s9}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s9 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s9").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s9: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s9: e.target.value });
                      }}
                      value={this.state.remark_s9}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 85 เอกสาร ย้ายตรา visa ไม่ถูกต้อง */}
            {this.state.receive_status === 85 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร ย้ายตรา visa+หนังสือมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร ย้ายตรา visa+หนังสือมอบอำนาจ ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 88 เอกสาร ย้ายตรา visa ไม่ถูกต้อง */}
            {this.state.receive_status === 88 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร ย้ายตรา visa</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร ย้ายตรา visa ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 810 เอกสารถูกต้อง  */}
            {this.state.receive_status === 89 && (
              <div>
                <h5 className="mb-3">
                  <b>แสดงข้อมูล</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">เลขที่ตรวจลงตรา visa</th>
                      <th className="text-left text-white">สถานที่ต่ออายุ</th>
                      <th className="text-left text-white">วันที่ออก</th>
                      <th className="text-left text-white">วันที่หมด</th>
                      <th className="text-left text-white">Visa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{item.passport_number}</td>
                          <td className="text-left align-middle">{item.visa_number}</td>
                          <td className="text-left align-middle">{item.visa_allowed_location}</td>
                          <td className="text-left align-middle">{format_date(item.visa_allowed_date)}</td>
                          <td className="text-left align-middle">{format_date(item.visa_expiry_date)}</td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  Work.pdf
                                </a>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 810 กรอกข้อมูล visa เล่มใหม่แล้ว  */}
            {this.state.receive_status === 810 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Line" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s19 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s19: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s19 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s19 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s19: e.target.value });
                        }}
                        value={this.state.sending_messenger_s19}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s19: e });
                      }}
                      value={this.state.sending_document_date_s19}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s19 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s19").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s19: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s19: e.target.value });
                      }}
                      value={this.state.remark_s19}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 811 จัดส่งเอกสารแล้ว */}
            {this.state.receive_status === 811 && (
              <div>
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {[80, 81, 82, 84, 86, 87, 810, 811].includes(this.state.receive_status) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update_8: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      this.Update_8();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update_8: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* สถานะงาน 11 เปลี่ยนเล่ม Passport */}
        <Modal
          show={this.state.modal_update_11}
          onHide={() => {
            this.setState({ modal_update_11: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {/* ผู้มีอำนาจผูกพัน */}
                          {/* <div className="mb-3 d-flex col-6">
                              <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน</b></div>
                              {this.state.emp_data.binding_authority_th ? this.state.emp_data.binding_authority_th : "-"}
                          </div> */}
                          {/* ตำแหน่ง */}
                          {/* <div className="mb-3 d-flex col-6">
                              <div className="wpx-220"><b>ตำแหน่ง</b></div>
                              {this.state.emp_data.position_th ? this.state.emp_data.position_th : "-"}
                          </div> */}
                          {/* ผู้มีอำนาจผูกพัน (English) */}
                          {/* <div className="mb-3 d-flex col-6">
                              <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน (English)</b></div>
                              {this.state.emp_data.binding_authority_en ? this.state.emp_data.binding_authority_en : "-"}
                          </div> */}
                          {/* ตำแหน่ง (English) */}
                          {/* <div className="mb-3 d-flex col-6">
                              <div className="wpx-220"><b>ตำแหน่ง (English)</b></div>
                              {this.state.emp_data.position_en ? this.state.emp_data.position_en : "-"}
                          </div> */}
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {this.state.receive_status >= 111 && (
                <div>
                  <div
                    className={this.state.show_tab === 110 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 110 ? 0 : 110,
                      });
                    }}
                  >
                    {this.state.receive_status_date[110] ? format_date(this.state.receive_status_date[110], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[110]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 110 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                            <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">Passport</th>
                            <th className="text-left text-white">Work</th>
                            <th className="text-left text-white">Visa</th>
                            <th className="text-left text-white">ทะเบียนบ้าน</th>
                            <th className="text-left text-white">บัตรประชาชน</th>
                            <th className="text-left text-white">รูปถ่าย</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_en ? item.first_name_en : "-"}</td>
                                <td className="text-left align-middle">{item.birth_date ? format_date(item.birth_date, "dd/mm/yyyy", "en") : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">
                                  {item.passport_image && (
                                    <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                      Passport.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.work_pm_image && (
                                    <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                      Work.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.visa_image && (
                                    <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                      Visa.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.copy_house_registration && (
                                    <a target="_blank" download href={item.copy_house_registration && item.copy_house_registration.includes("://") ? item.copy_house_registration : "data:application/pdf;base64," + item.copy_house_registration}>
                                      ทะเบียนบ้าน.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.copy_idcard_image && (
                                    <a target="_blank" download href={item.copy_idcard_image && item.copy_idcard_image.includes("://") ? item.copy_idcard_image : "data:application/pdf;base64," + item.copy_idcard_image}>
                                      บัตรประชาชน.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.profile_image && (
                                    <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                      รูปถ่าย.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 115 && (
                <div>
                  <div
                    className={this.state.show_tab === 113 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 113 ? 0 : 113,
                      });
                    }}
                  >
                    {this.state.receive_status_date[112] ? format_date(this.state.receive_status_date[112], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[112]} ตรวจสอบเอกสาร
                  </div>
                  {this.state.show_tab === 113 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[112] ? format_date(this.state.receive_status_date[112], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[112]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 115 && (
                <div>
                  <div
                    className={this.state.show_tab === 114 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 114 ? 0 : 114,
                      });
                    }}
                  >
                    {this.state.receive_status_date[114] ? format_date(this.state.receive_status_date[114], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[114]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 114 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_status_by[114]}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 116 && (
                <div>
                  <div
                    className={this.state.show_tab === 116 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 116 ? 0 : 116,
                      });
                    }}
                  >
                    {this.state.receive_status_date[116] ? format_date(this.state.receive_status_date[116], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[116]}
                    แนบเอกสาร
                  </div>
                  {this.state.show_tab === 116 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">แนบเอกสาร</b>
                          <br />
                          {this.state.documents_employer_receipt && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_employer_receipt && this.state.documents_employer_receipt.includes("://") ? this.state.documents_employer_receipt : "data:application/pdf;base64," + this.state.documents_employer_receipt}>
                              เอกสาร.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 115 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 117 && (
                <div>
                  <div
                    className={this.state.show_tab === 117 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 117 ? 0 : 117,
                      });
                    }}
                  >
                    {this.state.receive_status_date[117] ? format_date(this.state.receive_status_date[117], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[117]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 117 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[117]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 118 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_status_date[118] ? format_date(this.state.receive_status_date[118], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[118]} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 110 เปิดเรื่องแล้ว */}
            {this.state.receive_status === 110 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>รอแนบเอกสาร</b>
                  </h6>
                </div>
                <div className="mb-3 d-flex">
                  <button className="btn btn-primary px-4 mx-3">พิมพ์รายชื่อลูกจ้าง</button>
                  <button
                    className="btn btn-danger px-4 me-3"
                    onClick={() => {
                      this.setState({ modal_import: true });
                    }}
                  >
                    แนบรายชื่อลูกจ้าง
                  </button>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล (อังกฤษ)</th>
                      <th className="text-left text-white">วัน/เดือน/ปีเกิด</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">ทะเบียนบ้าน</th>
                      <th className="text-left text-white">บัตรประชาชน</th>
                      <th className="text-left text-white">รูปถ่าย</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {item.passport_image && (
                              <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                Passport.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.work_pm_image && (
                              <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                Work.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                              <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                Visa.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.copy_house_registration && (
                              <a target="_blank" download href={item.copy_house_registration && item.copy_house_registration.includes("://") ? item.copy_house_registration : "data:application/pdf;base64," + item.copy_house_registration}>
                                ทะเบียนบ้าน.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.copy_idcard_image && (
                              <a target="_blank" download href={item.copy_idcard_image && item.copy_idcard_image.includes("://") ? item.copy_idcard_image : "data:application/pdf;base64," + item.copy_idcard_image}>
                                บัตรประชาชน.pdf
                              </a>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.profile_image && (
                              <a target="_blank" download href={item.profile_image && item.profile_image.includes("://") ? item.profile_image : "data:application/pdf;base64," + item.profile_image}>
                                รูปถ่าย.pdf
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 113 เอกสารไม่ถูกต้อง */}
            {this.state.receive_status === 113 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร ไม่ถูกต้อง</label>
                <br />
                <label>
                  ผู้ตรวจสอบ {this.state.receive_status_by[113]} วันที่ตรวจสอบ {this.state.receive_status_date[113] ? format_date(this.state.receive_status_date[113], "dd/mm/yyyy") : ""}
                </label>
              </div>
            )}
            {/* status 115 แนบเอกสารจากสถานฑูต */}
            {this.state.receive_status === 115 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบเอกสารที่เซ็นกลับมา (สถานฑูต)</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">แนบเอกสารที่เซ็นกลับมา (สถานฑูต)</div>
                  {!this.state.documents_employer_receipt ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_documents_employer_receipt").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.documents_employer_receipt && this.state.documents_employer_receipt.includes("://") ? this.state.documents_employer_receipt : "data:application/pdf;base64," + this.state.documents_employer_receipt}>
                        เอกสาร.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ documents_employer_receipt: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 116 แนบเอกสารแล้ว */}
            {this.state.receive_status === 116 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>จัดส่งเอกสาร</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Line" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s16 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s16: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s16 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s16 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s16: e.target.value });
                        }}
                        value={this.state.sending_messenger_s16}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s16: e });
                      }}
                      value={this.state.sending_document_date_s16}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s16 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s16").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s16: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s16: e.target.value });
                      }}
                      value={this.state.remark_s16}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 117 จัดส่งเอกสารแล้ว */}
            {this.state.receive_status === 117 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>จัดส่งเอกสารแล้ว</b>
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {![110, 113, 118].includes(this.state.receive_status) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update_11: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      this.Update_11();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update_11: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        {/* สถานะงาน 13 แจ้งคนต่างด้าวออกจากงาน บต.53 */}
        <Modal
          show={this.state.modal_update_13}
          onHide={() => {
            this.setState({ modal_update_13: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {this.state.receive_status >= 132 && (
                <div>
                  <div
                    className={this.state.show_tab === 132 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 132 ? 0 : 132,
                      });
                    }}
                  >
                    {this.state.receive_status_date[132] ? format_date(this.state.receive_status_date[132], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[130]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 132 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th>
                            <th className="text-left text-white">วันที่ออก</th>
                            <th className="text-left text-white">สาเหตุที่ออก</th>
                            <th className="text-left text-white">Passport</th>
                            <th className="text-left text-white">Work</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">{item.issue_date}</td>
                                <td className="text-left align-middle">{item.issue_reason}</td>
                                <td className="text-left align-middle">
                                  {item.passport_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                        passport.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.work_pm_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                        Work.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 134 && this.state.receive_status !== 135 && (
                <div>
                  <div
                    className={this.state.show_tab === 134 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 134 ? 0 : 134,
                      });
                    }}
                  >
                    {this.state.receive_status_date[134] ? format_date(this.state.receive_status_date[134], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[134]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจ
                  </div>
                  {this.state.show_tab === 134 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[134] ? format_date(this.state.receive_status_date[134], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[134]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 136 && (
                <div>
                  <div
                    className={this.state.show_tab === 136 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 136 ? 0 : 136,
                      });
                    }}
                  >
                    {this.state.receive_status_date[136] ? format_date(this.state.receive_status_date[136], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[136]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 136 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_status_by[136]}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 134 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 137 && this.state.receive_status !== 138 && (
                <div>
                  <div
                    className={this.state.show_tab === 139 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 139 ? 0 : 139,
                      });
                    }}
                  >
                    {this.state.receive_status_date[139] ? format_date(this.state.receive_status_date[139], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[139]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจช่วง
                  </div>
                  {this.state.show_tab === 139 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[139] ? format_date(this.state.receive_status_date[139], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[139]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 137 && this.state.receive_status !== 139 && (
                <div>
                  <div
                    className={this.state.show_tab === 138 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 138 ? 0 : 138,
                      });
                    }}
                  >
                    {this.state.receive_status_date[137] ? format_date(this.state.receive_status_date[137], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[137]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 138 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[137]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 139 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 137 && this.state.receive_status !== 138 && (
                <div>
                  <div
                    className={this.state.show_tab === 1310 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1310 ? 0 : 1310,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1310] ? format_date(this.state.receive_status_date[1310], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1310]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจช่วง
                  </div>
                  {this.state.show_tab === 1310 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[1310] ? format_date(this.state.receive_status_date[1310], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[1310]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1310 && (
                <div>
                  <div
                    className={this.state.show_tab === 1311 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1311 ? 0 : 1311,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1311] ? format_date(this.state.receive_status_date[1311], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1311]}
                    แนบเอกสารแจ้งออก
                  </div>
                  {this.state.show_tab === 1311 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">แนบเอกสารแจ้งออก</b>
                          <br />
                          {this.state.report_out && (
                            <a className="mx-3" target="_blank" download href={this.state.report_out && this.state.report_out.includes("://") ? this.state.report_out : "data:application/pdf;base64," + this.state.report_out}>
                              เอกสารแจ้งออก.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 139 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1311 && (
                <div>
                  <div
                    className={this.state.show_tab === 1311 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1311 ? 0 : 1311,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1311] ? format_date(this.state.receive_status_date[1311], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1311]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 1311 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[1311]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 139 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1312 && (
                <div>
                  <div
                    className={this.state.show_tab === 1312 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1312 ? 0 : 1312,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1312] ? format_date(this.state.receive_status_date[1312], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1312]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 1312 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_status_by[1311]}
                          </label>
                          {this.state.documents_s19 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 139 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1313 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_status_date[1313] ? format_date(this.state.receive_status_date[1313], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1313]} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 130 เปิดเรื่องแล้ว  */}
            {this.state.receive_status === 130 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">วันที่ออก</th>
                      <th className="text-left text-white">สาเหตุที่ออก</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Work</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            <DatePicker
                              className="form-control wpx-150 ms-3"
                              placeholder="พิมพ์คำค้นหา..."
                              onChange={(date) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].issue_date = date;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.issue_date}
                            />
                          </td>
                          <td className="text-left align-middle">
                            <input
                              tyle="text"
                              className="form-control"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].issue_reason = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.issue_reason}
                            />
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 131 บันทึกลูกจ้างสำเร็จ 1/2  */}
            {this.state.receive_status === 131 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">วันที่ออก</th>
                      <th className="text-left text-white">สาเหตุที่ออก</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Work</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            <DatePicker
                              className="form-control wpx-150 ms-3"
                              placeholder="พิมพ์คำค้นหา..."
                              onChange={(date) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].issue_date = date;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.issue_date}
                            />
                          </td>
                          <td className="text-left align-middle">
                            <input
                              tyle="text"
                              className="form-control"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].issue_reason = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.issue_reason}
                            />
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 132 ตรวจสอบเอกสาร */}
            {this.state.receive_status === 132 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                  <div
                    className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-140 mx-2"
                    onClick={() => {
                      document_contact(
                        this.state.fw_data,
                        this.state.emp_data,
                        this.state.data_workplace,
                        this.state.dpz_data,
                        {
                          witness_power_1: this.state.witness_power_1,
                          witness_power_2_th: this.state.witness_power_2_th,
                          witness_power_2_en: this.state.witness_power_2_en,
                        },
                        this.state.data_prefix_th,
                        this.state.data_prefix_en
                      );
                    }}
                  >
                    พิมพ์ ใบมอบอำนาจ
                  </div>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">วันที่ออก</th>
                      <th className="text-left text-white">สาเหตุที่ออก</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">เอกสาร</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">{format_date(item.issue_date)}</td>
                          <td className="text-left align-middle">{item.issue_reason}</td>
                          <td className="text-left align-middle">
                            {item.passport_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.work_pm_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            <td className="text-left text-white">
                              <div className="d-flex">
                                <div
                                  className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1"
                                  onClick={() => {
                                    document_bt46(
                                      [item],
                                      this.state.emp_data,
                                      this.state.data_workplace,
                                      this.state.dpz_data,
                                      this.state.data_salary,
                                      {
                                        witness_contract_1: this.state.witness_contract_1,
                                        witness_contract_2_th: this.state.witness_contract_2_th,
                                        witness_contract_2_en: this.state.witness_contract_2_en,
                                      },
                                      this.state.data_prefix_th,
                                      this.state.data_prefix_en,
                                      this.state.data_work_type,
                                      this.state.data_business_type
                                    );
                                  }}
                                >
                                  บต. 53
                                </div>
                              </div>
                            </td>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 133 รอตรวจสอบเอกสาร */}
            {this.state.receive_status === 133 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบ บต.53 เข้าระบบ</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">บต. 53</div>
                  {!this.state.bt_53_document_after ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_bt_53_document_after").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.bt_53_document_after && this.state.bt_53_document_after.includes("://") ? this.state.bt_53_document_after : "data:application/pdf;base64," + this.state.bt_53_document_after}>
                        บต. 53.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ bt_53_document_after: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 134 เอกสารถูกต้อง */}
            {this.state.receive_status === 134 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Line" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s9 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s9: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s9 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s9 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s9: e.target.value });
                        }}
                        value={this.state.sending_messenger_s9}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s9: e });
                      }}
                      value={this.state.sending_document_date_s9}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s9 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s9").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s9: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s9: e.target.value });
                      }}
                      value={this.state.remark_s9}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 135 เอกสาร บต.53+หนังสือมอบอำนาจ ไม่ถูกต้อง */}
            {this.state.receive_status === 135 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจ ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 138 เอกสาร บต.53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง */}
            {this.state.receive_status === 138 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจช่วง</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 1310 ส่งเอกสาร บต.53+หนังสือมอบอำนาจช่วง แล้ว */}
            {this.state.receive_status === 1310 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบเอกสารแจ้งออก</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">แนบเอกสารแจ้งออก</div>
                  {!this.state.report_out ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_report_out").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.report_out && this.state.report_out.includes("://") ? this.state.report_out : "data:application/pdf;base64," + this.state.report_out}>
                        เอกสารแจ้งออก.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ report_out: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 1311 แนบไฟล์ใบแจ้งออกแล้ว  */}
            {this.state.receive_status === 1311 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Line" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s19 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s19: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s19 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s19 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s19: e.target.value });
                        }}
                        value={this.state.sending_messenger_s19}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s19: e });
                      }}
                      value={this.state.sending_document_date_s19}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s19 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s19").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s19: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s19: e.target.value });
                      }}
                      value={this.state.remark_s19}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 1312 จัดส่งเอกสารแล้ว */}
            {this.state.receive_status === 1312 && (
              <div>
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {[130, 131, 132, 134, 136, 137, 139, 1310, 1311, 1312].includes(this.state.receive_status) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update_13: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      this.Update_13();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update_13: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

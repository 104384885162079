import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_delete: false,
      modal_workplace_create: false,
      modal_workplace_update: false,
      tab: 1,
      loading: false,

      show: false,

      data_search: "",

      data_menu_work: [],
      data_branch: [],
      data_immigration_office: [],
      data_business_type: [],
      country_qty: "",
      type_search: "ในระบบ",

      receive_code: "",
      receive_date: new Date(),
      branch: { value: "1", label: "สำนักงานใหญ่" },
      immigration_office: "",
      broker: "",
      emp_full_name: "",
      full_name_en: "",
      emp_type: "",
      register_date: "",
      register_capital: "",
      id_card: "",
      place_issue: "",
      issue_date: "",
      expiry_date: "",
      business_type: "",
      contact_name: "",
      phone: "",
      fax: "",
      email: "",
      home_number: "",
      address_number: "",
      phone_2: "",
      fax_2: "",
      binding_authority_th: "",
      position_th: "",
      binding_authority_en: "",
      position_en: "",
      process_matter: "",
      cambodia_qty: "",
      laos_qty: "",
      myanmar_qty: "",
      vietnam_qty: "",
      total_qty: "",
      remark: "",

      filing_type: "",
      billing_type: "NO VAT",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: false,
      c_certificate_issue_date: "",
      c_certificate_20: false,
      c_copy_committee: false,
      c_copy_committee_expire_date: "",
      readOnly: false,
      data_deputize: [],
      data_workplace: [],
      checklist_data: [],
      fw_data: [],
      data_broker: [],

      // ตัวแทน
      agent_name: "",
      agent_phone: "",
      agent_email: "",
      modal_agent_create: false,
      data_admin: [],

      // พยาน
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      emp_period_y: "",
      emp_period_m: "",
      emp_period_d: "",
      contract_until_date: "",

      payment_person: "",
      payment_choice: "",
      listname_choice: "",
      quotation_id: ""
    };
  }
  async componentDidMount() {
    await this.GetImmigration();
    await this.GetBranch();
    await this.GetBusinessType();
    await this.GetMenuWork();
    await this.GetAgent();
    await this.GetAdmin();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
    }
    if (this.props.location.query.emp_number) {
      this.setState({ data_search: this.props.location.query.emp_number });
      setTimeout(() => {
        this.GetUser();
      }, 10);
    }
  }
  GetImmigration = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_immigration_office: result.data.map((e) => {
          return { value: e.id.toString(), label: e.imm_name_th };
        }),
      });
    }
  };
  GetBranch = async () => {
    let body = {
      data_search: "",
      branch_id: "",
    };
    let result = await GET(tokens, "api/setting/branch/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_branch: result.data.map((e) => {
          return { value: e.id.toString(), label: e.branch_name_th };
        }),
      });
    }
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      console.log(result.data)
      await this.GetWorkplace(result.data.emp_data.emp_id);
      this.setState({
        quotation_id: result.data.quotation_id,
        receive_id: result.data.receive_id,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch ? this.state.data_branch.filter((e) => e.value === result.data.branch)[0] : "",
        immigration_office: result.data.immigration_office ? this.state.data_immigration_office.filter((e) => e.value === result.data.immigration_office)[0] : "",
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.agent_data ? this.state.data_broker.filter((e) => e.value === result.data.agent_data.agent_id)[0] : "",
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter ? this.state.data_menu_work.filter((e) => e.value === result.data.menu_data.menu_id)[0] : "",
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_issue_date: result.data.c_certificate_issue_date,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        c_copy_committee_expire_date: result.data.c_copy_committee_expire_date,
        receive_status: result.data.receive_status,
        is_active: result.data.is_active,
        checklist_data: result.data.checklist_data,
        fw_data: result.data.fw_data,
        country_qty: result.data.cambodia_qty ? "กัมพูชา" : result.data.laos_qty ? "ลาว" : result.data.myanmar_qty ? "เมียนมา" : result.data.vietnam_qty ? "เวียดนาม" : "",
        // data_workplace: result.data.pw_data,
        data_deputize: result.data.dpz_data ? result.data.dpz_data : [],
        place_work: result.data.place_work ? this.state.data_workplace.filter((e) => e.value === result.data.place_work)[0] : "",

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: "",
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,
        emp_period_y: result.data.emp_period_y,
        emp_period_m: result.data.emp_period_m,
        emp_period_d: result.data.emp_period_d,
        contract_until_date: result.data.contract_until_date,

        payment_person: result.data.payment_person,
        payment_choice: result.data.payment_choice,
        listname_choice: result.data.listname_choice,
      });
      if (result.data.emp_data) {
        this.setState({
          data_search: result.data.emp_data.emp_number,
          emp_id: result.data.emp_data.emp_id,
          emp_code: result.data.emp_data.emp_code,
          emp_user_type: result.data.emp_data.emp_user_type,
          emp_type: result.data.emp_data.emp_type,
          emp_number: result.data.emp_data.emp_number,
          register_capital: result.data.emp_data.register_capital,
          register_date: result.data.emp_data.register_date,
          id_card: result.data.emp_data.id_card,
          place_issue: result.data.emp_data.place_issue,
          issue_date: result.data.emp_data.issue_date,
          expiry_date: result.data.emp_data.expiry_date,
          contact_name: result.data.emp_data.contact_name,
          emp_full_name: result.data.emp_data.full_name_th,
          full_name_en: result.data.emp_data.full_name_en,
          business_type: result.data.emp_data.business_type,
          address_number: result.data.emp_data.address_number,
          village_name: result.data.emp_data.village_name,
          floor: result.data.emp_data.floor,
          room_no: result.data.emp_data.room_no,
          building_name: result.data.emp_data.building_name,
          moo: result.data.emp_data.moo,
          soi: result.data.emp_data.soi,
          street: result.data.emp_data.street,
          province: result.data.emp_data.province,
          district: result.data.emp_data.district,
          sub_district: result.data.emp_data.sub_district,
          zipcode: result.data.emp_data.zipcode,
          email: result.data.emp_data.email,
          phone: result.data.emp_data.phone,
          fax: result.data.emp_data.fax,
          mobile_phone: result.data.emp_data.mobile_phone,
          home_number: result.data.emp_data.home_number,
          phone_2: result.data.emp_data.phone_2,
          fax_2: result.data.emp_data.fax_2,
          binding_authority_th: result.data.emp_data.binding_authority_th,
          binding_authority_en: result.data.emp_data.binding_authority_en,
          position_th: result.data.emp_data.position_th,
          position_en: result.data.emp_data.position_en,
          operator_type: result.data.emp_data.operator_type,
          deputize_number: result.data.emp_data.deputize_number,
          deputize_full_name: result.data.emp_data.deputize_full_name,
          username: result.data.emp_data.username,
          copy_idcard_image: result.data.emp_data.copy_idcard_image,
          copy_idcard_image_date: result.data.emp_data.copy_idcard_image_date,
          // dpz_data: result.data.emp_data.dpz_data,
          coordinator_data: result.data.emp_data.coordinator_data,
        });
      }
      if(result.data.receive_status !== 22){
        this.setState({ readOnly: true });
      }
    }
    this.setState({ loading: false });
  };
  GetMenuWork = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: [],
      menu_id: "",
    };
    let result = await GET(tokens, "api/receive-matter-menu/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data.map((e) => {
          return {
            value: e.menu_id,
            label: e.menu_name,
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  GetAgent = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      agent_id: "",
    };
    let result = await GET(tokens, "api/agent/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_broker: result.data.map((e) => {
          return {
            value: e.agent_id,
            label: e.agent_name,
            ...e,
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  GetAdmin = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
    this.setState({ loading: false });
  };
  GetUser = async () => {
    if (this.state.data_search === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขประจำตัวนายจ้าง");
      return;
    }
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      emp_id: "",
    };
    let result = await GET(tokens, this.state.type_search === "กรมพัฒนาธุรกิจการค้า" ? "api/employer/department-trade" : "api/employer/list/page/0", body);
    if (result && result.status) {
      this.setState({
        emp_id: result.data[0].emp_id,
        emp_code: result.data[0].emp_code,
        emp_user_type: result.data[0].emp_user_type,
        emp_type: result.data[0].emp_type,
        emp_number: result.data[0].emp_number,
        register_capital: result.data[0].register_capital,
        register_date: result.data[0].register_date,
        id_card: result.data[0].id_card,
        place_issue: result.data[0].place_issue,
        issue_date: result.data[0].issue_date,
        expiry_date: result.data[0].expiry_date,
        contact_name: result.data[0].contact_name,
        emp_full_name: result.data[0].full_name_th,
        full_name_en: result.data[0].full_name_en,
        business_type: result.data[0].business_type,
        address_number: result.data[0].address_number,
        village_name: result.data[0].village_name,
        floor: result.data[0].floor,
        room_no: result.data[0].room_no,
        building_name: result.data[0].building_name,
        moo: result.data[0].moo,
        soi: result.data[0].soi,
        street: result.data[0].street,
        province: result.data[0].province,
        district: result.data[0].district,
        sub_district: result.data[0].sub_district,
        zipcode: result.data[0].zipcode,
        email: result.data[0].email,
        phone: result.data[0].phone,
        fax: result.data[0].fax,
        mobile_phone: result.data[0].mobile_phone,
        home_number: result.data[0].home_number,
        phone_2: result.data[0].phone_2,
        fax_2: result.data[0].fax_2,
        binding_authority_th: result.data[0].binding_authority_th,
        binding_authority_en: result.data[0].binding_authority_en,
        position_th: result.data[0].position_th,
        position_en: result.data[0].position_en,
        operator_type: result.data[0].operator_type,
        deputize_number: result.data[0].deputize_number,
        deputize_full_name: result.data[0].deputize_full_name,
        username: result.data[0].username,
        copy_idcard_image: result.data[0].copy_idcard_image,
        copy_idcard_image_date: result.data[0].copy_idcard_image_date,
        dpz_data: result.data[0].dpz_data,
        pw_data: result.data[0].pw_data,
        data_workplace: result.data[0].pw_data,
        coordinator_data: result.data[0].coordinator_data,
        data_deputize: result.data[0].dpz_data ? result.data[0].dpz_data : [],
      });
      this.GetWorkplace(result.data[0].emp_id);
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    if(!this.state.filing_type){
      alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการยื่น");
      return;
    }
     if(!this.state.billing_type){
      alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการออกบิล");
      return;
     }
      // if(!this.state.payment_person){
      //   alert("warning", "แจ้งเตือน", "กรุณาเลือกการจ่ายเงิน");
      //   return;
      // }
    this.setState({ loading: true });
    let body = {
      receive_date: this.state.receive_date,
      branch: this.state.branch ? this.state.branch.value : "",
      immigration_office: this.state.immigration_office ? this.state.immigration_office.value : "",
      filing_type: this.state.filing_type,
      billing_type: this.state.billing_type,
      broker: this.state.broker ? this.state.broker.value : "",
      process_matter: this.state.process_matter ? this.state.process_matter.value : "",
      cambodia_qty: this.state.cambodia_qty,
      laos_qty: this.state.laos_qty,
      myanmar_qty: this.state.myanmar_qty,
      vietnam_qty: this.state.vietnam_qty,
      total_qty: this.state.total_qty,
      remark: this.state.remark,
      buy_health_insurance: this.state.buy_health_insurance,
      type_health_insurance: this.state.type_health_insurance,
      buy_disease_service: this.state.buy_disease_service,
      type_disease_service: this.state.type_disease_service,
      c_certificate: this.state.c_certificate,
      c_certificate_issue_date: this.state.c_certificate_issue_date,
      c_certificate_20: this.state.c_certificate_20,
      c_copy_committee: this.state.c_copy_committee,
      c_copy_committee_expire_date: this.state.c_copy_committee_expire_date,
      emp_number: this.state.emp_number,
      data_deputize: this.state.data_deputize,
      data_workplace: [],
      place_work: this.state.place_work ? this.state.place_work.value : "",
      witness_power_1: this.state.witness_power_1 ? this.state.witness_power_1.value : "",
      witness_power_2_th: this.state.witness_power_2_th ? this.state.witness_power_2_th.value : "",
      witness_power_2_en: "",
      witness_contract_1: this.state.witness_contract_1 ? this.state.witness_contract_1.value : "",
      witness_contract_2_th: this.state.witness_contract_2_th,
      witness_contract_2_en: this.state.witness_contract_2_en,
      emp_period_y: this.state.emp_period_y,
      emp_period_m: this.state.emp_period_m,
      emp_period_d: this.state.emp_period_d,
      contract_until_date: this.state.contract_until_date,

      payment_person: this.state.payment_person,
      payment_choice: this.state.payment_choice,
      listname_choice: this.state.listname_choice
    };
    let result = await POST(tokens, "api/receive-matter/create", body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    if(!this.state.filing_type){
      alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการยื่น");
      return;
    }
     if(!this.state.billing_type){
      alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทการออกบิล");
      return;
     }
      // if(!this.state.payment_person){
      //   alert("warning", "แจ้งเตือน", "กรุณาเลือกการจ่ายเงิน");
      //   return;
      // }
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    if (this.state.fw_data.filter((e) => e.status_complete_info).length === this.state.fw_data.length && this.state.receive_status === 3) {
      receive_status = 6;
    }
    if (this.state.fw_data.filter((e) => e.status_complete_info).length === this.state.fw_data.length && this.state.receive_status === 5) {
      receive_status = 6;
    }
    if ((this.state.receive_status === 3 || this.state.receive_status === 5 || this.state.receive_status === 6) && this.state.fw_data.filter((e) => e.status_complete_info).length === this.state.fw_data.length) {
      receive_status = 7;
    }
    let body = {
      c_certificate: this.state.c_certificate,
      c_certificate_issue_date: this.state.c_certificate_issue_date,
      c_certificate_20: this.state.c_certificate_20,
      c_copy_committee: this.state.c_copy_committee,
      c_copy_committee_expire_date: this.state.c_copy_committee_expire_date,
      checklist_data: this.state.checklist_data,
      immigration_office: this.state.immigration_office ? this.state.immigration_office.value : "",

      fw_data: this.state.fw_data,

      data_deputize: this.state.data_deputize,
      data_workplace: [],
      place_work: this.state.place_work ? this.state.place_work.value : "",
      witness_power_1: this.state.witness_power_1 ? this.state.witness_power_1.value : "",
      witness_power_2_th: this.state.witness_power_2_th ? this.state.witness_power_2_th.value : "",
      witness_power_2_en: "",
      witness_contract_1: this.state.witness_contract_1 ? this.state.witness_contract_1.value : "",
      witness_contract_2_th: this.state.witness_contract_2_th,
      witness_contract_2_en: this.state.witness_contract_2_en,
      emp_period_y: this.state.emp_period_y,
      emp_period_m: this.state.emp_period_m,
      emp_period_d: this.state.emp_period_d,
      contract_until_date: this.state.contract_until_date,

      broker: this.state.broker ? this.state.broker.value : "",
      filing_type: this.state.filing_type,
      billing_type: this.state.billing_type,
      payment_person: this.state.payment_person,
      payment_choice: this.state.payment_choice,
      listname_choice: this.state.listname_choice,
      receive_status : receive_status === 50 ? this.state.payment_person === "นายจ้าง" ? 51 : this.state.payment_person === "ลูกจ้าง" ? 52 : receive_status: receive_status
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  GetWorkplace = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.pw_id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
            ...e,
          };
        }),
      });
      if (result.data.length === 1) {
        setTimeout(() => {
          this.setState({
            place_work: this.state.data_workplace[0],
          });
        }, 10);
      }
    }
    this.setState({ loading: false });
  };
  CreateAgent = async () => {
    this.setState({ loading: true });
    let body = {
      agent_name: this.state.agent_name,
      agent_phone: this.state.agent_phone,
      agent_email: this.state.agent_email,
    };
    let result = await POST(tokens, "api/agent/create", body);
    if (result && result.status) {
      success();
      this.setState({
        agent_name: "",
        agent_phone: "",
        agent_email: "",
        modal_agent_create: false,
      });
      setTimeout(() => {
        this.GetAgent();
      }, 1);
    }
    this.setState({ loading: false });
  };
  CreateWorkplace = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      phone: this.state.phone_create,
      email: this.state.email_create,
    };
    let result = await POST(tokens, "api/place-work/create", body);
    if (result && result.status) {
      success();
      this.setState({
        address_number: "",
        moo: "",
        soi: "",
        street: "",
        province: "",
        district: "",
        sub_district: "",
        zipcode: "",
        phone_create: "",
        email_create: "",
        modal_workplace_create: false,
      });
      setTimeout(() => {
        this.GetWorkplace(this.state.emp_id);
      }, 1);
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2={this.state.process_matter ? this.state.process_matter.label : "รายการรับเรื่องทั้งหมด"} page3="เพิ่ม" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>{this.state.process_matter ? this.state.process_matter.label : "รายการรับเรื่องทั้งหมด"}</b>
              </h4>
              <div className="d-flex">
                {this.state.quotation_id && (
                <button
                  className="btn btn-info px-4 mx-1"
                  onClick={() => {
                    window.location.href = "/Quotation/update?id=" + this.state.quotation_id;
                  }}
                >
                  ใบเสนอราคา
                </button>
                )}
                  <button
                  className="btn btn-danger px-4 mx-1"
                  onClick={() => {
                    window.location.href = "/Invoice/create?quotation_id=" + this.state.quotation_id + "&receive_id="+this.state.receive_id+ "&receive_code="+this.state.receive_code+ "&broker="+(this.state.broker?this.state.broker.label:"");
                  }}
                >
                  ออกใบแจ้งหนี้
                </button>
                <button
                  className="btn btn-success px-4 mx-1"
                  onClick={() => {
                    window.location.href = "/Receipt/list";
                  }}
                >
                  ออกใบเสร็จ
                </button>
                </div>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <div className="row mt-3">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขเรื่อง{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ receive_code: e.target.value });
                        }}
                        value={this.state.receive_code}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">วันที่รับเรื่อง{required}</span>
                      <DatePicker
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ receive_date: e });
                        }}
                        value={this.state.receive_date}
                        disabled={this.state.readOnly}
                        max={new Date()}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">สาขา{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_branch}
                        onChange={(e) => {
                          this.setState({ branch: e });
                        }}
                        value={this.state.branch}
                        isDisabled={this.state.readOnly}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ที่ทำการตรวจคนเข้าเมือง</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_immigration_office}
                        onChange={(e) => {
                          this.setState({ immigration_office: e });
                        }}
                        value={this.state.immigration_office}
                        isDisabled={this.state.readOnly}
                      ></Select>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทการยื่น{required}</span>
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="filing_type"
                        onChange={(e) => {
                          this.setState({ filing_type: "ยื่นผ่านนายหน้า",readOnly:false });
                        }}
                        checked={this.state.filing_type === "ยื่นผ่านนายหน้า"}
                        disabled={this.state.readOnly && this.state.filing_type}
                      />
                      ยื่นผ่านนายหน้า
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="filing_type"
                        onChange={(e) => {
                          this.setState({ filing_type: "นายจ้างยื่นเอง",readOnly:false });
                        }}
                        checked={this.state.filing_type === "นายจ้างยื่นเอง"}
                        disabled={this.state.readOnly && this.state.filing_type}
                      />
                      นายจ้างยื่นเอง
                    </div>
                    {this.state.filing_type === "ยื่นผ่านนายหน้า" && (
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">นายหน้า{required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_broker}
                          onChange={(e) => {
                            this.setState({ broker: e,readOnly:false });
                          }}
                          value={this.state.broker}
                          isDisabled={this.state.readOnly && this.state.broker}
                        ></Select>
                      </div>
                    )}
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทการออกบิล{required}</span>
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="billing_type"
                        onChange={(e) => {
                          this.setState({ billing_type: "NO VAT",readOnly:false });
                        }}
                        checked={this.state.billing_type === "NO VAT"}
                        disabled={this.state.readOnly && this.state.billing_type}
                      />
                      NO VAT
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="billing_type"
                        onChange={(e) => {
                          this.setState({ billing_type: "VAT",readOnly:false });
                        }}
                        checked={this.state.billing_type === "VAT"}
                        disabled={this.state.readOnly && this.state.billing_type}
                      />
                      VAT
                    </div>
                    {/* <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">การจ่ายเงิน{required}</span>
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="payment_person"
                        onChange={(e) => {
                          this.setState({ payment_person: "นายจ้าง",payment_choice:"",readOnly:false });
                        }}
                        checked={this.state.payment_person === "นายจ้าง"}
                        disabled={this.state.readOnly && this.state.payment_person}
                      />
                      นายจ้าง
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="payment_person"
                        onChange={(e) => {
                          this.setState({ payment_person: "ลูกจ้าง",payment_choice:"",readOnly:false });
                        }}
                        checked={this.state.payment_person === "ลูกจ้าง"}
                        disabled={this.state.readOnly && this.state.payment_person}
                      />
                      ลูกจ้าง
                  </div> */}
                  {this.state.payment_person === "นายจ้าง" && (
                  <div className="col-12 d-flex align-items-center mb-3">
                    <span className="wpx-150">การชำระ{required}</span>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="payment_choice"
                      onChange={(e) => {
                        this.setState({ payment_choice: "จ่ายทั้งหมด",readOnly:false });
                      }}
                      checked={this.state.payment_choice === "จ่ายทั้งหมด"}
                      disabled={this.state.readOnly}
                    />
                    จ่ายทั้งหมด
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="payment_choice"
                      onChange={(e) => {
                        this.setState({ payment_choice: "จ่ายบางส่วน",readOnly:false });
                      }}
                      checked={this.state.payment_choice === "จ่ายบางส่วน"}
                      disabled={this.state.readOnly}
                    />
                    จ่ายบางส่วน
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="payment_choice"
                      onChange={(e) => {
                        this.setState({ payment_choice: "ยังไม่จ่าย" });
                      }}
                      checked={this.state.payment_choice === "ยังไม่จ่าย"}
                      disabled={this.state.readOnly}
                    />
                    ยังไม่จ่าย
                  </div>
                  )}
                  </div>
                </div>

                <hr />
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>ข้อมูลนายจ้าง</b>
                  </h5>
                  <div className="row">
                    {/* <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ค้นหาจาก{required}</span>
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="type_search"
                        onChange={(e) => {
                          this.setState({ type_search: "ในระบบ" });
                        }}
                        checked={this.state.type_search === "ในระบบ"}
                        disabled={this.state.readOnly}
                      />
                      ในระบบ
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="type_search"
                        onChange={(e) => {
                          this.setState({ type_search: "กรมพัฒนาธุรกิจการค้า" });
                        }}
                        checked={this.state.type_search === "กรมพัฒนาธุรกิจการค้า"}
                        disabled={this.state.readOnly}
                      />
                      กรมพัฒนาธุรกิจการค้า
                    </div> */}
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขนิติบุคคล/เลขประจำตัวผู้เสียภาษี(13หลัก){required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ data_search: e.target.value });
                        }}
                        value={this.state.data_search}
                        disabled={this.state.readOnly}
                      />
                      {!this.state.readOnly && (
                        <button
                          className="btn btn-primary mx-1 px-4"
                          onClick={() => {
                            this.GetUser();
                          }}
                        >
                          ค้นหา
                        </button>
                      )}
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภทนายจ้าง</span>
                      {/* <div className="d-flex align-items-center wpx-150">
                        <input type="radio" name="type" checked={this.state.emp_type === 1} disabled />
                        <span className="ms-2">บุคคลธรรมดา</span>
                      </div>
                      <div className="d-flex align-items-center wpx-120">
                        <input type="radio" name="type" checked={this.state.emp_type === 2} disabled />
                        <span className="ms-2">นิติบุคคล</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input type="radio" name="type" checked={this.state.emp_type === 3} disabled />
                        <span className="ms-2">นิติบุคคลต่างด้าว</span>
                      </div> */}
                      <input className="form-control mt-1" type="text" readOnly value={this.state.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_type === 2 ? "นิติบุคคล" : this.state.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""} />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อนายจ้าง</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ emp_full_name: e.target.value });
                        }}
                        value={this.state.emp_full_name}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                      <span className="wpx-150">English</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ full_name_en: e.target.value });
                        }}
                        value={this.state.full_name_en}
                      />
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary px-4 mb-3"
                      onClick={() => {
                        this.setState({ show: !this.state.show });
                      }}
                    >
                      {this.state.show ? "ซ่อนรายละเอียด" : "แสดงรายละเอียด"}
                    </button>
                  </div>
                  {this.state.show ? (
                    <div>
                      <div className="row">
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">จดทะเบียนเมื่อ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.register_date ? format_date(this.state.register_date) : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ทุนจดทะเบียน(บาท)</span>
                          <input className="form-control mt-1" type="text" readOnly value={toFixed(this.state.register_capital)} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">บัตรประชาชนเลขที่</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.emp_number} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">สถานที่ออกบัตร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.place_issue} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่ออกบัตร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.issue_date ? format_date(this.state.issue_date) : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">วันที่หมดอายุ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.expiry_date ? format_date(this.state.expiry_date) : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ประเภทกิจการ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.business_type.toString())[0].label : ""} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ประเภทกิจการ(English)</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.business_type.toString())[0].label : ""} />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ชื่อผู้ติดต่อ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.contact_name} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรศัพท์</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.phone} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรสาร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.fax} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรศัพท์มือถือ</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.phone} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">E-mail Address</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.email} />
                        </div>
                        { this.state.process_matter && !this.state.process_matter.label.includes('คนเถื่อน') && (
                          <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                            <span className="wpx-150">เลขรหัสประจำบ้าน ๑๑ หลัก</span>
                            <input className="form-control mt-1" type="text" readOnly value={this.state.home_number} />
                          </div>
                        )}
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ที่ตั้ง/ที่อยู่</span>
                          <input className="form-control mt-1" type="text" readOnly value={(this.state.address_number ? this.state.address_number : "") + (this.state.moo ? " หมู่ " + this.state.moo : "") + (this.state.soi ? " ซอย " + this.state.soi : "") + (this.state.street ? " ถนน " + this.state.street : "") + (this.state.sub_district ? " ตำบล " + this.state.sub_district : "") + (this.state.district ? " อำเภอ " + this.state.district : "") + (this.state.province ? " จังหวัด " + this.state.province : "") + (this.state.zipcode ? " รหัสไปรษณีย์ " + this.state.zipcode : "")} />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ที่ตั้ง/ที่อยู่(English)</span>
                          <input className="form-control mt-1" type="text" readOnly value={(this.state.address_number ? this.state.address_number : "") + (this.state.moo ? " Moo " + this.state.moo : "") + (this.state.soi ? " Soi " + this.state.soi : "") + (this.state.street ? " Street " + this.state.street : "") + (this.state.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.sub_district)[0]?.sub_district_name_en : "") + (this.state.district ? " , " + district.filter((e) => e.district_name === this.state.district)[0]?.district_name_en : "") + (this.state.province ? " , " + province.filter((e) => e.province_name === this.state.province)[0]?.province_name_en : "") + (this.state.zipcode ? " " + this.state.zipcode : "")} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรศัพท์</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.phone_2} />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">หมายเลขโทรสาร</span>
                          <input className="form-control mt-1" type="text" readOnly value={this.state.fax_2} />
                        </div>
                      </div>
                      {this.state.emp_id && (
                        <div>
                          <div className="d-flex justify-content-between mb-3">
                            <h5 className="mb-3">
                              <b>กรรมการผู้มีอำนาจ</b>
                            </h5>
                            {this.state.data_deputize.length < 3 && (
                              <button
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  let data_deputize = this.state.data_deputize;
                                  data_deputize.push({
                                    operator_type: 2,
                                    deputize_id: "",
                                    deputize_number: "",
                                    deputize_full_name: "",
                                    deputize_phone: "",
                                    deputize_position: "",
                                    copy_company_certificate: "",
                                    copy_company_certificate_date: "",
                                    copy_power_attorney: "",
                                    copy_power_attorney_date: "",
                                    copy_tax_certificate: "",
                                    copy_tax_certificate_date: "",
                                  });
                                  this.setState({ data_deputize: data_deputize });
                                }}
                              >
                                + เพิ่มกรรมการผู้มีอำนาจ
                              </button>
                            )}
                          </div>
                          <div className="card bg-contain p-3 pb-0">
                            {this.state.data_deputize.map((item, index) => (
                              <div className="card p-3 mb-3 position-relative">
                                <div
                                  className="wpx-30 hpx-30 rounded-circle d-flex align-items-center justify-content-center icon text-white pointer bg-danger position-absolute"
                                  style={{ top: -8, right: -8 }}
                                  onClick={() => {
                                    let data_deputize = this.state.data_deputize;
                                    data_deputize.splice(index, 1);
                                    this.setState({ data_deputize: data_deputize });
                                  }}
                                >
                                  {"\uf00d"}
                                </div>
                                <div className="row">
                                  <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <span className="wpx-220">ชื่อ - นามสกุล กรรมการผู้มีอำนาจ{item.operator_type === 2 ? required : null}</span>
                                    <input
                                      className="form-control mt-1"
                                      type="text"
                                      onChange={(e) => {
                                        let data_deputize = this.state.data_deputize;
                                        data_deputize[index].deputize_full_name = e.target.value;
                                        this.setState({ data_deputize: data_deputize });
                                      }}
                                      value={item.deputize_full_name}
                                    />
                                  </div>
                                  <div className="col-12 col-lg-6 d-flex align-items-center mb-3">
                                    <span className="wpx-80">ตำแหน่ง</span>
                                    <input
                                      className="form-control mt-1"
                                      type="text"
                                      onChange={(e) => {
                                        let data_deputize = this.state.data_deputize;
                                        data_deputize[index].deputize_position = e.target.value;
                                        this.setState({ data_deputize: data_deputize });
                                      }}
                                      value={item.deputize_position}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div> 
                <hr />
                <h5 className="mb-3">
                  <b>พยานสำหรับใบมอบอำนาจ</b>
                </h5>
                <div className="row mt-3">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 1 ไทย{required}</span>
                    <Select
                      className="form-control select-search"
                      styles={select_style}
                      options={this.state.data_admin}
                      onChange={(e) => {
                        if (e === this.state.witness_power_2_th) {
                          alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกพยานที่ซ้ำกันได้");
                          this.setState({ witness_power_1: "" });
                        } else {
                          this.setState({ witness_power_1: e });
                        }
                      }}
                      value={this.state.witness_power_1}
                    ></Select>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 1 English{required}</span>
                    <input type="text" className="form-control" value={this.state.witness_power_1 ? this.state.witness_power_1.full_name_en : ""} readOnly />
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 2 ไทย</span>
                    {/* <input
                    type="text"
                      className="form-control"  
                      onChange={(e) => {
                        this.setState({ witness_power_2_th: e.target.value });
                      }}
                      value={this.state.witness_power_2_th}
                    /> */}
                    <Select
                      className="form-control select-search"
                      styles={select_style}
                      options={this.state.data_admin}
                      onChange={(e) => {
                        if (e === this.state.witness_power_1) {
                          alert("warning", "แจ้งเตือน", "ไม่สามารถเลือกพยานที่ซ้ำกันได้");
                          this.setState({ witness_power_2_th: "" });
                        } else {
                          this.setState({ witness_power_2_th: e });
                        }
                      }}
                      value={this.state.witness_power_2_th}
                    ></Select>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 2 English</span>
                    {/* <input
                    type="text"
                      className="form-control"  
                      onChange={(e) => {
                        this.setState({ witness_power_2_en: e.target.value });
                      }}
                      value={this.state.witness_power_2_en}
                    /> */}
                    <input type="text" className="form-control" value={this.state.witness_power_2_th ? this.state.witness_power_2_th.full_name_en : ""} readOnly />
                  </div>
                </div>
                <hr />
                <h5 className="mb-3">
                  <b>พยานสำหรับสัญญา 3 ภาษา</b>
                </h5>
                <div className="row mt-3">
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 1 ไทย{required}</span>
                    <Select
                      className="form-control select-search"
                      styles={select_style}
                      options={this.state.data_admin}
                      onChange={(e) => {
                        this.setState({ witness_contract_1: e });
                      }}
                      value={this.state.witness_contract_1}
                    ></Select>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 1 English{required}</span>
                    <input type="text" className="form-control" value={this.state.witness_contract_1 ? this.state.witness_contract_1.full_name_en : ""} readOnly />
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 2 ไทย</span>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ witness_contract_2_th: e.target.value });
                      }}
                      value={this.state.witness_contract_2_th}
                    />
                  </div>{" "}
                  <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                    <span className="wpx-150">คนที่ 2 English</span>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ witness_contract_2_en: e.target.value });
                      }}
                      value={this.state.witness_contract_2_en}
                    />
                  </div>
                </div>
                <hr />
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>ข้อมูลรายละเอียดรับเรื่อง</b>
                  </h5>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                      <span className="wpx-150">ดำเนินการเรื่อง{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_menu_work}
                        onChange={(e) => {
                          this.setState({
                            process_matter: e,
                            country_qty: "",
                            cambodia_qty: "",
                            laos_qty: "",
                            myanmar_qty: "",
                            vietnam_qty: "",
                            total_qty: "",
                          });
                        }}
                        value={this.state.process_matter}
                        isDisabled={this.state.readOnly}
                      ></Select>
                    </div>
                  </div>
                  {this.state.process_matter && (
                    <div className="row">
                      {this.state.process_matter.label === "ขอนำเข้าแรงงานต่างด้าว (MOU)" ? (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white wpx-140">สัญชาติ</th>
                                <th className="text-center text-white">จำนวนคน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        country_qty: e.target.value,
                                        cambodia_qty: "",
                                        laos_qty: "",
                                        myanmar_qty: "",
                                        vietnam_qty: "",
                                        total_qty: "",
                                      });
                                    }}
                                    value={this.state.country_qty}
                                    disabled={this.state.readOnly}
                                  >
                                    <option value=""></option>
                                    <option value="กัมพูชา">กัมพูชา</option>
                                    <option value="ลาว">ลาว</option>
                                    <option value="เมียนมา">เมียนมา</option>
                                    <option value="เวียดนาม">เวียดนาม</option>
                                  </select>
                                </td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      switch (this.state.country_qty) {
                                        case "กัมพูชา":
                                          this.setState({
                                            cambodia_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            laos_qty: "",
                                            myanmar_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "ลาว":
                                          this.setState({
                                            laos_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            myanmar_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "เมียนมา":
                                          this.setState({
                                            myanmar_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            laos_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "เวียดนาม":
                                          this.setState({
                                            vietnam_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            laos_qty: "",
                                            myanmar_qty: "",
                                          });
                                          break;

                                        default:
                                          break;
                                      }
                                    }}
                                    value={this.state.country_qty === "กัมพูชา" ? this.state.cambodia_qty : this.state.country_qty === "ลาว" ? this.state.laos_qty : this.state.country_qty === "เมียนมา" ? this.state.myanmar_qty : this.state.country_qty === "เวียดนาม" ? this.state.vietnam_qty : ""}
                                    disabled={this.state.readOnly || !this.state.country_qty}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white wpx-140">สัญชาติ</th>
                                <th className="text-center text-white">จำนวนคน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">กัมพูชา</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ cambodia_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.cambodia_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">ลาว</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ laos_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.laos_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">เมียนมา</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ myanmar_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.myanmar_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">เวียดนาม</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ vietnam_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        this.setState({
                                          total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
                                        });
                                      }, 10);
                                    }}
                                    value={this.state.vietnam_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                      <span className="wpx-150">รวมทั้งสิ้น</span>
                      <input
                        className="form-control mt-1 text-end"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ total_qty: e.target.value });
                        }}
                        value={this.state.total_qty}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-start mb-3 mx-auto">
                      <span className="wpx-150">หมายเหตุ</span>
                      <textarea
                        className="form-control mt-1"
                        rows={5}
                        onChange={(e) => {
                          this.setState({ remark: e.target.value });
                        }}
                        value={this.state.remark}
                        disabled={this.state.readOnly}
                      />
                    </div>
                  </div>
                </div>
                <hr />

                {this.props.location.query.id && (
                  <div>
                    <div className="w-100">
                      <h5 className="mb-3">
                        <b>ข้อมูลรายละเอียดรับเรื่อง</b>
                      </h5>

                      <div className="row">
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">หนังสือรับรอง</span>
                          <input
                            className="mt-1 mx-3"
                            type="checkbox"
                            name="c_certificate"
                            onChange={(e) => {
                              this.setState({ c_certificate: !this.state.c_certificate });
                            }}
                            checked={this.state.c_certificate}
                          />
                          <span className="ms-3 wpx-100">วันที่ออก</span>
                          <DatePicker
                            className="form-control wpx-170 mt-1"
                            onChange={(e) => {
                              this.setState({ c_certificate_issue_date: e });
                            }}
                            value={this.state.c_certificate_issue_date}
                            max={new Date()}
                          />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">ภพ.20</span>
                          <input
                            className="mt-1 mx-3"
                            type="checkbox"
                            name="c_certificate_20"
                            onChange={(e) => {
                              this.setState({ c_certificate_20: !this.state.c_certificate_20 });
                            }}
                            checked={this.state.c_certificate_20}
                          />
                        </div>
                        <div className="col-12 d-flex align-items-center mb-3">
                          <span className="wpx-150">สำเนากรรมการ</span>
                          <input
                            className="mt-1 mx-3"
                            type="checkbox"
                            name="c_copy_committee"
                            onChange={(e) => {
                              this.setState({ c_copy_committee: !this.state.c_copy_committee });
                            }}
                            checked={this.state.c_copy_committee}
                          />
                          <span className="ms-3 wpx-100">วันที่หมดอายุ</span>
                          <DatePicker
                            className="form-control wpx-170 mt-1"
                            onChange={(e) => {
                              this.setState({ c_copy_committee_expire_date: e });
                            }}
                            value={this.state.c_copy_committee_expire_date}
                            min={new Date()}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="w-100">
                      <h5 className="mb-3">
                        <b>ข้อมูลรายละเอียดรับเรื่อง</b>
                      </h5>
                      <div className="table-responsive cs-table-responsive">
                      { this.state.process_matter && (this.state.process_matter.label.includes("90 วัน") || this.state.process_matter.label.includes("แจ้งเข้าที่พัก")) ? (
                        <table className="table table-bordered table-striped table-fixed3">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white wpx-60">ลำดับ</th>
                            <th className="text-center text-white wpx-200">เลขประจำตัว 13 หลัก</th>
                            <th className="text-center text-white wpx-200">Name List</th>
                            <th className="text-center text-white" style={{ minWidth: 830 }} colSpan={4}>
                              เอกสารลูกจ้าง
                              <input
                                className="mx-2"
                                type="checkbox"
                                onChange={(e) => {
                                  let checklist_data = this.state.checklist_data;
                                  for (let i = 0; i < checklist_data.length; i++) {
                                    checklist_data[i].c_passport = e.target.checked;
                                    checklist_data[i].c_visa = e.target.checked;
                                    checklist_data[i].c_work_permit = e.target.checked;
                                  }
                                  this.setState({ checklist_data: checklist_data });
                                }}
                                checked={this.state.checklist_data.filter((item) => !item.c_passport).length === 0 && this.state.checklist_data.filter((item) => !item.c_visa).length === 0 && this.state.checklist_data.filter((item) => !item.c_work_permit).length === 0}
                              />{" "}
                              ทั้งหมด
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.checklist_data &&
                            this.state.checklist_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center" style={{ verticalAlign: "middle" }}>
                                  {index + 1}
                                </td>
                                <td className="text-left">
                                  <input
                                    className="form-control"
                                    type="text"
                                    maxLength={13}
                                    onChange={(e) => {
                                      let checklist_data = this.state.checklist_data;
                                      checklist_data[index].id_card = e.target.value;
                                      this.setState({ checklist_data: checklist_data });
                                    }}
                                    value={item.id_card}
                                  />
                                </td>
                                <td className="text-left">
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) => {
                                      let checklist_data = this.state.checklist_data;
                                      checklist_data[index].name_list = e.target.value;
                                      this.setState({ checklist_data: checklist_data });
                                    }}
                                    value={item.name_list}
                                  />
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_passport = e.target.checked;
                                        checklist_data[index].c_visa = e.target.checked;
                                        checklist_data[index].c_work_permit = e.target.checked;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_passport && item.c_visa && item.c_work_permit}
                                    />{" "}
                                    ทั้งหมด
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_passport = !checklist_data[index].c_passport;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_passport}
                                    />{" "}
                                    Passport
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_visa = !checklist_data[index].c_visa;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_visa}
                                    />{" "}
                                    Visa
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_work_permit = !checklist_data[index].c_work_permit;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_work_permit}
                                    />{" "}
                                    Work permit
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      ) : (
                        <table className="table table-bordered table-striped table-fixed3">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white wpx-60">ลำดับ</th>
                            <th className="text-center text-white wpx-200">เลขประจำตัว 13 หลัก</th>
                            <th className="text-center text-white wpx-200">Name List</th>
                            <th className="text-center text-white" style={{ minWidth: 830 }} colSpan={8}>
                              เอกสารลูกจ้าง
                              <input
                                className="mx-2"
                                type="checkbox"
                                onChange={(e) => {
                                  let checklist_data = this.state.checklist_data;
                                  for (let i = 0; i < checklist_data.length; i++) {
                                    checklist_data[i].c_passport = e.target.checked;
                                    checklist_data[i].c_visa = e.target.checked;
                                    checklist_data[i].c_work_permit = e.target.checked;
                                    checklist_data[i].c_disease = e.target.checked;
                                    checklist_data[i].c_health_insurance = e.target.checked;
                                    checklist_data[i].c_consent_address = e.target.checked;
                                    checklist_data[i].c_profile = e.target.checked;
                                  }
                                  this.setState({ checklist_data: checklist_data });
                                }}
                                checked={this.state.checklist_data.filter((item) => !item.c_passport).length === 0 && this.state.checklist_data.filter((item) => !item.c_visa).length === 0 && this.state.checklist_data.filter((item) => !item.c_work_permit).length === 0 && this.state.checklist_data.filter((item) => !item.c_disease).length === 0 && this.state.checklist_data.filter((item) => !item.c_health_insurance).length === 0 && this.state.checklist_data.filter((item) => !item.c_consent_address).length === 0 && this.state.checklist_data.filter((item) => !item.c_profile).length === 0}
                              />{" "}
                              ทั้งหมด
                            </th>
                            <th className="text-center text-white">No</th>
                            <th className="text-center text-white" style={{ minWidth: 470 }} colSpan={3}>
                              ประกันสุขภาพ
                            </th>
                            <th className="text-center text-white" style={{ minWidth: 760 }} colSpan={4}>
                              บริการตรวจโรค
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.checklist_data &&
                            this.state.checklist_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center" style={{ verticalAlign: "middle" }}>
                                  {index + 1}
                                </td>
                                <td className="text-left">
                                  <input
                                    className="form-control"
                                    type="text"
                                    maxLength={13}
                                    onChange={(e) => {
                                      let checklist_data = this.state.checklist_data;
                                      checklist_data[index].id_card = e.target.value;
                                      this.setState({ checklist_data: checklist_data });
                                    }}
                                    value={item.id_card}
                                  />
                                </td>
                                <td className="text-left">
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) => {
                                      let checklist_data = this.state.checklist_data;
                                      checklist_data[index].name_list = e.target.value;
                                      this.setState({ checklist_data: checklist_data });
                                    }}
                                    value={item.name_list}
                                  />
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_passport = e.target.checked;
                                        checklist_data[index].c_visa = e.target.checked;
                                        checklist_data[index].c_work_permit = e.target.checked;
                                        checklist_data[index].c_disease = e.target.checked;
                                        checklist_data[index].c_health_insurance = e.target.checked;
                                        checklist_data[index].c_consent_address = e.target.checked;
                                        checklist_data[index].c_profile = e.target.checked;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_passport && item.c_visa && item.c_work_permit && item.c_disease && item.c_health_insurance && item.c_consent_address && item.c_profile}
                                    />{" "}
                                    ทั้งหมด
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_passport = !checklist_data[index].c_passport;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_passport}
                                    />{" "}
                                    Passport
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_visa = !checklist_data[index].c_visa;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_visa}
                                    />{" "}
                                    Visa
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_work_permit = !checklist_data[index].c_work_permit;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_work_permit}
                                    />{" "}
                                    Work permit
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_disease = !checklist_data[index].c_disease;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_disease}
                                    />{" "}
                                    ใบตรวจโรค
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_health_insurance = !checklist_data[index].c_health_insurance;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_health_insurance}
                                    />{" "}
                                    ประกันสุขภาพ
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_consent_address = !checklist_data[index].c_consent_address;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_consent_address}
                                    />{" "}
                                    ยินยอมให้ใช้ที่อยู่
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_profile = !checklist_data[index].c_profile;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_profile}
                                    />{" "}
                                    รูปถ่าย
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="checkbox"
                                      onChange={(e) => {
                                        let checklist_data = this.state.checklist_data;
                                        checklist_data[index].c_no = !checklist_data[index].c_no;
                                        this.setState({ checklist_data: checklist_data });
                                      }}
                                      checked={item.c_no}
                                    />{" "}
                                    No
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_health_insurance" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_health_insurance = true;
                                        fw_data[index].type_health_insurance = "ทำประกันสุขภาพเอกชน";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_health_insurance === "ทำประกันสุขภาพเอกชน" && this.state.fw_data[index].buy_health_insurance === true}
                                    />
                                    ทำประกันสุขภาพเอกชน
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_health_insurance" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_health_insurance = true;
                                        fw_data[index].type_health_insurance = "ทำประกันสุขภาพรัฐบาล";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_health_insurance === "ทำประกันสุขภาพรัฐบาล" && this.state.fw_data[index].buy_health_insurance === true}
                                    />
                                    ทำประกันสุขภาพรัฐบาล
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_health_insurance" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_health_insurance = false;
                                        fw_data[index].type_health_insurance = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_health_insurance === "" && this.state.fw_data[index].buy_health_insurance === false}
                                    />
                                    ไม่ทำประกัน
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_disease_service" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_disease_service = true;
                                        fw_data[index].type_disease_service = "พาคนไปตรวจจริง";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_disease_service === "พาคนไปตรวจจริง" && this.state.fw_data[index].buy_disease_service === true}
                                    />
                                    พาคนไปตรวจจริง
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_disease_service" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_disease_service = true;
                                        fw_data[index].type_disease_service = "ซื้อใบรับรองแพทย์ เอาใบเสร็จ";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_disease_service === "ซื้อใบรับรองแพทย์ เอาใบเสร็จ" && this.state.fw_data[index].buy_disease_service === true}
                                    />
                                    ซื้อใบรับรองแพทย์ เอาใบเสร็จ
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_disease_service" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_disease_service = true;
                                        fw_data[index].type_disease_service = "ซื้อใบรับรองแพทย์ ไม่เอาใบเสร็จ";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_disease_service === "ซื้อใบรับรองแพทย์ ไม่เอาใบเสร็จ" && this.state.fw_data[index].buy_disease_service === true}
                                    />
                                    ซื้อใบรับรองแพทย์ ไม่เอาใบเสร็จ
                                  </div>
                                </td>
                                <td className="text-left">
                                  <div className="w-100  d-flex align-items-center">
                                    <input
                                      className="mt-1 me-2"
                                      type="radio"
                                      name={"type_disease_service" + index}
                                      onChange={(e) => {
                                        let fw_data = this.state.fw_data;
                                        fw_data[index].buy_disease_service = false;
                                        fw_data[index].type_disease_service = "";
                                        this.setState({ fw_data: fw_data });
                                      }}
                                      checked={this.state.fw_data[index].type_disease_service === "" && this.state.fw_data[index].buy_disease_service === false}
                                    />
                                    ไม่ซื้อบริการตรวจโรค
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      ) }
                        
                      </div>
                    </div>
                  </div>
                )}
                  {/* <div className="col-12 d-flex align-items-center mb-3">
                    <span className="wpx-150">ใช้รายชื่อลูกจ้าง{required}</span>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="listname_choice"
                      onChange={(e) => {
                        this.setState({ listname_choice: "รายเก่า" });
                      }}
                      checked={this.state.listname_choice === "รายเก่า"}
                      disabled={this.state.readOnly}
                    />
                    รายเก่า
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="listname_choice"
                      onChange={(e) => {
                        this.setState({ listname_choice: "รายใหม่" });
                      }}
                      checked={this.state.listname_choice === "รายใหม่"}
                      disabled={this.state.readOnly}
                    />
                    รายใหม่
                  </div> */}
                <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/RecieveAll/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* เพิ่มสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_workplace_create || this.state.modal_workplace_update}
          onHide={() => {
            this.setState({ modal_workplace_create: false, modal_workplace_update: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มสถานที่ทำงานใหม่</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-1">
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">เลขที่(ที่อยู่){required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ address_number: e.target.value });
                  }}
                  value={this.state.address_number}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">หมู่ที่</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ moo: e.target.value });
                  }}
                  value={this.state.moo}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ซอย</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ soi: e.target.value });
                  }}
                  value={this.state.soi}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ถนน</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ street: e.target.value });
                  }}
                  value={this.state.street}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">จังหวัด{required}</span>
                <Select
                  className="form-control select-search"
                  styles={select_style}
                  options={province.map((item) => {
                    return { value: item.province_name, label: item.province_name };
                  })}
                  onChange={(e) => {
                    this.setState({ province: e, district: null, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150"> อำเภอ / เขต{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                  styles={select_style}
                  options={district
                    .filter((item) => this.state.province && this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.district_name, label: item.district_name };
                    })}
                  onChange={(e) => {
                    this.setState({ district: e, sub_district: null, zip_code: "" });
                  }}
                  value={this.state.district}
                  isDisabled={!this.state.province}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">ตำบล / แขวง{required}</span>
                <Select
                  className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                  styles={select_style}
                  options={subdistrict
                    .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&& this.state.province.value === item.province_name)
                    .map((item) => {
                      return { value: item.sub_district_name, label: item.sub_district_name, zip_code: item.zip_code };
                    })}
                  onChange={(e) => {
                    this.setState({ sub_district: e, zipcode: e.zip_code.toString() });
                  }}
                  value={this.state.sub_district}
                  isDisabled={!this.state.district}
                ></Select>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                <input
                  className="form-control"
                  type="text"
                  placeholder="รหัสไปรษณีย์"
                  onChange={(e) => {
                    this.setState({ zipcode: e.target.value });
                  }}
                  value={this.state.zipcode}
                  disabled
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">โทรศัพท์</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ phone_create: e.target.value });
                  }}
                  value={this.state.phone_create}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                <span className="wpx-150">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ email_create: e.target.value });
                  }}
                  value={this.state.email_create}
                />
              </div>
            </div>
            {/* <hr />
              <div className="d-flex align-items-center justify-content-between mb-3">
                  <span><span className="wpx-150">รายการเอกสารแนบ</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)</span>
              </div>
              <div className="table-responsive">
                  <table className="table table-borderless table-striped">
                      <thead className="bg-primary">
                          <tr>
                              <th className="text-center text-white">ลำดับ</th>
                              <th className="text-center text-white">รายการ</th>
                              <th className="text-center text-white">วันที่แนบเอกสารล่าสุด</th>
                              <th className="text-center text-white">ดำเนินการ</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td className="text-center">1</td>
                              <td className="text-start">หลักฐานการเพิ่มสถานที่ทำงานใหม่และแผนที่ เช่น<br />
                                  - สำเนาทะเบียนบ้าน หรือ<br />
                                  - สำเนาเช่าสำนักงาน/ร้านค้า/โรงงาน หรือ<br />
                                  - สัญญาจ้างงานของโครงการก่อสร้าง หรือ<br />
                                  - สำเนาโฉนดที่ดิน {required}</td>
                              <td className="text-center">{format_date(new Date(), "dd mmm yyyy")} เวลา {format_date(new Date(), "hh:mm")} น.</td>
                              <td className="text-center">
                                  <button className="btn btn-primary mx-1 px-4" onClick={() => { }}>
                                      แนบไฟล์
                                  </button>
                                  <div className="w-100 d-flex justify-content-center">
                                      <div className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                          onClick={() => { this.setState({ modal_workplace_detail: true, attachment_id: "", attachment_name: "", attachment_file: "" }) }}>
                                          <label className="pointer icon text-primary p-2">{"\uf15c"}</label>
                                      </div>
                                      <div className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                          onClick={() => { this.setState({ modal_workplace_delete: true, attachment_id: "", attachment_name: "" }) }}>
                                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <span className="d-flex align-items-center">หมายเหตุ :
                  <div className="ms-3 me-2 wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center">
                      <label className="icon text-primary p-2">{"\uf15c"}</label>
                  </div>
                  แสดงเอกสารแนบ
                  <div className="ms-5 me-2 wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center">
                      <label className="icon text-danger p-2">{"\uf2ed"}</label>
                  </div>
                  ลบเอกสาร
              </span> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_workplace_create: false, modal_workplace_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.address_number === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                      return;
                    } else if (!this.state.province) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                      return;
                    } else if (!this.state.district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                      return;
                    } else if (!this.state.sub_district) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                      return;
                    } else if (this.state.zipcode === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                      return;
                    }
                    this.CreateWorkplace();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ลบสถานที่ทำงาน */}
        <Modal
          show={this.state.modal_workplace_delete}
          onHide={() => {
            this.setState({ modal_workplace_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบสถานที่ทำงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.address_number} ” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_workplace = this.state.data_workplace;
                    data_workplace.splice(this.state.index, 1);
                    this.setState({ data_workplace: data_workplace, modal_workplace_delete: false });
                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_workplace_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* เพิ่มตัวแทน */}
        <Modal
          show={this.state.modal_agent_create}
          onHide={() => {
            this.setState({ modal_agent_create: false });
          }}
          size="md"
        >
          <Modal.Header closeButton>
            {" "}
            <Modal.Title>
              {" "}
              <b>เพิ่มตัวแทน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">ชื่อ - นามสกุล{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  onChange={(e) => {
                    this.setState({ agent_name: e.target.value });
                  }}
                  value={this.state.agent_name}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">โทรศัพท์{required}</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={10}
                  onChange={(e) => {
                    if (!float(e.target.value)) {
                      return;
                    }
                    this.setState({ agent_phone: e.target.value });
                  }}
                  value={this.state.agent_phone}
                />
              </div>
              <div className="col-12 col-md-12 d-flex align-items-center mb-3">
                <span className="wpx-120">อีเมล</span>
                <input
                  className="form-control mt-1"
                  type="email"
                  onChange={(e) => {
                    this.setState({ agent_email: e.target.value });
                  }}
                  value={this.state.agent_email}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_agent_create: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.agent_name === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อ - นามสกุล");
                      return;
                    } else if (this.state.agent_phone === "") {
                      alert("warning", "แจ้งเตือน", "กรุณากรอกเบอร์โทร");
                      return;
                    }
                    this.CreateAgent();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

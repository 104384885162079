import React, { Component } from "react";
import { removeStorage, getStorage } from "../components/CustomComponent.js";
import "../assets/css/bootstrap.css";

export default class Loading extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
      if (user && user.role_name === "line_oa") {
        window.location.href = "/upload";
      } else if (user&& user.user_type === "owner") {
        window.location.href = "/console";
      } else if (user) {
        window.location.href = "/Employer/list";
      } else {
        window.location.href = "/Login";
      }
    } else {
      localStorage.clear();
      window.location.href = "/login";
    }
  }
  render() {
    return <div></div>;
  }
}
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import Switch from "react-switch";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import province from "../../../assets/files/province.json";
import district from "../../../assets/files/district.json";
import subdistrict from "../../../assets/files/all_province.json";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      category_list: [],
      is_active: false,
    };
  }
  async componentDidMount() {
    this.Get();
  }
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      category_id: "",
    };
    let result = await GET(tokens, "api/setting/category-lms/list", body);
    if (result && result.status) {
      this.setState({ category_list: result.data });
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      category_list: this.state.category_list,
    };
    let result = await PUT(tokens, "api/setting/category-lms/update", body);
    if (result && result.status) {
      success_url("/setting/LMSCategory/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="จัดการหมวดหมู่ระบบ LMS" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>จัดการหมวดหมู่ระบบ LMS</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <div className="card p-3">
                    <div className="row">
                      {this.state.category_list.map((item, index) => (
                        <div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="wpx-50">{index + 1}.</div>
                            <div className="mx-1 w-50">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => {
                                  let category_list = this.state.category_list;
                                  category_list[index].category_name = e.target.value;
                                  this.setState({ category_list: category_list });
                                }}
                                value={item.category_name}
                              />
                            </div>
                            <div className="wpx-50 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  let category_list = this.state.category_list;
                                  category_list.splice(index, 1);
                                  this.setState({ category_list: category_list });
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                              </div>
                            </div>
                          </div>
                          {item.category_list.map((child_item, child_index) => (
                            <div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="wpx-50"></div>
                                <div className="wpx-50">
                                  {index + 1}.{child_index + 1}.
                                </div>
                                <div className="mx-1 w-50">
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) => {
                                      let category_list = this.state.category_list;
                                      category_list[index].category_list[child_index].name = e.target.value;
                                      this.setState({ category_list: category_list });
                                    }}
                                    value={child_item.name}
                                  />
                                </div>
                                <div className="wpx-50 d-flex justify-content-center">
                                  <div
                                    className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      let category_list = this.state.category_list;
                                      category_list[index].category_list.splice(child_index, 1);
                                      this.setState({ category_list: category_list });
                                    }}
                                  >
                                    <label className="pointer icon text-warning p-2">{"\uf2ed"}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="d-flex align-items-center mb-3 create">
                            <div className="wpx-50"></div>
                            <div className="mx-1 w-100">
                              <button
                                type="button"
                                className="btn btn-primary px-4"
                                onClick={() => {
                                  let category_list = this.state.category_list;
                                  category_list[index].category_list.push({
                                    name: "",
                                  });
                                  this.setState({ category_list: category_list });
                                }}
                              >
                                เพิ่มหมวดหมู่ย่อย
                              </button>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between create">
                      <button
                        type="button"
                        className="btn btn-primary px-4"
                        onClick={() => {
                          let category_list = this.state.category_list;
                          category_list.push({
                            category_id: "",
                            category_name: "",
                            category_list: [],
                          });
                          this.setState({ category_list: category_list });
                        }}
                      >
                        เพิ่มหมวดหมู่
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row update">
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/setting/LMSCategory/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            this.Update();
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { locale, tokens, select_style, total, success, success_url, alert, alert_url, toFixed, GET, POST, PUT, DELETE, random_charactor, format_date, number, float, validate, required, DatePicker, NumberZero, Logout } from "../components/CustomComponent.js";
import Select from "react-select";
import LOGO from "../assets/images/logo.png";

import Resizer from "react-image-file-resizer";

import "../assets/css/bootstrap.css";
export default class Console extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      search: "",

      data: null,

      modal_create: false,
      modal_update: false,
      modal_logout: false,

      select: "",

      user_id: "",
      username: "",
      email: "",
      full_name: "",
      full_name_en: "",
      phone: "",
      password: "",
      confirm_password: "",
    };
  }

  async componentDidMount() {
    localStorage.removeItem("token_user");
    localStorage.removeItem("detail_user");
    await this.GetAll();
    // await this.GetUserAll();
  }
  GetAll = async () => {
    let result = await GET(tokens, "api/user/console", null);
    if (result && result.status) {
      this.setState({ data: result.data });
    }
  };
  Selected = async (item) => {
    this.setState({ loading: true });
    let data = {
      user_id: item.user_id,
    };
    let result = await POST(tokens, "api/console-login/bypass", data);
    if (result && result.status) {
      localStorage.setItem("token_user", "token " + result.token);
      localStorage.setItem("detail_user", JSON.stringify(item));
      window.location.href = "/Employer/list";
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    if (this.state.full_name.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    } else if (this.state.username.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อผู้ใช้งาน");
      return;
    } else if (this.state.password.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกรหัสผ่าน");
      return;
    }
    if (this.state.password !== this.state.confirm_password) {
      alert("primary", "แจ้งเตือน", "รหัสผ่านไม่ตรงกัน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      full_name: this.state.full_name,
      full_name_en: this.state.full_name_en,
      phone: this.state.phone,
      role_id: "",
      user_type: "super_user",
    };
    let result = await POST(tokens, "api/user/create", data);
    if (result && result.status) {
      success_url("/console");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    if (this.state.full_name.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อ-นามสกุล");
      return;
    } else if (this.state.username.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อผู้ใช้งาน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      full_name: this.state.full_name,
      username: this.state.username,
      email: this.state.email,
      role_id: this.state.role_id,
    };
    let result = await PUT(tokens, "api/user/update/" + this.state.user_id, data);
    if (result && result.status) {
      success_url("/console");
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "v1/" + locale + "/product-mgt/all/delete/" + this.state.product_id, null);
    if (result && result.status) {
      this.clear_form();
      success();
      this.GetAll();
    }
    this.setState({ loading: false, modal_delete: false });
  };
  clear_form() {
    this.setState({
      loading: false,

      search: "",

      modal_create: false,
      modal_update: false,
      modal_logout: false,

      select: "",

      full_name: "",
      username: "",
      password: "",
      confirm_password: "",
    });
  }
  Logout = async () => {
    await GET(tokens, "v1/" + locale + "/logout", null);
    localStorage.clear();
    window.location.href = "/";
  };
  render() {
    return (
      <div className="fade-in w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="w-100 bg-white">
          <div className="container py-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img className="me-3" alt="" src={LOGO} style={{ width: 60 }} />
                <h4>
                  <b>สวัสดี {localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).full_name : ""}</b>
                </h4>
              </div>
              <label
                className="icon text-danger p-3"
                onClick={() => {
                  this.setState({ modal_logout: true });
                }}
              >
                {"\uf2f5"}
              </label>
            </div>
          </div>
        </div>
        <div className="container pt-3">
          <div className="mx-0 d-flex justify-content-between flex-nowrap overflow-scroll none-scroll">
            <div className="w-100 d-flex justify-content-between">
              <input
                className="form-control bg-white wpx-200 border-0 mb-3"
                type="search"
                placeholder="ค้นหา"
                value={this.state.search}
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
              />
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  className="btn btn-dark px-4 mx-3"
                  onClick={() => {
                    localStorage.setItem("manage_role", "owner");
                    window.location.href = "/setting/Prefix/list";
                  }}
                >
                  จัดการระบบ
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-24 mr-3"
                  onClick={() => {
                    this.clear_form();
                    this.setState({ modal_create: true });
                  }}
                >
                  เพิ่มผู้ใช้งาน
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-3">
          <div className="row">
            {this.state.data &&
              this.state.data
                .filter((item) => item.full_name.includes(this.state.search) || this.state.search === "")
                .map((item, index) => (
                  <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card border-0 shadow mb-3 user-profile position-relative">
                      <div className="d-flex">
                        <img className="mx-3" alt="" src={LOGO} style={{ width: 80 }} />
                        <div className="w-100 p-2 position-relative">
                          <b>ชื่อ : {item.full_name}</b>
                          <br />
                          <b>Name : {item.full_name_en ? item.full_name_en : "-"}</b>
                          <br />
                          <label>{item.user_code ? item.user_code : "-"}</label>
                          <label
                            className="icon p-3 position-absolute text-success pointer"
                            style={{ right: 28, top: 0 }}
                            onClick={() => {
                              localStorage.setItem("manage_role", "consult");
                              this.Selected(item);
                            }}
                          >
                            {"\uf04b"}
                          </label>
                          <label
                            className="icon p-3 position-absolute pointer"
                            style={{ right: 0, top: 0 }}
                            onClick={() => {
                              this.setState({
                                modal_update: true,
                                user_id: item.user_id,
                                username: item.username,
                                email: item.email,
                                full_name: item.full_name,
                                full_name_en: item.full_name_en,
                                phone: item.phone,
                              });
                            }}
                          >
                            {"\uf304"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        {/* MODAL */}
        {/* create */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.modal_create ? "เพิ่มผู้ใช้งาน" : "แก้ไขผู้ใช้งาน"}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <b>ชื่อ-นามสกุล{required}</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="ชื่อ-นามสกุล"
              onChange={(e) => {
                this.setState({ full_name: e.target.value });
              }}
              value={this.state.full_name}
            />
            <b>ชื่อ-นามสกุล Eng</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="ชื่อ-นามสกุล Eng"
              onChange={(e) => {
                this.setState({ full_name_en: e.target.value });
              }}
              value={this.state.full_name_en}
            />
            <b>อีเมล</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="อีเมล"
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              value={this.state.email}
            />
            <b>เบอร์โทรศัพท์</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="เบอร์โทรศัพท์"
              maxLength={10}
              onChange={(e) => {
                if (!float(e.target.value)) {
                  return;
                }
                this.setState({ phone: e.target.value });
              }}
              value={this.state.phone}
            />
            <b>รหัสผู้ใช้งาน{required}</b>
            <input
              className="form-control mt-1 mb-3"
              type="text"
              placeholder="รหัสผู้ใช้งาน"
              onChange={(e) => {
                this.setState({ username: e.target.value });
              }}
              value={this.state.username}
              readOnly={this.state.modal_update}
            />
            {this.state.modal_create && (
              <div>
                <b>ระบุรหัสผ่าน{required}</b>
                <input
                  className="form-control mt-1 mb-3"
                  type="password"
                  placeholder="รหัสผ่าน"
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  value={this.state.password}
                />
                <b>ระบุรหัสผ่านอีกครั้ง{required}</b>
                <input
                  className="form-control mt-1 mb-3"
                  type="password"
                  placeholder="รหัสผ่าน"
                  onChange={(e) => {
                    this.setState({ confirm_password: e.target.value });
                  }}
                  value={this.state.confirm_password}
                />
              </div>
            )}
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.modal_update) {
                      this.Update();
                    } else {
                      this.Create();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* logout */}
        <Modal
          show={this.state.modal_logout}
          onHide={() => {
            this.setState({ modal_logout: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ออกจากระบบ</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="w-100  mb-3">
              <label>ยืนยันการออกจากระบบ</label>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_logout: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Logout();
                  }}
                >
                  ออกจากระบบ
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

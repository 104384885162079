import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  document_bt46_ENTH,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_match2: false,
      modal_match4: false,
      modal_match7: false,
      loading: false,
      data: [],
      data_workplace: [],

      data_search: "",

      checklist_data: [],
      fw_data: [],
      receive_id: "",
      receive_code: "",
      receive_date: null,
      branch: "",
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      emp_number: "",
      process_matter: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: null,
      c_certificate_20: null,
      c_copy_committee: null,
      receive_status: 0,
      receive_date_s1: null,
      receive_by_s1: null,
      receive_date_s2: null,
      receive_by_s2: null,
      receive_date_s3: null,
      receive_by_s3: null,
      receive_date_s4: null,
      receive_by_s4: null,
      receive_date_s5: null,
      receive_by_s5: null,
      receive_date_s6: null,
      receive_by_s6: null,
      receive_date_s7: null,
      receive_by_s7: null,
      receive_date_s8: null,
      receive_by_s8: null,
      receive_date_s9: null,
      receive_by_s9: null,
      receive_date_s10: null,
      receive_by_s10: null,
      receive_date_s11: null,
      receive_by_s11: null,
      receive_date_s12: null,
      receive_by_s12: null,
      receive_date_s13: null,
      receive_by_s13: null,
      receive_date_s14: null,
      receive_by_s14: null,
      receive_date_s15: null,
      receive_by_s15: null,
      receive_date_s16: null,
      receive_by_s16: null,
      receive_date_s17: null,
      receive_by_s17: null,
      receive_date_s18: null,
      receive_by_s18: null,
      receive_date_s19: null,
      receive_by_s19: null,
      receive_date_s20: null,
      receive_by_s20: null,
      receive_date_s21: null,
      receive_by_s21: null,
      is_active: true,
      create_by: "",
      emp_data: null,
      menu_data: null,
      dpz_data: [],

      r_username: "",
      r_password: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,

      show_tab: 0,

      data_business_type: [],

      data_salary: [],

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      data_prefix_th: [],
      data_prefix_en: [],

      data_work_type: [],
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetWorkType();
    await this.GetBusinessType();
    await this.GetSalary();
    await this.GetAdmin();
    await this.GetPrefix();
    await this.Get();
    this.setState({ loading: false });
  }
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetAdmin = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
  };
  Get = async () => {
    let body = {
      data_search: this.state.data_search,
      receive_id: "",
      menu: "QC",
      menu_id: 7,
    };
    let result = await GET(tokens, "api/receive-matter/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
      });
    }
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
          };
        }),
      });
    }
  };
  GetSalary = async () => {
    let body = {
      data_search: "",
      minimum_wage_id: "",
    };
    let result = await GET(tokens, "api/minimum-wage/list", body);
    if (result && result.status) {
      this.setState({
        data_salary: result.data,
      });
    }
  };
  Status = (item) => {
    let component = null;
    switch (item.receive_status) {
      case 1:
        component = <label>รอ Matching</label>;
        break;
      case 2:
        component = <label>มอบอำนาจ Matching</label>;
        break;
      case 3:
        component = <label>ใช้ User ของนายจ้าง</label>;
        break;
      case 4:
        component = <label>Machting ไม่ได้</label>;
        break;
      case 5:
        component = <label>Machting สำเร็จ</label>;
        break;
      case 6:
        component = <label>บันทึกลูกจ้างสำเร็จ</label>;
        break;
      case 7:
        component = <label>รอตรวจสอบ บต.46+มอบอำนาจ</label>;
        break;
      case 8:
        component = <label>เอกสารไม่ถูกต้อง บต.46</label>;
        break;
      case 9:
        component = <label>เอกสาร บต.46+มอบอำนาจ ถูกต้อง</label>;
        break;
      case 10:
        component = <label>ส่งเอกสาร บต.46+มอบอำนาจ แล้ว</label>;
        break;
      case 11:
        component = item.fw_data && item.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 ? <label>เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย</label> : <label>รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย</label>;
        break;
      case 12:
        component = <label>เอกสารถูกต้อง พร้อมยื่น</label>;
        break;
      case 13:
        component = <label>ได้เลข WP</label>;
        break;
      case 14:
        component = <label>ได้เลข WP แล้ว รออนุมัติ</label>;
        break;
      case 15:
        component = <label>แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน</label>;
        break;
      case 16:
        component = <label>จัดส่งเอกสารใบเสร็จ</label>;
        break;
      case 17:
        component = <label>ส่งใบเสร็จให้ลูกค้าแล้ว รอออก eWork</label>;
        break;
      case 18:
        component = <label>ออก eWork แล้ว</label>;
        break;
      case 19:
        component = <label>รับ eWork แล้ว รอจัดส่ง</label>;
        break;
      case 20:
        component = <label>ส่ง eWork แล้ว</label>;
        break;
      case 21:
        component = <label>ปิดงาน</label>;
        break;
        case 22:
          component = <label>เปิดเรื่องแล้ว</label>;
          break;
      default:
        break;
    }
    return component;
  };
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      this.setState({
        checklist_data: result.data.checklist_data,
        fw_data:
          result.data.receive_status === 11 && result.data.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === result.data.fw_data.length
            ? result.data.fw_data.map((e) => {
                return { ...e, status_document: null };
              })
            : result.data.fw_data,
        receive_id: result.data.receive_id,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch,
        immigration_office: result.data.immigration_office,
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.broker,
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        receive_status: result.data.receive_status,
        receive_date_s1: result.data.receive_date_s1,
        receive_by_s1: result.data.receive_by_s1,
        receive_date_s2: result.data.receive_date_s2,
        receive_by_s2: result.data.receive_by_s2,
        receive_date_s3: result.data.receive_date_s3,
        receive_by_s3: result.data.receive_by_s3,
        receive_date_s4: result.data.receive_date_s4,
        receive_by_s4: result.data.receive_by_s4,
        receive_date_s5: result.data.receive_date_s5,
        receive_by_s5: result.data.receive_by_s5,
        receive_date_s6: result.data.receive_date_s6,
        receive_by_s6: result.data.receive_by_s6,
        receive_date_s7: result.data.receive_date_s7,
        receive_by_s7: result.data.receive_by_s7,
        receive_date_s8: result.data.receive_date_s8,
        receive_by_s8: result.data.receive_by_s8,
        receive_date_s9: result.data.receive_date_s9,
        receive_by_s9: result.data.receive_by_s9,
        receive_date_s10: result.data.receive_date_s10,
        receive_by_s10: result.data.receive_by_s10,
        receive_date_s11: result.data.receive_date_s11,
        receive_by_s11: result.data.receive_by_s11,
        receive_date_s12: result.data.receive_date_s12,
        receive_by_s12: result.data.receive_by_s12,
        receive_date_s13: result.data.receive_date_s13,
        receive_by_s13: result.data.receive_by_s13,
        receive_date_s14: result.data.receive_date_s14,
        receive_by_s14: result.data.receive_by_s14,
        receive_date_s15: result.data.receive_date_s15,
        receive_by_s15: result.data.receive_by_s15,
        receive_date_s16: result.data.receive_date_s16,
        receive_by_s16: result.data.receive_by_s16,
        receive_date_s17: result.data.receive_date_s17,
        receive_by_s17: result.data.receive_by_s17,
        receive_date_s18: result.data.receive_date_s18,
        receive_by_s18: result.data.receive_by_s18,
        receive_date_s19: result.data.receive_date_s19,
        receive_by_s19: result.data.receive_by_s19,
        receive_date_s20: result.data.receive_date_s20,
        receive_by_s20: result.data.receive_by_s20,
        receive_date_s21: result.data.receive_date_s21,
        receive_by_s21: result.data.receive_by_s21,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
        menu_data: result.data.menu_data,
        dpz_data: result.data.dpz_data ? result.data.dpz_data : [],

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: result.data.witness_power_2_en,
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,
      });
      if (result.data.emp_data) {
        await this.GetWorkplace(result.data.emp_data.emp_id);
      }
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if ((receive_status === 3 || receive_status === 5) && this.state.fw_data.filter((e) => e.status_complete_info).length === this.state.fw_data.length) {
      receive_status = 7;
    }
    if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 9;
    } else if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length >= 1) {
      receive_status = 8;
    }
    if (receive_status === 8 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 9;
    }
    if (receive_status === 11 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0) {
      receive_status = 10;
    }
    if (receive_status === 11 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 12;
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveQC/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="รายการรอตรวจสอบ ต่อใบอนุญาตทำงาน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการรอตรวจสอบ ต่อใบอนุญาตทำงาน</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่รับเรื่อง</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-center text-white">ดำเนินการเรื่อง</th>
                      <th className="text-center text-white">ผู้บันทึกข้อมูล</th>
                      <th className="text-center text-white">สถานะ</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.receive_date ? format_date(item.receive_date) : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.full_name_th : "-"}</td>
                          <td className="text-left">{item.menu_data ? item.menu_data.menu_name : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left"> {this.Status(item)}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  window.location.href = "/RecieveMOU/update?id=" + item.receive_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.getById(item.receive_id);
                                  switch (item.receive_status) {
                                    case 2:
                                      this.setState({ modal_match2: true });
                                      break; // มอบอำนาจ Matching
                                    case 4:
                                      this.setState({ modal_match4: true });
                                      break; // Machting ไม่ได้
                                    case 7:
                                      this.setState({ modal_match7: true });
                                      break; // รอตรวจสอบ บต.46+มอบอำนาจ
                                    case 8:
                                      this.setState({ modal_match7: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46
                                    case 11:
                                      this.setState({ modal_match7: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย
                                    default:
                                      break;
                                  }
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf044"}</label>
                              </div>
                              {item.receive_status === 2 && (
                                <div
                                  className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-100"
                                  onClick={() => {
                                    this.setState({ modal_delete: false });
                                  }}
                                >
                                  ใบมอบอำนาจ
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    {" "}
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* status 2 มอบอำนาจ Matching */}
        <Modal
          show={this.state.modal_match2}
          onHide={() => {
            this.setState({ modal_match2: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>Matching</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>
              เลขประจำตัวนายจ้าง <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({
                    receive_status: 3,
                    r_username: "",
                    r_password: "",
                  });
                }}
                checked={this.state.receive_status === 3}
              />
              ใช้ User ของนายจ้าง
            </div>
            {this.state.receive_status === 3 && (
              <div className="row">
                <div className="col-6">
                  <span className="wpx-150">User</span>
                  <input
                    className="form-control mt-1"
                    type="text"
                    onChange={(e) => {
                      this.setState({ r_username: e.target.value });
                    }}
                    value={this.state.r_username}
                  />
                </div>
                <div className="col-6">
                  <span className="wpx-150">Password</span>
                  <input
                    className="form-control mt-1"
                    type="password"
                    onChange={(e) => {
                      this.setState({ r_password: e.target.value });
                    }}
                    value={this.state.r_password}
                  />
                </div>
              </div>
            )}
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 2 });
                }}
                checked={this.state.receive_status === 2 || this.state.receive_status === 5}
              />
              มอบอำนาจ Matching
            </div>
            {(this.state.receive_status === 2 || this.state.receive_status === 5) && (
              <div className="d-flex align-items-center ps-4">
                <input
                  className="mt-1 me-3"
                  type="checkbox"
                  name="receive_status"
                  onChange={(e) => {
                    this.setState({
                      receive_status: this.state.receive_status === 5 ? 2 : 5,
                    });
                  }}
                  checked={this.state.receive_status === 5}
                />
                Matching สำเร็จ
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match2: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* status 4 Machting ไม่ได้  */}
        <Modal
          show={this.state.modal_match4}
          onHide={() => {
            this.setState({ modal_match4: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>Matching</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>
              เลขประจำตัวนายจ้าง <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({
                    receive_status: 3,
                    r_username: "",
                    r_password: "",
                  });
                }}
                checked={this.state.receive_status === 3}
              />
              ใช้ User ของนายจ้าง
            </div>
            {this.state.receive_status === 3 && (
              <div className="row">
                <div className="col-6">
                  <span className="wpx-150">User</span>
                  <input
                    className="form-control mt-1"
                    type="text"
                    onChange={(e) => {
                      this.setState({ r_username: e.target.value });
                    }}
                    value={this.state.r_username}
                  />
                </div>
                <div className="col-6">
                  <span className="wpx-150">Password</span>
                  <input
                    className="form-control mt-1"
                    type="password"
                    onChange={(e) => {
                      this.setState({ r_password: e.target.value });
                    }}
                    value={this.state.r_password}
                  />
                </div>
              </div>
            )}
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 2 });
                }}
                checked={this.state.receive_status === 2}
              />
              มอบอำนาจ Matching
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match4: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* status 7 รอตรวจสอบ บต.46+มอบอำนาจ   */}
        {/* สถานะงาน */}
        <Modal
          show={this.state.modal_match7}
          onHide={() => {
            this.setState({ modal_match7: false });
          }}
          size="lg"
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>สถานะงาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0].sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0].district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0].province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {/* ผู้มีอำนาจผูกพัน */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน</b></div>
                                                        {this.state.emp_data.binding_authority_th ? this.state.emp_data.binding_authority_th : "-"}
                                                    </div> */}
                          {/* ตำแหน่ง */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ตำแหน่ง</b></div>
                                                        {this.state.emp_data.position_th ? this.state.emp_data.position_th : "-"}
                                                    </div> */}
                          {/* ผู้มีอำนาจผูกพัน (English) */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ผู้มีอำนาจผูกพัน (English)</b></div>
                                                        {this.state.emp_data.binding_authority_en ? this.state.emp_data.binding_authority_en : "-"}
                                                    </div> */}
                          {/* ตำแหน่ง (English) */}
                          {/* <div className="mb-3 d-flex col-6">
                                                        <div className="wpx-220"><b>ตำแหน่ง (English)</b></div>
                                                        {this.state.emp_data.position_en ? this.state.emp_data.position_en : "-"}
                                                    </div> */}
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* Matching */}
              {this.state.receive_status >= 5 && (
                <div>
                  <div
                    className={this.state.show_tab === 5 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 5 ? 0 : 5,
                      });
                    }}
                  >
                    {this.state.receive_date_s5 ? format_date(this.state.receive_date_s5, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s5} Matching
                  </div>
                  {this.state.show_tab === 5 && (
                    <div className="card-body">
                      <b>
                        Matching วันที่ {this.state.receive_date_s5 ? format_date(this.state.receive_date_s5, "dd/mm/yyyy") : ""} {this.state.receive_by_s5}
                      </b>
                    </div>
                  )}
                </div>
              )}
              {/* status 6 บันทึกลูกจ้างสำเร็จ */}
              {this.state.receive_status >= 6 && (
                <div>
                  <div
                    className={this.state.show_tab === 6 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 6 ? 0 : 6,
                      });
                    }}
                  >
                    {this.state.receive_date_s6 ? format_date(this.state.receive_date_s6, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s6} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 6 && (
                    <div className="card-body">
                      <b>
                        บันทึกลูกจ้างสำเร็จ วันที่ {this.state.receive_date_s6 ? format_date(this.state.receive_date_s6, "dd/mm/yyyy") : ""} {this.state.receive_by_s6}
                      </b>
                    </div>
                  )}
                </div>
              )}
              {/* status 7 แนบเอกสาร บต.46 + ใบมอบอำนาจแล้ว  */}
              {this.state.receive_status >= 7 && (
                <div>
                  <div
                    className={this.state.show_tab === 7 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 7 ? 0 : 7,
                      });
                    }}
                  >
                    {this.state.receive_date_s7 ? format_date(this.state.receive_date_s7, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s7} ตรวจสอบ บต.46+มอบอำนาจ
                  </div>
                  {this.state.show_tab === 7 && (
                    <div className="card-body">
                      {/* <table className="table table-borderless table-striped">
                                                <thead className="bg-primary">
                                                    <tr>
                                                        <th className="text-center text-white">ลำดับ</th>
                                                        <th className="text-left text-white">ชื่อ-นามสกุล</th>
                                                        <th className="text-left text-white">เลขบัตรประจำตัว</th>
                                                        <th className="text-left text-white">บต.46</th>
                                                        <th className="text-left text-white">ใบมอบอำนาจ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.fw_data && this.state.fw_data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center align-middle">{index + 1}</td>
                                                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                                            <td className="text-center align-middle">
                                                                {item.bt_46_document && (
                                                                    <a target="_blank" download href={(item.bt_46_document && item.bt_46_document.includes("://")) ? item.bt_46_document : "data:application/pdf;base64," + item.bt_46_document}>บต.46.pdf</a>
                                                                )}
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {item.power_attorney_document && (
                                                                    <a target="_blank" download href={(item.power_attorney_document && item.power_attorney_document.includes("://")) ? item.power_attorney_document : "data:application/pdf;base64," + item.power_attorney_document}>ใบมอบอำนาจ.pdf</a>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table> */}
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_date_s9 ? format_date(this.state.receive_date_s9, "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_by_s9}
                        </label>{" "}
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* status 10 ส่งเอกสาร บต.46+มอบอำนาจ แล้ว  */}
              {this.state.receive_status >= 10 && this.state.receive_status !== 15 && (
                <div>
                  <div
                    className={this.state.show_tab === 10 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 10 ? 0 : 10,
                      });
                    }}
                  >
                    {this.state.receive_date_s10 ? format_date(this.state.receive_date_s10, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s10} การจัดส่งเอกสาร บต.46 + มอบอำนาจ แล้ว
                  </div>
                  {this.state.show_tab === 10 && (
                    <div className="card-body">
                      <b className="mb-3">การจัดส่งเอกสาร</b>
                      <br />
                      <label>
                        {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_by_s10}
                      </label>
                      {this.state.documents_s9 && (
                        <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* status 11 รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย */}
              {this.state.receive_status >= 11 && this.state.receive_status !== 15 && (
                <div>
                  <div
                    className={this.state.show_tab === 11 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 11 ? 0 : 11,
                      });
                    }}
                  >
                    {this.state.receive_date_s11 ? format_date(this.state.receive_date_s11, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s11} เอกสาร บต.46 + ใบมอบอำนาจ ที่ลูกค้าเซ็นแล้วเข้าสู่ระบบ
                  </div>
                  {this.state.show_tab === 11 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">บต.46</th>
                            <th className="text-left text-white">ใบมอบอำนาจ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.af_bt_46_document && (
                                    <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                      บต.46.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.af_power_attorney_document && (
                                    <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                                      ใบมอบอำนาจ.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 15 แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน */}
              {/* {this.state.receive_status >= 15 && (
                                <div>
                                    <div
                                        className={this.state.show_tab === 15 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                                        onClick={() => { this.setState({ show_tab: this.state.show_tab === 15 ? 0 : 15 }) }}>
                                        {this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s15} ดาวน์โหลด บต.50 + ใบเสร็จรับเงินแล้ว
                                    </div>
                                    {this.state.show_tab === 15 && (
                                        <div className="card-body">
                                            <table className="table table-borderless table-striped">
                                                <thead className="bg-primary">
                                                    <tr>
                                                        <th className="text-center text-white">ลำดับ</th>
                                                        <th className="text-left text-white">ชื่อ-นามสกุล</th>
                                                        <th className="text-left text-white">เลขบัตรประจำตัว</th>
                                                        <th className="text-left text-white">เลข WP</th>
                                                        <th className="text-left text-white">บต.50</th>
                                                        <th className="text-left text-white">ใบเสร็จรับเงิน</th>
                                                        <th className="text-left text-white">ดาวน์โหลดแล้ว</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.fw_data && this.state.fw_data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center align-middle">{index + 1}</td>
                                                            <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                                            <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                                            <td className="text-left align-middle">{item.wp_number ? item.fw_number : "-"}</td>
                                                            <td className="text-center align-middle">
                                                                {item.bt_50_document && (
                                                                    <a target="_blank" download href={(item.bt_50_document && item.bt_50_document.includes("://")) ? item.bt_50_document : "data:application/pdf;base64," + item.bt_50_document}>บต.50.pdf</a>
                                                                )}
                                                                <br />
                                                                <label>{this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}</label>
                                                            </td>
                                                            <td className="text-center align-middle">
                                                                {item.fee_document && (
                                                                    <a target="_blank" download href={(item.fee_document && item.fee_document.includes("://")) ? item.fee_document : "data:application/pdf;base64," + item.fee_document}>ใบเสร็จรับเงิน.pdf</a>
                                                                )} <br />
                                                                <label>{this.state.receive_date_s15 ? format_date(this.state.receive_date_s15, "dd/mm/yyyy") : ""} , {this.state.receive_by_s15}</label>
                                                            </td>
                                                            <td className="text-left align-middle">
                                                                <div className="d-flex align-items-center ps-4">
                                                                    <input
                                                                        className="mt-1 me-3"
                                                                        type="checkbox"
                                                                        disabled
                                                                        checked={item.is_download_s14 === true}
                                                                    />
                                                                    ดาวน์โหลดแล้ว
                                                                </div></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            )} */}
              {/* status 16 จัดส่งเอกสารใบเสร็จ  */}
              {this.state.receive_status >= 16 && (
                <div>
                  <div
                    className={this.state.show_tab === 16 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 16 ? 0 : 16,
                      });
                    }}
                  >
                    {this.state.receive_date_s16 ? format_date(this.state.receive_date_s16, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s16} เอกสารใบเสร็จ + ทะเบียนใบอนุญาตทำงาน
                  </div>
                  {this.state.show_tab === 16 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">ใบเสร็จรับเงิน</th>
                            <th className="text-left text-white">ทะเบียนใบอนุญาตทำงาน</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.receipt_document && (
                                    <a target="_blank" download href={item.receipt_document && item.receipt_document.includes("://") ? item.receipt_document : "data:application/pdf;base64," + item.receipt_document}>
                                      ใบเสร็จรับเงิน.pdf
                                    </a>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.work_permit_document && (
                                    <a target="_blank" download href={item.work_permit_document && item.work_permit_document.includes("://") ? item.work_permit_document : "data:application/pdf;base64," + item.work_permit_document}>
                                      ทะเบียนใบอนุญาตทำงาน.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 17 ส่งใบเสร็จให้ลูกค้าแล้ว */}
              {this.state.receive_status >= 17 && (
                <div>
                  <div
                    className={this.state.show_tab === 17 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 17 ? 0 : 17,
                      });
                    }}
                  >
                    {this.state.receive_date_s17 ? format_date(this.state.receive_date_s17, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s17} ส่งเอกสารเอกสารใบเสร็จ + ทะเบียนใบอนุญาตทำงาน แล้ว
                  </div>
                  {this.state.show_tab === 17 && (
                    <div className="card-body">
                      <div className="d-flex mb-3">
                        <label className="wpx-150">ใบเสร็จ</label>
                        <label>
                          วันที่แนบไฟล์ {this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} ผู้แนบไฟล์ {this.state.receive_by_s17}
                        </label>
                      </div>
                      <b className="mb-3">การจัดส่งเอกสารใบเสร็จ</b>
                      <br />
                      <label>
                        {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_by_s17}
                      </label>
                      {this.state.documents_s16 && (
                        <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                          เอกสารแนบ.pdf
                        </a>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* status 19 รับ eWork แล้ว รอจัดส่ง   */}
              {(this.state.receive_status >= 19 || (this.state.receive_status === 18 && this.state.fw_data.filter((e) => !e.e_work_card_issued).length === 0)) && (
                <div>
                  {this.state.receive_status === 18 ? (
                    <div
                      className={this.state.show_tab === 19 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                      onClick={() => {
                        this.setState({
                          show_tab: this.state.show_tab === 19 ? 0 : 19,
                        });
                      }}
                    >
                      {this.state.receive_date_s18 ? format_date(this.state.receive_date_s18, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s18} ออก eWork แล้ว
                    </div>
                  ) : (
                    <div
                      className={this.state.show_tab === 19 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                      onClick={() => {
                        this.setState({
                          show_tab: this.state.show_tab === 19 ? 0 : 19,
                        });
                      }}
                    >
                      {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} ออก eWork แล้ว
                    </div>
                  )}
                  {this.state.show_tab === 19 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">eWork</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  <div className="d-flex align-items-center ps-4">
                                    <input className="mt-1 me-3" type="checkbox" checked={item.e_work_card_issued === true} disabled />
                                    ออกบัตรแล้ว
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 19 รับ eWork แล้ว รอจัดส่ง   */}
              {this.state.receive_status >= 19 && (
                <div>
                  <div
                    className={this.state.show_tab === 21 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 21 ? 0 : 21,
                      });
                    }}
                  >
                    {this.state.receive_date_s19 ? format_date(this.state.receive_date_s19, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s19} รับ eWork แล้ว
                  </div>
                  {this.state.show_tab === 21 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">เลข WP</th>
                            <th className="text-left text-white">รับ eWork</th>
                            <th className="text-left text-white">บัตร eWork</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">{item.wp_number ? item.wp_number : "-"}</td>
                                <td className="text-left align-middle">
                                  <div className="d-flex align-items-center ps-4">
                                    <input className="mt-1 me-3" type="checkbox" checked={item.e_work_card_received === true} disabled />
                                    รับบัตรแล้ว
                                  </div>
                                </td>
                                <td className="text-left align-middle">
                                  {item.e_received_document && (
                                    <a className="mx-3" target="_blank" download href={item.e_received_document && item.e_received_document.includes("://") ? item.e_received_document : "data:application/pdf;base64," + item.e_received_document}>
                                      เอกสารแนบ.pdf
                                    </a>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {/* status 20 ส่ง eWork แล้ว  */}
              {this.state.receive_status >= 20 && (
                <div>
                  <div
                    className={this.state.show_tab === 20 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 20 ? 0 : 20,
                      });
                    }}
                  >
                    {this.state.receive_date_s20 ? format_date(this.state.receive_date_s20, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s20} การจัดส่งเอกสาร eWork
                  </div>
                  {this.state.show_tab === 20 && (
                    <div className="card-body">
                      <b className="mb-3">การจัดส่งเอกสาร eWork</b>
                      <br />
                      <label>
                        {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_by_s20}
                      </label>
                      {this.state.documents_s19 && (
                        <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* status 21 ปิดงาน  */}
              {this.state.receive_status >= 21 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_date_s21 ? format_date(this.state.receive_date_s21, "dd/mm/yyyy hh:mm") : ""} {this.state.receive_by_s21} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 6 บันทึกลูกจ้างสำเร็จ */}
            {(this.state.receive_status === 6 || this.state.receive_status === 7 || this.state.receive_status === 8) && (
              <div>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ตรวจสอบ บต.46</b>
                  </h6>
                  <div
                    className="pointer bg-danger-light rounded text-primary px-2 py-1 mx-2"
                    onClick={() => {
                      document_bt46_ENTH(
                        this.state.fw_data.map(e=>{return {...e,prefix_th:"",first_name_th:"",middle_name_th:"",last_name_th:""}}),
                        this.state.emp_data,
                        this.state.data_workplace,
                        this.state.dpz_data,
                        this.state.data_salary,
                        {
                          witness_contract_1: this.state.witness_contract_1,
                          witness_contract_2_th: this.state.witness_contract_2_th,
                          witness_contract_2_en: this.state.witness_contract_2_en,
                        },
                        this.state.data_prefix_th,
                        this.state.data_prefix_en,
                        this.state.data_work_type,
                        this.state.data_business_type
                      );
                    }}
                  >
                    พิมพ์ บต.46 (EN)
                  </div>
                  <div
                    className="pointer bg-danger-light rounded text-primary px-2 py-1 mx-2"
                    onClick={() => {
                      document_bt46_ENTH(
                        this.state.fw_data,
                        this.state.emp_data,
                        this.state.data_workplace,
                        this.state.dpz_data,
                        this.state.data_salary,
                        {
                          witness_contract_1: this.state.witness_contract_1,
                          witness_contract_2_th: this.state.witness_contract_2_th,
                          witness_contract_2_en: this.state.witness_contract_2_en,
                        },
                        this.state.data_prefix_th,
                        this.state.data_prefix_en,
                        this.state.data_work_type,
                        this.state.data_business_type
                      );
                    }}
                  >
                    พิมพ์ บต.46 (EN+TH)
                  </div>                  <div
                    className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-140 mx-2"
                    onClick={() => {
                      document_contact(
                        this.state.fw_data,
                        this.state.emp_data,
                        this.state.data_workplace,
                        this.state.dpz_data,
                        {
                          witness_power_1: this.state.witness_power_1,
                          witness_power_2_th: this.state.witness_power_2_th,
                          witness_power_2_en: this.state.witness_power_2_en,
                        },
                        this.state.data_prefix_th,
                        this.state.data_prefix_en
                      );
                    }}
                  >
                    พิมพ์ ใบมอบอำนาจ
                  </div>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">บต.46</th>
                      <th className="text-left text-white">
                        การตรวจสอบเอกสาร
                        <div className="d-flex align-items-center mt-2">
                          <input
                            className="mt-1 me-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ถูกต้อง
                          <input
                            className="mt-1 mx-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ไม่ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ไม่ถูกต้อง
                        </div>
                      </th>
                      <th className="text-left text-white">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            <div
                              className="pointer bg-danger-light rounded text-primary px-2 py-1 text-center mb-2"
                              onClick={() => {
                                document_bt46_ENTH(
                                  [item].map(e=>{return {...e,prefix_th:"",first_name_th:"",middle_name_th:"",last_name_th:""}}),
                                  this.state.emp_data,
                                  this.state.data_workplace,
                                  this.state.dpz_data,
                                  this.state.data_salary,
                                  {
                                    witness_contract_1: this.state.witness_contract_1,
                                    witness_contract_2_th: this.state.witness_contract_2_th,
                                    witness_contract_2_en: this.state.witness_contract_2_en,
                                  },
                                  this.state.data_prefix_th,
                                  this.state.data_prefix_en,
                                  this.state.data_work_type,
                                  this.state.data_business_type
                                );
                              }}
                            >
                              พิมพ์ บต.46 (EN)
                            </div>
                            <div
                              className="pointer bg-danger-light rounded text-primary px-2 py-1 text-center"
                              onClick={() => {
                                document_bt46_ENTH(
                                  [item],
                                  this.state.emp_data,
                                  this.state.data_workplace,
                                  this.state.dpz_data,
                                  this.state.data_salary,
                                  {
                                    witness_contract_1: this.state.witness_contract_1,
                                    witness_contract_2_th: this.state.witness_contract_2_th,
                                    witness_contract_2_en: this.state.witness_contract_2_en,
                                  },
                                  this.state.data_prefix_th,
                                  this.state.data_prefix_en,
                                  this.state.data_work_type,
                                  this.state.data_business_type
                                );
                              }}
                            >
                              พิมพ์ บต.46 (EN+TH)
                            </div>
                          </td>
                          <td className="text-left">
                            <div className="d-flex align-items-center mt-2">
                              <input
                                className="mt-1 me-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ถูกต้อง"}
                              />
                              ถูกต้อง
                              <input
                                className="mt-1 mx-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ไม่ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ไม่ถูกต้อง"}
                              />
                              ไม่ถูกต้อง
                            </div>
                          </td>
                          <td className="text-left">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].remark_document = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.remark_document}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {this.state.receive_status === 11 && (
              <div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>ตรวจสอบ บต.46 ครั้งสุดท้าย</b>
                  </h6>
                  {this.state.fw_data && this.state.fw_data[0].af_power_attorney_document && (
                    <a target="_blank" download href={this.state.fw_data[0].af_power_attorney_document && this.state.fw_data[0].af_power_attorney_document.includes("://") ? this.state.fw_data[0].af_power_attorney_document : "data:application/pdf;base64," + this.state.fw_data[0].af_power_attorney_document}>
                      ใบมอบอำนาจ.pdf
                    </a>
                  )}
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">บต.46</th>
                      {/* <th className="text-left text-white">สัญญาจ้าง</th> */}
                      <th className="text-left text-white">
                        การตรวจสอบเอกสาร
                        <div className="d-flex align-items-center mt-2">
                          <input
                            className="mt-1 me-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ถูกต้อง
                          <input
                            className="mt-1 mx-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ไม่ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ไม่ถูกต้อง
                        </div>
                      </th>
                      <th className="text-left text-white">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.af_bt_46_document && (
                              <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                                บต.46.pdf
                              </a>
                            )}
                          </td>
                          {/* <td className="text-left align-middle">
                            {item.af_contract_document && (
                              <a target="_blank" download href={item.af_contract_document && item.af_contract_document.includes("://") ? item.af_contract_document : "data:application/pdf;base64," + item.af_contract_document}>
                                สัญญาจ้าง.pdf
                              </a>
                            )}
                          </td> */}
                          <td className="text-left">
                            <div className="d-flex align-items-center mt-2">
                              <input
                                className="mt-1 me-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ถูกต้อง"}
                              />
                              ถูกต้อง
                              <input
                                className="mt-1 mx-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ไม่ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ไม่ถูกต้อง"}
                              />
                              ไม่ถูกต้อง
                            </div>
                          </td>
                          <td className="text-left">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].remark_document = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.remark_document}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match7: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.fw_data.filter((e) => e.status_document === null).length !== 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาตรวจสอบเอกสารให้ครบ");
                      return;
                    }
                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

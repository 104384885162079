import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../assets/images/logo.png";
import Switch from "react-switch";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
  convert_imagetopdf,
} from "../../components/CustomComponent.js";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal_detail: false,
      modal_delete: false,

      show: false,

      data_search: "",

      data_menu_work: [],
      data_business_type: [],

      quotation_id: "",

      country_qty: "",
      receive_id: "",
      emp_id: 0,
      emp_code: "",
      emp_user_type: "",
      emp_type: "",
      emp_number: "",
      register_capital: "",
      register_date: "",
      id_card: "",
      place_issue: "",
      issue_date: "",
      expiry_date: "",
      hours_working_day: "",
      working_day: "",
      number_holidays: "",
      number_holidays_day: "",
      take_annual_leave: "",
      contact_name: "",
      prefix_th: "",
      prefix_en: "",
      work_type: "",
      business_type: "",
      business_description: "",
      full_name_th: "",
      full_name_en: "",
      address_number: "",
      village_name: "",
      floor: "",
      room_no: "",
      building_name: "",
      moo: "",
      soi: "",
      street: "",
      email: "",
      phone: "",
      fax: "",
      mobile_phone: "",
      home_number: "",
      phone_2: "",
      fax_2: "",
      binding_authority_th: "",
      binding_authority_en: "",
      position_th: "",
      position_en: "",
      operator_type: "",
      deputize_number: "",
      deputize_full_name: "",
      username: "",
      copy_idcard_image: "",
      copy_idcard_image_date: "",
      dpz_data: "",
      pw_data: "",
      data_workplace: [],
      coordinator_data: "",
      data_deputize: [],
      attachment_file: "",
      province: "",
      district: "",
      sub_district: "",
      zipcode: "",
      password: "",

      process_matter: "",
      cambodia_qty: "",
      laos_qty: "",
      myanmar_qty: "",
      vietnam_qty: "",
      total_qty: "",
      remark: "",

      // ไฟล์แนบ
      copy_company_certificate: "",
      copy_company_certificate_date: "",
      copy_power_attorney: "",
      copy_power_attorney_date: "",
      copy_tax_certificate: "",
      copy_tax_certificate_date: "",

      data_prefix_th: [],
      data_prefix_en: [],
      data_work_type: [],
      data_rate: [],

      readOnly: false,

      rate_list: [],
      installments_count: 0,
      installments: [],
      holder: 0,
      is_vat: false,
      payment_person: ""
    };
  }
  async componentDidMount() {
    let user = await localStorage.getItem("user")
    user = user ? JSON.parse(user):null;
    if(user){
      if(user.user_type === "super_user" || user.user_type === "admin" || user.user_type === "owner"){ this.setState({holder:1}) } // admin
      else if(user.user_type === "sales"){ this.setState({holder:2}) } // ฝ่ายขาย
      else if(user.user_type === "agent"){ this.setState({holder:3}) } // ตัวแทน  
    }
    await this.GetBusinessType();
    await this.GetMenuWork();
    await this.GetPrefix();
    await this.GetWorkType();
    await this.GetRateAll();
    if (this.props.location.query.id) {
      this.Get(this.props.location.query.id);
      this.setState({ readOnly: true });
    }
  }
  Get = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      quotation_id: id,
      holder: 1
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/0", body);
    if (result && result.status) { 
      if (result.data.emp_data) {
        this.setState({
          data_search: result.data.emp_data.emp_number,
          receive_id: result.data.receive_id,
          emp_id: result.data.emp_data.id,
          emp_code: result.data.emp_data.emp_code,
          emp_user_type: result.data.emp_data.emp_user_type,
          emp_type: result.data.emp_data.emp_type,
          emp_number: result.data.emp_data.emp_number,
          register_capital: result.data.emp_data.register_capital,
          register_date: result.data.emp_data.register_date,
          id_card: result.data.emp_data.id_card,
          place_issue: result.data.emp_data.place_issue,
          issue_date: result.data.emp_data.issue_date,
          expiry_date: result.data.emp_data.expiry_date,
          hours_working_day: result.data.emp_data.hours_working_day,
          number_holidays: result.data.emp_data.number_holidays,
          working_day: result.data.emp_data.working_day,
          number_holidays_day: result.data.emp_data.number_holidays_day,
          take_annual_leave: result.data.emp_data.take_annual_leave,
          contact_name: result.data.emp_data.contact_name,
          prefix_th: this.state.data_prefix_th.filter((e) => Number(e.value) === result.data.emp_data.prefix_th)[0],
          prefix_en: this.state.data_prefix_en.filter((e) => Number(e.value) === result.data.emp_data.prefix_en)[0],
          work_type: this.state.data_work_type.filter((e) => Number(e.value) === result.data.emp_data.work_type)[0],
          business_type: this.state.data_business_type.filter((e) => Number(e.value) === result.data.emp_data.business_type)[0],
          business_description: result.data.emp_data.business_description,
          full_name_th: result.data.emp_data.full_name_th,
          full_name_en: result.data.emp_data.full_name_en,
          address_number: result.data.emp_data.address_number,
          village_name: result.data.emp_data.village_name,
          floor: result.data.emp_data.floor,
          room_no: result.data.emp_data.room_no,
          building_name: result.data.emp_data.building_name,
          moo: result.data.emp_data.moo,
          soi: result.data.emp_data.soi,
          street: result.data.emp_data.street,
          email: result.data.emp_data.email,
          phone: result.data.emp_data.phone,
          fax: result.data.emp_data.fax,
          mobile_phone: result.data.emp_data.mobile_phone,
          home_number: result.data.emp_data.home_number,
          phone_2: result.data.emp_data.phone_2,
          fax_2: result.data.emp_data.fax_2,
          binding_authority_th: result.data.emp_data.binding_authority_th,
          binding_authority_en: result.data.emp_data.binding_authority_en,
          position_th: result.data.emp_data.position_th,
          position_en: result.data.emp_data.position_en,
          operator_type: result.data.emp_data.operator_type,
          deputize_number: result.data.emp_data.deputize_number,
          deputize_full_name: result.data.emp_data.deputize_full_name,
          username: result.data.emp_data.username,
          copy_idcard_image: result.data.emp_data.copy_idcard_image,
          copy_idcard_image_date: result.data.emp_data.copy_idcard_image_date,
          dpz_data: result.data.emp_data.dpz_data,
          pw_data: result.data.emp_data.pw_data,
          data_workplace: result.data.emp_data.pw_data,
          coordinator_data: result.data.emp_data.coordinator_data,
          data_deputize: result.emp_data.dpz_data ? result.emp_data.dpz_data : [],
          province: result.data.emp_data.province
            ? province
                .filter((item) => item.province_name === result.data.emp_data.province)
                .map((item) => {
                  return { value: item.province_name, label: item.province_name };
                })[0]
            : "",
          district: result.data.emp_data.district
            ? district
                .filter((item) => item.district_name === result.data.emp_data.district)
                .map((item) => {
                  return { value: item.district_name, label: item.district_name };
                })[0]
            : "",
          sub_district: result.data.emp_data.sub_district
            ? subdistrict
                .filter((item) => item.sub_district_name === result.data.emp_data.sub_district)
                .map((item) => {
                  return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
                })[0]
            : "",
          zipcode: result.data.emp_data.sub_district
            ? subdistrict
                .filter((item) => item.sub_district_name === result.data.emp_data.sub_district)
                .map((item) => {
                  return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
                })[0].zipcode
            : "",
        });
      } 
      this.setState({ 
        quotation_id: id,
        payment_person: result.data.payment_person,
        process_matter: this.state.data_menu_work.filter((e) => e.value === result.data.menu_id)[0],
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.cambodia_qty + result.data.laos_qty + result.data.myanmar_qty + result.data.vietnam_qty,
        country_qty:result.data.cambodia_qty?"กัมพูชา":result.data.laos_qty?"ลาว":result.data.myanmar_qty?"เมียนมา":result.data.vietnam_qty?"เวียดนาม":"",
        rate_list:result.data.rate_list.map(e=>{
          return {
            qty_price:e.qty_price,
            qty:e.qty,
            discount:e.discount,
            discount_percent:e.discount_percent,
            vat: e.vat,
            remark:e.remark,
            service_type:e.service_type,
            total_price: e.service_type === "รายหัว"?(e.qty_price*e.qty)-((e.qty_price*e.qty)*(e.discount_percent/100)):e.vat+(e.qty_price)-((e.vat+e.qty_price)*(e.discount_percent/100))
          }
        }),
        installments: result.data.installment,
        installments_count: result.data.installment.length,
        is_vat: result.data.is_vat
      });
    }
    this.setState({ loading: false });
  };
  GetRateAll = async () => { 
    let body = {
      data_search: "",
      rate_id: "",
    };
    let result = await GET(tokens, "api/setting/service-rate/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_rate: result.data 
      });
    } 
  }; 
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetMenuWork = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: [],
      menu_id: "",
    };
    let result = await GET(tokens, "api/receive-matter-menu/list/page/0" , body);
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data.map((e) => {
          return {
            value: e.menu_id,
            label: e.menu_name,
          };
        }),
      }); 
    }
    this.setState({ loading: false });
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetUser = async () => {
    if (this.state.data_search === "") {
      alert("warning", "แจ้งเตือน", "กรุณากรอกเลขประจำตัวนายจ้าง");
      return;
    }
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      emp_id: "",
    };
    let result = await GET(tokens, this.state.type_search === "กรมพัฒนาธุรกิจการค้า" ? "api/employer/department-trade" : "api/employer/list/page/0", body);
    if (result?.status) { 
      this.setState(prevState =>({
        emp_id: result.data[0].id,
        emp_code: result.data[0].emp_code,
        emp_user_type: result.data[0].emp_user_type,
        emp_type: result.data[0].emp_type,
        emp_number: result.data[0].emp_number,
        register_capital: result.data[0].register_capital,
        register_date: result.data[0].register_date,
        id_card: result.data[0].id_card,
        place_issue: result.data[0].place_issue,
        issue_date: result.data[0].issue_date,
        expiry_date: result.data[0].expiry_date,
        hours_working_day: result.data[0].hours_working_day,
        number_holidays: result.data[0].number_holidays,
        working_day: result.data[0].working_day,
        number_holidays_day: result.data[0].number_holidays_day,
        take_annual_leave: result.data[0].take_annual_leave,
        contact_name: result.data[0].contact_name,
        prefix_th: prevState.data_prefix_th.filter((e) => Number(e.value) === result.data[0].prefix_th)[0],
        prefix_en: prevState.data_prefix_en.filter((e) => Number(e.value) === result.data[0].prefix_en)[0],
        work_type: prevState.data_work_type.filter((e) => Number(e.value) === result.data[0].work_type)[0],
        business_type: prevState.data_business_type.filter((e) => Number(e.value) === result.data[0].business_type)[0],
        business_description: result.data[0].business_description,
        full_name_th: result.data[0].full_name_th,
        full_name_en: result.data[0].full_name_en,
        address_number: result.data[0].address_number,
        village_name: result.data[0].village_name,
        floor: result.data[0].floor,
        room_no: result.data[0].room_no,
        building_name: result.data[0].building_name,
        moo: result.data[0].moo,
        soi: result.data[0].soi,
        street: result.data[0].street,
        email: result.data[0].email,
        phone: result.data[0].phone,
        fax: result.data[0].fax,
        mobile_phone: result.data[0].mobile_phone,
        home_number: result.data[0].home_number,
        phone_2: result.data[0].phone_2,
        fax_2: result.data[0].fax_2,
        binding_authority_th: result.data[0].binding_authority_th,
        binding_authority_en: result.data[0].binding_authority_en,
        position_th: result.data[0].position_th,
        position_en: result.data[0].position_en,
        operator_type: result.data[0].operator_type,
        deputize_number: result.data[0].deputize_number,
        deputize_full_name: result.data[0].deputize_full_name,
        username: result.data[0].username,
        copy_idcard_image: result.data[0].copy_idcard_image,
        copy_idcard_image_date: result.data[0].copy_idcard_image_date,
        dpz_data: result.data[0].dpz_data,
        pw_data: result.data[0].pw_data,
        data_workplace: result.data[0].pw_data,
        coordinator_data: result.data[0].coordinator_data,
        data_deputize: result.data[0].dpz_data ? result.data[0].dpz_data : [],
        province: result.data[0].province
          ? province
              .filter((item) => item.province_name === result.data[0].province)
              .map((item) => {
                return { value: item.province_name, label: item.province_name };
              })[0]
          : "",
        district: result.data[0].district
          ? district
              .filter((item) => item.district_name === result.data[0].district)
              .map((item) => {
                return { value: item.district_name, label: item.district_name };
              })[0]
          : "",
        sub_district: result.data[0].sub_district
          ? subdistrict
              .filter((item) => item.sub_district_name === result.data[0].sub_district)
              .map((item) => {
                return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
              })[0]
          : "",
        zipcode: result.data[0].sub_district
          ? subdistrict
              .filter((item) => item.sub_district_name === result.data[0].sub_district)
              .map((item) => {
                return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
              })[0].zipcode
          : "",
      }))
    }
    this.setState({ loading: false });
  };
  Create = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id?this.state.emp_id:0, // ถ้าส่ง 0 มา จะเป็นการสร้างข้อมูลนายจ้าง แต่ถ้าค้นหาแล้วส่งเป็น id นายจ้างมาจะไม่สร้างใหม่
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : null,
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : null,
      work_type: this.state.work_type ? Number(this.state.work_type.value) : null,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : null,
      business_description: this.state.business_description,
      register_capital: this.state.register_capital,
      register_date: this.state.register_date,
      id_card: this.state.id_card,
      place_issue: this.state.place_issue,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      hours_working_day: this.state.hours_working_day,
      number_holidays: this.state.number_holidays,
      working_day: this.state.working_day,
      number_holidays_day: this.state.number_holidays_day,
      take_annual_leave: this.state.take_annual_leave,
      contact_name: this.state.contact_name,
      full_name_th: this.state.full_name_th,
      full_name_en: this.state.full_name_en,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      email: this.state.email,
      phone: this.state.phone,
      fax: this.state.fax,
      mobile_phone: this.state.mobile_phone,
      home_number: this.state.home_number,
      phone_2: this.state.phone_2,
      fax_2: this.state.fax_2,
      binding_authority_th: this.state.binding_authority_th,
      binding_authority_en: this.state.binding_authority_en,
      position_th: this.state.position_th,
      position_en: this.state.position_en,
      data_deputize: this.state.data_deputize,
      username: this.state.username,
      password: this.state.password,

      payment_person: this.state.payment_person,

      menu_id: this.state.process_matter ? this.state.process_matter.value : "",
      menu_name: this.state.process_matter ? this.state.process_matter.label : "",
      cambodia_qty: Number(this.state.cambodia_qty),
      laos_qty: Number(this.state.laos_qty),
      myanmar_qty: Number(this.state.myanmar_qty),
      vietnam_qty: Number(this.state.vietnam_qty),
      total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
      rate_list: this.state.rate_list.map((e) => {
        return {
          qty_price: Number(e.qty_price),
          qty: Number(e.qty),
          discount: Number(e.discount),
          discount_percent: Number(e.discount_percent),
          service_type: e.service_type,
          remark: e.remark,
          vat: e.vat ? Number(e.vat) : 0
        };
      }),
      installment: this.state.installments.length === 0 ? 
      [{
        amount: this.state.is_vat ? (total(this.state.rate_list, "total_price")* 1.07)  : (total(this.state.rate_list, "total_price")),
        percent: 100,
        remark: "",
        checked: false,
        paid: false,
      }]:
      this.state.installments.map((e) => {
        return {
          amount: Number(e.amount),
          percent: Number(e.percent),
          remark: e.remark,
          checked: e.checked,
          paid: e.paid,
        };
      }),
      holder:1,
      is_vat:this.state.is_vat
    }; 
    let result = await POST(tokens, "api/setting/quotation/create", body);
    if (result && result.status) {
      success_url("/Quotation/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      emp_id: this.state.emp_id?this.state.emp_id:0, // ถ้าส่ง 0 มา จะเป็นการสร้างข้อมูลนายจ้าง แต่ถ้าค้นหาแล้วส่งเป็น id นายจ้างมาจะไม่สร้างใหม่
      emp_user_type: this.state.emp_user_type,
      emp_type: this.state.emp_type,
      emp_number: this.state.emp_number,
      prefix_th: this.state.prefix_th ? Number(this.state.prefix_th.value) : null,
      prefix_en: this.state.prefix_en ? Number(this.state.prefix_en.value) : null,
      work_type: this.state.work_type ? Number(this.state.work_type.value) : null,
      business_type: this.state.business_type ? Number(this.state.business_type.value) : null,
      business_description: this.state.business_description,
      register_capital: this.state.register_capital,
      register_date: this.state.register_date,
      id_card: this.state.id_card,
      place_issue: this.state.place_issue,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      hours_working_day: this.state.hours_working_day,
      number_holidays: this.state.number_holidays,
      working_day: this.state.working_day,
      number_holidays_day: this.state.number_holidays_day,
      take_annual_leave: this.state.take_annual_leave,
      contact_name: this.state.contact_name,
      full_name_th: this.state.full_name_th,
      full_name_en: this.state.full_name_en,
      address_number: this.state.address_number,
      moo: this.state.moo,
      soi: this.state.soi,
      street: this.state.street,
      province: this.state.province ? this.state.province.value : "",
      district: this.state.district ? this.state.district.value : "",
      sub_district: this.state.sub_district ? this.state.sub_district.value : "",
      zipcode: this.state.zipcode,
      email: this.state.email,
      phone: this.state.phone,
      fax: this.state.fax,
      mobile_phone: this.state.mobile_phone,
      home_number: this.state.home_number,
      phone_2: this.state.phone_2,
      fax_2: this.state.fax_2,
      binding_authority_th: this.state.binding_authority_th,
      binding_authority_en: this.state.binding_authority_en,
      position_th: this.state.position_th,
      position_en: this.state.position_en,
      data_deputize: this.state.data_deputize,
      username: this.state.username,
      password: this.state.password,

      payment_person: this.state.payment_person,

      menu_id: this.state.process_matter ? this.state.process_matter.value : "",
      menu_name: this.state.process_matter ? this.state.process_matter.label : "",
      cambodia_qty: Number(this.state.cambodia_qty),
      laos_qty: Number(this.state.laos_qty),
      myanmar_qty: Number(this.state.myanmar_qty),
      vietnam_qty: Number(this.state.vietnam_qty),
      total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty),
      rate_list: this.state.rate_list.map((e) => {
        return {
          qty_price: Number(e.qty_price),
          qty: Number(e.qty),
          discount: Number(e.discount),
          discount_percent: Number(e.discount_percent),
          service_type: e.service_type,
          remark: e.remark,
          vat: e.vat ? Number(e.vat) : 0
        };
      }),
      installment: this.state.installments.length === 0 ? 
      [{
        amount: this.state.is_vat ? (total(this.state.rate_list, "total_price")* 1.07)  : (total(this.state.rate_list, "total_price")),
        percent: 100,
        remark: "",
        checked: false,
        paid: false,
      }]:this.state.installments.map((e) => {
        return {
          amount: Number(e.amount),
          percent: Number(e.percent),
          remark: e.remark,
          checked: e.checked,
          paid: e.paid,
        };
      }),
      is_vat:this.state.is_vat
    };
    let result = await PUT(tokens, "api/setting/quotation/update/" + this.state.quotation_id, body);
    if (result && result.status) {
      success_url("/Quotation/list");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        let files = null;
        if (file.type.includes("pdf")) {
          files = e.target.result.split(",")[1];
        }else{
          files = await convert_imagetopdf(e.target.result);
        }
        if (type === "copy_company_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_company_certificate = files;
          data_deputize[this.state.index].copy_company_certificate_date = new Date();
          data_deputize[this.state.index].copy_company_certificate_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_power_attorney_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_power_attorney = files;
          data_deputize[this.state.index].copy_power_attorney_date = new Date();
          data_deputize[this.state.index].copy_power_attorney_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_tax_certificate_array") {
          let data_deputize = this.state.data_deputize;
          data_deputize[this.state.index].copy_tax_certificate = files;
          data_deputize[this.state.index].copy_tax_certificate_date = new Date();
          data_deputize[this.state.index].copy_tax_certificate_type = "pdf";
          this.setState({ data_deputize: data_deputize });
        } else if (type === "copy_idcard_image") {
          this.setState({ copy_idcard_image: files, copy_idcard_type: "pdf" });
        }
      };
    
    if (type === "copy_company_certificate_array") {
      document.getElementById("file_copy_company_certificate_array").value = "";
      document.getElementById("file_copy_company_certificate_array").file = null;
    } else if (type === "copy_power_attorney_array") {
      document.getElementById("file_copy_power_attorney_array").value = "";
      document.getElementById("file_copy_power_attorney_array").file = null;
    } else if (type === "copy_tax_certificate_array") {
      document.getElementById("file_copy_tax_certificate_array").value = "";
      document.getElementById("file_copy_tax_certificate_array").file = null;
    } else if (type === "copy_idcard_image") {
      document.getElementById("file_copy_idcard_image").value = "";
      document.getElementById("file_copy_idcard_image").file = null;
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ใบเสนอราคา" page3={this.props.location.query.id ? "ใบเสนอราคา" : "สร้างใบเสนอราคา"} />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>{this.props.location.query.id ? "ใบเสนอราคา" : "สร้างใบเสนอราคา"}</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                {/* ข้อมูลนายจ้าง */}
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>ข้อมูลนายจ้าง</b>
                  </h5>
                  <div className="w-100">
                    <div className="w-100 card border-0 p-0">
                      <div className="d-flex align-items-center mx-0">
                        <span className="me-2">เลขทะเบียนนิติบุคคล</span>
                        <input
                          className="form-control wpx-250 bg-contain border-0 mx-1"
                          type="text"
                          placeholder="กรอกข้อมูล"
                          onChange={(e) => {
                            this.setState({ data_search: e.target.value });
                          }}
                          value={this.state.data_search}
                        />
                        <button
                          className="btn btn-primary mx-1 px-4"
                          onClick={() => {
                            this.GetUser();
                          }}
                        >
                          ค้นหา
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center mb-3">
                      <span className="wpx-150">ประเภท{required}</span>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 1, data_deputize: [], data_coordinator: [], register_date: "", register_capital: 0 });
                          }}
                          checked={this.state.emp_type === 1}
                        />
                        <span className="ms-2">บุคคลธรรมดา</span>
                      </div>
                      <div className="d-flex align-items-center wpx-120">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 2, copy_idcard_image: "", copy_idcard_type: "", data_coordinator: [], prefix_th: "", prefix_en: "" });
                          }}
                          checked={this.state.emp_type === 2}
                        />
                        <span className="ms-2">นิติบุคคล</span>
                      </div>
                      <div className="d-flex align-items-center wpx-150">
                        <input
                          type="radio"
                          name="type"
                          onChange={(e) => {
                            this.setState({ emp_type: 3, copy_idcard_image: "", copy_idcard_type: "", data_coordinator: [], prefix_th: "", prefix_en: "" });
                          }}
                          checked={this.state.emp_type === 3}
                        />
                        <span className="ms-2">นิติบุคคลต่างด้าว</span>
                      </div>
                    </div>
                    {this.state.emp_type === 1 && (
                      <div className="d-flex align-items-center">
                        <span className="wpx-150">เลขบัตรประชาชน{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขบัตรประชาชน"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div>
                    )}
                    {this.state.emp_type === 2 && (
                      <div className="d-flex align-items-center">
                        <span className="wpx-150">เลขนิติบุคคล{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขนิติบุคคล"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div>
                    )}
                    {this.state.emp_type === 3 && (
                      <div className="d-flex align-items-center">
                        <span className="wpx-150">เลขนิติบุคคลต่างด้าว{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="เลขนิติบุคคลต่างด้าว"
                          maxLength={13}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ emp_number: e.target.value });
                          }}
                          value={this.state.emp_number}
                        />
                      </div>
                    )}
                    <hr />
                    {/* ข้อมูล */}
                    <div className="row mt-3">
                      {this.state.emp_type === 1 && (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">คำนำหน้า(ไทย){required}</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_prefix_th}
                            onChange={(e) => {
                              this.setState({ prefix_th: e, prefix_en: e ? this.state.data_prefix_en.filter((i) => i.value === e.value)[0] : "" });
                            }}
                            value={this.state.prefix_th}
                          ></Select>
                        </div>
                      )}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อนายจ้าง/สถานประกอบการ{required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ full_name_th: e.target.value });
                          }}
                          value={this.state.full_name_th}
                        />
                      </div>
                      {this.state.emp_type === 1 && (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">คำนำหน้า(English)</span>
                          <Select
                            className="form-control select-search"
                            styles={select_style}
                            options={this.state.data_prefix_en}
                            onChange={(e) => {
                              this.setState({ prefix_en: e, prefix_th: e ? this.state.data_prefix_th.filter((i) => i.value === e.value)[0] : "" });
                            }}
                            value={this.state.prefix_en}
                          ></Select>
                        </div>
                      )}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อนายจ้าง/สถานประกอบการ EN</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ full_name_en: e.target.value });
                          }}
                          value={this.state.full_name_en}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ประเภทกิจการ</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_business_type}
                          onChange={(e) => {
                            this.setState({ business_type: e });
                          }}
                          value={this.state.business_type}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ประเภทงาน{required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={this.state.data_work_type}
                          onChange={(e) => {
                            this.setState({ work_type: e });
                          }}
                          value={this.state.work_type}
                        ></Select>
                      </div>
                      {/* <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ลักษณะงาน{required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ business_description: e.target.value });
                          }}
                          value={this.state.business_description}
                        />
                      </div> */}
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ที่ตั้งเลขที่{required}</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ address_number: e.target.value });
                          }}
                          value={this.state.address_number}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมู่ที่</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ moo: e.target.value });
                          }}
                          value={this.state.moo}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ซอย</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ soi: e.target.value });
                          }}
                          value={this.state.soi}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ถนน</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ street: e.target.value });
                          }}
                          value={this.state.street}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จังหวัด{required}</span>
                        <Select
                          className="form-control select-search"
                          styles={select_style}
                          options={province.map((item) => {
                            return { value: item.province_name, label: item.province_name };
                          })}
                          onChange={(e) => {
                            this.setState({ province: e, district: null, sub_district: null, zipcode: "" });
                          }}
                          value={this.state.province}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150"> อำเภอ / เขต{required}</span>
                        <Select
                          className={"form-control select-search" + (this.state.province ? "" : " bg-contain")}
                          styles={select_style}
                          options={district
                            .filter((item) => this.state.province && this.state.province.value === item.province_name)
                            .map((item) => {
                              return { value: item.district_name, label: item.district_name };
                            })}
                          onChange={(e) => {
                            this.setState({ district: e, sub_district: null, zipcode: "" });
                          }}
                          value={this.state.district}
                          isDisabled={!this.state.province}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ตำบล / แขวง{required}</span>
                        <Select
                          className={"form-control select-search" + (this.state.district ? "" : " bg-contain")}
                          styles={select_style}
                          options={subdistrict
                            .filter((item) => this.state.district && this.state.district.value === item.district_name && this.state.province&& this.state.province.value === item.province_name)
                            .map((item) => {
                              return { value: item.sub_district_name, label: item.sub_district_name, zipcode: item.zip_code };
                            })}
                          onChange={(e) => {
                            this.setState({ sub_district: e, zipcode: e.zipcode.toString() });
                          }}
                          value={this.state.sub_district}
                          isDisabled={!this.state.district}
                        ></Select>
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">รหัสไปรษณีย์{required}</span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="รหัสไปรษณีย์"
                          onChange={(e) => {
                            this.setState({ zipcode: e.target.value });
                          }}
                          value={this.state.zipcode}
                          disabled
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">โทรศัพท์ที่ติดต่อได้</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          maxLength={10}
                          onChange={(e) => {
                            if (!float(e.target.value)) {
                              return;
                            }
                            this.setState({ phone: e.target.value });
                          }}
                          value={this.state.phone}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรสาร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ fax: e.target.value });
                          }}
                          value={this.state.fax}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรศัพท์มือถือ</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ mobile_phone: e.target.value });
                          }}
                          value={this.state.mobile_phone}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">อีเมล</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    {(this.state.emp_type === 2 || this.state.emp_type === 3) && (
                      <div className="row">
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">จดทะเบียนเมื่อ{required}</span>
                          <DatePicker
                            type="th"
                            className="form-control mt-1"
                            placeholder="พิมพ์คำค้นหา..."
                            onChange={(e) => {
                              this.setState({ register_date: e });
                            }}
                            value={this.state.register_date}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ทุนจดทะเบียน(บาท)</span>
                          <input
                            className="form-control mt-1"
                            type="text"
                            onChange={(e) => {
                              if (!float(e.target.value)) {
                                return
                              }
                              this.setState({ register_capital: e.target.value });
                            }}
                            value={this.state.register_capital}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">บัตรประชาชนเลขที่</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          maxLength={13}
                          onChange={(e) => {
                            this.setState({ id_card: e.target.value });
                          }}
                          value={this.state.id_card}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">สถานที่ออกบัตร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ place_issue: e.target.value });
                          }}
                          value={this.state.place_issue}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันที่ออกบัตร</span>
                        <DatePicker
                          className="form-control mt-1"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(e) => {
                            this.setState({ issue_date: e });
                          }}
                          value={this.state.issue_date}
                          max={new Date()}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันที่หมดอายุ</span>
                        <DatePicker
                          className="form-control mt-1"
                          placeholder="พิมพ์คำค้นหา..."
                          onChange={(e) => {
                            this.setState({ expiry_date: e });
                          }}
                          value={this.state.expiry_date}
                          min={new Date()}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชั่วโมง/วันการทำงาน</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ hours_working_day: e.target.value });
                          }}
                          value={this.state.hours_working_day}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จำนวนวันหยุด</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ number_holidays: e.target.value });
                          }}
                          value={this.state.number_holidays}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ใน 1 สัปดาห์ทำงาน(วัน)</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ working_day: e.target.value });
                          }}
                          value={this.state.working_day}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">จำนวนวันหยุดประจำสัปดาห์(วัน)</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ number_holidays_day: e.target.value });
                          }}
                          value={this.state.number_holidays_day}
                        />
                      </div>

                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">วันหยุดพักผ่อนประจำปี</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ take_annual_leave: e.target.value });
                          }}
                          value={this.state.take_annual_leave}
                        />
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">ชื่อผู้ติดต่อ</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ contact_name: e.target.value });
                          }}
                          value={this.state.contact_name}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">เลขรหัสประจำบ้าน ๑๑ หลัก</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ home_number: e.target.value });
                          }}
                          value={this.state.home_number}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรศัพท์</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ phone_2: e.target.value });
                          }}
                          value={this.state.phone_2}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                        <span className="wpx-150">หมายเลขโทรสาร</span>
                        <input
                          className="form-control mt-1"
                          type="text"
                          onChange={(e) => {
                            this.setState({ fax_2: e.target.value });
                          }}
                          value={this.state.fax_2}
                        />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ผู้มีอำนาจผูกพัน</span>
                          <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                  this.setState({ binding_authority_th: e.target.value })
                              }}
                              value={this.state.binding_authority_th}
                          />
                        </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ตำแหน่ง</span>
                          <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                  this.setState({ position_th: e.target.value })
                              }}
                              value={this.state.position_th}
                          />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ผู้มีอำนาจผูกพัน(English)</span>
                          <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                  this.setState({ binding_authority_en: e.target.value })
                              }}
                              value={this.state.binding_authority_en}
                          />
                      </div>
                      <div className="col-12 col-md-6 d-flex align-items-center mb-3">
                          <span className="wpx-150">ตำแหน่ง(English)</span>
                          <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                  this.setState({ position_en: e.target.value })
                              }}
                              value={this.state.position_en}
                          />
                      </div>
                    </div> */}
                    {/* กรรมการผู้มีอำนาจ */}
                    {this.state.emp_type !== 1 && (
                     <>
                     <hr />
                     <div className="d-flex justify-content-between mb-3">
                       <h5 className="mb-3">
                         <b>กรรมการผู้มีอำนาจ</b>
                       </h5>
                       {this.state.data_deputize.length < 3 && (
                         <button
                           className="btn btn-primary px-4"
                           onClick={() => {
                             let data_deputize = this.state.data_deputize;
                             data_deputize.push({
                               deputize_id: "",
                               operator_type: 2,
                               deputize_number: "",
                               deputize_full_name: "",
                               deputize_full_name_en: "",
                               deputize_phone: "",
                               deputize_position: "",
                               deputize_position_en: "",
                               deputize_id_card: "",
                               copy_company_certificate: "",
                               copy_company_certificate_date: "",
                               copy_power_attorney: "",
                               copy_power_attorney_date: "",
                               copy_tax_certificate: "",
                               copy_tax_certificate_date: "",
                             });
                             this.setState({ data_deputize: data_deputize });
                           }}
                         >
                           + เพิ่มกรรมการผู้มีอำนาจ
                         </button>
                       )}
                     </div>
                     <div className="card bg-contain p-3 pb-0">
                       {this.state.data_deputize.map((item, index) => (
                         <div className="card p-3 mb-3 position-relative">
                           <div
                             className="wpx-30 hpx-30 rounded-circle d-flex align-items-center justify-content-center icon text-white pointer bg-danger position-absolute"
                             style={{ top: -8, right: -8 }}
                             onClick={() => {
                               let data_deputize = this.state.data_deputize;
                               data_deputize.splice(index, 1);
                               this.setState({ data_deputize: data_deputize });
                             }}
                           >
                             {"\uf00d"}
                           </div>
                           <div className="row">
                             <div className="col-12 col-lg-2 mb-3 px-1">
                               <span>เลือกกรรมการ</span>
                               <br />
                               <Switch
                                 onChange={(e) => {
                                   let data_deputize = this.state.data_deputize;
                                   // for(let d of data_deputize){
                                   //   d.deputize_selected = false;
                                   // }
                                   data_deputize[index].deputize_selected = e;
                                   this.setState({ data_deputize: data_deputize });
                                 }}
                                 checked={item.deputize_selected}
                               />
                             </div>
                             <div className="col-12 col-md-6 col-lg-5 mb-3">
                               <span>ชื่อ - นามสกุล ไทย{item.operator_type === 2 ? required : null}</span>
                               <br />
                               <input
                                 className="form-control mt-1"
                                 type="text"
                                 onChange={(e) => {
                                   let data_deputize = this.state.data_deputize;
                                   data_deputize[index].deputize_full_name = e.target.value;
                                   this.setState({ data_deputize: data_deputize });
                                 }}
                                 value={item.deputize_full_name}
                               />
                             </div>
                             <div className="col-12 col-md-6 col-lg-5 mb-3">
                               <span>ชื่อ - นามสกุล English</span>
                               <br />
                               <input
                                 className="form-control mt-1"
                                 type="text"
                                 onChange={(e) => {
                                   let data_deputize = this.state.data_deputize;
                                   data_deputize[index].deputize_full_name_en = e.target.value;
                                   this.setState({ data_deputize: data_deputize });
                                 }}
                                 value={item.deputize_full_name_en}
                               />
                             </div>
                             <div className="col-12 col-lg-2"></div>
                             <div className="col-12 col-md-6 col-lg-5 mb-3">
                               <span>ตำแหน่ง ไทย{required}</span>
                               <br />
                               <input
                                 className="form-control mt-1"
                                 type="text"
                                 onChange={(e) => {
                                   let data_deputize = this.state.data_deputize;
                                   data_deputize[index].deputize_position = e.target.value;
                                   this.setState({ data_deputize: data_deputize });
                                 }}
                                 value={item.deputize_position}
                               />
                             </div>
                             <div className="col-12 col-md-6 col-lg-5 mb-3">
                               <span>ตำแหน่ง EN</span>
                               <br />
                               <input
                                 className="form-control mt-1"
                                 type="text"
                                 onChange={(e) => {
                                   let data_deputize = this.state.data_deputize;
                                   data_deputize[index].deputize_position_en = e.target.value;
                                   this.setState({ data_deputize: data_deputize });
                                 }}
                                 value={item.deputize_position_en}
                               />
                             </div>
                             <div className="col-12 col-lg-2"></div>
                             <div className="col-12 col-md-6 col-lg-5 mb-3">
                               <span>เลข 13 หลัก{required}</span>
                               <br />
                               <input
                                 className="form-control mt-1"
                                 type="text"
                                 maxLength={13}
                                 onChange={(e) => {
                                   let data_deputize = this.state.data_deputize;
                                   data_deputize[index].deputize_id_card = e.target.value;
                                   this.setState({ data_deputize: data_deputize });
                                 }}
                                 value={item.deputize_id_card}
                               />
                             </div>
                           </div>
                           <div className="d-flex align-items-center justify-content-between mb-3">
                             <span>
                               <span className="wpx-150">แนบไฟล์เอกสาร</span> (รองรับไฟล์ PDF ขนาดไม่เกิน 3 MB เท่านั้น)
                             </span>
                           </div>
                           <div className="table-responsive">
                             <table className="table table-borderless table-striped">
                               <thead className="bg-primary">
                                 <tr>
                                   <th className="text-center text-white">ลำดับ</th>
                                   <th className="text-left text-white">รายการ</th>
                                   <th className="text-left text-white">วันที่แนบเอกสารล่าสุด</th>
                                   <th className="text-center text-white">ดำเนินการ</th>
                                 </tr>
                               </thead>
                               <tbody>
                                 <tr>
                                   <td className="text-center">1</td>
                                   <td className="text-left">หนังสือรับรองบริษัท+สำเนาบัตรประชาชนกรรมการ{required}</td>
                                   <td className="text-left">{item.copy_power_attorney_date ? format_date(item.copy_power_attorney_date, "dd mmm yyyy") + " เวลา " + format_date(item.copy_power_attorney_date, "hh:mm") + " น." : ""}</td>
                                   <td className="text-center">
                                     {!item.copy_power_attorney ? (
                                       <button
                                         className="btn btn-primary mx-1 px-4"
                                         onClick={() => {
                                           this.setState({ index: index });
                                           document.getElementById("file_copy_power_attorney_array").click();
                                         }}
                                       >
                                         แนบไฟล์
                                       </button>
                                     ) : (
                                       <div className="w-100 d-flex justify-content-center">
                                         <iframe src={item.copy_power_attorney.includes("://") ? item.copy_power_attorney :  "data:application/pdf;base64,"+item.copy_power_attorney} width="150" height="150"></iframe>
                                         <div
                                           className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                           onClick={() => {
                                             this.setState({ modal_detail: true, attachment_file: this.state.copy_power_attorney });
                                           }}
                                         >
                                           <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                                         </div>
                                         <div
                                           className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                           onClick={() => {
                                             this.setState({ modal_delete: true, index: index, attachment_name: "copy_power_attorney" });
                                           }}
                                         >
                                           <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                         </div>
                                       </div>
                                     )}
                                   </td>
                                 </tr>
                               </tbody>
                             </table>
                           </div>
                         </div>
                       ))}
                       <input
                         className="d-none"
                         type="file"
                         id="file_copy_company_certificate_array"
                         accept="application/pdf,image/*"
                         onChange={(e) => {
                           this.import_file(e, "copy_company_certificate_array");
                         }}
                       />
                       <input
                         className="d-none"
                         type="file"
                         id="file_copy_power_attorney_array"
                         accept="application/pdf,image/*"
                         onChange={(e) => {
                           this.import_file(e, "copy_power_attorney_array");
                         }}
                       />
                       <input
                         className="d-none"
                         type="file"
                         id="file_copy_tax_certificate_array"
                         accept="application/pdf,image/*"
                         onChange={(e) => {
                           this.import_file(e, "copy_tax_certificate_array");
                         }}
                       />
                     </div>
                   </>
                    )}
                  </div>
                </div>
                <hr />
                {/* บริการที่เสนอราคา */}
                <div className="w-100">
                  <h5 className="mb-3">
                    <b>บริการที่เสนอราคา</b>
                  </h5>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                      <span className="wpx-150">บริการที่เสนอราคา{required}</span>
                      <Select
                        className="form-control select-search"
                        styles={select_style}
                        options={this.state.data_menu_work}
                        onChange={(e) => {
                          this.setState({
                            process_matter: e,
                            country_qty: "",
                            cambodia_qty: "",
                            laos_qty: "",
                            myanmar_qty: "",
                            vietnam_qty: "",
                            total_qty: "",
                          });

                          if(e.label === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA"){
                            let data_rate = this.state.data_rate.filter(e=>e.service_type ==="รายเรื่อง" && (["งาน 90 วัน","งาน ต่อ Work","งาน ต่อ Visa"].includes(e.rate_name)))
                            let rate_list = [
                              { remark: data_rate[2].rate_name, qty_price: data_rate[2].price, qty: this.state.total_qty, discount_percent: "", discount: "", total_price: data_rate[2].price, service_type:data_rate[2].service_type,vat:""},
                              { remark: data_rate[0].rate_name, qty_price: data_rate[0].price, qty: this.state.total_qty, discount_percent: "", discount: "", total_price: data_rate[0].price, service_type:data_rate[0].service_type,vat:""},
                              { remark: data_rate[1].rate_name, qty_price: data_rate[1].price, qty: this.state.total_qty, discount_percent: "", discount: "", total_price: data_rate[1].price, service_type:data_rate[1].service_type,vat:""},
                            ];
                            this.setState({ rate_list: rate_list });
                          }else{
                            this.setState({ rate_list: [] });
                          }
                        }}
                        value={this.state.process_matter}
                      ></Select>
                    </div>
                  </div>
                  {this.state.process_matter && (
                    <div className="row">
                      {this.state.process_matter.label === "ขอนำเข้าแรงงานต่างด้าว (MOU)" ? (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white wpx-140">สัญชาติ</th>
                                <th className="text-center text-white">จำนวนคน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        country_qty: e.target.value,
                                        cambodia_qty: "",
                                        laos_qty: "",
                                        myanmar_qty: "",
                                        vietnam_qty: "",
                                        total_qty: "",
                                      });
                                    }}
                                    value={this.state.country_qty}
                                    disabled={this.state.readOnly}
                                  >
                                    <option value=""></option>
                                    <option value="กัมพูชา">กัมพูชา</option>
                                    <option value="ลาว">ลาว</option>
                                    <option value="เมียนมา">เมียนมา</option>
                                    <option value="เวียดนาม">เวียดนาม</option>
                                  </select>
                                </td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      switch (this.state.country_qty) {
                                        case "กัมพูชา":
                                          this.setState({
                                            cambodia_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            laos_qty: "",
                                            myanmar_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "ลาว":
                                          this.setState({
                                            laos_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            myanmar_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "เมียนมา":
                                          this.setState({
                                            myanmar_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            laos_qty: "",
                                            vietnam_qty: "",
                                          });
                                          break;
                                        case "เวียดนาม":
                                          this.setState({
                                            vietnam_qty: Number(e.target.value),
                                            total_qty: Number(e.target.value),
                                            cambodia_qty: "",
                                            laos_qty: "",
                                            myanmar_qty: "",
                                          });
                                          break;

                                        default:
                                          break;
                                      }
                                      let rate_list = this.state.rate_list;
                                      for (let item of rate_list) {
                                        item.qty = Number(e.target.value);
                                        if(item.service_type === "รายหัว"){
                                        if (Number(item.discount_percent)) {
                                          item.discount = (Number(item.qty_price) * Number(item.qty)) * (Number(item.discount_percent) / 100);
                                        }
                                        item.total_price = (Number(item.qty_price) * Number(item.qty)) - Number(item.discount);
                                      }else{
                                        if (Number(item.discount_percent)) {
                                          item.discount = Number(item.qty_price) * (Number(item.discount_percent) / 100);
                                        }
                                        item.total_price = Number(item.vat)+Number(item.qty_price) - Number(item.discount);
                                        }
                                      }
                                      this.setState({ rate_list: rate_list });
                                    }}
                                    value={this.state.country_qty === "กัมพูชา" ? this.state.cambodia_qty : this.state.country_qty === "ลาว" ? this.state.laos_qty : this.state.country_qty === "เมียนมา" ? this.state.myanmar_qty : this.state.country_qty === "เวียดนาม" ? this.state.vietnam_qty : ""}
                                    disabled={this.state.readOnly || !this.state.country_qty}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                          <table className="table table-borderless table-striped">
                            <thead className="bg-primary">
                              <tr>
                                <th className="text-center text-white wpx-140">สัญชาติ</th>
                                <th className="text-center text-white">จำนวนคน</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">กัมพูชา</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ cambodia_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        let rate_list = this.state.rate_list;
                                        for (let item of rate_list) {
                                          item.qty = Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty);
                                          if(item.service_type === "รายหัว"){
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price) * Number(item.qty) * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.qty_price) * Number(item.qty) - Number(item.discount);
                                        }else{
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price) * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.vat)+Number(item.qty_price) - Number(item.discount);
                                        }
                                        }
                                        this.setState({ rate_list: rate_list, total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty) });
                                      }, 10);
                                    }}
                                    value={this.state.cambodia_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">ลาว</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ laos_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        let rate_list = this.state.rate_list;
                                        for (let item of rate_list) {
                                          item.qty = Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty);
                                          if(item.service_type === "รายหัว"){
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price) * Number(item.qty) * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.qty_price) * Number(item.qty) - Number(item.discount);
                                        }else{
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price)  * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.vat)+Number(item.qty_price)  - Number(item.discount);
                                        }
                                        }
                                        this.setState({ rate_list: rate_list, total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty) });
                                      }, 10);
                                    }}
                                    value={this.state.laos_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">เมียนมา</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ myanmar_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        let rate_list = this.state.rate_list;
                                        for (let item of rate_list) {
                                          item.qty = Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty);
                                          if(item.service_type === "รายหัว"){
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price) * Number(item.qty) * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.qty_price) * Number(item.qty) - Number(item.discount);
                                        }else{
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price)  * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.vat)+Number(item.qty_price)  - Number(item.discount);
                                        }
                                        }
                                        this.setState({ rate_list: rate_list, total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty) });
                                      }, 10);
                                    }}
                                    value={this.state.myanmar_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">เวียดนาม</td>
                                <td className="text-center">
                                  <input
                                    className="form-control text-end"
                                    type="text"
                                    onChange={(e) => {
                                      if (!float(e.target.value)) {
                                        return;
                                      }
                                      this.setState({ vietnam_qty: Number(e.target.value) });
                                      setTimeout(() => {
                                        let rate_list = this.state.rate_list;
                                        for (let item of rate_list) {
                                          item.qty = Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty);
                                          if(item.service_type === "รายหัว"){
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price) * Number(item.qty) * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.qty_price) * Number(item.qty) - Number(item.discount);
                                        }else{
                                          if (Number(item.discount_percent)) {
                                            item.discount = Number(item.qty_price)  * (Number(item.discount_percent) / 100);
                                          }
                                          item.total_price = Number(item.vat)+Number(item.qty_price)  - Number(item.discount);
                                        }
                                        }
                                        this.setState({ rate_list: rate_list, total_qty: Number(this.state.cambodia_qty) + Number(this.state.laos_qty) + Number(this.state.myanmar_qty) + Number(this.state.vietnam_qty) });
                                      }, 10);
                                    }}
                                    value={this.state.vietnam_qty}
                                    disabled={this.state.readOnly}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-3 mx-auto">
                      <span className="wpx-150">รวมทั้งสิ้น</span>
                      <input
                        className="form-control mt-1 text-end"
                        type="text"
                        readOnly
                        onChange={(e) => {
                          this.setState({ total_qty: e.target.value });
                        }}
                        value={this.state.total_qty}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                {/* ค่าบริการ */}
                <div className="col-12"> 
                    <h5 className="mb-3">
                      <b>ค่าบริการ</b>
                    </h5>
                  <div className="card p-3">
                    <div className="mb-3">
                      {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (
                        <button
                          type="button"
                          className="btn btn-primary px-4"
                          onClick={() => {
                            let rate_list = this.state.rate_list;
                            rate_list.push({
                              remark: "",
                              qty_price: "",
                              qty: this.state.total_qty,
                              discount_percent: "",
                              discount: "",
                              total_price: "",
                              service_type:"",
                              vat: ""
                            });
                            this.setState({ rate_list: rate_list });
                          }}
                        >
                          + เพิ่มรายการ
                        </button>
                      )}
                    </div>
                    <table className="table table-borderless table-striped">
                      <thead className="bg-primary">
                        <tr>
                          <th className="text-left text-white wpx-100">รายการ</th>
                          <th className="text-left text-white">บริการ</th>
                          {this.state.process_matter.label === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (<th className="text-left text-white wpx-100">ค่าธรรมเนียม</th>)}
                          {this.state.process_matter.label === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (<th className="text-left text-white wpx-100">ค่าบริการ</th>)}
                          {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (<th className="text-left text-white wpx-100">ราคาต่อหน่วย</th>)}
                          {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (<th className="text-left text-white wpx-100">หน่วย</th>)}
                          {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && ( <th className="text-left text-white wpx-100">ส่วนลด %</th>)}
                          {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (<th className="text-left text-white wpx-100">ส่วนลด (บาท)</th>)}
                          <th className="text-left text-white wpx-100">รวม</th>
                          <th className="text-left text-white wpx-70"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rate_list &&
                          this.state.rate_list.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left"> รายการ {index + 1} </td>
                              <td className="text-left">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    let rate_list = this.state.rate_list;
                                    rate_list[index].remark = e.target.value;  
                                    rate_list[index].qty_price = e.target.options[e.target.selectedIndex].getAttribute("data-price");
                                    rate_list[index].service_type = e.target.options[e.target.selectedIndex].getAttribute("data-service-type");
                                    if(rate_list[index].service_type === "รายหัว"){
                                    if (Number(rate_list[index].discount_percent)) {
                                      rate_list[index].discount = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) * (Number(rate_list[index].discount_percent) / 100);
                                    }
                                    rate_list[index].total_price = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) - Number(rate_list[index].discount);
                                  }else{
                                    if (Number(rate_list[index].discount_percent)) {
                                      rate_list[index].discount = Number(rate_list[index].qty_price)  * (Number(rate_list[index].discount_percent) / 100);
                                    }
                                    rate_list[index].total_price = Number(rate_list[index].qty_price)  - Number(rate_list[index].discount);
                                 
                                  } 
                                    this.setState({ rate_list: rate_list });
                                  }}
                                  value={item.remark}
                                  disabled={this.state.process_matter.label === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA"}
                                >
                                  <option value=""></option>
                                  <optgroup label="รายเรื่อง">
                                  {this.state.data_rate.filter(e=>e.service_type ==="รายเรื่อง").map((rate,rate_index)=>(
                                    <option value={rate.rate_name} data-price={rate.price} data-service-type={rate.service_type}>{rate.rate_name}</option>
                                  ))}
                                  </optgroup>
                                  <optgroup label="รายหัว">
                                  {this.state.data_rate.filter(e=>e.service_type ==="รายหัว").map((rate,rate_index)=>(
                                    <option value={rate.rate_name} data-price={rate.price} data-service-type={rate.service_type}>{rate.rate_name}</option>
                                  ))}
                                  </optgroup>
                                  
                                </select> 
                              </td>
                              {this.state.process_matter.label === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (
                                <td className="text-left">
                                <input
                                  className="form-control text-end"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    if (!float(e.target.value)) {
                                      return;
                                    }
                                    let rate_list = this.state.rate_list;
                                    rate_list[index].vat = e.target.value;
                                    rate_list[index].total_price = Number(rate_list[index].qty_price) + Number(rate_list[index].vat);
                                    this.setState({ rate_list: rate_list });
                                  }}
                                  value={item.vat}
                                />
                              </td>
                              )}
                              <td className="text-left">
                                <input
                                  className="form-control text-end"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    if (!float(e.target.value)) {
                                      return;
                                    }
                                    let rate_list = this.state.rate_list;
                                    rate_list[index].qty_price = e.target.value;

                                    console.log(Number(rate_list[index].discount))
                                    if(rate_list[index].service_type === "รายหัว"){
                                    if (Number(rate_list[index].discount_percent)) {
                                      rate_list[index].discount = ((Number(rate_list[index].qty_price) * Number(rate_list[index].qty))) * (Number(rate_list[index].discount_percent) / 100);
                                    }
                                    rate_list[index].total_price = (Number(rate_list[index].qty_price) * Number(rate_list[index].qty)) - Number(rate_list[index].discount);
                                  }else{
                                    if (Number(rate_list[index].discount_percent)) {
                                      rate_list[index].discount = (Number(rate_list[index].qty_price)) * (Number(rate_list[index].discount_percent) / 100);
                                    }
                                    rate_list[index].total_price = Number(rate_list[index].qty_price) + Number(rate_list[index].vat) - Number(rate_list[index].discount);
                                 
                                  }
                                    this.setState({ rate_list: rate_list });
                                  }}
                                  value={item.qty_price}
                                />
                              </td>
                              {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (
                              <td className="text-left">
                                <input
                                  className="form-control text-end"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    if (!float(e.target.value)) {
                                      return;
                                    }
                                    let rate_list = this.state.rate_list;
                                    rate_list[index].qty = e.target.value;
                                    if(rate_list[index].service_type === "รายหัว"){
                                      if (Number(rate_list[index].discount_percent)) {
                                      rate_list[index].discount = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) * (Number(rate_list[index].discount_percent) / 100);
                                    }
                                    rate_list[index].total_price = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) - Number(rate_list[index].discount);
                                  }else{
                                    if (Number(rate_list[index].discount_percent)) {
                                      rate_list[index].discount = Number(rate_list[index].qty_price)  * (Number(rate_list[index].discount_percent) / 100);
                                    }
                                    rate_list[index].total_price = Number(rate_list[index].qty_price)  - Number(rate_list[index].discount);
                                  }
                                    this.setState({ rate_list: rate_list });
                                  }}
                                  value={item.qty}
                                  readOnly
                                />
                              </td>)}
                              {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (
                              <td className="text-left">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    let rate_list = this.state.rate_list;
                                    rate_list[index].discount_percent = e.target.value;
                                    if(rate_list[index].service_type === "รายหัว"){
                                    
                                    rate_list[index].discount = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) * (Number(rate_list[index].discount_percent) / 100);
                                    rate_list[index].total_price = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) - Number(rate_list[index].discount);
                                    }else{
                                      rate_list[index].discount = Number(rate_list[index].qty_price) * (Number(rate_list[index].discount_percent) / 100);
                                      rate_list[index].total_price = Number(rate_list[index].qty_price)  - Number(rate_list[index].discount);
                                      
                                    }
                                    this.setState({ rate_list: rate_list });
                                  }}
                                  value={item.discount_percent}
                                >
                                  <option></option>
                                  <option value="5">5 %</option>
                                  <option value="10">10 %</option>
                                </select>
                              </td>)}
                              {this.state.process_matter.label !== "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA" && (
                              <td className="text-left">
                                <input
                                  className="form-control text-end"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    if (!float(e.target.value)) {
                                      return;
                                    }
                                    let rate_list = this.state.rate_list;
                                    rate_list[index].discount_percent = "";
                                    rate_list[index].discount = e.target.value;
                                    if(rate_list[index].service_type === "รายหัว"){
                                    
                                    rate_list[index].total_price = Number(rate_list[index].qty_price) * Number(rate_list[index].qty) - Number(item.discount);
                                    }else{
                                      rate_list[index].total_price = Number(rate_list[index].qty_price) - Number(item.discount);
                                      
                                    }
                                    this.setState({ rate_list: rate_list });
                                  }}
                                  value={item.discount}
                                />
                              </td>)}
                              <td className="text-left">{item.total_price ? toFixed(item.total_price) : "0.00"}</td>
                              <td className="text-left">
                                <div
                                  className="icon wpx-30 hpx-30 text-18 text-secondary d-flex justify-content-center align-items-center pointer"
                                  onClick={() => {
                                    let rate_list = this.state.rate_list;
                                    rate_list.splice(index, 1);
                                    this.setState({ rate_list: rate_list });
                                  }}
                                >
                                  {"\uf2ed"}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-flex align-items-center justify-content-end">
                      <label>รวมเป็นเงิน</label>
                      <h3 className="wpx-200 text-end">
                        <b>{this.state.is_vat ? toFixed(total(this.state.rate_list, "total_price")* 1.07)  : toFixed(total(this.state.rate_list, "total_price"))}</b>
                      </h3>
                    </div>
                  </div>

                  <div className="p-3 pb-0">
                    <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                        <label className="wpx-120">ค่าบริการมี VAT :</label>
                        <input
                        type="checkbox"
                          onChange={(e) => { 
                            this.setState({ is_vat: e.target.checked });
                          }}
                          checked={this.state.is_vat}
                        />
                      </div>
                      <div className="col-12 col-lg-6">
                      </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-120">การจ่ายเงิน{required}</span>
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="payment_person"
                        onChange={(e) => {
                          this.setState({ payment_person: "นายจ้าง" });
                        }}
                        checked={this.state.payment_person === "นายจ้าง"}
                        disabled={this.state.readOnly && this.state.payment_person}
                      />
                      นายจ้าง
                      <input
                        className="mt-1 mx-3"
                        type="radio"
                        name="payment_person"
                        onChange={(e) => {
                          this.setState({ payment_person: "ลูกจ้าง" });
                        }}
                        checked={this.state.payment_person === "ลูกจ้าง"}
                        disabled={this.state.readOnly && this.state.payment_person}
                      />
                      ลูกจ้าง
                  </div>
                      <div className="col-12 col-lg-6 mb-3 d-flex">
                        <label className="wpx-120">หมายเหตุ :</label>
                        <textarea
                          className="form-control"
                          rows={5}
                          placeholder=""
                          onChange={(e) => {
                            this.setState({ remark: e.target.value });
                          }}
                          value={this.state.remark}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                {/* งวดเก็บค่าบริการ */}
                <div className="col-12"> 
                    <h5 className="mb-3">
                      <b>งวดเก็บค่าบริการ</b>
                    </h5>
                    <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-primary px-4"
                      onClick={() => {
                        let installments = this.state.installments;
                                    installments.push({ percent: "", amount: "",remark:"", status: false,checked:false,paid: false });
                                  this.setState({ installments_count: installments.length, installments: installments });
                      }}
                    >
                      + เพิ่มรายการ
                    </button>

                    {this.state.installments && (
                    <button
                      type="button"
                      className="btn btn-primary px-4"
                      onClick={() => {
                        let amount = this.state.is_vat ? total(this.state.rate_list, "total_price") * 1.07 :total(this.state.rate_list, "total_price");
                        let installments = this.state.installments;
                        for(let item of installments){ 
                          item.amount = (Number(item.percent)/100)*amount;
                        }
                        this.setState({ installments: installments });
                      }}
                    >
                      คำนวณใหม่
                    </button>
                    )}
                    </div>
                    
                        <div className="py-3">
                        <table className="table table-borderless table-striped">
                      <thead className="bg-primary">
                        <tr>
                          <th className="text-left text-white wpx-100">งวดที่</th>
                          <th className="text-left text-white">จำนวนยอด</th>
                          <th className="text-left text-white">คิดเป็น % {this.state.installments && " ( รวม "+toFixed(total(this.state.installments,"percent")) + "% ) " }</th>
                          <th className="text-left text-white">คำอธิบายเพิ่มเติม</th>
                          <th className="text-left text-white wpx-70"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.installments.map((item, index) => (
                            <tr key={index}>
                              <td className="text-left"> งวดที่ {index + 1} </td>
                              <td className="text-left"> 
                                <input
                                  className="form-control mb-2"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    if (!float(e.target.value)) {
                                      return;
                                    } 
                                    let amount = this.state.is_vat ? total(this.state.rate_list, "total_price") * 1.07 :total(this.state.rate_list, "total_price");
                                    let installments = this.state.installments;
                                    installments[index].amount = e.target.value;
                                    if(total(this.state.installments,"amount") > amount){
                                      installments[index].amount = 0;
                                      installments[index].amount = amount - total(this.state.installments,"amount")
                                    }
                                    installments[index].percent = Number(toFixed(installments[index].amount/amount*100));
                                    this.setState({ installments: installments });
                                  }}
                                  value={item.amount}
                                /> 
                              </td>
                              <td className="text-left">
                                <input
                                  className="form-control mb-2"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    if (!float(e.target.value) ) {
                                      return;
                                    }
                                    let amount = this.state.is_vat ? total(this.state.rate_list, "total_price") * 1.07 :total(this.state.rate_list, "total_price");
                                    let installments = this.state.installments;
                                    installments[index].percent = e.target.value;
                                    if(total(this.state.installments,"percent") > 100){
                                      installments[index].percent = 0;
                                      installments[index].percent = Number(toFixed(100 - total(this.state.installments,"percent")))
                                    }
                                    installments[index].amount = Number(((Number(installments[index].percent)/100)*amount).toFixed(2));
                                    this.setState({ installments: installments });
                                  }}
                                  value={item.percent}
                                />
                              </td>
                              <td className="text-left">
                                <input
                                  className="form-control mb-2"
                                  type="text"
                                  placeholder=""
                                  onChange={(e) => {
                                    let installments = this.state.installments;
                                    installments[index].remark = e.target.value;
                                    this.setState({ installments: installments });
                                  }}
                                  value={item.remark}
                                />
                              </td>
                              <td className="text-left">
                                <div
                                  className="icon wpx-30 hpx-30 text-18 text-secondary d-flex justify-content-center align-items-center pointer"
                                  onClick={() => {
                                    let installments = this.state.installments;
                                    installments.splice(index, 1);
                                    this.setState({ installments: installments });
                                  }}
                                >
                                  {"\uf2ed"}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {this.state.installments.length !== 0 && (
                      <label className="d-flex align-items-center mb-2"> 
                        ราคารวม : <h4 className="px-2">{this.state.installments.length !== 0 ? toFixed(total(this.state.installments, "amount")) : "0.00"}</h4> บาท
                      </label>
                    )}
                  </div>
                </div>
                {/* ปุ่ม */}
                <div className={"row" + (window.location.href.includes("create")?" create":" update")}>
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.href = "/Quotation/list";
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => {
                            if (this.state.emp_type === 0) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภท");
                              return;
                            } else if (this.state.emp_number.trim() === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอก" + (this.state.emp_type === 1 ? "รหัสบัตรประชาชน" : this.state.emp_type === 2 ? "เลขนิติบุคคล" : this.state.emp_type === 3 ? "เลขที่หนังสือเดินทาง" : " ประเภท"));
                              return;
                            } else if (this.state.prefix_th === "" && this.state.emp_type === 1) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกคำนำหน้า");
                              return;
                            } else if (this.state.full_name_th === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกชื่อนายจ้าง/สถานประกอบการ");
                              return;
                            } else if (!this.state.work_type) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกประเภทงาน");
                              return;
                            // } else if (!this.state.business_description) {
                            //   alert("warning", "แจ้งเตือน", "กรุณากรอกลักษณะงาน");
                            //   return;
                            } else if (this.state.address_number === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกเลขที่");
                              return;
                            } else if (!this.state.province) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกจังหวัด");
                              return;
                            } else if (!this.state.district) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกอำเภอ/เขต");
                              return;
                            } else if (!this.state.sub_district) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกตำบล/แขวง");
                              return;
                            } else if (this.state.zipcode === "") {
                              alert("warning", "แจ้งเตือน", "กรุณากรอกรหัสไปษณีย์");
                              return;
                            } else if (this.state.register_date === "" && this.state.emp_type !== 1) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่จดทะเบียน");
                              return;
                            } else if (!this.state.process_matter) {
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกบริการที่เสนอราคา");
                              return;
                            }
                            if(!this.state.payment_person){
                              alert("warning", "แจ้งเตือน", "กรุณาเลือกการจ่ายเงิน");
                              return;
                            }
                            if (this.props.location.query.id) {
                              this.Update();
                            } else {
                              this.Create();
                            }
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ดูข้อมูลเอกสาร */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="xl"
        >
          <Modal.Body className="p-2">
            <iframe src={this.state.attachment_file.includes("://") ? this.state.attachment_file :  "data:application/pdf;base64,"+this.state.attachment_file} width="100%" height={window.innerHeight - 70 + "px"}></iframe>
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_detail: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.attachment_name === "copy_company_certificate" || this.state.attachment_name === "copy_power_attorney" ? "ลบเอกสารแนบ" : ""}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบ “ {this.state.attachment_name === "copy_company_certificate" ? "สำเนาบัตรประชาชน " : this.state.attachment_name === "copy_power_attorney" ? "สำเนากรรมการผู้มีอำนาจ " : ""}” หรือไม่</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    let data_deputize = this.state.data_deputize;
                    if (this.state.attachment_name === "copy_company_certificate") {
                      data_deputize[this.state.index].copy_company_certificate = "";
                      data_deputize[this.state.index].copy_company_certificate_date = "";
                    } else if (this.state.attachment_name === "copy_power_attorney") {
                      data_deputize[this.state.index].copy_power_attorney = "";
                      data_deputize[this.state.index].copy_power_attorney_date = "";
                    } else if (this.state.attachment_name === "copy_tax_certificate") {
                      data_deputize[this.state.index].copy_tax_certificate = "";
                      data_deputize[this.state.index].copy_tax_certificate_date = "";
                    } else if (this.state.attachment_name === "copy_idcard_image") {
                      this.setState({ copy_idcard_image: "", modal_delete: false });
                    }
                    this.setState({ data_deputize: data_deputize, modal_delete: false });

                    success();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../../components/Navbar.js";
import Select from "react-select";
import LOGO from "../../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  days_between,
  // component
  DatePicker,
} from "../../../components/CustomComponent.js";

import province from "../../../assets/files/province.json";
import district from "../../../assets/files/district.json";
import subdistrict from "../../../assets/files/all_province.json";

import "../../../components/global.js";
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/index.css";

export default class CreateUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_delete: false,
      tab: 1,
      loading: false,

      data_search: "",

      name_th: "",
      name_en: "",
      address_th: "",
      address_en: "",
      tax_number: "",
      left_image: "",
      right_image: "",

      phone_list: [],
      social_list: []
    };
  }
  async componentDidMount() {
      this.Get();
  }
  Get = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "api/header-doc/list", null);
    if (result && result.status) {
      let item = result.data; 
      this.setState({
        name_th: item.name_th,
        name_en: item.name_en,
        address_th: item.address_th,
        address_en: item.address_en,
        tax_number: item.tax_number,
        left_image: item.left_image,
        right_image: item.right_image, 
        phone_list: item.phone_list ? item.phone_list : [], 
        social_list: item.social_list ? item.social_list : [], 
      });
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let body = {
      name_th: this.state.name_th,
      name_en: this.state.name_en,
      address_th: this.state.address_th,
      address_en: this.state.address_en,
      tax_number: this.state.tax_number,
      left_image: this.state.left_image,
      right_image: this.state.right_image,
      phone_list: this.state.phone_list, 
      social_list: this.state.social_list, 
    };
    let result = await PUT(tokens, "api/header-doc/update", body);
    if (result && result.status) {
      success_url("/setting/PaperHead/create");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    await this.changePhoto(e.target.files[0], type);
    if (type === "left") {
      document.getElementById("file_left").value = "";
      document.getElementById("file_left").file = null;
    } else if (type === "right") {
      document.getElementById("file_right").value = "";
      document.getElementById("file_right").file = null;
    }
  };
  changePhoto = async (file, type) => {
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("pdf") && !file.type.includes("image")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (type === "left") {
        this.setState({
          left_image: e.target.result.split(",")[1],
        });
      } else if (type === "right") {
        this.setState({
          right_image: e.target.result.split(",")[1],
        });
      }
    };
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ตั้งค่า" page3="หัวกระดาษ" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h4 className="mb-3">
                <b>หัวกระดาษ</b>
              </h4>
            </div>
            <div className="card shadow border-0 mb-5" style={{ background: "#ffffff" }}>
              <div className="p-3">
                <div className="w-100">
                  <div className="row mt-3">
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อบริษัท ภาษาไทย{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ name_th: e.target.value });
                        }}
                        value={this.state.name_th}
                      />
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ชื่อบริษัท ภาษาอังกฤษ{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ name_en: e.target.value });
                        }}
                        value={this.state.name_en}
                      />
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ที่อยู่ TH{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ address_th: e.target.value });
                        }}
                        value={this.state.address_th}
                      />
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">ที่อยู่ EN{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ address_en: e.target.value });
                        }}
                        value={this.state.address_en}
                      />
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">เลขที่ผู้เสียภาษี{required}</span>
                      <input
                        className="form-control mt-1"
                        type="text"
                        onChange={(e) => {
                          this.setState({ tax_number: e.target.value });
                        }}
                        value={this.state.tax_number}
                      />
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">รูปภาพด้านซ้าย{required}</span>
                      {this.state.left_image ? (
                        <div className="position-relative">
                        <img alt="left" src={this.state.left_image.includes("https://") ? this.state.left_image : "data:image/png;base64," + this.state.left_image} className="wpx-150 hpx-150 rounded" style={{objectFit:"cover"}}/>
                        <button
                                className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle"
                                style={{ top: -8, right: -8 }}
                                onClick={() => {
                                  this.setState({ left_image: "" });
                                }}
                              >
                                {"\uf00d"}
                              </button>
                      </div>
                      ):(
                      <input
                        className="form-control mt-1"
                        type="file"
                        onChange={(e) => {
                          this.import_file(e,"left");
                        }}
                        id="file_left"
                      />
                    )}
                    </div>
                    <div className="col-12 d-flex align-items-center mb-3">
                      <span className="wpx-150">รูปภาพด้านขวา{required}</span>
                      {this.state.right_image ? (
                        <div className="position-relative">
                        <img alt="right" src={this.state.right_image.includes("https://") ? this.state.right_image : "data:image/png;base64," + this.state.right_image} className="wpx-150 hpx-150 rounded" style={{objectFit:"cover"}}/>
                        <button
                                className="icon btn btn-danger position-absolute wpx-30 hpx-30 d-flex justify-content-center align-items-center rounded-circle"
                                style={{ top: -8, right: -8 }}
                                onClick={() => {
                                  this.setState({ right_image: "" });
                                }}
                              >
                                {"\uf00d"}
                              </button>
                      </div>
                      ):(
                      <input
                        className="form-control mt-1"
                        type="file"
                        onChange={(e) => {
                          this.import_file(e,"right");
                        }}
                        id="file_right"
                      />
                    )}
                      
                    </div>
                  </div>
                  <h5 className="my-3">
                    <b>เบอร์โทร</b>
                  </h5>
                  <div className="card p-3">
                    <div className="row">
                      {this.state.phone_list.map((item, index) => (
                        <div className="d-flex align-items-center mb-3">
                          <div className="wpx-50">{index + 1}.</div>
                          <div className="mx-1 w-100">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let phone_list = this.state.phone_list;
                                phone_list[index] = e.target.value;
                                this.setState({ phone_list: phone_list });
                              }}
                              value={item}
                            />
                          </div>
                          <div className="wpx-50 d-flex justify-content-center">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                let phone_list = this.state.phone_list;
                                phone_list.splice(index, 1);
                                this.setState({ phone_list: phone_list });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary px-4"
                        onClick={() => {
                          let phone_list = this.state.phone_list;
                          phone_list.push("");
                          this.setState({ phone_list: phone_list });
                        }}
                      >
                        เพิ่ม
                      </button>
                    </div>
                  </div>
                  <hr/>
                  <h5 className="my-3">
                    <b>Social Media</b>
                  </h5>
                  <div className="card p-3">
                    <div className="row">
                      {this.state.social_list.map((item, index) => (
                        <div className="d-flex align-items-center mb-3">
                          <div className="wpx-50">{index + 1}.</div>
                          <div className="mx-1 w-100">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => {
                                let social_list = this.state.social_list;
                                social_list[index] = e.target.value;
                                this.setState({ social_list: social_list });
                              }}
                              value={item}
                            />
                          </div>
                          <div className="wpx-50 d-flex justify-content-center">
                            <div
                              className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                let social_list = this.state.social_list;
                                social_list.splice(index, 1);
                                this.setState({ social_list: social_list });
                              }}
                            >
                              <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-primary px-4"
                        onClick={() => {
                          let social_list = this.state.social_list;
                          social_list.push("");
                          this.setState({ social_list: social_list });
                        }}
                      >
                        เพิ่ม
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row update">
                  <div className="col-12 col-sm-8 col-md-6 col-xl-5 col-xxl-4 mx-auto">
                    <div className="d-flex mt-5">
                      <div className="w-50 pe-1">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-100 mb-3"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                      <div className="w-50 ps-1">
                        <button 
                          type="button"
                          className="btn btn-primary w-100 mb-3"
                          onClick={() => { 
                              this.Update(); 
                          }}
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    );
  }
}

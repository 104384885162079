global.toggle = false;
global.api = "https://clmv-api.servicesaes.com/";
// global.api = "http://localhost:5001/";
// global.api = "http://203.150.33.150:5000/";
// global.api="http://150.95.31.194:8032/";
global.version = "V.1.0.88";
global.role = [
  { menu_name: "RecieveAll", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "RecieveMOU", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "RenewLicense", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "ApproveRecieveAll", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Waiting", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Invoice", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Receipt", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "BillingNote", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Quotation", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "RequestForPrice", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "SaleDepartment", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Employer", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Employee", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Agent", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Insurance", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Download", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/LMS", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/PostBoxNo", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/LMSCategory", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/Quotation", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/ServiceType", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/ServiceRate", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/DocumentName", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/SendDocument", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/PaperHead", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/EmpOffice", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/Bank", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "setting/Alert", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Number", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "User/list", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
  { menu_name: "Role/list", permission: { can_view: false, can_add: false, can_edit: false, can_delete: false, can_download: false, can_approved: false } },
];
global.role_name = {
"RecieveAll":"รายการรับเรื่องทั้งหมด",
"RecieveMOU":"รายการรับเรื่อง > ขอใบอนุญาตทำงาน",
"RenewLicense":"รายการรับเรื่อง > ต่อใบอนุญาตทำงาน",
"ApproveRecieveAll":"รายการรับเรื่อง > รายการ QC",
"Waiting":"รายการรอยื่น",
"Invoice":"รายการใบแจ้งหนี้",
"Receipt":"รายการใบเสร็จ",
"BillingNote":"Billing Note",
"Quotation":"ใบเสนอราคา",
"RequestForPrice":"รายการใบขอราคา",
"SaleDepartment":"ฝ่ายการขาย",
"Employer":"ทะเบียนนายจ้าง",
"Employee":"ทะเบียนลูกจ้าง",
"Agent":"ทะเบียนตัวแทน",
"Insurance":"ประกันภัย",
"Download":"ดาวน์โหลดไฟล์",
"setting/LMS":"การตั้งค่า > จัดการระบบ LMS",
"setting/PostBoxNo":"การตั้งค่า > เลขตู้",
"setting/LMSCategory":"การตั้งค่า > จัดการหมวดหมู่ระบบ LMS",
"setting/Quotation":"การตั้งค่า > จัดการใบเสนอราคา",
"setting/ServiceType":"การตั้งค่า > จัดการประเภทบริการ",
"setting/ServiceRate":"การตั้งค่า > จัดการอัตราค่าบริการ",
"setting/DocumentName":"การตั้งค่า > จัดการชื่อเอกสาร",
"setting/SendDocument":"การตั้งค่า > จัดการการส่งเอกสาร",
"setting/PaperHead":"การตั้งค่า > จัดการหัวกระดาษ",
"setting/EmpOffice":"การตั้งค่า > รายชื่อสำนักจัดหางาน",
"setting/Bank":"การตั้งค่า > บัญชีธนาคาร",
"setting/Alert":"การตั้งค่า > การแจ้งเตือนหมดอายุเอกสาร",
"Number":"การตั้งค่า > ตั้งค่าการออกเลขเรื่อง",
"User/list":"การตั้งค่า > บัญชีระบบ",
"Role/list":"การตั้งค่า > สิทธิ์ผู้ใช้",
};
global.data_nationality = [
  { value: "1", label: "เมียนมา", en: "Myanmar" },
  { value: "2", label: "ลาว", en: "Lao" },
  { value: "3", label: "กัมพูชา", en: "Cambodia" },
  { value: "4", label: "เวียดนาม", en: "Vietnam" },
];
global.data_gender = [
  { value: "ชาย", label: "ชาย" },
  { value: "หญิง", label: "หญิง" },
];
global.data_evidence_type = [
  { value: "1", label: "หนังสือเดินทาง (Passport)" },
  { value: "2", label: "หนังสือรับรองสถานะบุคคล (Certificate of Identity)" },
  { value: "3", label: "เอกสารเดินทาง (Travel Document)" },
  { value: "4", label: "บัตรผ่านแดน (Border pass)" },
  { value: "5", label: "บัตรสีชมพู (Pink Card)" },
  { value: "6", label: "ใบอนุญาตทำงาน (Work Permit)" },
  { value: "7", label: "บัตรประจำตัวประชาชนหรือเอกสารเพื่อใช้ในการยืนยันตัวตนอื่นที่ประเทศต้นทางออกให้ (Identification Card)" },
  { value: "8", label: "เอกสารอื่น ๆ" },
  { value: "9", label: "ไม่มีเอกสารประจำตัว" },
];
global.data_country = [
  { value: "1", label: "กัมพูชา" },
  { value: "2", label: "ลาว" },
  { value: "3", label: "เมียนมา" },
  { value: "4", label: "เวียดนาม" },
  { value: "5", label: "ไทย" },
];

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  format_date_excel,
  tokens,
  days_between,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_match: false,
      modal_update: false,
      modal_match2: false,
      modal_download: false,
      loading: false,
      data: [],
      data_menu_work: [],
      search: "",

      data_search: "",
      status_search: "",

      select_90d_date:"",
      select_visa_date:"",
      select_work_date:"",
      count_e_work: 0,
      count_wp: 0,
      fw_data: [],
      checklist_data: [],
      receive_id: "",
      receive_code: "",
      receive_date: null,
      branch: "",
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      emp_number: "",
      process_matter: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: null,
      c_certificate_20: null,
      c_copy_committee: null,
      receive_status: 0,
      receive_date_s1: null,
      receive_by_s1: null,
      receive_date_s2: null,
      receive_by_s2: null,
      receive_date_s3: null,
      receive_by_s3: null,
      receive_date_s4: null,
      receive_by_s4: null,
      receive_date_s5: null,
      receive_by_s5: null,
      receive_date_s6: null,
      receive_by_s6: null,
      receive_date_s7: null,
      receive_by_s7: null,
      receive_date_s8: null,
      receive_by_s8: null,
      receive_date_s9: null,
      receive_by_s9: null,
      receive_date_s10: null,
      receive_by_s10: null,
      receive_date_s11: null,
      receive_by_s11: null,
      receive_date_s12: null,
      receive_by_s12: null,
      receive_date_s13: null,
      receive_by_s13: null,
      receive_date_s14: null,
      receive_by_s14: null,
      receive_date_s15: null,
      receive_by_s15: null,
      receive_date_s16: null,
      receive_by_s16: null,
      receive_date_s17: null,
      receive_by_s17: null,
      receive_date_s18: null,
      receive_by_s18: null,
      receive_date_s19: null,
      receive_by_s19: null,
      receive_date_s20: null,
      receive_by_s20: null,
      receive_date_s21: null,
      receive_by_s21: null,
      is_active: true,
      create_by: "",
      emp_data: null,
      menu_data: null,

      check_received_e_work: false,

      check_close: false,

      deliver_documents_s9: null,
      sending_document_date_s9: null,
      remark_s9: "",
      documents_s9: null,

      deliver_documents_s16: null,
      sending_document_date_s16: null,
      remark_s16: "",
      documents_s16: null,

      deliver_documents_s19: null,
      sending_document_date_s19: null,
      remark_s19: "",
      documents_s19: null,
      remark_s20: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,

      show_tab: 0,

      data_business_type: [],
      data_deputize: [],
      count_status: null,

      status_group: "",
      data_salary: [],

      data_workplace: [],

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      documents_employer_s22: "",
      documents_power_s22: "",
      documents_sub_power_s22: "",
      documents_scan_name: "",

      data_work_type: [],

      admin_fullname:"",

      rate_list: [],
      due_date:"",
      due_location:"",
      due_remark:"",
      save_name_list:""
    };
  }
  async componentDidMount() {
    let data = localStorage.getItem("detail_user") ? localStorage.getItem("detail_user") : localStorage.getItem("user");
    data = data ? JSON.parse(data) : null;
    if (data) {
      this.setState({ admin_fullname: data.full_name });
    }
    this.setState({ loading: true });
    await this.GetWorkType();
    await this.GetBusinessType();
    await this.GetSalary();
    await this.GetAdmin();
    await this.GetPrefix();
    await this.GetMenuWork();
    await this.Get();
    this.setState({ loading: false });
  }
  GetMenuWork = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: [],
      menu_id: "",
    };
    let result = await GET(tokens, "api/receive-matter-menu/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data,
      });
    }
    this.setState({ loading: false });
  };
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetAdmin = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
  };
  GetSalary = async () => {
    let body = {
      data_search: "",
      minimum_wage_id: "",
    };
    let result = await GET(tokens, "api/minimum-wage/list", body);
    if (result && result.status) {
      this.setState({
        data_salary: result.data,
      });
    }
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
          };
        }),
      });
    }
  }; 
  
  Get = async () => {
    let body = {
      data_search: this.state.data_search,
      receive_id: "",
      menu: "MOU",
      status_group: this.state.status_group,
      menu_id: Number(this.state.status_search),
    };
    let result = await GET(tokens, "api/receive-matter/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/foreign-worker/delete/" + this.state.receive_id, null);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Status = (item) => {
    let component = null;
    switch (item.receive_status) {
      case 1:
        component = (
          <span
            className="pointer bg-danger-light rounded hpx-30 px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_match: true });
            }}
          >
            รอ Matching
          </span>
        );
        break;
      case 2: component = <label>มอบอำนาจ Matching</label>; break;
      case 3: component = <label>ใช้ User ของนายจ้าง</label>; break;
      case 4: component = <label className="text-danger">Machting ไม่ได้</label>; break;
      case 5: component = <label className="text-success">Machting สำเร็จ</label>; break;
      case 6:
        component = (
          <label
            className="text-info pointer"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true, show_tab: 1 });
            }}
          >
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </label>
        );
        break;
      case 7:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            รอตรวจสอบ บต.46+มอบอำนาจ
          </span>
        );
        break;
      case 8:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            {item.menu_data.id === 7 ? "เอกสารไม่ถูกต้อง":"เอกสารไม่ถูกต้อง บต.46"}
          </span>
        );
        break;
      case 9:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
             {item.menu_data.id === 7 ? "เอกสารถูกต้องพร้อมยื่น":"เอกสาร บต.46+มอบอำนาจ ถูกต้อง"}
            
          </span>
        );
        break;
      case 10:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            {item.menu_data.id === 7 ? "เอกสารถูกต้องพร้อมยื่น":"ส่งเอกสาร บต.46+มอบอำนาจ แล้ว"}
            
          </span>
        );
        break;
      case 11:
        component =(
            <span
              className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
              onClick={() => {
                this.getById(item.receive_id);
                this.setState({ modal_update: true });
              }}
            >
             {item.fw_data && item.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0?"เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย":"รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย"} 
            </span>
          );
        break;
      case 12:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            เอกสารถูกต้อง พร้อมยื่น
          </span>
        );
        break;
      case 13:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap text-center"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ได้เลข WP {item.count_wp === item.total_qty ? "" : "(" + item.count_wp + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 14:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            {[3, 4, 5, 6].includes(item.menu_data.id) ? item.fw_data.filter(e=>e.fee_status).length === 0 ?"รอเก็บค่าบริการ":"บันทึกรับเงินแล้ว " + "(" + item.fw_data.filter(e=>e.fee_status).length + "/" + item.fw_data.length + ")" :item.menu_data.id === 20 ? "รอบันทึกเลขประจำตัว" :  "ได้เลข WP แล้ว รออนุมัติ"}
          </span>
        );
        break;
      case 15:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน
          </span>
        );
        break;
      case 16:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            {[3, 4, 5, 6].includes(item.menu_data.id) ? "ต่ออายุสำเร็จ" :item.menu_data.id === 8 ? "กรอกข้อมูล VISA แล้ว" : "แนบใบเสร็จแล้ว รอจัดส่ง"}
          </span>
        );
        break;
      case 17:
        component = (
          <span
            className="pointer bg-warning-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ส่งใบเสร็จให้ลูกค้าแล้ว รอออก eWork
          </span>
        );
        break;
      case 18:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap text-center"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ออก eWork แล้ว {item.count_e_work === item.total_qty ? "" : "(" + item.count_e_work + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 19:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            {item.menu_data.id === 11 ? "รับ Passport แล้ว" :"รับ eWork แล้ว รอจัดส่ง"}
          </span>
        );
        break;
      case 20:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            {[3, 4, 5, 6].includes(item.menu_data.id) ? "ส่งเอกสารคืน" : [7,8,20].includes(item.menu_data.id) ? "จัดส่งเอกสารแล้ว" :item.menu_data.id === 11 ? "จัดส่ง Passport แล้ว" :  "ส่ง eWork แล้ว"}
          </span>
        );
        break;
      case 21:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ปิดงาน
          </span>
        );
        break;
      case 22:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true, show_tab: 1 });
            }}
          >
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 23:
        component = (
          <span
            className="pointer bg-primary-light rounded text-primary px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true, show_tab: 1 });
            }}
          >
            รอต่ออายุ
          </span>
        );
        break;
      case 24:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            ลงชื่อ scan
          </span>
        );
        break;
      case 25:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            รอกรอกข้อมูล VISA
          </span>
        );
        break;
        case 26:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            เอกสารถูกต้องพร้อมยื่น
          </span>
        );
        break;
        case 27:
        component = (
          <span
            className="pointer bg-cyan-light rounded text-cyan px-2 py-1 text-nowrap"
            onClick={() => {
              this.getById(item.receive_id);
              this.setState({ modal_update: true });
            }}
          >
            บันทึกวันนัดรับ Passport แล้ว
          </span>
        );
        break;
      default:
        break;
    }
    return component;
  };
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) { 
      this.setState({
        count_e_work: result.data.count_e_work,
        count_wp: result.data.count_wp,
        checklist_data: result.data.checklist_data,
        fw_data: result.data.fw_data.map(e=> {return {
          ...e,
          visa_allowed_date: e.visa_allowed_date ? e.visa_allowed_date.split("T")[0]: "",
          visa_expiry_date: e.visa_expiry_date ? e.visa_expiry_date.split("T")[0]: "",
        }}),
        receive_id: result.data.receive_id,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch,
        immigration_office: result.data.immigration_office,
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.broker,
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        r_username: result.data.r_username,
        r_password: result.data.r_password,
        receive_status: result.data.receive_status,
        receive_date_s1: result.data.receive_date_s1,
        receive_by_s1: result.data.receive_by_s1,
        receive_date_s2: result.data.receive_date_s2,
        receive_by_s2: result.data.receive_by_s2,
        receive_date_s3: result.data.receive_date_s3,
        receive_by_s3: result.data.receive_by_s3,
        receive_date_s4: result.data.receive_date_s4,
        receive_by_s4: result.data.receive_by_s4,
        receive_date_s5: result.data.receive_date_s5,
        receive_by_s5: result.data.receive_by_s5,
        receive_date_s6: result.data.receive_date_s6,
        receive_by_s6: result.data.receive_by_s6,
        receive_date_s7: result.data.receive_date_s7,
        receive_by_s7: result.data.receive_by_s7,
        receive_date_s8: result.data.receive_date_s8,
        receive_by_s8: result.data.receive_by_s8,
        receive_date_s9: result.data.receive_date_s9,
        receive_by_s9: result.data.receive_by_s9,
        receive_date_s10: result.data.receive_date_s10,
        receive_by_s10: result.data.receive_by_s10,
        receive_date_s11: result.data.receive_date_s11,
        receive_by_s11: result.data.receive_by_s11,
        receive_date_s12: result.data.receive_date_s12,
        receive_by_s12: result.data.receive_by_s12,
        receive_date_s13: result.data.receive_date_s13,
        receive_by_s13: result.data.receive_by_s13,
        receive_date_s14: result.data.receive_date_s14,
        receive_by_s14: result.data.receive_by_s14,
        receive_date_s15: result.data.receive_date_s15,
        receive_by_s15: result.data.receive_by_s15,
        receive_date_s16: result.data.receive_date_s16,
        receive_by_s16: result.data.receive_by_s16,
        receive_date_s17: result.data.receive_date_s17,
        receive_by_s17: result.data.receive_by_s17,
        receive_date_s18: result.data.receive_date_s18,
        receive_by_s18: result.data.receive_by_s18,
        receive_date_s19: result.data.receive_date_s19,
        receive_by_s19: result.data.receive_by_s19,
        receive_date_s20: result.data.receive_date_s20,
        receive_by_s20: result.data.receive_by_s20,
        receive_date_s21: result.data.receive_date_s21,
        receive_by_s21: result.data.receive_by_s21,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
        data_deputize: result.data.dpz_data ? result.data.dpz_data : [],
        menu_data: result.data.menu_data,

        deliver_documents_s9: result.data.deliver_documents_s9,
        sending_document_date_s9: result.data.sending_document_date_s9,
        remark_s9: result.data.remark_s9,
        documents_s9: result.data.documents_s9,

        deliver_documents_s16: result.data.deliver_documents_s16,
        sending_document_date_s16: result.data.sending_document_date_s16,
        remark_s16: result.data.remark_s16,
        documents_s16: result.data.documents_s16,

        deliver_documents_s19: result.data.deliver_documents_s19,
        sending_document_date_s19: result.data.sending_document_date_s19,
        remark_s19: result.data.remark_s19,
        documents_s19: result.data.documents_s19,
        remark_s20: result.data.remark_s20,


        documents_employer_s22: result.data.documents_employer_s22,
        documents_power_s22: result.data.documents_power_s22,
        documents_sub_power_s22: result.data.documents_sub_power_s22,
        documents_scan_name: result.data.documents_scan_name,

        check_received_e_work: false,

        check_close: false,

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: result.data.witness_power_2_en,
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,

        due_date: result.data.due_date,
due_location: result.data.due_location,
due_remark: result.data.due_remark,
save_name_list: result.data.save_name_list,

        rate_list:result.data.rate_list?result.data.rate_list.map(e=>{
          return {
            qty_price:e.qty_price,
            qty:e.qty,
            discount:e.discount,
            discount_percent:e.discount_percent,
            vat: e.vat,
            remark:e.remark,
            service_type:e.service_type,
            total_price: e.service_type === "รายหัว"?(e.qty_price*e.qty)-((e.qty_price*e.qty)*(e.discount_percent/100)):e.vat+(e.qty_price)-((e.vat+e.qty_price)*(e.discount_percent/100))
          }
        }):[],
      });
    }
    this.setState({ loading: false });
  }; 
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="รายการรอยื่น" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการรอยื่น</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <select
                  className="form-control w-auto bg-contain border-0 mx-1 mb-3"
                  onChange={(e) => {
                    this.setState({
                      status_search: e.target.value,
                    });
                    setTimeout(() => {
                      this.Get();
                    }, 1);
                  }}
                  value={this.state.status_search}
                >
                  <option value="">รายการรับเรื่องทั้งหมด</option>
                  {this.state.data_menu_work.map((item, index) => (
                    <option value={item.id}>{item.menu_name}</option>
                  ))}
                </select>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่รับเรื่อง</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">ดำเนินการเรื่อง</th>
                      <th className="text-left text-white">สถานที่ยื่นเรื่อง</th>
                      <th className="text-left text-white">เอกสาร</th>
                      <th className="text-left text-white">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.receive_date ? format_date(item.receive_date) : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.full_name_th : "-"}</td>
                          <td className="text-left">{item.menu_data ? item.menu_data.menu_name : "-"}</td>
                          <td className="text-left"></td>
                          <td className="text-left">
                            <ul>
                              <li>Passport</li>
                              <li>Work</li>
                              <li>VISA</li>
                            </ul>
                          </td>
                          <td className="text-left">
                            <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => { }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          </div>
      </div>
    );
  }
}

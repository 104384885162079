import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
  document_receipt
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      loading: false,
      data: [],
      data_header: null,

      data_search: "",
      datetime_search: "",
      page:1,

      datetime_create: new Date(), //วันที่สร้าง
      full_name: "", //ลูกค้า
      invoice_type: "", //บริการที่แจ้งหนี้
      nationality: "", //สัญชาติ
      quantity: "", //จำนวน
      list_service: [], //ค่าบริการ
      total_price: 0, //รวมเป็นเงิน
      emp_data: null, //นายจ้าง
    };
  }
  async componentDidMount() {
    await this.Get();
    await this.GetHeaderDoc();
  }
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
    };
    let result = await GET(tokens, "api/receipt/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };

  GetHeaderDoc = async () => {
    this.setState({ loading: true });
    let result = await GET(tokens, "api/header-doc/list");
    if (result && result.status) {
      this.setState({
        data_header: result.data
      });
    }
    this.setState({ loading: false });
  };
  getById = async (item) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: item.receive_id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      let body_2 = {
        data_search: "",
        invoice_id: item.invoice_id,
        menu: "",
      };
      result.data.rate_list = result.data.rate_list.map(e=>{return {
        ...e,
        qty_price : e.qty_price - e.discount
      }})
      let result_2 = await GET(tokens, "api/invoice/list/page/0", body_2);
      if (result_2 && result_2.status) {
        let obj = {
          ...item,
          receive_data: result.data,
          invoice_data: result_2.data,
        }
        console.log(obj)
        document_receipt(obj, this.state.data_header)
      }
    }
    this.setState({ loading: false });
  };

  Create = async () => {
    let body = {};
    let result = await POST(tokens, "api/invoice/update/" + this.state.receipt_id, body);
    if (result && result.status) {
      success_url("/Invoice/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="รายการใบเสร็จ" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการใบเสร็จ</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3 align-items-center">
                <input
                  className="form-control wpx-250 bg-contain border-0 me-3 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่สร้าง</th>
                      <th className="text-left text-white">เลขที่ใบเสร็จ</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">บริการ</th>
                      <th className="text-left text-white">ผู้ขาย</th>
                      <th className="text-left text-white">ตัวแทน</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.receipt_code ? item.receipt_code : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                          <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">{item.broker_name ? item.broker_name : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              {/* <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  window.location.href = "/Invoice/update?id=" + item.receipt_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div> */}
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  this.getById(item);
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf1c1"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

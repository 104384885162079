import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class Listrole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      search: "",

      data: null,
      data_search: "",

      modal_create: false,
      modal_update: false,

      role_id: "",
      role_name: "",
      role_detail: [],

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,
    };
  }
  async componentDidMount() {
    await this.GetAll();
  }

  GetAll = async () => {
    let body = {
      data_search: this.state.data_search,
      role_id: "",
    };
    let result = await GET(tokens, "api/role/list/page/"+this.state.page, body);
    if (result && result.status) {
      this.setState({ data: result.data, end_index: result.count < result.end_index ? result.count : result.end_index, count: result.count, page_number: result.page_number });
    }
  };
  Create = async () => {
    if (this.state.role_name.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อสิทธิ์ผู้ใช้งาน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      role_name: this.state.role_name,
      role_detail: this.state.role_detail,
    };
    let result = await POST(tokens, "api/role/create", data);
    if (result && result.status) {
      success_url("/Role/list");
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    if (this.state.role_name.trim() === "") {
      alert("primary", "แจ้งเตือน", "กรุณากรอกชื่อสิทธิ์ผู้ใช้งาน");
      return;
    }
    this.setState({ loading: true });
    let data = {
      role_id: this.state.role_id,
      role_name: this.state.role_name,
      role_detail: this.state.role_detail,
    };
    let result = await PUT(tokens, "api/role/update/" + this.state.role_id, data);
    if (result && result.status) {
      success_url("/Role/list");
    }
    this.setState({ loading: false });
  };
  Delete = async () => {
    this.setState({ loading: true });
    let result = await DELETE(tokens, "api/role/delete/" + this.state.role_id, null);
    if (result && result.status) {
      success_url("/Role/list");
    }
    this.setState({ loading: false, modal_delete: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            {" "}
            <div className="loading-spinner"></div>{" "}
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="สิทธิ์ผู้ใช้งาน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>สิทธิ์ผู้ใช้งาน</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    this.setState({
                      modal_create: true,
                      role_id: "",
                      role_name: "",
                      role_detail: global.role,
                    });
                  }}
                >
                  <label className="icon">{"\u002B"}</label> เพิ่มสิทธิ์ผู้ใช้งาน
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อสิทธิ์</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.filter(e=>e.role_name !== "ตัวแทน").map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.role_name ? item.role_name : "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.setState({
                                    modal_update: true,
                                    role_id: item.role_id,
                                    role_name: item.role_name,
                                    role_detail: item.role_detail,
                                  });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.setState({ modal_delete: true, role_id: item.role_id, role_name: item.role_name });
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    {" "}
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ{" "}
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.GetAll();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* create */}
        <Modal
          show={this.state.modal_create || this.state.modal_update}
          onHide={() => {
            this.setState({ modal_create: false, modal_update: false });
          }}
          size={"lg"}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>{this.state.modal_create ? "เพิ่มสิทธิ์ผู้ใช้งาน" : "แก้ไขสิทธิ์ผู้ใช้งาน"}</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center mb-3">
              <b className="wpx-100">ชื่อสิทธิ์{required}</b>
              <input
                className="form-control"
                type="text"
                placeholder="ชื่อสิทธิ์"
                onChange={(e) => {
                  this.setState({ role_name: e.target.value });
                }}
                value={this.state.role_name}
              />
            </div>
            <div className="w-100">
              <table className="table table-bordered">
                <thead className="bg-primary text-white">
                  <tr>
                    <th>รายการ</th>
                    <th className="wpx-80 text-center">
                      ทั้งหมด <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { can_view: e.target.checked, can_add: e.target.checked, can_edit: e.target.checked, can_delete: e.target.checked, can_download: e.target.checked, can_approved: e.target.checked } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_view && item.permission.can_add && item.permission.can_edit && item.permission.can_delete && item.permission.can_download && item.permission.can_approved).length === this.state.role_detail.length}
                      />
                    </th>
                    <th className="wpx-80 text-center">
                      ดู <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { ...item.permission, can_view: !item.permission.can_view } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_view).length === this.state.role_detail.length}
                      />
                    </th>
                    <th className="wpx-80 text-center">
                      เพิ่ม <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { ...item.permission, can_add: !item.permission.can_add } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_add).length === this.state.role_detail.length}
                      />
                    </th>
                    <th className="wpx-80 text-center">
                      แก้ไข <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { ...item.permission, can_edit: !item.permission.can_edit } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_edit).length === this.state.role_detail.length}
                      />
                    </th>
                    <th className="wpx-80 text-center">
                      ลบ <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { ...item.permission, can_delete: !item.permission.can_delete } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_delete).length === this.state.role_detail.length}
                      />
                    </th>
                    <th className="wpx-80 text-center">
                      ดาวน์โหลด <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { ...item.permission, can_download: !item.permission.can_download } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_download).length === this.state.role_detail.length}
                      />
                    </th>
                    <th className="wpx-80 text-center">
                      อนุมัติ <br />{" "}
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          this.setState({
                            role_detail: this.state.role_detail.map((item) => {
                              return { ...item, permission: { ...item.permission, can_approved: !item.permission.can_approved } };
                            }),
                          });
                        }}
                        checked={this.state.role_detail.filter((item) => item.permission.can_approved).length === this.state.role_detail.length}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.role_detail &&
                    this.state.role_detail.map((item, index) => (
                      <tr>
                        <td>{global.role_name[item.menu_name]}</td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_view = e.target.checked;
                              role_detail[index].permission.can_add = e.target.checked;
                              role_detail[index].permission.can_edit = e.target.checked;
                              role_detail[index].permission.can_delete = e.target.checked;
                              role_detail[index].permission.can_download = e.target.checked;
                              role_detail[index].permission.can_approved = e.target.checked;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_view && item.permission.can_add && item.permission.can_edit && item.permission.can_delete && item.permission.can_download && item.permission.can_approved}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_view = !role_detail[index].permission.can_view;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_view}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_add = !role_detail[index].permission.can_add;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_add}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_edit = !role_detail[index].permission.can_edit;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_edit}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_delete = !role_detail[index].permission.can_delete;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_delete}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_download = !role_detail[index].permission.can_download;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_download}
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              let role_detail = this.state.role_detail;
                              role_detail[index].permission.can_approved = !role_detail[index].permission.can_approved;
                              this.setState({ role_detail: role_detail });
                            }}
                            checked={item.permission.can_approved}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="w-100 d-flex">
              <div className="w-50 p-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_create: false, modal_update: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 p-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.modal_update) {
                      this.Update();
                    } else {
                      this.Create();
                    }
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ลบข้อมูล */}
        <Modal
          show={this.state.modal_delete}
          onHide={() => {
            this.setState({ modal_delete: false });
          }}
        >
          <Modal.Header>
            {" "}
            <Modal.Title>
              {" "}
              <b>ลบสิทธิ์ผู้ใช้งาน</b>{" "}
            </Modal.Title>{" "}
          </Modal.Header>
          <Modal.Body>
            <label>ท่านต้องการลบสิทธิ์ผู้ใช้งาน “{this.state.role_name}”</label>
          </Modal.Body>
          <Modal.Footer>
            <label className="mb-3 w-100">หมายเหตุ ไม่สามารถกู้คืนได้</label>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.Delete();
                  }}
                >
                  ลบ
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-outline-danger w-100"
                  onClick={() => {
                    this.setState({ modal_delete: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
